import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useApi, useWorkspace } from '~/contexts';
import { PageLoader } from '~/routes/public/pages';

const SavedReportsContext = React.createContext();

function SavedReportsProvider({ children }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [savedReports, setSavedReports] = useState(null);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().saved.get();
    setSavedReports(data.map((r) => ({ ...r, path: `/saved/${r.id}` })));
  }, [api, workspace.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <SavedReportsContext.Provider value={{ saved: savedReports }}>
      {savedReports === null ? <PageLoader /> : children}
    </SavedReportsContext.Provider>
  );
}

function useSavedReports() {
  const context = useContext(SavedReportsContext);
  return context;
}

export { SavedReportsContext, SavedReportsProvider, useSavedReports };
