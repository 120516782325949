import { BillableIcon, DateTime, Hours, Icon, Table, Tooltip } from '~/components';
import { useApi } from '~/contexts';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { rgba } from 'polished';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const Page = styled.div`
  background-color: white;
  padding: 3.125rem;
  padding-top: 2rem;
  border: 1px solid ${colors.grey10};
  box-shadow: 0 6px 8px ${rgba(colors.black, 0.16)};

  @media print {
    box-shadow: none;
    border: none;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: ${weights.light};
`;

const ActionButton = styled.button`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  color: ${colors.white};
  background-color: ${colors.primary};
  border-radius: 100%;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.accent};
  }
`;

const DefaultContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  font-size: 1.25rem;
`;

const GroupRow = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${weights.bold};
  background-color: ${colors.grey10};
`;

const GroupCell = styled.div`
  display: flex;
  padding: 0.5rem 1rem;

  &:not(:first-child) {
    margin-left: auto;
  }
`;

const TimeEntryNotes = styled.p`
  margin-top: -0.5rem;
  padding: 0 4rem 1rem 12rem;
  border-bottom: 1px solid ${colors.grey10};
  color: ${colors.grey55};
  font-size: 0.75rem;

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }
`;

const HoursLabel = styled.div`
  position: absolute;
  top: 50%;
  left: -2.5rem;
  transform: translateY(-50%);
`;

export default function InvoiceTimeReport({ invoice }) {
  const api = useApi();
  const { workspaceKey, invoiceId } = useParams();
  const [{ locale, entries }, setData] = useState({ locale: null, entries: null });
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await api.invoices({ workspaceKey, invoiceId }).time();
      setData(data);
      setIsReady(true);
    };
    fetchData();
    return fetchData.cancel;
  }, [api, invoiceId, workspaceKey]);

  const totalHours = useMemo(() => {
    if (!entries) {
      return 0;
    }
    return _(entries)
      .map((entry) => entry.hours)
      .filter(_.isNumber)
      .sum();
  }, [entries]);

  const groupedEntries = useMemo(() => {
    if (!entries) {
      return [];
    }
    return _.reduce(
      entries,
      (acc, entry) => {
        let group;
        if (invoice.groupTimeReportBy === 'role') {
          group = {
            id: entry.project?.useRoles ? `role_${entry.role?.id}` : `no_role`,
            name: entry.project?.useRoles ? entry.role?.name : '',
          };
        } else if (invoice.groupTimeReportBy === 'role_and_person') {
          group = {
            id: entry.project?.useRoles
              ? `role_${entry.role?.id}_person_${entry.member?.id}`
              : `no_role_person_${entry.member?.id}`,
            name:
              entry.project?.useRoles && entry.role && entry.member
                ? `${entry.role?.name} - ${entry.member?.name}`
                : entry.project?.useRoles && entry.role
                  ? entry.role.name
                  : entry.member?.name,
          };
        } else if (invoice.groupTimeReportBy === 'person') {
          group = {
            id: `person_${entry.member?.id}`,
            name: entry.member?.name,
          };
        } else {
          group = {};
        }

        let obj = _.find(acc, group);
        if (!obj) {
          obj = { ...group, entries: [], totalHours: 0 };
          acc.push(obj);
        }

        obj.entries.push(entry);
        obj.totalHours = _(obj.entries)
          .map((entry) => entry.hours)
          .filter(_.isNumber)
          .sum();

        return acc;
      },
      [],
    );
  }, [entries, invoice]);

  const columns = useMemo(() => {
    const fields = invoice?.timeReportFields || [];
    const groupBy = invoice?.groupTimeReportBy;
    return {
      billable: fields.includes('billable'),
      date: fields.includes('date'),
      person: fields.includes('person') && !['person', 'role_and_person'].includes(groupBy),
      project: fields.includes('project'),
      role: fields.includes('role') && !['role', 'role_and_person'].includes(groupBy),
      task: fields.includes('task'),
      notes: fields.includes('notes'),
    };
  }, [invoice]);

  const handleExport = async () => {
    const response = await api.invoices({ workspaceKey, invoiceId }).timeCsv();
    const disposition = response.headers['content-disposition'];
    const filenameMatch = disposition.match(/filename[^;=\n]*=((['"])(?<name>.*)?\2|[^;\n]*)/i);
    const filename = filenameMatch.groups['name'];
    const blob = new Blob([response.data], { type: 'text/csv' });

    saveAs(blob, filename);
  };

  if (!isReady) {
    return (
      <Page>
        <DefaultContent>
          <PageLoader />
        </DefaultContent>
      </Page>
    );
  }

  if (!entries || entries.length === 0) {
    return (
      <Page>
        <DefaultContent>No time entries available.</DefaultContent>
      </Page>
    );
  }

  return (
    <Page>
      <Header>
        <Title>Time Detail - Invoice #{invoice.number}</Title>
        <Tooltip message="Download time detail CSV">
          <ActionButton onClick={handleExport}>
            <Icon icon="file-csv" />
          </ActionButton>
        </Tooltip>
      </Header>
      <Table>
        <Table.Header>
          {columns.billable && <Table.Column width="3.5rem" />}
          {columns.date && <Table.Column width="7.5rem">Date</Table.Column>}
          {columns.person && <Table.Column>Member</Table.Column>}
          {columns.project && <Table.Column>Project</Table.Column>}
          {columns.role && <Table.Column>Role</Table.Column>}
          {columns.task && <Table.Column>Task</Table.Column>}
          <Table.Column width="5rem" align="right" style={{ marginLeft: 'auto' }}>
            Total
          </Table.Column>
        </Table.Header>
        {groupedEntries.map((group) => (
          <React.Fragment key={group.id}>
            <GroupRow>
              <GroupCell>{group.name}</GroupCell>
              <GroupCell>
                <Hours value={group.totalHours} locale={locale} />
              </GroupCell>
            </GroupRow>
            {group.entries.map((entry) => (
              <div key={entry.id}>
                <Table.Row borderBottom={entry.notes ? '0' : undefined}>
                  {columns.billable && (
                    <Table.Cell>
                      <BillableIcon value={entry.isBillable} />
                    </Table.Cell>
                  )}
                  {columns.date && (
                    <Table.Cell>
                      <DateTime value={entry.date} locale={locale} />
                    </Table.Cell>
                  )}
                  {columns.person && <Table.Cell>{entry.member?.name}</Table.Cell>}
                  {columns.project && <Table.Cell>{entry.project?.name}</Table.Cell>}
                  {columns.role && <Table.Cell>{entry.project?.useRoles && entry.role?.name}</Table.Cell>}
                  {columns.task && <Table.Cell>{entry.task?.name}</Table.Cell>}
                  <Table.Cell style={{ marginLeft: 'auto' }}>
                    <Hours value={entry.hours} locale={locale} />
                  </Table.Cell>
                </Table.Row>
                {columns.notes && entry.notes && <TimeEntryNotes>{entry.notes}</TimeEntryNotes>}
              </div>
            ))}
          </React.Fragment>
        ))}
        <Table.Row style={{ fontWeight: weights.bold }}>
          {columns.billable && <Table.Cell />}
          {columns.date && <Table.Cell />}
          {columns.person && <Table.Cell />}
          {columns.project && <Table.Cell />}
          {columns.role && <Table.Cell />}
          {columns.task && <Table.Cell />}
          <Table.Cell style={{ marginLeft: 'auto' }}>
            <HoursLabel>Total</HoursLabel>
            <Hours value={totalHours} locale={locale} />
          </Table.Cell>
        </Table.Row>
      </Table>
    </Page>
  );
}
