import _ from 'lodash';
import { del, get, patch, post } from './agent';

const hubspot = {
  public: {
    postRedirectUri: ({ uri }) => post('/hubspot/redirect-uri', { uri }),
  },

  workspace: (workspacesUrl) => {
    const url = _([workspacesUrl, 'hubspot']).compact().join('/');

    return {
      getIntegration: () => get(url.concat('/integration')),
      manualSync: (body) => post(url.concat('/manual-sync'), body),
      updateSyncSettings: (body) => patch(url.concat('/sync-settings'), body),
      getPipelines: () => get(url.concat('/pipelines')),
      getOpportunityTypes: () => get(url.concat('/opportunity-types')),
      getResults: () => get(url.concat('/results')),
      getSyncs: () => get(url.concat('/syncs')),
      getSyncLog: (id) => get(url.concat('/syncs/', id, '/log')),
      getStatus: () => get(url.concat('/status')),
      remove: () => del(url),
    };
  },
};
export default hubspot;
