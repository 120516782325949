import React from 'react';
import SingleSelectFilter from './SingleSelectFilter';

function ExpenseReimbursableTypeFilter(props) {
  return (
    <SingleSelectFilter
      icon="filter"
      placeholder="Reimbursable Type"
      renderValue={(value) => value.name}
      options={[
        { id: 'reimbursable', name: 'Reimbursable' },
        { id: 'non_reimbursable', name: 'Non-Reimbursable' },
      ]}
      {...props}
    />
  );
}

export default ExpenseReimbursableTypeFilter;
