import { Button, Buttons, CancelButton, Drawer, Field, Form, TextInput } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useConfirmation } from '~/contexts/ConfirmationContext';
import { Formik } from 'formik';
import { useDirtyCheck } from '~/hooks';
import React, { useRef } from 'react';
import { mergeValues } from '~/utils';
import * as Yup from 'yup';
import BaseTransactionNumberDialog from './BaseTransactionNumberDialog';
import DuplicateTransactionNumberDialog from './DuplicateTransactionNumberDialog';
import extractTransactionNumber from './extract-transaction-number';

function HeaderDrawer({ creditNote, values, onApply, onClose }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const confirmation = useConfirmation();
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);

  function handleClose() {
    onClose();
  }

  const initialValues = mergeValues(
    {
      creditNoteFor: '',
      issuedOn: null,
      notes: '',
      number: values.transactionNumber,
      transactionNumber: values.transactionNumber,
      poNumber: '',
      billTo: '',
    },
    values,
  );

  const schema = Yup.object().shape({
    // Header
    creditNoteFor: Yup.string().label('Credit Note For').max(255),
    issuedOn: Yup.date().label('Issued On').nullable().required(),
    number: Yup.string().label('Number').max(255).nullable().required(),
    poNumber: Yup.string().label('P.O. Number').max(255),
    billTo: Yup.string().label('Bill To').max(5000).required(),
  });

  return (
    <>
      <Drawer
        isOpen
        title="Credit Note Header"
        onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
        onClose={handleClose}>
        {(closeDrawer) => {
          const handleCloseClick = () => dirtyCheck(() => closeDrawer());

          async function handleSubmit(formValues) {
            // If the transaction number changed, check if it's used by another credit note
            if (formValues.transactionNumber !== values.transactionNumber) {
              const { data: validateTransactionNumberResult } = await api.www
                .workspaces(workspace.id)
                .creditNotes(creditNote.id)
                .validateTransactionNumber({ transactionNumber: formValues.transactionNumber });

              if (validateTransactionNumberResult === 'duplicate') {
                const confirmDuplicate = await confirmation.prompt((resolve) => (
                  <DuplicateTransactionNumberDialog
                    transactionNumber={formValues.transactionNumber}
                    onConfirm={() => resolve(true)}
                    onClose={() => resolve(false)}
                  />
                ));

                if (!confirmDuplicate) return;
              }
            }

            if (formValues.transactionNumber !== values.transactionNumber) {
              const setBaseTransactionNumber = await confirmation.prompt((resolve) => (
                <BaseTransactionNumberDialog
                  originalTransactionNumber={values.transactionNumber}
                  newTransactionNumber={formValues.transactionNumber}
                  onConfirm={(result) => resolve(result)}
                  onClose={() => resolve(null)}
                />
              ));

              if (setBaseTransactionNumber == null) return;

              formValues.setBaseTransactionNumber = setBaseTransactionNumber;
            }

            onApply(formValues);
            closeDrawer();
          }

          return (
            <>
              <Formik
                innerRef={formRef}
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={schema}>
                {(formik) => {
                  const handleCreditNoteNumberChange = ({ target: { value } }) => {
                    const transactionNumber = extractTransactionNumber(value);
                    formik.setValues({
                      ...formik.values,
                      number: value,
                      transactionNumber: transactionNumber || creditNote.transactionNumber,
                    });
                  };

                  return (
                    <Form>
                      <Form.Section title="Client Information">
                        <Form.Control>
                          <TextInput placeholder="Client" value={creditNote.client.name} disabled />
                        </Form.Control>

                        <Form.Control>
                          <Field.TextArea name="billTo" placeholder="Bill To" maxLength={5000} rows={4} />
                        </Form.Control>
                      </Form.Section>

                      <Form.Section title="Credit Note Information">
                        <Form.Control>
                          <Field.Text
                            name="number"
                            placeholder="Credit Note Number"
                            maxLength={255}
                            onChange={handleCreditNoteNumberChange}
                          />
                          <Field.DayPicker name="issuedOn" placeholder="Issued On" clearable={false} />
                        </Form.Control>
                        <Form.Control>
                          {!values.purchaseOrder && (
                            <Field.Text
                              name="poNumber"
                              placeholder="P.O. Number"
                              maxLength={255}
                              disabled={values.purchaseOrder}
                            />
                          )}
                        </Form.Control>
                        <Form.Control>
                          <Field.Text name="creditNoteFor" placeholder="Credit Note For" maxLength={255} />
                        </Form.Control>
                      </Form.Section>
                      <Drawer.Actions>
                        <Buttons align="right">
                          <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                          <Button onClick={formik.submitForm}>Apply</Button>
                        </Buttons>
                      </Drawer.Actions>
                    </Form>
                  );
                }}
              </Formik>
            </>
          );
        }}
      </Drawer>
    </>
  );
}

export default HeaderDrawer;
