import salesforceIcon from '~/assets/public/salesforce1.svg';
import { DateTime, Spinner, Tooltip } from '~/components';
import { useApi, useIntegrations, useToast, useWorkspace } from '~/contexts';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import ListTooltip from '../../reports/components/ListTooltip';
import List from '../../reports/components/List';

const Wrapper = styled.div`
  width: 1rem;
  height: 1rem;
  margin: 0 0.5rem 0 0.5rem;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
`;

const Image = styled.img`
  min-width: 1rem;
  min-height: 1rem;
  width: 1rem;
`;

export default function SalesforceIndicator({ message = 'Synced from Salesforce', id }) {
  const integrations = useIntegrations();
  const toast = useToast();
  const api = useApi();
  const { workspace } = useWorkspace();
  const [integration, setIntegration] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const handleCopy = () => {
    if (id) {
      navigator.clipboard.writeText(id);
      toast.success('Salesforce ID copied to clipboard.');
    }
  };

  const fetchIntegration = useCallback(async () => {
    if (!id && !integrations.salesforce) return;
    try {
      const { data } = await api.www.workspaces(workspace.id).salesforce.get();
      setIntegration(data);
    } finally {
      setIsLoading(false);
    }
  }, [api, workspace, id, integrations.salesforce]);

  useEffect(() => {
    if (id && integrations.salesforce) {
      fetchIntegration();
      return fetchIntegration.cancel;
    }
  }, [fetchIntegration, id, integrations.salesforce]);

  if (!integrations.salesforce) return null;

  if (!id) {
    return (
      <Wrapper>
        <Tooltip message={message}>
          <Image src={salesforceIcon} />
        </Tooltip>
      </Wrapper>
    );
  }

  return (
    <Wrapper isClickable={id ? true : false} onClick={handleCopy}>
      <ListTooltip
        message={
          <List>
            <List.Item label="Synced From">Salesforce</List.Item>
            <List.Item label="Salesforce ID">{id} </List.Item>
            <List.Item label="Last Sync Date">
              {isLoading ? (
                <Spinner />
              ) : (
                <DateTime value={integration?.settings.mostRecentSync} format="l" titleFormat="LL" />
              )}
            </List.Item>
          </List>
        }>
        <Image src={salesforceIcon} />
      </ListTooltip>
    </Wrapper>
  );
}
