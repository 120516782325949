import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { CreateButton, DateTime, ListView, ListViewActions, Page } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import ApiKeyForm from './ApiKeyForm';
import RemoveApiKey from './RemoveApiKey';
import ViewApiKey from './ViewApiKey';
import { colors } from '~/styles';

const Description = styled.p`
  margin-top: 0.5rem;
  font-size: 0.875rem;
  margin-right: 1rem;
  color: ${colors.grey55};
`;

export default function ApiKeys() {
  useDocumentTitle('API Keys');

  const api = useApi();
  const { workspace } = useWorkspace();
  const [isLoading, setIsLoading] = useState(true);
  const [deleteKey, setDeleteKey] = useState();
  const [editKey, setEditKey] = useState();
  const [viewKey, setViewKey] = useState();
  const [apiKeys, setApiKeys] = useState([]);

  const fetchKeys = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await api.www.workspaces(workspace.id).apiKeys().get();
      setApiKeys(data);
    } finally {
      setIsLoading(false);
    }
  }, [api, workspace]);

  useEffect(() => {
    fetchKeys();
    return fetchKeys.cancel;
  }, [fetchKeys]);

  const handleSave = (apiKey) => {
    if (apiKey.token) {
      setViewKey(apiKey);
    }
    fetchKeys();
  };

  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <Page>
      <Page.Header>
        <Page.Info>
          <Page.Eyebrow>Settings</Page.Eyebrow>
          <Page.Title>API Keys</Page.Title>

          <Description>
            Access your data using the Ruddr API by creating authentication keys. See the{' '}
            <a href="http://ruddr.readme.io" target="_blank" rel="noopener noreferrer">
              documentation
            </a>{' '}
            for more information.
          </Description>
        </Page.Info>

        <Page.Actions>
          <CreateButton tooltip="Create an API Key" onClick={() => setEditKey(null)} />
        </Page.Actions>
      </Page.Header>

      <Page.ListView>
        <ListView>
          <ListView.Header>
            <ListView.Column>Name</ListView.Column>
            <ListView.Column>Token</ListView.Column>
            <ListView.Column>Created</ListView.Column>
            <ListViewActions.Column />
          </ListView.Header>

          <ListView.Body>
            {apiKeys.map((apiKey) => (
              <ListView.Row key={apiKey.id}>
                <ListView.Cell>{apiKey.name}</ListView.Cell>
                <ListView.Cell>
                  ...<code>{apiKey.tokenLast4}</code>
                </ListView.Cell>
                <ListView.Cell>
                  <DateTime value={apiKey.createdAt} />
                </ListView.Cell>
                <ListViewActions>
                  <ListViewActions.Edit onClick={() => setEditKey(apiKey)} />
                  <hr />
                  <ListViewActions.Delete onClick={() => setDeleteKey(apiKey)} />
                </ListViewActions>
              </ListView.Row>
            ))}

            {apiKeys.length === 0 && <ListView.Empty />}
          </ListView.Body>
        </ListView>
      </Page.ListView>

      {deleteKey !== undefined && (
        <RemoveApiKey apiKey={deleteKey} onClose={() => setDeleteKey()} onRemove={() => fetchKeys()} />
      )}
      {editKey !== undefined && <ApiKeyForm apiKey={editKey} onClose={() => setEditKey()} onFinish={handleSave} />}
      {viewKey !== undefined && <ViewApiKey apiKey={viewKey} onClose={() => setViewKey()} />}
    </Page>
  );
}
