import path from 'path-browserify';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { BackLink, IconLink, Page } from '~/components';
import { useIntegrations, useWorkspace } from '~/contexts';
import { Tag, Tags } from '../components/HeaderStyledComponents';
import HubspotIndicator from '../components/HubspotIndicator';
import SalesforceIndicator from '../components/SalesforceIndicator';

const resolveEditPath = (to, { pathname } = {}) =>
  pathname ? (pathname.endsWith(to) ? pathname : path.resolve(pathname, to)) : '';

function CompanyHeader({ company }) {
  const location = useLocation();
  const { workspace } = useWorkspace();
  const integrations = useIntegrations();

  return (
    <Page.Header>
      <BackLink defaultPath={`/app/${workspace.key}/companies`} />
      <Page.Info>
        <Page.Eyebrow>Company Dashboard</Page.Eyebrow>
        <Page.Title>{company.name}</Page.Title>

        {integrations.salesforce && company?.salesforceCompany?.salesforceId && (
          <Tags>
            <Tag>
              <SalesforceIndicator />
            </Tag>
          </Tags>
        )}
        {integrations.hubspot && company?.hubspotCompany?.hubspotId && (
          <Tags>
            <Tag>
              <HubspotIndicator />
            </Tag>
          </Tags>
        )}
      </Page.Info>

      <Page.Actions>
        {company.permissions.manage && (
          <IconLink
            tooltip="Edit Company"
            icon="pencil-alt"
            to={`${resolveEditPath('edit', location)}${location.search}`}
          />
        )}
      </Page.Actions>
    </Page.Header>
  );
}

export default CompanyHeader;
