import classNames from 'classnames';
import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '~/styles';
import { alignToJustify } from './ListViewColumn';
import { useListView } from './ListViewContext';

const Cell = styled.div`
  flex: ${({ column }) => (column.width ? `0 0 ${column.width}` : '1')};
  position: relative;
  display: flex;
  align-items: center;
  min-height: 1rem;
  justify-content: ${({ column }) => alignToJustify(column.align)};
  min-width: ${({ column }) => column.minWidth ?? column.width ?? 'auto'};
  padding: ${({ column, padding }) => (padding ? padding : column?.padding ? column.padding : '0.5rem 1rem')};
  text-align: ${({ column }) => column.align};
  font-size: ${({ column }) => column.fontSize ?? '0.875rem'};

  ${({ column }) =>
    !!column?.sticky &&
    css`
      position: sticky;
      left: -1rem;
      z-index: 1;
      background: linear-gradient(to right, ${colors.white} 90%, transparent 100%);
      background-size: calc(100%) calc(100% - 2px);
      background-position: center;
      background-repeat: no-repeat;
    `}
`;

function ListViewCell({ className, children, index, style, ...props }) {
  const table = useListView();
  const column = table.columns[index];
  const mergedStyle = { ...(style ?? {}), ...(column?.style ?? {}) };

  if (!column?.isVisible) return null;
  return (
    <Cell
      {...props}
      style={mergedStyle}
      column={column}
      className={classNames(className, 'table-cell', {
        [`cell-${column?.name}`]: column?.name,
      })}>
      {children}
    </Cell>
  );
}

export default styled(ListViewCell)``;
