import { rgba } from 'polished';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Dropdown, Icon, Page } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { colors, weights } from '~/styles';

const Component = styled(Dropdown)`
  font-size: 0.875rem;
  font-weight: ${weights.normal};
  white-space: nowrap;
  width: 15rem;
  text-align: left;
  justify-self: center;
  margin-top: 0.25rem;

  ${Dropdown.Trigger} {
    button {
      color: ${colors.black};

      &:hover {
        color: ${colors.grey55};
      }
    }
  }
`;

const Menu = styled(Dropdown.Menu)`
  box-shadow: 0 6px 8px ${rgba(colors.black, 0.16)};
  min-width: 20rem;
  max-height: 20rem;
  overflow-y: auto;
`;

const Trigger = styled.div`
  position: relative;
  display: flex;
`;

const Caret = styled(Icon)`
  font-size: 0.875rem;
  padding-top: 0.5rem;
`;

export default function MemberDropdown({ member }) {
  const { workspace } = useWorkspace();
  const api = useApi();

  const [members, setMembers] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).members().list({ isActive: true, size: 1000 });

      setMembers(data);
    } catch (error) {
      setMembers([]);
    }
  }, [api, workspace.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Component position="bottom" align="left">
      {({ setIsOpen, ...props }) => {
        return (
          <>
            <Dropdown.Trigger>
              <Button isAnchor onClick={() => setIsOpen(true)}>
                <Trigger>
                  <Page.Title>{member.name}</Page.Title>
                  <Caret spaceLeft icon="angle-down" />
                </Trigger>
              </Button>
            </Dropdown.Trigger>

            <Menu {...props}>
              {members?.length > 0 ? (
                members
                  .filter((m) => m.id !== member.id)
                  .map((member) => (
                    <Dropdown.Link
                      key={member.id}
                      onClick={() => setIsOpen(false)}
                      to={`/app/${workspace.key}/settings/members/details/${member.id}/general`}
                      value={member.id}>
                      {member.name}
                    </Dropdown.Link>
                  ))
              ) : (
                <Dropdown.Item>No members available</Dropdown.Item>
              )}
            </Menu>
          </>
        );
      }}
    </Component>
  );
}
