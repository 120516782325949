import _ from 'lodash';
import React from 'react';
import {
  DisciplineFilter,
  EmploymentTypeFilter,
  JobTitleFilter,
  LocationFilter,
  MemberBillableTypeFilter,
  MemberCostMethodFilter,
  MemberFilter,
  MemberInvitationStatusFilter,
  MemberStatusFilter,
  MemberTagFilter,
  PracticeFilter,
  SecurityRoleFilter,
  SkillFilter,
} from '~/components/index';
import useFeatures from '~/hooks/useFeatures';
import FiltersGroup from './FiltersGroup';
import ReportPeriodFilter from './ReportPeriodFilter';

function MemberFiltersGroup({ filters, onChange }) {
  const features = useFeatures();

  return (
    <FiltersGroup
      label="Member Filters"
      filters={[
        filters.members,
        filters.employmentTypes,
        filters.memberBillableType,
        filters.memberStatus,
        filters.memberDisciplines,
        filters.jobTitles,
        filters.memberPractices,
        filters.managers,
        filters.memberSkills,
        filters.memberTags,
        filters.memberLocations,
        filters.securityRoles,
        filters.memberCostMethod,
        filters.memberInvitationStatuses,
        filters.memberActive?.start,
        filters.memberActive?.end,
      ]}>
      {_.has(filters, 'memberActive') && (
        <ReportPeriodFilter
          placeholder="Member Active Range"
          value={filters.memberActive}
          onChange={({ target: { value } }) => onChange({ memberActive: value })}
        />
      )}

      <MemberFilter
        value={filters.members}
        placeholder="Member"
        onChange={({ target: { value } }) => onChange({ members: value })}
      />

      {_.has(filters, 'securityRoles') && (
        <SecurityRoleFilter
          value={filters.securityRoles}
          onChange={({ target: { value } }) => onChange({ securityRoles: value })}
        />
      )}

      <EmploymentTypeFilter
        value={filters.employmentTypes}
        onChange={({ target: { value } }) => onChange({ employmentTypes: value })}
      />

      <MemberBillableTypeFilter
        value={filters.memberBillableType}
        onChange={({ target: { value } }) => onChange({ memberBillableType: value })}
      />

      {_.has(filters, 'memberStatus') && (
        <MemberStatusFilter
          value={filters.memberStatus}
          onChange={({ target: { value } }) => onChange({ memberStatus: value })}
        />
      )}

      {features.disciplines && _.has(filters, 'memberDisciplines') && (
        <DisciplineFilter
          value={filters.memberDisciplines}
          placeholder="Member Discipline"
          onChange={({ target: { value } }) => onChange({ memberDisciplines: value })}
        />
      )}

      <JobTitleFilter value={filters.jobTitles} onChange={({ target: { value } }) => onChange({ jobTitles: value })} />

      {features.practices && _.has(filters, 'memberPractices') && (
        <PracticeFilter
          value={filters.memberPractices}
          placeholder="Member Practice"
          onChange={({ target: { value } }) => onChange({ memberPractices: value })}
        />
      )}

      <MemberFilter
        value={filters.managers}
        placeholder="Member Manager"
        onChange={({ target: { value } }) => onChange({ managers: value })}
      />

      {_.has(filters, 'memberSkills') && (
        <SkillFilter
          value={filters.memberSkills}
          placeholder="Member Skill"
          onChange={({ target: { value } }) => onChange({ memberSkills: value })}
        />
      )}

      <MemberTagFilter
        value={filters.memberTags}
        onChange={({ target: { value } }) => onChange({ memberTags: value })}
      />

      <MemberCostMethodFilter
        value={filters.memberCostMethod}
        onChange={({ target: { value } }) => onChange({ memberCostMethod: value })}
      />

      {_.has(filters, 'memberLocations') && (
        <LocationFilter
          value={filters.memberLocations}
          placeholder="Member Location"
          onChange={({ target: { value } }) => onChange({ memberLocations: value })}
        />
      )}

      <MemberInvitationStatusFilter
        value={filters.memberInvitationStatuses}
        onChange={({ target: { value } }) => onChange({ memberInvitationStatuses: value })}
      />
    </FiltersGroup>
  );
}

export default MemberFiltersGroup;
