import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  BusinessUnitFilter,
  ClientFilter,
  ClientRecordStatusFilter,
  ClientTagFilter,
  CreateButton,
  Currency,
  DateTime,
  ExportDialog,
  ExportDropdown,
  FiltersBar,
  IndustryFilter,
  InvoiceStatusFilter,
  ListView,
  ListViewActions,
  ListViewMenu,
  LocationFilter,
  MemberFilter,
  Page,
  PeriodFilter,
  PracticeFilter,
  RouteLink,
  SearchInput,
  Tag,
  Tooltip,
} from '~/components';
import SingleSelectFilter from '~/components/filters/SingleSelectFilter';
import InvoiceSentFilter from '~/components/filters/InvoiceSentFilter.jsx';
import { useApi, useConfirmation, useIntegrations, useWorkspace } from '~/contexts';
import {
  useAuth,
  useClientFilters,
  useDocumentTitle,
  useFeatures,
  useIsMounted,
  useProjectFilters,
  useSearchParams,
  useSearchParamsConfig,
} from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort, intervalOptions, intervalsByScope, mimeTypes } from '~/utils';
import { sumBy } from '~/utils/math';
import IssueCreditNoteDrawer from '../../billing/credit-notes/dialogs/IssueCreditNoteDrawer';
import ClientFiltersGroup from '~/components/filters/ClientFiltersGroup.jsx';
import FilterButton from '~/components/filters/FilterButton.jsx';
import FiltersDrawer from '~/components/filters/FiltersDrawer.jsx';
import FiltersGroup from '~/components/filters/FiltersGroup.jsx';
import ProjectFiltersBar from '~/components/filters/ProjectFiltersBar.jsx';
import ProjectFiltersGroup from '~/components/filters/ProjectFiltersGroup.jsx';
import ReportPeriodFilter from '~/components/filters/ReportPeriodFilter.jsx';
import useReportsSearchParamsConfig from '../../reports/hooks/useReportsSearchParamsConfig.js';
import QBOIndicator from '../components/QBOIndicator';
import SentIndicator from '../components/SentIndicator';
import XeroInvoiceIndicator from '../components/XeroInvoiceIndicator';
import InvoiceWebLinkModal from '../invoice-actions/InvoiceWebLinkModal';
import SendInvoiceDrawer from '../invoice-actions/send-invoice-drawer/SendInvoiceDrawer';
import DeleteInvoiceDialog from '../invoice-dialogs/DeleteInvoiceDialog';
import LoadFromQuickBooksDialog from '../invoice-dialogs/LoadFromQuickBooksDialog';
import LoadFromXeroDialog from '../invoice-dialogs/LoadFromXeroDialog';
import PublishInvoiceDialog from '../invoice-dialogs/PublishInvoiceDialog';
import SaveToQuickBooksDialog from '../invoice-dialogs/SaveToQuickBooksDialog';
import SaveToXeroDialog from '../invoice-dialogs/SaveToXeroDialog';
import UnpublishInvoiceDialog from '../invoice-dialogs/UnpublishInvoiceDialog';
import ClientPaymentForm from '../payments/ClientPaymentForm';
import CreateClientInvoiceDialog from './CreateClientInvoiceDialog';
import InvoicesSummaryWidget from './InvoicesSummaryWidget';
import agingIntervals from './agingIntervals';

const SummarySection = styled.section`
  background: ${colors.grey5};
  padding: 1rem;
  margin: 0 -1rem;
`;

const Due = styled.span`
  white-space: nowrap;

  ${({ isLate }) =>
    isLate &&
    css`
      color: ${colors.danger};
    `}
`;

const Small = styled.small`
  display: block;
`;

function InvoicesListPage({
  projectId,
  clientId,
  defaultInvoiceStatuses = [],
  mode = 'page',
  showProjectColumn = true,
  renderFilters,
  onRowClick,
  sessionKey,
}) {
  const documentTitle = useDocumentTitle();
  if (mode === 'page') documentTitle.set('Invoices');

  const { workspace } = useWorkspace();
  const api = useApi();

  const isMounted = useIsMounted();

  const history = useHistory();
  const { url } = useRouteMatch();

  const [dialog, setDialog] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();

  const [query, setQuery] = useState({
    isReady: false,
    searchParamsStatus: 'pending',
    data: null,
    params: {
      q: '',
      invoiceStatuses: [],
      aging: null,
      sort: new QuerySort('issuedOn', 'desc'),
      page: 0,
      period: null,
      size: 50,
      invoiceSent: null,
      invoiceServicesThroughPeriod: null,
      ...clientFilters.filters,
      ...projectFilters.filters,
    },
    action: 'load',
  });

  const setParams = (params) => {
    setQuery((state) => ({
      ...state,
      action: 'filter',
      params: { ...state.params, ...params, page: 0 },
      searchParamsStatus: 'ready',
    }));
  };

  const loadMore = useCallback(() => {
    setQuery((state) => {
      if (
        state.searchParamsStatus !== 'ready' ||
        state.action !== null ||
        !state.data ||
        state.data.total <= state.data.results.length
      ) {
        return state;
      }

      return {
        ...state,
        params: { ...state.params, page: state.params.page + 1 },
        action: 'load-more',
      };
    });
  }, []);

  const removeItem = (id) => {
    setQuery((state) => ({
      ...state,
      dialog: null,
      data: {
        ...state.data,
        results: state.data?.results.filter((i) => i.id !== id),
        total: state.data.total - 1,
      },
    }));
  };

  const updateItems = (items) => {
    setQuery((state) => ({
      ...state,
      data: {
        ...state.data,
        results: state.data?.results.map((result) => {
          const item = items.find((i) => i.id === result.id);
          return item ? { ...result, ...item } : result;
        }),
      },
    }));
  };

  const { action, data, isReady, params, searchParamsStatus } = query;

  const auth = useAuth();
  const confirmation = useConfirmation();

  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        q: { default: '' },
        invoiceStatuses: { ...searchParamsConfig.invoiceStatuses, default: defaultInvoiceStatuses },
        period: {
          ...searchParamsConfig.period,
          default: intervalOptions.all_dates,
        },
        aging: {
          default: null,
          valid: ['all', ..._.keys(agingIntervals)],
          serialize: (value) => value || 'all',
          deserialize: (value) => (value === 'all' ? null : value),
        },
        sort: { default: new QuerySort('issuedOn', 'desc'), ...searchParamsConfig.sort },
        invoiceServicesThroughPeriod: reportsSearchParamsConfig.invoiceServicesThroughPeriod,
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
      }),

      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters, defaultInvoiceStatuses],
    ),
    sessionKey,

    onChange: useCallback((params) => setParams((state) => ({ ...state, ...params })), []),
  });

  const urlSearchParams = useMemo(() => {
    const { start, end } = params.period || {};
    const { start: dueOnStart, end: dueOnEnd } = agingIntervals[params.aging] || {};

    return {
      q: params.q,
      sort: params.sort,
      page: params.page,
      size: params.size,
      start: start ?? undefined,
      end: end ?? undefined,
      invoiceSent: params.invoiceSent ?? undefined,
      invoiceServicesThroughStart: params.invoiceServicesThroughPeriod?.start ?? undefined,
      invoiceServicesThroughEnd: params.invoiceServicesThroughPeriod?.end ?? undefined,
      dueOnStart: dueOnStart || undefined,
      dueOnEnd: dueOnEnd || undefined,
      invoiceStatusId: params.invoiceStatuses?.map((v) => v.id) || undefined,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
    };
  }, [params, clientFilters, projectFilters]);

  const widgetParams = useMemo(() => {
    return {
      ...urlSearchParams,
      clientId: urlSearchParams.clientId.length ? urlSearchParams.clientId : clientId,
      projectId: urlSearchParams.projectId.length ? urlSearchParams.projectId : projectId,
    };
  }, [urlSearchParams, clientId, projectId]);

  useEffect(() => {
    if (searchParamsStatus !== 'pending') return;
    searchParams.get().then((params) => {
      if (params) setParams(params);
    });
  }, [searchParams, searchParamsStatus, mode]);

  const fetchData = useCallback(async () => {
    try {
      const params = {
        ...urlSearchParams,
        clientId: urlSearchParams.clientId.length ? urlSearchParams.clientId : clientId,
        projectId: urlSearchParams.projectId.length ? urlSearchParams.projectId : projectId,
      };

      const { data } = await api.www.workspaces(workspace.id).invoices().get(params);

      if (!isMounted.current) return;

      setQuery((state) => ({
        ...state,
        action: null,
        dialog: null,
        searchParamsStatus: 'ready',
        data: {
          ...data,
          results: state.action === 'load-more' ? [...state.data.results, ...data.results] : data.results,
          total: data.total,
        },
      }));
    } catch (error) {
      setQuery((state) => ({ ...state, data: { total: 0, results: [] } }));
    }
  }, [workspace.id, api, isMounted, urlSearchParams, clientId, projectId]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const features = useFeatures();

  const integrations = useIntegrations();

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);

  const Container = useCallback(
    (props) => (mode === 'page' ? <Page scrollable {...props} /> : <React.Fragment {...props} />),
    [mode],
  );

  if (!isReady && !data) return <PageLoader />;

  const handleFilter = (value) => {
    setParams({ ...value });
    searchParams.set({ ...value });
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleRowClick = (invoice) => {
    if (onRowClick) onRowClick(invoice);
    else history.push(`${url}/${invoice.id}`);
  };

  const refreshSummary = () => {
    setRefreshKey(refreshKey + 1);
  };

  const reloadInvoices = async (...ids) => {
    const invoices = await api.www
      .workspaces(workspace.id)
      .invoices()
      .get({ ids })
      .then((res) => res.data);

    updateItems(invoices);

    refreshSummary();

    return invoices;
  };

  const handleDelete = async (invoice) => {
    await confirmation.prompt((resolve) => (
      <DeleteInvoiceDialog
        invoice={invoice}
        onClose={resolve}
        onDelete={() => {
          removeItem(invoice.id);
          refreshSummary();
          resolve();
        }}
      />
    ));
  };

  const handlePublish = async (invoice) => {
    return await confirmation.prompt((resolve) => (
      <PublishInvoiceDialog
        invoiceId={invoice.id}
        onClose={() => {
          resolve();
        }}
        resolve={async () => {
          await reloadInvoices(invoice.id);
          resolve(true);
        }}
      />
    ));
  };

  const handleUnpublish = async (invoice) => {
    await confirmation.prompt((resolve) => (
      <UnpublishInvoiceDialog
        invoice={invoice}
        resolve={async () => {
          await reloadInvoices(invoice.id);
          resolve();
        }}
      />
    ));
  };

  const handleCloseDialog = () => {
    setDialog(null);
  };

  const handleGetWebLink = (invoice) => {
    setDialog({ type: 'webLink', invoice });
  };

  const handleSend = async (invoice) => {
    const { data } = await api.www.workspaces(workspace.id).invoices(invoice.id).get();
    setDialog({ type: 'send', invoice: data });
  };

  const handlePublishAndSend = async (invoice) => {
    const isPublished = await handlePublish(invoice);
    if (isPublished) {
      await handleSend(invoice);
    }
  };

  const handlePayment = (invoice) => {
    setDialog({ type: 'payment', invoice });
  };

  const handleIssueCreditNote = (invoice) => {
    setDialog({ type: 'issueCreditNote', invoice });
  };

  const handleSaveToQuickBooks = async (invoice) => {
    await confirmation.prompt((resolve) => (
      <SaveToQuickBooksDialog
        invoice={invoice}
        resolve={async () => {
          await reloadInvoices(invoice.id);
          resolve();
        }}
      />
    ));
  };

  const handleReloadFromQuickBooks = async (invoice) => {
    await confirmation.prompt((resolve) => (
      <LoadFromQuickBooksDialog
        invoice={invoice}
        resolve={async () => {
          await reloadInvoices(invoice.id);
          resolve();
        }}
      />
    ));
  };

  const handleSaveToXero = async (invoice) => {
    await confirmation.prompt((resolve) => (
      <SaveToXeroDialog
        invoice={invoice}
        resolve={async () => {
          await reloadInvoices(invoice.id);
          resolve();
        }}
      />
    ));
  };

  const handleReloadFromXero = async (invoice) => {
    await confirmation.prompt((resolve) => (
      <LoadFromXeroDialog
        invoice={invoice}
        resolve={async () => {
          await reloadInvoices(invoice.id);
          resolve();
        }}
      />
    ));
  };

  const handlePaymentReceived = async (payment) => {
    await reloadInvoices(payment.paymentInvoices.map((pi) => pi.invoiceId));
  };

  const handleCreditNoteIssued = async (creditNote) => {
    history.push(`/app/${workspace.key}/billing/credit-notes/${creditNote.id}`);
  };

  const handleInvoiceSent = async (invoice) => {
    await reloadInvoices(invoice.id);
  };

  const handleCreateInvoice = () => {
    setDialog({ type: 'create' });
  };

  const handleExport = async (filename, mimeType) => {
    await confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .invoices()
          .export(
            {
              ...urlSearchParams,
              size: null,
            },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  const totals = {
    balance: sumBy(data.results, 'convertedBalance'),
    total: sumBy(data.results, 'convertedTotal'),
  };

  return (
    <Container>
      {mode === 'page' && (
        <>
          <Page.Header>
            <Page.Info>
              <Page.Eyebrow>Billing</Page.Eyebrow>
              <Page.Title>Invoices</Page.Title>
            </Page.Info>

            <Page.Actions>
              <ExportDropdown>
                {({ setIsOpen }) => (
                  <>
                    <ExportDropdown.Item
                      onClick={async () => {
                        await handleExport(`invoices.csv`, mimeTypes.csv);
                        setIsOpen(false);
                      }}>
                      Export to CSV
                    </ExportDropdown.Item>

                    <ExportDropdown.Item
                      onClick={async () => {
                        await handleExport(`invoices.xlsx`, mimeTypes.xlsx);
                        setIsOpen(false);
                      }}>
                      Export to Excel
                    </ExportDropdown.Item>
                  </>
                )}
              </ExportDropdown>

              <FilterButton isOutline onClick={showFilters} />

              {workspace.adHocInvoices && (
                <CreateButton
                  disabled={!auth.invoices.manage.client}
                  tooltip={
                    auth.invoices.manage.client
                      ? 'Create Invoice'
                      : 'Your security role prohibits you from creating blank invoices.'
                  }
                  onClick={handleCreateInvoice}
                />
              )}
            </Page.Actions>
          </Page.Header>
        </>
      )}

      {renderFilters ? (
        renderFilters({ query: params, handleFilter })
      ) : (
        <>
          <Page.Filters>
            <FiltersBar>
              <SearchInput
                value={params.q}
                placeholder="Search"
                onChange={({ target: { value } }) => handleFilter({ q: value })}
              />

              <ClientFilter
                name="clients"
                placeholder="Client"
                activeOnly={false}
                value={params.clients}
                onChange={({ target: { value } }) => handleFilter({ clients: value })}
              />

              <InvoiceStatusFilter
                value={params.invoiceStatuses}
                onChange={({ target: { value } }) => handleFilter({ invoiceStatuses: value })}
              />

              <PeriodFilter
                name="period"
                placeholder="Issue Date"
                intervals={[intervalOptions.all_dates, ...intervalsByScope.month]}
                value={params.period}
                onChange={({ target: { value } }) => handleFilter({ period: value })}
              />

              <AgingFilter value={params.aging} onChange={({ target: { value } }) => handleFilter({ aging: value })} />

              {features.practices && (
                <PracticeFilter
                  name="clientPractices"
                  placeholder="Client Practice"
                  value={params.clientPractices}
                  onChange={({ target: { value } }) => handleFilter({ clientPractices: value })}
                />
              )}

              <ClientFiltersBar filters={params} onChange={handleFilter} />
              <ProjectFiltersBar filters={params} onChange={handleFilter} />
              <InvoiceFiltersBar filters={params} onChange={handleFilter} />
            </FiltersBar>
          </Page.Filters>
          <Filters values={params} isOpen={filtersVisible} onApply={handleFilter} onClose={hideFilters} />
        </>
      )}

      {mode === 'page' && (
        <Page.Section sticky>
          <SummarySection>
            <InvoicesSummaryWidget key={refreshKey} params={widgetParams} />
          </SummarySection>
        </Page.Section>
      )}

      <Page.ListView>
        <ListView>
          <ListView.Header>
            <ListView.Column sticky minWidth="10rem" name="transactionNumber" onSort={handleSort} sort={params.sort}>
              #
            </ListView.Column>
            <ListView.Column
              minWidth="16rem"
              name="client.name"
              isVisible={showProjectColumn}
              onSort={handleSort}
              sort={params.sort}>
              Client
            </ListView.Column>
            <ListView.Column minWidth="16rem" isVisible={showProjectColumn}>
              Projects
            </ListView.Column>
            <ListView.Column width="10rem" name="issuedOn" onSort={handleSort} sort={params.sort}>
              Issued
            </ListView.Column>
            <ListView.Column width="10rem" name="dueOn" onSort={handleSort} sort={params.sort}>
              Due
            </ListView.Column>
            <ListView.Column width="8rem" name="actualStatusId" onSort={handleSort} sort={params.sort}>
              Status
            </ListView.Column>
            <ListView.Column width="10rem" align="right" name="convertedBalance" onSort={handleSort} sort={params.sort}>
              Balance
            </ListView.Column>
            <ListView.Column width="10rem" align="right" name="convertedTotal" onSort={handleSort} sort={params.sort}>
              Total
            </ListView.Column>
            <ListViewActions.Column isVisible={mode === 'page'} />
          </ListView.Header>
          <ListView.Body fade={action === 'filter'}>
            {data.results.map((invoice) => {
              const {
                id,
                number,
                issuedOn,
                dueOn,
                isLate,
                daysLate,
                status,
                hasPayments,
                hasCreditNotes,
                qboInvoiceId,
                xeroInvoiceId,
                sentAt,
              } = invoice;

              const receivePayment = !invoice.permissions.receivePayment
                ? {
                    disabled: true,
                    tooltip: 'Insufficient permissions to receive a payment on this invoice.',
                  }
                : !features.multicurrency && invoice.currency !== workspace.currency
                  ? { disabled: true, tooltip: 'The invoice currency is different from the workspace home currency.' }
                  : { disabled: false, tooltip: undefined };

              const issueCreditNote = !invoice.permissions.applyCredit
                ? {
                    disabled: true,
                    tooltip: 'Insufficient permissions to issue a credit note for this invoice.',
                  }
                : !features.multicurrency && invoice.currency !== workspace.currency
                  ? { disabled: true, tooltip: 'The invoice currency is different from the workspace home currency.' }
                  : { disabled: false, tooltip: undefined };

              return (
                <ListView.Row key={id} data-testid={id} onClick={() => handleRowClick(invoice)}>
                  <ListView.Cell>
                    <RouteLink to={`/app/${workspace.key}/billing/invoices/${id}`} style={{ wordBreak: 'break-word' }}>
                      {number}
                    </RouteLink>
                  </ListView.Cell>
                  <ListView.Cell>{invoice.client.name}</ListView.Cell>
                  <ListView.Cell>
                    <FirstProject invoice={invoice} />
                    <Projects invoice={invoice} />
                  </ListView.Cell>
                  <ListView.Cell>
                    <DateTime value={issuedOn} />
                    {sentAt && <SentIndicator sentAt={sentAt} />}
                  </ListView.Cell>
                  <ListView.Cell>
                    <Due isLate={isLate}>
                      <DateTime value={dueOn} />
                      {isLate && (
                        <p>
                          ({daysLate} {daysLate === 1 ? 'day' : 'days'})
                        </p>
                      )}
                    </Due>
                  </ListView.Cell>
                  <ListView.Cell>
                    {status.name}
                    {qboInvoiceId && <QBOIndicator />}
                    {xeroInvoiceId && <XeroInvoiceIndicator xeroInvoiceId={xeroInvoiceId} />}
                  </ListView.Cell>
                  <ListView.Cell>
                    <p>
                      <Currency value={invoice.convertedBalance} currency={workspace.currency} />
                      {invoice.currency !== workspace.currency && (
                        <Small>
                          <Currency value={invoice.balance} currency={invoice.currency} />
                        </Small>
                      )}
                    </p>
                  </ListView.Cell>
                  <ListView.Cell>
                    <p>
                      <Currency value={invoice.convertedTotal} currency={workspace.currency} />
                      {invoice.currency !== workspace.currency && (
                        <Small>
                          <Currency value={invoice.total} currency={invoice.currency} />
                        </Small>
                      )}
                    </p>
                  </ListView.Cell>

                  <ListViewActions isVisible={mode === 'page'}>
                    {status.id === 'draft' ? (
                      <ListViewActions.Edit
                        onClick={() => history.push(`/app/${workspace.key}/billing/invoices/${invoice.id}`)}
                      />
                    ) : (
                      <ListViewActions.View
                        onClick={() => history.push(`/app/${workspace.key}/billing/invoices/${invoice.id}`)}
                      />
                    )}

                    <hr />

                    <ListViewMenu>
                      {({ setIsOpen }) => {
                        const handleAction = async (action) => {
                          setIsOpen(false);
                          await action();
                        };

                        return {
                          draft: (
                            <>
                              <ListViewMenu.Item
                                disabled={!invoice.permissions.publish || invoice.total < 0}
                                tooltip={
                                  !invoice.permissions.publish
                                    ? 'Insufficient permissions to publish this invoice.'
                                    : invoice.total < 0
                                      ? 'Invoice total must be zero or greater.'
                                      : undefined
                                }
                                onClick={() => handleAction(() => handlePublish(invoice))}>
                                Publish
                              </ListViewMenu.Item>

                              <ListViewMenu.Item
                                disabled={!invoice.permissions.publish || invoice.total < 0}
                                tooltip={
                                  !invoice.permissions.publish
                                    ? 'Insufficient permissions to publish this invoice.'
                                    : invoice.total < 0
                                      ? 'Invoice total must be zero or greater.'
                                      : undefined
                                }
                                onClick={() => handleAction(() => handlePublishAndSend(invoice))}>
                                Publish & Send
                              </ListViewMenu.Item>

                              <ListViewMenu.Link to={`/app/${workspace.key}/billing/invoices/${invoice.id}`}>
                                Edit
                              </ListViewMenu.Link>

                              <ListViewMenu.Link
                                to={`/${workspace.key}/invoices/${invoice.id}?preview=true`}
                                target="_blank">
                                Preview
                              </ListViewMenu.Link>

                              <ListViewMenu.Item onClick={() => handleAction(() => handleGetWebLink(invoice))}>
                                Get Web Link
                              </ListViewMenu.Item>

                              <ListViewMenu.Item
                                disabled={!invoice.permissions.manage}
                                tooltip={
                                  !invoice.permissions.manage
                                    ? 'Insufficient permissions to delete this invoice.'
                                    : undefined
                                }
                                onClick={() => handleAction(() => handleDelete(invoice))}>
                                Delete
                              </ListViewMenu.Item>
                            </>
                          ),

                          open: (
                            <>
                              <ListViewMenu.Item
                                disabled={!invoice.permissions.manage}
                                tooltip={
                                  !invoice.permissions.manage
                                    ? 'Insufficient permissions to send this invoice.'
                                    : undefined
                                }
                                onClick={() => handleAction(() => handleSend(invoice))}>
                                Send
                              </ListViewMenu.Item>

                              <ListViewMenu.Item
                                data-testid="receive_payment"
                                disabled={receivePayment.disabled}
                                tooltip={receivePayment.tooltip}
                                onClick={() => handleAction(() => handlePayment(invoice))}>
                                Receive Payment
                              </ListViewMenu.Item>

                              <ListViewMenu.Item
                                disabled={issueCreditNote.disabled}
                                tooltip={issueCreditNote.tooltip}
                                onClick={() => handleAction(() => handleIssueCreditNote(invoice))}>
                                Issue Credit Note
                              </ListViewMenu.Item>

                              <ListViewMenu.Item
                                disabled={hasPayments || hasCreditNotes || !invoice.permissions.manage}
                                tooltip={
                                  !invoice.permissions.manage
                                    ? 'Insufficient permissions to unpublish this invoice.'
                                    : hasPayments
                                      ? 'An invoice with received payments cannot be unpublished.'
                                      : hasCreditNotes
                                        ? 'An invoice with applied credit notes cannot be unpublished.'
                                        : undefined
                                }
                                onClick={() => handleAction(() => handleUnpublish(invoice))}>
                                Unpublish
                              </ListViewMenu.Item>

                              <ListViewMenu.Link to={`/app/${workspace.key}/billing/invoices/${invoice.id}`}>
                                View
                              </ListViewMenu.Link>

                              <ListViewMenu.Link
                                to={`/${workspace.key}/invoices/${invoice.id}?preview=true`}
                                target="_blank">
                                Preview
                              </ListViewMenu.Link>

                              <ListViewMenu.Item onClick={() => handleAction(() => handleGetWebLink(invoice))}>
                                Get Web Link
                              </ListViewMenu.Item>

                              {integrations.qbo &&
                                (invoice.qboInvoiceId ? (
                                  <ListViewMenu.Item
                                    disabled={!invoice.permissions.manage}
                                    tooltip={
                                      !invoice.permissions.manage
                                        ? 'Insufficient permissions to reload this invoice from QuickBooks.'
                                        : undefined
                                    }
                                    onClick={() => handleAction(() => handleReloadFromQuickBooks(invoice))}>
                                    Reload from QuickBooks
                                  </ListViewMenu.Item>
                                ) : (
                                  <ListViewMenu.Item
                                    disabled={!invoice.permissions.manage}
                                    tooltip={
                                      !invoice.permissions.manage
                                        ? 'Insufficient permissions to save this invoice to QuickBooks.'
                                        : undefined
                                    }
                                    onClick={() => handleAction(() => handleSaveToQuickBooks(invoice))}>
                                    Save to QuickBooks
                                  </ListViewMenu.Item>
                                ))}

                              {!!integrations.xero &&
                                (invoice.xeroInvoiceId ? (
                                  <ListViewMenu.Item
                                    disabled={!invoice.permissions.manage}
                                    tooltip={
                                      !invoice.permissions.manage
                                        ? 'Insufficient permissions to reload this invoice from Xero.'
                                        : undefined
                                    }
                                    onClick={() => handleAction(() => handleReloadFromXero(invoice))}>
                                    Reload from Xero
                                  </ListViewMenu.Item>
                                ) : (
                                  <ListViewMenu.Item
                                    disabled={!invoice.permissions.manage}
                                    tooltip={
                                      !invoice.permissions.manage
                                        ? 'Insufficient permissions to save this invoice to Xero.'
                                        : undefined
                                    }
                                    onClick={() => handleAction(() => handleSaveToXero(invoice))}>
                                    Save to Xero
                                  </ListViewMenu.Item>
                                ))}

                              <ListViewMenu.Item
                                disabled={!invoice.permissions.manage || hasPayments || hasCreditNotes}
                                tooltip={
                                  !invoice.permissions.manage
                                    ? 'Insufficient permissions to delete this invoice'
                                    : hasPayments
                                      ? 'An invoice with received payments cannot be deleted.'
                                      : hasCreditNotes
                                        ? 'An invoice with applied credit notes cannot be deleted.'
                                        : undefined
                                }
                                onClick={() => handleAction(() => handleDelete(invoice))}>
                                Delete
                              </ListViewMenu.Item>
                            </>
                          ),

                          paid: (
                            <>
                              <ListViewMenu.Item
                                disabled={!invoice.permissions.manage}
                                tooltip={
                                  !invoice.permissions.manage
                                    ? 'Insufficient permissions to send this invoice.'
                                    : undefined
                                }
                                onClick={() => handleAction(() => handleSend(invoice))}>
                                Send
                              </ListViewMenu.Item>

                              <ListViewMenu.Item
                                disabled={hasPayments || hasCreditNotes || !invoice.permissions.manage}
                                tooltip={
                                  !invoice.permissions.manage
                                    ? 'Insufficient permissions to unpublish this invoice.'
                                    : invoice.hasPayments
                                      ? 'An invoice with received payments cannot be unpublished.'
                                      : hasCreditNotes
                                        ? 'An invoice with applied credit notes cannot be unpublished.'
                                        : undefined
                                }
                                onClick={() => handleAction(() => handleUnpublish(invoice))}>
                                Unpublish
                              </ListViewMenu.Item>

                              <ListViewMenu.Link to={`/app/${workspace.key}/billing/invoices/${invoice.id}`}>
                                View
                              </ListViewMenu.Link>

                              <ListViewMenu.Link
                                to={`/${workspace.key}/invoices/${invoice.id}?preview=true`}
                                target="_blank">
                                Preview
                              </ListViewMenu.Link>

                              <ListViewMenu.Item onClick={() => handleAction(() => handleGetWebLink(invoice))}>
                                Get Web Link
                              </ListViewMenu.Item>

                              {integrations.qbo &&
                                (invoice.qboInvoiceId ? (
                                  <ListViewMenu.Item
                                    disabled={!invoice.permissions.manage}
                                    tooltip={
                                      !invoice.permissions.manage
                                        ? 'Insufficient permissions to reload this invoice from QuickBooks.'
                                        : undefined
                                    }
                                    onClick={() => handleAction(() => handleReloadFromQuickBooks(invoice))}>
                                    Reload from QuickBooks
                                  </ListViewMenu.Item>
                                ) : (
                                  <ListViewMenu.Item
                                    disabled={!invoice.permissions.manage}
                                    tooltip={
                                      !invoice.permissions.manage
                                        ? 'Insufficient permissions to save this invoice to QuickBooks.'
                                        : undefined
                                    }
                                    onClick={() => handleAction(() => handleSaveToQuickBooks(invoice))}>
                                    Save to QuickBooks
                                  </ListViewMenu.Item>
                                ))}

                              {!!integrations.xero &&
                                (invoice.xeroInvoiceId ? (
                                  <ListViewMenu.Item
                                    disabled={!invoice.permissions.manage}
                                    tooltip={
                                      !invoice.permissions.manage
                                        ? 'Insufficient permissions to reload this invoice from Xero.'
                                        : undefined
                                    }
                                    onClick={() => handleAction(() => handleReloadFromXero(invoice))}>
                                    Reload from Xero
                                  </ListViewMenu.Item>
                                ) : (
                                  <ListViewMenu.Item
                                    disabled={!invoice.permissions.manage}
                                    tooltip={
                                      !invoice.permissions.manage
                                        ? 'Insufficient permissions to save this invoice to Xero.'
                                        : undefined
                                    }
                                    onClick={() => handleAction(() => handleSaveToXero(invoice))}>
                                    Save to Xero
                                  </ListViewMenu.Item>
                                ))}

                              <ListViewMenu.Item
                                disabled={!invoice.permissions.manage || hasPayments || hasCreditNotes}
                                tooltip={
                                  !invoice.permissions.manage
                                    ? 'Insufficient permissions to delete this invoice'
                                    : hasPayments
                                      ? 'An invoice with received payments cannot be deleted.'
                                      : hasCreditNotes
                                        ? 'An invoice with applied credit notes cannot be deleted.'
                                        : undefined
                                }
                                onClick={() => handleAction(() => handleDelete(invoice))}>
                                Delete
                              </ListViewMenu.Item>
                            </>
                          ),
                        }[status.id];
                      }}
                    </ListViewMenu>
                  </ListViewActions>
                </ListView.Row>
              );
            })}

            {data.results.length > 0 && (
              <ListView.Row style={{ fontWeight: weights.bold, marginTop: '0.5rem', fontSize: '0.875rem' }}>
                <ListView.Cell>Total</ListView.Cell>
                <ListView.Cell />
                <ListView.Cell />
                <ListView.Cell />
                <ListView.Cell />
                <ListView.Cell />
                <ListView.Cell data-testid="balance">
                  <Currency value={totals.balance} currency={workspace.currency} />
                </ListView.Cell>
                <ListView.Cell data-testid="total">
                  <Currency value={totals.total} currency={workspace.currency} />
                </ListView.Cell>
                <ListViewActions.Cell isVisible={mode === 'page'} />
              </ListView.Row>
            )}

            {data.results.length === 0 && <ListView.Empty />}

            {data.total > data.results.length && (
              <ListView.Loader key={data.results.length} onIntersecting={loadMore} />
            )}
          </ListView.Body>

          <ListView.Status total={data.total} label="Invoice" isLoading={!!action} />
        </ListView>
      </Page.ListView>

      {dialog &&
        {
          webLink: () => <InvoiceWebLinkModal invoice={dialog.invoice} onClose={handleCloseDialog} />,
          send: () => (
            <SendInvoiceDrawer invoice={dialog.invoice} onSent={handleInvoiceSent} onClose={handleCloseDialog} />
          ),
          payment: () => (
            <ClientPaymentForm
              invoiceId={dialog.invoice.id}
              onSaved={handlePaymentReceived}
              onClose={handleCloseDialog}
            />
          ),
          issueCreditNote: () => (
            <IssueCreditNoteDrawer
              invoiceId={dialog.invoice.id}
              onSaved={handleCreditNoteIssued}
              onClose={handleCloseDialog}
            />
          ),
          create: () => <CreateClientInvoiceDialog onClose={handleCloseDialog} />,
        }[dialog.type]()}
    </Container>
  );
}

function ClientFiltersBar({ filters, onChange }) {
  return (
    <>
      {!_.isEmpty(filters.clientRecordStatusId) && (
        <ClientRecordStatusFilter
          value={filters.clientRecordStatusId}
          onChange={({ value }) => onChange({ clientRecordStatusId: value })}
        />
      )}

      {!_.isEmpty(filters.clientBusinessUnits) && (
        <BusinessUnitFilter
          materialPlaceholder="Client Business Unit"
          value={filters.clientBusinessUnits}
          onChange={({ value }) => onChange({ clientBusinessUnits: value })}
        />
      )}

      {!_.isEmpty(filters.clientSalesRepresentatives) && (
        <MemberFilter
          materialPlaceholder="Client Sales Representative"
          value={filters.clientSalesRepresentatives}
          onChange={({ value }) => onChange({ clientSalesRepresentatives: value })}
        />
      )}

      {!_.isEmpty(filters.clientOwners) && (
        <MemberFilter
          materialPlaceholder="Client Relationship Owner"
          value={filters.clientOwners}
          onChange={({ value }) => onChange({ clientOwners: value })}
        />
      )}

      {!_.isEmpty(filters.clientTags) && (
        <ClientTagFilter value={filters.clientTags} onChange={({ value }) => onChange({ clientTags: value })} />
      )}

      {!_.isEmpty(filters.clientLocations) && (
        <LocationFilter
          materialPlaceholder="Client Location"
          value={filters.clientLocations}
          onChange={({ value }) => onChange({ clientLocations: value })}
        />
      )}

      {!_.isEmpty(filters.clientIndustries) && (
        <IndustryFilter
          materialPlaceholder="Client Industry"
          value={filters.clientIndustries}
          onChange={({ value }) => onChange({ clientIndustries: value })}
        />
      )}
    </>
  );
}

function InvoiceFiltersBar({ filters, onChange }) {
  return (
    <>
      {(filters.invoiceServicesThroughPeriod?.start || filters.invoiceServicesThroughPeriod?.end) && (
        <ReportPeriodFilter
          materialPlaceholder="Services Through Date Range"
          value={filters.invoiceServicesThroughPeriod}
          onChange={({ value }) => onChange({ invoiceServicesThroughPeriod: value })}
        />
      )}

      {!_.isEmpty(filters.invoiceSent) && (
        <InvoiceSentFilter
          value={filters.invoiceSent}
          onChange={({ target: { value } }) => onChange({ invoiceSent: value })}
        />
      )}
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <FiltersGroup
        label="Invoice Filters"
        filters={[
          filters.aging,
          filters.period,
          filters.invoiceServicesThroughPeriod?.start,
          filters.invoiceServicesThroughPeriod?.end,
          filters.invoiceStatuses,
          filters.invoiceSent,
        ]}>
        <PeriodFilter
          name="period"
          placeholder="Issue Date"
          intervals={[intervalOptions.all_dates, ...intervalsByScope.month]}
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />

        <AgingFilter value={filters.aging} onChange={({ target: { value } }) => handleFilter({ aging: value })} />

        <ReportPeriodFilter
          value={filters.invoiceServicesThroughPeriod}
          placeholder="Services Through Date Range"
          onChange={({ target: { value } }) => handleFilter({ invoiceServicesThroughPeriod: value })}
        />

        <InvoiceStatusFilter
          value={filters.invoiceStatuses}
          onChange={({ target: { value } }) => handleFilter({ invoiceStatuses: value })}
        />

        <InvoiceSentFilter
          value={filters.invoiceSent}
          onChange={({ target: { value } }) => handleFilter({ invoiceSent: value })}
        />
      </FiltersGroup>
    </FiltersDrawer>
  );
}

const FirstProject = ({ invoice }) => {
  const project = invoice.projects[0];
  if (!project) return null;

  return project.name;
};

const Title = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

const Projects = ({ invoice }) => {
  let projectsCount = invoice.projects.length - 1; // Remove the first project because it already shows a tag
  if (projectsCount <= 0) return null;

  return (
    <Tooltip
      message={
        <div style={{ fontSize: '1rem' }}>
          <Title>Projects</Title>

          {invoice.projects.map((projects) => (
            <Tag style={{ backgroundColor: colors.grey5 }} key={projects.id}>
              <small>{projects.name}</small>
            </Tag>
          ))}
        </div>
      }>
      <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40 }}>
        <small>+{projectsCount}</small>
      </Tag>
    </Tooltip>
  );
};

function AgingFilter(props) {
  return (
    <SingleSelectFilter
      icon="filter"
      placeholder="Aging"
      options={_.map(agingIntervals, ({ label }, key) => ({ id: key, name: label }))}
      renderValue={(value) => value.name}
      {...props}
    />
  );
}

export default InvoicesListPage;
