import React from 'react';
import styled from 'styled-components';
import Button from '~/components/Button';
import Icon from '~/components/Icon';
import InlineTooltip from '~/components/InlineTooltip';
import { colors } from '~/styles';

export const Container = styled.div`
  align-self: stretch;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 2.5rem;
  margin-left: auto;
  background: ${colors.white};
  z-index: 2;
  position: sticky;
  right: -1rem;
  padding-top: 1px;
  padding-bottom: 1px;
  background-clip: content-box;

  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    height: calc(100% + 2px);
    width: 1px;
    background: ${colors.grey10};
  }

  hr {
    width: 2.5rem;
  }

  > .button {
    flex: 1;
    padding: 0;
    background: transparent;
    color: ${colors.primary};
    border-radius: 0;
    transition: all 150ms ease-in-out;
    border-width: 0;
    position: relative;

    .icon {
      font-size: 0.75rem;
      pointer-events: none;
    }

    &:hover:not(:disabled),
    &:focus {
      border-color: ${colors.primary};
      background-color: ${colors.primary};
      color: ${colors.white};
    }
  }

  > .button {
    &:first-child {
      margin-top: -1px;
      padding-top: 1px;
    }

    &:last-child {
      margin-bottom: -1px;
      padding-bottom: 1px;
    }
  }
`;

const ListViewActions = ({ isVisible = true, ...props }) => {
  if (!isVisible) return null;
  return <Container onClick={(e) => e.stopPropagation()} {...props} />;
};

const Column = styled.div`
  width: 2.5rem;
  background: ${colors.grey5};
  margin-left: auto;
  position: sticky;
  right: -1rem;
`;

const Cell = styled(Column)`
  align-self: stretch;
  background: ${colors.white};
`;

function ListViewActionsColumn({ isVisible = true, ...props }) {
  if (!isVisible) return null;
  return <Column {...props} />;
}

function ListViewActionsCell({ isVisible = true, ...props }) {
  if (!isVisible) return null;
  return <Cell {...props} />;
}

function Edit({ tooltip, ...props }) {
  return (
    <Button {...props} data-testid="edit_button">
      <Icon icon="pencil-alt" />
      {tooltip && <InlineTooltip message={tooltip} placement="left" />}
    </Button>
  );
}

function View({ tooltip, ...props }) {
  return (
    <Button {...props}>
      <Icon icon="eye" />
      {tooltip && <InlineTooltip message={tooltip} placement="left" />}
    </Button>
  );
}

export const DeleteButton = styled(Button)`
  &&& {
    color: ${colors.danger};

    &:not(:disabled) {
      &:hover,
      &:focus {
        color: ${colors.white};
        border-color: ${colors.danger};
        background-color: ${colors.danger};
      }
    }
  }
`;

function Delete({ tooltip, icon = 'trash', ...props }) {
  return (
    <DeleteButton {...props}>
      <Icon icon={icon} />
      {tooltip && <InlineTooltip message={tooltip} placement="left" />}
    </DeleteButton>
  );
}

ListViewActions.Column = ListViewActionsColumn;
ListViewActions.Cell = ListViewActionsCell;
ListViewActions.Edit = Edit;
ListViewActions.View = View;
ListViewActions.Delete = Delete;

export default ListViewActions;
