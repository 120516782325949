import React, { useCallback, useEffect, useState } from 'react';
import { AutoComplete, Dropdown } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';

function ClientContactSelect({ value, clientId, onChange }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);

  const handleSearch = useCallback(
    async (q) => {
      const { data } = await api.www
        .workspaces(workspace.id)
        .contacts()
        .get({ q, isActive: true, clientIds: [clientId] });

      return data;
    },
    [workspace.id, clientId, api],
  );

  useEffect(() => {
    (async () => {
      const data = await handleSearch('');
      if (!isMounted.current) return;
      setDefaultOptions(data);
    })();
  }, [handleSearch, isMounted]);

  const handleChange = (event) => {
    const changeArgument = { target: { value: event.target.value } };
    onChange(changeArgument);
  };

  return (
    <AutoComplete
      onSearch={handleSearch}
      value={value}
      displayText={value ? value.name : ''}
      defaultOptions={defaultOptions}
      renderOption={(option) => option.name}
      onChange={handleChange}
      placeholder="Contact">
      {({ options }) => {
        let index = 0;

        return options.length > 0 ? (
          options.map((o) => {
            return (
              <AutoComplete.Option padding="0.25rem 0 0.25rem 2rem" key={o.id} value={o} index={index++}>
                {o.name}
              </AutoComplete.Option>
            );
          })
        ) : (
          <Dropdown.Text>None</Dropdown.Text>
        );
      }}
    </AutoComplete>
  );
}

export default ClientContactSelect;
