import Fuse from 'fuse.js';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, Icon, Page, SearchInput } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useDocumentTitle, useSearchParams, useSessionStorage } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';
import NavBox from './components/NavBox';
import NavSection from './components/NavSection';
import useExpenseReports from './expenses/useExpenseReports';
import useAccountingReports from './financial/useAccountingReports';
import usePerformanceReports from './financial/usePerformanceReports';
import useForecastReports from './forecast/useForecastReports';
import usePlanReports from './forecast/usePlanReports';
import useVarianceReports from './forecast/useVarianceReports';
import usePipelineReports from './pipeline/usePipelineReports';
import useTimeReports from './time/useTimeReports';
import useUtilizationReports from './utilization/useUtilizationReports';
import useWorkspaceReports from './workspace/useWorkspaceReports';

const AccordionButton = styled(Button)`
  font-size: 0.75rem;
  color: ${colors.grey55};
  opacity: 0.5;

  &:hover {
    color: ${colors.grey55};
    opacity: 1;
  }
`;

const Search = styled.div`
  flex: 1;
  .icon {
    margin-right: 0.5rem;
  }
`;

const NoResults = styled.div`
  color: ${colors.grey40};
  font-size: 0.875rem;
  margin-top: 1.5rem;
`;

export default function SavedReportsPage() {
  useDocumentTitle('Saved Reports');

  const api = useApi();
  const { workspace } = useWorkspace();

  const [savedReports, setSavedReports] = useState(null);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().saved.get();
    setSavedReports(data.map((r) => ({ ...r, path: `/saved/${r.id}` })));
  }, [api, workspace.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const sessionStorage = useSessionStorage({ key: 'saved_reports__sections' });

  const [sections, setSections] = useState(() => {
    const sections = {
      my: true,
      shared: true,
    };
    try {
      if (sessionStorage.hasValue()) {
        const value = JSON.parse(sessionStorage.get());
        if (value) {
          sections.my = !!value.my;
          sections.shared = !!value.shared;
        }
      }
    } finally {
      sessionStorage.set(JSON.stringify(sections));
    }
    return sections;
  });

  const all = Object.keys(sections).every((section) => sections[section]);

  const toggleSection = (section) => {
    const value = { ...sections, [section]: !sections[section] };
    setSections(value);
    sessionStorage.set(JSON.stringify(value));
  };

  const toggleAllSections = () => {
    const value = {
      my: !all,
      shared: !all,
    };

    setSections(value);
    sessionStorage.set(JSON.stringify(value));
  };

  const [params, setParams] = useState(() => ({ q: '' }));
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(() => ({ q: { default: '' } }), []),
    sessionKey: 'saved_reports__params',
    onChange: useCallback((params) => setParams((state) => ({ ...state, ...params })), []),
  });
  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      if (params) {
        setParams((state) => ({ ...state, ...params }));
        setSearchParamsStatus('ready');
      }
    });
  }, [searchParams, searchParamsStatus]);

  const timeReports = useTimeReports();
  const expenseReports = useExpenseReports();
  const utilizationReports = useUtilizationReports();
  const planReports = usePlanReports();
  const forecastReports = useForecastReports();
  const varianceReports = useVarianceReports();
  const accountingReports = useAccountingReports();
  const performanceReports = usePerformanceReports();
  const pipelineReports = usePipelineReports();
  const workspaceReports = useWorkspaceReports();

  const config = useMemo(() => {
    if (!savedReports) return null;

    const libraryReports = [
      ...timeReports,
      ...expenseReports,
      ...utilizationReports,
      ...planReports,
      ...forecastReports,
      ...varianceReports,
      ...accountingReports,
      ...performanceReports,
      ...pipelineReports,
      ...workspaceReports,
    ];

    const availableLibraryReportKeys = new Set(libraryReports.map((report) => report.key));
    const availableReports = savedReports.filter((report) => availableLibraryReportKeys.has(report.reportKey));
    const myReports = availableReports.filter((report) => !report.isShared);
    const sharedReports = availableReports.filter((report) => report.isShared);

    const filter = (reports) => {
      let results = _.orderBy(reports, ['name'], ['asc']);

      if (params.q) {
        const fuse = new Fuse(reports, {
          keys: ['name', 'description'],
          threshold: 0.1,
          ignoreLocation: true,
        });

        results = fuse.search(params.q).map(({ item }) => item);
      }

      return results;
    };

    const sections = [
      { id: 'my', title: 'My Reports', reports: filter(myReports ?? []) },
      { id: 'shared', title: 'Shared Reports', reports: filter(sharedReports ?? []) },
    ];

    return sections.filter(({ reports }) => reports.length);
  }, [
    savedReports,
    params.q,
    timeReports,
    expenseReports,
    utilizationReports,
    planReports,
    forecastReports,
    varianceReports,
    accountingReports,
    performanceReports,
    pipelineReports,
    workspaceReports,
  ]);

  const handleFilter = ({ target: { name, value } }) => {
    setParams((params) => ({ ...params, [name]: value }));
    searchParams.set({ [name]: value });
  };

  if (!savedReports) return <PageLoader />;

  return (
    <Page>
      <Page.Header>
        <Page.Info>
          <Page.Eyebrow>Reports</Page.Eyebrow>
          <Page.Title>
            Saved{' '}
            <AccordionButton isAnchor onClick={() => toggleAllSections()}>
              <Icon icon={all ? 'angle-double-down' : 'angle-double-up'} />
            </AccordionButton>
          </Page.Title>
        </Page.Info>
      </Page.Header>

      <Page.Filters>
        <Search>
          <SearchInput name="q" value={params.q} onChange={handleFilter} placeholder="Search" autoFocus />
        </Search>
      </Page.Filters>

      {searchParamsStatus === 'pending' ? (
        <PageLoader />
      ) : (
        <>
          {config.map((section) => (
            <NavSection
              key={section.id}
              title={section.title}
              expanded={sections[section.id]}
              onToggle={() => toggleSection(section.id)}>
              {_.map(section.reports, (report) => (
                <NavBox
                  key={report.path}
                  to={`/app/${workspace.key}/reports${report.path}`}
                  reportKey={report.key}
                  title={report.name}
                  description={report.description}
                />
              ))}
            </NavSection>
          ))}

          {!config.length && <NoResults>No results found.</NoResults>}
        </>
      )}
    </Page>
  );
}
