import { useMemo } from 'react';
import { useSearchParamsConfig } from '~/hooks';
import intervalOptions from '~/utils/intervalOptions';

// This hook is being thined-out to only include the period options that are used in the reports.
// It will eventually be removed in favor of useSearchParamsConfig.
export default function useReportsSearchParamsConfig() {
  const searchParamsConfig = useSearchParamsConfig();

  const config = useMemo(
    () => ({
      period: {
        ...searchParamsConfig.period,
        default: intervalOptions.this_month_to_date,
        keys: ['period', 'start', 'end'],
      },

      completedPeriod: {
        ...searchParamsConfig.period,
        default: intervalOptions.all_dates,
        keys: ['completedOnPeriod', 'completedOnFrom', 'completedOnTo'],
      },

      createdPeriod: {
        ...searchParamsConfig.period,
        default: intervalOptions.all_dates,
        keys: ['createdPeriod', 'createdStart', 'createdEnd'],
      },

      closedPeriod: {
        ...searchParamsConfig.period,
        default: intervalOptions.all_dates,
        keys: ['closedPeriod', 'closedStart', 'closedEnd'],
      },

      modifiedPeriod: {
        ...searchParamsConfig.period,
        default: intervalOptions.all_dates,
        keys: ['modifiedPeriod', 'modifiedStart', 'modifiedEnd'],
      },

      memberActivePeriod: {
        ...searchParamsConfig.period,
        default: intervalOptions.all_dates,
        keys: ['memberActivePeriod', 'memberActiveStart', 'memberActiveEnd'],
      },

      invoiceServicesThroughPeriod: {
        ...searchParamsConfig.period,
        default: intervalOptions.all_dates,
        keys: ['invoiceServicesThroughPeriod', 'invoiceServicesThroughStart', 'invoiceServicesThroughEnd'],
      },

      startPeriod: {
        default: intervalOptions.all_dates,
        keys: ['startPeriod', 'startFrom', 'startTo'],
        serialize: (value) => ({ startFrom: value.start ?? null, startTo: value.end ?? null }),
      },

      endPeriod: {
        ...searchParamsConfig.period,
        default: intervalOptions.all_dates,
        keys: ['endPeriod', 'endFrom', 'endTo'],
      },
    }),
    [searchParamsConfig],
  );

  return config;
}
