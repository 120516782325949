import _ from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon } from '~/components';
import { colors, weights } from '~/styles';

const Title = styled.h4`
  font-size: 0.875rem;
  font-weight: ${weights.bold};
  color: ${colors.primary};
`;

const CollapseButton = styled.div`
  margin-left: auto;
  align-self: center;
  width: 2rem;
  height: 2rem;
  border-radius: 999rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.grey40};
  font-size: 0.75rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  &:hover {
    ${CollapseButton} {
      color: ${colors.grey55};
    }
  }
`;

const Group = styled.div``;

const Filters = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  & input {
    height: 2rem;
    font-size: 0.75rem;
  }
`;

export default function FiltersGroup({ label, filters, children }) {
  const [expanded, setExpanded] = useState(() => _.some(filters, (filter) => !_.isEmpty(filter)));

  return (
    <Group>
      <Header
        onClick={() => {
          setExpanded(expanded ? false : true);
        }}>
        <Title>{label}</Title>
        <CollapseButton>
          <Icon icon={expanded ? 'chevrons-up' : 'chevrons-down'}></Icon>
        </CollapseButton>
      </Header>

      {expanded && <Filters>{children}</Filters>}
    </Group>
  );
}
