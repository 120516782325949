import _ from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  FiltersBar,
  Icon,
  MultilineText,
  Page,
  Panel,
  PipelineActivityTypeFilter,
  SearchInput,
  Stack,
} from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useNumberFormat } from '~/hooks';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';
import { ActivityItem } from '../components/ActivityItem';

const Tag = styled(Link)`
  border-radius: 0.3125rem;
  display: flex;
  align-items: center;
  color: black;
  margin-bottom: 0.2rem;
`;

const GroupHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  background-color: ${colors.grey5};
  color: ${colors.grey75};
  width: 100%;
  margin-bottom: 1rem;
  cursor: pointer;
  &:hover {
    background-color: ${colors.grey25};
  }
`;

const NoResults = styled.div`
  color: ${colors.grey40};
`;

function Assignments({ assignments }) {
  const { workspace } = useWorkspace();

  const assignmentsByType = assignments.reduce((acc, assignment) => {
    acc[assignment.type] = acc[assignment.type] || [];
    acc[assignment.type].push(assignment);
    acc[assignment.type] = _.orderBy(acc[assignment.type], ['name'], ['asc']);

    return acc;
  }, {});

  return _.map(assignmentsByType, (assignments, type) => (
    <Panel.Info title={pluralize(type)} key={type}>
      {assignments.map((assignment) => {
        const { clientId, companyId, opportunityId, id } = assignment;
        const getRoute = (type) => {
          switch (type) {
            case 'client':
              return `clients/${clientId}/overview`;
            case 'company':
              return `pipeline/companies/${companyId}/overview`;
            case 'opportunity':
              return `pipeline/opportunities/${opportunityId}/overview`;
          }
        };

        return (
          <Tag to={`/app/${workspace.key}/${getRoute(type)}`} key={id}>
            <p>{assignment.name}</p>
          </Tag>
        );
      })}
    </Panel.Info>
  ));
}

function ContactDetailsPage({ contact }) {
  const { workspace } = useWorkspace();
  const api = useApi();

  const [groupedOptions, setGroupedOptions] = useState([]);
  const [showActivities, setShowActivities] = useState({ upcoming: true, last30Days: true, olderThan30Days: true });
  const [status, setStatus] = useState('loading');
  const [params, setParams] = useState({ q: '', typeIds: [] });

  const fetchData = useCallback(async () => {
    try {
      const { data: activities } = await api.www
        .workspaces(workspace.id)
        .pipelineActivities()
        .get({
          contactId: contact.id,
          q: params.q,
          typeId: params.typeIds.length ? params.typeIds.map((v) => v.id) : [],
        });
      const groups =
        activities &&
        activities.reduce((a, v) => {
          let activityDate = moment(v.date);
          let now = moment();
          let thirtyDaysAgo = moment().subtract(30, 'days');
          let key;

          if (activityDate.isAfter(now)) {
            key = 'upcoming';
          } else if (activityDate.isAfter(thirtyDaysAgo)) {
            key = 'last30Days';
          } else {
            key = 'olderThan30Days';
          }

          a[key] = a[key] || { key, activities: [] };
          a[key].activities.push(v);
          return a;
        }, {});

      const groupOrder = ['upcoming', 'last30Days', 'olderThan30Days'];
      const orderedGroups = _.orderBy(groups, (group) => groupOrder.indexOf(group.key));

      const options = [];
      for (const group of orderedGroups) {
        options.push({ type: 'group', group, count: group.activities.length });
        group.activities.forEach((activity) => {
          options.push({ type: 'activity', activity, group });
        });
      }

      setGroupedOptions(options);
      setStatus('ready');
    } catch (error) {
      setGroupedOptions(null);
    }
  }, [api, contact, params.q, params.typeIds, workspace.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = ({ target: { name, value } }) => {
    setStatus('filtering');
    setParams((params) => ({ ...params, [name]: value }));
  };

  const toggleShowActivities = (key) => {
    setShowActivities((current) => ({
      ...current,
      [key]: !current[key],
    }));
  };

  const numberFormat = useNumberFormat({ minimumFractionDigits: 0, maximumFractionDigits: 0 });

  if (!contact) return <ErrorPage.NotFound />;

  return (
    <Page.Content>
      <Page.Main>
        <Page.TabHeader>
          <Page.Title>Activities</Page.Title>
        </Page.TabHeader>

        <Page.Filters>
          <FiltersBar>
            <SearchInput value={params.q} placeholder="Search" onChange={handleChange} />

            <PipelineActivityTypeFilter
              name="typeIds"
              value={params.typeIds}
              entity="contact"
              onChange={handleChange}
            />
          </FiltersBar>
        </Page.Filters>

        <Page.Section>
          <Stack style={{ opacity: `${status === 'filtering' ? 0.2 : 1}` }}>
            {status === 'loading' && <PageLoader />}
            {groupedOptions?.length > 0 &&
              groupedOptions.map((o) => {
                switch (o.type) {
                  case 'group':
                    return (
                      <GroupHeader key={o.group.key} onClick={() => toggleShowActivities(o.group.key)}>
                        <div style={{ textTransform: 'uppercase' }}>
                          <strong>
                            {
                              {
                                upcoming: 'Upcoming',
                                last30Days: 'Last 30 Days',
                                olderThan30Days: ' Older Than 30 Days',
                              }[o.group.key]
                            }
                          </strong>
                          <Icon icon={showActivities[o.group.key] ? 'angle-up' : 'angle-down'} spaceLeft />
                        </div>

                        <div style={{ fontSize: '0.8rem' }}>
                          {pluralize('Activity', parseInt(numberFormat.format(o.count), 10), true)}
                        </div>
                      </GroupHeader>
                    );
                  case 'activity': {
                    const item = o.activity;
                    const participants = [
                      ...item.members.map((activityMember) => activityMember.member.name),
                      ...item.contacts.map((activityContact) => activityContact.contact.name),
                    ];

                    if (!showActivities[o.group.key]) return null;

                    return <ActivityItem key={item.id} item={item} participants={participants} entity="contact" />;
                  }
                }
              })}
            {status === 'ready' && groupedOptions?.length === 0 && <NoResults>No Results</NoResults>}
          </Stack>
        </Page.Section>
      </Page.Main>

      <Panel>
        <Panel.Title>Contact Details</Panel.Title>
        <Panel.Info title="First Name">{contact.firstName}</Panel.Info>
        <Panel.Info title="Last Name">{contact.lastName}</Panel.Info>
        <Panel.Info title="Email">{contact.email}</Panel.Info>
        <Assignments assignments={contact.assignments} />
        {contact.owner && (
          <Panel.Info title="Relationship Owner">
            <Panel.Member member={contact.owner} />
          </Panel.Info>
        )}
        {contact.mobilePhone && <Panel.Info title="Mobile Phone">{contact.mobilePhone}</Panel.Info>}
        {contact.officePhone && <Panel.Info title="Office Phone">{contact.officePhone}</Panel.Info>}
        {contact.fax && <Panel.Info title="Fax">{contact.fax}</Panel.Info>}
        {contact.title && <Panel.Info title="Title">{contact.title}</Panel.Info>}
        {contact.description && (
          <Panel.Info title="Description">
            <MultilineText value={contact.description} chars={280} buttonProps={{ isAnchor: true }} />
          </Panel.Info>
        )}
        {contact.websiteUrl && (
          <Panel.Info title="Website">
            <a href={contact.websiteUrl}>{contact.websiteUrl}</a>
          </Panel.Info>
        )}
      </Panel>
    </Page.Content>
  );
}
export default ContactDetailsPage;
export { ContactDetailsPage };
