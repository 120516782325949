import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { DateTime, Icon } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { colors, weights } from '~/styles';
import SyncStatusBar from './SyncStatusBar';

const REFRESH_IMPORT_STATUS_SECONDS = 2;

const IndicatorIcon = styled(Icon)`
  width: 0.675rem;
  margin: 0.25rem 0.5rem 0 0.25rem;
`;

const IntegrationTable = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.grey10};
`;

const Attribute = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
`;

const Value = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0.75rem;
  font-weight: ${weights.bold};
`;

const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: ${weights.light};
`;

export default function ConnectionInformation({ integration, refreshDate }) {
  const api = useApi();
  const toast = useToast();
  const { workspace } = useWorkspace();
  const [sync, setSync] = useState(null);

  const fetchSync = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).hubspot.getSyncs();

      const manualSyncs = _.filter(data, { typeId: 'manual' });
      const sortedSyncs = _.sortBy(manualSyncs, 'createdAt');
      const lastSync = sortedSyncs[sortedSyncs.length - 1] || null;
      setSync(lastSync);
    } catch (error) {
      toast.error('There was an error fetching the sync status');
    }
  }, [api, toast, workspace]);

  useEffect(() => {
    fetchSync();
    return fetchSync.cancel;
  }, [fetchSync, refreshDate]);

  useEffect(() => {
    let interval = null;
    if (sync?.isRunning) {
      interval = setInterval(fetchSync, REFRESH_IMPORT_STATUS_SECONDS * 1000);
    }
    return () => {
      if (interval) {
        clearTimeout(interval);
      }
    };
  }, [sync?.isRunning, fetchSync]);

  return (
    <>
      <Title>Connection Information</Title>
      <small>{integration.info.instanceUrl}</small>
      <IntegrationTable>
        <Row>
          <Attribute>Connection Status</Attribute>
          <Value>
            {integration.isConnected ? (
              <>
                <IndicatorIcon icon="circle" color={colors.green} />
                Active
              </>
            ) : (
              <>
                <IndicatorIcon icon="circle" color={colors.red} />
                Disconnected
              </>
            )}
          </Value>
        </Row>
        <Row>
          <Attribute>Connection Established</Attribute>
          <Value>
            <DateTime value={integration.info.tokenCreatedAt} format="l LT" />
          </Value>
        </Row>
        <Row>
          <SyncStatusBar sync={sync} />
        </Row>
      </IntegrationTable>
    </>
  );
}
