import { Currency, Icon, Percent } from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { colors } from '~/styles';
import getGradientColor from './components/gradient';
import CreateClientAndProjectDialog from './CreateClientAndProjectDialog';
import opportunityStageCategories from '~/lookups/opportunity-stage-categories';
import _ from 'lodash';

const Selector = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex: 1;
  width: 15rem;
  padding: 0.25rem 0.5rem;

  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};

  &:hover {
    background-color: ${colors.grey5};
    border-radius: 0.5rem;

    color: ${colors.black};

    .complete-indicator {
      color: ${({ isSelected }) => (isSelected ? colors.success : colors.grey75)};
    }

    .subText {
      color: ${colors.grey75};
    }
  }

  .color-indicator {
    font-size: 0.625rem;
    margin-bottom: 1rem;

    color: ${({ status, probability }) => {
      if (status === 'open') {
        // Get interpolated color based on probability
        return getGradientColor(probability);
      } else {
        // Use predefined colors for other statuses
        return (
          {
            won: colors.success,
            lost: colors.danger,
          }[status] || colors.warning0
        ); // Fallback to 'warning0' if status is not recognized
      }
    }};
  }

  .complete-indicator {
    margin-left: 1rem;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
    color: ${({ isSelected, isGreen }) => (isSelected || isGreen ? colors.success : colors.grey10)};
    font-size: 0.75rem;
  }

  .middle-content {
    flex-grow: 1; /* This will make the div take up any remaining space */
    padding: 0 15px; /* This adds some padding so text doesn't touch the icons, adjust as needed */
  }
`;

const SubText = styled.div`
  font-size: 0.75rem;
  color: ${colors.grey40};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.3125rem;
  border: solid 1px ${colors.grey10};
  background-color: ${colors.white};
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
  position: relative;
  transition: opacity 50ms ease-out;
  z-index: 300;

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: calc(50% - 0.875rem);
    border: solid 0.875rem transparent;
    border-bottom-color: ${colors.white};
  }
`;

const Content = styled.div`
  padding: 1.25rem 0.5rem;
  width: 100%;
`;

function OpportunityStagesPopover({ opportunity, onStageChange, ...props }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const toast = useToast();
  const hovering = useRef(false);
  const forceVisible = useRef(false);
  const confirmation = useConfirmation();

  const [query, setQuery] = useState({ isReady: false, data: null });
  const [visible, setVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10], // The second value is to set the vertical spacing on the popover.
        },
      },
    ],
  });

  useEffect(() => {
    if (!visible || query.isReady) return;

    (async () => {
      try {
        const hubspotPipelineId = opportunity.opportunityStage?.hubspotStage?.pipelineId;

        const { data } = await api.www
          .workspaces(workspace.id)
          .opportunityStages()
          .get({
            isActive: true,
            include: opportunity ? opportunity.opportunityStageId : undefined,
            hubspotPipelineId,
          });

        setQuery({ isReady: true, data: data });
      } catch {
        // Do nothing
      }
    })();
  }, [api, workspace.id, visible, opportunity, query.isReady]);

  const selectStatus = async ({ opportunityStageId, stageStatusId }) => {
    try {
      await api.www.workspaces(workspace.id).opportunities(opportunity.id).setStage({ opportunityStageId });
      if (stageStatusId === 'won' && !opportunity.project) {
        confirmation.prompt((resolve) => (
          <CreateClientAndProjectDialog opportunity={opportunity} onClose={resolve} onSaved={onStageChange} />
        ));
      }
      onStageChange();
      setVisible(false);
      toast.success('Opportunity stage has been successfully updated.');
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const handleMouseEnter = () => {
    hovering.current = true;
    // If the query has been executed, delay showing the popover.
    // Otherwise, the delay will be caused by the API query.
    const delay = query.isReady ? 100 : 0;

    setTimeout(() => {
      if (!hovering.current) return;
      setVisible(true);
    }, delay);
  };

  const handleMouseLeave = () => {
    hovering.current = false;

    setTimeout(() => {
      if (!forceVisible.current) setVisible(false);
    }, 200);
  };

  const handleCardMouseEnter = () => {
    forceVisible.current = true;
  };

  const handleCardMouseLeave = () => {
    forceVisible.current = false;
    setVisible(false);
  };

  const sortedStages = useMemo(() => {
    const statusOrder = { open: 1, won: 2, lost: 3 };

    return _.orderBy(
      query.data,
      [
        // Map status to numeric order
        (stage) => statusOrder[stage.statusId],
        'probability',
        'name',
      ],
      ['asc', 'asc', 'asc'],
    );
  }, [query.data]);

  // Find the index of the selected stage
  const selectedIndex = useMemo(() => {
    return _.findIndex(sortedStages, { id: opportunity.opportunityStageId });
  }, [sortedStages, opportunity.opportunityStageId]);

  return (
    <>
      <div ref={setReferenceElement} {...props} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
      {visible &&
        query.isReady &&
        ReactDOM.createPortal(
          <Container
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            onMouseEnter={handleCardMouseEnter}
            onMouseLeave={handleCardMouseLeave}>
            <Content style={{ width: '16rem' }}>
              {sortedStages.map((stage, index) => {
                return (
                  <Selector
                    key={stage.id}
                    isSelected={index === selectedIndex}
                    isGreen={index < selectedIndex}
                    status={stage.statusId}
                    probability={stage.probability}
                    isClickable={!opportunity.salesforceOpportunity?.salesforceId}
                    onClick={() => {
                      if (opportunity.salesforceOpportunity?.salesforceId) return null;
                      if (opportunity.hubspotOpportunity?.hubspotId) return null;
                      return stage.id !== opportunity.opportunityStageId
                        ? selectStatus({ opportunityStageId: stage.id, stageStatusId: stage.statusId })
                        : null;
                    }}>
                    <Icon icon="circle" className="color-indicator" />
                    <div className="middle-content">
                      {stage.name}

                      {stage.statusId && (
                        <SubText status={stage.statusId} probability={stage.probability}>
                          <div>
                            {opportunityStageCategories[stage.statusId].name}&nbsp;|&nbsp;
                            <Percent value={stage.probability / 100} minimumFractionDigits={0} />
                            &nbsp;|&nbsp;
                            <Currency
                              value={(opportunity.amount * stage.probability) / 100}
                              currency={opportunity.currency}
                              minimumFractionDigits={0}
                              maximumFractionDigits={2}
                            />
                          </div>
                        </SubText>
                      )}
                    </div>
                    <Icon icon="check" className="complete-indicator" />
                  </Selector>
                );
              })}
            </Content>
          </Container>,
          document.body,
        )}
    </>
  );
}

export default OpportunityStagesPopover;
