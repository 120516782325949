import { useApi, useWorkspace } from '~/contexts';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import InternalClientTooltip from '../InternalClientTooltip';
import Fuse from 'fuse.js';
import { SingleSelectFilter } from '.';

const SingleProjectFilter = ({ placeholder = 'Project', name, value, activeOnly, permission, onChange }) => {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [data, setData] = useState(null);
  const [options, setOptions] = useState([]);

  const handleSearch = useCallback(
    async (q) => {
      let options = data;
      if (!data) {
        const result = await api.www
          .workspaces(workspace.id)
          .projects()
          .get({
            q,
            recordStatusId: activeOnly ? 'active' : undefined,
            size: 1000,
            permission,
          });
        setData(result.data);
        options = result.data;
      }
      if (q) {
        options = new Fuse(options, { keys: ['name'], threshold: 0.4 }).search(q).map((result) => result.item);
      }
      setOptions(options);
    },
    [workspace.id, api, data, activeOnly, permission],
  );

  const handleChange = (event) => {
    const changeArgument = { target: { name: event.target.name, value: projectsById[event.target.value] } };
    onChange(changeArgument);
  };

  const projectsById = useMemo(() => _.keyBy(options, 'id'), [options]);

  const groups = useMemo(() => {
    if (!options) return null;
    return _(options)
      .orderBy('client.name')
      .orderBy((o) => (o.active ? 0 : 1))
      .groupBy((o) => (o.recordStatusId === 'active' ? 'Active' : 'Archived'))
      .map((projects, status) => ({
        status,
        clients: _(projects)
          .groupBy('client.id')
          .map((clientProjects) => ({
            client: clientProjects[0].client,
            projects: _.orderBy(clientProjects, (o) => (o.active ? 0 : 1)),
          }))
          .value(),
      }))
      .value();
  }, [options]);

  return (
    <SingleSelectFilter
      icon="gear"
      placeholder={placeholder}
      name={name}
      value={value}
      renderValue={(value) => value.name}
      options={options}
      renderOptions={(opts) => {
        const optionsById = _.keyBy(opts, 'id');
        return groups.map(({ status, clients }) => (
          <SingleSelectFilter.Group key={status} label={status}>
            {clients.map(({ client, projects }) => (
              <SingleSelectFilter.Group
                key={client.id}
                label={
                  <div style={{ display: 'flex' }}>
                    {client.name} {client.isInternal && <InternalClientTooltip />}
                  </div>
                }>
                {projects.map((option) => (
                  <SingleSelectFilter.Option
                    key={option.id}
                    inactive={!option.active}
                    style={{ display: 'flex' }}
                    {...optionsById[option.id].props}>
                    <div>{option.name}</div>
                  </SingleSelectFilter.Option>
                ))}
              </SingleSelectFilter.Group>
            ))}
          </SingleSelectFilter.Group>
        ));
      }}
      isLoading={!data}
      onSearch={handleSearch}
      onChange={handleChange}
    />
  );
};

export default SingleProjectFilter;
