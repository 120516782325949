import { PeriodFilter } from '~/components';
import React from 'react';
import { intervalOptions } from '~/utils';
import intervalsByScope from '~/utils/intervalsByScope';

function ReportPeriodFilter({ clearable = true, placeholder = 'Date Range', scope = 'day', ...props }) {
  let intervals = intervalsByScope[scope];

  if (clearable) intervals = [intervalOptions.all_dates].concat(intervals);

  return (
    <PeriodFilter intervals={intervals} clearable={clearable} placeholder={placeholder} scope={scope} {...props} />
  );
}

export default ReportPeriodFilter;
