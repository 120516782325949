import Fuse from 'fuse.js';
import React, { useMemo, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Icon, Page, SearchInput } from '~/components';
import { useDocumentTitle, useFeatures, useSessionStorage } from '~/hooks';
import { colors, weights } from '~/styles';

const AccordionButton = styled(Button)`
  font-size: 1rem;
  color: ${colors.grey55};
  opacity: 0.5;

  &:hover {
    color: ${colors.grey55};
    opacity: 1;
  }
`;

const NavBox = styled(Link)`
  display: flex;
  flex-direction: column;
  padding: 1rem 1.25rem;
  color: ${colors.black};
  border: solid 1px ${colors.grey10};
  border-radius: 0.3125rem;
  background-color: ${colors.white};
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${colors.black};
    border-color: transparent;
    box-shadow: 0 0.1875rem 1rem ${colors.grey10};
  }
`;

const Section = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1rem;
`;

const SectionHeader = styled.div`
  padding: 1.25rem 1rem 1.25rem 1.25rem;
  border-top: solid 1px ${colors.grey10};

  &:first-child {
    border-top: none;
  }

  h2 {
    cursor: pointer;

    color: ${colors.grey40};
    font-size: 0.75rem;
    font-weight: ${weights.black};
    letter-spacing: 0.0625rem;
    text-transform: uppercase;

    &:hover {
      color: ${colors.grey55};
    }
  }
  small {
    font-size: 0.75rem;
    font-style: italic;
    color: ${colors.grey75};
    font-weight: ${weights.normal};
    text-transform: none;
  }
`;

const Title = styled.div`
  font-weight: ${weights.bold};
`;

const Description = styled.div`
  padding-top: 0.25rem;
  font-size: 0.875rem;
`;

const Search = styled.div`
  flex: 1;
  .icon {
    margin-right: 0.5rem;
  }
`;

function CustomDataSection({ title, subtitle, expanded, children, onToggle, ...props }) {
  return (
    <>
      <SectionHeader {...props}>
        <h2 onClick={onToggle}>
          {title}
          <Icon style={{ marginLeft: '0.25rem' }} icon={expanded ? 'angle-down' : 'angle-up'} />
        </h2>

        {!expanded && (
          <p>
            <small>{subtitle}</small>
          </p>
        )}
      </SectionHeader>
      {expanded && children}
    </>
  );
}

function CustomDataBox({ to, title, description, ...props }) {
  return (
    <NavBox to={to} {...props}>
      <Title>{title}</Title> <Description>{description}</Description>
    </NavBox>
  );
}

export default function CustomDataListPage() {
  useDocumentTitle('Custom Data');

  const { url } = useRouteMatch();
  const features = useFeatures();

  const sessionStorage = useSessionStorage({ key: 'custom_data' });
  const [sections, setSections] = useState(() => {
    const sections = {
      billing: true,
      clientsAndProjects: true,
      general: true,
      members: true,
      pipeline: true,
    };
    try {
      if (sessionStorage.hasValue()) {
        const value = JSON.parse(sessionStorage.get());
        if (value) {
          sections.billing = !!value.billing;
          sections.clientsAndProjects = !!value.clientsAndProjects;
          sections.general = !!value.general;
          sections.members = !!value.members;
          sections.pipeline = !!value.pipeline;
        }
      }
    } finally {
      sessionStorage.set(JSON.stringify(sections));
    }
    return sections;
  });
  const [query, setQuery] = useState('');

  const allSections = useMemo(() => {
    const {
      disciplines,
      practices,
      pipeline,
      billing = true,
      multicurrency,
      businessUnits,
      allocations,
      workspaceRoles,
    } = features;

    return [
      billing && {
        id: 'billing',
        title: 'Billing',
        subtitle: 'Invoice Items, Payment Methods and Tax Rates.',
        boxes: [
          {
            title: 'Invoice Items',
            description:
              'Every line item on a Ruddr invoice must have an invoice item. The invoice item is the category of the charge to the customer, such as "service" or "expense".',
            route: 'invoice-items',
            parent: 'Billing',
          },
          {
            title: 'Payment Methods',
            description: 'Every payment in Ruddr can be assigned a payment method.',
            route: 'payment-methods',
            parent: 'Billing',
          },
          {
            title: 'Tax Rates',
            description:
              'Tax rates are used to associate a label with a specific tax rate percentage. These tax rates can then be used to apply tax to line items on Ruddr invoices.',
            route: 'tax-rates',
            parent: 'Billing',
          },
        ],
      },
      {
        id: 'clientsAndProjects',
        title: 'Clients and Projects',
        subtitle:
          'Client Tags, Industries, Project Tags, Project Types, Task Tags, Task Templates, and Workspace Roles and Rates.',
        boxes: [
          {
            title: 'Client Tags',
            description:
              'Client tags can be assigned to each client in Ruddr. This allows you to define custom client attributes. You can then filter reports using client tags.',
            route: 'client-tags',
            parent: 'Clients and Projects',
          },
          {
            title: 'Industries',
            description: 'Each workspace client can be assigned an industry. You can also filter reports by industry.',
            route: 'industries',
            parent: 'Clients and Projects',
          },
          {
            title: 'Project Tags',
            description:
              'Project tags can be assigned to each project in Ruddr. This allows you to define custom project attributes. You can then filter reports using project tags.',
            route: 'project-tags',
            parent: 'Clients and Projects',
          },
          {
            title: 'Project Types',
            description:
              'Every project in Ruddr can be assigned a project type. Reports can then be filtered by project type to analyze performance by type.',
            route: 'project-types',
            parent: 'Clients and Projects',
          },
          {
            title: 'Task Tags',
            description:
              'Task tags can be assigned to each task within a project. This allows you to define custom task attributes. You can then filter reports using task tags.',
            route: 'task-tags',
            parent: 'Clients and Projects',
          },
          {
            title: 'Task Templates',
            description: 'Task templates allow standard tasks to be easily added to projects.',
            route: 'task-templates',
            parent: 'Clients and Projects',
          },
          workspaceRoles && {
            title: 'Workspace Roles and Rates',
            description:
              'Workspace roles and rates allow you to create a company-wide rate card. These roles and rates can then be added or linked to client-specific rate cards.',
            route: 'workspace-roles-and-rates',
            parent: 'Clients and Projects',
          },
        ].filter(Boolean),
      },
      {
        id: 'general',
        title: 'General',
        subtitle:
          'Business Units, Exchange Rate Periods, Disciplines, Holidays, Holiday Schedules, Locations, Practices, Resource Placeholders, and Time Off Types.',
        boxes: [
          businessUnits && {
            title: 'Business Units',
            description:
              'Each client and project can be assigned to a business unit. You can set invoice defaults for each business unit.',
            route: 'business-units',
            parent: 'General',
          },
          disciplines && {
            title: 'Disciplines',
            description:
              'A discipline is a grouping of similar job functions. For example, various project managers within an organization could be assigned to a single "project management" discipline. Reports can then be filtered by discipline.',
            route: 'disciplines',
            parent: 'General',
          },
          multicurrency && {
            title: 'Exchange Rate Periods',
            description:
              'Exchange rate periods allow you to set custom exchange rates for specific date ranges. This is useful when you need to override the default exchange rates.',
            route: 'exchange-rate-periods',
            parent: 'General',
          },
          {
            title: 'Expense Categories',
            description:
              'Each item on an expense report must be assigned an expense category. Also, you can build an expense budget for a project using expense categories.',
            route: 'expense-categories',
            parent: 'General',
          },
          {
            title: 'Holidays',
            description:
              "Ruddr has several built-in standard holidays that can be added to your holiday schedules. If your company observes a holiday that isn't built-in, you can create a custom holiday.",
            route: 'holidays',
            parent: 'General',
          },
          {
            title: 'Holiday Schedules',
            description:
              'You can create multiple holiday schedules for your workspace and apply a holiday schedule to each member. This allows you to have a different set of holidays for each country where you have personnel.',
            route: 'holiday-schedules',
            parent: 'General',
          },
          {
            title: 'Locations',
            description:
              'Each workspace client and member can be assigned a location. You can also filter reports by location.',
            route: 'locations',
            parent: 'General',
          },
          practices && {
            title: 'Practices',
            description:
              'Larger professional services companies often organize service offerings and personnel into practices. You can assign a practice to each member, client, and project in Ruddr. Reports can then be filtered by practice.',
            route: 'practices',
            parent: 'General',
          },
          allocations && {
            title: 'Resource Placeholders',
            description:
              "Within the resources area of Ruddr, you can allocate members or resource placeholders to projects. Resource placeholders should be used when you aren't certain which team member will ultimately be assigned to the project.",
            route: 'resource-placeholders',
            parent: 'General',
          },
          {
            title: 'Time Off Types',
            description:
              'Workspace members can track both project time and time off. Each workspace can have custom time off types, such as vacation or sick leave.',
            route: 'time-off-types',
            parent: 'General',
          },
        ].filter(Boolean),
      },
      {
        id: 'members',
        title: 'Members',
        subtitle: 'Job Titles, Skills, and Member Tags.',
        boxes: [
          {
            title: 'Job Titles',
            description: 'Each workspace member can be assigned a job title. You can also filter reports by job title.',
            route: 'job-titles',
            parent: 'Members',
          },
          {
            title: 'Skills',
            description: 'Skills can be assigned to members and placeholders. You can also filter reports by skills.',
            route: 'skills',
            parent: 'Members',
          },
          {
            title: 'Member Tags',
            description:
              'Member tags can be assigned to each member in Ruddr. This allows you to define custom member attributes. You can then filter reports using member tags.',
            route: 'member-tags',
            parent: 'Members',
          },
        ],
      },
      pipeline && {
        id: 'pipeline',
        title: 'Pipeline',
        subtitle:
          'Company Spend Tiers, Company Types, Opportunity Lead Sources, Opportunity Stages, Opportunity Types, and Pipeline Activity Types.',
        boxes: [
          {
            title: 'Company Spend Tiers',
            description: 'Company spend tiers are used to categorize companies based on their annual spend.',
            route: 'company-spend-tiers',
            parent: 'Pipeline',
          },
          {
            title: 'Company Types',
            description: 'Company types are used to categorize Companies within the pipeline.',
            route: 'company-types',
            parent: 'Pipeline',
          },
          {
            title: 'Opportunity Lead Sources',
            description: 'Opportunity lead sources are used to track how you found out about an opportunity.',
            route: 'opportunity-lead-sources',
            parent: 'Pipeline',
          },
          {
            title: 'Opportunity Stages',
            description: 'Opportunity stages are used to track the progress of opportunities.',
            route: 'opportunity-stages',
            parent: 'Pipeline',
          },
          {
            title: 'Opportunity Tags',
            description:
              'Opportunity tags can be assigned to each opportunity in Ruddr. This allows you to define custom opportunity attributes. You can then filter reports using opportunity tags.',
            route: 'opportunity-tags',
            parent: 'Pipeline',
          },
          {
            title: 'Opportunity Types',
            description: 'Opportunity types are used to categorize opportunities.',
            route: 'opportunity-types',
            parent: 'Pipeline',
          },
          {
            title: 'Pipeline Activity Types',
            description:
              'Pipeline activity types are used to track the types of activities that occur during the sales process.',
            route: 'pipeline-activity-types',
            parent: 'Pipeline',
          },
        ],
      },
    ].filter(Boolean);
  }, [features]);

  const filteredSections = useMemo(() => {
    if (!query) return allSections;

    const fuse = new Fuse(
      allSections.flatMap(({ boxes }) => boxes),
      {
        keys: ['title', 'description', 'parent'],
        threshold: 0.1,
        ignoreLocation: true,
      },
    );

    const searchResults = fuse.search(query).map(({ item }) => item);

    return allSections
      .map((section) => {
        const matchedBoxes = section.boxes.filter((box) => searchResults.includes(box));

        return matchedBoxes.length ? { ...section, boxes: matchedBoxes } : null;
      })
      .filter(Boolean);
  }, [query, allSections]);

  const all = Object.keys(sections).every((section) => sections[section]);

  const toggleSection = (section) => {
    const value = { ...sections, [section]: !sections[section] };
    setSections(value);
    sessionStorage.set(JSON.stringify(value));
  };

  const toggleAllSections = () => {
    const value = {
      billing: !all,
      clientsAndProjects: !all,
      general: !all,
      members: !all,
      pipeline: !all,
    };

    setSections(value);
    sessionStorage.set(JSON.stringify(value));
  };

  return (
    <>
      <Page.Header>
        <Page.Info>
          <Page.Eyebrow>Settings</Page.Eyebrow>
          <Page.Title>
            Custom Data{' '}
            <AccordionButton isAnchor onClick={toggleAllSections}>
              <Icon icon={all ? 'angle-double-down' : 'angle-double-up'} />
            </AccordionButton>
          </Page.Title>
        </Page.Info>
      </Page.Header>
      <Page.Filters>
        <Search>
          <SearchInput
            data-testid="custom_data_search_box"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Search"
            autoFocus
          />
        </Search>
      </Page.Filters>
      <div>
        {filteredSections.map((section) => (
          <CustomDataSection
            key={section.id}
            title={section.title}
            subtitle={section.subtitle}
            expanded={sections[section.id]}
            onToggle={() => toggleSection(section.id)}
            data-testid={section.id}>
            <Section>
              {section.boxes.map((box) => (
                <CustomDataBox
                  key={box.route}
                  to={url.concat('/', box.route)}
                  title={box.title}
                  description={box.description}
                  data-testid={box.route}
                />
              ))}
            </Section>
          </CustomDataSection>
        ))}
      </div>
    </>
  );
}
