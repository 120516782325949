import { BillableIcon, DateTime, Duration, Table, Tag, Tags } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.3125rem;
  border: solid 1px ${colors.grey10};
  background-color: ${colors.white};
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
  z-index: 300;
  left: 0;
  position: relative;
  transition: opacity 50ms ease-out;

  &::before {
    content: '';
    position: absolute;
    border: solid 0.875rem transparent;
    z-index: 299;
  }

  &[data-placement='left'] {
    &::before {
      top: calc(50% - 0.875rem);
      left: 100%;
      border-left-color: ${colors.white};
    }
  }

  &[data-placement='right'] {
    &::before {
      top: calc(50% - 0.875rem);
      right: 100%;
      border-right-color: ${colors.white};
    }
  }

  &[data-placement='top'] {
    &::before {
      top: 100%;
      left: calc(50% - 0.875rem);
      border-top-color: ${colors.white};
    }
  }

  &[data-placement='bottom'] {
    &::before {
      bottom: 100%;
      left: calc(50% - 0.875rem);
      border-bottom-color: ${colors.white};
    }
  }
`;

const Content = styled.div`
  padding: 1.25rem 0.5rem;
  width: 100%;
`;

const DayTimeEntryDetails = styled.div`
  font-size: 0.75rem;
  display: flex;
  overflow: hidden;
  flex-direction: column;
`;

const TimeEntryDetails = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  border-bottom: 1px solid ${colors.grey10};
  padding: 0.75rem 0;
  align-items: center;
  &:last-child {
    border-bottom: none;
  }
`;

const TimeEntryNote = styled.div`
  flex: 1;
  display: flex;
  margin: 0 0.75rem;
  white-space: nowrap;
  overflow: hidden;
`;

const NoteContent = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`;

const NotesMissingMessage = styled.div`
  color: ${colors.grey55};
  font-style: italic;
`;

const TimeEntryHours = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.25rem;
  height: 1.875rem;
  color: ${({ status }) =>
    ({
      not_submitted: colors.black,
      pending_approval: colors.warning,
      rejected: colors.danger,
    })[status] || colors.primary};
  font-weight: ${weights.medium};
  background-color: ${({ status }) =>
    ({
      not_submitted: colors.grey10,
      pending_approval: colors.warning10,
      rejected: colors.danger10,
    })[status] || colors.primary10};
  border-radius: 999rem;
  font-size: 0.875rem;
`;

export default function WeekApprovalPopover({ entry, placement = 'auto', children, ...props }) {
  const [visible, setVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const hovering = useRef(false);
  const forceVisible = useRef(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    strategy: 'fixed',
  });

  const { workspace } = useWorkspace();
  const [query, setQuery] = useState({ isReady: false, data: null });
  const api = useApi();

  const fetchData = useCallback(async () => {
    if (!visible) return;

    try {
      if (entry) {
        const { data } = await api.www.workspaces(workspace.id).timeEntries(entry.id).approvalWorkflow.get();
        setQuery({ isReady: true, data });
      }
    } catch {
      // Do nothing
    }
  }, [api, entry, workspace.id, visible]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleMouseEnter = () => {
    hovering.current = true;
    // If the query has been executed, delay showing the popover.
    // Otherwise, the delay will be caused by the API query.
    const delay = query.isReady ? 100 : 0;

    setTimeout(() => {
      if (!hovering.current) return;
      setVisible(true);
    }, delay);
  };

  const handleMouseLeave = () => {
    hovering.current = false;

    setTimeout(() => {
      if (!forceVisible.current) setVisible(false);
    }, 200);
  };

  const handleCardMouseEnter = () => {
    forceVisible.current = true;
  };

  const handleCardMouseLeave = () => {
    forceVisible.current = false;
    setVisible(false);
  };

  const { data } = query;
  const steps = data?.approval?.steps;

  const isPopoverVisible = visible && data?.approval;

  return (
    <>
      <div ref={setReferenceElement} {...props} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </div>
      {isPopoverVisible &&
        ReactDOM.createPortal(
          <Container
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            onMouseEnter={handleCardMouseEnter}
            onMouseLeave={handleCardMouseLeave}>
            {visible && entry && (
              <Content style={{ width: '35rem' }}>
                <DayTimeEntryDetails>
                  <TimeEntryDetails key={entry.id}>
                    {entry.notes ? (
                      <TimeEntryNote>
                        "<NoteContent>{entry.notes}</NoteContent>"
                      </TimeEntryNote>
                    ) : (
                      <TimeEntryNote>
                        <NotesMissingMessage>No notes provided</NotesMissingMessage>
                      </TimeEntryNote>
                    )}
                    <BillableIcon value={entry.isActuallyBillable} />
                    <TimeEntryHours status={entry.status?.id}>
                      <Duration
                        minutes={entry.minutes}
                        timerStartedAt={entry.timerStartedAt}
                        showSeconds={!!entry.timerStartedAt}
                        trim={!entry.timerStartedAt}
                      />
                    </TimeEntryHours>
                  </TimeEntryDetails>
                </DayTimeEntryDetails>
                <Table>
                  <Table.Header>
                    <Table.Column width="4rem" align="center">
                      Step
                    </Table.Column>
                    <Table.Column>Approver(s)</Table.Column>
                    <Table.Column>Approval Status</Table.Column>
                  </Table.Header>

                  <Table.Body>
                    {steps.map((step, index) => {
                      return (
                        <Table.Row key={index}>
                          <Table.Cell>{index + 1}</Table.Cell>
                          <Table.Cell>
                            <Tags style={{ fontSize: '.75rem' }}>
                              {step.approvers.map((a) => {
                                switch (a.type) {
                                  case 'role':
                                    return (
                                      <Tag key={a.approvalRoleKey} variant="primary10">
                                        {a.approvalRole.name}
                                      </Tag>
                                    );

                                  case 'member':
                                    return (
                                      <Tag key={a.memberId} variant="grey5">
                                        {a.member.name}
                                      </Tag>
                                    );
                                }
                              })}
                            </Tags>
                          </Table.Cell>
                          <Table.Cell>
                            <div>
                              <p>
                                {{
                                  approved: <strong style={{ color: colors.green }}>Approved</strong>,
                                  rejected: <strong style={{ color: colors.red }}>Rejected</strong>,
                                }[step.result?.statusId] ?? <strong style={{ color: colors.yellow }}>Pending</strong>}
                              </p>
                              {step.result && (
                                <p>
                                  <small>
                                    <i>
                                      by {step.result.member.name} on <DateTime value={step.result.createdAt} />
                                    </i>
                                  </small>
                                </p>
                              )}
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </Content>
            )}
          </Container>,
          document.body,
        )}
    </>
  );
}
