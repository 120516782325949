import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import { Redirect } from 'react-router-dom';
import { Confirmation, DeleteConfirmation, ExportDialog } from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { dateFormats, mimeTypes } from '~/utils';
import ActionBar from '../components/action-bar/ActionBar';
import { Allocations, Grid, Schedule, Sidebar, useGroups, useSchedule } from '../components/schedule';
import AllocationDetails from '../dialogs/AllocationDetails';
import AllocationForm from '../dialogs/AllocationForm';
import Cells from './cells/Cells';
import Filters from './filters/Filters';
import FiltersBar from './filters/FiltersBar';
import useHeaderMetrics from './hooks/useHeaderMetrics';
import useResourceMetrics from './hooks/useResourceMetrics';
import Empty from './sidebar/Empty';
import Group from './sidebar/Group';
import HeaderLabel from './sidebar/HeaderLabel';
import Row from './sidebar/Row';
import allocationBillableTypes from '~/lookups/allocation-billable-types';

const metricOptions = {
  all_hours: 'All Hours',
  project_hours: 'Project Hours',
  billable_hours: 'Billable Hours',
  productive_hours: 'Productive Hours',
  available_hours: 'Available Hours',
  billable_utilization: 'Billable Utilization',
  productive_utilization: 'Productive Utilization',
  target_attainment: 'Target Attainment',
};

const periods = {
  day: 3,
  week: 7,
  month: 11,
};

const merge = (source, target, key) => {
  const sourceObject = _.keyBy(source, key);
  const targetObject = _.keyBy(target, key);
  const merged = _.merge(sourceObject, targetObject);
  const result = _.values(merged);
  return result;
};

export default function MemberSchedule() {
  const route = useRouteMatch();
  const history = useHistory();
  const api = useApi();
  const { workspace } = useWorkspace();
  const toast = useToast();
  const auth = useAuth();
  const features = useFeatures();

  const documentTitle = useDocumentTitle('Resource Allocations');

  const [query, setQuery] = useState({
    status: 'loading',
    action: 'load',
    fetching: false,
    resources: [],
    assignments: [],
    allocations: [],
    metrics: {
      members: { header: [], rows: [] },
      placeholders: { header: [], rows: [] },
      total: [],
    },
  });

  // Remove the utilization metrics from the dropdown options if the feature is not enabled.
  const filteredMetrics = useMemo(() => {
    return features.utilization
      ? metricOptions
      : _.omit(metricOptions, ['billable_utilization', 'productive_utilization', 'target_attainment']);
  }, [features.utilization]);

  const location = useLocation();

  const [params, setParams] = useState({
    date: moment().format(dateFormats.isoDate),
    unit: 'week',
    allocationBillableTypes: [],
    resourcePractice: [],
    resourceLocation: [],
    resourceSkill: [],
    discipline: [],
    resourceStatusId: 'active',
    resourceTypeId: null,
    onlyAllocatedResources: false,
    member: [],
    memberBillableTypeId: 'billable',
    employmentType: [],
    jobTitles: [],
    memberTags: [],
    memberLocations: [],
    metric: 'all_hours',
    groups: null,

    // Project filters
    projectAdmin: [],
    projectPractice: [],
    projectBusinessUnits: [],
    projectRecordStatusId: 'active',
    client: [],
    clientOwner: [],
    clientTags: [],
    clientLocations: [],
    clientIndustries: [],
    clientBusinessUnits: [],
    projectBillingType: [],
    projectStatus: [],
    projectTags: [],
    projectTypes: [],
    project: [],
  });

  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParamsConfig = useSearchParamsConfig();
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        date: {
          default: moment().format(dateFormats.isoDate),
          deserialize: (value) => (moment(value).isValid() ? moment(value).format(dateFormats.isoDate) : null),
        },
        unit: { default: 'week', valid: ['day', 'week', 'month'] },
        allocationBillableTypes: {
          ...searchParamsConfig.allocationBillableTypes,
          default: [
            allocationBillableTypes.billable,
            allocationBillableTypes.non_billable,
            allocationBillableTypes.internal,
            allocationBillableTypes.time_off,
          ],
        },
        resourcePractice: searchParamsConfig.practices,
        resourceLocation: searchParamsConfig.locations,
        resourceSkill: searchParamsConfig.skills,
        discipline: searchParamsConfig.disciplines,
        resourceStatusId: {
          default: 'active',
          valid: ['all', 'active', 'inactive'],
          serialize: (value) => value || 'all',
          deserialize: (value) => (value === 'all' ? null : value),
        },
        resourceTypeId: { valid: ['member', 'placeholder'] },
        onlyAllocatedResources: {
          default: false,
          serialize: (value) => (value === true ? 'true' : 'false'),
          deserialize: (value) => value === 'true',
        },
        member: searchParamsConfig.members,
        memberBillableTypeId: {
          default: 'billable',
          valid: ['all', 'billable', 'non_billable'],
          serialize: (value) => value || 'all',
          deserialize: (value) => (value === 'all' ? null : value),
        },
        employmentType: searchParamsConfig.employmentTypes,
        jobTitles: searchParamsConfig.jobTitles,
        memberTags: searchParamsConfig.memberTags,
        memberLocations: { ...searchParamsConfig.locations, key: 'memberLocation' },
        groups: { valid: ['expanded', 'collapsed'] },
        metric: { default: 'all_hours', valid: _.keys(filteredMetrics) },

        // Project filters
        projectAdmin: { ...searchParamsConfig.members, key: 'projectAdmin' },
        projectPractice: searchParamsConfig.practices,
        projectBusinessUnits: searchParamsConfig.businessUnits,
        projectRecordStatusId: searchParamsConfig.recordStatusId,
        client: searchParamsConfig.clients,
        clientOwner: { ...searchParamsConfig.members, key: 'clientOwner' },
        clientTags: searchParamsConfig.clientTags,
        clientLocations: { ...searchParamsConfig.locations, key: 'clientLocation' },
        clientIndustries: { ...searchParamsConfig.industries, key: 'clientIndustry' },
        clientBusinessUnits: searchParamsConfig.businessUnits,
        projectBillingType: searchParamsConfig.projectBillingTypes,
        projectStatus: searchParamsConfig.projectStatuses,
        projectTags: searchParamsConfig.projectTags,
        projectTypes: searchParamsConfig.projectTypes,
        project: searchParamsConfig.projects,
      }),
      [searchParamsConfig, filteredMetrics],
    ),
    sessionKey: 'member_allocations',
    onChange: useCallback((params) => setParams((state) => ({ ...state, ...params })), []),
  });

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      if (params) {
        setParams((state) => ({ ...state, ...params }));
        setSearchParamsStatus('ready');
      }
    });
  }, [searchParams, searchParamsStatus]);

  const [allocationFormInitialValues, setAllocationFormInitialValues] = useState(null);

  const { start, end } = useMemo(() => {
    let start;
    let end;

    switch (params.unit) {
      case 'day':
        start = moment(params.date).startOf('isoWeek').format(dateFormats.isoDate);
        end = moment(params.date).add(periods.day, 'weeks').endOf('isoWeek').format(dateFormats.isoDate);
        break;

      case 'week':
        start = moment(params.date).startOf('isoWeek').format(dateFormats.isoDate);
        end = moment(params.date).add(periods.week, 'weeks').endOf('isoWeek').format(dateFormats.isoDate);
        break;

      case 'month':
        start = moment(params.date).startOf('month').format(dateFormats.isoDate);
        end = moment(params.date).add(periods.month, 'months').endOf('month').format(dateFormats.isoDate);
        break;
    }

    return { start, end };
  }, [params.date, params.unit]);

  const data = useMemo(() => {
    const allocations = query.allocations.filter(
      (allocation) => moment(allocation.start).isSameOrBefore(end) && moment(allocation.end).isSameOrAfter(start),
    );

    const assignmentsById = _.keyBy(query.assignments, 'id');
    const allocationsByResource = _.groupBy(allocations, 'resourceId');

    let resources = query.resources;

    if (params.onlyAllocatedResources) {
      resources = resources.filter((r) => allocations.some((a) => a.resourceId === r.id && a.entity === 'allocation'));
    }

    const rows = [];

    const totalRow = {
      id: 'total',
      type: 'header',
      label: 'Total',
      showExpandCollapse: true,
      cells: [],
    };

    if (resources.length > 0) {
      rows.push(totalRow);
    }

    const metrics = {
      total: _.groupBy(query.metrics.total, (metric) => metric.start),

      members: {
        header: _.groupBy(query.metrics.members.header, (metric) => metric.start),
        rows: _.groupBy(query.metrics.members.rows, (metric) => `${metric.id}_${metric.start}`),
      },

      placeholders: {
        header: _.groupBy(query.metrics.placeholders.header, (metric) => metric.start),
        rows: _.groupBy(query.metrics.placeholders.rows, (metric) => `${metric.id}_${metric.start}`),
      },
    };

    const periodCount = moment(end).diff(start, params.unit) + 1;

    for (let index = 0; index < periodCount; index++) {
      const date = moment(start).add(index, params.unit).format(dateFormats.isoDate);

      const metric = metrics.total[date] ? metrics.total[date][0] : null;

      totalRow.cells.push({
        key: `total_${date}`,
        mode: 'header',
        type: 'total',
        date,
        capacity: metric?.capacity ?? 0,
        allocated: metric?.allocated ?? 0,
        billable: metric?.billable ?? 0,
        project: metric?.project ?? 0,
        productive: metric?.productive ?? 0,
        targetBillable: metric?.targetBillable ?? 0,
        workingDays: metric?.workingDays ?? 0,
      });
    }

    const resourcesByType = _.groupBy(resources, 'resourceType');
    const membersCount = resourcesByType.member?.length ?? 0;
    const placeholdersCount = resourcesByType.placeholder?.length ?? 0;

    _.forEach(resourcesByType, (resources, key) => {
      const headerRow = {
        id: key,
        type: 'header',
        resourceType: key,
        label: { member: `Members (${membersCount})`, placeholder: `Placeholders (${placeholdersCount})` }[key],
        cells: [],
      };

      let headerSubtotal = {
        member: metrics.members.header,
        placeholder: metrics.placeholders.header,
      }[key];

      for (let index = 0; index < periodCount; index++) {
        const date = moment(start).add(index, params.unit).format(dateFormats.isoDate);
        const metric = headerSubtotal[date] ? headerSubtotal[date][0] : null;

        headerRow.cells.push({
          key: `${key}_subtotal_${date}`,
          mode: 'header',
          type: 'header',
          resourceType: key,
          date,
          capacity: metric?.capacity,
          allocated: metric?.allocated ?? 0,
          billable: metric?.billable ?? 0,
          project: metric?.project ?? 0,
          productive: metric?.productive ?? 0,
          targetBillable: metric?.targetBillable,
          workingDays: metric?.workingDays ?? 0,
        });
      }

      rows.push(headerRow);

      for (const resource of resources) {
        const group = {
          id: resource.id,
          type: 'group',
          parentId: null,
          resourceId: resource.id,
          resource,
          resourceType: resource.resourceType,
          hasAllocations: allocationsByResource[resource.id]?.length > 0,
          cells: [],
        };
        rows.push(group);

        const resourceAssignments = {};

        // Order the allocations by assignment type and name
        const resourceAllocations = _.orderBy(allocationsByResource[resource.id] || [], [
          'assignmentTypeId',
          (allocation) => assignmentsById[allocation.assignmentId]?.name,
        ]);

        for (let index = 0; index < periodCount; index++) {
          const date = moment(start).add(index, params.unit).format(dateFormats.isoDate);

          const key = `${group.id}_${date}`;

          switch (group.resourceType) {
            case 'member': {
              const metric = metrics.members.rows[key] ? metrics.members.rows[key][0] : {};

              group.cells.push({
                key: `${group.id}_${date}`,
                mode: 'heatmap',
                type: 'group',
                group,
                date,
                manage: resource.permissions.manageAllocations,
                capacity: metric.capacity ?? 0,
                allocated: metric.allocated ?? 0,
                billable: metric.billable ?? 0,
                project: metric.project ?? 0,
                productive: metric.productive ?? 0,
                targetBillable: metric.targetBillable ?? 0,
              });

              break;
            }

            case 'placeholder': {
              const metric = metrics.placeholders.rows[key] ? metrics.placeholders.rows[key][0] : {};

              group.cells.push({
                key: `${group.id}_${date}`,
                mode: 'placeholder',
                type: 'group',
                group,
                date,
                manage: resource.permissions.manageAllocations,
                allocated: metric.allocated ?? 0,
                billable: metric.billable ?? 0,
                project: metric.project ?? 0,
                productive: metric.productive ?? 0,
                workingDays: metric?.workingDays ?? 0,
              });

              break;
            }
          }
        }

        for (const allocation of resourceAllocations) {
          if (!resourceAssignments[allocation.assignmentId]) {
            resourceAssignments[allocation.assignmentId] = {
              id: `${resource.id}_${allocation.assignmentId}`,
              assignmentId: allocation.assignmentId,
              type: 'row',
              resourceId: resource.id,
              parentId: group.id,
              assignment: assignmentsById[allocation.assignmentId],
              allocations: [],
              cells: [],
            };
          }

          resourceAssignments[allocation.assignmentId].allocations.push(allocation);
        }

        _.forEach(resourceAssignments, (value) => {
          // Add cells for each assignment row
          for (let index = 0; index < periodCount; index++) {
            const date = moment(start).add(index, params.unit).format(dateFormats.isoDate);

            value.cells.push({
              key: `${value.id}_${date}`,
              mode: 'row',
              type: 'row',
              group,
              row: value,
              date,
              manage: resource.permissions.manageAllocations,
            });
          }

          rows.push(value);
        });
      }
    });

    return { rows };
  }, [
    query.resources,
    query.assignments,
    query.allocations,
    query.metrics,
    params.unit,
    params.onlyAllocatedResources,
    start,
    end,
  ]);

  const updateParams = useCallback(
    (params) => {
      setParams((state) => ({ ...state, ...params }));
      searchParams.set(params);
    },
    [searchParams],
  );

  const handleGroupsToggle = useCallback(
    (status) => {
      updateParams({ groups: status });
    },
    [updateParams],
  );

  const { groups, hasExpandedGroups, toggleGroup, toggleGroups } = useGroups({
    rows: data?.rows,
    defaultGroupStatus: params.groups,
    onGroupsToggle: handleGroupsToggle,
  });

  const bodyRef = useRef();
  const canvasRef = useRef();

  const {
    virtualRows: rows,
    allocations,
    cells,
    styles,
  } = useSchedule({
    data,
    start,
    end,
    unit: params.unit,
    groups,
    parentRef: bodyRef,
  });

  const resourceMetrics = useResourceMetrics({
    parentRef: bodyRef,
    resources: useMemo(() => rows.filter((r) => r.type === 'group'), [rows]),
    start,
    end,
    unit: params.unit,
    metric: params.metric,
    allocations: query.allocations,
    onFetched: useCallback((metrics) => {
      setQuery((state) => {
        return state.metrics
          ? {
              ...state,
              metrics: {
                ...state.metrics,
                members: {
                  ...state.metrics.members,
                  rows: merge(state.metrics.members.rows, metrics.members, (obj) => `${obj.id}_${obj.start}`),
                },
                placeholders: {
                  ...state.metrics.placeholders,
                  rows: merge(state.metrics.placeholders.rows, metrics.placeholders, (obj) => `${obj.id}_${obj.start}`),
                },
              },
            }
          : state;
      });
    }, []),
  });

  const headerMetrics = useHeaderMetrics({
    parentRef: bodyRef,
    headers: useMemo(() => rows.filter((r) => r.type === 'header'), [rows]),
    resources: useMemo(() => data.rows.filter((r) => r.type === 'group'), [data.rows]),
    start,
    end,
    unit: params.unit,
    allocations: query.allocations,
    onFetched: useCallback((metrics) => {
      setQuery((state) => {
        return state.metrics
          ? {
              ...state,
              metrics: {
                members: {
                  header: merge(state.metrics.members.header, metrics.members, 'start'),
                  rows: state.metrics.members.rows,
                },
                placeholders: {
                  header: merge(state.metrics.placeholders.header, metrics.placeholders, 'start'),
                  rows: state.metrics.placeholders.rows,
                },
                total: merge(state.metrics.total, metrics.total, 'start'),
              },
            }
          : state;
      });
    }, []),
  });

  const fetchResources = useCallback(async () => {
    setQuery((state) => ({ ...state, action: 'fetch-resources' }));

    const { data } = await api.www
      .workspaces(workspace.id)
      .allocations()
      .memberSchedule.resources({
        resourcePracticeId: params.resourcePractice?.map((v) => v.id),
        resourceLocationId: params.resourceLocation?.map((v) => v.id),
        resourceSkillId: params.resourceSkill?.map((v) => v.id),
        resourceDisciplineId: params.discipline?.map((v) => v.id),
        resourceStatusId: params.resourceStatusId ?? undefined,
        resourceTypeId: params.resourceTypeId ?? undefined,
        memberId: params.member?.map((v) => v.id),
        memberBillableTypeId: params.memberBillableTypeId ?? undefined,
        employmentTypeId: params.employmentType?.map((v) => v.id),
        jobTitleId: params.jobTitles?.map((v) => v.id),
        memberTagId: params.memberTags?.map((v) => v.id),
        memberLocationId: params.memberLocations?.map((v) => v.id),
      });

    setQuery((state) => ({ ...state, action: null, resources: data }));
    return data;
  }, [api, workspace.id, params]);

  const fetchAssignments = useCallback(async () => {
    setQuery((state) => ({ ...state, action: 'fetch-assignments' }));

    const { data } = await api.www
      .workspaces(workspace.id)
      .allocations()
      .memberSchedule.assignments({
        projectAdminId: params.projectAdmin?.map((v) => v.id),
        projectPracticeId: params.projectPractice?.map((v) => v.id),
        projectBusinessUnitId: params.projectBusinessUnits?.map((v) => v.id),
        projectRecordStatusId: params.projectRecordStatusId ?? undefined,
        clientId: params.client?.map((v) => v.id),
        clientOwnerId: params.clientOwner?.map((v) => v.id),
        clientTagId: params.clientTags?.map((v) => v.id),
        clientLocationId: params.clientLocations?.map((v) => v.id),
        clientIndustryId: params.clientIndustries?.map((v) => v.id),
        clientBusinessUnitId: params.clientBusinessUnits?.map((v) => v.id),
        projectBillingTypeId: params.projectBillingType?.map((v) => v.id),
        projectStatusId: params.projectStatus?.map((v) => v.id),
        projectTagId: params.projectTags?.map((v) => v.id),
        projectTypeId: params.projectTypes?.map((v) => v.id),
        projectId: params.project?.map((v) => v.id),
      });

    setQuery((state) => ({ ...state, action: null, assignments: data }));
    return data;
  }, [api, workspace.id, params]);

  const fetchAllocations = useCallback(async () => {
    setQuery((state) => ({ ...state, fetching: true, action: 'fetch-allocations' }));

    const { data } = await api.www
      .workspaces(workspace.id)
      .allocations()
      .memberSchedule.allocations({
        start,
        end,
        unit: params.unit,
        resourcePracticeId: params.resourcePractice?.map((v) => v.id),
        resourceLocationId: params.resourceLocation?.map((v) => v.id),
        resourceSkillId: params.resourceSkill?.map((v) => v.id),
        resourceDisciplineId: params.discipline?.map((v) => v.id),
        resourceStatusId: params.resourceStatusId ?? undefined,
        resourceTypeId: params.resourceTypeId ?? undefined,
        memberId: params.member?.map((v) => v.id),
        memberBillableTypeId: params.memberBillableTypeId ?? undefined,
        employmentTypeId: params.employmentType?.map((v) => v.id),
        jobTitleId: params.jobTitles?.map((v) => v.id),
        memberTagId: params.memberTags?.map((v) => v.id),
        memberLocationId: params.memberLocations?.map((v) => v.id),
        projectAdminId: params.projectAdmin?.map((v) => v.id),
        projectPracticeId: params.projectPractice?.map((v) => v.id),
        projectBusinessUnitId: params.projectBusinessUnits?.map((v) => v.id),
        projectRecordStatusId: params.projectRecordStatusId ?? undefined,
        clientId: params.client?.map((v) => v.id),
        clientOwnerId: params.clientOwner?.map((v) => v.id),
        clientTagId: params.clientTags?.map((v) => v.id),
        clientLocationId: params.clientLocations?.map((v) => v.id),
        clientIndustryId: params.clientIndustries?.map((v) => v.id),
        clientBusinessUnitId: params.clientBusinessUnits?.map((v) => v.id),
        projectBillingTypeId: params.projectBillingType?.map((v) => v.id),
        projectStatusId: params.projectStatus?.map((v) => v.id),
        projectTagId: params.projectTags?.map((v) => v.id),
        projectTypeId: params.projectTypes?.map((v) => v.id),
        projectId: params.project?.map((v) => v.id),
        allocationBillableTypeId: params.allocationBillableTypes?.map((v) => v.id),
      });

    setQuery((state) => ({ ...state, fetching: false, action: null, allocations: data }));

    headerMetrics.initialize();
    resourceMetrics.initialize();

    return data;
  }, [start, end, params, api, workspace.id, headerMetrics, resourceMetrics]);

  const fetchMoreAllocations = useCallback(
    async ({ start, end }) => {
      setQuery((state) => ({ ...state, fetching: true }));

      const { data } = await api.www
        .workspaces(workspace.id)
        .allocations()
        .memberSchedule.allocations({
          start,
          end,
          unit: params.unit,
          resourcePracticeId: params.resourcePractice?.map((v) => v.id),
          resourceLocationId: params.resourceLocation?.map((v) => v.id),
          resourceSkillId: params.resourceSkill?.map((v) => v.id),
          resourceDisciplineId: params.discipline?.map((v) => v.id),
          resourceStatusId: params.resourceStatusId ?? undefined,
          resourceTypeId: params.resourceTypeId ?? undefined,
          memberId: params.member?.map((v) => v.id),
          memberBillableTypeId: params.memberBillableTypeId ?? undefined,
          employmentTypeId: params.employmentType?.map((v) => v.id),
          jobTitleId: params.jobTitles?.map((v) => v.id),
          memberTagId: params.memberTags?.map((v) => v.id),
          memberLocationId: params.memberLocations?.map((v) => v.id),
          projectAdminId: params.projectAdmin?.map((v) => v.id),
          projectPracticeId: params.projectPractice?.map((v) => v.id),
          projectBusinessUnitId: params.projectBusinessUnits?.map((v) => v.id),
          projectRecordStatusId: params.projectRecordStatusId ?? undefined,
          clientId: params.client?.map((v) => v.id),
          clientOwnerId: params.clientOwner?.map((v) => v.id),
          clientTagId: params.clientTags?.map((v) => v.id),
          clientLocationId: params.clientLocations?.map((v) => v.id),
          clientIndustryId: params.clientIndustries?.map((v) => v.id),
          clientBusinessUnitId: params.clientBusinessUnits?.map((v) => v.id),
          projectBillingTypeId: params.projectBillingType?.map((v) => v.id),
          projectStatusId: params.projectStatus?.map((v) => v.id),
          projectTagId: params.projectTags?.map((v) => v.id),
          projectTypeId: params.projectTypes?.map((v) => v.id),
          projectId: params.project?.map((v) => v.id),
          allocationBillableTypeId: params.allocationBillableTypes?.map((v) => v.id),
        });

      setQuery((state) => ({
        ...state,
        status: 'ready',
        action: 'fetch-more',
        fetching: false,
        allocations: merge(state.allocations, data, 'id'),
      }));

      headerMetrics.load({ start, end, unit: params.unit, metric: params.metric, allocations: data });
      resourceMetrics.load({ start, end, unit: params.unit, metric: params.metric, allocations: data });

      return data;
    },
    [params, headerMetrics, resourceMetrics, api, workspace.id],
  );

  useEffect(() => {
    if (searchParamsStatus !== 'ready' || !['load', 'refetch', 'refetch-allocations'].includes(query.action)) return;

    (async () => {
      switch (query.action) {
        case 'load':
        case 'refetch': {
          await fetchResources();
          await fetchAssignments();
          await fetchAllocations();
          break;
        }

        case 'refetch-allocations':
          await fetchAllocations();
          break;
      }

      setQuery((state) => ({ ...state, fetching: false, action: null, status: 'ready' }));
    })();
  }, [searchParamsStatus, query.action, fetchResources, fetchAllocations, fetchAssignments]);

  const components = useMemo(
    () => ({
      sidebar: {
        Empty,
        Group,
        Row: (props) => <Row {...props} options={{ showTentative: true }} />,
        HeaderLabel,
      },
    }),
    [],
  );

  const refetch = () => {
    setQuery((state) => ({
      ...state,
      action: 'refetch',
      metrics: {
        total: [],
        members: { header: [], rows: [] },
        placeholders: { header: [], rows: [] },
      },
    }));
  };

  const refetchAllocations = () => {
    setQuery((state) => ({
      ...state,
      action: 'refetch-allocations',
      metrics: {
        total: [],
        members: { header: [], rows: [] },
        placeholders: { header: [], rows: [] },
      },
    }));
  };

  const handleDateChange = (date) => {
    updateParams({ date });
    refetchAllocations();
  };

  const handleDateNavPrevious = () => {
    const date = {
      day: moment(params.date).subtract(1, 'week'),
      week: moment(params.date).subtract(1, 'week'),
      month: moment(params.date).subtract(1, 'month'),
    }[params.unit].format(dateFormats.isoDate);

    updateParams({ date });

    const { start, end } = {
      day: {
        start: moment(date).startOf('isoWeek').format(dateFormats.isoDate),
        end: moment(date).endOf('isoWeek').format(dateFormats.isoDate),
      },
      week: {
        start: moment(date).startOf('isoWeek').format(dateFormats.isoDate),
        end: moment(date).endOf('isoWeek').format(dateFormats.isoDate),
      },
      month: {
        start: moment(date).startOf('month').format(dateFormats.isoDate),
        end: moment(date).endOf('month').format(dateFormats.isoDate),
      },
    }[params.unit];

    fetchMoreAllocations({ start, end });
  };

  const handleDateNavNext = () => {
    const date = {
      day: moment(params.date).add(1, 'week'),
      week: moment(params.date).add(1, 'week'),
      month: moment(params.date).add(1, 'month'),
    }[params.unit].format(dateFormats.isoDate);

    updateParams({ date });

    const { start, end } = {
      day: {
        start: moment(date).add(periods.day, 'weeks').startOf('isoWeek').format(dateFormats.isoDate),
        end: moment(date).add(periods.day, 'weeks').endOf('isoWeek').format(dateFormats.isoDate),
      },
      week: {
        start: moment(date).add(periods.week, 'weeks').startOf('isoWeek').format(dateFormats.isoDate),
        end: moment(date).add(periods.week, 'weeks').endOf('isoWeek').format(dateFormats.isoDate),
      },
      month: {
        start: moment(date).add(periods.month, 'months').startOf('month').format(dateFormats.isoDate),
        end: moment(date).add(periods.month, 'months').endOf('month').format(dateFormats.isoDate),
      },
    }[params.unit];

    fetchMoreAllocations({ start, end });
  };

  const handleFormClose = () => {
    setAllocationFormInitialValues(null);
    history.push({ pathname: route.url, search: location.search });
    documentTitle.set('Resource Allocations');
  };

  const handleCreateAllocation = () => {
    const unit = { day: 'isoWeek', week: 'isoWeek', month: 'month' }[params.unit];

    setAllocationFormInitialValues({
      start: moment(params.date).startOf(unit).format(dateFormats.isoDate),
      end: moment(params.date).endOf(unit).format(dateFormats.isoDate),
    });

    history.push({ pathname: route.url.concat('/new'), search: location.search });
  };

  const handleView = (allocation) => {
    history.push({ pathname: `${route.url}/view/${allocation.id}`, search: location.search });
  };

  const handleEdit = (allocation) => {
    history.push({ pathname: `${route.url}/edit/${allocation.id}`, search: location.search });
  };

  const handleClone = async (allocation) => {
    await api.www.workspaces(workspace.id).allocations(allocation.id).clone();
    await fetchAllocations();
    toast.success('Allocation successfully cloned.');
  };

  const handleSplit = async (allocation, date) => {
    await api.www.workspaces(workspace.id).allocations(allocation.id).split({ date });
    await fetchAllocations();
    toast.success('Allocation successfully split.');
  };

  const handleSplitByDay = async (allocation) => {
    await api.www.workspaces(workspace.id).allocations(allocation.id).splitByDay();
    await fetchAllocations();
    toast.success('Allocation successfully split by day.');
  };

  const handleSplitByWeek = async (allocation) => {
    await api.www.workspaces(workspace.id).allocations(allocation.id).splitByWeek();
    await fetchAllocations();
    toast.success('Allocation successfully split by week.');
  };

  const handleSplitByMonth = async (allocation) => {
    await api.www.workspaces(workspace.id).allocations(allocation.id).splitByMonth();
    await fetchAllocations();
    toast.success('Allocation successfully split by month.');
  };

  const handleRemoveOverlapping = async (allocation) => {
    await confirmation.prompt((resolve) => {
      return (
        <Confirmation
          title="Remove Overlapping"
          resolve={async (result) => {
            if (result) {
              await api.www.workspaces(workspace.id).allocations(allocation.id).removeOverlapping();
              await fetchAllocations();
              toast.success('Successfully removed overlapping allocations.');
            }
            resolve();
          }}>
          This will remove all overlapping allocations for{' '}
          {query.resources.find((r) => r.id === allocation.resourceId).name}. Are you sure you want to continue?
        </Confirmation>
      );
    });
  };

  const handleDelete = async (allocation) => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this allocation?</DeleteConfirmation>
    ));

    if (!confirm) return;

    await api.www.workspaces(workspace.id).allocations(allocation.id).delete();

    await fetchAllocations();
  };

  const handleSelectCellsEnd = (cells) => {
    const unit = { day: 'day', week: 'isoWeek', month: 'month' }[params.unit];

    const cell = _.first(cells);
    const lastCell = _.last(cells);

    let initialValues = {
      start: moment(cell.date).startOf(unit).format(dateFormats.isoDate),
      end: moment(lastCell.date).endOf(unit).format(dateFormats.isoDate),
    };

    const resource = query.resources.find((r) => r.id === cell.group.resource.id);

    if (resource) {
      // TODO: fetch resource with permissions before assigning to the form
      initialValues.resourceTypeId = resource.resourceType;
      initialValues[resource.resourceType] = resource;
    }

    if (cell.row) {
      const assignment = query.assignments.find((a) => a.id === cell.row.assignment.id);

      if (assignment) {
        const assignmentType = { project: 'project', time_off: 'timeOffType' }[assignment.assignmentType];

        if (assignmentType === 'project') {
          initialValues.assignmentTypeId = assignment.assignmentType;
          initialValues.projectId = assignment.id;
        } else {
          initialValues.assignmentTypeId = assignment.assignmentType;
          initialValues[assignmentType] = assignment;
        }
      }
    }

    setAllocationFormInitialValues(initialValues);

    history.push({ pathname: route.url.concat('/new'), search: location.search });
  };

  const handleUpdateAllocationDates = useCallback(
    async (allocation) => {
      try {
        const body = _.pick(allocation, [
          'unit',
          'start',
          'end',
          'hoursPerDay',
          'hoursPerWeek',
          'hoursPerMonth',
          'hoursPerAllocation',
          'hoursRatioOfCapacity',
        ]);

        // Optimistic update
        setQuery((state) => ({
          ...state,
          allocations: state.allocations.map((a) => (a.id === allocation.id ? { ...a, ...body } : a)),
        }));

        await api.www.workspaces(workspace.id).allocations(allocation.id).upsert(body);
      } catch (error) {
        toast.error(error.message);
      } finally {
        await fetchAllocations();
      }
    },
    [api, fetchAllocations, toast, workspace.id],
  );

  const handleAllocationSaved = async (allocation) => {
    await fetchAllocations();

    if (groups[allocation.resourceId]?.state !== 'expanded') {
      toggleGroup({ id: allocation.resourceId });
    }
  };

  const handleUnitChange = async (unit) => {
    if (unit !== params.unit) {
      setQuery((state) => ({ ...state, status: 'grid_loading' }));
      updateParams({ unit });
      refetchAllocations();
    }
  };

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) {
      values = _.omit(values, 'unit', 'date', 'metric');
      setQuery((state) => ({ ...state, status: 'filtering' }));
      updateParams({ ...values });
      refetch();
    }

    hideFilters();
  };

  const handleFilterChange = (filter) => {
    handleApplyFilters({ ...params, ...filter });
  };

  const confirmation = useConfirmation();

  const handleExport = async (mimeType) => {
    const filename =
      mimeType === mimeTypes.xlsx
        ? `member_allocations_by_${params.unit}.xlsx`
        : `member_allocations_by_${params.unit}.csv`;

    const exportParams = {
      assignmentTypeId: ['project', 'time_off'],
      start,
      end,
      unit: params.unit,
      allocationBillableTypeId: params.allocationBillableTypes.map((v) => v.id),
      resourcePracticeId: params.resourcePractice?.map((v) => v.id),
      resourceLocationId: params.resourceLocation?.map((v) => v.id),
      resourceSkillId: params.resourceSkill?.map((v) => v.id),
      resourceDisciplineId: params.discipline?.map((v) => v.id),
      resourceStatusId: params.resourceStatusId ?? undefined,
      resourceTypeId: params.resourceTypeId ?? undefined,
      memberId: params.member?.map((v) => v.id),
      memberBillableTypeId: params.memberBillableTypeId ?? undefined,
      employmentTypeId: params.employmentType?.map((v) => v.id),
      jobTitleId: params.jobTitles?.map((v) => v.id),
      memberTagId: params.memberTags?.map((v) => v.id),
      memberLocationId: params.memberLocations?.map((v) => v.id),

      // Project filters
      projectAdminId: params.projectAdmin?.map((v) => v.id),
      projectPracticeId: params.projectPractice?.map((v) => v.id),
      projectBusinessUnitId: params.projectBusinessUnits?.map((v) => v.id),
      projectRecordStatusId: params.projectRecordStatusId ?? undefined,
      clientId: params.client?.map((v) => v.id),
      clientOwnerId: params.clientOwner?.map((v) => v.id),
      clientTagId: params.clientTags?.map((v) => v.id),
      clientLocationId: params.clientLocations?.map((v) => v.id),
      clientIndustryId: params.clientIndustries?.map((v) => v.id),
      clientBusinessUnitId: params.clientBusinessUnits?.map((v) => v.id),
      projectBillingTypeId: params.projectBillingType?.map((v) => v.id),
      projectStatusId: params.projectStatus?.map((v) => v.id),
      projectTagId: params.projectTags?.map((v) => v.id),
      projectTypeId: params.projectTypes?.map((v) => v.id),
      projectId: params.project?.map((v) => v.id),

      onlyAllocatedResources: params.onlyAllocatedResources ? 'true' : undefined,
    };

    const headers = {
      headers: { accept: mimeType },
      responseType: 'blob',
    };

    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www.workspaces(workspace.id).allocations().memberSchedule.export(exportParams, headers)}
        onClose={resolve}
      />
    ));
  };

  const handleMetricChange = (value) => {
    setQuery((state) => ({
      ...state,
      metrics: {
        total: [],
        members: {
          header: [],
          rows: [],
        },
        placeholders: {
          header: [],
          rows: [],
        },
      },
    }));

    updateParams({ metric: value });

    headerMetrics.initialize();
    resourceMetrics.initialize();
  };

  if (query.status === 'loading') return <PageLoader />;

  return (
    <>
      <ActionBar
        unit={params.unit}
        date={params.date}
        start={start}
        end={end}
        filters={true}
        create={true}
        disabled={query.fetching}
        onCreateAllocation={handleCreateAllocation}
        onDateChange={handleDateChange}
        onDateNavNext={handleDateNavNext}
        onDateNavPrevious={handleDateNavPrevious}
        onUnitChange={handleUnitChange}
        onFilter={showFilters}
        onExport={handleExport}
      />

      <FiltersBar params={params} onFilterChange={handleFilterChange} onMetricChange={handleMetricChange} />

      <Schedule>
        <Grid
          canvasRef={canvasRef}
          bodyRef={bodyRef}
          start={start}
          end={end}
          unit={params.unit}
          rows={rows}
          styles={styles}
          loading={query.status !== 'ready'}
          navigation={true}
          Sidebar={() => (
            <Sidebar
              groups={groups}
              toggleGroup={toggleGroup}
              toggleGroups={toggleGroups}
              hasExpandedGroups={hasExpandedGroups}
              styles={styles}
              rows={rows}
              components={components}
              parentRef={bodyRef}
            />
          )}
          onDateChange={handleDateChange}>
          {query.status === 'ready' && rows.length > 0 && (
            <>
              <Cells
                bodyRef={bodyRef}
                styles={styles}
                metric={params.metric}
                cells={cells}
                rows={rows}
                start={start}
                end={end}
                unit={params.unit}
                allocations={query.allocations}
                resources={query.resources}
                onSelectEnd={handleSelectCellsEnd}
              />

              <Allocations
                allocations={allocations}
                rows={rows}
                styles={styles}
                canvasRef={canvasRef}
                parentRef={bodyRef}
                onView={handleView}
                onEdit={handleEdit}
                onClone={handleClone}
                onSplit={handleSplit}
                onSplitByDay={handleSplitByDay}
                onSplitByWeek={handleSplitByWeek}
                onSplitByMonth={handleSplitByMonth}
                onRemoveOverlapping={handleRemoveOverlapping}
                onDelete={handleDelete}
                onAllocationDatesChange={handleUpdateAllocationDates}
              />
            </>
          )}
        </Grid>
      </Schedule>

      <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />

      <Switch>
        {auth.allocations.manage && (
          <Route path={[route.path.concat('/new'), route.path.concat('/edit/:allocationId')]}>
            <AllocationForm
              initialValues={allocationFormInitialValues}
              onSaved={handleAllocationSaved}
              onDeleted={fetchAllocations}
              onClose={handleFormClose}
            />
          </Route>
        )}

        <Route path={route.path.concat('/view/:allocationId')}>
          <AllocationDetails onClose={handleFormClose} />
        </Route>

        <Redirect to={route.url.concat(location.search)} />
      </Switch>
    </>
  );
}
