import React from 'react';
import styled from 'styled-components';
import { Icon } from '~/components';
import { colors, weights } from '~/styles';

const Nav = styled.div`
  &:not(:first-child) {
    margin-top: 1.5rem;
  }
`;

const NavGrid = styled.div`
  margin-top: 0.5rem;
  display: grid;
  grid-row-gap: 0.875rem;
  grid-column-gap: 0.875rem;
  grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr));
`;

const NavSectionHeader = styled.div``;

const Title = styled.h2`
  cursor: pointer;
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;

  &:hover {
    color: ${colors.grey55};
  }
`;

const Subtitle = styled.small`
  font-size: 0.75rem;
  font-style: italic;
  color: ${colors.grey75};
  font-weight: ${weights.normal};
  text-transform: none;
`;

function NavSection({ title, subtitle, expanded, children, onToggle }) {
  return (
    <Nav>
      {title && (
        <NavSectionHeader>
          <Title onClick={onToggle}>
            {title}
            <Icon style={{ marginLeft: '0.25rem' }} icon={expanded ? 'angle-down' : 'angle-up'} />
          </Title>

          <Subtitle>{subtitle}</Subtitle>
        </NavSectionHeader>
      )}

      {expanded && <NavGrid>{children}</NavGrid>}
    </Nav>
  );
}

export default NavSection;
