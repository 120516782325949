import {
  ApprovalWorkflowEditor,
  Button,
  Checkbox,
  CloudFolderLink,
  Confirmation,
  Field,
  Form,
  HelpTooltip,
  Icon,
  Inline,
  InvoiceVariablesDialog,
  ProjectTagMultiSelect,
  Radio,
  Tooltip,
} from '~/components';
import OpportunitySelect from '~/components/OpportunitySelect';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useAuth, useFeatures } from '~/hooks';
import _ from 'lodash';
import revenueAttributionMethods from '~/lookups/revenue-attribution-methods';
import revenueRecognitionMethods from '~/lookups/revenue-recognition-methods';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import * as Yup from 'yup';
import SlackChannelSelect from './SlackChannelSelect';
import ProjectPurchaseOrderSelect from './ProjectPurchaseOrderSelect';
import ReadTextbox from '~/components/read-only/ReadTextbox';

const LabelTooltip = styled.div`
  display: flex;
  align-items: center;
`;

const ControlLabel = styled.p`
  display: flex;
  color: ${colors.grey75};

  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

const Checkboxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.375rem -0.75rem;

  > label {
    margin: 0.375rem 0.75rem;
  }
`;

const VariablesContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;

  > a {
    margin-left: auto;
  }
`;

const StyledLink = styled.a``;

const taxableItems = {
  services: 'Services',
  expenses: 'Expenses',
  other_items: 'Other Items to Bill',
};

function ProjectForm({
  project,
  projectModel: { isBillable, statusId, client, useRoles, billingTypeId },
  formik,
  drawerLoaded,
  isSlackConnected,
  QBOProjectsEnabled,
}) {
  const firstFieldRef = useRef();
  const invoiceSubjectRef = useRef(null);
  const invoiceNotesRef = useRef(null);
  const invoiceEmailSubjectRef = useRef(null);
  const invoiceEmailBodyRef = useRef(null);

  const { workspace } = useWorkspace();
  const auth = useAuth();

  const locked = project.hasInvoices || project.hasCreditNotes;

  const handleClientChange = ({ target: { value } }) => {
    const values = { ...formik.values, client: value };
    if (value && value.isInternal) {
      values.isBillable = false;
      values.billingTypeId = 'non_billable';
      values.enableClientApprovals = false;
      values.salesRepresentative = null;
    }

    if (!value || value.id !== client?.id) {
      values.qboProjectId = null;
    }

    if (client.id !== value.id) {
      values.purchaseOrder = null;
    }

    formik.setValues(values);
  };

  const handleBillingTypeChange = ({ target: { value } }) => {
    const values = { ...formik.values, billingTypeId: value };
    values.isBillable = value !== 'non_billable';

    // For fixed fee projects, an overall budget is required to turn on the monthly budget
    if (values.billingTypeId === 'fixed' && !values.useBudget && values.useMonthlyBudget) {
      values.useMonthlyBudget = false;
    }

    if (values.billingTypeId !== 'tm') values.capRevenue = false;

    formik.setValues(values);
  };

  const handleUseBudgetChange = ({ target: { checked } }) => {
    const values = { ...formik.values, useBudget: checked };

    // If it's a fixed fee project and the overall budget is turned off, turn off the monthly budget.
    // This is because the overall budget hours are used to calculate the monthly revenue.
    if (values.billingTypeId === 'fixed' && !values.useBudget) values.useMonthlyBudget = false;

    formik.setValues(values);
  };

  useState(() => {
    if (!project && drawerLoaded && firstFieldRef.current) {
      firstFieldRef.current.focus();
    }
  }, [drawerLoaded]);

  const budgetDisabled = useMemo(
    () =>
      ['fixed', 'fixed_recurring'].includes(formik.values.billingTypeId) &&
      ['automated_hours', 'automated_tm_basis'].includes(formik.values.revenueRecognitionMethod) &&
      formik.values.useBudget,
    [formik.values.revenueRecognitionMethod, formik.values.useBudget, formik.values.billingTypeId],
  );

  const monthlyBudgetDisabled = useMemo(
    () => formik.values.billingTypeId === 'fixed' && !formik.values.useBudget,
    [formik.values.billingTypeId, formik.values.useBudget],
  );

  const handleEmailChange = (event) => {
    const value = event.target.value;
    formik.setFieldValue('email', value);

    const values = value.split(/[\s,;]+/).filter(Boolean);
    formik.setFieldValue('emails', values);
  };

  const handleCcEmailChange = (event) => {
    const value = event.target.value;
    formik.setFieldValue('ccEmail', value);

    const values = value.split(/[\s,;]+/).filter(Boolean);
    formik.setFieldValue('ccEmails', values);
  };

  const processEmailErrors = (errors) => {
    if (!errors || !errors.map) {
      return errors;
    }
    return errors.map((error) => {
      if (typeof error !== 'string') {
        return error;
      }
      return error.replace(/emails\[(\d+)\]/, (match, p1) => `To Email Address #${parseInt(p1) + 1}`);
    });
  };

  const processCcEmailErrors = (errors) => {
    if (!errors || !errors.map) {
      return errors;
    }
    return errors.map((error) => {
      if (typeof error !== 'string') {
        return error;
      }
      return error.replace(/ccEmails\[(\d+)\]/, (match, p1) => `Cc Email Address #${parseInt(p1) + 1}`);
    });
  };

  const confirmation = useConfirmation();

  const features = useFeatures();
  const api = useApi();
  const toast = useToast();

  const [clientGraph, setClientGraph] = useState(null);

  useEffect(() => {
    (async () => {
      const { data } = await api.www.workspaces(workspace.id).projects(project.id).client();

      setClientGraph(data);
    })();
  }, [client.id, project.id, api, workspace.id]);

  // The initial project graph does not include client permissions. So, we need to get them from the client graph. If the client changes, the selector already returns the permissions.
  const clientPermissions = formik.values.client.permissions ?? clientGraph?.permissions;

  const [timeApproval, setTimeApproval] = useState();

  const handleTimeApprovalEdit = async () => {
    const { data: timeApproval } = await api.www.workspaces(workspace.id).projects(project.id).timeApproval.get();
    setTimeApproval(timeApproval || {});
  };

  const handleTimeApprovalSubmit = async (value) => {
    const submit = async () => {
      try {
        await api.www.workspaces(workspace.id).projects(project.id).timeApproval.update(value);
        toast.success('The time approval workflow has been successfully updated.');
        setTimeApproval(null);
      } catch (error) {
        toast.error('An error has occurred updating the time approval workflow.');
      }
    };

    if (timeApproval.hasActiveWorkflows) {
      confirmation.prompt((resolve) => {
        const handleResolve = async (confirm) => {
          if (confirm) await submit();
          resolve();
        };

        return (
          <Confirmation resolve={handleResolve} title="Update Workflow">
            Are you sure that you want to update this approval workflow? This will restart the workflow for time entries
            on this project that are not in the Approved state.
          </Confirmation>
        );
      });
    } else {
      await submit();
    }
  };

  const [expenseApproval, setExpenseApproval] = useState();

  const handleExpenseApprovalEdit = async () => {
    const { data: expenseApproval } = await api.www.workspaces(workspace.id).projects(project.id).expenseApproval.get();
    setExpenseApproval(expenseApproval || {});
  };

  const handleExpenseApprovalSubmit = async (value) => {
    const submit = async () => {
      try {
        await api.www.workspaces(workspace.id).projects(project.id).expenseApproval.update(value);
        toast.success('The expense approval workflow has been successfully updated.');
        setExpenseApproval(null);
      } catch (error) {
        toast.error('An error has occurred updating the expense approval workflow.');
      }
    };

    if (expenseApproval.hasActiveWorkflows) {
      confirmation.prompt((resolve) => {
        const handleResolve = async (confirm) => {
          if (confirm) await submit();
          resolve();
        };

        return (
          <Confirmation resolve={handleResolve} title="Update Workflow">
            Are you sure that you want to update this approval workflow? This will restart the workflow for expense
            items on this project that are not in the Approved state.
          </Confirmation>
        );
      });
    } else {
      await submit();
    }
  };

  const handleTaxableItemsChange = (name) => {
    let fields;
    if (formik.values.invoiceTaxableItems.some((v) => v === name)) {
      fields = formik.values.invoiceTaxableItems.filter((v) => v !== name);
    } else {
      fields = [...formik.values.invoiceTaxableItems, name];
    }
    formik.setFieldValue('invoiceTaxableItems', fields);
  };

  const getRefByName = (name) => {
    switch (name) {
      case 'invoiceSubject':
        return invoiceSubjectRef;
      case 'invoiceNotes':
        return invoiceNotesRef;
      case 'invoiceEmailSubject':
        return invoiceEmailSubjectRef;
      case 'invoiceEmailBody':
        return invoiceEmailBodyRef;
      default:
        return null;
    }
  };

  const handleOpenInvoiceVariablesDialog = async (name) => {
    await confirmation.prompt((resolve) => (
      <InvoiceVariablesDialog
        onClose={() => resolve(true)}
        onSaved={(variables) => {
          const ref = getRefByName(name);

          let selectionStart = ref.current.selectionStart;
          if (!formik.touched[name]) {
            selectionStart = formik.values[name].length;
          }

          const updatedValue =
            formik.values[name].slice(0, selectionStart) +
            variables.join(' ') +
            formik.values[name].slice(selectionStart);

          formik.setFieldValue(name, updatedValue);
          resolve(true);
        }}
      />
    ));
  };

  const handleCurrencyChange = ({ target: { value } }) => {
    // Only reset the purchase order if the currency actually changed
    if (value !== formik.values.currency) {
      formik.setValues({
        ...formik.values,
        currency: value,
        purchaseOrder: null,
      });
    }
  };

  const handleBusinessUnitChange = (event) => {
    const value = event.target.value;

    if (!value && formik.values.invoiceDetailsSource === 'business_unit')
      formik.setFieldValue('invoiceDetailsSource', 'client');

    formik.setFieldValue('businessUnit', value);
  };

  const handleRevenueRecognitionMethodChange = (event) => {
    const values = { ...formik.values, revenueRecognitionMethod: event.target.value };

    switch (event.target.value) {
      case 'automated_hours':
        values.useBudget = true;
        break;

      case 'automated_tm_basis':
        values.useBudget = true;
        if (values.budgetMode === '') values.budgetMode = 'detailed';
        break;
    }

    formik.setValues(values);
  };

  return (
    <>
      <Form.Section title="Basic Information">
        <Form.Control>
          <Field.ClientSelect
            name="client"
            placeholder="Client"
            initialValue={project.client}
            allowNew
            disabled={locked}
            clearable={false}
            onChange={handleClientChange}
          />
        </Form.Control>
        <Form.Control>
          <Field.Text ref={firstFieldRef} name="name" placeholder="Project Name" maxLength={255} />
        </Form.Control>
        {features.pipeline && auth.pipeline.manage && (
          <Form.Control>
            <OpportunitySelect
              name="opportunity"
              placeholder="Opportunity"
              value={formik.values.opportunity}
              company={formik.values.client?.company}
              initialValue={project.opportunity}
              onChange={(event) => {
                formik.setFieldValue('opportunity', event.target.value);
              }}
            />
          </Form.Control>
        )}
        <Form.Control help="The project identifier used in URLs. Must be unique for the selected client. Changing this will break existing links to the project.">
          <Field.Text name="key" placeholder="Project URL ID" maxLength={255} />
        </Form.Control>
        {features.practices && (
          <Form.Control>
            <Field.PracticeSelect name="practice" placeholder="Practice" allowNew />
          </Form.Control>
        )}
        {features.businessUnits && (
          <Form.Control>
            <Field.BusinessUnitSelect
              name="businessUnit"
              placeholder="Business Unit"
              onChange={handleBusinessUnitChange}
            />
          </Form.Control>
        )}
        {!client.isInternal && (
          <Form.Control>
            <Field.MemberSelect
              name="salesRepresentative"
              placeholder="Sales Representative"
              initialValue={project.salesRepresentative}
            />
          </Form.Control>
        )}
        <Form.Control>
          <Field.ProjectStatusSelect name="statusId" placeholder="Project Status" />
          {statusId === 'completed' && <Field.DayPicker name="completedOn" placeholder="Completed On" />}
        </Form.Control>
        <Form.Control>
          <Field.ProjectTypeSelect
            name="projectType"
            placeholder="Project Type"
            initialValue={project.projectType}
            allowNew
          />
        </Form.Control>
        <Form.Control>
          <Field.DayPicker name="start" placeholder="Start Date" />
          <Field.DayPicker name="end" placeholder="End Date" />
        </Form.Control>
        <Form.Control>
          <Field.Text name="code" placeholder="Project Code" maxLength={255} />
        </Form.Control>
        <Form.Control>
          <Field.Control>
            <ProjectTagMultiSelect
              name="tags"
              placeholder="Tags"
              value={formik.values.tags}
              allowNew
              onChange={({ target: { value } }) => formik.setFieldValue('tags', value)}
            />
          </Field.Control>
        </Form.Control>
        <Form.Control>
          <Field.TextArea name="description" placeholder="Notes" maxLength={5000} />
        </Form.Control>
      </Form.Section>

      <Form.Section title="Settings">
        <Form.Control>
          <LabelTooltip>
            <Field.Checkbox label="Use roles" name="useRoles" disabled={locked} />
            <HelpTooltip
              message="Team members can be assigned to one or more roles and each role will have its own bill rate."
              style={{ marginLeft: '0.5rem' }}
            />
          </LabelTooltip>
        </Form.Control>

        <Form.Control>
          <LabelTooltip>
            <Tooltip
              message={
                budgetDisabled && !formik.errors.useBudget
                  ? 'An overall budget is required for the selected Revenue Recognition Method.'
                  : undefined
              }>
              <Field.Checkbox
                name="useBudget"
                label="Track overall budget"
                onChange={handleUseBudgetChange}
                disabled={budgetDisabled}
              />
            </Tooltip>
          </LabelTooltip>
          <Field.Checkbox name="useMonthlyBudget" label="Track monthly budget" disabled={monthlyBudgetDisabled} />
        </Form.Control>

        {features.projectHealth && (
          <Form.Control>
            <Field.Checkbox name="useHealthReports" label="Track project health" />
          </Form.Control>
        )}

        <Form.Control>
          <LabelTooltip>
            <Field.Checkbox name="lockTimeAndExpenses" label="Lock time and expenses" />
            <HelpTooltip
              message="Prevent time entries and expense items on this project from being created or updated."
              style={{ marginLeft: '0.5rem' }}
            />
          </LabelTooltip>
        </Form.Control>

        {client.isInternal && (
          <Form.Control>
            <LabelTooltip>
              <Field.Checkbox name="isProductive" label="Productive Internal Project" />
              <HelpTooltip
                message="All time tracked to this project will count towards a member's productive utilization."
                style={{ marginLeft: '0.5rem' }}
              />
            </LabelTooltip>
          </Form.Control>
        )}

        <Form.Control>
          <Field.Checkbox label="Automatically assign new workspace members to this project" name="autoAssignMembers" />
        </Form.Control>

        <Form.Control>
          <Field.Checkbox
            label="Notify members when they are added to this project team"
            name="assignmentNotifications"
          />
        </Form.Control>
      </Form.Section>

      <Form.Section title="Time Tracking Rules">
        <Form.Control>
          <Field.Checkbox label="Every time entry must have notes" name="requireNotes" />
        </Form.Control>

        <Form.Control>
          <Field.Checkbox label="Every time entry must be associated with a task" name="requireTimeEntryTask" />
        </Form.Control>

        {useRoles && (
          <Form.Control>
            <Field.Checkbox label="Members can only track time to assigned roles" name="trackTimeToAssignedRoles" />
          </Form.Control>
        )}

        <Form.Control>
          <Field.Checkbox label="Time cannot be tracked on a weekend" name="forbidTimeOnWeekends" />
        </Form.Control>

        <Form.Control>
          <Field.Checkbox label="Time cannot be tracked on a holiday" name="forbidTimeOnHolidays" />
        </Form.Control>

        <Form.Control>
          <Inline>
            <Field.Checkbox name="capMaxMemberHoursPerWeek" label="Members can track no more than" />
            <Field.SingleSelect
              name="maxMemberHoursPerWeek"
              materialPlaceholder={false}
              disabled={!formik.values.capMaxMemberHoursPerWeek}
              style={{ width: '5.5rem', margin: '0 0.5rem', textAlign: 'right' }}>
              {[...Array(60).keys()].map((_, idx) => (
                <option key={idx} value={idx + 1}>
                  {idx + 1}
                </option>
              ))}
            </Field.SingleSelect>
            <span>hours in a week</span>
          </Inline>
        </Form.Control>

        <Form.Control>
          <Inline>
            <Field.Checkbox name="capMaxMemberHoursPerDay" label="Members can track no more than" />
            <Field.SingleSelect
              name="maxMemberHoursPerDay"
              materialPlaceholder={false}
              disabled={!formik.values.capMaxMemberHoursPerDay}
              style={{ width: '5.5rem', margin: '0 0.5rem', textAlign: 'right' }}>
              {[...Array(12).keys()].map((_, idx) => (
                <option key={idx} value={idx + 1}>
                  {idx + 1}
                </option>
              ))}
            </Field.SingleSelect>
            <span>hours in a day</span>
          </Inline>
        </Form.Control>
      </Form.Section>

      <Form.Section title="Approval Options">
        <div>
          Time approval:
          <Form.Control style={{ alignItems: 'center', height: '2.5rem' }}>
            <Field.RadioGroup name="isTimeApprovalRequired">
              <Radio value={false} label="Automatic" />
              <Radio value={true} label="Manual" />
            </Field.RadioGroup>

            {features.approvalWorkflow && (
              <div style={{ flex: '.4' }}>
                <Button isOutline disabled={!formik.values.isTimeApprovalRequired} onClick={handleTimeApprovalEdit}>
                  Edit Workflow
                </Button>
              </div>
            )}
          </Form.Control>
        </div>

        <div style={{ marginTop: '1rem' }}>
          Expense approval:
          <Form.Control style={{ alignItems: 'center', height: '2.5rem' }}>
            <Field.RadioGroup name="isExpensesApprovalRequired">
              <Radio value={false} label="Automatic" />
              <Radio value={true} label="Manual" />
            </Field.RadioGroup>

            {features.approvalWorkflow && (
              <div style={{ flex: '.4' }}>
                <Button
                  isOutline
                  disabled={!formik.values.isExpensesApprovalRequired}
                  onClick={handleExpenseApprovalEdit}>
                  Edit Workflow
                </Button>
              </div>
            )}
          </Form.Control>
        </div>

        {client?.isInternal === false && features.clientApprovals && (
          <Form.Control style={{ marginTop: '1rem' }}>
            <LabelTooltip>
              <Field.Checkbox label="Enable client approvals" name="enableClientApprovals" />
              <HelpTooltip
                message="Allows you to send time and expense approvals to the client."
                style={{ marginLeft: '0.5rem' }}
              />
            </LabelTooltip>
          </Form.Control>
        )}
      </Form.Section>

      <Form.Section title="Billing">
        <Form.Control>
          <Field.WorkspaceCurrencySelect
            name="currency"
            clearable={false}
            onChange={handleCurrencyChange}
            disabled={locked || (!features.multicurrency && project.currency === workspace.currency)}
          />
        </Form.Control>

        <Form.Control>
          <Field.ProjectBillingTypeSelect
            name="billingTypeId"
            placeholder="Billing Type"
            disabled={locked || client?.isInternal}
            onChange={handleBillingTypeChange}
          />
        </Form.Control>

        {isBillable && (
          <>
            {['fixed', 'fixed_recurring'].includes(billingTypeId) && (
              <>
                <Form.Control>
                  <Field.SingleSelect
                    placeholder="Revenue Recognition Method"
                    name="revenueRecognitionMethod"
                    disabled={!features.manualRevenueRecognition}
                    onChange={handleRevenueRecognitionMethodChange}>
                    {_.map(revenueRecognitionMethods, (option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Field.SingleSelect>
                </Form.Control>

                <Form.Control help={<>How recognized revenue will be attributed to project team members.</>}>
                  <Field.SingleSelect placeholder="Revenue Attribution Method" name="revenueAttributionMethod">
                    {_.map(revenueAttributionMethods, (option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Field.SingleSelect>
                </Form.Control>
              </>
            )}

            {!formik.values.purchaseOrder && (
              <Form.Control>
                <Field.Text name="poNumber" placeholder="PO Number" maxLength={255} />
              </Form.Control>
            )}

            {features.purchaseOrders && (
              <Form.Control>
                <ProjectPurchaseOrderSelect
                  name="purchaseOrder"
                  project={project}
                  clientId={formik.values.client?.id}
                  currency={formik.values.currency}
                  value={formik.values.purchaseOrder}
                  initialValue={project.purchaseOrder}
                  onChange={({ target: { value } }) => formik.setFieldValue('purchaseOrder', value)}
                  disabled={!clientPermissions?.manageDraftInvoices}
                  clearable={clientPermissions?.manageDraftInvoices}
                />
              </Form.Control>
            )}
          </>
        )}
      </Form.Section>

      {client && !client.isInternal && (
        <Form.Section title="Invoice details">
          <Form.Control>
            <Field.RadioGroup name="invoiceDetailsSource">
              <LabelTooltip>
                <Radio value="client" label="Use client defaults" />
                {client.invoiceDetailsSource === 'workspace' && (
                  <HelpTooltip
                    message="This client uses the workspace invoice details."
                    style={{ marginLeft: '0.5rem' }}
                  />
                )}
                {client.invoiceDetailsSource === 'business_unit' && (
                  <HelpTooltip
                    message="This client uses the business unit invoice details."
                    style={{ marginLeft: '0.5rem' }}
                  />
                )}
              </LabelTooltip>
              {features.businessUnits && (
                <Tooltip
                  message={
                    formik.values.businessUnit ? undefined : 'A Business Unit has not been selected for this project.'
                  }>
                  <Radio
                    value="business_unit"
                    label="Use Business Unit defaults"
                    disabled={!formik.values.businessUnit}
                  />
                </Tooltip>
              )}
              <Radio value="custom" label="Use custom invoice values" />
            </Field.RadioGroup>
          </Form.Control>

          {(() => {
            switch (formik.values.invoiceDetailsSource) {
              case 'client':
                switch (formik.values.client.invoiceDetailsSource) {
                  case 'workspace':
                    return (
                      <>
                        <Form.Control help="Can contain multiple emails separated by a comma, semicolon, or space.">
                          <ReadTextbox label="To Email Address" value={client.emails.join(', ')} />
                        </Form.Control>
                        <Form.Control help="Can contain multiple emails separated by a comma, semicolon, or space.">
                          <ReadTextbox label="Cc Email Address" value={client.ccEmails.join(', ')} />
                        </Form.Control>
                        <Form.Control>
                          <ReadTextbox label="Client's Invoice Street Address" textarea value={client.streetAddress} />
                        </Form.Control>
                        <Form.Control>
                          <Field.PaymentTermsSelect
                            name="paymentTermsId"
                            placeholder="Payment Terms"
                            disabled
                            value={workspace.paymentTermsId}
                          />
                        </Form.Control>
                        <Form.Control>
                          <Field.TaxRateSelect
                            name="invoiceTaxRate"
                            placeholder="Tax Rate"
                            clearable={false}
                            disabled
                            value={workspace.invoiceTaxRate}
                          />
                        </Form.Control>
                        <ControlLabel>The following items are taxable by default:</ControlLabel>
                        <Form.Control>
                          <Checkboxes>
                            {_.map(taxableItems, (value, key) => (
                              <Checkbox
                                key={key}
                                label={value}
                                checked={workspace.invoiceTaxableItems.some((v) => v === key)}
                                disabled
                              />
                            ))}
                          </Checkboxes>
                        </Form.Control>
                        <Form.Control>
                          <ReadTextbox label="Invoice Subject" value={workspace.invoiceSubject} />
                        </Form.Control>
                        <Form.Control>
                          <ReadTextbox label="Invoice Note" textarea value={workspace.invoiceNotes} />
                        </Form.Control>
                        <Form.Control>
                          <ReadTextbox label="Invoice Email Subject" value={workspace.invoiceEmailSubject} />
                        </Form.Control>
                        <Form.Control>
                          <ReadTextbox label="Invoice Email Body" textarea value={workspace.invoiceEmailBody} />
                        </Form.Control>
                      </>
                    );
                  case 'business_unit':
                    return (
                      <>
                        <Form.Control help="Can contain multiple emails separated by a comma, semicolon, or space.">
                          <ReadTextbox label="To Email Address" value={client.emails.join(', ')} />
                        </Form.Control>
                        <Form.Control help="Can contain multiple emails separated by a comma, semicolon, or space.">
                          <ReadTextbox label="Cc Email Address" value={client.ccEmails.join(', ')} />
                        </Form.Control>
                        <Form.Control>
                          <ReadTextbox label="Client's Invoice Street Address" textarea value={client.streetAddress} />
                        </Form.Control>
                        <Form.Control>
                          <Field.PaymentTermsSelect
                            name="paymentTermsId"
                            placeholder="Payment Terms"
                            disabled
                            value={client.businessUnit.paymentTermsId}
                          />
                        </Form.Control>
                        <Form.Control>
                          <Field.TaxRateSelect
                            name="invoiceTaxRate"
                            placeholder="Tax Rate"
                            clearable={false}
                            disabled
                            value={client.businessUnit.invoiceTaxRate}
                          />
                        </Form.Control>
                        <ControlLabel>The following items are taxable by default:</ControlLabel>
                        <Form.Control>
                          <Checkboxes>
                            {_.map(taxableItems, (value, key) => (
                              <Checkbox
                                key={key}
                                label={value}
                                checked={client.businessUnit.invoiceTaxableItems.some((v) => v === key)}
                                disabled
                              />
                            ))}
                          </Checkboxes>
                        </Form.Control>
                        <Form.Control>
                          <ReadTextbox label="Invoice Subject" value={client.businessUnit.invoiceSubject} />
                        </Form.Control>
                        <Form.Control>
                          <ReadTextbox label="Invoice Note" textarea value={client.businessUnit.invoiceNotes} />
                        </Form.Control>
                        <Form.Control>
                          <ReadTextbox label="Invoice Email Subject" value={client.businessUnit.invoiceEmailSubject} />
                        </Form.Control>
                        <Form.Control>
                          <ReadTextbox
                            label="Invoice Email Body"
                            textarea
                            value={client.businessUnit.invoiceEmailBody}
                          />
                        </Form.Control>
                      </>
                    );
                  case 'custom':
                    return (
                      <>
                        <Form.Control help="Can contain multiple emails separated by a comma, semicolon, or space.">
                          <ReadTextbox label="To Email Address" value={client.emails.join(', ')} />
                        </Form.Control>
                        <Form.Control help="Can contain multiple emails separated by a comma, semicolon, or space.">
                          <ReadTextbox label="Cc Email Address" value={client.ccEmails.join(', ')} />
                        </Form.Control>
                        <Form.Control>
                          <ReadTextbox label="Client's Invoice Street Address" textarea value={client.streetAddress} />
                        </Form.Control>
                        <Form.Control>
                          <Field.PaymentTermsSelect
                            name="paymentTermsId"
                            placeholder="Payment Terms"
                            disabled
                            value={client.paymentTermsId}
                          />
                        </Form.Control>
                        <Form.Control>
                          <Field.TaxRateSelect
                            name="invoiceTaxRate"
                            placeholder="Tax Rate"
                            clearable={false}
                            disabled
                            value={client.invoiceTaxRate}
                          />
                        </Form.Control>
                        <ControlLabel>The following items are taxable by default:</ControlLabel>
                        <Form.Control>
                          <Checkboxes>
                            {_.map(taxableItems, (value, key) => (
                              <Checkbox
                                key={key}
                                label={value}
                                checked={client.invoiceTaxableItems.some((v) => v === key)}
                                disabled
                              />
                            ))}
                          </Checkboxes>
                        </Form.Control>
                        <Form.Control>
                          <ReadTextbox label="Invoice Subject" value={client.invoiceSubject} />
                        </Form.Control>
                        <Form.Control>
                          <ReadTextbox label="Invoice Note" textarea value={client.invoiceNotes} />
                        </Form.Control>
                        <Form.Control>
                          <ReadTextbox label="Invoice Email Subject" value={client.invoiceEmailSubject} />
                        </Form.Control>
                        <Form.Control>
                          <ReadTextbox label="Invoice Email Body" textarea value={client.invoiceEmailBody} />
                        </Form.Control>
                      </>
                    );
                }
                break;
              case 'business_unit':
                return (
                  <>
                    <Form.Control help="Can contain multiple emails separated by a comma, semicolon, or space.">
                      <Field.Text
                        name="emails"
                        value={formik.values.email}
                        placeholder="To Email Address"
                        onChange={handleEmailChange}
                        onProcessErrors={processEmailErrors}
                      />
                    </Form.Control>
                    <Form.Control help="Can contain multiple emails separated by a comma, semicolon, or space.">
                      <Field.Text
                        name="ccEmails"
                        value={formik.values.ccEmail}
                        placeholder="Cc Email Address"
                        onChange={handleCcEmailChange}
                        onProcessErrors={processCcEmailErrors}
                      />
                    </Form.Control>
                    <Form.Control>
                      <Form.Control>
                        <Field.TextArea
                          name="streetAddress"
                          placeholder="Client's Invoice Street Address"
                          maxLength={5000}
                          rows={4}
                        />
                      </Form.Control>
                    </Form.Control>
                    <Form.Control>
                      <Field.PaymentTermsSelect
                        name="paymentTermsId"
                        placeholder="Payment Terms"
                        disabled
                        value={formik.values.businessUnit.paymentTermsId}
                      />
                    </Form.Control>
                    <Form.Control>
                      <Field.TaxRateSelect
                        name="invoiceTaxRate"
                        placeholder="Tax Rate"
                        clearable={false}
                        disabled
                        value={formik.values.businessUnit.invoiceTaxRate}
                      />
                    </Form.Control>
                    <ControlLabel>The following items are taxable by default:</ControlLabel>
                    <Form.Control>
                      <Checkboxes>
                        {_.map(taxableItems, (value, key) => (
                          <Checkbox
                            key={key}
                            label={value}
                            checked={formik.values.businessUnit.invoiceTaxableItems.some((v) => v === key)}
                            disabled
                          />
                        ))}
                      </Checkboxes>
                    </Form.Control>
                    <Form.Control>
                      <ReadTextbox label="Invoice Subject" value={formik.values.businessUnit.invoiceSubject} />
                    </Form.Control>
                    <Form.Control>
                      <ReadTextbox label="Invoice Note" textarea value={formik.values.businessUnit.invoiceNotes} />
                    </Form.Control>
                    <Form.Control>
                      <ReadTextbox
                        label="Invoice Email Subject"
                        value={formik.values.businessUnit.invoiceEmailSubject}
                      />
                    </Form.Control>
                    <Form.Control>
                      <ReadTextbox
                        label="Invoice Email Body"
                        textarea
                        value={formik.values.businessUnit.invoiceEmailBody}
                      />
                    </Form.Control>
                  </>
                );
              case 'custom':
                return (
                  <>
                    <Form.Control help="Can contain multiple emails separated by a comma, semicolon, or space.">
                      <Field.Text
                        name="emails"
                        value={formik.values.email}
                        placeholder="To Email Address"
                        onChange={handleEmailChange}
                        onProcessErrors={processEmailErrors}
                      />
                    </Form.Control>
                    <Form.Control help="Can contain multiple emails separated by a comma, semicolon, or space.">
                      <Field.Text
                        name="ccEmails"
                        value={formik.values.ccEmail}
                        placeholder="Cc Email Address"
                        onChange={handleCcEmailChange}
                        onProcessErrors={processCcEmailErrors}
                      />
                    </Form.Control>
                    <Form.Control>
                      <Field.TextArea
                        name="streetAddress"
                        placeholder="Client's Invoice Street Address"
                        maxLength={5000}
                        rows={4}
                      />
                    </Form.Control>
                    <Form.Control>
                      <Field.PaymentTermsSelect name="paymentTermsId" placeholder="Payment Terms" />
                    </Form.Control>
                    <Form.Control>
                      <Field.TaxRateSelect name="invoiceTaxRate" placeholder="Tax Rate" />
                    </Form.Control>
                    <ControlLabel>The following items are taxable by default:</ControlLabel>
                    <Form.Control>
                      <Checkboxes>
                        {_.map(taxableItems, (value, key) => (
                          <Checkbox
                            key={key}
                            label={value}
                            checked={formik.values.invoiceTaxableItems.some((v) => v === key)}
                            onChange={() => handleTaxableItemsChange(key)}
                          />
                        ))}
                      </Checkboxes>
                    </Form.Control>
                    <VariablesContainer>
                      <StyledLink onClick={() => handleOpenInvoiceVariablesDialog('invoiceSubject')}>
                        Insert Variables
                      </StyledLink>
                      <Form.Control>
                        <Field.Text
                          ref={invoiceSubjectRef}
                          name="invoiceSubject"
                          placeholder="Invoice Subject"
                          maxLength={255}
                        />
                      </Form.Control>
                    </VariablesContainer>
                    <VariablesContainer>
                      <StyledLink onClick={() => handleOpenInvoiceVariablesDialog('invoiceNotes')}>
                        Insert Variables
                      </StyledLink>
                      <Form.Control>
                        <Field.TextArea
                          ref={invoiceNotesRef}
                          name="invoiceNotes"
                          placeholder="Invoice Note"
                          maxLength={5000}
                        />
                      </Form.Control>
                    </VariablesContainer>
                    <VariablesContainer>
                      <StyledLink onClick={() => handleOpenInvoiceVariablesDialog('invoiceEmailSubject')}>
                        Insert Variables
                      </StyledLink>
                      <Form.Control>
                        <Field.Text
                          ref={invoiceEmailSubjectRef}
                          name="invoiceEmailSubject"
                          placeholder="Invoice Email Subject"
                          maxLength={255}
                        />
                      </Form.Control>
                    </VariablesContainer>
                    <VariablesContainer>
                      <StyledLink onClick={() => handleOpenInvoiceVariablesDialog('invoiceEmailBody')}>
                        Insert Variables
                      </StyledLink>
                      <Form.Control>
                        <Field.TextArea
                          ref={invoiceEmailBodyRef}
                          name="invoiceEmailBody"
                          placeholder="Invoice Email Body"
                          maxLength={5000}
                        />
                      </Form.Control>
                    </VariablesContainer>
                  </>
                );
            }
          })()}
        </Form.Section>
      )}

      {isSlackConnected && (
        <Form.Section title="Slack" help="Assign a project channel to get project updates in Slack.">
          <Form.Control>
            <SlackChannelSelect name="slackChannelId" />
          </Form.Control>
        </Form.Section>
      )}

      <Form.Section
        title="Cloud Folder"
        help="Assign a shared cloud folder to this project to let team members view files.">
        <Form.Control>
          <div>
            <Field.Text name="cloudFolderUrl" placeholder="Cloud Folder URL" maxLength={1024} />
          </div>

          {!!formik.values.cloudFolderUrl && Yup.string().url().isValidSync(formik.values.cloudFolderUrl) && (
            <div style={{ flex: '0 0 3rem', alignSelf: 'center' }}>
              <CloudFolderLink url={formik.values.cloudFolderUrl} />
            </div>
          )}
        </Form.Control>
      </Form.Section>

      {client && !client.isInternal && QBOProjectsEnabled && (
        <Form.Section title="QuickBooks" help="Select a QuickBooks project.">
          <Form.Control>
            <Field.QBOProjectSelect
              name="qboProjectId"
              placeholder="QuickBooks Project"
              placement="top"
              include={project.qboProjectId}
              disabled={!project.qboProjectId && !client.qboCustomerId}
              qboCustomerId={client.qboCustomerId}
              showEmptyOption
            />

            {!client.qboCustomerId && (
              <div style={{ flex: 0, display: 'flex', alignItems: 'center' }}>
                <Tooltip message="This project's client is not mapped to a QuickBooks customer.">
                  <Icon icon="exclamation-triangle" color={colors.warning} />
                </Tooltip>
              </div>
            )}
          </Form.Control>
        </Form.Section>
      )}

      {timeApproval && (
        <ApprovalWorkflowEditor
          value={timeApproval}
          onSubmit={handleTimeApprovalSubmit}
          onClose={() => setTimeApproval(false)}
          onLoadWorkspaceWorkflow={api.www.workspaces(workspace.id).timeApproval.get}
        />
      )}

      {expenseApproval && (
        <ApprovalWorkflowEditor
          value={expenseApproval}
          onSubmit={handleExpenseApprovalSubmit}
          onClose={() => setExpenseApproval(false)}
          onLoadWorkspaceWorkflow={api.www.workspaces(workspace.id).expenseApproval.get}
        />
      )}
    </>
  );
}

export default ProjectForm;
