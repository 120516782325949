import React, { useCallback, useEffect, useState } from 'react';
import { useApi, useWorkspace } from '~/contexts';
import { PageLoader } from '~/routes/public/pages';

const FavoriteReportsContext = React.createContext();

function FavoriteReportsProvider({ children }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [favorites, setFavorites] = useState(null);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().favorites.get();
    setFavorites(data);
  }, [api, workspace.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const toggle = useCallback(
    async (reportKey, flag) => {
      if (flag) {
        await api.www.workspaces(workspace.id).reports().favorites.add(reportKey);
      } else {
        await api.www.workspaces(workspace.id).reports().favorites.remove(reportKey);
      }

      await fetchData();
    },
    [api, fetchData, workspace.id],
  );

  return (
    <FavoriteReportsContext.Provider value={{ favorites, toggle }}>
      {favorites === null ? <PageLoader /> : children}
    </FavoriteReportsContext.Provider>
  );
}

export { FavoriteReportsContext, FavoriteReportsProvider };
