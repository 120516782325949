import {
  DeleteConfirmation,
  ExportDialog,
  SearchInput,
  ExportDropdown,
  Page,
  BackLink,
  IconButton,
  FiltersBar,
  ListView,
  ListViewActions,
  ListViewMenu,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { mimeTypes } from '~/utils';
import { ActiveStatusSelect } from '../custom-data/components';
import ExpenseCategoryDrawer from './ExpenseCategoryDrawer';

function ExpenseCategoriesPage() {
  const documentTitle = useDocumentTitle('Expense Categories');

  const api = useApi();
  const { workspace } = useWorkspace();
  const confirmation = useConfirmation();
  const toast = useToast();

  const [query, setQuery] = useState({ isReady: false, data: null, error: null });
  const [params, setParams] = useState({ q: '', isActive: 'true', size: 1000 });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .expenseCategories()
        .get({ ...params, isActive: params.isActive ?? undefined });
      setQuery({ isReady: true, data });
    } catch (error) {
      setQuery({ isReady: true, data: null, error });
    }
  }, [workspace.id, params, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [dialog, setDialog] = useState(null);

  const handleFilterChange = ({ target: { name, value } }) => {
    setParams((state) => ({ ...state, [name]: value }));
  };

  const handleExport = async (filename, mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onClose={resolve}
        onLoad={api.www
          .workspaces(workspace.id)
          .expenseCategories()
          .get(
            { ...params, isActive: params.isActive ?? undefined },
            { headers: { accept: mimeType }, responseType: 'blob' },
          )}
      />
    ));
  };

  const handleEdit = async (expenseCategory) => {
    setDialog(
      <ExpenseCategoryDrawer
        expenseCategory={expenseCategory}
        onClose={() => {
          setDialog(null);
          documentTitle.set('Expense Categories');
        }}
        onDeleted={fetchData}
        onSaved={fetchData}
      />,
    );
  };

  async function handleActiveStatusChange(item, flag) {
    try {
      await api.www.workspaces(workspace.id).expenseCategories(item.id).setActiveStatus(flag);
      await fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  async function handleDelete(expenseCategory) {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this Expense Category?</DeleteConfirmation>
    ));
    if (!confirm) return;

    try {
      await api.www
        .workspaces(workspace.id)
        .expenseCategories(expenseCategory ? expenseCategory.id : undefined)
        .delete();
      await fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  if (!query.isReady) return <PageLoader />;
  if (!query.data) return <ErrorPage publicSite={false} />;

  const data = query.data;

  return (
    <>
      <Page.Header>
        <BackLink defaultPath={`/app/${workspace.key}/settings/custom-data`} />

        <Page.Info>
          <Page.Title>Expense Categories</Page.Title>
          <Page.Description>
            Each item on an expense report must be assigned an expense category. Also, you can build an expense budget
            for a project using expense categories.
          </Page.Description>
        </Page.Info>

        <Page.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('expense_categories.csv', mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('expense_categories.xlsx', mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <IconButton icon="plus" tooltip="New Expense Category" onClick={() => handleEdit({})} />
        </Page.Actions>
      </Page.Header>

      <Page.Filters>
        <FiltersBar>
          <SearchInput value={params.q} placeholder="Search" onChange={handleFilterChange} />

          <ActiveStatusSelect value={params.isActive} onChange={handleFilterChange} />
        </FiltersBar>
      </Page.Filters>

      <Page.ListView>
        <ListView>
          <ListView.Header>
            <ListView.Column name="name">Name</ListView.Column>
            <ListViewActions.Column />
          </ListView.Header>
          <ListView.Body>
            {data.map((expenseCategory) => {
              const { name, isActive, id } = expenseCategory;

              return (
                <ListView.Row onClick={() => handleEdit(expenseCategory)} key={id} isDisabled={!isActive}>
                  <ListView.Cell>{name}</ListView.Cell>
                  <ListViewActions>
                    <>
                      <ListViewActions.Edit onClick={() => handleEdit(expenseCategory)} />

                      <hr />

                      <ListViewMenu>
                        {({ setIsOpen }) => (
                          <>
                            <ListViewMenu.Item onClick={() => handleEdit(expenseCategory)}>Edit</ListViewMenu.Item>
                            <ListViewMenu.Item
                              onClick={async () => {
                                await handleActiveStatusChange(expenseCategory, !isActive);
                                setIsOpen(false);
                              }}>
                              {isActive ? 'Deactivate' : 'Activate'}
                            </ListViewMenu.Item>
                            <ListViewMenu.DeleteItem
                              tooltip="This item is currently in use."
                              onCheckDependencies={async (workspace) =>
                                (await workspace.expenseCategories(id).hasDependencies()).data
                              }
                              onClick={() => handleDelete(expenseCategory)}>
                              Delete
                            </ListViewMenu.DeleteItem>
                          </>
                        )}
                      </ListViewMenu>
                    </>
                  </ListViewActions>
                </ListView.Row>
              );
            })}

            {data.length === 0 && <ListView.Empty />}
          </ListView.Body>
          <ListView.Status total={data.length} label="Expense Category" />
        </ListView>
      </Page.ListView>

      {dialog}
    </>
  );
}

export default ExpenseCategoriesPage;
