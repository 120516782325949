import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Currency, Grid, Widget } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { colors, weights } from '~/styles';

const StyledWidget = styled(Widget)`
  background-color: ${colors.white};
  padding: 0;
`;

function InvoicesSummaryWidget({ params }) {
  const { workspace } = useWorkspace();
  const api = useApi();

  const [query, setQuery] = useState({ isReady: false, data: null });

  const load = () => {
    setQuery(() => ({
      isReady: false,
    }));
  };

  const ready = (data) => {
    setQuery(() => ({
      isReady: true,
      ...data,
    }));
  };

  const fetchData = useCallback(async () => {
    load();

    const { data } = await api.www.workspaces(workspace.id).invoices().openInvoicesKpi(params);

    ready({ data });
  }, [workspace.id, api, params]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const { data, isReady } = query;

  return (
    <StyledWidget loading={!isReady}>
      <Widget.Preview style={{ minHeight: '4.875rem' }}></Widget.Preview>
      <Widget.Content style={{ minHeight: '4.875rem' }}>{data && <Data data={data} />}</Widget.Content>
    </StyledWidget>
  );
}

const Boxes = styled.div`
  display: flex;
  border-top: 1px solid ${colors.grey10};
  height: 4.875rem;
`;

const Value = styled.div`
  font-size: 1rem;
  font-weight: ${weights.bold};
  display: flex;
  justify-content: center;
  flex-direction: column;

  &:not(:first-child) {
    margin-top: 0.625rem;
  }
`;

const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: ${({ size = 1 }) => size};

  &:not(:first-child) {
    border-left: 1px solid ${colors.grey10};
  }

  ${({ corner }) =>
    corner &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        border-style: solid;
        border-color: transparent ${corner} transparent transparent;
        border-width: 0 0.75rem 0.75rem 0;
      }
    `}
`;

const Label = styled.small`
  display: block;
  font-size: 0.75rem;
`;

function Data({ data }) {
  return (
    <Grid style={{ flex: 1 }}>
      <Grid.Row style={{ flex: 1 }}>
        <Grid.Column>
          <Boxes>
            <Box>
              <Value>
                <Currency value={data.open} minimumFractionDigits={0} maximumFractionDigits={0} />
              </Value>
              <Label>Open Invoices</Label>
            </Box>
            <Box corner={colors.success}>
              <Value>
                <Currency value={data.current} minimumFractionDigits={0} maximumFractionDigits={0} />
              </Value>
              <Label>Current</Label>
            </Box>
            <Box corner={colors.warning}>
              <Value>
                <Currency value={data.late30} minimumFractionDigits={0} maximumFractionDigits={0} />
              </Value>
              <Label>1 - 30 Days Late</Label>
            </Box>
            <Box corner={colors.danger}>
              <Value>
                <Currency value={data.laterThan30} minimumFractionDigits={0} maximumFractionDigits={0} />
              </Value>
              <Label>&gt; 30 Days Late</Label>
            </Box>
          </Boxes>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default InvoicesSummaryWidget;
