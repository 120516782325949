import { useAuth, useFeatures } from '~/hooks';
import ClientContacts from './ClientContacts';
import ClientsList from './ClientsList';
import History from './History';
import MembersList from './MembersList';
import ProjectHealthByProject from './ProjectHealthByProject';
import ProjectMembers from './ProjectMembers';
import ProjectsList from './ProjectsList';
import ProjectTasksList from './ProjectTasksList';

export default function useWorkspaceReports() {
  const auth = useAuth();
  const features = useFeatures();

  return [
    {
      key: 'client_contacts',
      name: 'Client Contacts',
      description:
        'Client contacts grouped by client including the contact name, title, email address, and phone numbers.',
      path: '/client-contacts',
      component: ClientContacts,
    },
    {
      key: 'clients',
      name: 'Clients',
      description: features.practices
        ? "Clients including each client's practice and relationship owner."
        : 'A list of clients in the workspace.',
      path: '/clients',
      component: ClientsList,
    },
    auth.workspace.manage && {
      key: 'history',
      name: 'History',
      description:
        'History of actions taken in the workspace including the event time, entity type, event type, member and source.',
      path: '/history',
      component: History,
    },
    auth.workspace.manage && {
      key: 'members',
      name: 'Members',
      description: "Workspace members including each member's type, billability, security role, status, and manager.",
      path: '/members',
      component: MembersList,
    },
    {
      key: 'project_team_members',
      name: 'Project Team Members',
      description:
        features.disciplines && features.practices
          ? "Project team members including each member's status, type, security role, practice and discipline."
          : 'A list of projects and the members assigned to each project.',
      path: '/project-team-members',
      component: ProjectMembers,
    },
    {
      key: 'projects',
      name: 'Projects',
      description: features.practices
        ? "Projects including each project's client, project type, status, project admin, billing type and practice."
        : 'A list of projects in the workspace.',
      path: '/projects',
      component: ProjectsList,
    },
    {
      key: 'project_tasks',
      name: 'Project Tasks',
      description:
        "Project Tasks grouped by project, including each task's status, assignment, hours, revenue, and budget.",
      path: '/project-tasks',
      component: ProjectTasksList,
    },
    auth.reports.projectHealthByProject && {
      key: 'project_health_by_project',
      name: 'Project Health By Project',
      description: 'A list of projects and their current project health scoring.',
      path: '/project-health-by-project',
      component: ProjectHealthByProject,
    },
  ].filter(Boolean);
}
