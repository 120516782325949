import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Buttons, CancelButton, Currency, DateTime, Drawer, Form, RouteLink, Table, Tag, Tooltip } from '~/components';
import ReadForm from '~/components/read-only/ReadForm';
import ReadTextbox from '~/components/read-only/ReadTextbox';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useCurrencyFormat, useDocumentTitle, useLocale } from '~/hooks';
import { ErrorPage } from '~/routes/public/pages';
import { colors, weights } from '~/styles';

const Container = styled.div`
  font-size: 0.875rem;
  margin-bottom: 1rem;
`;

const SectionTitle = styled.div`
  width: 12rem;
  padding: 1rem 3rem 1rem 0;
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
`;

export default function PurchaseOrderView({ id, onClose }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const toast = useToast();

  const [query, setQuery] = useState({ isReady: false, data: null });
  const fetchData = useCallback(async () => {
    if (!id) {
      setQuery({ isReady: true, data: {} });
      return;
    }

    try {
      const { data } = await api.www.workspaces(workspace.id).purchaseOrders(id).get();
      setQuery({ isReady: true, data });
    } catch (error) {
      toast.error(error.message || 'There was a problem reading this purchase order. Please try again.');
    }
  }, [workspace.id, id, api, toast]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const purchaseOrder = query?.data;

  const locale = useLocale(purchaseOrder?.client.locale || purchaseOrder?.workspace.locale);
  const symbol = useCurrencyFormat({ locale, currency: purchaseOrder?.currency })
    .formatToParts(1)
    .find((part) => part.type === 'currency').value;

  const title = 'View Purchase Order';
  useDocumentTitle(title);

  if (!query.isReady) return null;
  if (!query.data) return <ErrorPage.NotFound publicSite={false} />;

  return (
    <Drawer isOpen title={title} onClose={onClose}>
      <ReadForm>
        <ReadForm.Section title="Purchase Order">
          <Form.Control>
            <ReadTextbox label="Name" value={purchaseOrder.name} />
          </Form.Control>

          <Form.Control>
            <ReadTextbox label="Number" value={purchaseOrder.number} />
          </Form.Control>

          <Form.Control>
            <ReadTextbox
              label="Amount"
              prefix={symbol}
              value={<Currency value={purchaseOrder.amount} currency={purchaseOrder.currency} />}
            />
          </Form.Control>

          {purchaseOrder.contact && (
            <Form.Control>
              <ReadTextbox label="Contact" value={purchaseOrder.contact.name} />
            </Form.Control>
          )}

          {purchaseOrder.start && (
            <Form.Control>
              <ReadTextbox label="Start Date" value={purchaseOrder.start} />
            </Form.Control>
          )}

          {purchaseOrder.end && (
            <Form.Control>
              <ReadTextbox label="End Date" value={purchaseOrder.end} />
            </Form.Control>
          )}

          {purchaseOrder.notes && (
            <Form.Control>
              <ReadTextbox label="Notes" value={purchaseOrder.notes} />
            </Form.Control>
          )}
        </ReadForm.Section>

        {query.data.invoices.length > 0 && (
          <Container>
            <SectionTitle>Invoices</SectionTitle>
            <Table>
              <Table.BoxHeader>
                <Table.Column width="8rem">Invoice #</Table.Column>
                <Table.Column>Projects</Table.Column>
                <Table.Column align="right">Due Date</Table.Column>
                <Table.Column align="right">Issued On</Table.Column>
                <Table.Column align="right">Total</Table.Column>
              </Table.BoxHeader>

              <Table.Body>
                {query.data.invoices.map((invoice) => {
                  return (
                    <Table.BoxRow key={invoice.id}>
                      <Table.Cell>
                        <RouteLink to={`/app/${workspace.key}/billing/invoices/${invoice.id}`}>
                          #{invoice.number}
                        </RouteLink>
                      </Table.Cell>
                      <Table.Cell>
                        <FirstProject entity={invoice} />
                        <Projects entity={invoice} />
                      </Table.Cell>
                      <Table.Cell>
                        <DateTime value={invoice.dueOn} />
                      </Table.Cell>
                      <Table.Cell>
                        <DateTime value={invoice.dueOn} />
                      </Table.Cell>
                      <Table.Cell>
                        <Currency value={invoice.total} currency={query.data.currency} />
                      </Table.Cell>
                    </Table.BoxRow>
                  );
                })}
              </Table.Body>
            </Table>
          </Container>
        )}

        {query.data.creditNotes.length > 0 && (
          <Container>
            <SectionTitle>Credit Notes</SectionTitle>
            <Table>
              <Table.BoxHeader>
                <Table.Column width="10rem">Credit Note #</Table.Column>
                <Table.Column>Projects</Table.Column>
                <Table.Column align="right">Issued On</Table.Column>
                <Table.Column align="right">Total</Table.Column>
              </Table.BoxHeader>

              <Table.Body>
                {query.data.creditNotes.map((creditNote) => {
                  return (
                    <Table.BoxRow key={creditNote.id}>
                      <Table.Cell>
                        <RouteLink to={`/app/${workspace.key}/billing/credit-notes/${creditNote.id}`}>
                          #{creditNote.number}
                        </RouteLink>
                      </Table.Cell>
                      <Table.Cell>
                        <FirstProject entity={creditNote} />
                        <Projects entity={creditNote} />
                      </Table.Cell>
                      <Table.Cell>
                        <DateTime value={creditNote.issuedOn} />
                      </Table.Cell>
                      <Table.Cell>
                        <Currency value={creditNote.total} currency={query.data.currency} />
                      </Table.Cell>
                    </Table.BoxRow>
                  );
                })}
              </Table.Body>
            </Table>
          </Container>
        )}

        <Drawer.Actions>
          <Buttons align="right">
            <CancelButton onClick={onClose}>Close</CancelButton>
          </Buttons>
        </Drawer.Actions>
      </ReadForm>
    </Drawer>
  );
}

const FirstProject = ({ entity }) => {
  const project = entity.projects[0];
  if (!project) return null;

  return project.name;
};

const Title = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

const Projects = ({ entity }) => {
  let projectsCount = entity.projects.length - 1; // Remove the first project because it already shows a tag
  if (projectsCount <= 0) return null;

  return (
    <Tooltip
      message={
        <div style={{ fontSize: '1rem' }}>
          <Title>Projects</Title>

          {entity.projects.map((projects) => (
            <Tag style={{ backgroundColor: colors.grey5 }} key={projects.id}>
              <small>{projects.name}</small>
            </Tag>
          ))}
        </div>
      }>
      <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40 }}>
        <small>+{projectsCount}</small>
      </Tag>
    </Tooltip>
  );
};
