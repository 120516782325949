import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import Icon from './Icon';
import Tooltip from './Tooltip';
import Currency from './Currency';

const MarkupIconContainer = styled.div`
  background-color: ${colors.green};
  color: white;
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
  font-size: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ marginRight }) => marginRight ?? '0.4375rem'};
  flex-shrink: 0;
`;

function MarkupIcon({ showTooltip = true, tooltip, total, currency, ...props }) {
  return (
    <MarkupIconContainer {...props}>
      {showTooltip ? (
        <Tooltip
          message={
            tooltip ? (
              tooltip
            ) : total ? (
              <div>Billable with markup to {<Currency value={total} currency={currency} />}</div>
            ) : (
              'Billable with markup'
            )
          }>
          <Icon icon="arrow-up" />
        </Tooltip>
      ) : (
        <Icon icon="arrow-up" />
      )}
    </MarkupIconContainer>
  );
}

export default MarkupIcon;
export { MarkupIconContainer };
