import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ApprovalStatusFilter,
  ExportDialog,
  ExportDropdown,
  FiltersBar,
  MemberFilter,
  Page,
  PeriodFilter,
  PracticeFilter,
  ProjectFilter,
  TimeBillableTypeFilter,
} from '~/components';
import { useApi, useConfirmation, useSubscription, useWorkspace } from '~/contexts';
import { useActions, useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { QuerySort, intervalsByScope, intervalOptions, mimeTypes } from '~/utils';
import EditTimeEntry from '../edit-time-entry';
import ViewTimeEntry from '../view-time-entry';
import TimeAuditingResults from './TimeAuditingResults';

const initialState = {
  isReady: false,
  searchParamsStatus: 'pending',
  data: null,
  query: {
    period: null,
    projects: [],
    statuses: [],
    billableTypes: [],
    members: [],
    memberPractices: [],
    page: 0,
    size: 25,
    sort: new QuerySort('date', 'desc'),
  },
  action: 'load',
};

const handlers = {
  load: (values, state) => ({ query: { ...state.query, page: 0 }, action: 'load' }),
  loadMore: (values, state) => {
    if (state.action === null && state.data.total > state.data.results.length) {
      return { query: { ...state.query, page: state.query.page + 1 }, action: 'load-more' };
    }
  },
  ready: ({ data }, state) => ({
    isReady: true,
    action: null,
    data: state.action === 'load-more' ? { ...state.data, results: [...state.data.results, ...data.results] } : data,
  }),
  setParams: (params, state) => ({
    ...state,
    action: 'filter',
    query: { ...state.query, ...params, page: 0 },
    searchParamsStatus: 'ready',
  }),
  updateItems: (items, { data }) => ({
    data: {
      ...data,
      results: data.results.map((result) => {
        let item = items.find((i) => i.id === result.id);
        if (!item) return result;

        item = _.pick(
          item,
          'date',
          'isActuallyBillable',
          'minutes',
          'project',
          'role',
          'statusId',
          'task',
          'timeOffType',
          'timerStartedAt',
        );

        return item ? { ...result, ...item } : result;
      }),
    },
  }),
  removeItem: (id, { data }) => ({
    data: { ...data, results: data.results.filter((i) => i.id !== id), total: data.total - 1 },
  }),
};

function TimeAuditingPage() {
  const documentTitle = useDocumentTitle('Time Auditing');

  const { workspace } = useWorkspace();
  const [{ isReady, data, query, searchParamsStatus, action }, actions] = useActions(handlers, initialState);
  const [drawer, setDrawer] = useState(null);
  const api = useApi();
  const { notify } = useSubscription();
  const features = useFeatures();
  const confirmation = useConfirmation();

  const searchParamsConfig = useSearchParamsConfig();
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: {
          ...searchParamsConfig.period,
          default: intervalOptions.past_30_days,
        },
        statuses: searchParamsConfig.approvalStatuses,
        billableTypes: searchParamsConfig.timeBillableTypes,
        sort: { default: initialState.query.sort, ...searchParamsConfig.sort },
        projects: searchParamsConfig.projects,
        members: searchParamsConfig.members,
        memberPractices: searchParamsConfig.practices,
      }),
      [searchParamsConfig],
    ),
    sessionKey: 'time_auditing',
    onChange: useCallback((params) => actions.setParams(params), [actions]),
  });

  useEffect(() => {
    if (searchParamsStatus !== 'pending') return;
    searchParams.get().then((params) => {
      if (params) actions.setParams(params);
    });
  }, [searchParams, searchParamsStatus, actions]);

  const urlSearchParams = useMemo(() => {
    const { start, end } = query.period || {};

    return {
      statusId: query.statuses?.map((v) => v.id),
      billableTypeId: query.billableTypes?.map((v) => v.id),
      projectId: query.projects?.map((v) => v.id),
      memberId: query.members?.map((v) => v.id),
      memberPracticeId: query.memberPractices?.map((v) => v.id),
      start: start ?? undefined,
      end: end ?? undefined,
    };
  }, [query]);

  const fetchData = useCallback(async () => {
    try {
      const params = { ...urlSearchParams, page: query.page, size: query.size, sort: query.sort };
      const { data } = await api.www.workspaces(workspace.id).timeAdmin().getAuditing(params);

      actions.ready({ data });
    } catch (error) {
      actions.ready({ data: [], members: [] });
    }
  }, [actions, workspace.id, urlSearchParams, api, query.page, query.size, query.sort]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const handleFilterChange = ({ target }) => {
    actions.setParams({ [target.name]: target.value });
    searchParams.set({ [target.name]: target.value });
  };

  const handleResultClick = (entry, mode) => {
    setDrawer({ entry, mode });
  };

  const handleCloseDrawer = () => {
    setDrawer(null);
    documentTitle.set('Time Auditing');
  };

  const handleDelete = async (entry) => {
    await api.www.workspaces(workspace.id).timeAdmin(entry.id).delete();

    handleEntryDeleted(entry);
  };

  const handleEntrySaved = (entry) => {
    actions.updateItems([entry]);
    notify(useSubscription.keys.refresh_timer);
  };

  const handleEntryDeleted = (entry) => {
    actions.removeItem(entry.id);
    notify(useSubscription.keys.refresh_timer);
  };

  const handleTimerChange = (entry) => {
    // Stop all timers for the member
    const entries = data.results
      .filter((e) => !!e.timerStartedAt && e.member.id === entry.member.id)
      .map((e) => ({
        ...e,
        minutes: moment.duration(e.minutes, 'minutes').add(moment().diff(e.timerStartedAt)).asMinutes(),
        timerStartedAt: null,
      }));

    actions.updateItems([...entries, entry]);
    notify(useSubscription.keys_refresh_timer);
  };

  const handleSort = ({ column, sort }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    actions.setParams({ sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleExport = async (filename, mimeType) => {
    await confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .timeAdmin()
          .exportAuditing(
            { ...urlSearchParams, size: null },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  if (!isReady) return <PageLoader />;

  return (
    <>
      <Page scrollable>
        <Page.Header>
          <Page.Info>
            <Page.Eyebrow>Time</Page.Eyebrow>
            <Page.Title>Time Auditing</Page.Title>
          </Page.Info>

          <Page.Actions>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(`time_auditing.csv`, mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(`time_auditing.xlsx`, mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>
          </Page.Actions>
        </Page.Header>

        <Page.Filters>
          <FiltersBar>
            <PeriodFilter
              name="period"
              placeholder="Date Range"
              intervals={[intervalOptions.all_dates, ...intervalsByScope.day]}
              value={query.period}
              onChange={handleFilterChange}
            />

            <ProjectFilter name="projects" value={query.projects} onChange={handleFilterChange} />

            <ApprovalStatusFilter name="statuses" value={query.statuses} onChange={handleFilterChange} />

            <TimeBillableTypeFilter
              name="billableTypes"
              placeholder="Time Type"
              value={query.billableTypes}
              onChange={handleFilterChange}
            />

            {!_.isEmpty(workspace.member.securityRole.viewProjectTimeAndExpenses) && (
              <>
                <MemberFilter name="members" value={query.members} onChange={handleFilterChange} />

                {features.practices && (
                  <PracticeFilter
                    name="memberPractices"
                    placeholder="Member Practice"
                    value={query.memberPractices}
                    onChange={handleFilterChange}
                  />
                )}
              </>
            )}
          </FiltersBar>
        </Page.Filters>

        <Page.ListView>
          <TimeAuditingResults
            data={data}
            sort={query.sort}
            onLoadMore={actions.loadMore}
            onSort={handleSort}
            onResultClick={handleResultClick}
            onDelete={handleDelete}
            action={action}
          />
        </Page.ListView>
      </Page>

      {drawer &&
        {
          edit: () => (
            <EditTimeEntry
              id={drawer.entry.id}
              memberId={drawer.entry.member.id}
              onSubmit={(body) => api.www.workspaces(workspace.id).timeAdmin(drawer.entry.id).update(body)}
              onConfirmDelete={(id) => api.www.workspaces(workspace.id).timeAdmin(id).delete()}
              onSaved={handleEntrySaved}
              onDeleted={handleEntryDeleted}
              onClose={handleCloseDrawer}
              onTimerChange={handleTimerChange}
            />
          ),
          view: () => <ViewTimeEntry id={drawer.entry.id} onClose={handleCloseDrawer} />,
        }[drawer.mode]()}
    </>
  );
}

export default TimeAuditingPage;
