import { ActionButton, Field, Form, FormMessage, Icon, Level, Page, Radio, Stack, Table } from '~/components';
import { SectionTitle } from '~/components/Form';
import { useApi, useSession, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDocumentTitle, useForm } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { mergeValues } from '~/utils';
import * as Yup from 'yup';
import _ from 'lodash';

const HarvestPage = styled.div`
  ${SectionTitle} {
    min-width: 17rem;
    flex: 0.6;
  }

  display: flex;
  flex-direction: column;
  flex: 1;
`;

const SaveButton = styled(ActionButton)`
  width: 8.25rem;
`;

const VerificationStatus = styled.div`
  font-weight: ${weights.bold};
  margin-top: 0.5rem;
  text-align: left;
`;

const ImportDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const SkippedItem = styled.div`
  color: ${colors.grey55};
  font-weight: ${weights.normal};
  font-size: 0.75rem;
  flex: 1;
  padding-top: 0.5rem;
`;

const ImportLog = styled(Level)`
  display: flex;
  flex-direction: column;
`;

const ViewMore = styled.a`
  font-weight: ${weights.normal};
  font-size: 0.75rem;
`;

const ImportOption = styled.div`
  font-weight: ${weights.normal};

  p {
    font-size: 0.75rem;
  }
`;

const currency = new Intl.NumberFormat('en-us', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

function HarvestImportRow({ area, importResults, testResult, activeImport }) {
  const [expanded, setExpanded] = useState(false);
  const areaDisplayNames = {
    users: 'Users',
    clients: 'Clients',
    contacts: 'Contacts',
    projects: 'Projects',
    projectTaskAssignments: 'Project Tasks',
    projectUserAssignments: 'Project Users',
    expenseCategories: 'Expense Categories',
    invoices: 'Invoices',
    expenses: 'Expenses',
    timeEntries: 'Time Entries',
  };
  const importResult = importResults ? importResults[area] : null;

  return (
    <Table.BoxRow>
      <Table.Cell>{importResult && <Icon icon="check" color={colors.success}></Icon>}</Table.Cell>
      <Table.Cell>
        <strong>{areaDisplayNames[area]}</strong>
      </Table.Cell>

      <Table.Cell>{testResult?.counts ? testResult.counts[area] : 0}</Table.Cell>
      <Table.Cell>
        <ImportDetails>
          <Level>
            {area === activeImport
              ? 'Importing...'
              : importResult
                ? `Imported ${importResult.imported ? importResult.imported.length : ''} and skipped ${
                    importResult.skipped ? importResult.skipped.length : ''
                  }`
                : 'Pending'}
          </Level>
          <Level>
            {importResult?.skipped?.length > 0 && <ViewMore onClick={() => setExpanded(!expanded)}>view more</ViewMore>}
          </Level>
          {expanded && (
            <ImportLog>
              {importResult?.skipped && (
                <>
                  {importResult.skipped.map((s) => (
                    <SkippedItemDetail data={s.data} area={area} reason={s.reason} key={s.id}></SkippedItemDetail>
                  ))}
                </>
              )}
            </ImportLog>
          )}
        </ImportDetails>
      </Table.Cell>
    </Table.BoxRow>
  );
}

function SkippedItemDetail({ data, area, reason }) {
  const skippedReasons = {
    duplicate: 'the record was imported previously',
    memberFound: 'a member with a matching email was found',
    timer: 'the record has a running timer',
    invalid: 'the record has invalid data',
  };

  return {
    users: (
      <SkippedItem>
        Skipped{' '}
        <strong>
          {data.first_name} {data.last_name} ({data.email})
        </strong>{' '}
        because {skippedReasons[reason]}.
      </SkippedItem>
    ),
    clients: (
      <SkippedItem>
        Skipped <strong>{data.name}</strong> because {skippedReasons[reason]}.
      </SkippedItem>
    ),
    contacts: (
      <SkippedItem>
        Skipped{' '}
        <strong>
          {data.first_name} {data.last_name}
        </strong>{' '}
        because {skippedReasons[reason]}.
      </SkippedItem>
    ),
    projects: (
      <SkippedItem>
        Skipped <strong>{data.name}</strong>{' '}
        {data.client && (
          <>
            for client <strong>{data.client?.name}</strong>
          </>
        )}{' '}
        because {skippedReasons[reason]}.
      </SkippedItem>
    ),
    projectTaskAssignments: (
      <SkippedItem>
        Skipped <strong>{data.task?.name}</strong> on <strong>{data.project?.name}</strong> for{' '}
        <strong>{data.client?.name}</strong> because {skippedReasons[reason]}.
      </SkippedItem>
    ),
    projectUserAssignments: (
      <SkippedItem>
        Skipped{' '}
        <strong>
          {data.user?.first_name} {data.user?.last_name}
        </strong>{' '}
        on <strong>{data.project?.name}</strong> for <strong>{data.client?.name}</strong> because{' '}
        {skippedReasons[reason]}.
      </SkippedItem>
    ),
    expenseCategories: (
      <SkippedItem>
        Skipped <strong>{data.name}</strong> because {skippedReasons[reason]}.
      </SkippedItem>
    ),
    invoices: (
      <SkippedItem>
        Skipped <strong>#{data.number}</strong> for client <strong>{data.client?.name}</strong> because{' '}
        {skippedReasons[reason]}.
      </SkippedItem>
    ),
    expenses: (
      <SkippedItem>
        Skipped <strong>{currency.format(data.amount)}</strong> entered by <strong>{data.user?.name}</strong> from{' '}
        <strong>{data.date}</strong> on <strong>{data.project?.name}</strong> for <strong>{data.client?.name}</strong>{' '}
        because {skippedReasons[reason]}.
      </SkippedItem>
    ),
    timeEntries: (
      <SkippedItem>
        Skipped <strong>{data.hours}</strong> hours entered by <strong>{data.user?.name}</strong> from{' '}
        <strong>{data.date}</strong> on <strong>{data.project?.name}</strong> for <strong>{data.client?.name}</strong>{' '}
        because {skippedReasons[reason]}.
      </SkippedItem>
    ),
  }[area];
}

export default function HarvestIntegration() {
  useDocumentTitle('Import Data');
  const api = useApi();
  const { isAdmin } = useSession();
  const { workspace } = useWorkspace();
  const [isReady, setIsReady] = useState(false);
  const [testResult, setTestResult] = useState(false);
  const [importResults, setImportResults] = useState({});
  const [activeImport, setActiveImport] = useState(false);
  const [harvestConnection, setHarvestConnection] = useState(false);
  const [{ isSubmitting, saved, status, message }, form] = useForm();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).integrations().harvest.get();

      setHarvestConnection(data);
      setIsReady(true);
    } catch (error) {
      setHarvestConnection();
      throw error;
    }
  }, [workspace, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!isReady && !harvestConnection) return <PageLoader />;

  const initialValues = mergeValues(
    {
      accountId: '',
      token: '',
      appName: '',
      isImportAsRoles: true,
    },
    harvestConnection,
  );

  async function handleSubmit(values) {
    try {
      form.submit();
      const body = { ..._.omit(values, 'isImportAsRoles'), id: harvestConnection?.id };
      const { data } = await api.www.workspaces(workspace.id).integrations().harvest.save(body);
      setHarvestConnection(data.connection);
      setTestResult(data.testResult);
      form.save();
      form.done();
    } catch (error) {
      form.error(error);
      form.done();
    }
  }
  const areas = [
    'users',
    'clients',
    'contacts',
    'projects',
    // 'projectTaskAssignments',
    // 'projectUserAssignments',
    'expenseCategories',
    'invoices',
    'expenses',
    'timeEntries',
  ];
  async function importData(values) {
    try {
      for (let index = 0; index < areas.length; index++) {
        const currentArea = areas[index];
        setActiveImport(currentArea);
        const result = await api.www
          .workspaces(workspace.id)
          .integrations()
          .harvest.import(currentArea, values.isImportAsRoles);

        importResults[currentArea] = result.data;
        setImportResults({ ...importResults });
      }

      setActiveImport('done');
    } catch (error) {
      form.error(error);
    }
  }

  return (
    <Page>
      <HarvestPage>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            accountId: Yup.string().label('Account ID').max(255).required(),
            appName: Yup.string().label('Account ID').max(255).required(),
            token: Yup.string().label('Token').max(1024).required(),
          })}>
          {(formik) => {
            return (
              <Stack>
                <Form.Section
                  title="Step 1: Connect to Harvest"
                  subtitle={
                    <>
                      To connect to Harvest, you need a Personal Access Token.{' '}
                      <a href="https://id.getharvest.com/oauth2/access_tokens/new">Click here</a> to create your
                      Personal Access Token.
                    </>
                  }>
                  <Form.Control>
                    <Field.Text name="accountId" placeholder="Account ID" maxLength={255} />
                    <Field.Text name="appName" placeholder="App Name" maxLength={255} />
                  </Form.Control>
                  <Form.Control>
                    <Field.Text name="token" placeholder="Token" maxLength={1024} />
                  </Form.Control>
                  <Form.Control>
                    <Form.Control>
                      {harvestConnection.verified && (
                        <VerificationStatus>
                          <Icon icon="check" color={colors.success}></Icon> Credentials Verified
                        </VerificationStatus>
                      )}
                      {!harvestConnection.verified && (
                        <VerificationStatus>
                          <Icon icon="times" color={colors.danger}></Icon> Credentials Not Verified.
                        </VerificationStatus>
                      )}
                    </Form.Control>
                    <SaveButton
                      isLoading={isSubmitting}
                      ok={saved}
                      onClick={formik.submitForm}
                      disabled={!!activeImport}>
                      Step 1: Connect
                    </SaveButton>
                  </Form.Control>
                </Form.Section>

                {testResult.verified && testResult.counts && (
                  <>
                    <Form.Section
                      title="Step 2: Import Data"
                      subtitle="Click the Import Data button to begin the import process. This process can take several minutes if you have a large amount of data in Harvest.">
                      <Form.Control>
                        {isAdmin && (
                          <Level>
                            <Level.Item>
                              <Field.RadioGroup name="isImportAsRoles" direction="vertical">
                                <Level padding="0rem 0rem 1rem 0rem">
                                  <Radio value={true} />
                                  <ImportOption>
                                    Import Harvest tasks as<strong> Ruddr roles</strong>
                                    <p name="test">
                                      Choose this option if your Harvest tasks are generic categories, such as "Project
                                      Management".
                                    </p>
                                  </ImportOption>
                                </Level>
                                <Level>
                                  <Radio value={false} />
                                  <ImportOption>
                                    Import Harvest tasks as<strong> Ruddr tasks</strong>
                                    <p>
                                      Choose this option if your Harvest tasks are specific work items, such as "Create
                                      weekly status report".
                                    </p>
                                  </ImportOption>
                                </Level>
                              </Field.RadioGroup>
                            </Level.Item>
                          </Level>
                        )}
                      </Form.Control>

                      <Form.Control>
                        <Table>
                          <Table.Header>
                            <Table.Column width="2rem"></Table.Column>
                            <Table.Column width="10rem">Type</Table.Column>
                            <Table.Column width="6rem" align="right">
                              Records
                            </Table.Column>
                            <Table.Column>Details</Table.Column>
                            <Table.BoxActionsColumn />
                          </Table.Header>
                          <Table.Body>
                            <HarvestImportRow
                              area="users"
                              importResults={importResults}
                              testResult={testResult}
                              activeImport={activeImport}></HarvestImportRow>

                            <HarvestImportRow
                              area="clients"
                              importResults={importResults}
                              testResult={testResult}
                              activeImport={activeImport}></HarvestImportRow>

                            <HarvestImportRow
                              area="contacts"
                              importResults={importResults}
                              testResult={testResult}
                              activeImport={activeImport}></HarvestImportRow>

                            <HarvestImportRow
                              area="projects"
                              importResults={importResults}
                              testResult={testResult}
                              activeImport={activeImport}></HarvestImportRow>

                            {/* <HarvestImportRow
                            area="projectTaskAssignments"
                            importResults={importResults}
                            testResult={testResult}
                            activeImport={activeImport}></HarvestImportRow>

                          <HarvestImportRow
                            area="projectUserAssignments"
                            importResults={importResults}
                            testResult={testResult}
                            activeImport={activeImport}></HarvestImportRow> */}

                            <HarvestImportRow
                              area="expenseCategories"
                              importResults={importResults}
                              testResult={testResult}
                              activeImport={activeImport}></HarvestImportRow>

                            <HarvestImportRow
                              area="invoices"
                              importResults={importResults}
                              testResult={testResult}
                              activeImport={activeImport}></HarvestImportRow>

                            <HarvestImportRow
                              area="expenses"
                              importResults={importResults}
                              testResult={testResult}
                              activeImport={activeImport}></HarvestImportRow>

                            <HarvestImportRow
                              area="timeEntries"
                              importResults={importResults}
                              testResult={testResult}
                              activeImport={activeImport}></HarvestImportRow>
                          </Table.Body>
                        </Table>
                      </Form.Control>
                      <Form.Control>
                        <Form.Control></Form.Control>
                        {isAdmin && (
                          <SaveButton
                            disabled={activeImport === 'done'}
                            onClick={() => importData(formik.values)}
                            isLoading={!!activeImport && activeImport !== 'done'}>
                            Step 2: Import Data
                          </SaveButton>
                        )}
                      </Form.Control>
                    </Form.Section>
                  </>
                )}

                <div>{status && <FormMessage.Error>{message}</FormMessage.Error>}</div>
              </Stack>
            );
          }}
        </Formik>
      </HarvestPage>
    </Page>
  );
}
