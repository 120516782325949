import _ from 'lodash';
import React from 'react';
import {
  ApprovalStatusFilter,
  ClientApprovalStatusFilter,
  ExpenseBillableTypeFilter,
  ExpenseCategoryFilter,
  ExpenseReimbursableTypeFilter,
  InvoicedFilter,
} from '~/components';
import ReimbursedFilter from '~/components/filters/ReimbursedFilter';
import ExpenseTypeFilter from './ExpenseTypeFilter';
import ReportPeriodFilter from './ReportPeriodFilter';

export default function ExpenseFiltersBar({ filters, onChange }) {
  return (
    <>
      {(filters.createdPeriod?.start || filters.createdPeriod?.end) && (
        <ReportPeriodFilter
          placeholder="Created Date Range"
          value={filters.createdPeriod}
          onChange={({ value }) => onChange({ createdPeriod: value })}
        />
      )}

      {(filters.modifiedPeriod?.start || filters.modifiedPeriod?.end) && (
        <ReportPeriodFilter
          placeholder="Modified Date Range"
          value={filters.modifiedPeriod}
          onChange={({ value }) => onChange({ modifiedPeriod: value })}
        />
      )}

      {!_.isEmpty(filters.approvalStatuses) && (
        <ApprovalStatusFilter
          value={filters.approvalStatuses}
          onChange={({ target: { value } }) => onChange({ approvalStatuses: value })}
        />
      )}

      {!_.isEmpty(filters.clientApprovalStatuses) && (
        <ClientApprovalStatusFilter
          value={filters.clientApprovalStatuses}
          onChange={({ target: { value } }) => onChange({ clientApprovalStatuses: value })}
        />
      )}

      {!_.isEmpty(filters.invoiced) && (
        <InvoicedFilter value={filters.invoiced} onChange={({ target: { value } }) => onChange({ invoiced: value })} />
      )}

      {!_.isEmpty(filters.expenseCategories) && (
        <ExpenseCategoryFilter
          value={filters.expenseCategories}
          onChange={({ target: { value } }) => onChange({ expenseCategories: value })}
        />
      )}

      {!_.isEmpty(filters.expenseType) && (
        <ExpenseTypeFilter
          value={filters.expenseType}
          onChange={({ target: { value } }) => onChange({ expenseType: value })}
        />
      )}

      {!_.isEmpty(filters.billableType) && (
        <ExpenseBillableTypeFilter
          value={filters.billableType}
          onChange={({ target: { value } }) => onChange({ billableType: value })}
        />
      )}

      {!_.isEmpty(filters.reimbursableType) && (
        <ExpenseReimbursableTypeFilter
          value={filters.reimbursableType}
          onChange={({ target: { value } }) => onChange({ reimbursableType: value })}
        />
      )}

      {!_.isEmpty(filters.isReimbursed) && (
        <ReimbursedFilter
          value={filters.isReimbursed}
          onChange={({ target: { value } }) => onChange({ isReimbursed: value })}
        />
      )}
    </>
  );
}
