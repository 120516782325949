import _ from 'lodash';
import { QueryString } from '~/utils';
import { get, post, patch, del, put } from './agent';
import companyFiles from './companyFiles';

const companies =
  (workspacesUrl) =>
  (id = '') => {
    const url = _([workspacesUrl, 'companies', id]).compact().join('/');

    return {
      get: (query = {}) => {
        return get(url.concat(new QueryString(query, { multi: true }).toString(true)));
      },
      export: (query = {}, headers = {}) =>
        get(url.concat('/export').concat(new QueryString(query, { multi: true }).toString(true)), headers),
      detailsCard: () => get(url.concat('/details-card')),
      integration: (query = {}) => {
        return get(url.concat('/integration', new QueryString(query).toString(true)));
      },
      files: companyFiles(url.concat('/files')),
      hasDependencies: () => get(url.concat('/has-dependencies')),
      upsert: (body) => (id ? patch : post)(url, body),
      delete: () => del(url),
      setActiveStatus: (flag = true) => put(url.concat('/is-active'), { isActive: flag }),
      clone: (body) => post(url.concat('/clone'), body),
      findDuplicate: (query = {}) =>
        get(url.concat('/new/find-duplicate').concat(new QueryString(query).toString(true))),
    };
  };

export default companies;
