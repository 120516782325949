import React, { useMemo } from 'react';
import { SingleSelectFilter } from '~/components';
import { useFeatures } from '~/hooks';

export default function ProjectTimeViewSelect({ project, value, onChange }) {
  const features = useFeatures();

  const options = useMemo(() => {
    const options = [];

    if (project.useRoles) options.push({ value: 'role', label: 'Time by Role' });
    options.push({ value: 'member', label: 'Time by Member' });
    if (features.timesheets) options.push({ value: 'timesheets', label: 'Timesheets' });

    return options;
  }, [project.useRoles, features.timesheets]);

  if (options.length === 1) return null;

  return (
    <SingleSelectFilter
      icon="cog"
      placeholder="View"
      value={value}
      options={options.map((option) => ({ id: option.value, name: option.label }))}
      clearable={false}
      onChange={onChange}
    />
  );
}
