import { useLocale } from '~/hooks';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '~/styles';
import { dateFormats, parseDate } from '~/utils';
import DayPickerDropdown from './DayPickerDropdown';
import Icon from './Icon';
import TextInput from './TextInput';

const StyledInput = styled(TextInput)`
  && {
    padding-right: 2.625rem;
  }
`;

const Container = styled.div`
  position: relative;
`;

export const ReadableOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 1px;
  right: 1px;
  display: flex;
  align-items: center;
  height: 1.375rem;
  padding: 0.5rem 0.875rem;
  background-color: white;
  border-radius: 0.3125rem;
  pointer-events: none;
  transform: translateY(-50%);

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${colors.grey5};
    `}
`;

const CalendarIcon = styled.div`
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  color: ${colors.grey25};
  font-size: 1rem;
  pointer-events: none;
`;

const Indicator = styled.div`
  position: absolute;
  right: 2.25rem;
  top: 50%;
  padding-top: 1px;
  transform: translateY(-50%);
  color: ${colors.primary};
  cursor: pointer;
  font-size: 0.875rem;
  &:hover {
    color: ${colors.accent};
  }
`;

const DayPickerInput = React.forwardRef(
  (
    {
      value = null,
      onKeyDown,
      onChange,
      onFocus,
      placeholder,
      clearable = true,
      displayFormat = dateFormats.compactDate,
      scope = 'day',
      position,
      getDayProps,
      align,
      locale,
      ...props
    },
    ref,
  ) => {
    locale = useLocale(locale);

    const [isOpen, setIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
      if (value) {
        setInputValue((currentValue) => {
          const currentDate = parseDate(currentValue, displayFormat, locale);
          if (currentDate !== value) {
            return moment(value).locale(locale).format(displayFormat);
          }
          return currentValue;
        });
      } else if (!isOpen) {
        setInputValue('');
      }
    }, [value, isOpen, displayFormat, locale]);

    const handleFocus = (event) => {
      setIsOpen(true);
      event.target.select();
      if (typeof onFocus === 'function') {
        onFocus(event);
      }
    };

    const handleKeyDown = (event) => {
      // Close on tab
      if (event.keyCode === 9) {
        setIsOpen(false);
      }
      if (typeof onKeyDown === 'function') {
        onKeyDown(event);
      }
    };

    const handleChange = (event) => {
      if (typeof onChange !== 'function') {
        return;
      }

      // Only allow entering text if the scope is 'day'. For other scopes, the user must use the picker.
      if (scope !== 'day') return;

      const value = event.target.value;
      setInputValue(value);
      onChange(parseDate(value, displayFormat, locale));
    };

    const handlePickerChange = (value) => {
      setIsOpen(false);
      if (typeof onChange === 'function') {
        onChange(value);
      }
    };

    const handleClearValue = () => {
      setInputValue('');
      if (typeof onChange === 'function') {
        onChange(null);
      }
    };

    const dateFormat = moment.localeData(locale).longDateFormat(displayFormat);
    const displayProps = dateFormat
      ? {
          placeholder: dateFormat.toLowerCase(),
          materialPlaceholder: placeholder,
          materialAlwaysVisible: true,
        }
      : {
          placeholder: placeholder,
          materialPlaceholder: undefined,
          materialAlwaysVisible: false,
        };

    return (
      <DayPickerDropdown
        isOpen={isOpen}
        value={value}
        scope={scope}
        onChange={handlePickerChange}
        onClose={() => setIsOpen(false)}
        position={position}
        positionOffset="0.375rem"
        getDayProps={getDayProps}
        align={align}>
        <Container>
          <StyledInput
            ref={ref}
            placeholder={displayProps.placeholder}
            materialPlaceholder={displayProps.materialPlaceholder}
            materialAlwaysVisible={displayProps.materialAlwaysVisible}
            value={inputValue}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            {...props}
          />
          {!isOpen && value && (
            <ReadableOverlay disabled={props.disabled}>
              {moment(value).locale(locale).format(displayFormat)}
            </ReadableOverlay>
          )}
          <CalendarIcon>
            <Icon icon="calendar-alt" type="far" />
          </CalendarIcon>
          {clearable && value && (
            <Indicator onClick={handleClearValue}>
              <Icon icon="times" />
            </Indicator>
          )}
        </Container>
      </DayPickerDropdown>
    );
  },
);

export default DayPickerInput;
