import styled from 'styled-components';
import { colors } from '~/styles';
import Section from './Section';

const Separator = styled.div`
  margin: 1.5rem 0;
  position: relative;
  border-top: 1px solid ${colors.grey10};

  + ${Section} {
    margin-top: 0;
  }
`;

export default Separator;
