import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Currency, DateTime, ExportDropdown } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { dateFormats, intervalOptions, mimeTypes } from '~/utils';
import CurrencyFilter from '~/components/filters/CurrencyFilter.jsx';
import ExportDialogAsync from '../components/ExportDialogAsync';
import Report from '../components/Report';
import Table from '../components/table';
import SavedReportActions from '../SavedReportActions';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import { ReportPeriodFilter, DayPickerFilter, SingleClientFilter } from '~/components/filters';

export default function ClientStatement({ report }) {
  useDocumentTitle(report.name);
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({
    report: null,
    status: 'loading',
    loading: {
      table: false,
    },
  });

  const [params, setParams] = useState({
    period: null,
    date: null,
    client: null,
    currency: null,
  });

  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: { ...reportsSearchParamsConfig.period, default: intervalOptions.last_month },
        date: {
          default: moment().format(dateFormats.isoDate),
          deserialize: (value) => {
            if (!moment(value, true).isValid()) {
              value = null;
            }
            return value;
          },
        },
        client: searchParamsConfig.client,
        currency: { ...searchParamsConfig.currency, default: null },
      }),
      [searchParamsConfig, reportsSearchParamsConfig],
    ),

    onChange: (params) => setParams(params),
  });

  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      date: params.date ?? undefined,
      clientId: params.client ? params.client.id : undefined,
      currency: params.currency ?? undefined,
    }),
    [params],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams(params);
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    if (!urlSearchParams.clientId) {
      setQuery((state) => ({
        ...state,
        data: {},
        status: 'ready',
        loading: { table: false },
      }));
      return;
    }
    const { data } = await api.www.workspaces(workspace.id).reports().clientStatement(urlSearchParams);
    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: { table: false },
    }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values, page: 0 });
    searchParams.set(values);
  };

  const handleClientChange = (client) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams({
      ...params,
      page: 0,
      client,
      currency: features.multicurrency && client ? client.currency : params.currency,
    });
    searchParams.set({ client, currency: features.multicurrency && client ? client.currency : params.currency });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .clientStatement(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <Report.Info report={report} />

        <Report.Actions>
          <SavedReportActions report={report} onSave={() => searchParams.set(params)} />
          <ExportDropdown>
            {({ setIsOpen }) => {
              const disabled = !query.data;

              return (
                <>
                  <ExportDropdown.Item
                    tooltip={disabled ? 'Missing required filters' : undefined}
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    tooltip={disabled ? 'Missing required filters' : undefined}
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    tooltip={disabled ? 'Missing required filters' : undefined}
                    onClick={async () => {
                      await handleExport(mimeTypes.pdf);
                      setIsOpen(false);
                    }}>
                    Export to PDF
                  </ExportDropdown.Item>
                </>
              );
            }}
          </ExportDropdown>
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <SingleClientFilter
          name="client"
          placeholder="Client"
          materialAlwaysVisible
          materialPlaceholder="Client"
          activeOnly={false}
          permission="viewPublishedInvoices"
          value={params.client}
          clearable={false}
          onChange={({ target: { value } }) => handleClientChange(value)}
        />

        <ReportPeriodFilter
          value={params.period}
          onChange={({ value }) => handleApplyFilters({ period: value })}
          clearable={false}
        />

        <DayPickerFilter
          value={params.date}
          name="date"
          placeholder="Statement Date"
          clearable={false}
          scope={'day'}
          onChange={({ target: { value } }) => handleApplyFilters({ date: value })}
        />

        {features.multicurrency && (
          <CurrencyFilter value={params.currency} onChange={({ target: { value } }) => handleCurrencyChange(value)} />
        )}
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return <Data query={query} params={params} />;
        }
      })()}
    </Report>
  );
}

const Summary = styled.div`
  margin-bottom: 2rem;
  background-color: ${colors.white};
  padding: 1;
  overflow-x: auto;
  flex: 1;
  border-radius: 10px;
  padding: 1.25rem 0;
  box-shadow: 0px 3px 15px 0px ${colors.grey10};
  scrollbar-width: none;
`;

const SummaryContent = styled.div`
  min-width: fit-content;
  display: flex;
  justify-content: space-around;
`;

const SummaryBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: ${({ size = 1 }) => size};
  white-space: nowrap;
  padding: 0 1.25rem;

  &:not(:first-child) {
    border-left: 1px solid ${colors.grey10};
  }
`;

const SummaryColumn = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(:last-child) {
    ${SummaryBox} {
      border-right: 1px solid ${colors.grey10};
    }
  }
`;

const SummaryValue = styled.div`
  font-size: 1rem;
  line-height: 3rem;
  font-weight: ${weights.bold};
  display: flex;
  justify-content: center;
  flex-direction: column;
  white-space: nowrap;

  &:not(:first-child) {
    margin-top: 0.625rem;
  }
`;

const SummaryLabel = styled.small`
  display: block;
  font-size: 0.75rem;
`;

function Data({ query, params }) {
  const { rows, currency, totals, openingBalance } = query.data;

  if (!params.client) return;

  return (
    <>
      <Report.Summary>
        <Summary>
          <SummaryContent>
            <SummaryColumn size={2}>
              <SummaryBox>
                <SummaryValue>
                  <Currency value={totals.current} currency={currency} />
                </SummaryValue>
                <SummaryLabel>Current Due</SummaryLabel>
              </SummaryBox>
            </SummaryColumn>
            <SummaryColumn size={2}>
              <SummaryBox>
                <SummaryValue>
                  <Currency value={totals['01-30']} currency={currency} />
                </SummaryValue>
                <SummaryLabel>1 - 30 Days Past Due</SummaryLabel>
              </SummaryBox>
            </SummaryColumn>
            <SummaryColumn size={2}>
              <SummaryBox>
                <SummaryValue>
                  <Currency value={totals['31-60']} currency={currency} />
                </SummaryValue>
                <SummaryLabel>31 - 60 Days Past Due</SummaryLabel>
              </SummaryBox>
            </SummaryColumn>
            <SummaryColumn size={2}>
              <SummaryBox>
                <SummaryValue>
                  <Currency value={totals['61-90']} currency={currency} />
                </SummaryValue>
                <SummaryLabel>61 - 90 Days Past Due</SummaryLabel>
              </SummaryBox>
            </SummaryColumn>
            <SummaryColumn size={2}>
              <SummaryBox>
                <SummaryValue>
                  <Currency value={totals['91+']} currency={currency} />
                </SummaryValue>
                <SummaryLabel>90+ Days Past Due</SummaryLabel>
              </SummaryBox>
            </SummaryColumn>
            <SummaryColumn size={2}>
              <SummaryBox>
                <SummaryValue>
                  <Currency value={totals.total} currency={currency} />
                </SummaryValue>
                <SummaryLabel>Amount Due</SummaryLabel>
              </SummaryBox>
            </SummaryColumn>
          </SummaryContent>
        </Summary>
      </Report.Summary>

      <Table>
        <Table.Header>
          <Table.Column width="10rem" name="date">
            Date
          </Table.Column>
          <Table.Column name="description" minWidth="25rem">
            Description
          </Table.Column>
          <Table.Column width="10rem" align="right" name="date">
            Amount
          </Table.Column>
          <Table.Column width="10rem" align="right" name="date">
            Balance
          </Table.Column>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <DateTime value={params.start} />
            </Table.Cell>
            <Table.Cell>Opening Balance</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell>
              <Currency value={openingBalance.amount} currency={currency} />
            </Table.Cell>
          </Table.Row>
          {rows.map((row) => {
            return (
              <Table.Row key={row.id}>
                <Table.Cell>
                  <DateTime value={row.date} />
                </Table.Cell>
                <Table.Cell>{row.description}</Table.Cell>
                <Table.Cell>
                  <Currency value={row.amount} currency={currency} />
                </Table.Cell>
                <Table.Cell>
                  <Currency value={row.balance} currency={currency} />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
}
