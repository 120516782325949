import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Icon, Tooltip } from '~/components';
import { colors, weights } from '~/styles';
import { FavoriteReportsContext } from './FavoriteReportsContext';

const Box = styled(Link)`
  display: flex;
  gap: 0.75rem;
  background-color: #fff;
  border: solid 1px ${colors.grey10};
  border-radius: 5px;
  padding: 0.875rem;
  background-color: ${colors.white};
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    border-radius: 5px;
    box-shadow: 0 0.1875rem 1rem ${colors.grey10};
    z-index: 1;
    border-color: transparent;
  }
`;

const Title = styled.div`
  font-weight: ${weights.medium};
  color: ${colors.grey100};
  font-size: 1rem;
`;

const Favorite = styled(Button)`
  height: 100%;
  margin-left: auto;
  border: none;
  padding: 0;
  height: auto;
`;

const Right = styled.div`
  display: flex;
  gap: 0.75rem;
  margin-left: auto;
  align-items: center;
  height: 100%;
`;

const Details = styled.div`
  margin-left: auto;
`;

function Description({ children }) {
  return (
    <Details>
      <Tooltip message={children}>
        <Icon icon="info-circle" color={colors.grey10} />
      </Tooltip>
    </Details>
  );
}

function NavBox({ reportKey, title, description, ...props }) {
  const favoriteReportsContext = useContext(FavoriteReportsContext);

  const isFavorite = useMemo(
    () => favoriteReportsContext.favorites?.map((v) => v.reportKey).includes(reportKey),
    [favoriteReportsContext.favorites, reportKey],
  );

  const handleClick = async (event) => {
    event.preventDefault();
    await favoriteReportsContext.toggle(reportKey, !isFavorite);
  };

  return (
    <Box {...props}>
      <Title>{title}</Title>

      <Right>
        {reportKey && (
          <Favorite isOutline onClick={handleClick}>
            <Icon icon="star" type={isFavorite ? 'fa' : 'far'} />
          </Favorite>
        )}

        {description && (
          <Tooltip message={description} style={{ display: 'flex', alignItems: 'center' }}>
            <Icon icon="info-circle" color={colors.grey10} />
          </Tooltip>
        )}
      </Right>
    </Box>
  );
}

NavBox.Title = Title;
NavBox.Description = Description;

export default NavBox;
