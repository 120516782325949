import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ExportDialog, ListView, ListViewActions, Page } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useClientFilters, useDocumentTitle, useIsMounted, useSearchParams, useSearchParamsConfig } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { QuerySort } from '~/utils';
import ClientRow from './ClientRow';
import ClientsListFilters from './ClientsListFilters';
import { ClientDrawer } from './client-drawer';

function ClientsListPage() {
  const documentTitle = useDocumentTitle('Clients');

  const { workspace } = useWorkspace();
  const api = useApi();

  const isMounted = useIsMounted();

  const history = useHistory();
  const { clientId } = useParams();
  const location = useLocation();

  const [query, setQuery] = useState({
    isReady: false,
    searchParamsStatus: 'pending',
    data: null,
    params: {
      q: '',
      clientPractices: [],
      clientTags: [],
      clientOwners: [],
      page: 0,
      size: 25,
      withGraph: 'owner',
      sort: new QuerySort('name', 'asc'),
    },
    action: 'load',
  });

  const setParams = (params) => {
    setQuery((state) => ({
      ...state,
      action: 'filter',
      params: { ...state.params, ...params, page: 0 },
      searchParamsStatus: 'ready',
    }));
  };

  const load = () => {
    setQuery((state) => ({
      ...state,
      params: {
        ...state.params,
        page: 0,
      },
      action: 'load',
    }));
  };

  const loadMore = useCallback(() => {
    setQuery((state) => {
      if (
        state.searchParamsStatus !== 'ready' ||
        state.action !== null ||
        !state.data ||
        state.data.total <= state.data.results.length
      ) {
        return state;
      }

      return {
        ...state,
        params: { ...state.params, page: state.params.page + 1 },
        action: 'load-more',
      };
    });
  }, []);

  const removeItem = (id) => {
    setQuery((state) => ({
      ...state,
      data: {
        ...state.data,
        results: state.data?.results.filter((i) => i.id !== id),
        total: state.data.total - 1,
      },
    }));
  };

  const updateItem = (item) => {
    setQuery((state) => ({
      ...state,
      data: {
        ...state.data,
        results: state.data?.results.map((i) => (i.id === item.id ? { ...i, ...item } : i)),
      },
    }));
  };

  const { action, data, isReady, params, searchParamsStatus } = query;

  const confirmation = useConfirmation();

  const searchParamsConfig = useSearchParamsConfig();

  const clientFilters = useClientFilters({
    clientRecordStatusId: { default: 'active', ...searchParamsConfig.clientRecordStatusId },
  });

  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        q: { default: '' },
        sort: { default: new QuerySort('name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
      }),
      [clientFilters, searchParamsConfig],
    ),

    sessionKey: 'clients_list',

    onChange: useCallback((params) => setParams((state) => ({ ...state, ...params })), []),
  });

  const urlSearchParams = useMemo(
    () => ({
      q: params.q,
      sort: params.sort,
      page: params.page,
      size: params.size,
      withGraph: params.withGraph,
      ...clientFilters.mapUrlSearchParams(params),
    }),
    [clientFilters, params],
  );

  useEffect(() => {
    if (searchParamsStatus !== 'pending') return;
    searchParams.get().then((params) => {
      if (params) setParams(params);
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).clients().get(urlSearchParams);

      if (!isMounted.current) return;

      setQuery((state) => ({
        ...state,
        action: null,
        dialog: null,
        searchParamsStatus: 'ready',
        data: {
          ...data,
          results: state.action === 'load-more' ? [...state.data.results, ...data.results] : data.results,
          total: data.total,
        },
      }));
    } catch (error) {
      setQuery((state) => ({ ...state, data: { total: 0, results: [] } }));
    }
  }, [workspace.id, api, urlSearchParams, isMounted]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const handleFilter = (values) => {
    setParams({ ...values });
    searchParams.set({ ..._.omit(values, 'sort') });
  };

  const handleSort = ({ column, sort }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ sort: querySort });
    searchParams.set({ sort: querySort });
  };

  async function handleSaved() {
    load();
  }

  function handleClose() {
    history.push({ pathname: `/app/${workspace.key}/clients`, search: location.search });
    documentTitle.set('Clients');
  }

  const handleExport = async (filename, mimeType) => {
    await confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .clients()
          .export(
            {
              ...urlSearchParams,
              size: null,
            },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  if (!isReady && !data) return <PageLoader />;

  return (
    <>
      <Page scrollable>
        <ClientsListFilters onExport={handleExport} onChange={handleFilter} params={params} />

        <Page.ListView>
          <ListView>
            <ListView.Header>
              <ListView.Column
                sticky
                name="name"
                minWidth="16rem"
                padding="0.8rem"
                onSort={handleSort}
                sort={params.sort}>
                Client Name
              </ListView.Column>
              <ListView.Column name="clientCode" minWidth="16rem">
                Client Code
              </ListView.Column>
              <ListView.Column minWidth="16rem">Relationship Owner</ListView.Column>
              <ListView.Column minWidth="16rem">Sales Representative</ListView.Column>
              <ListView.Column name="createdAt" onSort={handleSort} sort={params.sort} width="8rem" align="right">
                Created
              </ListView.Column>
              <ListViewActions.Column />
            </ListView.Header>
            <ListView.Body fade={action === 'filter'}>
              {!data.total && <ListView.Empty />}

              {data.results.map((item) => (
                <ClientRow
                  key={item.id}
                  client={item}
                  onSaved={updateItem}
                  onDeleted={removeItem}
                  onCollapse={updateItem}
                />
              ))}

              {data.total > data.results.length && (
                <ListView.Loader key={data.results.length} onIntersecting={loadMore} />
              )}
            </ListView.Body>

            <ListView.Status total={data.total} label="Client" isLoading={!!action} />
          </ListView>
        </Page.ListView>
      </Page>

      {clientId && (
        <ClientDrawer clientId={clientId} onSaved={handleSaved} onDeleted={removeItem} onClose={handleClose} />
      )}
    </>
  );
}

export default ClientsListPage;
export { ClientsListPage };
