import React from 'react';
import styled from 'styled-components';
import {
  Confirmation,
  Currency,
  DateTime,
  Icon,
  InternalClientTooltip,
  ListView,
  ListViewActions,
  ListViewMenu,
  ProjectAdmins,
  ProjectHealthScoreBar,
  ProjectLink,
  Tooltip,
} from '~/components';
import {
  useApi,
  useConfirmation,
  useIntegrations,
  useSession,
  useSubscription,
  useToast,
  useWorkspace,
} from '~/contexts';
import { useAuth, useFeatures } from '~/hooks';
import { colors } from '~/styles';
import { QueryString } from '~/utils';
import ArchiveProjectConfirmation from './ArchiveProjectConfirmation';
import QBO from './assets/qbo.svg?react';
import QBOGrey from './assets/qbo-grey.svg?react';
import UseRolesProjectConfirmation from './UseRolesProjectConfirmation';

const ProjectInfo = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const Status = styled.span`
  color: ${({ status }) =>
    ({
      tentative: colors.grey25,
      not_started: colors.warning,
      in_progress: colors.success,
      paused: colors.danger,
      completed: colors.black,
      cancelled: colors.grey55,
    })[status]};
`;

function ProjectsTable({
  data,
  params,
  mode = 'page',
  action,
  onEdit,
  onClone,
  onSort,
  onDelete,
  onRowClick,
  onSaved,
  onTrackTime,
  onLoadMore,
}) {
  const projects = data.results;
  const auth = useAuth();
  const api = useApi();
  const { isAdmin: isSysAdmin } = useSession();

  const { workspace } = useWorkspace();
  const toast = useToast();
  const confirmation = useConfirmation();
  const { notify } = useSubscription();
  const features = useFeatures();
  const integrations = useIntegrations();

  return (
    <ListView>
      <ListView.Header>
        <ListView.Column width="3rem" isVisible={integrations.qbo}>
          <QBOGrey style={{ width: '1rem', height: '1rem' }} />
        </ListView.Column>

        <ListView.Column width="3rem">
          <Icon icon="lock" color={colors.grey40} style={{ fontSize: '0.9rem' }} />
        </ListView.Column>
        <ListView.Column sticky minWidth="16rem" name="name" onSort={onSort} sort={params.sort}>
          Name
        </ListView.Column>
        <ListView.Column name="code" minWidth="16rem" onSort={onSort} sort={params.sort}>
          Code
        </ListView.Column>
        <ListView.Column width="7.5rem">Status</ListView.Column>
        <ListView.Column minWidth="15rem">Project Admin</ListView.Column>
        <ListView.Column width="9.75rem" name="billingTypeId" onSort={onSort} sort={params.sort}>
          Billing Type
        </ListView.Column>
        <ListView.Column
          align="center"
          width="7.5rem"
          name="healthScore"
          onSort={onSort}
          sort={params.sort}
          isVisible={features.projectHealth && auth.projects.viewHealth}>
          Health
        </ListView.Column>
        <ListView.Column width="7.5rem" align="right" name="createdAt" onSort={onSort} sort={params.sort}>
          Created
        </ListView.Column>
        <ListViewActions.Column isVisible={mode === 'page'} />
      </ListView.Header>

      <ListView.Body fade={action === 'filter'}>
        {!data.total && <ListView.Empty />}

        {projects.map((project) => {
          const allowCreate = auth.projects.create;
          const viewProjectHealth = features.projectHealth && project.healthScore && project.permissions.edit;

          async function handleSetRecordStatus({ recordStatusId, statusId }) {
            try {
              const { data } = await api.www
                .workspaces(workspace.id)
                .projects(project.id)
                .setRecordStatus({ recordStatusId, statusId });
              await onSaved(data);
            } catch ({ message }) {
              toast.error(message);
            }
          }

          async function handleSetUseRoles({ useRoles }) {
            try {
              const { data } = await api.www.workspaces(workspace.id).projects(project.id).setUseRoles({ useRoles });
              await onSaved(data);
            } catch ({ message }) {
              toast.error(message);
            }
          }

          const handleArchive = async () => {
            const confirm = await confirmation.prompt((resolve) => (
              <ArchiveProjectConfirmation
                project={project}
                onResolve={resolve}
                onSubmit={(shouldUpdateStatus) =>
                  handleSetRecordStatus({
                    recordStatusId: 'archived',
                    statusId: shouldUpdateStatus ? 'completed' : undefined,
                  })
                }
              />
            ));
            if (!confirm) return;

            notify(useSubscription.keys.refresh_timer);
          };

          const handleUseRolesConvert = async () => {
            const confirm = await confirmation.prompt((resolve) => (
              <UseRolesProjectConfirmation
                project={project}
                onResolve={resolve}
                onSubmit={() =>
                  handleSetUseRoles({
                    useRoles: true,
                  })
                }
              />
            ));
            if (!confirm) return;

            notify(useSubscription.keys.refresh_timer);
          };

          const handleUnarchive = async () => {
            const confirm = await confirmation.prompt((resolve) => (
              <Confirmation resolve={resolve}>
                {project.client.recordStatusId === 'archived'
                  ? 'The client associated with this project is currently archived. If you unarchive this project, it will also unarchive the client.'
                  : 'Are you sure that you want to unarchive this project?'}
              </Confirmation>
            ));

            if (!confirm) return;

            handleSetRecordStatus({ recordStatusId: 'active' });
          };

          return (
            <ListView.Row
              key={project.id}
              isDisabled={project.recordStatus.id === 'archived'}
              onClick={() => onRowClick(project)}>
              <ListView.Cell>
                {project.qboProjectId && (
                  <Tooltip message="This project is connected with QuickBooks." placement="right">
                    <QBO style={{ width: '1rem', height: '1rem' }} />
                  </Tooltip>
                )}
              </ListView.Cell>

              <ListView.Cell>
                {project.lockTimeAndExpenses && (
                  <Tooltip message="Time and expenses have been locked for this project." placement="right">
                    <Icon icon="lock" color={colors.grey40} />
                  </Tooltip>
                )}
              </ListView.Cell>
              <ListView.Cell>
                <ProjectInfo>
                  <div style={{ display: 'flex' }}>
                    <ProjectLink project={project} onClick={(e) => e.stopPropagation()} />
                  </div>
                  <small>
                    {project.client.name}
                    {project.client.isInternal && <InternalClientTooltip />}
                  </small>
                </ProjectInfo>
              </ListView.Cell>
              <ListView.Cell style={{ wordBreak: 'break-word' }}>{project.code}</ListView.Cell>
              <ListView.Cell>
                <Status status={project.status.id}>{project.status.name}</Status>
              </ListView.Cell>
              <ListView.Cell>
                <ProjectAdmins project={project} />
              </ListView.Cell>
              <ListView.Cell>
                <ProjectInfo>
                  <p>{project.billingType.name}</p>
                  {auth.projects.viewRevenue && project.isBillable && <Budget project={project} />}
                </ProjectInfo>
              </ListView.Cell>
              <ListView.Cell data-testid="health_cell">
                {viewProjectHealth && <ProjectHealthScoreBar score={project.healthScore} />}
              </ListView.Cell>
              <ListView.Cell>
                <DateTime value={project.createdAt} />
              </ListView.Cell>

              <ListViewActions isVisible={mode === 'page'}>
                <ListViewActions.View onClick={() => onRowClick(project)} />

                <hr />

                <ListViewMenu>
                  {({ setIsOpen }) => {
                    const handleAction = (action) => setIsOpen(false) || action;

                    return (
                      <>
                        <ListViewMenu.Item onClick={() => handleAction(onRowClick(project))}>View</ListViewMenu.Item>

                        <ListViewMenu.Item
                          disabled={!project.permissions.edit}
                          tooltip={
                            !project.permissions.edit ? 'Insufficient permissions to edit this project.' : undefined
                          }
                          onClick={() => handleAction(onEdit(project))}>
                          Edit
                        </ListViewMenu.Item>

                        <ListViewMenu.Link
                          to={`/app/${workspace.key}/reports/time-entries?${new QueryString({
                            start: 'not_set',
                            end: 'not_set',
                            project: project.id,
                          })}`}>
                          View Time Entries
                        </ListViewMenu.Link>

                        <ListViewMenu.Item
                          disabled={!allowCreate}
                          tooltip={!allowCreate ? 'Insufficient permissions to clone this project.' : undefined}
                          onClick={() => handleAction(onClone(project))}>
                          Clone
                        </ListViewMenu.Item>

                        {project.useRoles === false && isSysAdmin ? (
                          <ListViewMenu.Item
                            disabled={!project.permissions.edit}
                            tooltip={
                              !project.permissions.edit
                                ? 'Insufficient permissions to convert this project.'
                                : undefined
                            }
                            onClick={() => handleAction(handleUseRolesConvert())}>
                            Use Roles
                          </ListViewMenu.Item>
                        ) : (
                          <></>
                        )}

                        {project.recordStatus.id === 'active' ? (
                          <ListViewMenu.Item
                            disabled={!project.permissions.delete}
                            tooltip={
                              !project.permissions.delete
                                ? 'Insufficient permissions to archive this project.'
                                : undefined
                            }
                            onClick={() => handleAction(handleArchive())}>
                            Archive
                          </ListViewMenu.Item>
                        ) : (
                          <ListViewMenu.Item
                            disabled={!project.permissions.delete}
                            tooltip={
                              !project.permissions.delete
                                ? 'Insufficient permissions to unarchive this project.'
                                : undefined
                            }
                            onClick={() => handleAction(handleUnarchive())}>
                            Unarchive
                          </ListViewMenu.Item>
                        )}

                        <ListViewMenu.Item
                          onClick={() => onTrackTime({ project })}
                          disabled={
                            !project.isProjectMember ||
                            project.recordStatusId !== 'active' ||
                            project.lockTimeAndExpenses
                          }
                          tooltip={(() => {
                            if (!project.isProjectMember)
                              return 'In order to track time to this project, you must be an active project team member.';
                            if (project.recordStatusId !== 'active') return 'This project is archived.';
                            if (project.lockTimeAndExpenses)
                              return 'This project does not allow adding new time entries.';
                          })()}>
                          Track Time
                        </ListViewMenu.Item>

                        <ListViewMenu.DeleteItem
                          disabled={!project.permissions.delete}
                          tooltip={
                            !project.permissions.delete
                              ? 'Insufficient permissions to delete this project.'
                              : 'This project is associated with one or more invoices or credit notes and cannot be deleted.'
                          }
                          onCheckDependencies={async (workspace) =>
                            project.permissions.delete &&
                            ((await workspace.projects(project.id).hasInvoices()).data ||
                              (await workspace.projects(project.id).hasCreditNotes()).data)
                          }
                          onClick={() => handleAction(onDelete(project))}>
                          Delete
                        </ListViewMenu.DeleteItem>
                      </>
                    );
                  }}
                </ListViewMenu>
              </ListViewActions>
            </ListView.Row>
          );
        })}

        {data.total > data.results.length && <ListView.Loader key={data.results.length} onIntersecting={onLoadMore} />}
      </ListView.Body>

      <ListView.Status total={data.total} label="Project" isLoading={!!action} />
    </ListView>
  );
}

function Budget({ project }) {
  if (!project.permissions.viewRevenue) return null;

  const currency = project.currency;

  if (project.useBudget)
    return (
      <p>
        <small>
          <Currency
            value={project.budget?.revenue}
            minimumFractionDigits={0}
            maximumFractionDigits={0}
            currency={currency}
          />
        </small>
      </p>
    );

  if (project.useMonthlyBudget)
    return (
      <p>
        <small>
          <Currency
            value={project.monthlyBudget?.revenue}
            minimumFractionDigits={0}
            maximumFractionDigits={0}
            currency={currency}
          />{' '}
          per month
        </small>
      </p>
    );

  return null;
}

export default ProjectsTable;
