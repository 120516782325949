import {
  Button,
  Buttons,
  CancelButton,
  Checkbox,
  Currency,
  CurrencyInput,
  DateTime,
  DeleteButton,
  DeleteConfirmation,
  Drawer,
  Field,
  Form,
  FormMessage,
  Icon,
  Level,
  Table,
  Tooltip,
} from '~/components';
import ReadTextbox from '~/components/read-only/ReadTextbox';
import { useApi, useConfirmation, useIntegrations, useToast, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useActions, useDirtyCheck, useDocumentTitle, useFeatures, useForm } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorPage } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';
import QBOIndicator from '../components/QBOIndicator';
import XeroInvoiceIndicator from '../components/XeroInvoiceIndicator';

const Container = styled.div`
  font-size: 0.875rem;

  input {
    font-size: inherit;
    width: 100%;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  margin-bottom: 1rem;
`;

const SectionTitle = styled.div`
  width: 12rem;
  padding: 1rem 3rem 1rem 0;
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
`;

const TotalRow = styled(Table.Row)`
  border-bottom: none;
  font-weight: ${weights.bold};
  text-transform: uppercase;
  height: 2rem;

  margin-top: 1rem;

  &:last-child {
    margin-top: 0;
  }

  > div {
    justify-content: flex-end;
    flex-basis: 10rem;
  }

  > div:first-child {
    flex: 1;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

const initialState = { payment: null, isReady: false };
const handlers = {
  ready: ({ payment }) => ({ isReady: true, payment }),
};

function ClientPaymentForm({ invoiceId, onClose, onSaved, onDeleted }) {
  const { paymentId } = useParams();

  const isNew = !paymentId;
  const title = isNew ? 'Receive Payment' : 'Edit Payment';

  useDocumentTitle(title);

  const api = useApi();
  const { workspace } = useWorkspace();
  const features = useFeatures();
  const integrations = useIntegrations();
  const [{ isReady, payment }, actions] = useActions(handlers, initialState);
  const [saveToQuickBooks, setSaveToQuickBooks] = useState(() => integrations.qbo);
  const [saveToXero, setSaveToXero] = useState(() => !!integrations.xero);
  const [invoices, setInvoices] = useState({ data: [], ready: false });
  const [{ status, message, isSubmitting }, form] = useForm();
  const confirmation = useConfirmation();
  const formRef = useRef();
  const firstFieldRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const toast = useToast();

  const fetchInvoices = useCallback(
    async ({ clientId, currency }) => {
      if (!clientId || !currency) {
        setInvoices({ data: [], ready: true });
        return [];
      }

      const { data } = await api.www
        .workspaces(workspace.id)
        .clientPayments()
        .findOpenInvoices({ clientId, currency, paymentId });

      setInvoices({ data, ready: true });
      return data;
    },
    [api, workspace.id, paymentId],
  );

  const [client, setClient] = useState(null);
  const fetchClient = useCallback(
    async ({ clientId }) => {
      if (!clientId) {
        setClient(null);
        return null;
      }

      const { data } = await api.www.workspaces(workspace.id).clientPayments().client(clientId);
      setClient(data);
      return data;
    },
    [api, workspace.id],
  );

  const fetchData = useCallback(async () => {
    try {
      if (paymentId) {
        // Edit an existing payment
        const { data: payment } = await api.www.workspaces(workspace.id).clientPayments(paymentId).get();

        actions.ready({ payment });

        await fetchInvoices({ clientId: payment.clientId, currency: payment.currency });
        await fetchClient({ clientId: payment.clientId });
      } else if (invoiceId) {
        // If an invoice ID is present, prepopulate with invoice data
        const { data: invoice } = await api.www.workspaces(workspace.id).invoices(invoiceId).get();

        actions.ready({
          payment: {
            client: invoice.client,
            currency: invoice.currency,
            paymentInvoices: [
              {
                invoiceId: invoice.id,
                amount: invoice.balance,
              },
            ],
          },
        });

        await fetchInvoices({ clientId: invoice.client.id, currency: invoice.currency });
        await fetchClient({ clientId: invoice.client.id });
      } else {
        // Create a new blank payment
        actions.ready({ payment: {} });
      }
    } catch (error) {
      actions.ready({ payment: null });
    }
  }, [actions, workspace.id, paymentId, api, fetchInvoices, fetchClient, invoiceId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function handleClose() {
    onClose();
  }

  if (!isReady) return null;
  if (!payment) return <ErrorPage.NotFound publicSite={false} />;

  const calculateBalance = (invoiceId) => {
    const invoice = invoices.data.find((invoice) => invoice.id === invoiceId);

    let balance = invoice.balance;

    if (paymentId) {
      const appliedPaymentInvoice = payment.paymentInvoices.find((pi) => pi.invoiceId === invoice.id);
      if (appliedPaymentInvoice) balance += appliedPaymentInvoice.amount;
    }

    return balance;
  };

  function getQuickBooksErrorMessage(values) {
    if (!integrations.qbo) return;
    if (!client) return;

    if (!client.qboCustomerId) return 'The selected client is not mapped to a QuickBooks Customer.';

    if (!invoices.ready) return;
    if (
      !values.paymentInvoices.every((pi) => {
        const invoice = invoices.data.find((i) => i.id === pi.invoiceId);
        return invoice.qboInvoiceId;
      })
    )
      return 'One or more invoices are not in QuickBooks.';
  }

  function getXeroErrorMessage(values) {
    if (!integrations.xero) return;
    if (!client) return;

    if (!client.xeroContactId) return 'The selected client is not mapped to a Xero Contact.';

    if (!invoices.ready) return;
    if (
      !values.paymentInvoices.every((pi) => {
        const invoice = invoices.data.find((i) => i.id === pi.invoiceId);
        return invoice.xeroInvoiceId;
      })
    )
      return 'One or more invoices are not in Xero.';
  }

  const initialValues = mergeValues(
    {
      amount: invoiceId && payment.paymentInvoices ? _.round(_.sumBy(payment.paymentInvoices, 'amount'), 2) : '',
      client: '',
      currency: workspace.currency,
      notes: '',
      paymentMethod: null,
      receivedOn: new Date(),
      referenceNumber: '',
      paymentInvoices: [],
    },
    {
      ...payment,
      paymentInvoices: _.map(payment.paymentInvoices, (pi) => _.pick(pi, ['invoiceId', 'amount'])),
    },
  );

  const schema = Yup.object()
    .shape({
      // Header
      amount: Yup.number().label('Amount Received').min(0.01).nullable().required(),
      client: Yup.object().label('Client').required(),
      notes: Yup.string().label('Notes').max(5000),
      paymentInvoices: Yup.array().of(
        Yup.object().shape({
          amount: Yup.number()
            .label('Amount')
            .min(0)
            .max(99999999999)
            .nullable()
            .test('amount', function (value) {
              const balance = calculateBalance(this.parent.invoiceId);

              if (balance < value)
                return this.createError({
                  message: 'The payment cannot exceed the invoice balance.',
                });

              return value;
            }),
        }),
      ),
      receivedOn: Yup.date().label('Received On').nullable().required(),
      referenceNumber: Yup.string().label('Reference Number').max(255),
    })
    .test('total', function (values) {
      if (values.amount !== _.round(_.sumBy(values.paymentInvoices, 'amount'), 2))
        return this.createError({
          path: 'total',
          message: 'The sum of invoice payments must match the amount received.',
        });

      return true;
    });

  return (
    <Drawer
      isOpen
      title={title}
      onOpen={() => firstFieldRef.current && firstFieldRef.current.focus()}
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        const handleSubmit = async (values) => {
          try {
            form.submit();

            let result;

            const body = emptyStringToNull({
              ..._.omit(values, ['client', 'paymentMethod']),
              clientId: values.client.id,
              paymentMethodId: values.paymentMethod?.id ?? null,
            });

            const { data } = await api.www.workspaces(workspace.id).clientPayments(payment.id).upsert(body);

            result = data;

            if (saveToQuickBooks && !getQuickBooksErrorMessage(values)) {
              try {
                const { data } = await api.www.workspaces(workspace.id).clientPayments(result.id).saveToQuickBooks();
                result = data;
              } catch (error) {
                toast.error(
                  error.message ??
                    'An error has occurred. The payment has not been saved to QuickBooks. Please review the QuickBooks integration settings and try again.',
                );
              }
            }

            if (saveToXero && !getXeroErrorMessage(values)) {
              try {
                const { data } = await api.www.workspaces(workspace.id).clientPayments(result.id).saveToXero();
                result = data;
              } catch (error) {
                toast.error(
                  error.message ??
                    'An error has occurred. The payment has not been saved to Xero. Please review the Xero integration settings and try again.',
                );
              }
            }

            form.save();
            closeDrawer();
            if (onSaved) onSaved(result);
            toast.success('Payment has been saved.');
          } catch (error) {
            form.error(error);
          }
        };

        async function handleDelete() {
          const confirm = await confirmation.prompt((resolve) => (
            <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this payment?</DeleteConfirmation>
          ));

          if (!confirm) return;

          await api.www.workspaces(workspace.id).clientPayments(payment.id).delete();

          if (onDeleted) onDeleted(payment);
          closeDrawer();
        }

        return (
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={handleSubmit}
            validationSchema={schema}>
            {(formik) => {
              const handleClientChange = async ({ target: { value } }) => {
                const currency = features.multicurrency ? value.currency : workspace.currency;
                formik.setValues({
                  ...formik.values,
                  client: value,
                  paymentInvoices: [],
                  currency,
                });
                await fetchInvoices({ clientId: value?.id, currency });
                await fetchClient({ clientId: value?.id });
              };

              const handleCurrencyChange = async ({ target: { value } }) => {
                formik.setValues({ ...formik.values, currency: value, paymentInvoices: [] });
                await fetchInvoices({ clientId: formik.values.client?.id, currency: value });
              };

              const handleAmountFocus = (event) => {
                event.target.previousValue = event.target.value;
              };

              const handleAmountBlur = async (event) => {
                // If the value didn't change, exit the function
                if (event.target.previousValue === event.target.value) return;

                formik.setFieldTouched('amount', true, false);

                if (formik.touched.paymentInvoices || invoices.data.length === 0) {
                  if (formik.errors.total) formik.validateForm();
                  return;
                }

                let remainingAmount = formik.values.amount;

                let paymentInvoices = [];

                invoices.data.forEach((invoice) => {
                  if (remainingAmount > 0) {
                    const balance = calculateBalance(invoice.id);
                    const amount = _.round(Math.min(balance, remainingAmount), 2);
                    remainingAmount = _.round(remainingAmount - amount, 2);
                    paymentInvoices.push({ invoiceId: invoice.id, amount });
                  }
                });

                await formik.setFieldValue('paymentInvoices', paymentInvoices);
                if (formik.errors.total) formik.validateForm();
              };

              const total = _.round(
                _.sumBy(formik.values.paymentInvoices, (pi) => Number(pi.amount) || 0),
                2,
              );

              const qboErrorMessage = getQuickBooksErrorMessage(formik.values);
              const xeroErrorMessage = getXeroErrorMessage(formik.values);

              const currency = formik.values.currency;

              return (
                <Form>
                  <Form.Control>
                    {payment.client ? (
                      <ReadTextbox label="Client" value={payment.client.name} />
                    ) : (
                      <Field.ClientSelect
                        ref={firstFieldRef}
                        name="client"
                        placeholder="Client"
                        isInternal={false}
                        permission="managePublishedInvoices"
                        clearable={false}
                        onChange={handleClientChange}
                      />
                    )}

                    <Field.WorkspaceCurrencySelect
                      name="currency"
                      disabled={payment.currency || !features.multicurrency}
                      clearable={false}
                      onChange={handleCurrencyChange}
                    />
                  </Form.Control>

                  <Form.Control>
                    <Field.Currency
                      ref={payment.client ? firstFieldRef : undefined}
                      name="amount"
                      placeholder="Amount Received"
                      currency={currency}
                      onFocus={handleAmountFocus}
                      onBlur={handleAmountBlur}
                    />

                    <Field.DayPicker name="receivedOn" placeholder="Received On" />
                  </Form.Control>

                  <Form.Control>
                    <Field.PaymentMethodSelect name="paymentMethod" placeholder="Payment Method" />
                    <Field.Text name="referenceNumber" placeholder="Reference Number" maxLength={255} />
                  </Form.Control>

                  <Form.Control>
                    <Field.TextArea name="notes" placeholder="Notes" rows={2} maxLength={5000} />
                  </Form.Control>

                  <Container>
                    <SectionTitle>Open Invoices</SectionTitle>

                    {!formik.values.client ? (
                      'Select a client and a currency to view open invoices.'
                    ) : invoices.data.length === 0 ? (
                      'There are no open invoices for the selected client and currency.'
                    ) : (
                      <Table>
                        <Table.BoxHeader>
                          <Table.Column width="2.5rem" />
                          <Table.Column width="6.5rem">Invoice #</Table.Column>
                          <Table.Column width="7rem">Due Date</Table.Column>
                          <Table.Column width="7.5rem" align="right">
                            Original Amount
                          </Table.Column>
                          <Table.Column width="7.5rem" align="right">
                            Open Balance
                          </Table.Column>
                          <Table.Column align="right">Payment</Table.Column>
                        </Table.BoxHeader>

                        <Table.Body>
                          {invoices.data.map((invoice) => {
                            const index = _.findIndex(
                              formik.values.paymentInvoices,
                              (pi) => pi.invoiceId === invoice.id,
                            );
                            const paymentInvoice = formik.values.paymentInvoices[index];
                            const amount = paymentInvoice?.amount || '';

                            const balance = calculateBalance(invoice.id);

                            const errors = formik.errors.paymentInvoices ? formik.errors.paymentInvoices[index] : {};

                            const handleAmountChange = (value) => {
                              let paymentInvoices = [...formik.values.paymentInvoices];

                              if (!value || Number(value) === 0) {
                                paymentInvoices = paymentInvoices.filter((pi) => pi.invoiceId !== invoice.id);
                              } else {
                                paymentInvoices = paymentInvoice
                                  ? paymentInvoices.map((pi) =>
                                      pi.invoiceId === invoice.id ? { ...pi, amount: value } : pi,
                                    )
                                  : [...paymentInvoices, { invoiceId: invoice.id, amount: value }];
                              }

                              formik.setValues({ ...formik.values, paymentInvoices });
                            };

                            const handleAmountBlur = () => {
                              if (!formik.touched.amount)
                                formik.setFieldValue(
                                  'amount',
                                  _.round(
                                    _.sumBy(formik.values.paymentInvoices, (pi) => Number(pi.amount) || 0),
                                    2,
                                  ),
                                );

                              formik.setFieldTouched('paymentInvoices', true, false);

                              if (formik.errors.total || errors?.amount) formik.validateForm();
                            };

                            return (
                              <Table.BoxRow key={invoice.id}>
                                <Table.Cell>
                                  {!!paymentInvoice && <Icon icon="check" color={colors.success} />}
                                </Table.Cell>

                                <Table.Cell>
                                  #{invoice.number}
                                  {invoice.qboInvoiceId && (
                                    <span>
                                      <QBOIndicator />
                                    </span>
                                  )}
                                  {invoice.xeroInvoiceId && (
                                    <span>
                                      <XeroInvoiceIndicator xeroInvoiceId={invoice.xeroInvoiceId} />
                                    </span>
                                  )}
                                </Table.Cell>

                                <Table.Cell>
                                  <DateTime value={invoice.dueOn} />
                                </Table.Cell>

                                <Table.Cell>
                                  <Currency value={invoice.total} currency={currency} />
                                </Table.Cell>

                                <Table.Cell>
                                  <Currency value={balance} currency={currency} />
                                </Table.Cell>

                                <Table.Cell>
                                  <Field.Control error={errors?.amount}>
                                    <CurrencyInput
                                      value={amount}
                                      currency={currency}
                                      onChange={handleAmountChange}
                                      onBlur={handleAmountBlur}
                                    />
                                  </Field.Control>
                                </Table.Cell>
                              </Table.BoxRow>
                            );
                          })}

                          <TotalRow>
                            <Table.Cell>Total</Table.Cell>
                            <Table.Cell>
                              <Field.Control error={formik.errors.total} style={{ alignItems: 'center' }}>
                                <Currency value={total} currency={currency} />
                              </Field.Control>
                            </Table.Cell>
                          </TotalRow>
                        </Table.Body>
                      </Table>
                    )}
                  </Container>

                  {status && <FormMessage.Error>{message}</FormMessage.Error>}

                  <Drawer.Actions>
                    <Level>
                      {paymentId && (
                        <Level.Item narrow>
                          <DeleteButton onClick={handleDelete}>Delete</DeleteButton>
                        </Level.Item>
                      )}

                      {integrations.qbo && !payment.qboPaymentId && (
                        <Level.Item>
                          <CheckboxContainer>
                            {qboErrorMessage && (
                              <Tooltip message={qboErrorMessage}>
                                <Icon icon="exclamation-circle" color={colors.warning} spaceRight />
                              </Tooltip>
                            )}

                            <Checkbox
                              label="Save to QuickBooks"
                              disabled={qboErrorMessage}
                              checked={saveToQuickBooks && !qboErrorMessage}
                              onChange={() => setSaveToQuickBooks(!saveToQuickBooks)}
                            />
                          </CheckboxContainer>
                        </Level.Item>
                      )}

                      {!!integrations.xero && !payment.xeroPaymentId && (
                        <Level.Item>
                          <CheckboxContainer>
                            {xeroErrorMessage && (
                              <Tooltip message={xeroErrorMessage}>
                                <Icon icon="exclamation-circle" color={colors.warning} spaceRight />
                              </Tooltip>
                            )}

                            <Checkbox
                              label="Save to Xero"
                              disabled={xeroErrorMessage}
                              checked={saveToXero && !xeroErrorMessage}
                              onChange={() => setSaveToXero(!saveToXero)}
                            />
                          </CheckboxContainer>
                        </Level.Item>
                      )}
                    </Level>

                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                      <Button type="submit" isLoading={isSubmitting}>
                        Save &amp; Close
                      </Button>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}

export default ClientPaymentForm;
