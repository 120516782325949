import icon from '~/assets/public/xero-icon.png';
import { Tooltip } from '~/components';
import { useIntegrations } from '~/contexts';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  cursor: pointer;
`;

const Image = styled.img`
  width: 1rem;
  height: 1rem;
`;

export default function XeroPaymentIndicator({ message = 'This payment is in Xero.', xeroPaymentId }) {
  const integrations = useIntegrations();
  if (!integrations.xero) return null;

  const handleClick = (event) => {
    event.stopPropagation();

    if (xeroPaymentId) {
      const paymentURL = integrations.xero.orgCode
        ? `https://go.xero.com/organisationlogin/default.aspx?shortcode=${integrations.xero.orgCode}&redirecturl=/Bank/ViewTransaction.aspx?bankTransactionID=${xeroPaymentId}`
        : `https://go.xero.com/Bank/ViewTransaction.aspx?bankTransactionID=${xeroPaymentId}`;
      return window.open(paymentURL, '_blank');
    }
  };

  return (
    <Wrapper onClick={handleClick}>
      <Tooltip message={message}>
        <Image src={icon} />
      </Tooltip>
    </Wrapper>
  );
}
