import styled from 'styled-components';
import { ReadableOverlay } from './DayPickerInput';
import { Select } from './SingleSelect';

const FiltersBar = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  grid-column-gap: 0.375rem;
  grid-row-gap: 0.75rem;
  flex: 1;

  &:empty {
    display: none;
  }

  &,
  & span > strong {
    font-size: 0.875rem;
  }

  & input {
    height: 2rem;
    font-size: 0.75rem;
  }

  ${ReadableOverlay} {
    font-size: 0.75rem;
  }

  ${Select} {
    height: 2rem;

    > div:first-child {
      font-size: 0.75rem;
    }
  }
`;

export default FiltersBar;
