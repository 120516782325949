import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ClientLink, DateTime, ExportDropdown, InternalClientTooltip, ProjectAdmins, ProjectLink } from '~/components';
import ClientFiltersBar from '~/components/filters/ClientFiltersBar';
import ClientFiltersGroup from '~/components/filters/ClientFiltersGroup';
import FilterButton from '~/components/filters/FilterButton';
import ProjectFiltersBar from '~/components/filters/ProjectFiltersBar';
import ProjectFiltersGroup from '~/components/filters/ProjectFiltersGroup';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import { PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';
import { QuerySort } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ExportDialogAsync from '../components/ExportDialogAsync';
import Report from '../components/Report';
import Table from '../components/table';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import SavedReportActions from '../SavedReportActions';

const ProjectInfo = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

export default function ProjectsList({ report }) {
  useDocumentTitle(report.name);
  const { workspace } = useWorkspace();
  const api = useApi();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters(() => ({
    projectRecordStatusId: { ...searchParamsConfig.recordStatusId, default: 'active' },
  }));

  const [params, setParams] = useState({
    startedDatePeriod: null,
    endedDatePeriod: null,
    createdAtPeriod: null,
    completedOnPeriod: null,
    projectBudgetHealth: [],
    projectScheduleHealth: [],
    projectClientSatisfaction: [],
    projectTeamSatisfaction: [],
    sort: new QuerySort('project.name', 'asc'),
    ...clientFilters.filters,
    ...projectFilters.filters,
  });

  // Init and sync URL search params
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        startedDatePeriod: reportsSearchParamsConfig.startPeriod,
        endedDatePeriod: reportsSearchParamsConfig.endPeriod,
        createdAtPeriod: reportsSearchParamsConfig.createdPeriod,
        completedOnPeriod: reportsSearchParamsConfig.completedPeriod,
        projectBudgetHealth: searchParamsConfig.projectBudgetHealth,
        projectScheduleHealth: searchParamsConfig.projectScheduleHealth,
        projectClientSatisfaction: searchParamsConfig.projectClientSatisfaction,
        projectTeamSatisfaction: searchParamsConfig.projectTeamSatisfaction,
        sort: { default: new QuerySort('project.name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
      }),
      [reportsSearchParamsConfig, searchParamsConfig, clientFilters, projectFilters],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      startFrom: params.startedDatePeriod?.start ?? undefined,
      startTo: params.startedDatePeriod?.end ?? undefined,
      endFrom: params.endedDatePeriod?.start ?? undefined,
      endTo: params.endedDatePeriod?.end ?? undefined,
      createdStart: params.createdAtPeriod?.start ?? undefined,
      createdEnd: params.createdAtPeriod?.end ?? undefined,
      completedOnFrom: params.completedOnPeriod?.start ?? undefined,
      completedOnTo: params.completedOnPeriod?.end ?? undefined,
      projectBudgetHealthId: params.projectBudgetHealth?.map((v) => v.id),
      projectScheduleHealthId: params.projectScheduleHealth?.map((v) => v.id),
      projectClientSatisfactionId: params.projectClientSatisfaction?.map((v) => v.id),
      projectTeamSatisfactionId: params.projectTeamSatisfaction?.map((v) => v.id),
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters, projectFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().projectsList(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'sorting' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .projectsList(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <Report.Info report={report} />

        <Report.Actions>
          <SavedReportActions report={report} onSave={() => searchParams.set(params)} />

          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <ClientFiltersBar filters={params} onChange={handleApplyFilters} />

        <ProjectFiltersBar filters={params} onChange={handleApplyFilters} />
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const report = query.data;
  const features = useFeatures();

  return (
    <Report.Table>
      <Table>
        <Table.Header>
          <Table.Column sticky minWidth="16rem" name="project.name" onSort={onSort} sort={params.sort}>
            Project/Client
          </Table.Column>
          <Table.Column name="projectType.name" minWidth="16rem" onSort={onSort} sort={params.sort}>
            Project Type
          </Table.Column>
          <Table.Column name="project.statusId" width="8rem" onSort={onSort} sort={params.sort}>
            Status
          </Table.Column>
          <Table.Column minWidth="16rem">Project Admin</Table.Column>
          <Table.Column name="project.billingTypeId" width="10rem" onSort={onSort} sort={params.sort}>
            Billing Type
          </Table.Column>
          <Table.Column
            name="practice.name"
            minWidth="16rem"
            onSort={onSort}
            sort={params.sort}
            isVisible={features.practices}>
            Practice
          </Table.Column>
          <Table.Column name="createdAt" width="8rem" align="right" onSort={onSort} sort={params.sort}>
            Created On
          </Table.Column>
        </Table.Header>

        <Table.Body fade={query.status === 'sorting'}>
          {report.records.map((project) => {
            return (
              <Table.Row key={project.id}>
                <Table.Cell>
                  <ProjectInfo>
                    <p>
                      <ProjectLink project={project} />
                    </p>
                    <small>
                      <ClientLink client={project.client} />
                      {project.client.isInternal && <InternalClientTooltip />}
                    </small>
                  </ProjectInfo>
                </Table.Cell>
                <Table.Cell>{project.projectType?.name}</Table.Cell>
                <Table.Cell>{project.status.name}</Table.Cell>
                <Table.Cell>
                  <ProjectAdmins project={project} />
                </Table.Cell>
                <Table.Cell>{project.billingType.name}</Table.Cell>
                <Table.Cell>{project.practice?.name}</Table.Cell>
                <Table.Cell>
                  <DateTime value={project.createdAt} />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>

        <Table.Status
          total={report.records.length}
          label="Project"
          isLoading={query.status !== 'ready'}
          partial={report.partialResults}
        />
      </Table>
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}
