import React from 'react';
import SingleSelectFilter from '~/components/filters/SingleSelectFilter';

function ProjectTeamStatusFilter(props) {
  return (
    <SingleSelectFilter
      placeholder="Project Team Status"
      renderValue={(value) => value.name}
      options={[
        { id: 'active', name: 'Active' },
        { id: 'inactive', name: 'Inactive' },
      ]}
      {...props}
    />
  );
}

export default ProjectTeamStatusFilter;
