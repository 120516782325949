const events = {
  activated: { id: 'activated', name: 'Activated' },
  approval_step_approved: { id: 'approval_step_approved', name: 'Approval Workflow Step Approved' },
  approval_step_rejected: { id: 'approval_step_rejected', name: 'Approval Workflow Step Rejected' },
  approval_step_reset: { id: 'approval_step_reset', name: 'Approval Workflow Step Reset' },
  approved: { id: 'approved', name: 'Approved' },
  archived: { id: 'archived', name: 'Archived' },
  auto_approved: { id: 'auto_approved', name: 'Automatically Approved' },
  client_approved: { id: 'client_approved', name: 'Approved by Client' },
  client_pending_approval: { id: 'client_pending_approval', name: 'Set to Client Pending Approval' },
  client_rejected: { id: 'client_rejected', name: 'Rejected by Client' },
  created: { id: 'created', name: 'Created' },
  credit_note_applied: { id: 'credit_note_applied', name: 'Credit Note Applied' },
  credit_note_removed: { id: 'credit_note_removed', name: 'Credit Note Removed' },
  credit_note_updated: { id: 'credit_note_updated', name: 'Credit Note Updated' },
  deactivated: { id: 'deactivated', name: 'Deactivated' },
  default: { id: 'default', name: 'Set to Default' },
  deleted: { id: 'deleted', name: 'Deleted' },
  imported: { id: 'imported', name: 'Imported' },
  invited: { id: 'invited', name: 'Invited' },
  not_submitted: { id: 'not_submitted', name: 'Set to Not Submitted' },
  payment_received: { id: 'payment_received', name: 'Payment Received' },
  payment_removed: { id: 'payment_removed', name: 'Payment Removed' },
  payment_updated: { id: 'payment_updated', name: 'Payment Updated' },
  pending_approval: { id: 'pending_approval', name: 'Set to Pending Approval' },
  published: { id: 'published', name: 'Published' },
  reimbursed: { id: 'reimbursed', name: 'Reimbursed' },
  rejected: { id: 'rejected', name: 'Rejected' },
  saved_to_qbo: { id: 'saved_to_qbo', name: 'Saved to QuickBooks Online' },
  saved_to_xero: { id: 'saved_to_xero', name: 'Saved to Xero' },
  sent: { id: 'sent', name: 'Sent' },
  viewed: { id: 'viewed', name: 'Viewed' },
  submitted: { id: 'submitted', name: 'Submitted for Approval' },
  timesheet_submitted: { id: 'timesheet_submitted', name: 'Timesheet Submitted' },
  timesheet_unsubmitted: { id: 'timesheet_unsubmitted', name: 'Timesheet Unsubmitted' },
  unarchived: { id: 'unarchived', name: 'Unarchived' },
  unpublished: { id: 'unpublished', name: 'Unpublished' },
  unreimbursed: { id: 'unreimbursed', name: 'Unreimbursed' },
  updated: { id: 'updated', name: 'Updated' },
};

export default events;
