import { useField, useIsMounted } from '~/hooks';
import React, { useEffect, useMemo, useState } from 'react';
import FieldControl from './FieldControl';
import SingleSelect from './SingleSelect';
import { useApi } from '~/contexts';
import Fuse from 'fuse.js';

function CountrySelect({ ...props }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const [options, setOptions] = useState([]);
  const [filterValue, setFilterValue] = useState('');

  useEffect(() => {
    (async () => {
      const { data } = await api.countries().get();
      if (!isMounted.current) return;
      setOptions(data);
    })();
  }, [api, isMounted]);

  const selectedOption = useMemo(() => {
    return options?.find((o) => o.code === props.value);
  }, [props.value, options]);

  const filteredOptions = useMemo(() => {
    let countries = options;

    if (filterValue) {
      const fuse = new Fuse(options, { keys: ['name', 'code'], threshold: 0.2 });
      const results = fuse.search(filterValue);
      countries = results.map((result) => result.item);
    }

    return countries;
  }, [filterValue, options]);

  return (
    <SingleSelect
      showFilter={true}
      noOptionsMessage="No results"
      onFilterChange={(event) => setFilterValue(event.target.value)}
      valueRenderer={selectedOption?.name || ''}
      {...props}>
      {filteredOptions.map(({ code, name }) => (
        <option key={code} value={code}>
          {name}
        </option>
      ))}
    </SingleSelect>
  );
}

function FieldCountrySelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <CountrySelect {...field} {...props} />
    </FieldControl>
  );
}

export default CountrySelect;
export { FieldCountrySelect };
