import React, { useMemo, useState } from 'react';
import { Button, Buttons, CancelButton, Checkbox, FormMessage, ModalCard, SearchInput, Table } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { useForm } from '~/hooks';
import Fuse from 'fuse.js';
import _ from 'lodash';
import styled, { css } from 'styled-components';
import { colors } from '~/styles';
import { holidaySchedules } from '~/lookups';

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 42rem;
  background-color: ${colors.white};
`;

const StyledModal = styled(ModalCard)`
  ${Card} {
    width: 64rem;
  }
`;

const StyledTable = styled(Table)`
  font-size: 0.875rem;
  margin-top: 1rem;

  ${Table.Cell} {
    align-items: flex-start;
    height: 100%;
    min-height: unset;
    padding: 1rem;
  }
  opacity: ${({ fade }) => (fade ? 0.1 : 1)};

  height: 25rem;
  overflow-y: auto;
`;

const StyledCheckbox = styled.div`
  > label > div {
    background: ${colors.white};
    width: 1.125rem;
    height: 1.125rem;
    font-size: 0.5rem;
    margin: 0;
  }
`;

const StyledHeader = styled(Table.Header)`
  ${({ sticky }) =>
    sticky &&
    css`
      position: sticky;
      top: 0;
      z-index: 1;
    `}
`;

function AvaliableSchedulesModal({ onChange, onClose }) {
  const toast = useToast();
  const api = useApi();

  const { workspace } = useWorkspace();
  const [{ status, message, isSubmitting }, form] = useForm();

  const [selectedSchedules, setSelectedSchedules] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const options = useMemo(() => {
    if (!searchQuery) return holidaySchedules;

    const fuse = new Fuse(holidaySchedules, { keys: ['name'], threshold: 0.4 });
    const results = fuse.search(searchQuery);

    return results.map((result) => result.item);
  }, [searchQuery]);

  const handleBulkSelectionChange = () => {
    setSelectedSchedules(
      selected.some
        ? selectedSchedules.filter(({ id }) => !selectedSchedules.some((role) => id === role.id))
        : [...selectedSchedules, ...holidaySchedules],
    );
  };

  const selected = useMemo(() => {
    if (!selectedSchedules) return {};

    const rolesByCode = _.groupBy(holidaySchedules, 'code');

    return {
      roles: selectedSchedules.filter(({ code }) => !!rolesByCode[code]),

      get some() {
        return this.roles.length > 0;
      },
      get all() {
        return this.roles.length === selectedSchedules.length;
      },
    };
  }, [selectedSchedules]);

  const handleSearchChange = async ({ target: { value } }) => {
    setSearchQuery(value);
  };

  const handleSubmit = async () => {
    try {
      form.submit();

      const codes = selectedSchedules.map(({ code }) => code);

      await api.www.workspaces(workspace.id).holidaySchedules().availableSchedules({ codes });

      onChange();
      form.done();
      onClose();
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const handleCloseClick = () => {
    onClose();
  };

  return (
    <>
      <StyledModal title="Add Holiday Schedule" onClose={onClose}>
        {status && <FormMessage.Error style={{ marginBottom: '1rem' }}>{message}</FormMessage.Error>}
        <ModalCard.Body style={{ marginBottom: 0 }}>
          <SearchInput
            value={searchQuery}
            placeholder="All"
            materialPlaceholder="Search"
            materialAlwaysVisible
            onChange={handleSearchChange}
          />
          <StyledTable>
            <StyledHeader sticky>
              <Table.Column align="center" width="3rem">
                <StyledCheckbox>
                  <Checkbox
                    checked={selected.some || false}
                    partial={!selected.all || false}
                    onChange={handleBulkSelectionChange}
                  />
                </StyledCheckbox>
              </Table.Column>
              <Table.Column width="13rem">Name</Table.Column>
              <Table.Column>Description</Table.Column>
            </StyledHeader>
            <Table.Body>
              {options.map((schedule) => {
                const checked = !!selectedSchedules.find((r) => r.code === schedule.code);

                const handleSelectionChange = () => {
                  setSelectedSchedules(
                    checked
                      ? selectedSchedules.filter(({ code }) => code !== schedule.code)
                      : [...selectedSchedules, schedule],
                  );
                };

                return (
                  <Table.Row key={schedule.code}>
                    <Table.Cell>
                      <StyledCheckbox>
                        <Checkbox
                          tooltip="please"
                          checked={checked}
                          onChange={handleSelectionChange}
                          data-testid={`${schedule.code}_checkbox`}
                        />
                      </StyledCheckbox>
                    </Table.Cell>
                    <Table.Cell>{schedule.name}</Table.Cell>
                    <Table.Cell>{schedule.description}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </StyledTable>
        </ModalCard.Body>
        <ModalCard.Footer>
          <Buttons align="right">
            <CancelButton onClick={handleCloseClick}>Close</CancelButton>

            <Button disabled={!selected.some} isLoading={isSubmitting} type="submit" onClick={handleSubmit}>
              Add
            </Button>
          </Buttons>
        </ModalCard.Footer>
      </StyledModal>
    </>
  );
}

export default AvaliableSchedulesModal;
