import { Button, Buttons, CancelButton, Drawer, Field, Form, FormMessage } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useForm } from '~/hooks';
import React, { useRef } from 'react';
import { emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';
import { ContainerControl } from '../components/PageComponents';

export default function ClientApprovalSettingsForm({ clientApproval, onSaved, onClose }) {
  const api = useApi();
  const { workspace } = useWorkspace();

  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const [{ status, message, isSubmitting }, form] = useForm();

  const initialValues = mergeValues(
    {
      sendReminder: false,
      sendReminderAfterDays: 3,
      autoApprove: false,
      autoApproveAfterDays: 5,
      includeReceipts: true,
    },
    clientApproval,
  );

  const schema = Yup.object().shape({
    sendReminder: Yup.bool(),
    sendReminderAfterDays: Yup.number().integer().min(1).max(20),
    autoApprove: Yup.bool(),
    autoApproveAfterDays: Yup.number()
      .integer()
      .min(1)
      .max(20)
      .when('sendReminder', {
        is: true,
        then: (schema) =>
          schema.when('autoApprove', {
            is: true,
            then: (schema) =>
              schema.moreThan(
                Yup.ref('sendReminderAfterDays'),
                'Auto-approve must be set to a value greater than the reminder email.',
              ),
          }),
      }),
    includeReceipts: Yup.bool(),
  });

  return (
    <Drawer
      isOpen
      title="Client Approval Settings"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={onClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        const handleSubmit = async (values) => {
          try {
            form.submit();

            const body = emptyStringToNull(values);

            const { data } = await api.www
              .workspaces(workspace.id)
              .projects(clientApproval.projectId)
              .clientApprovals(clientApproval.id)
              .update(body);

            if (onSaved) await onSaved(data);
            closeDrawer();
          } catch (error) {
            form.error(error);
          }
        };

        return (
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={schema}
            onSubmit={handleSubmit}>
            {(formik) => {
              return (
                <Form>
                  <Form.Control>
                    <Field.Checkbox name="includeReceipts" label="Include expense receipts on this client approval" />
                  </Form.Control>

                  <Form.Control>
                    <ContainerControl>
                      <Field.Checkbox
                        name="sendReminder"
                        label="Send a daily reminder email if the client does not submit a response within"
                      />
                      <Field.SingleSelect
                        name="sendReminderAfterDays"
                        materialPlaceholder={false}
                        disabled={!formik.values.sendReminder}
                        style={{ width: '5.5rem', margin: '0 0.5rem', textAlign: 'right' }}>
                        {[...Array(20).keys()].map((_, idx) => (
                          <option key={idx} value={idx + 1}>
                            {idx + 1}
                          </option>
                        ))}
                      </Field.SingleSelect>
                      <span>days.</span>
                    </ContainerControl>
                  </Form.Control>

                  <Form.Control>
                    <ContainerControl>
                      <Field.Checkbox
                        name="autoApprove"
                        label="Auto-approve all items if the client does not submit a response within"
                      />
                      <div style={{ margin: '0 0.5rem' }}>
                        <Field.SingleSelect
                          name="autoApproveAfterDays"
                          materialPlaceholder={false}
                          disabled={!formik.values.autoApprove}
                          style={{ width: '5.5rem', textAlign: 'right' }}>
                          {[...Array(20).keys()].map((_, idx) => (
                            <option key={idx} value={idx + 1}>
                              {idx + 1}
                            </option>
                          ))}
                        </Field.SingleSelect>
                      </div>
                      <span>days.</span>
                    </ContainerControl>
                  </Form.Control>

                  {status && <FormMessage.Error>{message}</FormMessage.Error>}

                  <Drawer.Actions>
                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>

                      <Button isLoading={isSubmitting} onClick={formik.submitForm}>
                        Save &amp; Close
                      </Button>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}
