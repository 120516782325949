import Fuse from 'fuse.js';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { InternalClientTooltip, MultiSelect } from '~/components';
import { useApi, useWorkspace } from '~/contexts';

export default function ProjectFilter({ placeholder = 'Project', name, value, clients, onChange }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [data, setData] = useState(null);
  const [options, setOptions] = useState([]);

  const handleSearch = useCallback(
    async (q) => {
      let options = data;
      if (!data) {
        const result = await api.www
          .workspaces(workspace.id)
          .projects()
          .selectOptions({
            query: q,
            clientIds: clients ? clients.map((v) => v.id).join(',') : undefined,
            activeOnly: false,
            limit: 10000,
          });
        setData(result.data);
        options = result.data;
      }
      if (q) {
        options = new Fuse(options, { keys: ['name'], threshold: 0.4, ignoreLocation: true })
          .search(q)
          .map((result) => result.item);
      }
      setOptions(options);
    },
    [workspace.id, api, data, clients],
  );

  const groups = useMemo(() => {
    if (!options) return null;
    return _(options)
      .orderBy((o) => (value?.some((v) => v.id === o.id) ? 0 : o.recordStatusId === 'active' ? 1 : 2))
      .groupBy((o) =>
        value?.some((v) => v.id === o.id) ? 'Selected' : o.recordStatusId === 'active' ? 'Active' : 'Archived',
      )
      .map((projects, status) => ({
        status,
        clients: _(projects)
          .groupBy('client.id')
          .map((clientProjects) => ({
            client: clientProjects[0].client,
            projects: _.orderBy(clientProjects, (o) => (o.isActive ? 0 : 1)),
          }))
          .value(),
      }))
      .value();
  }, [options, value]);

  return (
    <MultiSelect.Filter
      icon="filter"
      placeholder={placeholder}
      name={name}
      value={value}
      renderValue={(value) => value.map((v) => v.name).join('; ')}
      options={options}
      renderOptions={(opts) => {
        const optionsById = _.keyBy(opts, 'id');
        return groups.map(({ status, clients }) => (
          <MultiSelect.Group key={status} label={status}>
            {clients.map(({ client, projects }) => (
              <MultiSelect.Group
                key={client.id}
                label={
                  <div style={{ display: 'flex' }}>
                    {client.name} {client.isInternal && <InternalClientTooltip />}
                  </div>
                }>
                {projects.map((project) => (
                  <MultiSelect.Option
                    key={project.id}
                    inactive={project.recordStatusId !== 'active'}
                    {...optionsById[project.id].props}>
                    {project.name}
                  </MultiSelect.Option>
                ))}
              </MultiSelect.Group>
            ))}
          </MultiSelect.Group>
        ));
      }}
      isLoading={!data}
      onSearch={handleSearch}
      onChange={onChange}
    />
  );
}
