import React from 'react';
import SingleSelectFilter from './SingleSelectFilter';

const defaultOptions = [
  { id: 'active', name: 'Active' },
  { id: 'inactive', name: 'Inactive' },
];

export default function PurchaseOrderStatusFilter({
  placeholder = 'Purchase Order Status',
  name,
  value,
  onChange,
  options = defaultOptions,
}) {
  return (
    <SingleSelectFilter
      icon="filter"
      placeholder={placeholder}
      name={name}
      value={value}
      renderValue={(value) => value.name}
      options={options}
      onChange={onChange}
    />
  );
}
