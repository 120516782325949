import _ from 'lodash';
import React, { useMemo } from 'react';
import {
  BusinessUnitFilter,
  MemberFilter,
  PracticeFilter,
  ProjectBillingTypeFilter,
  ProjectFilter,
  ProjectHealthFilter,
  ProjectRecordStatusFilter,
  ProjectRoleFilter,
  ProjectStatusFilter,
  ProjectTagFilter,
  ProjectTypeFilter,
} from '~/components';
import { useFeatures } from '~/hooks';
import ReportPeriodFilter from './ReportPeriodFilter';

function ProjectFiltersBar({ filters, sticky, onChange }) {
  const features = useFeatures();

  const visible = useMemo(() => {
    const isVisible = (key) => sticky?.includes(key) || !_.isEmpty(filters[key]);
    const isPeriodVisible = (key) => sticky?.includes(key) || filters[key]?.start || filters[key]?.end;

    return {
      projects: isVisible('projects'),
      projectRoles: isVisible('projectRoles'),
      projectBudgetHealth: features.projectHealth && isVisible('projectBudgetHealth'),
      projectScheduleHealth: isVisible('projectScheduleHealth'),
      projectClientSatisfaction: isVisible('projectClientSatisfaction'),
      projectTeamSatisfaction: isVisible('projectTeamSatisfaction'),
      projectBillingTypes: isVisible('projectBillingTypes'),
      projectStatuses: isVisible('projectStatuses'),
      projectRecordStatusId: isVisible('projectRecordStatusId'),
      projectTags: isVisible('projectTags'),
      projectPractices: features.practices && isVisible('projectPractices'),
      projectBusinessUnits: isVisible('projectBusinessUnits'),
      projectTypes: isVisible('projectTypes'),
      projectAdmins: isVisible('projectAdmins'),
      projectSalesRepresentatives: isVisible('projectSalesRepresentatives'),
      startedDatePeriod: isPeriodVisible('startedDatePeriod'),
      endedDatePeriod: isPeriodVisible('endedDatePeriod'),
      createdAtPeriod: isPeriodVisible('createdAtPeriod'),
      completedOnPeriod: isPeriodVisible('completedOnPeriod'),
    };
  }, [filters, sticky, features]);

  return (
    <>
      {visible.projects && (
        <ProjectFilter
          value={filters.projects}
          onChange={({ value }) => {
            const result = { projects: value };

            if (_.has(filters, 'projectRoles')) result.projectRoles = [];

            if (_.has(filters, 'projectTasks')) result.projectTasks = [];

            onChange(result);
          }}
        />
      )}

      {visible.projectRoles && (
        <ProjectRoleFilter value={filters.projectRoles} onChange={({ value }) => onChange({ projectRoles: value })} />
      )}

      {visible.projectBillingTypes && (
        <ProjectBillingTypeFilter
          value={filters.projectBillingTypes}
          onChange={({ value }) => onChange({ projectBillingTypes: value })}
        />
      )}

      {visible.projectStatuses && (
        <ProjectStatusFilter
          value={filters.projectStatuses}
          onChange={({ value }) => onChange({ projectStatuses: value })}
        />
      )}

      {visible.projectRecordStatusId && (
        <ProjectRecordStatusFilter
          value={filters.projectRecordStatusId}
          onChange={({ value }) => onChange({ projectRecordStatusId: value })}
        />
      )}

      {visible.projectTags && (
        <ProjectTagFilter value={filters.projectTags} onChange={({ value }) => onChange({ projectTags: value })} />
      )}

      {visible.projectPractices && (
        <PracticeFilter
          placeholder="Project Practice"
          value={filters.projectPractices}
          onChange={({ value }) => onChange({ projectPractices: value })}
        />
      )}

      {visible.projectBusinessUnits && (
        <BusinessUnitFilter
          placeholder="Project Business Unit"
          value={filters.projectBusinessUnits}
          onChange={({ value }) => onChange({ projectBusinessUnits: value })}
        />
      )}

      {visible.projectTypes && (
        <ProjectTypeFilter value={filters.projectTypes} onChange={({ value }) => onChange({ projectTypes: value })} />
      )}

      {visible.projectAdmins && (
        <MemberFilter
          placeholder="Project Admin"
          value={filters.projectAdmins}
          onChange={({ value }) => onChange({ projectAdmins: value })}
        />
      )}

      {visible.projectSalesRepresentatives && (
        <MemberFilter
          placeholder="Sales Representative"
          value={filters.projectSalesRepresentatives}
          onChange={({ value }) => onChange({ projectSalesRepresentatives: value })}
        />
      )}

      {visible.projectBudgetHealth && (
        <ProjectHealthFilter
          placeholder="Budget Health"
          value={filters.projectBudgetHealth}
          onChange={({ value }) => onChange({ projectBudgetHealth: value })}
        />
      )}

      {visible.projectScheduleHealth && (
        <ProjectHealthFilter
          placeholder="Schedule Health"
          value={filters.projectScheduleHealth}
          onChange={({ value }) => onChange({ projectScheduleHealth: value })}
        />
      )}

      {visible.projectClientSatisfaction && (
        <ProjectHealthFilter
          placeholder="Client Satisfaction"
          value={filters.projectClientSatisfaction}
          onChange={({ value }) => onChange({ projectClientSatisfaction: value })}
        />
      )}

      {visible.projectTeamSatisfaction && (
        <ProjectHealthFilter
          placeholder="Team Satisfaction"
          value={filters.projectTeamSatisfaction}
          onChange={({ value }) => onChange({ projectTeamSatisfaction: value })}
        />
      )}

      {visible.startedDatePeriod && (
        <ReportPeriodFilter
          placeholder="Project Start Date"
          value={filters.startedDatePeriod}
          onChange={({ target: { value } }) => onChange({ startedDatePeriod: value })}
        />
      )}

      {visible.endedDatePeriod && (
        <ReportPeriodFilter
          placeholder="Project End Date"
          value={filters.endedDatePeriod}
          onChange={({ target: { value } }) => onChange({ endedDatePeriod: value })}
        />
      )}

      {visible.createdAtPeriod && (
        <ReportPeriodFilter
          placeholder="Project Create Date"
          value={filters.createdAtPeriod}
          onChange={({ target: { value } }) => onChange({ createdAtPeriod: value })}
        />
      )}

      {visible.completedOnPeriod && (
        <ReportPeriodFilter
          placeholder="Project Completed Date"
          value={filters.completedOnPeriod}
          onChange={({ target: { value } }) => onChange({ completedOnPeriod: value })}
        />
      )}
    </>
  );
}

export default ProjectFiltersBar;
