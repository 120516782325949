import Big from 'big.js';
import _ from 'lodash';

const calculateMarkupRatio = ({ markupRatio, enableMarkup }) => {
  if (!enableMarkup || !_.isNumber(markupRatio)) return null;

  return Big(markupRatio).div(100).toNumber();
};

export default calculateMarkupRatio;
