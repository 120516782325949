import _ from 'lodash';
import React from 'react';
import { InvoiceStatusFilter } from '~/components';
import InvoiceSentFilter from '~/components/filters/InvoiceSentFilter';
import FiltersGroup from './FiltersGroup';
import ReportPeriodFilter from './ReportPeriodFilter';
import InvoiceItemFilter from './InvoiceItemFilter';

export default function InvoiceFiltersGroup({ filters, onChange }) {
  return (
    <FiltersGroup
      label="Invoice Filters"
      filters={[
        filters.invoiceServicesThroughPeriod?.start,
        filters.invoiceServicesThroughPeriod?.end,
        filters.statuses,
        filters.invoiceSent,
        filters.invoiceItems,
      ]}>
      {_.has(filters, 'invoiceServicesThroughPeriod') && (
        <ReportPeriodFilter
          value={filters.invoiceServicesThroughPeriod}
          placeholder="Services Through Date Range"
          onChange={({ target: { value } }) => onChange({ invoiceServicesThroughPeriod: value })}
        />
      )}

      {_.has(filters, 'statuses') && (
        <InvoiceStatusFilter
          value={filters.statuses}
          onChange={({ target: { value } }) => onChange({ statuses: value })}
        />
      )}

      {_.has(filters, 'invoiceSent') && (
        <InvoiceSentFilter
          value={filters.invoiceSent}
          onChange={({ target: { value } }) => onChange({ invoiceSent: value })}
        />
      )}

      {_.has(filters, 'invoiceItems') && (
        <InvoiceItemFilter
          value={filters.invoiceItems}
          onChange={({ target: { value } }) => onChange({ invoiceItems: value })}
        />
      )}
    </FiltersGroup>
  );
}
