import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import DeleteFileConfirmation from './DeleteFileConfirmation';
import FilePreview from './FilePreview';

const MAX_FILE_SIZE = 20971520; // 20mb in bytes

const ErrorMessage = styled.div`
  margin-bottom: 1.625rem;
  padding: 1rem 1.5rem;
  color: ${colors.white};
  background-color: ${colors.error};
  border-radius: 0.25rem;
`;

const DropzoneContainer = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  overflow: auto;
  min-height: 6rem;
  max-height: 12rem;
  padding-bottom: 1.625rem;
`;

const DropzoneInterior = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: dashed 0.125rem ${colors.grey25};
  background-color: ${colors.grey5};
  border-radius: 0.8125rem;
  font-size: 1.5rem;
  font-weight: ${weights.light};
  color: ${colors.grey40};
  cursor: pointer;
`;

const Previews = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
  padding-bottom: 1.625rem;
`;

function PurchaseOrderFiles({ purchaseOrder, onDrop, files, error, isLoading, removeFile }) {
  const [fileToDelete, setFileToDelete] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: MAX_FILE_SIZE,
    onDrop,
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/msword': ['.doc'],
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'image/*': ['.bmp', '.jpg', '.jpeg', '.png', '.gif', '.tif', '.tiff'],
      'text/csv': ['.csv'],
      'text/plain': ['.txt'],
    },
  });

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <DropzoneContainer>
        <DropzoneInterior {...getRootProps()}>
          <input {...getInputProps()} />
          <p>Drop files here or click to select files</p>
        </DropzoneInterior>
      </DropzoneContainer>
      <Previews>
        {files.map((file) => (
          <FilePreview key={file.id} file={file} onRemove={setFileToDelete} />
        ))}
      </Previews>
      {fileToDelete && (
        <DeleteFileConfirmation
          file={fileToDelete}
          purchaseOrder={purchaseOrder}
          onClose={() => setFileToDelete(null)}
          onDelete={removeFile}
        />
      )}
    </>
  );
}

export default PurchaseOrderFiles;
