import { Formik } from 'formik';
import _ from 'lodash';
import React, { useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import * as Yup from 'yup';
import {
  Button,
  Buttons,
  CancelButton,
  DeleteButton,
  Field,
  Form,
  FormMessage,
  HelpTooltip,
  Level,
  ModalCard,
  Radio,
} from '~/components';
import { useApi, useMember, useToast, useWorkspace } from '~/contexts';
import { useDirtyCheck, useForm } from '~/hooks';
import { emptyStringToNull, mergeValues } from '~/utils';

export default function SavedReportForm({ title, savedReport, search, onClose, onSaved, onDelete }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const {
    member: { securityRole },
  } = useMember();

  const [{ status, message, isSubmitting }, form] = useForm();
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const toast = useToast();

  const history = useHistory();
  const location = useLocation();

  async function handleSubmit(values) {
    try {
      form.submit();
      const params = new URLSearchParams(search ?? location.search);
      params.set('dates', values.dates);

      if (savedReport?.id) {
        const body = emptyStringToNull({
          name: values.name,
          description: values.description,
          params: _.toArray(params.entries()),
        });

        await api.www.workspaces(workspace.id).reports().saved.update(savedReport.id, body);
      } else {
        const body = emptyStringToNull({
          name: values.name,
          description: values.description,
          isShared: values.isShared,
          reportKey: savedReport?.reportKey,
          params: _.toArray(params.entries()),
        });

        const { data } = await api.www.workspaces(workspace.id).reports().saved.add(body);

        history.replace(`/app/${workspace.key}/reports/saved/${data.id}`);
      }

      await onSaved();

      toast.success('Saved report updated.');

      form.done();
    } catch ({ message }) {
      form.error({ message });
    }
  }

  const initialValues = mergeValues(
    { name: '', description: '', dates: 'fixed', isShared: false },
    {
      ...savedReport,
      dates:
        savedReport?.params?.find(([key, value]) => key === 'dates' && ['fixed', 'relative'].includes(value))?.[1] ??
        'fixed',
    },
  );

  const handleClose = () => dirtyCheck(onClose);

  return (
    <>
      <ModalCard title={title} onClose={handleClose}>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            name: Yup.string().label('Name').max(255).required(),
            description: Yup.string().label('Description').max(5000),
          })}>
          {(formik) => {
            return (
              <Form>
                <ModalCard.Body>
                  <>
                    <Form.Control>
                      <Field.Text autoFocus name="name" placeholder="Name" maxLength={255} />
                    </Form.Control>

                    <Form.Control>
                      <Field.Text name="description" placeholder="Description" maxLength={5000} />
                    </Form.Control>

                    <Form.Control>
                      <Field.RadioGroup name="dates">
                        <Radio
                          value="fixed"
                          label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              Use fixed dates
                              <HelpTooltip
                                style={{ marginLeft: '0.5rem' }}
                                message="The report will always use the specified dates."
                              />
                            </div>
                          }
                        />
                        <Radio
                          value="relative"
                          label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              Use relative dates
                              <HelpTooltip
                                style={{ marginLeft: '0.5rem' }}
                                message={`When the report is run, the relative date filter (such as "Last Year" or "This Month") will be resolved to determine the report dates.`}
                              />
                            </div>
                          }
                        />
                      </Field.RadioGroup>
                    </Form.Control>

                    {!savedReport?.id && securityRole.manageWorkspace && (
                      <Form.Control>
                        <Field.Checkbox
                          name="isShared"
                          label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              Share with all workspace members
                              <HelpTooltip
                                message="Shared reports are available to all members that have permission to the base report type."
                                style={{ marginLeft: '0.5rem' }}
                              />
                            </div>
                          }
                        />
                      </Form.Control>
                    )}
                  </>
                  {status && <FormMessage.Error>{message || 'An error has occurred.'}</FormMessage.Error>}
                </ModalCard.Body>

                <ModalCard.Footer>
                  <Level>
                    {onDelete && <DeleteButton onClick={onDelete}>Delete</DeleteButton>}

                    <Buttons align="right">
                      <CancelButton onClick={handleClose}>Close</CancelButton>
                      <Button onClick={formik.submitForm} isLoading={isSubmitting}>
                        Save
                      </Button>
                    </Buttons>
                  </Level>
                </ModalCard.Footer>
              </Form>
            );
          }}
        </Formik>
      </ModalCard>
    </>
  );
}
