import _ from 'lodash';
import React from 'react';
import allocationBillableTypes from '~/lookups/allocation-billable-types';
import MultiSelect from '../MultiSelect';

const options = _.values(allocationBillableTypes);

export default function AllocationBillableTypeFilter({
  placeholder = 'Allocation Billability',
  name,
  value,
  onChange,
}) {
  return (
    <MultiSelect.Filter
      icon="filter"
      placeholder={placeholder}
      name={name}
      value={value}
      renderValue={(value) => value.map((v) => v.name).join('; ')}
      options={options}
      onChange={onChange}
    />
  );
}
