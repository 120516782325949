import { useAuth } from '~/hooks';
import HoursVarianceByMemberAndProject from './HoursVarianceByMemberAndProject';
import HoursVarianceByProjectAndResource from './HoursVarianceByProjectAndResource';
import HoursVarianceByProjectAndRole from './HoursVarianceByProjectAndRole';

export default function useVarianceReports() {
  const auth = useAuth();

  return [
    auth.reports.hoursVarianceByMemberAndProject && {
      key: 'hours_variance_by_member_and_project',
      name: 'Hours Variance by Member and Project',
      description: 'The variance between planned hours and worked hours, grouped by member and project.',
      path: '/hours-variance-by-member-and-project',
      component: HoursVarianceByMemberAndProject,
    },
    auth.reports.hoursVarianceByProjectAndResource && {
      key: 'hours_variance_by_project_and_resource',
      name: 'Hours Variance by Project and Resource',
      description: 'The variance between planned hours and worked hours, grouped by project and resource.',
      path: '/hours-variance-by-project-and-resource',
      component: HoursVarianceByProjectAndResource,
    },
    auth.reports.hoursVarianceByProjectAndRole && {
      key: 'hours_variance_by_project_and_role',
      name: 'Hours Variance by Project and Role',
      description: 'The variance between planned hours and worked hours, grouped by project and role.',
      path: '/hours-variance-by-project-and-role',
      component: HoursVarianceByProjectAndRole,
    },
  ].filter(Boolean);
}
