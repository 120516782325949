import classNames from 'classnames';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  BillableIcon,
  Buttons,
  CancelButton,
  DateTime,
  DeleteButton,
  Duration,
  EmploymentTypeFilter,
  FiltersBar,
  FormMessage,
  Hours,
  Icon,
  Level,
  MemberFilter,
  ModalCard,
  Page,
  PeriodFilter,
  PracticeFilter,
  Table,
  TimeApprovalPopover,
  TimeLockIcon,
  Tooltip,
  YesNoFilter,
} from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { Header } from '~/components/table/TableBoxHeader';
import { Row as BoxRow } from '~/components/table/TableBoxRow';
import { Row } from '~/components/table/TableRow';
import { useApi, useConfirmation, useSubscription, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useForm, useSearchParams, useSearchParamsConfig } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { intervalOptions, intervalsByScope, QuerySort } from '~/utils';
import TableStatus from '../../reports/components/table/TableStatus';
import EditTimeEntry from '../edit-time-entry';
import ViewTimeEntry from '../view-time-entry';

const GroupedTable = styled.div`
  min-width: fit-content;
  margin-left: -1rem;
  margin-right: -1rem;

  ${Header} {
    position: sticky;
    top: 0;
    z-index: 3;
  }
`;

export const Group = styled.div`
  position: sticky;
  border: 1px solid ${colors.grey10};
  background-color: white;
  z-index: 2;
  margin-top: 0.375rem;

  &:last-child {
    border-bottom-width: 1px;
    margin-bottom: 0;
  }

  ${BoxRow} {
    margin: 0;
    border-width: 0;
    position: sticky;
    top: 1.875rem;
    background-color: white;
    margin-top: 1px;
    margin-bottom: 1px;
    background-clip: content-box;
    z-index: 2;
  }

  &.expanded ${BoxRow} {
    border-bottom-width: 1px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &:last-child {
      border-bottom: none;
    }
  }

  ${Row}:last-child {
    border-bottom: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const NoResultsCell = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  color: ${colors.grey40};
  padding: ${({ padding = '0.875rem 1rem' }) => padding};
  height: 4.375rem;
`;

const ToggleSwitch = styled.div`
  flex: 1;
  display: flex;
  min-width: 2rem;
  justify-content: center;
`;

export default function TimesheetsPage() {
  useDocumentTitle('Timesheets');
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({ status: 'loading', data: [] });

  const [params, setParams] = useState({
    period: null,
    members: [],
    memberPractices: [],
    memberEmploymentTypeIds: [],
    sort: new QuerySort('name', 'asc'),
  });

  const searchParamsConfig = useSearchParamsConfig();
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: {
          ...searchParamsConfig.period,
          default: intervalOptions.past_30_days,
        },
        members: searchParamsConfig.members,
        memberPractices: searchParamsConfig.practices,
        memberEmploymentTypeIds: searchParamsConfig.employmentTypes,
        sort: { default: new QuerySort('name', 'asc'), ...searchParamsConfig.sort },
      }),
      [searchParamsConfig],
    ),
    sessionKey: 'timesheets',
    onChange: useCallback((params) => setParams((state) => ({ ...state, ...params })), []),
  });

  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      if (params) {
        setParams((state) => ({ ...state, ...params }));
        setSearchParamsStatus('ready');
      }
    });
  }, [searchParams, searchParamsStatus]);

  // Map the values to perform the API query
  const urlSearchParams = useMemo(() => {
    const { start, end } = params.period || {};

    return {
      start: start ?? undefined,
      end: end ?? undefined,
      memberId: params.members?.map((v) => v.id),
      memberPracticeId: params.memberPractices?.map((v) => v.id),
      memberEmploymentTypeId: params.memberEmploymentTypeIds?.map((v) => v.id),
      sort: params.sort,
    };
  }, [params]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).timesheets().list(urlSearchParams);
    setQuery({ status: 'ready', data });
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [showNotes, setShowNotes] = useState(false);

  const handleFilterChange = ({ target }) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams((params) => ({ ...params, [target.name]: target.value }));
    searchParams.set({ [target.name]: target.value });
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'sorting' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  if (query.status === 'loading') return <PageLoader />;

  const timesheets = query.data;

  return (
    <Page scrollable>
      <Page.Header>
        <Page.Info>
          <Page.Eyebrow>Time</Page.Eyebrow>
          <Page.Title>Timesheets</Page.Title>
        </Page.Info>
      </Page.Header>

      <Page.Filters>
        <FiltersBar>
          <PeriodFilter
            name="period"
            placeholder="Date Range"
            intervals={[intervalOptions.all_dates, ...intervalsByScope.day]}
            value={params.period}
            onChange={handleFilterChange}
          />

          <MemberFilter name="members" value={params.members} onChange={handleFilterChange} />

          {features.practices && (
            <PracticeFilter
              name="memberPractices"
              placeholder="Member Practice"
              value={params.memberPractices}
              onChange={handleFilterChange}
            />
          )}

          <EmploymentTypeFilter
            name="memberEmploymentTypeIds"
            value={params.memberEmploymentTypeIds}
            onChange={handleFilterChange}
          />

          <YesNoFilter
            icon="gear"
            placeholder="Show Notes"
            value={showNotes ? 'yes' : 'no'}
            onChange={({ target: { value } }) => setShowNotes(value === 'yes')}
          />
        </FiltersBar>
      </Page.Filters>

      <Page.Section>
        <Level>
          <Level.Item></Level.Item>
        </Level>
      </Page.Section>

      <GroupedTable>
        <Table>
          <Table.BoxHeader style={{ top: '-1rem', borderRadius: 0 }}>
            <Table.Column width="3rem" />
            <Table.Column style={{ minWidth: '16rem', flex: '1' }} name="name" onSort={handleSort} sort={params.sort}>
              Member
            </Table.Column>
            <Table.Column name="employmentTypeId" width="9rem" onSort={handleSort} sort={params.sort}>
              Member Type
            </Table.Column>
            <Table.Column name="timesheet.start" onSort={handleSort} sort={params.sort} width="8.5rem" align="right">
              Start Date
            </Table.Column>
            <Table.Column name="timesheet.end" onSort={handleSort} sort={params.sort} width="8.5rem" align="right">
              End Date
            </Table.Column>
            <Table.Column name="timesheet.created_at" onSort={handleSort} sort={params.sort} width="9rem" align="right">
              Submitted On
            </Table.Column>
            <Table.Column width="6rem" align="right">
              Capacity
            </Table.Column>
            <Table.Column width="6rem" align="right">
              Hours
            </Table.Column>

            <Table.BoxActionsColumn style={{ marginLeft: 'auto' }} />
          </Table.BoxHeader>

          <Table.Body fade={query.status === 'filtering'}>
            {_.map(timesheets, (timesheet) => {
              return <Timesheet key={timesheet.id} timesheet={timesheet} showNotes={showNotes} onChange={fetchData} />;
            })}
          </Table.Body>

          <TableStatus total={timesheets.length} label="Timesheet" isLoading={query.status !== 'ready'} />
        </Table>
      </GroupedTable>
    </Page>
  );
}

function Timesheet({ timesheet, showNotes, onChange }) {
  const [query, setQuery] = useState({ status: 'loading', data: [] });

  const { workspace } = useWorkspace();
  const api = useApi();

  const [collapsed, setCollapsed] = useState(true);

  const fetchData = async () => {
    const { data } = await api.www.workspaces(workspace.id).timesheets(timesheet.id).timeEntries();
    setQuery({ status: 'ready', data });
  };

  const handleToggleClick = async () => {
    setCollapsed(!collapsed);

    if (collapsed) {
      fetchData();
    }
  };

  const confirmation = useConfirmation();

  const handleDelete = async () => {
    await confirmation.prompt((resolve) => (
      <DeleteTimesheetConfirmation timesheet={timesheet} resolve={resolve} onDelete={onChange} />
    ));
  };

  const handleTimeEntryChange = () => {
    fetchData();
    onChange();
  };

  return (
    <Group className={classNames({ expanded: !collapsed })}>
      <Table.BoxRow onClick={handleToggleClick}>
        <Table.Cell flex="0" padding="1.5rem 0.25rem 1.5rem 0.75rem">
          <ToggleSwitch>
            <Icon color={colors.grey25} icon={collapsed ? 'chevron-right' : 'chevron-down'} />
          </ToggleSwitch>
        </Table.Cell>

        <Table.Cell style={{ minWidth: '16rem', flex: '1' }}>{timesheet.member.name}</Table.Cell>

        <Table.Cell>{timesheet.member.employmentType.name}</Table.Cell>

        <Table.Cell>
          <DateTime value={timesheet.start} />
        </Table.Cell>

        <Table.Cell>
          <DateTime value={timesheet.end} />
        </Table.Cell>

        <Table.Cell>
          <DateTime value={timesheet.createdAt} />
        </Table.Cell>

        <Table.Cell>
          <Hours value={timesheet.capacity} />
        </Table.Cell>

        <Table.Cell>
          <Hours value={timesheet.hours} />
        </Table.Cell>

        {timesheet.permissions.delete ? (
          <TableBoxRowActions style={{ marginLeft: 'auto', overflow: 'hidden' }}>
            <TableBoxRowActions.Delete icon="inbox-out" tooltip="Unsubmit this timesheet." onClick={handleDelete} />
          </TableBoxRowActions>
        ) : (
          <>
            <TableBoxRowActions style={{ marginLeft: 'auto' }}>
              <TableBoxRowActions.Delete
                icon="inbox-out"
                tooltip="You cannot unsubmit this timesheet."
                disabled={true}
                style={{ color: colors.grey25 }}
              />
            </TableBoxRowActions>
          </>
        )}
      </Table.BoxRow>

      {!collapsed && (
        <Table>
          <Table.Header style={{ display: 'none' }}>
            <Table.Column width="3.5rem" />
            <Table.Column width="7.5rem" />
            <Table.Column />
            <Table.Column width="8rem" align="right" />
          </Table.Header>

          <Table.Body>
            {query.status === 'loading' && (
              <Table.Row>
                <NoResultsCell style={{ justifyContent: 'center' }}>
                  <Icon icon="spinner" spin color={colors.grey40} />
                </NoResultsCell>
              </Table.Row>
            )}

            {query.status === 'ready' &&
              (query.data.length === 0 ? (
                <Table.Row>
                  <NoResultsCell flex="1" style={{ width: '100%', color: colors.grey40 }}>
                    This timesheet does not have any time entries.
                  </NoResultsCell>
                </Table.Row>
              ) : (
                query.data.map((entry) => {
                  return (
                    <TimeEntry key={entry.id} entry={entry} showNotes={showNotes} onChange={handleTimeEntryChange} />
                  );
                })
              ))}
          </Table.Body>
        </Table>
      )}
    </Group>
  );
}

const TimeEntryDetails = styled.div`
  display: 'flex';
  flex-direction: 'column';
  flex: 1;
`;

const Details = styled.div`
  flex: 1;

  display: flex;

  > div {
    flex: 1;
  }

  p:not(:last-child) {
    margin-bottom: 0.125rem;
  }
`;

const Notes = styled.p`
  margin-top: 0.5rem;
  width: 100%;
  font-size: 0.75rem;
  text-align: justify;
  color: ${colors.grey55};

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }
`;

const Byline = styled.small`
  display: block;
`;

const Time = styled.div`
  position: relative;
  padding: 0.2rem 1.5rem;
  font-weight: ${weights.medium};
  border-radius: 999rem;
  width: 6.25rem;
  text-align: center;

  &,
  &.icon {
    ${({ status }) =>
      ({
        not_submitted: css`
          background: ${colors.grey10};
          color: ${colors.black};
        `,
        pending_approval: css`
          background: ${colors.warning10};
          color: ${colors.warning};
        `,
        rejected: css`
          background: ${colors.danger10};
          color: ${colors.danger};
        `,
        approved: css`
          background: ${colors.primary10};
          color: ${colors.primary};
        `,
      })[status]}
  }
`;

const TimeEntryInfo = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > div {
    margin-right: 1rem;
  }
`;

const Stopwatch = styled(Tooltip)`
  position: absolute;
  top: 0.2rem;
  left: 0.5rem;
`;

function TimeEntry({ entry, showNotes, onChange }) {
  const [drawer, setDrawer] = useState(null);

  const api = useApi();
  const { workspace } = useWorkspace();

  const handleClick = () => {
    setDrawer(entry.isLocked ? 'view' : 'edit');
  };

  const handleCloseDrawer = () => {
    setDrawer(null);
  };

  return (
    <>
      <Table.Row onClick={handleClick} key={entry.id}>
        <Table.Cell>{entry.isLocked && <TimeLockIcon value={entry.lockStatusId} />}</Table.Cell>

        <Table.Cell>
          <DateTime value={entry.date} />
        </Table.Cell>

        <Table.Cell>
          <TimeEntryDetails>
            <Details>
              <div>
                {entry.typeId === 'project_time' ? (
                  entry.project && (
                    <p>
                      {entry.project.name}
                      <Byline>{entry.project.client.name}</Byline>
                    </p>
                  )
                ) : (
                  <p>{entry.timeOffType?.name}</p>
                )}
              </div>

              <div>
                {entry.task && <p>{entry.task.name}</p>}

                {entry.project?.useRoles && entry.role && <p>{entry.role.name}</p>}
              </div>
            </Details>

            {showNotes && entry.notes && (
              <Notes>
                <em>{entry.notes}</em>
              </Notes>
            )}
          </TimeEntryDetails>
        </Table.Cell>

        <Table.Cell>
          <TimeApprovalPopover timeEntryId={entry.id}>
            <TimeEntryInfo>
              <BillableIcon value={entry.isActuallyBillable} />
              <Time status={entry.statusId}>
                {entry.timerStartedAt && (
                  <Stopwatch message="This time entry has a running timer">
                    <Icon icon="stopwatch" type="far" />
                  </Stopwatch>
                )}
                <Duration minutes={entry.minutes} timerStartedAt={entry.timerStartedAt} trim />
              </Time>
            </TimeEntryInfo>
          </TimeApprovalPopover>
        </Table.Cell>
      </Table.Row>

      {drawer &&
        {
          edit: () => (
            <EditTimeEntry
              id={entry.id}
              memberId={entry.member.id}
              onSubmit={(body) => api.www.workspaces(workspace.id).timeAdmin(entry.id).update(body)}
              onConfirmDelete={(id) => api.www.workspaces(workspace.id).timeAdmin(id).delete()}
              onSaved={onChange}
              onDeleted={onChange}
              onClose={handleCloseDrawer}
              onTimerChange={onChange}
            />
          ),
          view: () => <ViewTimeEntry id={entry.id} onClose={handleCloseDrawer} />,
        }[drawer]()}
    </>
  );
}

function DeleteTimesheetConfirmation({ timesheet, resolve, onDelete }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ status, message, isSubmitting }, form] = useForm();
  const { notify } = useSubscription();
  const handleDelete = async () => {
    form.submit();

    try {
      await api.www.workspaces(workspace.id).timesheets(timesheet.id).unsubmit();
      await onDelete();
      resolve(true);
      notify(useSubscription.keys.refresh_time_approval_count);
    } catch ({ message }) {
      form.error(message);
    }
  };

  return (
    <ModalCard title="Unsubmit Timesheet" onClose={() => resolve(false)}>
      <ModalCard.Body>
        Are you sure you want to unsubmit this timesheet?
        {status && <FormMessage.Error>{message}</FormMessage.Error>}
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={() => resolve(false)}>Cancel</CancelButton>
          <DeleteButton isLoading={isSubmitting} onClick={handleDelete}>
            Unsubmit
          </DeleteButton>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}
