import React from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { Buttons, CancelButton, ModalCard } from '~/components';

const AnchorButton = styled.a`
  display: flex;
  align-items: center;
  height: 2.5em;
  padding: 0.5em 1.75em;
  color: ${colors.white};
  font-weight: ${weights.bold};
  white-space: nowrap;
  background-color: ${colors.primary};
  border: solid 1px transparent;
  border-radius: 999rem;
  cursor: pointer;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.accent};
  }
`;

export default function ReauthorizeConfirmation({ integration, onClose }) {
  return (
    <ModalCard title="Reauthorize Connection" onClose={onClose}>
      <ModalCard.Body>
        Reauthorizing the connection with Rippling is only required if there is a connection issue or if the Rippling
        administrator that initially configured the integration can no longer access the Ruddr app within Rippling.
        <br />
        <br />
        Before reauthorizing the connection, please remove the Ruddr application from Rippling. If the application has
        been removed in Rippling, please proceed by clicking the Reauthorize button below.
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <AnchorButton href={integration.authorizeUri}>Reauthorize</AnchorButton>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}
