import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import { Redirect } from 'react-router-dom';
import { ExportDialog } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useSearchParams } from '~/hooks';
import ActionBar from '~/routes/app/resources/allocations/components/action-bar/ActionBar';
import {
  Allocations,
  Grid,
  Schedule,
  Sidebar,
  useGroups,
  useSchedule,
} from '~/routes/app/resources/allocations/components/schedule';
import AllocationDetails from '~/routes/app/resources/allocations/dialogs/AllocationDetails';
import Cells from '~/routes/app/resources/allocations/member-schedule/cells/Cells';
import Empty from '~/routes/app/resources/allocations/member-schedule/sidebar/Empty';
import Group from '~/routes/app/resources/allocations/member-schedule/sidebar/Group';
import Row from '~/routes/app/resources/allocations/member-schedule/sidebar/Row';
import { PageLoader } from '~/routes/public/pages';
import { dateFormats, mimeTypes } from '~/utils';

const periods = {
  day: 3,
  week: 7,
  month: 11,
};

const merge = (source, target, key) => {
  const sourceObject = _.keyBy(source, key);
  const targetObject = _.keyBy(target, key);
  const merged = _.merge(sourceObject, targetObject);
  const result = _.values(merged);
  return result;
};

export default function MyAllocations() {
  const route = useRouteMatch();
  const history = useHistory();
  const api = useApi();
  const { workspace } = useWorkspace();

  const documentTitle = useDocumentTitle('My Allocations');

  const [query, setQuery] = useState({
    status: 'loading',
    action: 'load',
    fetching: false,
    resources: [],
    assignments: [],
    allocations: [],
    metrics: [],
  });

  const location = useLocation();

  const [params, setParams] = useState({
    date: moment().format(dateFormats.isoDate),
    unit: 'week',
  });

  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        date: {
          default: moment().format(dateFormats.isoDate),
          deserialize: (value) => (moment(value).isValid() ? moment(value).format(dateFormats.isoDate) : null),
        },
        unit: { default: 'week', valid: ['day', 'week', 'month'] },
      }),
      [],
    ),
    sessionKey: 'my_allocations',
    onChange: useCallback((params) => setParams((state) => ({ ...state, ...params })), []),
  });

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      if (params) {
        setParams((state) => ({ ...state, ...params }));
        setSearchParamsStatus('ready');
      }
    });
  }, [searchParams, searchParamsStatus]);

  const { start, end } = useMemo(() => {
    let start;
    let end;

    switch (params.unit) {
      case 'day':
        start = moment(params.date).startOf('isoWeek').format(dateFormats.isoDate);
        end = moment(params.date).add(periods.day, 'weeks').endOf('isoWeek').format(dateFormats.isoDate);
        break;

      case 'week':
        start = moment(params.date).startOf('isoWeek').format(dateFormats.isoDate);
        end = moment(params.date).add(periods.week, 'weeks').endOf('isoWeek').format(dateFormats.isoDate);
        break;

      case 'month':
        start = moment(params.date).startOf('month').format(dateFormats.isoDate);
        end = moment(params.date).add(periods.month, 'months').endOf('month').format(dateFormats.isoDate);
        break;
    }

    return { start, end };
  }, [params.date, params.unit]);

  const data = useMemo(() => {
    const allocations = query.allocations.filter(
      (allocation) => moment(allocation.start).isSameOrBefore(end) && moment(allocation.end).isSameOrAfter(start),
    );

    const assignmentsById = _.keyBy(query.assignments, 'id');
    const allocationsByResource = _.groupBy(allocations, 'resourceId');

    let resources = query.resources;

    const rows = [];

    const metricGroups = _.groupBy(query.metrics, (metric) => `${metric.id}_${metric.start}`);

    const periodCount = moment(end).diff(start, params.unit) + 1;

    for (const resource of resources) {
      const group = {
        id: resource.id,
        type: 'group',
        parentId: null,
        resourceId: resource.id,
        resource,
        resourceType: resource.resourceType,
        hasAllocations: allocationsByResource[resource.id]?.length > 0,
        cells: [],
      };
      rows.push(group);

      const resourceAssignments = {};

      // Order the allocations by assignment type and name
      const resourceAllocations = _.orderBy(allocationsByResource[resource.id] || [], [
        'assignmentTypeId',
        (allocation) => assignmentsById[allocation.assignmentId]?.name,
      ]);

      for (let index = 0; index < periodCount; index++) {
        const date = moment(start).add(index, params.unit).format(dateFormats.isoDate);

        const key = `${group.id}_${date}`;

        const metric = metricGroups[key] ? metricGroups[key][0] : {};

        group.cells.push({
          key: `${group.id}_${date}`,
          mode: 'heatmap',
          type: 'group',
          group,
          date,
          allocated: metric.allocated ?? 0,
          capacity: metric.capacity ?? 0,
          billable: metric.billable ?? 0,
          productive: metric.productive ?? 0,
          targetBillable: metric.targetBillable ?? 0,
        });
      }

      for (const allocation of resourceAllocations) {
        if (!resourceAssignments[allocation.assignmentId]) {
          resourceAssignments[allocation.assignmentId] = {
            id: `${resource.id}_${allocation.assignmentId}`,
            assignmentId: allocation.assignmentId,
            type: 'row',
            resourceId: resource.id,
            parentId: group.id,
            assignment: assignmentsById[allocation.assignmentId],
            allocations: [],
            cells: [],
          };
        }

        resourceAssignments[allocation.assignmentId].allocations.push(allocation);
      }

      _.forEach(resourceAssignments, (value) => {
        // Add cells for each assignment row
        for (let index = 0; index < periodCount; index++) {
          const date = moment(start).add(index, params.unit).format(dateFormats.isoDate);

          value.cells.push({
            key: `${value.id}_${date}`,
            mode: 'row',
            type: 'row',
            group,
            row: value,
            date,
            manage: resource.permissions.manageAllocations,
          });
        }

        rows.push(value);
      });
    }

    return { rows };
  }, [query.resources, query.assignments, query.allocations, query.metrics, params.unit, start, end]);

  const updateParams = useCallback(
    (params) => {
      setParams((state) => ({ ...state, ...params }));
      searchParams.set(params);
    },
    [searchParams],
  );

  const handleGroupsToggle = useCallback(
    (status) => {
      updateParams({ groups: status });
    },
    [updateParams],
  );

  const { groups, hasExpandedGroups, toggleGroup, toggleGroups } = useGroups({
    rows: data?.rows,
    defaultGroupStatus: 'expanded',
    onGroupsToggle: handleGroupsToggle,
  });

  const bodyRef = useRef();
  const canvasRef = useRef();

  const fetchAllocations = useCallback(async () => {
    setQuery((state) => ({ ...state, fetching: true, action: 'fetch-allocations' }));

    const { data } = await api.www.workspaces(workspace.id).personalDashboard(workspace.id).myAllocations({
      start,
      end,
      unit: params.unit,
    });

    setQuery((state) => ({
      ...state,
      fetching: false,
      action: null,
      allocations: data.allocations,
      resources: data.resources,
      assignments: data.assignments,
      metrics: data.metrics,
    }));

    return data;
  }, [start, end, params, api, workspace.id]);

  const fetchMoreAllocations = useCallback(
    async ({ start, end }) => {
      setQuery((state) => ({ ...state, fetching: true }));

      const { data } = await api.www.workspaces(workspace.id).personalDashboard(workspace.id).myAllocations({
        start,
        end,
        unit: params.unit,
      });

      setQuery((state) => ({
        ...state,
        status: 'ready',
        action: 'fetch-more',
        fetching: false,
        allocations: merge(state.allocations, data.allocations, 'id'),
        resources: merge(state.resources, data.resources, 'id'),
        assignments: merge(state.assignments, data.assignments, 'id'),
        metrics: merge(state.metrics, data.metrics, (obj) => `${obj.id}_${obj.start}`),
      }));

      return data;
    },
    [params, api, workspace.id],
  );

  useEffect(() => {
    if (searchParamsStatus !== 'ready' || !['load', 'refetch'].includes(query.action)) return;

    (async () => {
      await fetchAllocations();
      setQuery((state) => ({ ...state, fetching: false, action: null, status: 'ready' }));
    })();
  }, [searchParamsStatus, query.action, fetchAllocations]);

  const components = useMemo(
    () => ({
      sidebar: {
        Empty,
        Group,
        Row: (props) => <Row {...props} options={{ showAssigned: true }} />,
      },
    }),
    [],
  );

  const {
    virtualRows: rows,
    allocations,
    cells,
    styles,
  } = useSchedule({ data, start, end, unit: params.unit, groups, parentRef: bodyRef });

  const refetch = () => {
    setQuery((state) => ({
      ...state,
      action: 'refetch',
      metrics: [],
    }));
  };

  const handleDateChange = (date) => {
    updateParams({ date });
    refetch();
  };

  const handleDateNavPrevious = () => {
    const date = {
      day: moment(params.date).subtract(1, 'week'),
      week: moment(params.date).subtract(1, 'week'),
      month: moment(params.date).subtract(1, 'month'),
    }[params.unit].format(dateFormats.isoDate);

    updateParams({ date });

    const { start, end } = {
      day: {
        start: moment(date).startOf('isoWeek').format(dateFormats.isoDate),
        end: moment(date).endOf('isoWeek').format(dateFormats.isoDate),
      },
      week: {
        start: moment(date).startOf('isoWeek').format(dateFormats.isoDate),
        end: moment(date).endOf('isoWeek').format(dateFormats.isoDate),
      },
      month: {
        start: moment(date).startOf('month').format(dateFormats.isoDate),
        end: moment(date).endOf('month').format(dateFormats.isoDate),
      },
    }[params.unit];

    fetchMoreAllocations({ start, end });
  };

  const handleDateNavNext = () => {
    const date = {
      day: moment(params.date).add(1, 'week'),
      week: moment(params.date).add(1, 'week'),
      month: moment(params.date).add(1, 'month'),
    }[params.unit].format(dateFormats.isoDate);

    updateParams({ date });

    const { start, end } = {
      day: {
        start: moment(date).add(periods.day, 'weeks').startOf('isoWeek').format(dateFormats.isoDate),
        end: moment(date).add(periods.day, 'weeks').endOf('isoWeek').format(dateFormats.isoDate),
      },
      week: {
        start: moment(date).add(periods.week, 'weeks').startOf('isoWeek').format(dateFormats.isoDate),
        end: moment(date).add(periods.week, 'weeks').endOf('isoWeek').format(dateFormats.isoDate),
      },
      month: {
        start: moment(date).add(periods.month, 'months').startOf('month').format(dateFormats.isoDate),
        end: moment(date).add(periods.month, 'months').endOf('month').format(dateFormats.isoDate),
      },
    }[params.unit];

    fetchMoreAllocations({ start, end });
  };

  const handleUnitChange = async (unit) => {
    if (unit !== params.unit) {
      setQuery((state) => ({ ...state, status: 'grid_loading' }));
      updateParams({ unit });
      refetch();
    }
  };

  const handleView = (allocation) => {
    history.push({ pathname: `${route.url}/view/${allocation.id}`, search: location.search });
  };

  const handleFormClose = () => {
    history.push({ pathname: route.url, search: location.search });
    documentTitle.set('My Allocations');
  };

  const confirmation = useConfirmation();

  const handleExport = async (mimeType) => {
    const filename =
      mimeType === mimeTypes.xlsx ? `my_allocations_by_${params.unit}.xlsx` : `my_allocations_by_${params.unit}.csv`;

    const exportParams = { start, end, unit: params.unit };
    const headers = { headers: { accept: mimeType }, responseType: 'blob' };

    await confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www.workspaces(workspace.id).personalDashboard().exportMyAllocations(exportParams, headers)}
        onClose={resolve}
      />
    ));
  };

  if (query.status === 'loading') return <PageLoader />;

  return (
    <>
      <ActionBar
        unit={params.unit}
        date={params.date}
        start={start}
        end={end}
        filters={false}
        create={false}
        disabled={query.fetching}
        onDateChange={handleDateChange}
        onDateNavNext={handleDateNavNext}
        onDateNavPrevious={handleDateNavPrevious}
        onUnitChange={handleUnitChange}
        onExport={handleExport}
      />

      <Schedule>
        <Grid
          canvasRef={canvasRef}
          bodyRef={bodyRef}
          start={start}
          end={end}
          unit={params.unit}
          rows={rows}
          styles={styles}
          loading={query.status !== 'ready'}
          navigation={false}
          readOnly={true}
          Sidebar={() => (
            <Sidebar
              groups={groups}
              toggleGroup={toggleGroup}
              toggleGroups={toggleGroups}
              hasExpandedGroups={hasExpandedGroups}
              styles={styles}
              rows={rows}
              components={components}
              parentRef={bodyRef}
            />
          )}
          onDateChange={handleDateChange}>
          {query.status === 'ready' && rows.length > 0 && (
            <>
              <Cells
                bodyRef={bodyRef}
                styles={styles}
                metric={'allocated_hours_simple'}
                cells={cells}
                rows={rows}
                start={start}
                end={end}
                unit={params.unit}
                readOnly={true}
                allocations={query.allocations}
              />

              <Allocations
                allocations={allocations}
                rows={rows}
                styles={styles}
                canvasRef={canvasRef}
                parentRef={bodyRef}
                readOnly={true}
                onView={handleView}
              />
            </>
          )}
        </Grid>
      </Schedule>

      <Switch>
        <Route path={route.path.concat('/view/:allocationId')}>
          <AllocationDetails onClose={handleFormClose} />
        </Route>

        <Redirect to={route.url.concat(location.search)} />
      </Switch>
    </>
  );
}
