import React from 'react';
import {
  DisciplineFilter,
  EmploymentTypeFilter,
  JobTitleFilter,
  LocationFilter,
  MemberBillableTypeFilter,
  MemberCostMethodFilter,
  MemberFilter,
  MemberInvitationStatusFilter,
  MemberStatusFilter,
  MemberTagFilter,
  PracticeFilter,
  SecurityRoleFilter,
  SkillFilter,
} from '~/components';
import ReportPeriodFilter from './ReportPeriodFilter';

function MemberFiltersBar({ filters, onChange }) {
  return (
    <>
      {(filters.memberActive?.start || filters.memberActive?.end) && (
        <ReportPeriodFilter
          placeholder="Member Active"
          value={filters.memberActive}
          onChange={({ value }) => onChange({ memberActive: value })}
        />
      )}

      {filters.members?.length > 0 && (
        <MemberFilter value={filters.members} onChange={({ value }) => onChange({ members: value })} />
      )}

      {filters.securityRoles?.length > 0 && (
        <SecurityRoleFilter
          value={filters.securityRoles}
          onChange={({ value }) => onChange({ securityRoles: value })}
        />
      )}

      {filters.employmentTypes?.length > 0 && (
        <EmploymentTypeFilter
          value={filters.employmentTypes}
          onChange={({ value }) => onChange({ employmentTypes: value })}
        />
      )}

      {filters.memberBillableType && (
        <MemberBillableTypeFilter
          value={filters.memberBillableType}
          onChange={({ value }) => onChange({ memberBillableType: value })}
        />
      )}

      {filters.memberStatus && (
        <MemberStatusFilter value={filters.memberStatus} onChange={({ value }) => onChange({ memberStatus: value })} />
      )}

      {filters.memberDisciplines?.length > 0 && (
        <DisciplineFilter
          placeholder="Member Discipline"
          value={filters.memberDisciplines}
          onChange={({ value }) => onChange({ memberDisciplines: value })}
        />
      )}

      {filters.jobTitles?.length > 0 && (
        <JobTitleFilter value={filters.jobTitles} onChange={({ value }) => onChange({ jobTitles: value })} />
      )}

      {filters.memberPractices?.length > 0 && (
        <PracticeFilter
          placeholder="Member Practice"
          value={filters.memberPractices}
          onChange={({ value }) => onChange({ memberPractices: value })}
        />
      )}

      {filters.managers?.length > 0 && (
        <MemberFilter
          placeholder="Member Manager"
          value={filters.managers}
          onChange={({ value }) => onChange({ managers: value })}
        />
      )}

      {filters.memberSkills?.length > 0 && (
        <SkillFilter
          placeholder="Member Skills"
          value={filters.memberSkills}
          onChange={({ value }) => onChange({ memberSkills: value })}
        />
      )}

      {filters.memberTags?.length > 0 && (
        <MemberTagFilter value={filters.memberTags} onChange={({ value }) => onChange({ memberTags: value })} />
      )}

      {filters.memberCostMethod && (
        <MemberCostMethodFilter
          value={filters.memberCostMethod}
          onChange={({ value }) => onChange({ memberCostMethod: value })}
        />
      )}

      {filters.memberLocations?.length > 0 && (
        <LocationFilter
          placeholder="Member Location"
          value={filters.memberLocations}
          onChange={({ value }) => onChange({ memberLocations: value })}
        />
      )}

      {filters.memberInvitationStatuses?.length > 0 && (
        <MemberInvitationStatusFilter
          value={filters.memberInvitationStatuses}
          onChange={({ value }) => onChange({ memberInvitationStatuses: value })}
        />
      )}
    </>
  );
}

export default MemberFiltersBar;
