import _ from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  ClientLink,
  CreateButton,
  Currency,
  FiltersBar,
  Icon,
  MultilineText,
  Page,
  Panel,
  PipelineActivityTypeFilter,
  SearchInput,
  SocialMediaLink,
  Stack,
} from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useDocumentTitle, useNumberFormat } from '~/hooks';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';
import PipelineActivityForm from '../activity/PipelineActivityForm';
import { ActivityItem } from '../components/ActivityItem';

const GroupHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  background-color: ${colors.grey5};
  color: ${colors.grey75};
  width: 100%;
  margin-bottom: 1rem;
  cursor: pointer;
  &:hover {
    background-color: ${colors.grey25};
  }
`;

const NoResults = styled.div`
  color: ${colors.grey40};
`;

const SocialMediaBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .icon {
    margin-right: 0.5rem;
    font-size: 1.5rem;

    &:hover {
      color: ${colors.grey55};
    }
  }
`;

function CompanyDetailsPage({ company }) {
  const documentTitle = useDocumentTitle(company.name);

  const { workspace } = useWorkspace();
  const api = useApi();

  const [groupedOptions, setGroupedOptions] = useState([]);
  const [showActivities, setShowActivities] = useState({ upcoming: true, last30Days: true, olderThan30Days: true });
  const [status, setStatus] = useState('loading');
  const [dialog, setDialog] = useState(null);
  const [params, setParams] = useState({ q: '', typeIds: [] });

  const fetchData = useCallback(async () => {
    try {
      const { data: activities } = await api.www
        .workspaces(workspace.id)
        .pipelineActivities()
        .get({
          companyId: company.id,
          q: params.q,
          typeId: params.typeIds.length ? params.typeIds.map((v) => v.id) : [],
        });

      const groups =
        activities &&
        activities.reduce((a, v) => {
          let activityDate = moment(v.date);
          let now = moment();
          let thirtyDaysAgo = moment().subtract(30, 'days');
          let key;

          if (activityDate.isAfter(now)) {
            key = 'upcoming';
          } else if (activityDate.isAfter(thirtyDaysAgo)) {
            key = 'last30Days';
          } else {
            key = 'olderThan30Days';
          }

          a[key] = a[key] || { key, activities: [] };
          a[key].activities.push(v);
          return a;
        }, {});

      const groupOrder = ['upcoming', 'last30Days', 'olderThan30Days'];
      const orderedGroups = _.orderBy(groups, (group) => groupOrder.indexOf(group.key));

      const options = [];
      for (const group of orderedGroups) {
        options.push({ type: 'group', group, count: group.activities.length });
        group.activities.forEach((activity) => {
          const participants = [
            ...activity.members.map((activityMember) => activityMember.member.name),
            ...activity.contacts.map((activityContact) => activityContact.contact.name),
          ];
          options.push({ type: 'activity', activity, group, participants });
        });
      }

      setGroupedOptions(options);
      setStatus('ready');
    } catch (error) {
      setGroupedOptions(null);
    }
  }, [api, company, params.q, params.typeIds, workspace.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = ({ target: { name, value } }) => {
    setStatus('filtering');
    setParams((params) => ({ ...params, [name]: value }));
  };

  function handleOpenActivityForm({ activity }) {
    setDialog(
      <PipelineActivityForm
        company={company}
        activity={activity}
        onClose={() => {
          setDialog(null);
          documentTitle.set(company.name);
        }}
        onSaved={() => {
          setDialog(null);
          documentTitle.set(company.name);
          fetchData();
        }}
      />,
    );
  }

  const toggleShowActivities = (key) => {
    setShowActivities((current) => ({
      ...current,
      [key]: !current[key],
    }));
  };

  const numberFormat = useNumberFormat({ minimumFractionDigits: 0, maximumFractionDigits: 0 });

  if (!company) return <ErrorPage.NotFound />;

  return (
    <Page.Content>
      <Page.Main>
        <Page.TabHeader>
          <Page.Title>Activities</Page.Title>

          <Page.Actions>
            {company.permissions.manage && (
              <CreateButton tooltip="New Activity" onClick={() => handleOpenActivityForm({})} />
            )}
          </Page.Actions>
        </Page.TabHeader>

        <Page.Filters>
          <FiltersBar>
            <SearchInput value={params.q} placeholder="Search" onChange={handleChange} />

            <PipelineActivityTypeFilter
              name="typeIds"
              value={params.typeIds}
              entity="company"
              onChange={handleChange}
            />
          </FiltersBar>
        </Page.Filters>

        <Page.Section>
          <Stack style={{ opacity: `${status === 'filtering' ? 0.2 : 1}` }}>
            {status === 'loading' && <PageLoader />}
            {groupedOptions?.length > 0 &&
              groupedOptions.map((o) => {
                switch (o.type) {
                  case 'group':
                    return (
                      <GroupHeader key={o.group.key} onClick={() => toggleShowActivities(o.group.key)}>
                        <div style={{ textTransform: 'uppercase' }}>
                          <strong>
                            {
                              {
                                upcoming: 'Upcoming',
                                last30Days: 'Last 30 Days',
                                olderThan30Days: ' Older Than 30 Days',
                              }[o.group.key]
                            }
                          </strong>
                          <Icon icon={showActivities[o.group.key] ? 'angle-up' : 'angle-down'} spaceLeft />
                        </div>

                        <div>{pluralize('Activity', parseInt(numberFormat.format(o.count), 10), true)}</div>
                      </GroupHeader>
                    );
                  case 'activity': {
                    if (!showActivities[o.group.key]) return null;

                    return (
                      <ActivityItem
                        key={o.activity.id}
                        item={o.activity}
                        handleOpenActivityForm={handleOpenActivityForm}
                        participants={o.participants}
                        entity="company"
                      />
                    );
                  }
                  default:
                    return null;
                }
              })}
            {status === 'ready' && groupedOptions?.length === 0 && <NoResults>No Results</NoResults>}
          </Stack>
        </Page.Section>
      </Page.Main>

      <Panel>
        <Panel.Title>Company Details</Panel.Title>

        {company.client && (
          <Panel.Info title="Client Name">
            <ClientLink client={company.client} />
          </Panel.Info>
        )}

        {company.owner && (
          <Panel.Info title="Relationship Owner">
            <Panel.Member member={company.owner} />
          </Panel.Info>
        )}

        {company.description && (
          <Panel.Info title="Description">
            <MultilineText value={company.description} align={'left'} chars={280} buttonProps={{ isAnchor: true }} />
          </Panel.Info>
        )}

        {company.industry && <Panel.Info title="Industry">{company.industry.name}</Panel.Info>}

        {company.practice && <Panel.Info title="Practice">{company.practice.name}</Panel.Info>}

        {company.companySpendTier && (
          <Panel.Info title="Company Spend Tier">{company.companySpendTier.name}</Panel.Info>
        )}

        {company.companyType && <Panel.Info title="Company Type">{company.companyType.name}</Panel.Info>}

        <Panel.Info title="Currency">
          {company.currencyName} ({company.currency})
        </Panel.Info>

        {company.revenue && (
          <Panel.Info title="Revenue">
            <Currency value={company.revenue} currency={company.currency} />
          </Panel.Info>
        )}

        {company.yearFounded && <Panel.Info title="Year Founded">{company.yearFounded}</Panel.Info>}

        {company.ownershipClass && (
          <Panel.Info title="Ownership Class">
            {
              {
                public: 'Public',
                private: 'Private',
                subsidiary: 'Subsidiary',
                other: 'Other',
              }[company.ownershipClass]
            }
          </Panel.Info>
        )}

        {company.tickerSymbol && <Panel.Info title="Ticker Symbol">{company.tickerSymbol}</Panel.Info>}

        {company.sicCode && (
          <Panel.Info title="SIC Code">
            {company.sicCode.code} ({company.sicCode.industryTitle})
          </Panel.Info>
        )}

        {company.phoneNumber && <Panel.Info title="Phone Number">{company.phoneNumber}</Panel.Info>}

        {company.numberOfEmployees && <Panel.Info title="Number of Employees">{company.numberOfEmployees}</Panel.Info>}

        {company.billingAddress && <Panel.Info title="Billing Address">{company.billingAddress}</Panel.Info>}

        {company.shippingAddress && <Panel.Info title="Shipping Address">{company.shippingAddress}</Panel.Info>}

        {company.websiteUrl && (
          <Panel.Info title="Website">
            <a href={company.websiteUrl} target="_blank" rel="noopener noreferrer">
              {company.websiteUrl}
            </a>
          </Panel.Info>
        )}
        {(company.twitterUrl || company.facebookUrl || company.linkedinUrl) && (
          <Panel.Info title="Social Media">
            <SocialMediaBlock>
              {company.twitterUrl && <SocialMediaLink icon="twitter-square" url={company.twitterUrl} />}
              {company.linkedinUrl && <SocialMediaLink icon="linkedin" url={company.linkedinUrl} />}
              {company.facebookUrl && <SocialMediaLink icon="facebook-square" url={company.facebookUrl} />}
            </SocialMediaBlock>
          </Panel.Info>
        )}
      </Panel>

      {dialog}
    </Page.Content>
  );
}
export default CompanyDetailsPage;
