import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Button, Buttons, CancelButton, Field, Form, FormMessage, ModalCard, Checkbox } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';
import { Description } from './SalesforceSetup';
import ReadTextbox from '~/components/read-only/ReadTextbox';

export function CaptureCredentials({ onClose, onFinish }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const initialValues = {
    username: '',
    password: '',
    securityToken: '',
  };

  async function handleSubmit(values) {
    setIsLoading(true);
    setErrorMessage();

    try {
      const trimmedPassword = values.password.trim();
      const trimmedToken = values.securityToken.trim();
      const combinedPassphrase = `${trimmedPassword}${trimmedToken}`;

      await api.www.workspaces(workspace.id).salesforce.setup({
        username: values.username,
        passphrase: combinedPassphrase,
      });
      onFinish();
    } catch ({ message }) {
      if (message) {
        setErrorMessage(message);
      } else {
        setErrorMessage('There was a problem completing your request. Please try again later.');
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        username: Yup.string().label('username').max(255).required(),
        password: Yup.string().label('password').max(100).required(),
        securityToken: Yup.string().label('security token').max(155).required(),
      })}>
      {({ values }) => {
        const combinedPassphrase = `${values.password.trim()}${values.securityToken.trim()}`;
        return (
          <Form>
            <ModalCard.Body>
              <Description>
                To setup an integration with Salesforce, add your username, password, and security token. The security
                passphrase will be automatically generated.
              </Description>
              <Form.Control>
                <Field.Text name="username" placeholder="Username" maxLength={255} />
              </Form.Control>
              <Form.Control>
                <Field.Text
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  maxLength={255}
                />
              </Form.Control>
              <Form.Control>
                <Field.Text
                  name="securityToken"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Security Token"
                  maxLength={255}
                />
              </Form.Control>
              <Form.Control>
                <ReadTextbox
                  label="Security Passphrase"
                  value={showPassword ? combinedPassphrase : '•'.repeat(combinedPassphrase.length)}
                />
              </Form.Control>
              <Checkbox
                checked={showPassword}
                onChange={() => setShowPassword(!showPassword)}
                label="Show Password, Security Token and Passphrase"
              />
              {errorMessage && <FormMessage.Error>{errorMessage}</FormMessage.Error>}
            </ModalCard.Body>
            <ModalCard.Footer>
              <Buttons align="right">
                <CancelButton onClick={onClose} style={{ marginRight: 'auto' }}>
                  Close
                </CancelButton>
                <Button type="submit" isLoading={isLoading}>
                  Save
                </Button>
              </Buttons>
            </ModalCard.Footer>
          </Form>
        );
      }}
    </Formik>
  );
}
