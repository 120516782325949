import _ from 'lodash';
import React from 'react';
import {
  ApprovalStatusFilter,
  ClientApprovalStatusFilter,
  ExpenseBillableTypeFilter,
  ExpenseCategoryFilter,
  ExpenseReimbursableTypeFilter,
  InvoicedFilter,
} from '~/components';
import ReimbursedFilter from '~/components/filters/ReimbursedFilter';
import { useFeatures } from '~/hooks';
import ExpenseTypeFilter from './ExpenseTypeFilter';
import FiltersGroup from './FiltersGroup';
import ReportPeriodFilter from './ReportPeriodFilter';

export default function ExpenseFiltersGroup({ filters, onChange }) {
  const features = useFeatures();

  return (
    <FiltersGroup
      label="Expense Filters"
      filters={[
        filters.createdPeriod?.start,
        filters.createdPeriod?.end,
        filters.modifiedPeriod?.start,
        filters.modifiedPeriod?.end,
        filters.approvalStatuses,
        filters.clientApprovalStatuses,
        filters.invoiced,
        filters.expenseCategories,
        filters.expenseType,
        filters.billableType,
        filters.reimbursableType,
        filters.isReimbursed,
      ]}>
      {_.has(filters, 'createdPeriod') && (
        <ReportPeriodFilter
          value={filters.createdPeriod}
          placeholder="Created Date Range"
          onChange={({ target: { value } }) => onChange({ createdPeriod: value })}
        />
      )}

      {_.has(filters, 'modifiedPeriod') && (
        <ReportPeriodFilter
          value={filters.modifiedPeriod}
          placeholder="Modified Date Range"
          onChange={({ target: { value } }) => onChange({ modifiedPeriod: value })}
        />
      )}

      {_.has(filters, 'approvalStatuses') && (
        <ApprovalStatusFilter
          value={filters.approvalStatuses}
          onChange={({ target: { value } }) => onChange({ approvalStatuses: value })}
        />
      )}

      {features.clientApprovals && _.has(filters, 'clientApprovalStatuses') && (
        <ClientApprovalStatusFilter
          value={filters.clientApprovalStatuses}
          onChange={({ target: { value } }) => onChange({ clientApprovalStatuses: value })}
        />
      )}

      {_.has(filters, 'invoiced') && (
        <InvoicedFilter value={filters.invoiced} onChange={({ target: { value } }) => onChange({ invoiced: value })} />
      )}

      {_.has(filters, 'expenseCategories') && (
        <ExpenseCategoryFilter
          value={filters.expenseCategories}
          onChange={({ target: { value } }) => onChange({ expenseCategories: value })}
        />
      )}

      {_.has(filters, 'expenseType') && (
        <ExpenseTypeFilter
          value={filters.expenseType}
          onChange={({ target: { value } }) => onChange({ expenseType: value })}
        />
      )}

      {_.has(filters, 'billableType') && (
        <ExpenseBillableTypeFilter
          value={filters.billableType}
          onChange={({ target: { value } }) => onChange({ billableType: value })}
        />
      )}

      {_.has(filters, 'reimbursableType') && (
        <ExpenseReimbursableTypeFilter
          value={filters.reimbursableType}
          onChange={({ target: { value } }) => onChange({ reimbursableType: value })}
        />
      )}

      {_.has(filters, 'isReimbursed') && (
        <ReimbursedFilter
          value={filters.isReimbursed}
          onChange={({ target: { value } }) => onChange({ isReimbursed: value })}
        />
      )}
    </FiltersGroup>
  );
}
