import { rgba } from 'polished';
import React, { useEffect, useMemo, useState } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { Icon, Tooltip } from '~/components';
import { useChat, useWorkspace } from '~/contexts';
import { useAuth, useFeatures } from '~/hooks';
import { colors, weights } from '~/styles';
import AppVersion from './AppVersion';
import Maintenance from './Maintenance';

// Some properties are only used by `styled` and should not be passed through
// eslint-disable-next-line no-unused-vars
const StyledNavLink = ({ isActive, ...props }) => <NavLink {...props} />;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
`;

const AreaLink = styled(StyledNavLink)`
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.6875rem;
  color: ${({ isActive }) => (isActive ? colors.primary : colors.grey40)};
  font-size: 0.875rem;
  border-bottom: solid 1px ${colors.grey75};
  background-color: ${({ isActive }) => (isActive ? rgba(colors.white, 0.08) : 'transparent')};
  z-index: 3;

  &:hover {
    color: ${({ isActive }) => (isActive ? colors.primary : colors.white)};
  }

  > svg {
    width: 1rem;
  }
`;

const AreaBadge = styled.div`
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  width: 0.5rem;
  height: 0.5rem;
  background-color: ${colors.danger};
  border-radius: 50%;
`;

const Menu = styled.div`
  position: absolute;
  top: ${({ position }) => (position === 'bottom' ? 'auto' : '0')};
  bottom: ${({ position }) => (position === 'bottom' ? '0' : 'auto')};
  left: 100%;
  width: 12.25rem;
  padding-bottom: 0.5rem;
  color: ${colors.white};
  background-color: ${colors.primary};
  border-radius: 0 0.625rem 0.625rem 0;
  transition: transform 100ms ease-in-out;
  transform: translateX(-100%);
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0.75rem;
    background-image: linear-gradient(to right, ${rgba(colors.accent, 1)}, ${rgba(colors.accent, 0)});
  }
`;

const MenuTitle = styled.div`
  display: flex;
  align-items: center;
  height: 2.6875rem;
  margin-bottom: 0.5rem;
  padding-top: 0.125rem;
  padding-left: 2.125rem;
  padding-right: 1.125rem;
  line-height: 1;
  font-weight: ${weights.bold};
  border-bottom: solid 1px ${colors.primary50};
`;

const MenuLink = styled(NavLink)`
  display: flex;
  align-items: center;
  margin: 0 1rem;
  padding: 0.5rem 1.125rem;
  color: ${colors.white};
  font-size: 0.875rem;
  line-height: 1;
  text-transform: uppercase;
  border-radius: 999rem;

  &:hover {
    color: ${colors.white};
    font-weight: ${weights.black};
  }

  &.is-active {
    color: ${colors.primary};
    font-weight: ${weights.black};
    background-color: ${colors.white};
  }
`;

const MenuLinkBadge = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.25rem;
  height: 1.25rem;
  margin: -0.5rem;
  margin-left: auto;
  margin-right: -0.75rem;
  padding: 0 0.5rem;
  color: ${colors.white};
  font-size: 0.75rem;
  font-weight: ${weights.bold};
  background-color: ${colors.danger};
  border-radius: 999rem;
`;

const Area = styled.div`
  position: relative;

  &:hover {
    ${AreaLink} {
      color: ${({ isActive }) => (isActive ? colors.primary : colors.white)};
    }

    ${Menu} {
      transform: translateX(0);
    }
  }
`;

const NavFooter = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  z-index: 3;
`;

const HelpLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.25rem;
  color: ${colors.grey25};
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    color: ${colors.white};
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.black};
  z-index: 2;
`;

function NavArea({ name, icon, path, areaId, navAreaId, badgeCount, menuPosition, children }) {
  const match = useRouteMatch();
  const isActive = useMemo(() => areaId === navAreaId, [areaId, navAreaId]);
  return (
    <Area isActive={isActive} data-area={areaId}>
      <AreaLink to={`${match.url}${path}`} className="area-link" isActive={isActive}>
        <Icon icon={icon} />
        {badgeCount > 0 && <AreaBadge />}
      </AreaLink>
      {children && (
        <Menu position={menuPosition}>
          <MenuTitle>{name}</MenuTitle>
          {children}
        </Menu>
      )}
    </Area>
  );
}

function NavItem({ name, path, badgeCount, ...props }) {
  const match = useRouteMatch();
  return (
    <MenuLink to={`${match.url}${path}`} className="item-link" activeClassName="is-active" {...props}>
      <span>{name}</span>
      {badgeCount > 0 && <MenuLinkBadge>{badgeCount < 100 ? badgeCount : '99+'}</MenuLinkBadge>}
    </MenuLink>
  );
}

export default function CollapsedMenu({ navAreaId, timeApprovalsCount, expenseApprovalsCount }) {
  const auth = useAuth();
  const chat = useChat();
  const features = useFeatures();
  const { workspace } = useWorkspace();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const isBillingLocked = !workspace.billingStatus?.isValid;
  const isDisabled = workspace.statusId === 'disabled';

  const isTimeActive = (_, location) =>
    ['month', 'week', 'day', 'list'].some((v) => location.pathname.includes(`/time/${v}`));

  useEffect(() => {
    const handleResize = () => setWindowHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isBillingLocked || isDisabled) {
    return (
      <>
        <Container>
          <Nav>
            <NavArea name="Home" icon="home" path="" areaId="home" navAreaId={navAreaId} />
            {!isDisabled && auth.workspace.manage && (
              <NavArea
                name="Settings"
                icon="cog"
                path="/settings"
                areaId="settings"
                navAreaId={navAreaId}
                menuPosition={windowHeight > 670 ? 'top' : 'bottom'}>
                <NavItem name="Billing" path="/settings/billing" />
              </NavArea>
            )}
          </Nav>
          <NavFooter>
            <AppVersion />
            <Tooltip Container={HelpLink} message="Need help?" onClick={() => chat.open()}>
              <Icon type="far" icon="question-circle" />
            </Tooltip>
          </NavFooter>
        </Container>
        <Background />
      </>
    );
  }

  return (
    <>
      <Container>
        <Nav>
          <NavArea name="Home" icon="home" path="" areaId="home" navAreaId={navAreaId}>
            <NavItem name="Dashboard" path="/dashboard" />
            <NavItem name="My Projects" path="/my-projects" />
            <NavItem name="My Tasks" path="/my-tasks" />
            {features.allocations && <NavItem name="My Allocations" path="/my-allocations" />}
          </NavArea>
          {features.pipeline && auth.pipeline.view && (
            <NavArea
              name="Pipeline"
              icon="filter-circle-dollar"
              path="/pipeline/opportunities"
              areaId="pipeline"
              navAreaId={navAreaId}>
              <NavItem name="Opportunities" path="/pipeline/opportunities" />
              <NavItem name="Companies" path="/pipeline/companies" />
              <NavItem name="Contacts" path="/pipeline/contacts" />
            </NavArea>
          )}
          <NavArea name="Projects" icon="tasks" path="/projects" areaId="projects" navAreaId={navAreaId}>
            <NavItem name="Projects" path="/projects" />
            <NavItem name="Clients" path="/clients" />
          </NavArea>
          <NavArea
            name="Time"
            icon="clock"
            path="/time"
            areaId="time"
            navAreaId={navAreaId}
            badgeCount={timeApprovalsCount}>
            <NavItem name="Time Entries" path="/time" isActive={isTimeActive} />
            {auth.time.approve && <NavItem name="Approvals" path="/time/approvals" badgeCount={timeApprovalsCount} />}
            <NavItem name="Auditing" path="/time/auditing" />
            {features.timesheets && <NavItem name="Timesheets" path="/time/timesheets" />}
          </NavArea>
          {features.expenseReporting && (
            <NavArea
              name="Expenses"
              icon="receipt"
              path="/expenses"
              areaId="expenses"
              navAreaId={navAreaId}
              badgeCount={expenseApprovalsCount}>
              <NavItem name="Expense Reports" path="/expenses/reports" />
              {auth.expenses.approve && (
                <NavItem name="Approvals" path="/expenses/approvals" badgeCount={expenseApprovalsCount} />
              )}
              <NavItem name="Auditing" path="/expenses/auditing" />
            </NavArea>
          )}
          {auth.invoices.view.project && (
            <NavArea name="Billing" icon="file-invoice" path="/billing" areaId="billing" navAreaId={navAreaId}>
              {auth.invoices.manage.project && <NavItem name="Ready to Bill" path="/billing/ready-to-bill" />}
              <NavItem name="Invoices" path="/billing/invoices" />
              {auth.payments.view && <NavItem name="Payments" path="/billing/payments" />}
              {auth.creditNotes.view && <NavItem name="Credit Notes" path="/billing/credit-notes" />}
            </NavArea>
          )}
          {features.allocations && auth.allocations.view && (
            <NavArea name="Resources" icon="users" path="/resources" areaId="resources" navAreaId={navAreaId}>
              <NavItem name="Allocations" path="/resources/allocations" />
            </NavArea>
          )}
          <NavArea
            name="Reports"
            icon="chart-line"
            path="/reports"
            areaId="reports"
            navAreaId={navAreaId}
            menuPosition={windowHeight > 510 ? 'top' : 'bottom'}>
            <NavItem name="Library" path="/reports/library" />
            <NavItem name="Saved" path="/reports/saved" />
          </NavArea>
          {auth.workspace.manage && (
            <NavArea
              name="Settings"
              icon="cog"
              path="/settings"
              areaId="settings"
              navAreaId={navAreaId}
              menuPosition={windowHeight > 670 ? 'top' : 'bottom'}>
              <NavItem name="Workspace" path="/settings" exact />
              <NavItem name="Invoices" path="/settings/invoices" />
              <NavItem name="Members" path="/settings/members" />
              <NavItem name="Security Roles" path="/settings/security-roles" />
              <NavItem name="Custom Data" path="/settings/custom-data" />
              <NavItem name="Features" path="/settings/features" />
              <NavItem name="Integrations" path="/settings/integrations" />
              <NavItem name="API Keys" path="/settings/api-keys" />
              <NavItem name="Authentication" path="/settings/authentication" />
              <NavItem name="Billing" path="/settings/billing" />
            </NavArea>
          )}
        </Nav>
        <NavFooter>
          <AppVersion />
          <Maintenance />
          <Tooltip Container={HelpLink} message="Need help?" onClick={() => chat.open()}>
            <Icon type="far" icon="question-circle" />
          </Tooltip>
        </NavFooter>
      </Container>
      <Background />
    </>
  );
}
