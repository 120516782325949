import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  BillableIcon,
  Currency,
  DateTime,
  Icon,
  Percent,
  Tooltip,
  Widget,
  MarkupIcon,
  ExportDropdown,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useFeatures, useIsMounted, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useMemberFilters from '~/hooks/useMemberFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import clientApprovalStatuses from '~/lookups/client-approval-statuses';
import expenseLockedReasons from '~/routes/app/expenses/expenseLockedReason';
import ExpenseItemDrawer from '~/routes/app/expenses/item/ExpenseItemDrawer';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersBar from '~/components/filters/ClientFiltersBar';
import ClientFiltersGroup from '~/components/filters/ClientFiltersGroup';
import CurrencyFilter from '~/components/filters/CurrencyFilter';
import ExpenseFiltersBar from '~/components/filters/ExpenseFiltersBar';
import ExpenseFiltersGroup from '~/components/filters/ExpenseFiltersGroup';
import ExportDialogAsync from '../components/ExportDialogAsync';
import FilterButton from '~/components/filters/FilterButton';
import MemberFiltersBar from '~/components/filters/MemberFiltersBar';
import MemberFiltersGroup from '~/components/filters/MemberFiltersGroup';
import Meter from '../components/Meter';
import { Metric, Metrics } from '../components/Metric';
import ProjectFiltersBar from '~/components/filters/ProjectFiltersBar';
import ProjectFiltersGroup from '~/components/filters/ProjectFiltersGroup';
import Report from '../components/Report';
import ReportPeriodFilter from '~/components/filters/ReportPeriodFilter';
import Table from '../components/table';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import SavedReportActions from '../SavedReportActions';

const Byline = styled.small`
  display: block;
`;

const IconContainer = styled.div`
  display: flex;
  direction: row;
  gap: 1rem;
`;

function ExpenseDetail({ report }) {
  const documentTitle = useDocumentTitle(report.name);
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const isMounted = useIsMounted();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();
  const memberFilters = useMemberFilters();

  const [query, setQuery] = useState({
    report: null,
    params: {
      period: null,
      createdPeriod: null,
      modifiedPeriod: null,
      approvalStatuses: [],
      clientApprovalStatuses: [],
      expenseType: null,
      billableType: null,
      reimbursableType: null,
      isReimbursed: null,
      expenseCategories: [],
      invoiced: null,
      currency: workspace.currency,
      sort: new QuerySort('date', 'asc'),
      page: 0,
      size: 50,
      ...clientFilters.filters,
      ...projectFilters.filters,
      ...memberFilters.filters,
    },
    action: 'load',
    status: 'loading',
    loading: {
      summary: false,
      table: false,
    },
  });

  const params = query.params;

  const setParams = (params) => {
    setQuery((state) => ({
      ...state,
      params: { ...state.params, ...params },
    }));
  };

  const loadMore = () => {
    setQuery((state) => {
      if (
        state.status !== 'ready' ||
        state.action !== null ||
        !state.report ||
        state.report.records.total <= state.report.records.results.length
      )
        return state;

      return {
        ...state,
        params: { ...state.params, page: state.params.page + 1 },
        action: 'load-more',
      };
    });
  };

  // Init and sync URL search params
  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: reportsSearchParamsConfig.period,
        createdPeriod: reportsSearchParamsConfig.createdPeriod,
        modifiedPeriod: reportsSearchParamsConfig.modifiedPeriod,
        approvalStatuses: searchParamsConfig.approvalStatuses,
        clientApprovalStatuses: searchParamsConfig.clientApprovalStatuses,
        expenseType: searchParamsConfig.expenseType,
        billableType: searchParamsConfig.expenseBillableType,
        reimbursableType: searchParamsConfig.expenseReimbursableType,
        isReimbursed: searchParamsConfig.isReimbursed,
        expenseCategories: searchParamsConfig.expenseCategories,
        invoiced: searchParamsConfig.invoiced,
        currency: searchParamsConfig.currency,
        sort: { default: new QuerySort('date', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
        ...memberFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters, memberFilters],
    ),

    onChange: (params) => setParams(params),
  });

  const urlSearchParams = useMemo(() => {
    return {
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      createdStart: params.createdPeriod?.start ?? undefined,
      createdEnd: params.createdPeriod?.end ?? undefined,
      modifiedStart: params.modifiedPeriod?.start ?? undefined,
      modifiedEnd: params.modifiedPeriod?.end ?? undefined,
      statusId: params.approvalStatuses?.map((v) => v.id),
      clientApprovalStatusId: features.clientApprovals ? params.clientApprovalStatuses?.map((v) => v.id) : undefined,
      expenseType: params.expenseType ?? undefined,
      billableTypeId: params.billableType ?? undefined,
      reimbursableTypeId: params.reimbursableType ?? undefined,
      isReimbursed: params.isReimbursed ?? undefined,
      expenseCategoryId: params.expenseCategories?.map((v) => v.id),
      invoiced: params.invoiced ?? undefined,
      currency: params.currency ?? undefined,
      sort: params.sort,
      page: params.page,
      size: params.size,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
      ...memberFilters.mapUrlSearchParams(params),
    };
  }, [params, features.clientApprovals, clientFilters, projectFilters, memberFilters]);

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams(params);
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().expenseDetail(urlSearchParams);

    if (!isMounted.current) return;

    setQuery((state) => ({
      ...state,
      action: null,
      status: 'ready',
      loading: { summary: false, table: false },
      report: {
        ...data,
        records: {
          ...data.records,
          results:
            state.action === 'load-more'
              ? [...state.report.records.results, ...data.records.results]
              : data.records.results,
        },
      },
    }));
  }, [api, workspace.id, urlSearchParams, isMounted]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values, page: 0 });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, page: 0, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, summary: true, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };

  const route = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const handleRowClick = (item) => {
    history.push({ pathname: `${route.url}/${item.id}`, search: location.search, state: { scrollToTop: false } });
  };

  const handleCloseDrawer = () => {
    history.push({ pathname: route.url, search: location.search, state: { scrollToTop: false } });
    documentTitle.set('Expense Items');
  };

  const handleRowSaved = async (expenseItem) => {
    const { data } = await api.www
      .workspaces(workspace.id)
      .reports()
      .expenseDetail({
        ...urlSearchParams,
        expenseItemId: expenseItem.id,
      });

    if (!isMounted.current) return;

    const item = data.records.find((record) => record.id === expenseItem.id);

    setQuery((state) => ({
      ...state,
      report: {
        ...data,
        records: {
          total: item ? state.report.records.total : state.report.records.total - 1,
          results: item
            ? state.report.records.results.map((result) => (result.id === expenseItem.id ? item : result))
            : state.report.records.results.filter((result) => result.id !== expenseItem.id),
        },
      },
    }));
  };

  const handleRowDeleted = async () => {
    const { data } = await api.www
      .workspaces(workspace.id)
      .reports()
      .expenseDetail({
        ...urlSearchParams,
        page: 0,
        // Refetch every loaded page
        size: (query.params.page + 1) * query.params.size,
      });

    if (!isMounted.current) return;

    setQuery((state) => ({
      ...state,
      report: data,
    }));

    handleCloseDrawer();
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .expenseDetail(
            { ...urlSearchParams, page: undefined, size: null },
            {
              headers: { accept: mimeType },
            },
          )}
        onClose={resolve}
      />
    ));
  };

  return (
    <>
      <Report>
        <Report.Header>
          <Report.Info report={report} />

          <Report.Actions>
            <SavedReportActions report={report} onSave={() => searchParams.set(params)} />
            <ExportDropdown data-testid="download-button">
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(mimeTypes.pdf);
                      setIsOpen(false);
                    }}>
                    Export to PDF
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>

            <FilterButton isOutline onClick={showFilters} />
          </Report.Actions>
        </Report.Header>

        <Report.FiltersBar>
          <ReportPeriodFilter value={params.period} onChange={({ value }) => handleApplyFilters({ period: value })} />

          {features.multicurrency && (
            <CurrencyFilter
              value={query.params.currency}
              onChange={({ target: { value } }) => handleCurrencyChange(value)}
            />
          )}

          <ClientFiltersBar filters={params} onChange={handleApplyFilters} />

          <ProjectFiltersBar filters={params} onChange={handleApplyFilters} />

          <MemberFiltersBar filters={params} onChange={handleApplyFilters} />

          <ExpenseFiltersBar filters={params} onChange={handleApplyFilters} />
        </Report.FiltersBar>

        {(() => {
          switch (query.status) {
            case 'loading':
            case 'filtering':
              return <PageLoader />;

            default:
              return (
                <>
                  <Data query={query} onLoadMore={loadMore} onSort={handleSort} onRowClick={handleRowClick} />
                  <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
                </>
              );
          }
        })()}
      </Report>

      <Switch>
        <Route path={route.path.concat('/:expenseItemId')}>
          <ExpenseItemDrawer onSaved={handleRowSaved} onDeleted={handleRowDeleted} onClose={handleCloseDrawer} />
        </Route>
      </Switch>
    </>
  );
}

function Data({ query, onLoadMore, onSort, onRowClick }) {
  const features = useFeatures();
  const { report, params } = query;

  return (
    <>
      <Report.Summary fade={query.loading.summary}>
        <Widget>
          <Meter>
            <Meter.Value
              label="Billable"
              value={report.totals.billable}
              color={colors.success}
              tooltip={
                <>
                  <Currency value={report.totals.billable} currency={report.currency} /> (
                  <Percent value={report.totals.billable / report.totals.cost} />)
                </>
              }
            />
            <Meter.Value
              label="Non-Billable"
              value={report.totals.nonBillable}
              color={colors.danger}
              tooltip={
                <>
                  <Currency value={report.totals.nonBillable} currency={report.currency} /> (
                  <Percent value={report.totals.nonBillable / report.totals.cost} />)
                </>
              }
            />
          </Meter>

          <Metrics>
            <Metric>
              <Metric.Value>
                <Currency value={report.totals.billable} currency={report.currency} />
              </Metric.Value>
              <Metric.Label>Billable</Metric.Label>
            </Metric>
            <Metric>
              <Metric.Value>
                <Currency value={report.totals.nonBillable} currency={report.currency} />
              </Metric.Value>
              <Metric.Label>Non-Billable</Metric.Label>
            </Metric>
            <Metric>
              <Metric.Value>
                <Currency value={report.totals.cost} currency={report.currency} />
              </Metric.Value>
              <Metric.Label>Total</Metric.Label>
            </Metric>
          </Metrics>
        </Widget>
      </Report.Summary>

      <Report.Table>
        <Table>
          <Table.Header sticky>
            <Table.Column name="date" width="7.5rem" onSort={onSort} sort={params.sort}>
              Date
            </Table.Column>
            <Table.Column name="member_name" minWidth="12rem" onSort={onSort} sort={params.sort}>
              Member
            </Table.Column>
            <Table.Column name="project_name" minWidth="16rem" onSort={onSort} sort={params.sort}>
              Project/Client
            </Table.Column>
            <Table.Column minWidth="16rem">Category/Vendor</Table.Column>
            <Table.Column
              name="clientApprovalStatusId"
              width="12rem"
              isVisible={features.clientApprovals}
              onSort={onSort}
              sort={params.sort}>
              Client Approval Status
            </Table.Column>
            <Table.Column name="statusId" width="9rem" onSort={onSort} sort={params.sort}>
              Approval Status
            </Table.Column>
            <Table.Column width="5rem" />
            <Table.Column name="converted_amount" width="10rem" align="right" onSort={onSort} sort={params.sort}>
              Amount
            </Table.Column>
            <Table.Column width="3.5rem" />
          </Table.Header>

          <Table.Body fade={query.loading.table}>
            {report.records.results.map((item) => {
              switch (item.expenseType) {
                case 'member': {
                  const handleRowClick = () => onRowClick(item);

                  return (
                    <Table.Row key={item.id} onClick={handleRowClick}>
                      <Table.Cell>
                        <DateTime value={item.date} />
                      </Table.Cell>
                      <Table.Cell>{item.member.name}</Table.Cell>
                      <Table.Cell>
                        {item.project && (
                          <p>
                            {item.project.name}
                            <Byline>{item.project.client.name}</Byline>
                          </p>
                        )}
                      </Table.Cell>
                      <Table.Cell flex="2">
                        <p>
                          {item.expenseCategory?.name}
                          {item.vendorName && <Byline>{item.vendorName}</Byline>}
                        </p>
                      </Table.Cell>

                      <Table.Cell>{clientApprovalStatuses[item.clientApprovalStatusId]?.name}</Table.Cell>

                      <Table.Cell>{item.status.name}</Table.Cell>

                      <Table.Cell>
                        <IconsWidget item={item} />
                      </Table.Cell>

                      <Table.Cell>
                        <p>
                          <Currency value={item.convertedAmount} currency={report.currency} />
                          {item.currency !== report.currency && (
                            <Byline>
                              <Currency value={item.expenseAmount} currency={item.currency} />
                            </Byline>
                          )}
                        </p>
                      </Table.Cell>

                      <Table.Cell>
                        {item.lockStatusId !== 'unlocked' && (
                          <Tooltip message={expenseLockedReasons[item.lockStatusId]}>
                            <Icon color={colors.grey40} icon="lock" size="1x" />
                          </Tooltip>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                }

                case 'project':
                  return (
                    <Table.Row key={item.id}>
                      <Table.Cell>
                        <DateTime value={item.date} />
                      </Table.Cell>
                      <Table.Cell />
                      <Table.Cell>
                        <p>
                          {item.project.name}
                          <Byline>{item.project.client.name}</Byline>
                        </p>
                      </Table.Cell>
                      <Table.Cell flex="2">
                        <p>
                          {item.expenseCategory?.name}
                          {item.vendorName && <Byline>{item.vendorName}</Byline>}
                        </p>
                      </Table.Cell>

                      <Table.Cell />

                      <Table.Cell />

                      <Table.Cell>
                        <IconsWidget item={item} />
                      </Table.Cell>

                      <Table.Cell>
                        <p>
                          <Currency value={item.convertedAmount} currency={report.currency} />
                          {item.currency !== report.currency && (
                            <Byline>
                              <Currency value={item.expenseAmount} currency={item.currency} />
                            </Byline>
                          )}
                        </p>
                      </Table.Cell>

                      <Table.Cell />
                    </Table.Row>
                  );
              }
            })}

            <Table.Row style={{ fontWeight: weights.bold }}>
              <Table.Cell>Total</Table.Cell>
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
              <Table.Cell>
                <Currency value={report.totals.cost} currency={report.currency} />
              </Table.Cell>
              <Table.Cell />
            </Table.Row>

            {report.records.total > report.records.results.length && <Table.Loader onIntersecting={onLoadMore} />}
          </Table.Body>

          <Table.Status
            total={report.records.total}
            label="Expense"
            isLoading={query.status !== 'ready'}
            partial={report.partialResults}
          />
        </Table>
      </Report.Table>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const auth = useAuth();
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ReportPeriodFilter
        value={filters.period}
        onChange={({ target: { value } }) => handleFilter({ period: value })}
      />

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      {auth.expenses.view && <MemberFiltersGroup filters={filters} onChange={handleFilter} />}

      <ExpenseFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}

const ItemFinances = styled.div`
  padding: 0.25rem;
  display: flex;
  justify-content: flex-end;
  background-color: ${colors.grey5};
  border-radius: 1.5rem 1.5rem 1.5rem 1.5rem;
`;

const ItemBillable = styled.div`
  color: ${({ isBillable }) => (isBillable ? colors.green : colors.grey55)};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0rem 0 0.5rem;
`;

const ItemReimbursable = styled.div`
  color: ${({ color }) => color};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem 0 0rem;
`;

function IconsWidget({ item }) {
  const { markup, total, isBillable, isReimbursed, isReimbursable } = item;

  const currency = item.project?.currency;

  return (
    <ItemFinances>
      <ItemBillable>
        {markup > 0 && isBillable ? (
          <MarkupIcon total={total} currency={currency} />
        ) : (
          <BillableIcon value={isBillable} />
        )}
      </ItemBillable>

      <ItemReimbursable color={isReimbursed ? colors.green : isReimbursable ? colors.black : colors.grey25}>
        <Tooltip
          Container={IconContainer}
          message={
            isReimbursed
              ? 'Expense Item is Reimbursed.'
              : isReimbursable
                ? 'Expense Item is Reimbursable.'
                : 'Expense Item is not Reimbursable.'
          }>
          <Icon icon="repeat" />
        </Tooltip>
      </ItemReimbursable>
    </ItemFinances>
  );
}

export default ExpenseDetail;
