import { Field, HelpTooltip } from '~/components';
import React from 'react';
import styled from 'styled-components';

const Control = styled.div`
  display: flex;
  align-items: center;
`;

export default function CapExpensesRevenueCheckbox(props) {
  return (
    <Control>
      <Field.Checkbox name="capExpensesRevenue" label="Cap the billable expenses revenue" {...props} />

      <HelpTooltip
        message="Do not allow an expense item to be saved if it will cause the project to exceed its total expenses revenue budget."
        style={{ marginLeft: '.5rem' }}
      />
    </Control>
  );
}
