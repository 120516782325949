import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { FiltersBar, InvoiceStatusFilter, Page, SearchInput, SingleSelectFilter } from '~/components';
import { useWorkspace } from '~/contexts';
import intervals from '../invoices/invoices-list/intervals';
import InvoicesListPage from '../invoices/invoices-list/InvoicesListPage';

function Filters({ query, handleFilter }) {
  return (
    <>
      <Page.TabHeader>
        <Page.Title>Invoices</Page.Title>
      </Page.TabHeader>

      <Page.Filters>
        <FiltersBar>
          <SearchInput
            value={query.q}
            placeholder="Search"
            onChange={({ target: { value } }) => handleFilter({ q: value })}
          />

          <InvoiceStatusFilter
            value={query.invoiceStatuses}
            onChange={({ target: { value } }) => handleFilter({ invoiceStatuses: value })}
          />

          <SingleSelectFilter
            icon="calendar-alt"
            placeholder="Issue Date"
            options={_.map(intervals, ({ label }, key) => ({ id: key, name: label }))}
            value={query.period}
            onChange={({ target: { value } }) => handleFilter({ period: value })}
          />
        </FiltersBar>
      </Page.Filters>
    </>
  );
}

function ProjectInvoicesTab({ project }) {
  const { workspace } = useWorkspace();
  const history = useHistory();

  return (
    <InvoicesListPage
      mode="tab"
      showProjectColumn={false}
      projectId={project.id}
      renderFilters={Filters}
      onRowClick={(invoice) => history.push(`/app/${workspace.key}/billing/invoices/${invoice.id}`)}
    />
  );
}

export default ProjectInvoicesTab;
