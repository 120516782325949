import { useApi, useWorkspace } from '~/contexts';
import { useField, useIsMounted } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import AutoComplete from './AutoComplete';
import FieldControl from './FieldControl';

function BusinessUnitSelect({ value, initialValue, onSearch, ...props }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);

  const handleSearch = useCallback(
    async (q) => {
      if (onSearch) {
        return onSearch(q);
      }

      let { data } = await api.www
        .workspaces(workspace.id)
        .businessUnits()
        .get({ q, isActive: true, include: initialValue ? initialValue.id : undefined });

      return data;
    },
    [workspace.id, initialValue, api, onSearch],
  );

  useEffect(() => {
    (async () => {
      const data = await handleSearch('');
      if (!isMounted.current) return;
      setDefaultOptions(data);
    })();
  }, [handleSearch, isMounted]);

  return (
    <AutoComplete
      onSearch={handleSearch}
      value={value}
      displayText={value?.name ?? ''}
      defaultOptions={defaultOptions}
      renderValue={(value) => value.name}
      renderOption={(option) => option.name}
      {...props}
    />
  );
}

function FieldBusinessUnitSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <BusinessUnitSelect {...field} {...props} />
    </FieldControl>
  );
}

export default BusinessUnitSelect;
export { FieldBusinessUnitSelect };
