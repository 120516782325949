import isFalsy from '../helpers/is-falsy';
import isTruthy from '../helpers/is-truthy';
import { parseDate } from '../helpers/parse-date';

const projectLedgers = {
  'Import ID': {
    requirementType: 'required',
    description:
      'Unique identifier for the imported record. Imported row will be checked for duplicate. If a duplicate is found, the row will be skipped from import.',
    dataType: 'string',
    validator: (value) => {
      return value !== undefined && value !== null && value.trim() !== '';
    },
  },
  'Client Name': {
    requirementType: 'required',
    description:
      'The Name of the client associated with this ledger entry. This field must match the Name of a Client exactly, including spaces, punctuation and capitalization, otherwise the ledger entry may be skipped during import.',
    dataType: 'string',
    validator: (value) => value.trim() !== '',
  },
  'Project Name': {
    requirementType: 'required',
    description:
      'The name of the project associated with the ledger entry. This field must match the name of a project exactly, including spaces, punctuation and capitalization, otherwise the allocation may be skipped during import.',
    dataType: 'string',
    validator: (value) => value.trim() !== '',
  },
  Ledger: {
    requirementType: 'required',
    description: `Indicate the project ledger on which the entry should be created: ‘other’ for the Other Items to Bill ledger, ‘expense’ for the project Expenses ledger, 'milestone' for the Fixed Fee Billing Schedule, and ‘revenue” for the Revenue Recognition ledger.`,
    dataType: 'string',
    validator: (value) => ['milestone', 'other', 'expense', 'revenue'].includes(value.trim().toLowerCase()),
  },
  Notes: {
    requirementType: 'conditional',
    description: 'Notes is required for "other" and "milestone" ledger items.',
    dataType: 'string',
    validator: (value, row) => {
      const r = (row['Ledger'] === 'other' || row['Ledger'] === 'milestone') && !value?.trim() ? false : true;
      return r;
    },
  },
  Date: {
    requirementType: 'required',
    description: 'Date of item being imported in "YYYY-MM-DD" or "MM/DD/YYYY" format.',
    dataType: 'date',
    validator: (value) => {
      return !!parseDate(value);
    },
  },
  Amount: {
    requirementType: 'required',
    description:
      'Amount of the ledger item; project currency will be assumed. Quantity and rate, if provided, will be use to check Amount is valid.',
    dataType: 'number',
    validator: (value, row) => {
      const isValidAmount = value && !isNaN(parseFloat(value)) && isFinite(value) && Number(value) >= 0;
      const isValidQuantity =
        row['Quantity'] !== undefined &&
        !isNaN(parseFloat(row['Quantity'])) &&
        isFinite(row['Quantity']) &&
        Number(row['Quantity']) >= 0;
      const isValidRate =
        row['Rate'] !== undefined &&
        !isNaN(parseFloat(row['Rate'])) &&
        isFinite(row['Rate']) &&
        Number(row['Rate']) >= 0;

      const matchesQuantityAndRate =
        isValidQuantity && isValidRate ? Number(value) === Number(row['Quantity']) * Number(row['Rate']) : true;

      return isValidAmount && matchesQuantityAndRate;
    },
  },
  Quantity: {
    requirementType: 'conditional',
    description: 'Quantity of the ledger item; this will only be used for "milestone" and "other" items to bill.',
    dataType: 'number',
    validator: (value, row) => {
      const r =
        row['Ledger'] !== 'other' ||
        (row['Ledger'] === 'other' &&
          value !== undefined &&
          !isNaN(parseFloat(value)) &&
          isFinite(value) &&
          Number(value) >= 0);
      return r;
    },
  },
  Rate: {
    requirementType: 'conditional',
    description: 'Rate of the ledger item; this will only be used for "milestone" and "other" items to bill.',
    dataType: 'number',
    validator: (value, row) => {
      const r =
        row['Ledger'] !== 'other' ||
        (row['Ledger'] === 'other' &&
          value !== undefined &&
          !isNaN(parseFloat(value)) &&
          isFinite(value) &&
          Number(value) >= 0);
      return r;
    },
  },
  'Invoice Item': {
    requirementType: 'conditional',
    description:
      'Invoice Item of the ledger item; required if ledger is "milestone" or "other" item to bill. Specify the Invoice Item that you wish to be associated with the item being created on the ledger. If not found the default will be automatically assigned.',
    dataType: 'string',
    helpUrl:
      'https://help.ruddr.io/hc/en-us/articles/13007144801559-How-do-default-Invoice-Items-get-applied-in-invoices',
    validator: (value, row) => {
      const r =
        (row['Ledger'] !== 'other' && row['Ledger'] !== 'milestone') ||
        ((row['Ledger'] === 'other' || row['Ledger'] === 'milestone') && value.trim() !== '');
      return r;
    },
  },
  'Expense Category': {
    requirementType: 'conditional',
    description: 'Expense Category of the ledger item, required if ledger is "expense".',
    dataType: 'string',
    helpUrl: 'https://help.ruddr.io/hc/en-us/articles/360055882494-Manage-Expense-Categories',
    validator: (value, row) => {
      const r = row['Ledger'] != 'expense' || (row['Ledger'] === 'expense' && value.trim() !== '');
      return r;
    },
  },
  Billable: {
    requirementType: 'optional',
    description: '"Yes" if you consider this item billable to the client; only used if ledger is "expense".',
    dataType: 'boolean',
    validator: (value, row) => {
      return (
        row['Ledger'] !== 'expense' ||
        (row['Ledger'] === 'expense' && (value === undefined || isTruthy(value) || isFalsy(value)))
      );
    },
  },
  Vendor: {
    requirementType: 'optional',
    description: 'Vendor related to the ledger item, only is used if ledger is "expense".',
    dataType: 'string',
    validator: () => true,
  },
};

export default projectLedgers;
