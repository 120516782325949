import React, { useEffect, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';
import { ExportDropdown, FiltersBar, IconButton, Page, SearchInput } from '~/components';
import {
  ClientFiltersBar,
  ClientFiltersGroup,
  FilterButton,
  FiltersDrawer,
  ProjectFiltersBar,
  ProjectFiltersGroup,
} from '~/components/filters';
import { useAuth } from '~/hooks';
import { mimeTypes } from '~/utils';

export default function ProjectsListFilters({ onChange, onExport, params }) {
  const auth = useAuth();
  const { url } = useRouteMatch();
  const location = useLocation();

  const handleChange = ({ target: { name, value } }) => {
    onChange({ [name]: value });
  };

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);

  const history = useHistory();

  return (
    <>
      <Page.Header>
        <Page.Title>Projects</Page.Title>

        <Page.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await onExport(`projects.csv`, mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await onExport(`projects.xlsx`, mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />

          <IconButton
            icon="plus"
            tooltip={auth.projects.create ? 'New Project' : 'Your security role prohibits you from creating projects.'}
            onClick={() => history.push(`${url}/new${location.search}`)}
            disabled={!auth.projects.create}
          />
        </Page.Actions>
      </Page.Header>

      <Page.Filters>
        <FiltersBar>
          <SearchInput value={params.q} placeholder="Search" onChange={handleChange} />

          <ClientFiltersBar filters={params} sticky={['clients']} onChange={onChange} />

          <ProjectFiltersBar
            filters={params}
            sticky={[
              'projectBillingTypes',
              'projectStatuses',
              'projectRecordStatusId',
              'projectPractices',
              'projectTags',
              'projectAdmins',
            ]}
            onChange={onChange}
          />
        </FiltersBar>
      </Page.Filters>

      <Filters values={params} isOpen={filtersVisible} onApply={onChange} onClose={hideFilters} />
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
    onClose();
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />
    </FiltersDrawer>
  );
}
