import { Button, Buttons, CancelButton, Field, Form, FormMessage, HelpTooltip, ModalCard, Radio } from '~/components';
import { Card } from '~/components/ModalCard';
import { useApi, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useFeatures, useForm } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';
import { ContainerControl } from '../components/PageComponents';
import ClientApprovalScheduleMemberMultiSelect from './ClientApprovalScheduleMemberMultiSelect';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ContainerLabel = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const Modal = styled(ModalCard)`
  ${Card} {
    width: 62rem;
  }
`;

const ModalCardBody = styled(ModalCard.Body)`
  overflow-y: scroll;
  max-height: 75vh;
`;

export default function ClientApprovalScheduleForm({ project, clientApprovalScheduleId, onSaved, onClose }) {
  const api = useApi();
  const { workspace } = useWorkspace();

  const [query, setQuery] = useState({ isReady: false, data: null, error: null });

  const fetchData = useCallback(async () => {
    if (!clientApprovalScheduleId) {
      setQuery({ isReady: true, data: {} });
    }

    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .projects(project.id)
        .clientApprovalSchedules(clientApprovalScheduleId)
        .get();

      setQuery({ isReady: true, data });
    } catch (error) {
      setQuery({ isReady: true, data: null, error });
    }
  }, [workspace.id, api, project.id, clientApprovalScheduleId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const features = useFeatures();

  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const [{ status, message, isSubmitting }, form] = useForm();

  if (!query.isReady) return null;

  const clientApprovalSchedule = query.data;

  const initialValues = mergeValues(
    {
      id: null,
      interval: 'monthly',
      createAfterDays: 1,
      includeTime: true,
      requireTimeApproval: true,
      requireTimesheetSubmission: features.timesheets,
      includeExpenses: true,
      requireExpenseApproval: true,
      includeReceipts: true,
      members: [],
      timeZoneId: null,

      // Email settings
      email: '',
      emails: [],
      ccEmail: '',
      ccEmails: [],
      replyTo: workspace.member.email,
      sendBcc: true,
      emailFromName: workspace.member.name,
      emailSubject: `${project.name} - Approval Request`,
      emailBody: `Hi,\n\nWe have prepared the following items for your approval. To review these items now, click the Review Items button below.\n\nThank you for your business!\n\nRegards,\n${workspace.companyName}`,

      // Reminder Settings
      sendReminder: false,
      sendReminderAfterDays: 3,
      autoApprove: false,
      autoApproveAfterDays: 5,
      autoSendEmail: false,
    },
    {
      ...clientApprovalSchedule,
      email: clientApprovalSchedule.emails ? clientApprovalSchedule.emails.join(', ') : '',
      ccEmail: clientApprovalSchedule.ccEmails ? clientApprovalSchedule.ccEmails.join(', ') : '',
    },
  );

  const schema = Yup.object().shape({
    emails: Yup.array()
      .of(Yup.string().email().max(255).required())
      .label('To')
      .min(1, 'To field must have at least one email')
      .required(),
    ccEmails: Yup.array().of(Yup.string().email().max(255)).label('Cc'),
    replyTo: Yup.string().label('Reply To').email().max(255).required(),
    sendBcc: Yup.boolean().label('Bcc Me').required(),
    emailFromName: Yup.string().label('From Name').max(255),
    emailSubject: Yup.string().label('Subject').max(255).required(),
    emailBody: Yup.string().label('Body').max(5000).required(),
  });

  const handleClose = () => dirtyCheck(onClose);

  const handleSubmit = async (values) => {
    try {
      form.submit();

      const body = emptyStringToNull({
        ..._.omit(values, 'id', 'email', 'members', 'ccEmail'),
        members: values.members.map((m) => ({ projectMemberId: m.projectMemberId })),
      });

      const { data } = await api.www
        .workspaces(workspace.id)
        .projects(project.id)
        .clientApprovalSchedules(values.id)
        .upsert(body);

      if (onSaved) await onSaved(data);
      onClose();
    } catch (error) {
      form.error(error);
    }
  };

  return (
    <Modal
      title={initialValues.id ? 'Edit Client Approval Schedule' : 'Add Client Approval Schedule'}
      onClose={handleClose}>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={handleSubmit}>
        {(formik) => {
          const handleEmailChange = (event) => {
            const value = event.target.value;
            formik.setFieldValue('email', value);

            const values = value.split(/[\s,;]+/).filter(Boolean);
            formik.setFieldValue('emails', values);
          };

          const handleCcEmailChange = (event) => {
            const value = event.target.value;
            formik.setFieldValue('ccEmail', value);

            const values = value.split(/[\s,;]+/).filter(Boolean);
            formik.setFieldValue('ccEmails', values);
          };

          const processEmailErrors = (errors) => {
            if (!errors || !errors.map) {
              return errors;
            }
            return errors.map((error) => {
              if (typeof error !== 'string') {
                return error;
              }
              return error.replace(/emails\[(\d+)\]/, (match, p1) => `To email #${parseInt(p1) + 1}`);
            });
          };

          const processCcEmailErrors = (errors) => {
            if (!errors || !errors.map) {
              return errors;
            }
            return errors.map((error) => {
              if (typeof error !== 'string') {
                return error;
              }
              return error.replace(/ccEmails\[(\d+)\]/, (match, p1) => `Cc email #${parseInt(p1) + 1}`);
            });
          };

          return (
            <Form>
              <ModalCardBody>
                <Form.Section title="Schedule">
                  <Container>
                    <ContainerLabel>Automatically create client approval:</ContainerLabel>
                    <Field.RadioGroup name="interval" direction="vertical">
                      <Radio value="monthly" label="Monthly" />
                      <Radio value="semi_monthly" label="Semi-Monthly" />
                      <Radio value="weekly" label="Weekly" />
                    </Field.RadioGroup>
                  </Container>

                  <div style={{ marginTop: '1rem' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      Create client approval at 4 PM on the
                      <div style={{ width: '5.5rem', margin: '0 0.5rem' }}>
                        <Field.SingleSelect name="createAfterDays">
                          <option value={1}>1st</option>
                          <option value={2}>2nd</option>
                          <option value={3}>3rd</option>
                          <option value={4}>4th</option>
                          <option value={5}>5th</option>
                          <option value={6}>6th</option>
                        </Field.SingleSelect>
                      </div>
                      day after the close of the{' '}
                      {{ weekly: 'week', monthly: 'month', semi_monthly: 'semi-month' }[formik.values.interval]}.
                    </div>
                  </div>

                  <Form.Control>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ flex: 1 }}>
                        <Field.TimeZoneSelect name="timeZoneId" placeholder="Time Zone" />
                      </div>
                      <div style={{ marginLeft: '0.5rem' }}>
                        <HelpTooltip
                          message={
                            <>
                              The client approval will be created at <nobr>4 PM</nobr> in the selected time zone. If
                              left blank, the workspace time zone is used.
                            </>
                          }
                        />
                      </div>
                    </div>
                  </Form.Control>
                </Form.Section>

                <Form.Section title="Email and Auto-Approval">
                  <Form.Control>
                    <ContainerControl>
                      <Field.Checkbox
                        name="sendReminder"
                        label="Send a daily reminder email if the client does not submit a response within"
                      />
                      <Field.SingleSelect
                        name="sendReminderAfterDays"
                        materialPlaceholder={false}
                        disabled={!formik.values.sendReminder}
                        style={{ width: '5.5rem', margin: '0 0.5rem', textAlign: 'right' }}>
                        {[...Array(20).keys()].map((_, idx) => (
                          <option key={idx} value={idx + 1}>
                            {idx + 1}
                          </option>
                        ))}
                      </Field.SingleSelect>
                      <span>days.</span>
                      <HelpTooltip
                        style={{ marginLeft: '0.3rem' }}
                        message="A daily reminder will be sent for one week."
                        placement="right"
                      />
                    </ContainerControl>
                  </Form.Control>

                  <Form.Control>
                    <ContainerControl>
                      <Field.Checkbox
                        name="autoApprove"
                        label="Auto-approve all items if the client does not submit a response within"
                      />
                      <Field.SingleSelect
                        name="autoApproveAfterDays"
                        materialPlaceholder={false}
                        disabled={!formik.values.autoApprove}
                        style={{ width: '5.5rem', margin: '0 0.5rem', textAlign: 'right' }}>
                        {[...Array(20).keys()].map((_, idx) => (
                          <option key={idx} value={idx + 1}>
                            {idx + 1}
                          </option>
                        ))}
                      </Field.SingleSelect>
                      <span>days.</span>
                    </ContainerControl>
                  </Form.Control>

                  <div style={{ marginTop: '1rem' }}>
                    <Field.Checkbox
                      name="autoSendEmail"
                      label="Automatically email this client approval upon creation"
                    />
                  </div>
                </Form.Section>

                <Form.Section
                  title="Project Member Settings"
                  subtitle="Select the project members to include in this client approval schedule. If no project members are selected, every project member will be included.">
                  <Form.Control>
                    <Field.Control>
                      <ClientApprovalScheduleMemberMultiSelect
                        placeholder="Project Members"
                        value={formik.values.members}
                        exclude={formik.values.members.map((member) => member.projectMemberId)}
                        onChange={({ target: { value } }) => formik.setFieldValue('members', value)}
                        project={project}
                        initialValue={clientApprovalSchedule.members}
                      />
                    </Field.Control>
                  </Form.Control>
                </Form.Section>

                <Form.Section title="Time Settings">
                  <Form.Control>
                    <Field.Checkbox name="includeTime" label="Include time entries on this client approval" />
                  </Form.Control>

                  {formik.values.includeTime && (
                    <>
                      <p style={{ margin: '1rem 0 0.5rem' }}>
                        Only create this client approval if the following conditions are true:
                      </p>

                      <Form.Control>
                        <Field.Checkbox
                          name="requireTimeApproval"
                          label="All time entries associated with this client approval are internally approved"
                        />
                      </Form.Control>

                      {features.timesheets && (
                        <Form.Control>
                          <Field.Checkbox
                            name="requireTimesheetSubmission"
                            label="All project members' timesheets for the specified period must be submitted"
                          />
                        </Form.Control>
                      )}
                    </>
                  )}
                </Form.Section>

                <Form.Section title="Expense Settings">
                  <Form.Control>
                    <Field.Checkbox name="includeExpenses" label="Include expenses on this client approval" />
                  </Form.Control>

                  {formik.values.includeExpenses && (
                    <>
                      <p style={{ margin: '1rem 0 0.5rem' }}>
                        Only create this client approval if the following conditions are true:
                      </p>

                      <Form.Control>
                        <Field.Checkbox
                          name="requireExpenseApproval"
                          label="All expense items associated with this client approval are internally approved"
                        />
                      </Form.Control>
                    </>
                  )}

                  <Form.Control>
                    <Field.Checkbox name="includeReceipts" label="Include expense receipts on this client approval" />
                  </Form.Control>
                </Form.Section>

                <Form.Section title="Email Details">
                  <Form.Control help="Can contain multiple emails separated by a comma, semicolon, or space.">
                    <Field.Text
                      name="emails"
                      placeholder="To"
                      value={formik.values.email}
                      onChange={handleEmailChange}
                      onProcessErrors={processEmailErrors}
                    />
                  </Form.Control>
                  <Form.Control help='The name to display in the "from" part of the email. If not provided, "Ruddr" will be used.'>
                    <Field.Text name="emailFromName" placeholder="From Name" maxLength={255} />
                  </Form.Control>
                  <Form.Control help='In order to ensure deliverability, all emails will come from approvals@ruddr.io, but this will be used as the "Reply-To" email adddress.'>
                    <Field.Text name="replyTo" placeholder="Reply To" type="email" maxLength={255} />
                  </Form.Control>
                  <Form.Control help="Can contain multiple emails separated by a comma, semicolon, or space.">
                    <Field.Text
                      name="ccEmails"
                      placeholder="Cc"
                      value={formik.values.ccEmail}
                      onChange={handleCcEmailChange}
                      onProcessErrors={processCcEmailErrors}
                    />
                  </Form.Control>
                  <Form.Control>
                    <Field.Checkbox name="sendBcc" label="Bcc Me" />
                  </Form.Control>
                  <Form.Control>
                    <Field.Text name="emailSubject" placeholder="Subject" maxLength={255} />
                  </Form.Control>
                  <Form.Control>
                    <Field.TextArea name="emailBody" placeholder="Body" maxLength={5000} />
                  </Form.Control>
                </Form.Section>

                {status && <FormMessage.Error>{message}</FormMessage.Error>}
              </ModalCardBody>

              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={handleClose}>Close</CancelButton>

                  <Button isLoading={isSubmitting} onClick={formik.submitForm}>
                    Save &amp; Close
                  </Button>
                </Buttons>
              </ModalCard.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
