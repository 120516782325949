import { DateTime, Icon } from '~/components';
import { PageLoader } from '~/routes/public/pages';
import { useApi, useWorkspace } from '~/contexts';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const HistoryItem = styled.div`
  position: relative;
  margin-left: 1.25rem;
  padding-left: 3.75rem;
  padding-bottom: 2rem;
  border-left: solid 2px ${colors.grey10};

  &:last-child {
    padding-bottom: 0;
    border-color: transparent;
  }
`;

const HistoryIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 0;
  left: -1.3125rem;
  color: ${({ color }) => color};
  font-size: ${({ size }) => size};
  background-color: ${colors.white};
  border: solid 3px ${({ color }) => color};
  border-radius: 50%;
`;

const HistoryTitle = styled.div`
  font-weight: ${weights.bold};
`;

const HistoryDetails = styled.div`
  font-size: 0.875rem;
  color: ${colors.grey75};
`;

const HistoryNotes = styled.div`
  margin-top: 0.75rem;
  color: ${colors.grey55};
  font-size: 0.75rem;
  font-style: italic;

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }
`;

function PurchaseOrderHistoryIcon({ color = colors.black, size = '1rem', ...props }) {
  return (
    <HistoryIcon color={color} size={size}>
      <Icon {...props} />
    </HistoryIcon>
  );
}

export default function PurchaseOrderHistory({ purchaseOrderId }) {
  const api = useApi();
  const [query, setQuery] = useState({ status: 'loading', data: null });
  const { workspace } = useWorkspace();

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).purchaseOrders(purchaseOrderId).history();

    setQuery({ status: 'ready', data });
  }, [api, workspace.id, purchaseOrderId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (query.status === 'loading') return <PageLoader />;

  return (
    <>
      {query.data?.map((item) => (
        <HistoryItem key={item.id}>
          {
            {
              activated: <PurchaseOrderHistoryIcon icon="inbox-out" color={colors.primary} />,
              created: <PurchaseOrderHistoryIcon icon="plus" color={colors.primary} size="1rem" />,
              deactivated: <PurchaseOrderHistoryIcon icon="inbox-in" color={colors.primary} />,
              updated: <PurchaseOrderHistoryIcon icon="sync" color={colors.primary} size="0.875rem" />,
            }[item.status.id]
          }
          <HistoryTitle>{item.status?.name}</HistoryTitle>
          <HistoryDetails>
            <DateTime value={item.eventTime} format="l [at] LT" />
            {item.member ? ` by ${item.member.name}` : null} via {item.source.name}
          </HistoryDetails>
          {item.notes && <HistoryNotes>{item.notes}</HistoryNotes>}
        </HistoryItem>
      ))}
    </>
  );
}
