import _ from 'lodash';
import { useContext, useMemo } from 'react';
import { FavoriteReportsContext } from './components/FavoriteReportsContext';
import useExpenseReports from './expenses/useExpenseReports';
import useAccountingReports from './financial/useAccountingReports';
import usePerformanceReports from './financial/usePerformanceReports';
import useForecastReports from './forecast/useForecastReports';
import usePlanReports from './forecast/usePlanReports';
import useVarianceReports from './forecast/useVarianceReports';
import usePipelineReports from './pipeline/usePipelineReports';
import useTimeReports from './time/useTimeReports';
import useUtilizationReports from './utilization/useUtilizationReports';
import useWorkspaceReports from './workspace/useWorkspaceReports';

export default function useFavoriteReports() {
  const timeReports = useTimeReports();
  const expenseReports = useExpenseReports();
  const utilizationReports = useUtilizationReports();
  const planReports = usePlanReports();
  const forecastReports = useForecastReports();
  const varianceReports = useVarianceReports();
  const accountingReports = useAccountingReports();
  const performanceReports = usePerformanceReports();
  const pipelineReports = usePipelineReports();
  const workspaceReports = useWorkspaceReports();

  const favoriteReportsContext = useContext(FavoriteReportsContext);

  const favoriteReports = useMemo(() => {
    const favorites = favoriteReportsContext.favorites.map(({ reportKey }) => reportKey);
    return _([
      ...timeReports,
      ...expenseReports,
      ...utilizationReports,
      ...planReports,
      ...forecastReports,
      ...varianceReports,
      ...accountingReports,
      ...performanceReports,
      ...pipelineReports,
      ...workspaceReports,
    ])
      .filter((r) => favorites.includes(r.key))
      .orderBy((r) => r.name)
      .value();
  }, [
    timeReports,
    expenseReports,
    utilizationReports,
    planReports,
    forecastReports,
    varianceReports,
    accountingReports,
    performanceReports,
    pipelineReports,
    workspaceReports,
    favoriteReportsContext.favorites,
  ]);

  return favoriteReports;
}
