import { Formik } from 'formik';
import _ from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Button, Field, Form, FormMessage } from '~/components';
import { useApi } from '~/contexts';
import { useForm } from '~/hooks';
import { authProviders } from '~/lookups';
import locales from '~/lookups/locales';
import { weights } from '~/styles';
import { emptyStringToNull, mergeValues } from '~/utils';

const Title = styled.div`
  font-size: 2.25rem;
  font-weight: ${weights.light};
  text-align: center;
  margin-bottom: 2rem;
`;

const FormAction = styled.div`
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Button} {
    width: 14.6875rem;
    margin: 0 auto 1rem;
  }
`;

export default function AuthProviderForm({ member, authProvider }) {
  const api = useApi();
  const [{ message, isSubmitting }, form] = useForm();
  const { token } = useParams();

  const initialValues = mergeValues(
    {
      email: member.email,
      locale: locales.find((l) => l === navigator.language) ?? member.workspace.locale ?? 'en-US',
      name: member.name,
      termsAgree: false,
    },
    member,
  );

  async function handleSubmit(values) {
    try {
      form.submit();

      const body = emptyStringToNull({
        ..._.omit(values, ['email', 'name', 'termsAgree']),
        token,
      });
      const {
        data: { authorizeUrl },
      } = await api.www.authProviders(authProvider.id).joinWorkspace(body);
      window.location = authorizeUrl;
    } catch ({ message }) {
      form.error({ message });
    }
  }

  return (
    <>
      <Title>
        Join <strong>{member.workspace.name}</strong>
      </Title>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          termsAgree: Yup.bool().oneOf([true], "You must agree to Ruddr's terms"),
        })}>
        {() => (
          <Form autoComplete="off">
            <Form.Control>
              <Field.Text autoFocus name="name" placeholder="Name" autoComplete="username" maxLength={255} disabled />
            </Form.Control>
            <Form.Control>
              <Field.Text name="email" placeholder="Email" disabled />
            </Form.Control>
            <Form.Control>
              <Field.RegionalFormatSelect name="locale" clearable={false} />
            </Form.Control>
            <Form.Control>
              <Field.Checkbox
                name="termsAgree"
                label={
                  <>
                    I agree to Ruddr's{' '}
                    <a href="/master-subscription-agreement" target="_blank">
                      terms
                    </a>
                  </>
                }
              />
            </Form.Control>

            {message && <FormMessage.Error spaceTop>{message}</FormMessage.Error>}

            <FormAction>
              <Button type="submit" isLoading={isSubmitting}>
                Join Workspace
              </Button>

              <div style={{ fontSize: '.875rem', textAlign: 'center' }}>
                <i>You will be redirected to {authProviders[authProvider.provider].name} to complete the process.</i>
              </div>
            </FormAction>
          </Form>
        )}
      </Formik>
    </>
  );
}
