import _, { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  Avatar,
  CompanyFilter,
  CreateButton,
  Currency,
  CurrencyInput,
  DateTime,
  ExportDialog,
  ExportDropdown,
  FiltersBar,
  ListView,
  ListViewActions,
  ListViewMenu,
  MemberContactPopover,
  MemberFilter,
  OpportunityLink,
  OpportunityStageCategoryFilter,
  OpportunityStageFilter,
  OpportunityTypeFilter,
  Page,
  Percent,
  PracticeFilter,
  SearchInput,
  Tag,
  Tooltip,
  YesNoFilter,
} from '~/components';
import OpportunityTagFilter from '~/components/filters/OpportunityTagFilter';
import { useApi, useConfirmation, useIntegrations, useToast, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import opportunityStageCategories from '~/lookups/opportunity-stage-categories';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort, mimeTypes } from '~/utils';
import FilterButton from '~/components/filters/FilterButton.jsx';
import FiltersDrawer from '~/components/filters/FiltersDrawer.jsx';
import FiltersGroup from '~/components/filters/FiltersGroup.jsx';
import List from '../../reports/components/List';
import ListTooltip from '../../reports/components/ListTooltip';
import MultiCurrency from '../../../../components/MultiCurrency.jsx';
import ReportPeriodFilter from '~/components/filters/ReportPeriodFilter.jsx';
import useReportsSearchParamsConfig from '../../reports/hooks/useReportsSearchParamsConfig.js';
import HubspotIndicator from '../components/HubspotIndicator';
import SalesforceIndicator from '../components/SalesforceIndicator';
import OpportunitiesSummaryWidget from './OpportunitiesSummaryWidget';
import OpportunityCloneForm from './OpportunityCloneForm';
import OpportunityDeleteConfirmation from './OpportunityDeleteConfirmation';
import OpportunityForm from './OpportunityForm';
import OpportunityToProjectForm from './OpportunityToProjectForm';
import ProbabilityBar from './ProbabilityBar';

const SummarySection = styled.section`
  background: ${colors.grey5};
  padding: 1rem;
  margin: 0 -1rem;
  overflow: auto;
  scrollbar-width: none;
`;

const InfoContainer = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const StageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const OpportunityName = styled.div`
  display: flex;
  align-items: center;
`;

const Small = styled.small`
  display: block;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TagsTitle = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

const Owner = styled.div`
  display: flex;
  align-items: center;
`;

const OwnerInfo = styled.span`
  margin-left: 0.5rem;
`;

function FirstAssignment({ tags }) {
  const firstTag = tags[0];
  if (!firstTag) return null;

  return (
    <Tag style={{ backgroundColor: colors.grey5 }}>
      <small>{firstTag.name}</small>
    </Tag>
  );
}

function Assignments({ tags }) {
  const count = tags.length - 1;
  if (count <= 0) return null;

  return (
    <Tooltip
      message={
        <div style={{ fontSize: '1rem' }}>
          <TagsTitle>Tags</TagsTitle>
          {tags.map((tag) => (
            <Tag style={{ backgroundColor: colors.grey5 }} key={tag.id}>
              <small>{tag.name}</small>
            </Tag>
          ))}
        </div>
      }>
      <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40 }}>
        <small>+{count}</small>
      </Tag>
    </Tooltip>
  );
}

export default function OpportunitiesListPage({ renderFilters, mode = 'page', companyId, sessionKey }) {
  const integrations = useIntegrations();

  const documentTitle = useDocumentTitle();
  useEffect(() => {
    if (mode === 'page') documentTitle.set('Opportunities');
  }, [mode, documentTitle]);

  const { workspace } = useWorkspace();
  const features = useFeatures();
  const api = useApi();
  const auth = useAuth();
  const history = useHistory();
  const { opportunityId } = useParams();
  const toast = useToast();
  const confirmation = useConfirmation();

  const [cloneTarget, setCloneTarget] = useState(null);
  const [opportunityToProject, setOpportunityToProject] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);

  const [query, setQuery] = useState({
    data: null,
    action: 'load',
    status: 'loading',
  });
  const [params, setParams] = useState({
    q: '',
    companies: [],
    hubspotSynced: null,
    opportunityStages: [],
    opportunityStageStatuses: [],
    opportunityTypes: [],
    opportunityAmountGreaterThanOrEqual: null,
    opportunityTags: [],
    opportunityPractice: [],
    salesforceSynced: null,
    opportunityOwners: [],
    page: 0,
    size: 25,
    sort: new QuerySort('convertedWeightedAmount', 'desc'),
    period: null,
    closedPeriod: null,
  });

  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        q: { default: '' },
        companies: searchParamsConfig.companies,
        opportunityOwners: searchParamsConfig.members,
        opportunityStageStatuses: {
          default: [opportunityStageCategories.open],
          ...searchParamsConfig.opportunityStageCategories,
        },
        opportunityStages: searchParamsConfig.opportunityStages,
        opportunityTypes: searchParamsConfig.opportunityTypes,
        opportunityAmountGreaterThanOrEqual: {
          ...searchParamsConfig.amount,
          key: 'opportunityAmountGreaterThanOrEqual',
        },
        opportunityTags: searchParamsConfig.opportunityTags,
        opportunityPractice: searchParamsConfig.practices,
        salesforceSynced: { default: null },
        hubspotSynced: { default: null },
        sort: { default: new QuerySort('name', 'asc'), ...searchParamsConfig.sort },
        createdPeriod: reportsSearchParamsConfig.createdPeriod,
        closedPeriod: reportsSearchParamsConfig.closedPeriod,
      }),
      [reportsSearchParamsConfig, searchParamsConfig],
    ),
    sessionKey,
    onChange: useCallback((params) => setParams((state) => ({ ...state, ...params })), []),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      q: params.q,
      opportunityOwnerId: params.opportunityOwners?.map((v) => v.id),
      opportunityStageStatusId: params.opportunityStageStatuses?.map((v) => v.id),
      opportunityStageId: params.opportunityStages.length ? params.opportunityStages.map((v) => v.id) : [],
      opportunityTypeId: params.opportunityTypes?.map((v) => v.id),
      opportunityAmountGreaterThanOrEqual: _.isNumber(params?.opportunityAmountGreaterThanOrEqual)
        ? params.opportunityAmountGreaterThanOrEqual
        : undefined,
      opportunityTagId: params.opportunityTags.length ? params.opportunityTags.map((v) => v.id) : [],
      opportunityPracticeId: params.opportunityPractice?.map((v) => v.id),
      salesforceSynced: integrations?.salesforce && params.salesforceSynced ? params.salesforceSynced : undefined,
      hubspotSynced: integrations?.hubspot && params.hubspotSynced ? params.hubspotSynced : undefined,
      page: params.page,
      size: params.size,
      sort: params.sort,
      companyId: params.companies.length ? params.companies?.map((v) => v.id) : companyId,
      createdStart: params.createdPeriod?.start ?? undefined,
      createdEnd: params.createdPeriod?.end ?? undefined,
      closedStart: params.closedPeriod?.start ?? undefined,
      closedEnd: params.closedPeriod?.end ?? undefined,
    }),
    [params, companyId, integrations?.salesforce, integrations?.hubspot],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      if (params) {
        setParams((state) => {
          return { ...state, ...params };
        });
        setSearchParamsStatus('ready');
      }
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).opportunities().get(urlSearchParams);

      setQuery((state) => ({
        ...state,
        action: null,
        status: 'ready',
        error: null,
        data: {
          ...data,
          results: state.action === 'load-more' ? [...state.data.results, ...data.results] : data.results,
        },
      }));
    } catch (error) {
      setQuery({ status: 'ready', data: null, error });
      toast.error(error.message);
    }
  }, [workspace.id, api, urlSearchParams, toast]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);

  const handleSaved = () => {
    setQuery((state) => ({ ...state, action: 'load' }));
    setParams((params) => ({ ...params, page: 0 }));
    refreshSummary();
  };

  const handleChange = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values, page: 0 });
    searchParams.set(_.omit(values, 'sort'));
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort, page: 0 });
    searchParams.set({ sort: querySort });
  };

  const handleDelete = (opportunity) => {
    confirmation.prompt((resolve) => (
      <OpportunityDeleteConfirmation
        opportunity={opportunity}
        onClose={resolve}
        onDelete={() => {
          setQuery((state) => ({ ...state, status: 'load' }));
          setParams({ ...params, page: 0 });
          refreshSummary();
          resolve(true);
        }}
      />
    ));
  };

  const handleEdit = ({ id }) => {
    history.push(`/app/${workspace.key}/pipeline/opportunities/edit/${id}`);
  };

  const handleOverview = ({ id }) => {
    history.push(`/app/${workspace.key}/pipeline/opportunities/${id}/overview`);
  };

  const refreshSummary = () => {
    setRefreshKey((refreshKey) => refreshKey + 1);
  };

  async function handleCloned(opportunity) {
    try {
      toast.success('Opportunity cloned successfully.');
      history.push(`/app/${workspace.key}/pipeline/opportunities/${opportunity.id}/overview/edit`);
    } catch ({ message }) {
      toast.error(message);
    }
  }

  function handleClose() {
    history.push(`/app/${workspace.key}/pipeline/opportunities`);
    documentTitle.set('Opportunities');
  }

  const loadMore = () => {
    if (
      query.status !== 'ready' ||
      query.action !== null ||
      !query.data ||
      query.data.total <= query.data.results.length
    )
      return;

    setQuery((state) => {
      return {
        ...state,
        action: 'load-more',
      };
    });
    setParams((params) => ({ ...params, page: params.page + 1 }));
  };

  const handleExport = async (filename, mimeType) => {
    await confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .opportunities()
          .export(
            {
              ...urlSearchParams,
              size: null,
            },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  const Container = useCallback(
    (props) => (mode === 'page' ? <Page scrollable {...props} /> : <React.Fragment {...props} />),
    [mode],
  );

  if (query.status === 'loading' || !query.data) return <PageLoader />;

  const salesforceEnabled = integrations?.salesforce;
  const hubspotEnabled = integrations?.hubspot;

  return (
    <Container>
      {mode === 'page' && (
        <>
          <Page.Header>
            <Page.Info>
              <Page.Eyebrow>Pipeline</Page.Eyebrow>
              <Page.Title>Opportunities</Page.Title>
            </Page.Info>

            <Page.Actions>
              <ExportDropdown>
                {({ setIsOpen }) => (
                  <>
                    <ExportDropdown.Item
                      onClick={async () => {
                        await handleExport(`opportunities.csv`, mimeTypes.csv);
                        setIsOpen(false);
                      }}>
                      Export to CSV
                    </ExportDropdown.Item>

                    <ExportDropdown.Item
                      onClick={async () => {
                        await handleExport(`opportunities.xlsx`, mimeTypes.xlsx);
                        setIsOpen(false);
                      }}>
                      Export to Excel
                    </ExportDropdown.Item>
                  </>
                )}
              </ExportDropdown>

              <FilterButton isOutline onClick={showFilters} />

              {auth.pipeline.manage && (
                <CreateButton
                  tooltip="New Opportunity"
                  onClick={() => history.push(`/app/${workspace.key}/pipeline/opportunities/new`)}
                />
              )}
            </Page.Actions>
          </Page.Header>
        </>
      )}

      {renderFilters ? (
        renderFilters({ params, onFilter: handleChange })
      ) : (
        <>
          <Page.Filters>
            <FiltersBar>
              {(params.createdPeriod?.start || params.createdPeriod?.end) && (
                <ReportPeriodFilter
                  placeholder="Created Date Range"
                  value={params.createdPeriod}
                  onChange={({ value }) => handleChange({ createdPeriod: value })}
                />
              )}

              {(params.closedPeriod?.start || params.closedPeriod?.end) && (
                <ReportPeriodFilter
                  placeholder="Expected Closed Date Range"
                  value={params.closedPeriod}
                  onChange={({ value }) => handleChange({ closedPeriod: value })}
                />
              )}

              <SearchInput
                placeholder="Search"
                value={params.q}
                onChange={({ target: { value } }) => handleChange({ q: value })}
              />

              <OpportunityStageCategoryFilter
                name="opportunityStageStatuses"
                value={params.opportunityStageStatuses}
                onChange={({ target: { value } }) => handleChange({ opportunityStageStatuses: value })}
              />

              <OpportunityStageFilter
                name="opportunityStages"
                value={params.opportunityStages}
                opportunityStageStatusId={params.opportunityStageStatuses.map((status) => status.id)}
                onChange={({ target: { value } }) => handleChange({ opportunityStages: value })}
              />

              {features.practices && (
                <PracticeFilter
                  name="opportunityPractice"
                  value={params.opportunityPractice}
                  placeholder="Opportunity Practice"
                  onChange={({ target: { value } }) => handleChange({ opportunityPractice: value })}
                />
              )}

              <CompanyFilter
                name="companies"
                placeholder="Company"
                value={params.companies}
                onChange={({ target: { value } }) => handleChange({ companies: value })}
              />

              <OpportunityTypeFilter
                name="opportunityType"
                value={params.opportunityTypes}
                placeholder="Opportunity Type"
                onChange={({ target: { value } }) => handleChange({ opportunityTypes: value })}
              />

              <OpportunityTagFilter
                name="opportunityTags"
                value={params.opportunityTags}
                placeholder="Opportunity Tags"
                onChange={({ target: { value } }) => handleChange({ opportunityTags: value })}
              />

              <MemberFilter
                value={params.opportunityOwners}
                name="opportunityOwners"
                placeholder="Opportunity Owner"
                onChange={({ target: { value } }) => handleChange({ opportunityOwners: value })}
              />

              <YesNoFilter
                icon="filter"
                clearable
                placeholder="Salesforce Synced"
                value={params.salesforceSynced}
                onChange={({ target: { value } }) => handleChange({ salesforceSynced: value })}
              />

              {hubspotEnabled && (
                <YesNoFilter
                  icon="filter"
                  clearable
                  placeholder="HubSpot Synced"
                  value={params.hubspotSynced}
                  onChange={({ target: { value } }) => handleChange({ hubspotSynced: value })}
                />
              )}

              {_.isNumber(params?.opportunityAmountGreaterThanOrEqual) && (
                <CurrencyInput
                  name="opportunityAmountGreaterThanOrEqual"
                  placeholder="Minimum Opportunity Amount"
                  value={params.opportunityAmountGreaterThanOrEqual}
                  onChange={debounce((value) => {
                    handleChange({ opportunityAmountGreaterThanOrEqual: value });
                  }, 500)}
                />
              )}
            </FiltersBar>
          </Page.Filters>

          <Filters values={params} isOpen={filtersVisible} onApply={handleChange} onClose={hideFilters} />
        </>
      )}

      {mode === 'page' && (
        <Page.Section sticky>
          <SummarySection>
            <OpportunitiesSummaryWidget
              key={refreshKey}
              q={urlSearchParams.q}
              opportunityStageStatusId={urlSearchParams.opportunityStageStatusId}
              opportunityStageId={urlSearchParams.opportunityStageId}
              opportunityTagId={urlSearchParams.opportunityTagId}
              opportunityTypeId={urlSearchParams.opportunityTypeId}
              opportunityAmountGreaterThanOrEqual={urlSearchParams.opportunityAmountGreaterThanOrEqual}
              salesforceSynced={urlSearchParams.salesforceSynced}
              hubspotSynced={urlSearchParams.hubspotSynced}
              opportunityOwnerId={urlSearchParams.opportunityOwnerId}
              opportunityPracticeId={urlSearchParams.opportunityPracticeId}
              companyId={urlSearchParams.companyId}
              closedStart={urlSearchParams.closedStart}
              closedEnd={urlSearchParams.closedEnd}
              createdStart={urlSearchParams.createdStart}
              createdEnd={urlSearchParams.createdEnd}
            />
          </SummarySection>
        </Page.Section>
      )}

      <Page.ListView>
        <ListView>
          <ListView.Header>
            <ListView.Column sticky minWidth="16rem" name="name" onSort={handleSort} sort={params.sort}>
              Opportunity Name
            </ListView.Column>
            <ListView.Column minWidth="14rem" name="owner.name" onSort={handleSort} sort={params.sort}>
              Owner
            </ListView.Column>
            <ListView.Column width="12rem" name="opportunityStage" onSort={handleSort} sort={params.sort}>
              Stage
            </ListView.Column>
            <ListView.Column minWidth="10rem">Tags</ListView.Column>
            <ListView.Column width="10rem" name="convertedAmount" onSort={handleSort} sort={params.sort} align="right">
              Amount
            </ListView.Column>
            <ListView.Column width="8rem" name="probability" onSort={handleSort} sort={params.sort} align="right">
              Probability
            </ListView.Column>
            <ListView.Column width="7rem" name="lastActivityDate" onSort={handleSort} sort={params.sort} align="right">
              Last Activity
            </ListView.Column>
            <ListView.Column width="7rem" name="createdAt" onSort={handleSort} sort={params.sort} align="right">
              Created
            </ListView.Column>
            <ListView.Column width="7rem" name="closeDate" onSort={handleSort} sort={params.sort} align="right">
              Closing
            </ListView.Column>
            {salesforceEnabled && (
              <ListView.Column width="3rem" name="salesforceId" onSort={handleSort} sort={params.sort} align="right">
                <SalesforceIndicator />
              </ListView.Column>
            )}
            {hubspotEnabled && (
              <ListView.Column width="3rem" name="hubspotId" onSort={handleSort} sort={params.sort} align="right">
                <HubspotIndicator />
              </ListView.Column>
            )}
            <ListViewActions.Column isVisible={mode === 'page'} />
          </ListView.Header>

          <ListView.Body fade={query.status === 'filtering'}>
            {query.data.results.map((opportunity) => {
              const {
                id,
                amount,
                opportunityStage,
                tags,
                probability,
                createdAt,
                closeDate,
                currency,
                convertedAmount,
                permissions,
                owner,
                salesforceId,
                hubspotId,
                lastActivityDate,
              } = opportunity;

              async function handleRowClick(opportunity) {
                handleOverview(opportunity);
              }

              return (
                <ListView.Row key={id} onClick={() => handleRowClick(opportunity)}>
                  <ListView.Cell>
                    <InfoContainer>
                      <OpportunityName>
                        <OpportunityLink opportunity={opportunity} onClick={(e) => e.stopPropagation()} />
                      </OpportunityName>
                      <small>{opportunity.company.name}</small>
                    </InfoContainer>
                  </ListView.Cell>
                  <ListView.Cell>
                    {owner && (
                      <Owner>
                        <MemberContactPopover member={owner} placement="left">
                          <Avatar value={owner} isCircle hasBackground initialsFontSize=".9rem" />
                        </MemberContactPopover>
                        <OwnerInfo>{owner.name}</OwnerInfo>
                      </Owner>
                    )}
                  </ListView.Cell>
                  <ListView.Cell>
                    <StageContainer>
                      <span>{opportunityStage.name}</span>
                      <ProbabilityBar
                        probability={opportunityStage.probability}
                        stageStatus={opportunityStage.statusId}
                      />
                    </StageContainer>
                  </ListView.Cell>
                  <ListView.Cell>
                    <Tags>
                      <FirstAssignment tags={tags} />
                      <Assignments tags={tags} />
                    </Tags>
                  </ListView.Cell>
                  <ListView.Cell>
                    <p>
                      <Currency
                        value={convertedAmount}
                        currency={workspace.currency}
                        minimumFractionDigits={0}
                        maximumFractionDigits={2}
                      />
                      {currency !== workspace.currency && (
                        <Small>
                          <Currency
                            value={amount}
                            currency={currency}
                            minimumFractionDigits={0}
                            maximumFractionDigits={2}
                          />
                        </Small>
                      )}
                    </p>
                  </ListView.Cell>
                  <ListView.Cell>
                    <ListTooltip
                      message={
                        <List>
                          <List.Item label="Weighted Amount">
                            <MultiCurrency
                              value={[
                                { value: opportunity.convertedWeightedAmount, currency: workspace.currency },
                                { value: opportunity.weightedAmount, currency: opportunity.currency },
                              ]}
                            />
                          </List.Item>
                        </List>
                      }>
                      <Percent value={probability / 100} minimumFractionDigits={0} maximumFractionDigits={0} />
                    </ListTooltip>
                  </ListView.Cell>
                  <ListView.Cell>
                    <DateTime value={lastActivityDate} />
                  </ListView.Cell>
                  <ListView.Cell>
                    <DateTime value={createdAt} />
                  </ListView.Cell>
                  <ListView.Cell>
                    <DateTime value={closeDate} />
                  </ListView.Cell>
                  {salesforceEnabled && <ListView.Cell>{salesforceId && <SalesforceIndicator />}</ListView.Cell>}
                  {hubspotEnabled && <ListView.Cell>{hubspotId && <HubspotIndicator />}</ListView.Cell>}
                  <ListViewActions isVisible={mode === 'page'}>
                    {auth.pipeline.manage ? (
                      <ListViewActions.Edit onClick={() => handleEdit(opportunity)} />
                    ) : (
                      <ListViewActions.View onClick={() => handleOverview(opportunity)} />
                    )}

                    <hr />

                    <ListViewMenu>
                      {({ setIsOpen }) => {
                        const handleAction = (action) => setIsOpen(false) || action;

                        return (
                          <>
                            <ListViewMenu.Item onClick={() => handleAction(handleOverview(opportunity))}>
                              View
                            </ListViewMenu.Item>

                            <ListViewMenu.Item
                              disabled={!permissions.manage}
                              tooltip={
                                !permissions.manage ? 'Insufficient permissions to edit this opportunity.' : undefined
                              }
                              onClick={() => handleAction(handleEdit(opportunity))}>
                              Edit
                            </ListViewMenu.Item>

                            <ListViewMenu.Item
                              disabled={!permissions.manage}
                              tooltip={
                                !permissions.manage ? 'Insufficient permissions to delete this opportunity.' : undefined
                              }
                              onClick={() => handleAction(handleDelete(opportunity))}>
                              Delete
                            </ListViewMenu.Item>

                            <ListViewMenu.Item
                              disabled={!permissions.manage}
                              tooltip={
                                !permissions.manage ? 'Insufficient permissions to clone this opportunity.' : undefined
                              }
                              onClick={() => handleAction(setCloneTarget(opportunity))}>
                              Clone
                            </ListViewMenu.Item>

                            <ListViewMenu.Item
                              disabled={!!opportunity.project}
                              tooltip={(() => {
                                if (opportunity.project) return 'This opportunity is already linked to a project.';
                              })()}
                              onClick={() => handleAction(setOpportunityToProject(opportunity))}>
                              Create Project
                            </ListViewMenu.Item>
                          </>
                        );
                      }}
                    </ListViewMenu>
                  </ListViewActions>
                </ListView.Row>
              );
            })}

            {query.data.results.length === 0 && <ListView.Empty />}

            {query.data.total > query.data.results.length && (
              <ListView.Loader key={query.data.results.length} onIntersecting={loadMore} />
            )}
          </ListView.Body>

          <ListView.Status total={query.data.total} label="Opportunity" isLoading={query.status !== 'ready'} />
        </ListView>
      </Page.ListView>

      {opportunityId && <OpportunityForm onSaved={handleSaved} onClose={() => handleClose()} />}
      {cloneTarget && (
        <OpportunityCloneForm target={cloneTarget} onClose={() => setCloneTarget(null)} onSaved={handleCloned} />
      )}
      {opportunityToProject && (
        <OpportunityToProjectForm
          opportunity={opportunityToProject}
          onClose={() => setOpportunityToProject(false)}
          onSaved={(project) =>
            history.push(`/app/${workspace.key}/projects/${project.client.key}/${project.key}/overview/edit`)
          }
        />
      )}
    </Container>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);
  const features = useFeatures();
  const integrations = useIntegrations();

  const handleApply = () => {
    onApply(filters);
    onClose();
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  const salesforceEnabled = integrations?.salesforce;
  const hubspotEnabled = integrations?.hubspot;

  return (
    <FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <FiltersGroup label="Company Filters" filters={[filters.company]}>
        <CompanyFilter
          name="company"
          placeholder="Company"
          materialAlwaysVisible
          value={filters.companies}
          onChange={({ target: { value } }) => handleFilter({ companies: value })}
        />
      </FiltersGroup>

      <FiltersGroup
        label="Opportunity Filters"
        filters={[
          filters.closedPeriod,
          filters.opportunityStages,
          filters.opportunityStageStatuses,
          filters.opportunityTags,
          filters.opportunityTypes,
          filters.opportunityOwners,
          filters.opportunityPractice,
          filters.opportunityAmountGreaterThanOrEqual,
          filters.salesforceSynced,
          filters.hubspotSynced,
        ]}>
        <ReportPeriodFilter
          value={filters.createdPeriod}
          placeholder="Created Date Range"
          onChange={({ target: { value } }) => handleFilter({ createdPeriod: value })}
        />

        <ReportPeriodFilter
          value={filters.closedPeriod}
          placeholder="Expected Close Date Range"
          onChange={({ target: { value } }) => handleFilter({ closedPeriod: value })}
        />

        <OpportunityStageCategoryFilter
          name="opportunityStageStatuses"
          value={filters.opportunityStageStatuses}
          onChange={({ target: { value } }) => handleFilter({ opportunityStageStatuses: value })}
        />

        <OpportunityStageFilter
          name="opportunityStages"
          value={filters.opportunityStages}
          opportunityStageStatusId={filters.opportunityStageStatuses.map((status) => status.id)}
          onChange={({ target: { value } }) => handleFilter({ opportunityStages: value })}
        />

        <MemberFilter
          name="opportunityOwners"
          value={filters.opportunityOwners}
          onChange={({ target: { value } }) => handleFilter({ opportunityOwners: value })}
          placeholder="Opportunity Owner"
        />

        <OpportunityTagFilter
          name="opportunityTags"
          value={filters.opportunityTags}
          onChange={({ target: { value } }) => handleFilter({ opportunityTags: value })}
          placeholder="Opportunity Tags"
        />

        <OpportunityTypeFilter
          name="opportunityTypes"
          value={filters.opportunityTypes}
          onChange={({ target: { value } }) => handleFilter({ opportunityTypes: value })}
          placeholder="Opportunity Type"
        />

        <CurrencyInput
          name="opportunityAmountGreaterThanOrEqual"
          value={filters.opportunityAmountGreaterThanOrEqual}
          onChange={(value) => handleFilter({ opportunityAmountGreaterThanOrEqual: value })}
          placeholder="0"
          materialPlaceholder="Minimum Opportunity Amount"
          materialAlwaysVisible
        />

        {features.practices && (
          <PracticeFilter
            name="opportunityPractice"
            value={filters.opportunityPractice}
            onChange={({ target: { value } }) => handleFilter({ opportunityPractice: value })}
            placeholder="Opportunity Practice"
          />
        )}

        {salesforceEnabled && (
          <YesNoFilter
            icon="filter"
            clearable
            placeholder="Salesforce Synced"
            value={filters.salesforceSynced}
            onChange={({ target: { value } }) => handleFilter({ salesforceSynced: value })}
          />
        )}

        {hubspotEnabled && (
          <YesNoFilter
            icon="filter"
            clearable
            placeholder="HubSpot Synced"
            value={filters.hubspotSynced}
            onChange={({ target: { value } }) => handleFilter({ hubspotSynced: value })}
          />
        )}
      </FiltersGroup>
    </FiltersDrawer>
  );
}
