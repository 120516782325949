import _ from 'lodash';
import React from 'react';
import {
  ProjectTaskFilter,
  ProjectTaskRecordStatusFilter,
  ProjectTaskStatusFilter,
  ProjectTaskTagFilter,
} from '~/components';
import FiltersGroup from './FiltersGroup';

export default function TaskFiltersGroup({ filters, onChange }) {
  return (
    <FiltersGroup
      label="Task Filters"
      filters={[
        filters.projectTasks,
        filters.projectTaskRecordStatusId,
        filters.projectTaskStatuses,
        filters.projectTaskTags,
      ]}>
      {_.has(filters, 'projectTasks') && filters.projects.length === 1 && (
        <ProjectTaskFilter
          project={filters.projects[0]}
          value={filters.projectTasks}
          onChange={({ target: { value } }) => onChange({ projectTasks: value })}
        />
      )}

      {_.has(filters, 'projectTaskStatuses') && (
        <ProjectTaskStatusFilter
          value={filters.projectTaskStatuses}
          onChange={({ target: { value } }) => onChange({ projectTaskStatuses: value })}
        />
      )}

      {_.has(filters, 'projectTaskRecordStatusId') && (
        <ProjectTaskRecordStatusFilter
          value={filters.projectTaskRecordStatusId}
          onChange={({ target: { value } }) => onChange({ projectTaskRecordStatusId: value })}
        />
      )}

      {_.has(filters, 'projectTaskTags') && (
        <ProjectTaskTagFilter
          value={filters.projectTaskTags}
          onChange={({ target: { value } }) => onChange({ projectTaskTags: value })}
        />
      )}
    </FiltersGroup>
  );
}
