import _ from 'lodash';
import { QueryString } from '~/utils';
import { get, post, del, put, patch } from './agent';
import purchaseOrderFiles from './purchaseOrderFiles';

const purchaseOrders =
  (workspacesUrl) =>
  (id = '') => {
    const url = _([workspacesUrl, 'purchase-orders', id]).compact().join('/');

    return {
      get: (query = {}) => get(url.concat(new QueryString(query).toString(true))),
      files: purchaseOrderFiles(url.concat('/files')),
      history: () => get(url.concat('/history')),
      hasDependencies: () => get(url.concat('/has-dependencies')),
      upsert: (body) => (id ? patch : post)(url, body),
      setActiveStatus: (flag = true) => put(url.concat('/is-active'), { isActive: flag }),
      delete: () => del(url),
    };
  };

export default purchaseOrders;
