const workspaceSettingsByCountry = {
  GB: { timeZoneName: 'Europe/London', currencyCode: 'GBP', locale: 'en-GB' },
  US: { timeZoneName: 'US/Eastern', currencyCode: 'USD', locale: 'en-US' },
  BE: { timeZoneName: 'Europe/Brussels', currencyCode: 'EUR', locale: 'nl-NL' },
  CA: { timeZoneName: 'Canada/Eastern', currencyCode: 'CAD', locale: 'en-CA' },
  AU: { timeZoneName: 'Australia/Sydney', currencyCode: 'AUD', locale: 'en-AU' },
  FR: { timeZoneName: 'Europe/Paris', currencyCode: 'EUR', locale: 'fr-FR' },
  DK: { timeZoneName: 'Europe/Copenhagen', currencyCode: 'DKK', locale: '' },
  DE: { timeZoneName: 'Europe/Berlin', currencyCode: 'EUR', locale: 'de-DE' },
  IT: { timeZoneName: 'Europe/Rome', currencyCode: 'EUR', locale: 'it-IT' },
  MX: { timeZoneName: 'America/Mexico_City', currencyCode: 'MXN', locale: 'es-MX' },
  NL: { timeZoneName: 'Europe/Amsterdam', currencyCode: 'EUR', locale: 'nl-NL' },
  NZ: { timeZoneName: 'Pacific/Auckland', currencyCode: 'NZD', locale: 'en-NZ' },
  PL: { timeZoneName: 'Europe/Warsaw', currencyCode: 'PLN', locale: '' },
  ZA: { timeZoneName: 'Africa/Johannesburg', currencyCode: 'ZAR', locale: '' },
  IE: { timeZoneName: 'Europe/Dublin', currencyCode: 'EUR', locale: 'en-IE' },
  CH: { timeZoneName: 'Europe/Zurich', currencyCode: 'CHF', locale: '' },
  UA: { timeZoneName: 'Europe/Kyiv', currencyCode: 'UAH', locale: '' },
  ES: { timeZoneName: 'Europe/Madrid', currencyCode: 'EUR', locale: 'es-ES' },
  SE: { timeZoneName: 'Europe/Stockholm', currencyCode: 'SEK', locale: 'sv-SE' },
  IN: { timeZoneName: 'Asia/Kolkata', currencyCode: 'INR', locale: 'en-IN' },
  JP: { timeZoneName: 'Asia/Tokyo', currencyCode: 'JPY', locale: 'ja-JP' },
  BR: { timeZoneName: 'Brazil/East', currencyCode: 'BRL', locale: 'pt-BR' },
  AR: { timeZoneName: 'America/Argentina/Buenos_Aires', currencyCode: 'ARS', locale: 'es-AR' },
  IL: { timeZoneName: 'Asia/Tel_Aviv', currencyCode: 'ILS', locale: '' },
  NO: { timeZoneName: 'Europe/Oslo', currencyCode: 'NOK', locale: '' },
  CL: { timeZoneName: 'Chile/Continental', currencyCode: 'CLP', locale: '' },
  FI: { timeZoneName: 'Europe/Helsinki', currencyCode: 'EUR', locale: '' },
  PT: { timeZoneName: 'Europe/Lisbon', currencyCode: 'EUR', locale: 'pt-PT' },
};

export default workspaceSettingsByCountry;
