import { Currency, Widget } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import React, { useCallback, useEffect, useState } from 'react';
import BudgetTooltip from '../components/BudgetTooltip';
import PastDueTooltip from '../components/PastDueTooltip';
import ForecastedBudgetTooltip from '../components/ForecastedBudgetTooltip';
import {
  Box,
  Boxes,
  ChartContainer,
  Label,
  LowerBoxes,
  Metric,
  SmallBox,
  UpperBox,
  Value,
  ValueContainer,
} from '../components/StyledComponents';
import ViewNav from '../components/ViewNav';
import ServicesRevenueByMonthChart from './ServicesRevenueByMonthChart';
import ServicesRevenueProgressChart from './ServicesRevenueProgressChart';

export default function ServicesRevenueProgress({ project, view, actualsThrough, onViewChange }) {
  const { workspace } = useWorkspace();

  const api = useApi();
  const [{ data, isReady }, setQuery] = useState({ data: null, isReady: false });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .projects(project.id)
        .dashboard()
        .progress.servicesRevenue({ actualsThrough });
      setQuery({ data, isReady: true });
    } catch (error) {
      setQuery({ data: null, isReady: true });
    }
  }, [workspace.id, project.id, api, actualsThrough]);

  useEffect(() => {
    fetchData();
  }, [fetchData, project]);

  const boxes = {
    budget: !!data?.budget,
    forecast: data?.forecast?.dates.length > 0,
    get any() {
      return this.budget || this.forecast;
    },
  };

  const currency = project.currency;

  return (
    <Widget loading={!isReady}>
      <Widget.Preview style={{ minHeight: '32.4rem' }} />
      <Widget.Content>
        <ViewNav view={view} onViewChange={onViewChange} />

        {data && (
          <>
            <ChartContainer>
              {data &&
                {
                  progress: () => <ServicesRevenueProgressChart project={project} data={data} />,
                  month: () => <ServicesRevenueByMonthChart project={project} data={data} />,
                }[view]()}
            </ChartContainer>

            <Boxes>
              {project.permissions.viewRevenue && project.isBillable && (
                <Box>
                  <UpperBox>
                    <ValueContainer>
                      <Value>
                        <Currency
                          value={data.actual.revenue}
                          currency={currency}
                          minimumFractionDigits={0}
                          maximumFractionDigits={0}
                        />
                      </Value>
                      {project.revenueRecognitionMethod === 'invoiced' &&
                        ['fixed', 'fixed_recurring'].includes(project.billingTypeId) && (
                          <PastDueTooltip value={data.pastDue} currency={project.currency} />
                        )}
                    </ValueContainer>
                    <Label>Services Revenue</Label>
                  </UpperBox>

                  {boxes.any && (
                    <LowerBoxes>
                      {boxes.budget && (
                        <SmallBox>
                          <Metric>
                            <Value>
                              <Currency
                                value={data.budget.revenue.estimated ?? 0}
                                currency={currency}
                                minimumFractionDigits={0}
                                maximumFractionDigits={0}
                              />
                            </Value>
                            <Label>Budget</Label>
                          </Metric>
                          <Metric status={data.budget.revenue.status}>
                            <BudgetTooltip value={data.budget.revenue.percent}>
                              <Value>
                                <Currency
                                  value={data.budget.revenue.remaining}
                                  currency={currency}
                                  minimumFractionDigits={0}
                                  maximumFractionDigits={0}
                                />
                              </Value>
                              <Label>Budget Left</Label>
                            </BudgetTooltip>
                          </Metric>
                        </SmallBox>
                      )}

                      {boxes.forecast && (
                        <SmallBox>
                          <Metric>
                            <Value>
                              <Currency
                                value={data.forecast.revenue.forecasted}
                                currency={currency}
                                minimumFractionDigits={0}
                                maximumFractionDigits={0}
                              />
                            </Value>
                            <Label>Forecast</Label>
                          </Metric>
                          <Metric status={data.forecast.revenue.status} data-testid="forecasted-left">
                            <ForecastedBudgetTooltip value={data.forecast.revenue} currency={currency}>
                              <Value>
                                <Currency
                                  value={data.forecast.revenue.left}
                                  currency={currency}
                                  minimumFractionDigits={0}
                                  maximumFractionDigits={0}
                                />
                              </Value>
                              <Label>Forecast Left</Label>
                            </ForecastedBudgetTooltip>
                          </Metric>
                        </SmallBox>
                      )}
                    </LowerBoxes>
                  )}
                </Box>
              )}

              {project.permissions.viewRates && project.isBillable && (
                <Box>
                  <UpperBox style={{ flex: 0 }}>
                    <Value>
                      <Currency value={data.actual.effectiveRate} currency={currency} />
                    </Value>
                    <Label>Effective Rate</Label>
                  </UpperBox>
                  {boxes.any && (
                    <LowerBoxes style={{ flex: 2 }}>
                      {boxes.budget && (
                        <SmallBox>
                          <Metric>
                            <Value>
                              <Currency value={data.budget.effectiveRate.estimated ?? 0} currency={currency} />
                            </Value>
                            <Label>Budget</Label>
                          </Metric>
                        </SmallBox>
                      )}

                      {boxes.forecast && (
                        <SmallBox>
                          <Metric status={data.forecast.effectiveRate.status} data-testid="forecasted">
                            <ForecastedBudgetTooltip
                              value={{
                                budgetLeft: data.forecast.effectiveRate.difference,
                                ...data.forecast.effectiveRate,
                              }}
                              currency={currency}>
                              <Value>
                                <Currency value={data.forecast.effectiveRate.forecasted} currency={currency} />
                              </Value>
                              <Label>Forecast</Label>
                            </ForecastedBudgetTooltip>
                          </Metric>
                        </SmallBox>
                      )}
                    </LowerBoxes>
                  )}
                </Box>
              )}
            </Boxes>
          </>
        )}
      </Widget.Content>
    </Widget>
  );
}
