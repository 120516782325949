import React, { useMemo } from 'react';
import { useAuth } from '~/hooks';
import invoiceStatuses from '~/lookups/invoice-statuses';
import MultiSelect from '../MultiSelect';

export default function InvoiceStatusFilter({ placeholder = 'Status', name, value, onChange }) {
  const auth = useAuth();

  const options = useMemo(() => {
    const statusOptions = [];

    if (auth.invoices.manage.project) {
      statusOptions.push(invoiceStatuses.draft);
    }

    statusOptions.push(invoiceStatuses.open);
    statusOptions.push(invoiceStatuses.paid);

    return statusOptions;
  }, [auth.invoices.manage.project]);

  return (
    <MultiSelect.Filter
      icon="filter"
      placeholder={placeholder}
      name={name}
      value={value}
      renderValue={(value) => value.map((v) => v.name).join('; ')}
      options={options}
      onChange={onChange}
    />
  );
}
