import { useWorkspace } from '~/contexts';
import _ from 'lodash';
import { useMemo } from 'react';

function useAuth() {
  const {
    workspace: {
      features,
      member,
      member: { securityRole },
    },
  } = useWorkspace();

  const permissions = useMemo(() => {
    return {
      workspace: { manage: securityRole.manageWorkspace },

      allocations: {
        projects: {
          view: !_.isEmpty(securityRole.viewProjectAllocations),
          manage: !_.isEmpty(securityRole.manageProjectAllocations),
        },

        members: {
          view: !_.isEmpty(securityRole.viewMemberAllocations),
          manage: !_.isEmpty(securityRole.manageMemberAllocations),
        },

        get view() {
          return this.projects.view || this.members.view;
        },

        get manage() {
          return this.projects.manage || this.members.manage;
        },
      },

      executiveDashboard: {
        servicesRevenue:
          securityRole.viewClients.includes('always') &&
          securityRole.viewClientProjects.includes('always') &&
          securityRole.viewProjectTimeAndExpenses.includes('always') &&
          securityRole.viewProjectAllocations.includes('always') &&
          securityRole.viewProjectRates.includes('always') &&
          securityRole.viewProjectRevenue.includes('always'),

        servicesGrossProfit:
          securityRole.viewClients.includes('always') &&
          securityRole.viewClientProjects.includes('always') &&
          securityRole.viewProjectTimeAndExpenses.includes('always') &&
          securityRole.viewProjectAllocations.includes('always') &&
          securityRole.viewProjectRates.includes('always') &&
          securityRole.viewProjectRevenue.includes('always') &&
          securityRole.viewProjectMargin.includes('always'),

        servicesGrossMargin:
          securityRole.viewClients.includes('always') &&
          securityRole.viewClientProjects.includes('always') &&
          securityRole.viewProjectTimeAndExpenses.includes('always') &&
          securityRole.viewProjectAllocations.includes('always') &&
          securityRole.viewProjectRates.includes('always') &&
          securityRole.viewProjectRevenue.includes('always') &&
          securityRole.viewProjectMargin.includes('always'),

        billableUtilization:
          securityRole.viewClients.includes('always') &&
          securityRole.viewClientProjects.includes('always') &&
          securityRole.viewProjectTimeAndExpenses.includes('always') &&
          securityRole.viewProjectAllocations.includes('always'),

        topClientsByServicesRevenue:
          securityRole.viewClients.includes('always') &&
          securityRole.viewClientProjects.includes('always') &&
          securityRole.viewProjectTimeAndExpenses.includes('always') &&
          securityRole.viewProjectAllocations.includes('always') &&
          securityRole.viewProjectRates.includes('always') &&
          securityRole.viewProjectRevenue.includes('always'),

        topClientsByBillableHours:
          securityRole.viewClients.includes('always') &&
          securityRole.viewClientProjects.includes('always') &&
          securityRole.viewProjectTimeAndExpenses.includes('always') &&
          securityRole.viewProjectAllocations.includes('always'),

        servicesEffectiveBillRate:
          securityRole.viewClients.includes('always') &&
          securityRole.viewClientProjects.includes('always') &&
          securityRole.viewProjectTimeAndExpenses.includes('always') &&
          securityRole.viewProjectAllocations.includes('always') &&
          securityRole.viewProjectRates.includes('always') &&
          securityRole.viewProjectRevenue.includes('always'),

        servicesRealizationRate:
          securityRole.viewClients.includes('always') &&
          securityRole.viewClientProjects.includes('always') &&
          securityRole.viewProjectTimeAndExpenses.includes('always') &&
          securityRole.viewProjectAllocations.includes('always'),

        clientBillableHours:
          securityRole.viewClients.includes('always') &&
          securityRole.viewClientProjects.includes('always') &&
          securityRole.viewProjectTimeAndExpenses.includes('always') &&
          securityRole.viewProjectAllocations.includes('always'),

        clientNonBillableHours:
          securityRole.viewClients.includes('always') &&
          securityRole.viewClientProjects.includes('always') &&
          securityRole.viewProjectTimeAndExpenses.includes('always') &&
          securityRole.viewProjectAllocations.includes('always'),

        mostHealthyProjects:
          features.projectHealth &&
          securityRole.viewClients.includes('always') &&
          securityRole.viewClientProjects.includes('always') &&
          securityRole.editProjects.includes('always'),

        leastHealthyProjects:
          features.projectHealth &&
          securityRole.viewClients.includes('always') &&
          securityRole.viewClientProjects.includes('always') &&
          securityRole.editProjects.includes('always'),

        pipelineByExpectedCloseDate: features.pipeline && securityRole.viewPipeline,

        get view() {
          return (
            this.servicesRevenue ||
            this.servicesGrossProfit ||
            this.servicesGrossMargin ||
            this.billableUtilization ||
            this.topClientsByServicesRevenue ||
            this.topClientsByBillableHours ||
            this.servicesEffectiveBillRate ||
            this.servicesRealizationRate ||
            this.clientBillableHours ||
            this.clientNonBillableHours ||
            this.mostHealthyProjects ||
            this.leastHealthyProjects ||
            this.pipelineByExpectedCloseDate
          );
        },
      },

      invoices: {
        manage: {
          client: !_.isEmpty(securityRole.manageDraftInvoices),
          project: !_.isEmpty(securityRole.manageProjectDraftInvoices),
        },
        view: {
          client: !_.isEmpty(securityRole.viewPublishedInvoices),
          project: !_.isEmpty(securityRole.viewProjectPublishedInvoices),
        },
      },

      payments: {
        view: !_.isEmpty(securityRole.viewPublishedInvoices),
        manage: !_.isEmpty(securityRole.managePublishedInvoices),
      },

      creditNotes: {
        view: !_.isEmpty(securityRole.viewPublishedInvoices),
        manage: !_.isEmpty(securityRole.managePublishedInvoices),
      },

      clients: {
        create: securityRole.createClients,
      },

      pipeline: {
        view: securityRole.viewPipeline,
        manage: securityRole.managePipeline,
      },

      projects: {
        create: securityRole.createProjects,

        viewRevenue: !_.isEmpty(securityRole.viewProjectRevenue),
        viewMargin: !_.isEmpty(securityRole.viewProjectMargin),

        viewBudgetHours: !_.isEmpty(securityRole.viewProjectTimeAndExpenses),
        viewBudgetRevenue: !_.isEmpty(securityRole.viewProjectRevenue) && !_.isEmpty(securityRole.viewProjectRates),

        viewHealth: !_.isEmpty(securityRole.editProjects),
      },

      purchaseOrders: {
        manage: features.purchaseOrders && !_.isEmpty(securityRole.manageDraftInvoices),
      },

      time: {
        approve:
          !_.isEmpty(securityRole.manageMemberTimeAndExpenses) ||
          !_.isEmpty(securityRole.manageProjectTimeAndExpenses) ||
          member.isTimeOffApprover,
      },

      members: {
        viewTimeAndExpenses: !_.isEmpty(securityRole.viewMemberTimeAndExpenses),
        manageTimeAndExpenses: !_.isEmpty(securityRole.manageMemberTimeAndExpenses),
      },

      expenses: {
        view: !_.isEmpty(securityRole.viewMemberTimeAndExpenses) || !_.isEmpty(securityRole.viewProjectTimeAndExpenses),
        approve:
          !_.isEmpty(securityRole.manageMemberTimeAndExpenses) || !_.isEmpty(securityRole.manageProjectTimeAndExpenses),
      },

      reports: {
        performanceByProject:
          !_.isEmpty(securityRole.viewProjectTimeAndExpenses) &&
          !_.isEmpty(securityRole.viewProjectRates) &&
          !_.isEmpty(securityRole.viewProjectRevenue),

        performanceByProjectAndMember:
          !_.isEmpty(securityRole.viewProjectTimeAndExpenses) &&
          !_.isEmpty(securityRole.viewProjectRates) &&
          !_.isEmpty(securityRole.viewProjectRevenue),

        performanceByProjectAndTask:
          !_.isEmpty(securityRole.viewProjectTimeAndExpenses) &&
          !_.isEmpty(securityRole.viewProjectRates) &&
          !_.isEmpty(securityRole.viewProjectRevenue),

        performanceByClient:
          securityRole.viewProjectTimeAndExpenses.includes('always') &&
          securityRole.viewProjectRates.includes('always') &&
          securityRole.viewProjectRevenue.includes('always'),

        performanceByClientPractice:
          features.practices &&
          securityRole.viewProjectTimeAndExpenses.includes('always') &&
          securityRole.viewProjectRates.includes('always') &&
          securityRole.viewProjectRevenue.includes('always'),

        performanceByProjectPractice:
          features.practices &&
          !_.isEmpty(securityRole.viewProjectTimeAndExpenses) &&
          !_.isEmpty(securityRole.viewProjectRates) &&
          !_.isEmpty(securityRole.viewProjectRevenue),

        performanceByProjectType:
          !_.isEmpty(securityRole.viewProjectTimeAndExpenses) &&
          !_.isEmpty(securityRole.viewProjectRates) &&
          !_.isEmpty(securityRole.viewProjectRevenue),

        performanceByMember:
          !_.isEmpty(securityRole.viewMemberTimeAndExpenses) &&
          securityRole.viewProjectRates.includes('always') &&
          securityRole.viewProjectRevenue.includes('always') &&
          securityRole.viewProjectMargin.includes('always'),

        performanceByMemberPractice:
          features.practices &&
          !_.isEmpty(securityRole.viewMemberTimeAndExpenses) &&
          securityRole.viewProjectRates.includes('always') &&
          securityRole.viewProjectRevenue.includes('always') &&
          securityRole.viewProjectMargin.includes('always'),

        hourlyFees:
          !_.isEmpty(securityRole.viewProjectTimeAndExpenses) &&
          !_.isEmpty(securityRole.viewProjectRates) &&
          !_.isEmpty(securityRole.viewProjectRevenue),

        fixedFeeRevenue:
          !_.isEmpty(securityRole.viewProjectTimeAndExpenses) && !_.isEmpty(securityRole.viewProjectRevenue),

        uninvoicedRevenue:
          !_.isEmpty(securityRole.viewProjectTimeAndExpenses) &&
          !_.isEmpty(securityRole.viewProjectRates) &&
          !_.isEmpty(securityRole.viewProjectRevenue),

        get financial() {
          return (
            this.performanceByMember ||
            this.performanceByProject ||
            this.performanceByProjectAndMember ||
            this.performanceByProjectAndTask ||
            this.performanceByClientPractice ||
            this.performanceByProjectPractice ||
            this.hourlyFees ||
            this.uninvoicedRevenue ||
            this.fixedFeeRevenue ||
            !_.isEmpty(securityRole.viewProjectPublishedInvoices) ||
            !_.isEmpty(securityRole.viewProjectRevenue)
          );
        },

        performanceForecastbyProject:
          features.forecast &&
          !_.isEmpty(securityRole.viewProjectTimeAndExpenses) &&
          !_.isEmpty(securityRole.viewProjectAllocations) &&
          !_.isEmpty(securityRole.viewProjectRates) &&
          !_.isEmpty(securityRole.viewProjectRevenue) &&
          !_.isEmpty(securityRole.viewProjectMargin),

        get forecast() {
          return this.performanceForecastbyProject;
        },

        hoursPlan: features.allocations && features.forecast,

        performancePlanByProject:
          features.forecast && !_.isEmpty(securityRole.viewProjectRates) && !_.isEmpty(securityRole.viewProjectRevenue),

        revenuePlan:
          features.forecast && !_.isEmpty(securityRole.viewProjectRates) && !_.isEmpty(securityRole.viewProjectRevenue),

        utilizationPlanByMember:
          features.allocations && features.forecast && !_.isEmpty(securityRole.viewMemberAllocations),

        utilizationPlanByMemberPractice:
          features.practices &&
          features.allocations &&
          features.forecast &&
          !_.isEmpty(securityRole.viewMemberAllocations),

        get plan() {
          return (
            this.hoursPlan ||
            this.performancePlanByProject ||
            this.revenuePlan ||
            this.utilizationPlanByMember ||
            this.utilizationPlanByMemberPractice
          );
        },

        hoursVarianceByMemberAndProject: features.allocations && features.forecast,

        hoursVarianceByProjectAndResource:
          features.allocations &&
          features.forecast &&
          !_.isEmpty(securityRole.viewProjectAllocations) &&
          !_.isEmpty(securityRole.viewProjectTimeAndExpenses),

        hoursVarianceByProjectAndRole:
          features.allocations &&
          features.forecast &&
          !_.isEmpty(securityRole.viewProjectAllocations) &&
          !_.isEmpty(securityRole.viewProjectTimeAndExpenses),

        get variance() {
          return (
            this.hoursVarianceByMemberAndProject ||
            this.hoursVarianceByProjectAndResource ||
            this.hoursVarianceByProjectAndRole
          );
        },

        get planAndForecast() {
          return this.plan || this.forecast || this.variance;
        },

        utilization: !_.isEmpty(securityRole.viewMemberTimeAndExpenses),

        projectHealthByProject: features.projectHealth && !_.isEmpty(securityRole.editProjects),

        pipeline: securityRole.viewPipeline && features.pipeline,
      },
    };
  }, [features, member.isTimeOffApprover, securityRole]);

  return permissions;
}

export default useAuth;
