import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  ActionButton,
  Buttons,
  CancelButton,
  Checkbox,
  DeleteButton,
  DeleteConfirmation,
  Drawer,
  Field,
  Form,
  FormMessage,
  InvoiceVariablesDialog,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDirtyCheck, useDocumentTitle, useForm } from '~/hooks';
import { emptyStringToNull, getFileBytes, mergeValues } from '~/utils';
import styled from 'styled-components';
import { colors } from '~/styles';

const StyledLink = styled.a``;

const VariablesContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;

  > a {
    margin-left: auto;
  }
`;

const ControlLabel = styled.p`
  display: flex;
  color: ${colors.grey75};

  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

const Checkboxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.375rem -0.75rem;

  > label {
    margin: 0.375rem 0.75rem;
  }
`;

const taxableItems = {
  services: 'Services',
  expenses: 'Expenses',
  other_items: 'Other Items to Bill',
};

function BusinessUnitDrawer({ businessUnit, onClose, onSaved, onDeleted }) {
  const title = `${businessUnit?.id ? 'Edit' : 'New'} Business Unit`;
  useDocumentTitle(title);

  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const [{ status, message, isSubmitting, saved }, form] = useForm();
  const invoiceSubjectRef = useRef(null);
  const invoiceNotesRef = useRef(null);
  const invoiceEmailSubjectRef = useRef(null);
  const invoiceEmailBodyRef = useRef(null);
  const api = useApi();
  const { workspace } = useWorkspace();
  const toast = useToast();
  const confirmation = useConfirmation();
  const [hasDependencies, setHasDependencies] = useState(true);

  useEffect(() => {
    if (!businessUnit?.id) {
      setHasDependencies(false);
      return;
    }

    (async function fetchData() {
      const dependencies = await api.www.workspaces(workspace.id).businessUnits(businessUnit.id).hasDependencies();
      setHasDependencies(dependencies.data);
    })();
  }, [api, businessUnit, workspace]);

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  const initialValues = mergeValues(
    {
      name: '',
      companyName: '',
      streetAddress: '',
      invoiceImage: null,
      invoiceImageUrl: '',
      invoiceSubject: '',
      invoiceNotes: '',
      invoiceEmailSubject: '',
      invoiceEmailBody: '',
      paymentTermsId: '',
      invoiceTaxRate: null,
      invoiceTaxableItems: [],
    },
    {
      companyName: workspace.companyName,
      streetAddress: workspace.streetAddress,
      invoiceImageUrl: workspace.invoiceImageUrl ?? workspace.imageUrl,
      invoiceSubject: workspace.invoiceSubject,
      invoiceNotes: workspace.invoiceNotes,
      invoiceEmailSubject: workspace.invoiceEmailSubject,
      invoiceEmailBody: workspace.invoiceEmailBody,
      paymentTermsId: workspace.paymentTermsId,
      invoiceTaxRate: workspace.invoiceTaxRate,
      invoiceTaxableItems: workspace.invoiceTaxableItems,
      ...businessUnit,
    },
  );

  return (
    <Drawer
      isOpen
      title={title}
      byline="Custom Data"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(values, { resetForm }) {
          try {
            form.submit(formRef.current.status.action);

            const body = emptyStringToNull({
              ..._.omit(values, 'invoiceImage', 'invoiceImageUrl', 'invoiceTaxRate'),
              invoiceTaxRateId: values.invoiceTaxRate?.id ?? null,
            });

            const { data } = await api.www.workspaces(workspace.id).businessUnits(businessUnit?.id).upsert(body);

            if (values.invoiceImage || values.invoiceImageUrl !== initialValues.invoiceImageUrl) {
              let file = null;
              if (values.invoiceImage) {
                const bytes = await getFileBytes(values.invoiceImage);
                const type = values.invoiceImage.type;
                file = { bytes, type };
              }

              await api.www
                .workspaces(workspace.id)
                .businessUnits(businessUnit?.id ?? data.id)
                .setInvoiceImage(file);
            } else if (
              values.invoiceImageUrl &&
              values.invoiceImageUrl === (workspace.invoiceImageUrl ?? workspace.imageUrl)
            ) {
              await api.www.workspaces(workspace.id).businessUnits(data.id).setDefaultInvoiceImage();
            }

            await onSaved(data);
            form.save(formRef.current.status.action);

            switch (formRef.current.status.action) {
              case 'new':
                resetForm();
                toast.success('Business Unit successfully saved.');
                break;
              case 'close':
                closeDrawer();
                break;
            }
            form.done();
          } catch ({ message }) {
            form.error({ message });
          }
        }

        async function handleDelete() {
          const confirm = await confirmation.prompt((resolve) => (
            <DeleteConfirmation resolve={resolve}>
              Are you sure that you want to delete this Business Unit?
            </DeleteConfirmation>
          ));
          if (!confirm) return;
          try {
            await api.www.workspaces(workspace.id).businessUnits(businessUnit.id).delete();
            await onDeleted();
            closeDrawer();
          } catch ({ message }) {
            toast.error(message);
          }
        }

        return (
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              name: Yup.string().label('Name').max(255).required(),
              companyName: Yup.string().label('Invoice Company Name').max(255).required(),
              streetAddress: Yup.string().label('Invoice Street Address').max(5000),
              invoiceImage: Yup.mixed().label('Invoice Logo'),
              invoiceSubject: Yup.string().label('Invoice Subject').max(255),
              invoiceNotes: Yup.string().label('Invoice Note').max(5000),
              invoiceEmailSubject: Yup.string().label('Invoice Email Subject').max(255),
              invoiceEmailBody: Yup.string().label('Invoice Email Body').max(5000),
              paymentTermsId: Yup.string().label('Invoice Payment Terms').required(),
            })}>
            {(formik) => {
              const submit = async (action) => {
                formik.setStatus({ action });
                formik.submitForm();
              };

              const getRefByName = (name) => {
                switch (name) {
                  case 'invoiceSubject':
                    return invoiceSubjectRef;
                  case 'invoiceNotes':
                    return invoiceNotesRef;
                  case 'invoiceEmailSubject':
                    return invoiceEmailSubjectRef;
                  case 'invoiceEmailBody':
                    return invoiceEmailBodyRef;
                  default:
                    return null;
                }
              };

              const handleOpenInvoiceVariablesDialog = async (name) => {
                await confirmation.prompt((resolve) => (
                  <InvoiceVariablesDialog
                    onClose={() => resolve(true)}
                    onSaved={(variables) => {
                      const ref = getRefByName(name);

                      let selectionStart = ref.current.selectionStart;
                      if (!formik.touched[name]) {
                        selectionStart = formik.values[name].length;
                      }

                      const updatedValue =
                        formik.values[name].slice(0, selectionStart) +
                        variables.join(' ') +
                        formik.values[name].slice(selectionStart);

                      formik.setFieldValue(name, updatedValue);
                      resolve(true);
                    }}
                  />
                ));
              };

              const handleTaxableItemsChange = (name) => {
                let fields;
                if (formik.values.invoiceTaxableItems.some((v) => v === name)) {
                  fields = formik.values.invoiceTaxableItems.filter((v) => v !== name);
                } else {
                  fields = [...formik.values.invoiceTaxableItems, name];
                }
                formik.setFieldValue('invoiceTaxableItems', fields);
              };

              return (
                <Form>
                  {status && <FormMessage.Error>{message}</FormMessage.Error>}

                  <Form.Section title="Details">
                    <Form.Control>
                      <Field.Text autoFocus name="name" placeholder="Name" maxLength={255} />
                    </Form.Control>

                    <Form.Control>
                      <Field.Text name="companyName" placeholder="Invoice Company Name" maxLength={255} />
                    </Form.Control>

                    <Form.Control>
                      <Field.TextArea
                        name="streetAddress"
                        placeholder="Invoice Street Address"
                        maxLength={5000}
                        rows={4}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.ImageFileInput name="invoiceImage" urlFieldName="invoiceImageUrl" label="Invoice Logo" />
                    </Form.Control>

                    <VariablesContainer>
                      <StyledLink onClick={() => handleOpenInvoiceVariablesDialog('invoiceSubject')}>
                        Insert Variables
                      </StyledLink>
                      <Form.Control>
                        <Field.Text
                          ref={invoiceSubjectRef}
                          name="invoiceSubject"
                          placeholder="Invoice Subject"
                          maxLength={255}
                          onChange={(e) => formik.setFieldValue('invoiceSubject', e.target.value)}
                        />
                      </Form.Control>
                    </VariablesContainer>

                    <VariablesContainer>
                      <StyledLink onClick={() => handleOpenInvoiceVariablesDialog('invoiceNotes')}>
                        Insert Variables
                      </StyledLink>
                      <Form.Control>
                        <Field.TextArea
                          ref={invoiceNotesRef}
                          name="invoiceNotes"
                          placeholder="Invoice Note"
                          maxLength={5000}
                          onChange={(e) => formik.setFieldValue('invoiceNotes', e.target.value)}
                        />
                      </Form.Control>
                    </VariablesContainer>

                    <VariablesContainer>
                      <StyledLink onClick={() => handleOpenInvoiceVariablesDialog('invoiceEmailSubject')}>
                        Insert Variables
                      </StyledLink>
                      <Form.Control>
                        <Field.Text
                          ref={invoiceEmailSubjectRef}
                          name="invoiceEmailSubject"
                          placeholder="Invoice Email Subject"
                          maxLength={255}
                          onChange={(e) => formik.setFieldValue('invoiceEmailSubject', e.target.value)}
                        />
                      </Form.Control>
                    </VariablesContainer>

                    <VariablesContainer>
                      <StyledLink onClick={() => handleOpenInvoiceVariablesDialog('invoiceEmailBody')}>
                        Insert Variables
                      </StyledLink>
                      <Form.Control>
                        <Field.TextArea
                          ref={invoiceEmailBodyRef}
                          name="invoiceEmailBody"
                          placeholder="Invoice Email Body"
                          maxLength={5000}
                          onChange={(e) => formik.setFieldValue('invoiceEmailBody', e.target.value)}
                        />
                      </Form.Control>
                    </VariablesContainer>

                    <Form.Control>
                      <Field.PaymentTermsSelect name="paymentTermsId" placeholder="Invoice Payment Terms" />
                    </Form.Control>

                    <Form.Control>
                      <Field.TaxRateSelect name="invoiceTaxRate" placeholder="Invoice Tax Rate" />
                    </Form.Control>

                    <ControlLabel>The following items are taxable by default:</ControlLabel>
                    <Form.Control>
                      <Checkboxes>
                        {_.map(taxableItems, (value, key) => (
                          <Checkbox
                            key={key}
                            label={value}
                            checked={formik.values.invoiceTaxableItems.some((v) => v === key)}
                            onChange={() => handleTaxableItemsChange(key)}
                          />
                        ))}
                      </Checkboxes>
                    </Form.Control>
                  </Form.Section>

                  <Drawer.Actions>
                    {businessUnit.id && !hasDependencies && <DeleteButton onClick={handleDelete}>Delete</DeleteButton>}

                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                      {!businessUnit?.id && (
                        <ActionButton
                          isLoading={isSubmitting === 'new'}
                          ok={saved === 'new'}
                          type="submit"
                          onClick={() => submit('new')}>
                          Save &amp; New
                        </ActionButton>
                      )}

                      <ActionButton
                        isLoading={isSubmitting === 'close'}
                        ok={saved === 'close'}
                        type="submit"
                        onClick={() => submit('close')}>
                        Save &amp; Close
                      </ActionButton>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}

export default BusinessUnitDrawer;
