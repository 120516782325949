import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';

const ActionsGutter = styled.div`
  flex-shrink: 0;
  height: 1rem;
`;

const Actions = styled.div`
  position: sticky;
  top: 0;
  left: -1rem;
  bottom: -1rem;
  margin-top: auto;
  margin-right: -1rem;
  margin-left: -1rem;
  margin-bottom: -1rem;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 1rem;
  background-color: ${colors.white};
  z-index: 50;

  &::before {
    content: '';
    position: absolute;
    top: -0.625rem;
    left: 0;
    right: 0;
    height: 0.625rem;
    background-image: linear-gradient(to top, ${rgba(colors.black, 0.1)}, ${rgba(colors.black, 0)});
  }

  gap: 0.75rem;
`;

export default function ActionsBanner(props) {
  return (
    <>
      <ActionsGutter />
      <Actions {...props} />
    </>
  );
}
