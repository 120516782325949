import { useFeatures } from '~/hooks';
import ExpenseDetail from './ExpenseDetail';
import ExpenseSummaryByClientAndProject from './ExpenseSummaryByClientAndProject';
import ExpenseSummaryByExpenseCategory from './ExpenseSummaryByExpenseCategory';
import ExpenseSummaryByMember from './ExpenseSummaryByMember';
import ExpenseSummaryByMemberPractice from './ExpenseSummaryByMemberPractice';
import ExpenseSummaryByProject from './ExpenseSummaryByProject';
import ExpenseSummaryByProjectType from './ExpenseSummaryByProjectType';

export default function useExpenseReports() {
  const features = useFeatures();

  return [
    {
      key: 'expense_items',
      name: 'Expense Items',
      description:
        'Individual expense items including the date, member, project, client, category, vendor, and approval status.',
      path: '/expense-items',
      component: ExpenseDetail,
    },
    {
      key: 'expense_summary_by_client_and_project',
      name: 'Expense Summary by Client and Project',
      description: 'A summary of expenses by client and project including billable and non-billable expenses.',
      path: '/expense-summary-by-client-and-project',
      component: ExpenseSummaryByClientAndProject,
    },
    {
      key: 'expense_summary_by_expense_category',
      name: 'Expense Summary by Expense Category',
      description: 'A summary of expenses by expense category including billable and non-billable expenses.',
      path: '/expense-summary-by-expense-category',
      component: ExpenseSummaryByExpenseCategory,
    },
    features.expenseReporting && {
      key: 'expense_summary_by_member',
      name: 'Expense Summary by Member',
      description: 'A summary of expenses by member including billable and non-billable expenses.',
      path: '/expense-summary-by-member',
      component: ExpenseSummaryByMember,
    },
    features.expenseReporting &&
      features.practices && {
        key: 'expense_summary_by_member_practice',
        name: 'Expense Summary by Member Practice',
        description: 'A summary of expenses by member practice including billable and non-billable expenses.',
        path: '/expense-summary-by-member-practice',
        component: ExpenseSummaryByMemberPractice,
      },
    {
      key: 'expense_summary_by_project',
      name: 'Expense Summary by Project',
      description: 'A summary of expenses by project including billable and non-billable expenses.',
      path: '/expense-summary-by-project',
      component: ExpenseSummaryByProject,
    },
    {
      key: 'expense_summary_by_project_type',
      name: 'Expense Summary by Project Type',
      description: 'A summary of expenses by project type including billable and non-billable expenses.',
      path: '/expense-summary-by-project-type',
      component: ExpenseSummaryByProjectType,
    },
  ].filter(Boolean);
}
