import _ from 'lodash';
import { QueryString } from '~/utils';
import { get } from './agent';

const projectDashboard = (projectDashboardUrl) => () => {
  const url = _([projectDashboardUrl]).compact().join('/');

  return {
    timesheets: (query = {}, config) =>
      get(url.concat('/timesheets').concat(new QueryString(query).toString(true)), config),
    progress: {
      myHours: (query = {}) => get(url.concat('/progress/my-hours').concat(new QueryString(query).toString(true))),
      hours: (query = {}) => get(url.concat('/progress/hours').concat(new QueryString(query).toString(true))),
      servicesRevenue: (query = {}) =>
        get(url.concat('/progress/services-revenue').concat(new QueryString(query).toString(true))),
      expensesRevenue: (query = {}) =>
        get(url.concat('/progress/expenses-revenue').concat(new QueryString(query).toString(true))),
      totalRevenue: (query = {}) =>
        get(url.concat('/progress/total-revenue').concat(new QueryString(query).toString(true))),
      servicesCost: (query = {}) =>
        get(url.concat('/progress/services-cost').concat(new QueryString(query).toString(true))),
      expensesCost: (query = {}) =>
        get(url.concat('/progress/expenses-cost').concat(new QueryString(query).toString(true))),
      totalCost: (query = {}) => get(url.concat('/progress/total-cost').concat(new QueryString(query).toString(true))),
      servicesProfit: (query = {}) =>
        get(url.concat('/progress/services-profit').concat(new QueryString(query).toString(true))),
      totalProfit: (query = {}) =>
        get(url.concat('/progress/total-profit').concat(new QueryString(query).toString(true))),
    },
    timeByMember: (query = {}, config = {}) =>
      get(url.concat('/time-by-member').concat(new QueryString(query, { multi: true }).toString(true)), config),
    timeByRole: (query = {}, config = {}) =>
      get(url.concat('/time-by-role').concat(new QueryString(query, { multi: true }).toString(true)), config),
    expensesByCategory: (query = {}, config = {}) =>
      get(url.concat('/expenses-by-category').concat(new QueryString(query, { multi: true }).toString(true)), config),
    allocations: (query = {}) => get(url.concat('/allocations').concat(new QueryString(query).toString(true))),
    team: (query = {}) => get(url.concat('/team').concat(new QueryString(query).toString(true))),
  };
};

export default projectDashboard;
