import { Button, Buttons, CancelButton, Drawer, Field, Form, FormMessage, Radio } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useForm } from '~/hooks';
import React, { useRef } from 'react';
import { emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';

export default function TimeOffSettings({ integration, onClose, onSave }) {
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const [{ status, message, isSubmitting }, form] = useForm();
  const api = useApi();
  const { workspace } = useWorkspace();

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  const initialValues = mergeValues(
    {
      timeOffStartDate: null,
      autoTimeOffTypeMapping: true,
      createTimeOffTypes: true,
      updateTimeOffNotes: true,
    },
    integration.settings,
  );

  return (
    <Drawer
      isOpen
      title="Time Off Settings"
      byline="BambooHR Integration"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(values) {
          try {
            form.submit();

            const body = emptyStringToNull(values);
            await api.www.workspaces(workspace.id).bamboohr.updateSettings(body);

            form.save();
            onSave();
            closeDrawer();
          } catch ({ message }) {
            form.error({ message });
          }
        }

        return (
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              timeOffStartDate: Yup.date().label('Import Start Date').required(),
              autoTimeOffTypeMapping: Yup.bool().label('Time Off Type Mapping').required(),
              createTimeOffTypes: Yup.bool().label('Create Time Off Types').required(),
              updateTimeOffNotes: Yup.bool().label('Update Time Off Notes').required(),
            })}>
            {(formik) => {
              return (
                <Form>
                  {status && <FormMessage.Error>{message}</FormMessage.Error>}
                  <Form.Section
                    title="Import Start Date"
                    subtitle="The first date to import time off requests from BambooHR.">
                    <Form.Control>
                      <Field.DayPicker name="timeOffStartDate" placeholder="Import Start Date" clearable={false} />
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Time Off Type Mapping"
                    subtitle="Choose the type of mapping you would like to use for time off types.">
                    <Form.Control>
                      <Field.RadioGroup name="autoTimeOffTypeMapping" direction="vertical">
                        <Radio value={true} label="Automatic - Map automatically by matching exactly on the name" />
                        <Radio value={false} label="Manual - Manually control the mapping" />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Create Time Off Types"
                    subtitle="Automatically create new time off types in Ruddr, if they do not already exist.">
                    <Form.Control>
                      <Field.RadioGroup name="createTimeOffTypes" direction="vertical">
                        <Radio value={true} label="On - Create new time off types if they do not exist" />
                        <Radio value={false} label="Off - Do not create new time off types" />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Update Time Off Notes"
                    subtitle="Sync the notes from BambooHR to Ruddr time off requests">
                    <Form.Control>
                      <Field.RadioGroup name="updateTimeOffNotes" direction="vertical">
                        <Radio value={true} label="On - Set and update the time off notes" />
                        <Radio value={false} label="Off - Do not update the time off notes" />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Drawer.Actions>
                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                      <Button isLoading={isSubmitting} onClick={formik.submitForm}>
                        Save &amp; Close
                      </Button>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}
