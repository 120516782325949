import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import { useTimer } from '~/contexts';
import { colors } from '~/styles';
import ApprovalStatus from './ApprovalStatus';
import ClientApprovalStatus from './ClientApprovalStatus';
import HoursSummary from './HoursSummary';
import Utilization from './Utilization';

const Container = styled.div`
  display: flex;
  margin: 2rem -1rem -1rem -1rem;
  padding: 1.75rem;
  background-color: ${colors.grey5};
`;

const Approvals = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

function CalendarStats({ entries: rawEntries, startDate, endDate, member, memberTargets }) {
  const { nowRef, subTimer, unsubTimer } = useTimer();
  const [, setRefresh] = useState({});

  const entries = _.cloneDeep(rawEntries);

  const timerEntry = _.find(entries, (entry) => !!entry.timerStartedAt);
  if (timerEntry) {
    timerEntry.minutes += Math.max(moment(nowRef.current).diff(timerEntry.timerStartedAt, 'minutes'), 0);
  }

  const hasTimerEntry = !!timerEntry;
  useEffect(() => {
    const id = hasTimerEntry ? subTimer(() => setRefresh({})) : null;
    return () => (id ? unsubTimer(id) : null);
  }, [hasTimerEntry, subTimer, unsubTimer]);

  const props = { entries, startDate, endDate, member, memberTargets };

  return (
    <Container>
      <HoursSummary {...props} />
      <Approvals>
        <ApprovalStatus {...props} />
        <ClientApprovalStatus {...props} />
      </Approvals>
      <Utilization {...props} />
    </Container>
  );
}

export default CalendarStats;
