import styled from 'styled-components';
import { colors } from '~/styles';

const Pill = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.5rem;
  min-height: 1.5rem;
  padding: 0.25rem 0.75rem;
  margin: 0.25rem;
  color: ${colors.white};
  font-size: 0.75rem;
  line-height: 1.2;
  background-color: ${colors.primary};
  border-radius: 999rem;
`;

const Pills = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.25rem;

  a:hover ${Pill} {
    transition: all 100ms ease-in-out;
    background: ${colors.accent};
  }
`;

export default Pill;
export { Pills };
