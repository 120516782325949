import {
  BillableIcon,
  DeleteConfirmation,
  ExportDialog,
  Hours,
  SearchInput,
  ExportDropdown,
  Page,
  BackLink,
  IconButton,
  FiltersBar,
  ListView,
  ListViewActions,
  ListViewMenu,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import styled from 'styled-components';
import { mimeTypes } from '~/utils';
import ProjectTaskTemplateDrawer from './ProjectTaskTemplateDrawer';

const StyledNotes = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25rem;
  white-space: nowrap;
`;

function ProjectTaskTemplatesPage() {
  const documentTitle = useDocumentTitle('Task Templates');

  const api = useApi();
  const { workspace } = useWorkspace();
  const confirmation = useConfirmation();
  const toast = useToast();

  const [query, setQuery] = useState({ isReady: false, data: null, error: null });
  const [params, setParams] = useState({ q: '', size: 1000 });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).projectTaskTemplates().get(params);
      setQuery({ isReady: true, data });
    } catch (error) {
      setQuery({ isReady: true, data: null, error });
    }
  }, [workspace.id, params, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [dialog, setDialog] = useState(null);

  const handleFilterChange = ({ target: { name, value } }) => {
    setParams((state) => ({ ...state, [name]: value }));
  };

  const handleExport = async (filename, mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onClose={resolve}
        onLoad={api.www
          .workspaces(workspace.id)
          .projectTaskTemplates()
          .get(params, { headers: { accept: mimeType }, responseType: 'blob' })}
      />
    ));
  };

  const handleEdit = async (template) => {
    setDialog(
      <ProjectTaskTemplateDrawer
        template={template}
        onClose={() => {
          setDialog(null);
          documentTitle.set('Task Templates');
        }}
        onDeleted={fetchData}
        onSaved={fetchData}
      />,
    );
  };

  const handleDelete = async (template) => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this Task Template?</DeleteConfirmation>
    ));
    if (!confirm) return;

    try {
      await api.www
        .workspaces(workspace.id)
        .projectTaskTemplates(template ? template.id : undefined)
        .delete();
      await fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  };

  if (!query.isReady) return <PageLoader />;
  if (!query.data) return <ErrorPage publicSite={false} />;

  const data = query.data;

  return (
    <>
      <Page.Header>
        <BackLink defaultPath={`/app/${workspace.key}/settings/custom-data`} />

        <Page.Info>
          <Page.Title>Task Templates</Page.Title>
          <Page.Description>Task templates allow standard tasks to be easily added to projects.</Page.Description>
        </Page.Info>

        <Page.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('task_templates.csv', mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('task_templates.xlsx', mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <IconButton icon="plus" tooltip="New Task Template" onClick={() => handleEdit({})} />
        </Page.Actions>
      </Page.Header>

      <Page.Filters>
        <FiltersBar>
          <SearchInput value={params.q} placeholder="Search" onChange={handleFilterChange} />
        </FiltersBar>
      </Page.Filters>

      <Page.ListView>
        <ListView>
          <ListView.Header>
            <ListView.Column width="3.5rem" />
            <ListView.Column width="20rem">Name</ListView.Column>
            <ListView.Column>Notes</ListView.Column>
            <ListView.Column align="right">Hours Budget</ListView.Column>
            <ListViewActions.Column />
          </ListView.Header>
          <ListView.Body>
            {data.map((template) => {
              const { id, name, budgetHours, notes, isBillable } = template;

              return (
                <ListView.Row onClick={() => handleEdit(template)} key={id}>
                  <ListView.Cell>
                    <BillableIcon value={isBillable} />
                  </ListView.Cell>
                  <ListView.Cell>{name}</ListView.Cell>
                  <ListView.Cell>
                    <StyledNotes>{notes}</StyledNotes>
                  </ListView.Cell>
                  <ListView.Cell>
                    <Hours value={budgetHours} />
                  </ListView.Cell>
                  <ListViewActions>
                    <ListViewActions.Edit
                      onClick={() => {
                        handleEdit(template);
                      }}
                    />

                    <hr />

                    <ListViewMenu>
                      {({ setIsOpen }) => (
                        <>
                          <ListViewMenu.Item
                            onClick={() => {
                              handleEdit(template);
                              setIsOpen(false);
                            }}>
                            Edit
                          </ListViewMenu.Item>

                          <ListViewMenu.DeleteItem
                            tooltip="This item is currently in use."
                            onClick={() => {
                              handleDelete(template);
                            }}>
                            Delete
                          </ListViewMenu.DeleteItem>
                        </>
                      )}
                    </ListViewMenu>
                  </ListViewActions>
                </ListView.Row>
              );
            })}

            {data.length === 0 && <ListView.Empty />}
          </ListView.Body>
          <ListView.Status total={data.length} label="Task Template" />
        </ListView>
      </Page.ListView>

      {dialog}
    </>
  );
}

export default ProjectTaskTemplatesPage;
