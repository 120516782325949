import { Field, HelpTooltip } from '~/components';
import React from 'react';
import styled from 'styled-components';

const Control = styled.div`
  display: flex;
  align-items: center;
`;

export default function CapExpensesCostCheckbox() {
  return (
    <Control>
      <Field.Checkbox name="capExpensesCost" label="Cap the billable and non-billable expenses cost" />

      <HelpTooltip
        message="Do not allow an expense item to be saved if it will cause the project to exceed its billable or non-billable expense cost budget."
        style={{ marginLeft: '.5rem' }}
      />
    </Control>
  );
}
