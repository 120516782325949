import hubspotIcon from '~/assets/public/hubspot-icon.svg';
import { Tooltip } from '~/components';
import { useIntegrations } from '~/contexts';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 1rem;
  height: 1rem;
  margin: 0 0.5rem 0 0.5rem;
  margin: ${({ spaceLeft }) => (spaceLeft ? '0 0.5rem 0 auto' : '0 0.5rem 0 0.5rem')};
`;

const Image = styled.img`
  min-width: 1rem;
  min-height: 1rem;
  width: 1rem;
`;

export default function HubspotIndicator({ message = 'Synced from HubSpot', spaceLeft = false }) {
  const integrations = useIntegrations();
  if (!integrations.hubspot) return null;

  return (
    <Wrapper spaceLeft={spaceLeft}>
      <Tooltip message={message}>
        <Image src={hubspotIcon} />
      </Tooltip>
    </Wrapper>
  );
}
