import React from 'react';
import SingleSelectFilter from '~/components/filters/SingleSelectFilter';

const options = [
  { id: 'active', name: 'Yes' },
  { id: 'inactive', name: 'No' },
];

export default function ResourceStatusFilter(props) {
  return (
    <SingleSelectFilter
      placeholder="Resource Active"
      renderValue={(value) => value.name}
      options={options}
      {...props}
    />
  );
}
