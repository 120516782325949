import _ from 'lodash';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  ActionButton,
  ActionsBanner,
  ButtonBadge,
  Checkbox,
  ExpenseLockIcon,
  ExportDialog,
  InlineTooltip,
  ListView,
  Page,
  SplitButton,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useActions, useDocumentTitle, useForm, useSearchParams, useSearchParamsConfig } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';
import { QuerySort, intervalOptions } from '~/utils';
import DeleteExpenseItemConfirmation from '../DeleteExpenseItemConfirmation';
import ExpenseFilters from '../ExpenseFilters';
import ExpenseItemDrawer from '../item/ExpenseItemDrawer';
import ExpenseAuditRow from './ExpenseAuditRow';

const StyledCheckbox = styled.div`
  > label > div {
    background: ${colors.white};
  }
`;

const initialState = {
  isReady: false,
  searchParamsStatus: 'pending',
  data: null,
  query: {
    q: '',
    statuses: [],
    page: 0,
    projects: [],
    members: [],
    memberPractices: [],
    expenseCategories: [],
    isReimbursed: null,
    reimbursableType: null,
    size: 100,
    period: null,
    sort: new QuerySort('date', 'desc'),
  },
  action: 'load',
};

const handlers = {
  load: (values, state) => ({ query: { ...state.query, page: 0 }, action: 'load' }),
  loadMore: (values, state) => {
    if (state.action === null && state.data.total > state.data.results?.length) {
      return { query: { ...state.query, page: state.query.page + 1 }, action: 'load-more' };
    }
  },
  ready: ({ data }, state) => ({
    isReady: true,
    dialog: null,
    action: null,
    data: state.action === 'load-more' ? { ...state.data, results: [...state.data.results, ...data.results] } : data,
  }),
  setParams: (params, state) => ({
    ...state,
    action: 'filter',
    query: { ...state.query, ...params, page: 0 },
    searchParamsStatus: 'ready',
  }),
  updateItem: (item, { data }) => ({
    data: {
      ...data,
      results: data.results.some((i) => i.id === item.id)
        ? data.results.map((i) => (i.id === item.id ? item : i))
        : [...data.results, item],
    },
  }),
  closeDeleteConfirmation: () => ({ dialog: null }),
  showDeleteConfirmation: ({ target }) => ({ dialog: 'delete', target }),
};

function ExpenseAuditingPage({ parentUrl }) {
  useDocumentTitle('Expense Auditing');

  const { expenseItemId, mode } = useParams();
  const drawer = expenseItemId ? 'open' : null;
  const api = useApi();
  const confirmation = useConfirmation();
  const toast = useToast();
  const history = useHistory();
  const location = useLocation();
  const { workspace } = useWorkspace();
  const [{ isReady, data, query, target, dialog, searchParamsStatus, action }, actions] = useActions(
    handlers,
    initialState,
  );
  const [{ isSubmitting, saved }, form] = useForm();
  const [selection, setSelection] = useState([]);

  const searchParamsConfig = useSearchParamsConfig();

  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: {
          ...searchParamsConfig.period,
          default: intervalOptions.past_90_days,
        },
        statuses: searchParamsConfig.approvalStatuses,
        sort: {
          default: initialState.query.sort,
          ...searchParamsConfig.sort,
        },
        projects: searchParamsConfig.projects,
        members: searchParamsConfig.members,
        memberPractices: searchParamsConfig.practices,
        isReimbursed: searchParamsConfig.isReimbursed,
        reimbursableType: searchParamsConfig.expenseReimbursableType,
        expenseCategories: searchParamsConfig.expenseCategories,
      }),
      [searchParamsConfig],
    ),

    sessionKey: 'expense_auditing',

    onChange: useCallback((params) => actions.setParams(params), [actions]),
  });

  useEffect(() => {
    if (searchParamsStatus !== 'pending') return;
    searchParams.get().then((params) => {
      if (params) actions.setParams(params);
    });
  }, [searchParams, searchParamsStatus, actions]);

  const urlSearchParams = useMemo(() => {
    const { start, end } = query.period || {};

    return {
      projectId: query.projects?.map((v) => v.id),
      memberId: query.members?.map((v) => v.id),
      memberPracticeId: query.memberPractices?.map((v) => v.id),
      statusId: query.statuses?.map((v) => v.id),
      isReimbursed: query.isReimbursed ?? undefined,
      reimbursableTypeId: query.reimbursableType ?? undefined,
      expenseCategoryId: query.expenseCategories?.map((v) => v.id),
      start: start ?? undefined,
      end: end ?? undefined,
      sort: query.sort,
    };
  }, [query]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .expenseAdmin()
        .auditing({ ...urlSearchParams, page: query.page, size: query.size });

      actions.ready({ data });
    } catch (error) {
      actions.ready({ data: [], members: [] });
    }
  }, [actions, workspace.id, query.page, query.size, urlSearchParams, api]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const handleFilterChange = (filter) => {
    actions.setParams(filter);
    setSelection([]);
    searchParams.set(filter);
  };

  const handleSelectionChange = (selection) => {
    setSelection(selection);
  };

  const handleBatchReimbursedStatusChange = async (isReimbursed) => {
    try {
      form.submit('batch');

      await api.www
        .workspaces(workspace.id)
        .expenseAdmin()
        .batchUpdateReimbursedStatus({ ids: selection.map(({ id }) => id), isReimbursed });

      toast.success(
        `${selection.length} expense ${pluralize('item', selection.length)} set as ${isReimbursed ? 'Reimbursed' : 'Not Reimbursed'}.`,
      );
      setSelection([]);
      actions.load();
      form.save();
    } catch (error) {
      toast.error(error.message);
      form.done();
    }
  };

  const handleSort = ({ column, sort }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setSelection([]);
    actions.setParams({ sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleDeleted = () => {
    setSelection([]);
    actions.load();
  };

  async function handleReimbursedStatusChange(item, flag) {
    await api.www.workspaces(workspace.id).expenseAdmin(item.id).setReimbursedStatus(flag);
    setSelection([]);
    actions.load();
  }

  if (!isReady && !data) return <PageLoader />;

  const handleSelectAllChange = () =>
    handleSelectionChange(
      selection.length > 0
        ? []
        : data.results.map((item) => {
            return {
              id: item.id,
              isReimbursable: item.isReimbursable,
              isReimbursed: item.isReimbursed,
            };
          }),
    );

  const handleExport = async (filename, mimeType) => {
    await confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .expenseAdmin()
          .exportAuditing(
            { ...urlSearchParams, size: null },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  return (
    <Page scrollable>
      <ExpenseFilters onChange={handleFilterChange} filters={query} onExport={handleExport} />

      <Page.ListView>
        <ListView>
          <ListView.Header>
            <ListView.Column sticky name="selector" width="3.5rem" align="center">
              <StyledCheckbox>
                <Checkbox
                  checked={selection.length > 0}
                  partial={selection.length < data.results.length}
                  disabled={isSubmitting || data.results.length === 0 || action === 'filter'}
                  onChange={handleSelectAllChange}
                />
              </StyledCheckbox>
            </ListView.Column>
            <ListView.Column name="selector" width="3.5rem" align="center">
              <ExpenseLockIcon />
            </ListView.Column>
            <ListView.Column name="date" width="7.5rem" sort={query.sort} onSort={handleSort}>
              Date
            </ListView.Column>
            <ListView.Column name="member" minWidth="12rem" sort={query.sort} onSort={handleSort}>
              Member
            </ListView.Column>
            <ListView.Column name="report" minWidth="16rem" sort={query.sort} onSort={handleSort}>
              Expense Report
            </ListView.Column>
            <ListView.Column name="category" minWidth="16rem" sort={query.sort} onSort={handleSort}>
              Category/Vendor
            </ListView.Column>
            <ListView.Column name="project" width="20rem" sort={query.sort} onSort={handleSort}>
              Project/Client
            </ListView.Column>
            <ListView.Column name="amount" width="12.5rem" align="right" sort={query.sort} onSort={handleSort}>
              Amount
            </ListView.Column>
            <ListView.Column name="selector" width="3rem"></ListView.Column>
          </ListView.Header>
          <ListView.Body fade={action === 'filter'}>
            {_.map(data.results, (item) => {
              return (
                <ExpenseAuditRow
                  key={item.id}
                  item={item}
                  onDelete={(target) => {
                    return actions.showDeleteConfirmation({ target });
                  }}
                  onReimbursedStatusChange={handleReimbursedStatusChange}
                  selection={selection}
                  onSelectionChange={handleSelectionChange}
                />
              );
            })}

            {data.results.length === 0 && <ListView.Empty />}

            {data.total > data.results.length && <ListView.Loader onIntersecting={actions.loadMore} />}
          </ListView.Body>

          <ListView.Status total={data.total} label="Expense Item" loading={!!action} />
        </ListView>
      </Page.ListView>

      {selection?.length > 0 && (
        <ActionsBanner>
          <SplitButton>
            <ActionButton
              disabled={!!selection.find((s) => s.isReimbursable === false)}
              isLoading={isSubmitting === 'batch'}
              style={{ position: 'relative' }}
              ok={saved}
              onClick={() => handleBatchReimbursedStatusChange(true)}>
              Set as Reimbursed
              {!!selection.find((s) => s.isReimbursable === false) && (
                <InlineTooltip message="Only reimbursable items can be set as reimbursed." />
              )}
              <ButtonBadge visible={!saved && isSubmitting !== 'batch'}>{selection.length}</ButtonBadge>
            </ActionButton>

            <SplitButton.Menu position="top">
              {({ setIsOpen }) => (
                <SplitButton.Item
                  onClick={() => setIsOpen(false) || handleBatchReimbursedStatusChange(false)}
                  disabled={selection.length === 0 || !!selection.find((s) => s.isReimbursable === false)}>
                  Set as Not Reimbursed
                  {!!selection.find((s) => s.isReimbursable === false) && (
                    <InlineTooltip message="Only reimbursable items can be set as not reimbursed." />
                  )}
                </SplitButton.Item>
              )}
            </SplitButton.Menu>
          </SplitButton>
        </ActionsBanner>
      )}
      {dialog === 'delete' && (
        <DeleteExpenseItemConfirmation
          id={target.expenseItemId}
          onClose={actions.closeDeleteConfirmation}
          onDelete={handleDeleted}
        />
      )}
      {drawer === 'open' && (
        <ExpenseItemDrawer
          overrideShowMember={true}
          mode={mode}
          onSaved={fetchData}
          onClose={() => history.push({ pathname: parentUrl, search: location.search, state: { scrollToTop: false } })}
        />
      )}
    </Page>
  );
}
export default ExpenseAuditingPage;
