import React, { useEffect, useState } from 'react';
import {
  AllocationBillableTypeFilter,
  BusinessUnitFilter,
  Checkbox,
  ClientFilter,
  ClientTagFilter,
  DisciplineFilter,
  EmploymentTypeFilter,
  IndustryFilter,
  JobTitleFilter,
  LocationFilter,
  MemberBillableTypeFilter,
  MemberFilter,
  MemberTagFilter,
  PracticeFilter,
  ProjectBillingTypeFilter,
  ProjectFilter,
  ProjectRecordStatusFilter,
  ProjectStatusFilter,
  ProjectTagFilter,
  ProjectTypeFilter,
  SkillFilter,
} from '~/components';
import FiltersGroup from '~/components/filters/FiltersGroup';
import { useFeatures } from '~/hooks';
import FiltersDrawer from '../../components/FiltersDrawer';
import ResourceStatusFilter from '../../member-schedule/filters/ResourceStatusFilter';
import ResourceTypeFilter from '../../member-schedule/filters/ResourceTypeFilter';

export default function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);
  const features = useFeatures();

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <Checkbox
        checked={filters.onlyAllocatedProjects}
        label="Only show projects with allocations"
        onChange={({ target: { checked } }) => handleFilter({ onlyAllocatedProjects: checked })}
      />

      <FiltersGroup
        label="Client Filters"
        filters={[
          filters.client,
          filters.clientOwner,
          filters.clientTags,
          filters.clientLocations,
          filters.clientIndustries,
          filters.clientBusinessUnits,
        ]}>
        <ClientFilter
          value={filters.client}
          onChange={({ target: { value } }) => handleFilter({ client: value, project: [] })}
        />

        <MemberFilter
          value={filters.clientOwner}
          placeholder="Client Relationship Owner"
          onChange={({ target: { value } }) => handleFilter({ clientOwner: value })}
        />

        <ClientTagFilter
          value={filters.clientTags}
          onChange={({ target: { value } }) => handleFilter({ clientTags: value })}
        />

        <LocationFilter
          value={filters.clientLocations}
          placeholder="Client Location"
          onChange={({ target: { value } }) => handleFilter({ clientLocations: value })}
        />

        <IndustryFilter
          value={filters.clientIndustries}
          placeholder="Client Industry"
          onChange={({ target: { value } }) => handleFilter({ clientIndustries: value })}
        />

        {features.businessUnits && (
          <BusinessUnitFilter
            value={filters.clientBusinessUnits}
            placeholder="Client Business Unit"
            onChange={({ target: { value } }) => handleFilter({ clientBusinessUnits: value })}
          />
        )}
      </FiltersGroup>

      <FiltersGroup
        label="Project Filters"
        filters={[
          filters.project,
          filters.projectAdmin,
          filters.billingType,
          filters.status,
          filters.recordStatusId,
          filters.projectTags,
          filters.projectPractice,
          filters.projectBusinessUnits,
          filters.projectTypes,
        ]}>
        <ProjectFilter
          clients={filters.client}
          value={filters.project}
          onChange={({ target: { value } }) => handleFilter({ project: value })}
        />

        <MemberFilter
          value={filters.projectAdmin}
          placeholder="Project Admin"
          onChange={({ target: { value } }) => handleFilter({ projectAdmin: value })}
        />

        <ProjectBillingTypeFilter
          value={filters.billingType}
          onChange={({ target: { value } }) => handleFilter({ billingType: value })}
        />

        <ProjectStatusFilter
          value={filters.status}
          onChange={({ target: { value } }) => handleFilter({ status: value })}
        />

        <ProjectRecordStatusFilter
          value={filters.recordStatusId}
          onChange={({ target: { value } }) => handleFilter({ recordStatusId: value })}
        />

        <ProjectTagFilter
          value={filters.projectTags}
          onChange={({ target: { value } }) => handleFilter({ projectTags: value })}
        />

        {features.practices && (
          <PracticeFilter
            placeholder="Project Practice"
            value={filters.projectPractice}
            onChange={({ target: { value } }) => handleFilter({ projectPractice: value })}
          />
        )}

        {features.businessUnits && (
          <BusinessUnitFilter
            value={filters.projectBusinessUnits}
            placeholder="Project Business Unit"
            onChange={({ target: { value } }) => handleFilter({ projectBusinessUnits: value })}
          />
        )}

        <ProjectTypeFilter
          value={filters.projectTypes}
          onChange={({ target: { value } }) => handleFilter({ projectTypes: value })}
        />
      </FiltersGroup>

      <FiltersGroup
        label="Member Filters"
        filters={[
          filters.member,
          filters.memberEmploymentType,
          filters.memberBillableTypeId,
          filters.jobTitles,
          filters.memberTags,
          filters.memberLocations,
        ]}>
        <MemberFilter
          value={filters.member}
          placeholder="Member"
          onChange={({ target: { value } }) => handleFilter({ member: value })}
        />

        <EmploymentTypeFilter
          value={filters.memberEmploymentType}
          onChange={({ target: { value } }) => handleFilter({ memberEmploymentType: value })}
        />

        <MemberBillableTypeFilter
          value={filters.memberBillableTypeId}
          onChange={({ target: { value } }) => handleFilter({ memberBillableTypeId: value })}
        />

        <JobTitleFilter
          value={filters.jobTitles}
          onChange={({ target: { value } }) => handleFilter({ jobTitles: value })}
        />

        <MemberTagFilter
          value={filters.memberTags}
          onChange={({ target: { value } }) => handleFilter({ memberTags: value })}
        />

        <LocationFilter
          value={filters.memberLocations}
          placeholder="Member Location"
          onChange={({ target: { value } }) => handleFilter({ memberLocations: value })}
        />
      </FiltersGroup>

      <FiltersGroup
        label="Resource Filters"
        filters={[
          filters.resourcePractice,
          filters.resourceDiscipline,
          filters.resourceSkill,
          filters.resourceLocation,
          filters.resourceStatusId,
          filters.resourceTypeId,
        ]}>
        {features.practices && (
          <PracticeFilter
            placeholder="Resource Practice"
            value={filters.resourcePractice}
            onChange={({ target: { value } }) => handleFilter({ resourcePractice: value })}
          />
        )}

        {features.disciplines && (
          <DisciplineFilter
            value={filters.resourceDiscipline}
            placeholder="Resource Discipline"
            onChange={({ target: { value } }) => handleFilter({ resourceDiscipline: value })}
          />
        )}

        <LocationFilter
          value={filters.resourceLocation}
          placeholder="Resource Location"
          onChange={({ target: { value } }) => handleFilter({ resourceLocation: value })}
        />

        <SkillFilter
          value={filters.resourceSkill}
          placeholder="Resource Skills"
          onChange={({ target: { value } }) => handleFilter({ resourceSkill: value })}
        />

        <ResourceStatusFilter
          value={filters.resourceStatusId}
          onChange={({ target: { value } }) => handleFilter({ resourceStatusId: value })}
        />

        <ResourceTypeFilter
          value={filters.resourceTypeId}
          onChange={({ target: { value } }) => handleFilter({ resourceTypeId: value })}
        />
      </FiltersGroup>

      <FiltersGroup label="Allocation Filters" filters={[filters.allocationBillableTypes]}>
        <AllocationBillableTypeFilter
          value={filters.allocationBillableTypes}
          onChange={({ target: { value } }) => handleFilter({ allocationBillableTypes: value })}
        />
      </FiltersGroup>
    </FiltersDrawer>
  );
}
