import React, { useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { DateTime, DeleteConfirmation, ListView, ListViewActions, ListViewMenu } from '~/components';
import { useConfirmation } from '~/contexts';
import { capitalize, dateFormats } from '~/utils';
import { HolidaySchedules } from '../custom-data/components';

function DaysFromNow({ date }) {
  const now = moment().format(dateFormats.isoDate);
  const then = moment(date);
  if (!date || !then.isValid()) {
    return '';
  }
  const diff = then.diff(now, 'days');
  if (Math.abs(diff) > 1) {
    return capitalize(then.from(now));
  } else if (diff === -1) {
    return 'Yesterday';
  } else if (diff === 1) {
    return 'Tomorrow';
  } else {
    return 'Today';
  }
}

function HolidayRow({ holiday, onActiveStatusChange, onDelete, onEdit }) {
  const confirmation = useConfirmation();

  const nextOccurrence = useMemo(() => {
    const today = moment().format(dateFormats.isoDate);
    const futureDates = _(holiday?.occurrences || [])
      .map((occurrence) => moment(occurrence.date).format(dateFormats.isoDate))
      .filter((date) => moment(date).isSameOrAfter(today, 'day'))
      .sort()
      .value();
    if (futureDates.length <= 0) {
      return null;
    }
    return futureDates[0];
  }, [holiday]);

  const handleDelete = async () => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this holiday?</DeleteConfirmation>
    ));
    if (!confirm) {
      return;
    }
    onDelete(holiday);
  };

  return (
    <ListView.Row isDisabled={!holiday.isActive} onClick={onEdit}>
      <ListView.Cell>{holiday.name}</ListView.Cell>
      <ListView.Cell>
        <HolidaySchedules holiday={holiday} />
      </ListView.Cell>
      <ListView.Cell>
        {nextOccurrence ? (
          <>
            <DaysFromNow date={nextOccurrence} />
            {' ('}
            <DateTime value={nextOccurrence} format="dddd, MMMM D, YYYY" />
            {')'}
          </>
        ) : (
          'No future date set'
        )}
      </ListView.Cell>
      <ListViewActions>
        {holiday.workspaceId ? (
          <>
            <ListViewActions.Edit onClick={onEdit} />
            <hr />
            <ListViewMenu>
              {({ setIsOpen }) => (
                <>
                  <ListViewMenu.Item onClick={onEdit}>Edit</ListViewMenu.Item>
                  <ListViewMenu.Item
                    onClick={async () => {
                      await onActiveStatusChange(holiday, !holiday.isActive);
                      setIsOpen(false);
                    }}>
                    {holiday.isActive ? 'Deactivate' : 'Activate'}
                  </ListViewMenu.Item>
                  <ListViewMenu.DeleteItem
                    tooltip="This item is currently in use."
                    onCheckDependencies={async (workspace) =>
                      (await workspace.holidays(holiday.id).hasDependencies()).data
                    }
                    onClick={handleDelete}>
                    Delete
                  </ListViewMenu.DeleteItem>
                </>
              )}
            </ListViewMenu>
          </>
        ) : (
          <ListViewActions.View onClick={onEdit} />
        )}
      </ListViewActions>
    </ListView.Row>
  );
}

export default HolidayRow;
