import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ClientLink, Currency, DateTime, Table as DetailTable, Icon, RouteLink, ExportDropdown } from '~/components';
import { useApi, useConfirmation, useIntegrations, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useNumberFormat, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import invoiceStatuses from '~/lookups/invoice-statuses';
import QBOIndicator from '~/routes/app/invoices/components/QBOIndicator';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort, intervalOptions } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersBar from '~/components/filters/ClientFiltersBar';
import ClientFiltersGroup from '~/components/filters/ClientFiltersGroup';
import CurrencyFilter from '~/components/filters/CurrencyFilter';
import ExportDialogAsync from '../components/ExportDialogAsync';
import FilterButton from '~/components/filters/FilterButton';
import IIFExportDialogAsync from '../components/IIFExportDialogAsync';
import InvoiceFiltersBar from '~/components/filters/InvoiceFiltersBar';
import InvoiceFiltersGroup from '~/components/filters/InvoiceFiltersGroup';
import ProjectFiltersBar from '~/components/filters/ProjectFiltersBar';
import ProjectFiltersGroup from '~/components/filters/ProjectFiltersGroup';
import Report from '../components/Report';
import ReportPeriodFilter from '~/components/filters/ReportPeriodFilter';
import Table from '../components/table';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import SavedReportActions from '../SavedReportActions';

const Byline = styled.small`
  display: block;
`;

export default function InvoiceDetail({ report }) {
  useDocumentTitle(report.name);
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();

  const [params, setParams] = useState({
    period: null,
    invoiceServicesThroughPeriod: null,
    statuses: [invoiceStatuses.open],
    invoiceSent: null,
    currency: workspace.currency,
    sort: new QuerySort('client.name', 'asc'),
    ...clientFilters.filters,
    ...projectFilters.filters,
  });

  // Init and sync URL search params
  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: { ...reportsSearchParamsConfig.period, default: intervalOptions.all_dates },
        invoiceServicesThroughPeriod: reportsSearchParamsConfig.invoiceServicesThroughPeriod,
        statuses: {
          multi: true,
          key: 'invoiceStatus',
          default: [invoiceStatuses.open],
          valid: ['all', ..._.map(invoiceStatuses, (v) => v.id)],
          serialize: function (value) {
            return !!this.default && value.length === 0 ? ['all'] : value?.map((v) => v.id);
          },
          deserialize: (ids) => (ids.length === 1 && ids[0] === 'all' ? [] : ids.map((id) => invoiceStatuses[id])),
        },
        invoiceSent: {
          valid: ['all', 'yes', 'no'],
          serialize: function (value) {
            return this.default && !value ? 'all' : value;
          },
          deserialize: (value) => (value === 'all' ? null : value),
        },
        currency: searchParamsConfig.currency,
        sort: { default: new QuerySort('client.name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      invoiceServicesThroughStart: params.invoiceServicesThroughPeriod?.start ?? undefined,
      invoiceServicesThroughEnd: params.invoiceServicesThroughPeriod?.end ?? undefined,
      statusId: params.statuses.map((v) => v.id),
      invoiceSent: params.invoiceSent ?? undefined,
      includeLines: true,
      currency: params.currency ?? undefined,
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
    }),
    [params, clientFilters, projectFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().invoiceDetail(urlSearchParams);
    setQuery((state) => ({
      ...state,
      data,
      status: 'ready',
      loading: {
        table: false,
      },
    }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, table: true } }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleCurrencyChange = (currency) => {
    setQuery((state) => ({ ...state, loading: { ...state.loading, summary: true, table: true } }));
    setParams({ ...params, page: 0, currency });
    searchParams.set({ currency });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .invoiceDetail(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  const handleIIFExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <IIFExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .invoiceDetail(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  const integrations = useIntegrations();

  return (
    <Report>
      <Report.Header>
        <Report.Info report={report} />

        <Report.Actions>
          <SavedReportActions report={report} onSave={() => searchParams.set(params)} />
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>

                {integrations.qbd ? (
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleIIFExport(mimeTypes.iif);
                      setIsOpen(false);
                    }}>
                    Export to IIF
                  </ExportDropdown.Item>
                ) : null}
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} data-testid="filters_button" />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <ReportPeriodFilter
          value={params.period}
          onChange={({ target: { value } }) => handleApplyFilters({ period: value })}
        />
        {features.multicurrency && (
          <CurrencyFilter value={params.currency} onChange={({ target: { value } }) => handleCurrencyChange(value)} />
        )}
        <ClientFiltersBar filters={params} onChange={handleApplyFilters} />
        <ProjectFiltersBar filters={params} onChange={handleApplyFilters} />
        <InvoiceFiltersBar filters={params} onChange={handleApplyFilters} />
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} onCurrencyChange={handleCurrencyChange} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const report = query.data;
  const { workspace } = useWorkspace();

  const quantityFormat = useNumberFormat({ minimumFractionDigits: 0 });

  return (
    <Report.Table>
      <Table>
        <Table.Header>
          <Table.Column width="12rem" name="invoice.transactionNumber" onSort={onSort} sort={params.sort}>
            #
          </Table.Column>
          <Table.Column minWidth="16rem" name="client.name" onSort={onSort} sort={params.sort}>
            Client
          </Table.Column>
          <Table.Column width="8rem" align="right" name="invoice.issuedOn" onSort={onSort} sort={params.sort}>
            Issued
          </Table.Column>
          <Table.Column width="8rem" align="right" name="invoice.dueOn" onSort={onSort} sort={params.sort}>
            Due
          </Table.Column>
          <Table.Column width="6rem" name="actualStatusId" onSort={onSort} sort={params.sort}>
            Status
          </Table.Column>
          <Table.Column width="10rem" align="right" name="convertedTax" onSort={onSort} sort={params.sort}>
            Tax
          </Table.Column>
          <Table.Column width="10rem" align="right" name="convertedDiscountAmount" onSort={onSort} sort={params.sort}>
            Discount
          </Table.Column>
          <Table.Column width="10rem" align="right" name="convertedTotal" onSort={onSort} sort={params.sort}>
            Total
          </Table.Column>
        </Table.Header>

        <Table.Body fade={query.loading.table}>
          {report.records.map((invoice) => {
            return (
              <React.Fragment key={invoice.id}>
                <Table.GroupRow top="3rem">
                  <Table.Cell>
                    <RouteLink to={`/app/${workspace.key}/billing/invoices/${invoice.id}`}>{invoice.number}</RouteLink>
                  </Table.Cell>
                  <Table.Cell>
                    <ClientLink client={invoice.client} />
                  </Table.Cell>
                  <Table.Cell>
                    <DateTime value={invoice.issuedOn} />
                  </Table.Cell>
                  <Table.Cell>
                    <DateTime value={invoice.dueOn} />
                  </Table.Cell>
                  <Table.Cell>
                    {invoice.status.name}
                    {invoice.qboInvoiceId && <QBOIndicator />}
                  </Table.Cell>
                  <Table.Cell>
                    <p>
                      <Currency value={invoice.convertedTax} currency={report.currency} />
                      {invoice.currency !== report.currency && (
                        <Byline>
                          <Currency value={invoice.tax} currency={invoice.currency} />
                        </Byline>
                      )}
                    </p>
                  </Table.Cell>
                  <Table.Cell>
                    <p>
                      <Currency value={invoice.convertedDiscountAmount} currency={report.currency} />
                      {invoice.discountAmount !== null && invoice.currency !== report.currency && (
                        <Byline>
                          <Currency value={invoice.discountAmount} currency={invoice.currency} />
                        </Byline>
                      )}
                    </p>
                  </Table.Cell>
                  <Table.Cell>
                    <p>
                      <Currency value={invoice.convertedTotal} currency={report.currency} />
                      {invoice.currency !== report.currency && (
                        <Byline>
                          <Currency value={invoice.total} currency={invoice.currency} />
                        </Byline>
                      )}
                    </p>
                  </Table.Cell>
                </Table.GroupRow>

                {invoice.lines.length > 0 && (
                  <DetailTable style={{ fontSize: '.75rem' }}>
                    <DetailTable.Header style={{ background: colors.white }}>
                      <DetailTable.Column width="16rem">Item</DetailTable.Column>
                      <DetailTable.Column minWidth="20rem">Details</DetailTable.Column>
                      <DetailTable.Column align="center" width="5.5rem">
                        Taxable
                      </DetailTable.Column>
                      <DetailTable.Column align="right" width="7.5rem">
                        Quantity
                      </DetailTable.Column>
                      <DetailTable.Column align="right" width="7.5rem">
                        Rate
                      </DetailTable.Column>
                      <DetailTable.Column align="right" width="10rem">
                        Amount
                      </DetailTable.Column>
                    </DetailTable.Header>

                    <DetailTable.Body>
                      {invoice.lines.map((line) => {
                        return (
                          <DetailTable.Row key={line.id}>
                            <DetailTable.Cell>{line.invoiceItem?.name}</DetailTable.Cell>
                            <DetailTable.Cell>{line.description}</DetailTable.Cell>
                            <DetailTable.Cell>{line.taxable && <Icon icon="check" />}</DetailTable.Cell>
                            <DetailTable.Cell>
                              {line.quantity ? quantityFormat.format(line.quantity) : ''}
                            </DetailTable.Cell>
                            <DetailTable.Cell>
                              <p>
                                <Currency
                                  value={line.convertedRate}
                                  currency={report.currency}
                                  maximumFractionDigits={7}
                                />
                                {line.convertedRate != null && invoice.currency !== report.currency && (
                                  <Byline>
                                    <Currency value={line.rate} currency={invoice.currency} maximumFractionDigits={7} />
                                  </Byline>
                                )}
                              </p>
                            </DetailTable.Cell>
                            <DetailTable.Cell>
                              <p>
                                <Currency value={line.convertedAmount} currency={report.currency} />
                                {line.convertedAmount != null && invoice.currency !== report.currency && (
                                  <Byline>
                                    <Currency value={line.amount} currency={invoice.currency} />
                                  </Byline>
                                )}
                              </p>
                            </DetailTable.Cell>
                          </DetailTable.Row>
                        );
                      })}
                    </DetailTable.Body>
                  </DetailTable>
                )}
              </React.Fragment>
            );
          })}

          <Table.Row style={{ fontWeight: weights.bold }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell>
              <Currency value={report.totals.total} currency={report.currency} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Table.Status
        total={report.records.length}
        label="Invoice"
        isLoading={query.status !== 'ready'}
        partial={report.partialResults}
      />
    </Report.Table>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ReportPeriodFilter
        value={filters.period}
        onChange={({ target: { value } }) => handleFilter({ period: value })}
      />

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <InvoiceFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}
