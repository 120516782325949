import React, { useCallback, useEffect, useState } from 'react';
import { AutoComplete, Dropdown } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';

export default function CreditNotePurchaseOrderSelect({ creditNote, currency, value, initialValue, ...props }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [defaultOptions, setDefaultOptions] = useState([]);

  const handleSearch = useCallback(
    async (q) => {
      const { data } = await api.www
        .workspaces(workspace.id)
        .creditNotes(creditNote.id)
        .purchaseOrders({
          q,
          currency,
          include: initialValue ? initialValue.id : undefined,
        });

      return data;
    },
    [workspace.id, creditNote.id, initialValue, currency, api],
  );

  const refreshDefaultOptions = useCallback(async () => {
    const data = await handleSearch('');
    if (!isMounted.current) return;
    setDefaultOptions(data);
  }, [handleSearch, isMounted]);

  useEffect(() => {
    refreshDefaultOptions();
  }, [refreshDefaultOptions]);

  return (
    <AutoComplete
      onSearch={handleSearch}
      value={value}
      displayText={value ? `${value.name} (PO #${value.number})` : ''}
      defaultOptions={defaultOptions}
      renderOption={(option) => `${option.name} (PO #${option.number})`}
      placeholder="Purchase Order"
      {...props}>
      {({ options }) => {
        let index = 0;

        return options.length > 0 ? (
          options.map((o) => {
            return (
              <AutoComplete.Option padding="0.25rem 0 0.25rem 2rem" key={o.id} value={o} index={index++}>
                {o.name} (PO #{o.number})
              </AutoComplete.Option>
            );
          })
        ) : (
          <Dropdown.Text>None</Dropdown.Text>
        );
      }}
    </AutoComplete>
  );
}
