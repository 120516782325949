import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Button, Buttons, DateTime, DeleteButton, Icon, Tooltip } from '~/components';
import { colors, weights } from '~/styles';
import { useToast } from '~/contexts';
import { useIsMounted } from '~/hooks';

import DeleteDataConfirmation from './components/DeleteDataConfirmation';
import ManualSync from './components/ManualSync';
import MemberMapping from './components/MemberMapping';
import MemberPreview from './components/MemberPreview';
import MemberSettings from './components/MemberSettings';
import SyncResults from './components/SyncResults';
import SyncSettings from './components/SyncSettings';
import TimeOffSettings from './components/TimeOffSettings';
import TimeOffTypeMapping from './components/TimeOffTypeMapping';
import TimeOffTypePreview from './components/TimeOffTypePreview';
import ReauthorizeConfirmation from './components/ReauthorizeConfirmation';

const REFRESH_DELAY_SECONDS = 5;

const Blocks = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 64rem;
  margin: -1rem;
  margin-top: 1rem;
`;

const Block = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: calc(50% - 2rem);
  margin: 1rem;
  padding: 1.5rem;
  background-color: ${colors.white};
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
  border-radius: 0.3125rem;
`;

const BlockContainer = styled(Block)`
  box-shadow: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: auto;

  & > div {
    width: 100%;
    margin: 0 0 2rem 0;
    flex-shrink: 0;

    &:last-child {
      margin-bottom: 0;
      flex-grow: 1;
      min-height: min-content;
    }
  }
`;

const FullBlock = styled(Block)`
  width: calc(100% - 2rem);
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: ${weights.light};
`;

const RefreshButton = styled.div`
  margin-left: auto;
  padding-left: 1rem;
  color: ${({ disabled }) => (disabled ? colors.grey25 : colors.black)};
  cursor: pointer;
`;

const Actions = styled.div`
  margin-top: auto;
  padding-top: 1rem;
`;

const Table = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.grey10};
  }
`;

const StackedButtons = styled(Buttons)`
  flex-direction: column;
  align-items: flex-start;

  > button:not(.is-fullwidth) {
    margin-top: 2rem;
  }
`;

const Attribute = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
`;

const Value = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  font-weight: ${weights.bold};
`;

export default function RipplingSettings({ integration, reloadIntegration }) {
  const [forceRefreshSyncsDate, setForceRefreshSyncsDate] = useState(moment().format());
  const [showDeleteData, setShowDeleteData] = useState(false);
  const [showReauthorize, setShowReauthorize] = useState(false);
  const [showManualSync, setShowManualSync] = useState(false);
  const [showMemberMapping, setShowMemberMapping] = useState(false);
  const [showMemberPreview, setShowMemberPreview] = useState(false);
  const [showMemberSettings, setShowMemberSettings] = useState(false);
  const [showSyncSettings, setShowSyncSettings] = useState(false);
  const [showTimeOffTypeMapping, setShowTimeOffTypeMapping] = useState(false);
  const [showTimeOffTypePreview, setShowTimeOffTypePreview] = useState(false);
  const [showTimeOffSettings, setShowTimeOffSettings] = useState(false);
  const [canRefresh, setCanRefresh] = useState(true);

  const toast = useToast();
  const isMounted = useIsMounted();

  const reloadIntegrationWithDelay = () => {
    if (!canRefresh) {
      return;
    }
    setCanRefresh(false);
    reloadIntegration();
    setTimeout(() => {
      if (isMounted.current) {
        setCanRefresh(true);
      }
    }, REFRESH_DELAY_SECONDS * 1000);
  };

  function handleSSOSetup() {
    window.open(integration.ssoUri, '_blank', 'noreferrer');
  }

  function handleApiError() {
    toast.error('An error occurred. Please verify your Rippling authentication and try again.');
  }

  return (
    <>
      <Blocks>
        <Block>
          <Title>Member Settings</Title>
          <Table>
            <Row>
              <Attribute>Invite Created Members</Attribute>
              <Value>{integration.settings.inviteMembers ? 'On' : 'Off'}</Value>
            </Row>
            <Row>
              <Attribute>Create Billable Members</Attribute>
              <Value>{integration.settings.setBillable ? 'On' : 'Off'}</Value>
            </Row>
            <Row>
              <Attribute>Update Member Status</Attribute>
              <Value>{integration.settings.updateMemberStatus ? 'On' : 'Off'}</Value>
            </Row>
            <Row>
              <Attribute>Update Active Dates</Attribute>
              <Value>{integration.settings.updateMemberActiveDates ? 'On' : 'Off'}</Value>
            </Row>
            <Row>
              <Attribute>Update Name</Attribute>
              <Value>{integration.settings.updateMemberName ? 'On' : 'Off'}</Value>
            </Row>
            <Row>
              <Attribute>Update Email</Attribute>
              <Value>{integration.settings.updateMemberEmail ? 'On' : 'Off'}</Value>
            </Row>
            <Row>
              <Attribute>Update Job Title</Attribute>
              <Value>{integration.settings.updateMemberJobTitle ? 'On' : 'Off'}</Value>
            </Row>
            <Row>
              <Attribute>Update Manager</Attribute>
              <Value>{integration.settings.updateMemberManager ? 'On' : 'Off'}</Value>
            </Row>
            <Row>
              <Attribute>Update Internal ID</Attribute>
              <Value>{integration.settings.updateMemberInternalId ? 'On' : 'Off'}</Value>
            </Row>
            <Row>
              <Attribute>Member Mapping</Attribute>
              <Value>{integration.settings.autoMemberMapping ? 'Automatic' : 'Manual'}</Value>
            </Row>
          </Table>
          <Actions>
            <Buttons>
              <Button onClick={() => setShowMemberSettings(true)}>Change Settings</Button>
              {integration.settings.autoMemberMapping ? (
                <Button onClick={() => setShowMemberPreview(true)}>Preview Members</Button>
              ) : (
                <Button onClick={() => setShowMemberMapping(true)}>Edit Mappings</Button>
              )}
            </Buttons>
          </Actions>
        </Block>
        <BlockContainer>
          <Block>
            <Title>Time Off Settings</Title>
            <Table>
              <Row>
                <Attribute>Import Start Date</Attribute>
                <Value>
                  <DateTime value={integration.settings.timeOffStartDate} format="l" titleFormat="LL" />
                </Value>
              </Row>
              <Row>
                <Attribute>Create Time Off Types</Attribute>
                <Value>{integration.settings.createTimeOffTypes ? 'On' : 'Off'}</Value>
              </Row>
              <Row>
                <Attribute>Update Time Off Notes</Attribute>
                <Value>{integration.settings.updateTimeOffNotes ? 'On' : 'Off'}</Value>
              </Row>
              <Row>
                <Attribute>Time Off Type Mapping</Attribute>
                <Value>{integration.settings.autoTimeOffTypeMapping ? 'Automatic' : 'Manual'}</Value>
              </Row>
            </Table>
            <Actions>
              <Buttons>
                <Button onClick={() => setShowTimeOffSettings(true)}>Change Settings</Button>
                {integration.settings.autoTimeOffTypeMapping ? (
                  <Button onClick={() => setShowTimeOffTypePreview(true)}>Preview Time Off Types</Button>
                ) : (
                  <Button onClick={() => setShowTimeOffTypeMapping(true)}>Edit Mappings</Button>
                )}
              </Buttons>
            </Actions>
          </Block>
          <Block>
            <TitleRow>
              <Title>Imported Data</Title>
              <RefreshButton disabled={!canRefresh} onClick={() => reloadIntegrationWithDelay()}>
                <Tooltip message="Refresh data counts">
                  <Icon icon="sync" />
                </Tooltip>
              </RefreshButton>
            </TitleRow>
            <Table>
              <Row>
                <Attribute>Time Off Entries</Attribute>
                <Value>{integration?.data?.timeEntries}</Value>
              </Row>
            </Table>
            <Actions>
              <DeleteButton onClick={() => setShowDeleteData(true)}>Delete Time Off Entries</DeleteButton>
            </Actions>
          </Block>
        </BlockContainer>
        <Block>
          <Title>Sync Settings</Title>
          <Table>
            <Row>
              <Attribute>Automatically Sync</Attribute>
              <Value>{integration.settings.autoSync ? 'On' : 'Off'}</Value>
            </Row>
            <Row>
              <Attribute>Sync Members</Attribute>
              <Value>{integration.settings.syncMembers ? 'On' : 'Off'}</Value>
            </Row>
            <Row>
              <Attribute>Sync Time Off Entries</Attribute>
              <Value>{integration.settings.syncTimeOff ? 'On' : 'Off'}</Value>
            </Row>
          </Table>
          <Actions>
            <Buttons>
              <Button onClick={() => setShowSyncSettings(true)}>Change Settings</Button>
              <Button onClick={() => setShowManualSync(true)}>New Manual Sync</Button>
            </Buttons>
          </Actions>
        </Block>
        <Block>
          <Title>Manage Integration</Title>
          <StackedButtons>
            <Button onClick={() => handleSSOSetup()}>Setup SSO</Button>
            <Button onClick={() => setShowReauthorize(true)}>Reauthorize Connection</Button>
          </StackedButtons>
        </Block>
        <FullBlock>
          <SyncResults refreshDate={forceRefreshSyncsDate} />
        </FullBlock>
      </Blocks>
      {showManualSync && (
        <ManualSync
          integration={integration}
          onClose={() => setShowManualSync(false)}
          onFinish={() => setForceRefreshSyncsDate(moment().format())}
        />
      )}
      {showMemberMapping && (
        <MemberMapping
          onClose={() => setShowMemberMapping(false)}
          onSave={() => reloadIntegration()}
          onError={() => handleApiError()}
        />
      )}
      {showMemberPreview && (
        <MemberPreview onClose={() => setShowMemberPreview(false)} onError={() => handleApiError()} />
      )}
      {showMemberSettings && (
        <MemberSettings
          integration={integration}
          onClose={() => setShowMemberSettings(false)}
          onSave={() => reloadIntegration()}
        />
      )}
      {showSyncSettings && (
        <SyncSettings
          integration={integration}
          onClose={() => setShowSyncSettings(false)}
          onSave={() => reloadIntegration()}
        />
      )}
      {showTimeOffSettings && (
        <TimeOffSettings
          integration={integration}
          onClose={() => setShowTimeOffSettings(false)}
          onSave={() => reloadIntegration()}
        />
      )}
      {showTimeOffTypeMapping && (
        <TimeOffTypeMapping
          onClose={() => setShowTimeOffTypeMapping(false)}
          onSave={() => reloadIntegration()}
          onError={() => handleApiError()}
        />
      )}
      {showTimeOffTypePreview && (
        <TimeOffTypePreview
          integration={integration}
          onClose={() => setShowTimeOffTypePreview(false)}
          onError={() => handleApiError()}
        />
      )}
      {showDeleteData && (
        <DeleteDataConfirmation onClose={() => setShowDeleteData(false)} onDelete={() => reloadIntegration()} />
      )}
      {showReauthorize && (
        <ReauthorizeConfirmation integration={integration} onClose={() => setShowReauthorize(false)} />
      )}
    </>
  );
}
