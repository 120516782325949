import _ from 'lodash';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import {
  Confirmation,
  CreateButton,
  DateTime,
  ExportDialog,
  ExportDropdown,
  FiltersBar,
  ListView,
  ListViewActions,
  ListViewMenu,
  Page,
  SearchInput,
  SingleSelect,
  Tag,
  Tags,
  Tooltip,
} from '~/components';
import { useApi, useConfirmation, useIntegrations, useToast, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useSearchParams, useSearchParamsConfig } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort, mimeTypes } from '~/utils';
import HubspotIndicator from '../components/HubspotIndicator.jsx';
import SalesforceIndicator from '../components/SalesforceIndicator';

const ContactInfo = styled.div`
  line-height: 1;
  align-items: center;
`;

const ContactName = styled.div`
  display: flex;
  align-items: center;
`;

const ContactTitle = styled.small`
  font-size: 0.75rem;
  color: ${colors.grey40};
  display: flex;
  padding-top: 0.25rem;
`;

const AssociatedTo = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

function FirstAssignment({ assignments }) {
  if (assignments.length === 0) return null;

  const firstAssignment = assignments[0];
  let color;

  switch (firstAssignment.type) {
    case 'client':
      color = colors.primary10;
      break;
    case 'company':
      color = colors.warning10;
      break;
    case 'opportunity':
      color = colors.grey5;
      break;
    default:
      color = colors.black;
  }

  return (
    <Tag style={{ backgroundColor: color, color: colors.black }}>
      <small>{firstAssignment.name}</small>
    </Tag>
  );
}

function Assignments({ assignments }) {
  if (assignments.length <= 1) return null;

  const getColor = (type) => {
    switch (type) {
      case 'client':
        return colors.primary10;
      case 'company':
        return colors.warning10;
      case 'opportunity':
        return colors.grey5;
      default:
        return colors.black;
    }
  };

  const assignmentsByType = assignments.reduce((acc, assignment) => {
    acc[assignment.type] = acc[assignment.type] || [];
    acc[assignment.type].push(assignment);
    acc[assignment.type] = _.orderBy(acc[assignment.type], ['name'], ['asc']);

    return acc;
  }, {});

  return (
    <Tooltip
      message={
        <div style={{ fontSize: '1rem', marginBottom: '-0.75rem' }}>
          {_.map(assignmentsByType, (assignments, type) => (
            <div key={type} style={{ marginBottom: '1rem' }}>
              <AssociatedTo>{pluralize(type)}</AssociatedTo>
              {assignments.map((assignment, index) => (
                <Tag style={{ backgroundColor: getColor(assignment.type) }} key={index}>
                  <small>{assignment.name}</small>
                </Tag>
              ))}
            </div>
          ))}
        </div>
      }>
      <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40 }}>
        <small>+{assignments.length - 1}</small>
      </Tag>
    </Tooltip>
  );
}

export default function ContactsListPage() {
  useDocumentTitle('Contacts');

  const { workspace } = useWorkspace();
  const integrations = useIntegrations();
  const api = useApi();
  const auth = useAuth();
  const history = useHistory();
  const toast = useToast();

  const { url } = useRouteMatch();

  const [query, setQuery] = useState({
    data: null,
    action: 'load',
    status: 'loading',
  });
  const [params, setParams] = useState({
    q: '',
    hubspotSynced: null,
    page: 0,
    size: 25,
    sort: new QuerySort('name', 'asc'),
  });

  // Init and sync URL search params
  const searchParamsConfig = useSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        q: { default: '' },
        hubspotSynced: { default: null },
        sort: { default: new QuerySort('name', 'asc'), ...searchParamsConfig.sort },
      }),
      [searchParamsConfig],
    ),
    sessionKey: 'contacts_list',
    onChange: useCallback((params) => setParams((state) => ({ ...state, ...params })), []),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      q: params.q,
      hubspotSynced: integrations?.hubspot && params.hubspotSynced ? params.hubspotSynced : undefined,
      page: params.page,
      size: params.size,
      sort: params.sort,
    }),
    [params, integrations?.hubspot],
  );

  const confirmation = useConfirmation();

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      if (params) {
        setParams((state) => {
          return { ...state, ...params };
        });
        setSearchParamsStatus('ready');
      }
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).contacts().get(urlSearchParams);
      setQuery((state) => ({
        ...state,
        action: null,
        status: 'ready',
        error: null,
        data: {
          ...data,
          results: state.action === 'load-more' ? [...state.data.results, ...data.results] : data.results,
        },
      }));
    } catch (error) {
      setQuery({ status: 'ready', data: null, error });
      toast.error(error.message);
    }
  }, [workspace.id, api, urlSearchParams, toast]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const handleChange = ({ target: { name, value } }) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams({ ...params, page: 0, [name]: value });
    searchParams.set({ [name]: value });
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort, page: 0 });
    searchParams.set({ sort: querySort });
  };

  const handleDelete = async (contact) => {
    const confirm = await confirmation.prompt((resolve) => (
      <Confirmation resolve={resolve}>Are you sure you want to delete this contact?</Confirmation>
    ));

    if (!confirm) return;
    await api.www.workspaces(workspace.id).contacts(contact.id).delete();

    setQuery((state) => ({ ...state, status: 'load' }));
    setParams({ ...params, page: 0 });
  };

  const handleEdit = ({ id }) => {
    history.push(`/app/${workspace.key}/pipeline/contacts/${id}/overview/edit`);
  };

  const handleOverview = ({ id }) => {
    history.push(`/app/${workspace.key}/pipeline/contacts/${id}/overview`);
  };

  const loadMore = () => {
    if (
      query.status !== 'ready' ||
      query.action !== null ||
      !query.data ||
      query.data.total <= query.data.results.length
    )
      return;

    setQuery((state) => {
      return {
        ...state,
        action: 'load-more',
      };
    });
    setParams((params) => ({ ...params, page: params.page + 1 }));
  };

  const handleExport = async (filename, mimeType) => {
    await confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .contacts()
          .export(params, {
            headers: { accept: mimeType },
            responseType: 'blob',
          })}
        onClose={resolve}
      />
    ));
  };

  if (query.status === 'loading') return <PageLoader />;

  const salesforceEnabled = integrations?.salesforce;
  const hubspotEnabled = integrations?.hubspot;

  return (
    <>
      <Page scrollable>
        <Page.Header>
          <Page.Info>
            <Page.Eyebrow>Pipeline</Page.Eyebrow>
            <Page.Title>Contacts</Page.Title>
          </Page.Info>

          <Page.Actions>
            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(`contacts.csv`, mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await handleExport(`contacts.xlsx`, mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>

            {auth.pipeline.manage && <CreateButton tooltip="New Contact" onClick={() => history.push(`${url}/new`)} />}
          </Page.Actions>
        </Page.Header>

        <Page.Filters>
          <FiltersBar>
            <SearchInput value={params.q} placeholder="Search" onChange={handleChange} />

            {hubspotEnabled && (
              <SingleSelect
                name="hubspotSynced"
                placeholder="All"
                showEmptyOption
                materialPlaceholder="HubSpot Synced"
                materialAlwaysVisible
                value={params.hubspotSynced}
                onChange={handleChange}>
                <option value="synced">Yes</option>
                <option value="not-synced">No</option>
              </SingleSelect>
            )}
          </FiltersBar>
        </Page.Filters>

        <Page.ListView>
          <ListView>
            <ListView.Header>
              <ListView.Column sticky minWidth="16rem" name="name" onSort={handleSort} sort={params.sort}>
                Contact
              </ListView.Column>
              <ListView.Column minWidth="16rem">Assigned To</ListView.Column>
              <ListView.Column minWidth="16rem" name="email" onSort={handleSort} sort={params.sort}>
                Email
              </ListView.Column>
              <ListView.Column width="8rem" name="createdAt" onSort={handleSort} sort={params.sort} align="right">
                Created
              </ListView.Column>
              {salesforceEnabled && (
                <ListView.Column width="4rem" align="left">
                  <SalesforceIndicator />
                </ListView.Column>
              )}
              {hubspotEnabled && (
                <ListView.Column width="4rem" align="left">
                  <HubspotIndicator />
                </ListView.Column>
              )}
              <ListViewActions.Column />
            </ListView.Header>

            <ListView.Body fade={query.status === 'filtering'}>
              {query.data.results.map((contact) => {
                const { id, email, name, title, createdAt, permissions, assignments, salesforceId, hubspotId } =
                  contact;

                async function handleRowClick(contact) {
                  handleOverview(contact);
                }

                return (
                  <ListView.Row key={id} onClick={() => handleRowClick(contact)}>
                    <ListView.Cell>
                      <ContactInfo>
                        <ContactName>{name}</ContactName>

                        {title && <ContactTitle>{title}</ContactTitle>}
                      </ContactInfo>
                    </ListView.Cell>
                    <ListView.Cell>
                      <Tags>
                        <FirstAssignment assignments={assignments} />
                        <Assignments assignments={assignments} />
                      </Tags>
                    </ListView.Cell>
                    <ListView.Cell>{email}</ListView.Cell>
                    <ListView.Cell>
                      <DateTime value={createdAt} />
                    </ListView.Cell>
                    {salesforceEnabled && <ListView.Cell>{salesforceId && <SalesforceIndicator />}</ListView.Cell>}
                    {hubspotEnabled && <ListView.Cell>{hubspotId && <HubspotIndicator />}</ListView.Cell>}

                    <ListViewActions>
                      {permissions.manage ? (
                        <ListViewActions.Edit onClick={() => handleEdit(contact)} />
                      ) : (
                        <ListViewActions.View onClick={() => handleOverview(contact)} />
                      )}

                      <hr />

                      <ListViewMenu>
                        {({ setIsOpen }) => {
                          const handleAction = (action) => setIsOpen(false) || action;

                          return (
                            <>
                              <ListViewMenu.Item onClick={() => handleAction(handleOverview(contact))}>
                                View
                              </ListViewMenu.Item>

                              <ListViewMenu.Item
                                disabled={!permissions.manage}
                                tooltip={
                                  !permissions.manage ? 'Insufficient permissions to edit this contact.' : undefined
                                }
                                onClick={() => handleAction(handleEdit(contact))}>
                                Edit
                              </ListViewMenu.Item>

                              <ListViewMenu.Item
                                disabled={!permissions.manage}
                                tooltip={
                                  !permissions.manage ? 'Insufficient permissions to delete this contact.' : undefined
                                }
                                onClick={() => handleAction(handleDelete(contact))}>
                                Delete
                              </ListViewMenu.Item>
                            </>
                          );
                        }}
                      </ListViewMenu>
                    </ListViewActions>
                  </ListView.Row>
                );
              })}

              {query.data.results.length === 0 && <ListView.Empty />}

              {query.data.total > query.data.results.length && (
                <ListView.Loader key={query.data.results.length} onIntersecting={loadMore} />
              )}
            </ListView.Body>

            <ListView.Status total={query.data.total} label="Contact" isLoading={query.status !== 'ready'} />
          </ListView>
        </Page.ListView>
      </Page>
    </>
  );
}
