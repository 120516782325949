import { Formik } from 'formik';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import {
  Button,
  Buttons,
  CancelButton,
  Currency,
  DateTime,
  DeleteButton,
  DeleteConfirmation,
  Drawer,
  Field,
  Form,
  FormMessage,
  Icon,
  RouteLink,
  Tab,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDirtyCheck, useDocumentTitle, useFeatures, useForm } from '~/hooks';
import { ErrorPage } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { emptyStringToNull, getFileDataUrl, mergeValues } from '~/utils';
import ClientContactSelect from './ClientContactSelect';
import PurchaseOrderFiles from './PurchaseOrderFiles';
import PurchaseOrderHistory from './PurchaseOrderHistory';

const Container = styled.div`
  font-size: 0.875rem;
  margin-bottom: 1rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 1.625rem;
  margin-bottom: 1.625rem;
`;

const SectionTitle = styled.div`
  width: 12rem;
  padding: 1rem 3rem 1rem 0;
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
`;

export default function PurchaseOrderForm({ id, client, onDeleted, onSaved, onClose }) {
  const api = useApi();
  const features = useFeatures();
  const { workspace } = useWorkspace();
  const [tabIndex, setTabIndex] = useState(0);
  const [{ status, message, isSubmitting }, form] = useForm();
  const toast = useToast();
  const [query, setQuery] = useState({ isReady: false, data: null });
  const [hasDependencies, setHasDependencies] = useState(true);

  const [files, setFiles] = useState([]);
  const [attachmentsError, setAttachmentsError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = useCallback(async () => {
    if (!id) {
      setQuery({ isReady: true, data: {} });
      setHasDependencies(false);
      return;
    }

    try {
      const { data } = await api.www.workspaces(workspace.id).purchaseOrders(id).get();
      setQuery({ isReady: true, data });

      const dependencies = await api.www.workspaces(workspace.id).purchaseOrders(id).hasDependencies();
      setHasDependencies(dependencies.data);
    } catch (error) {
      toast.error(error.message || 'There was a problem reading this purchase order. Please try again.');
    }
  }, [workspace.id, id, api, toast]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const formRef = useRef();
  const firstFieldRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);

  const isNew = !id;
  const title = isNew ? 'New Purchase Order' : 'Edit Purchase Order';

  useDocumentTitle(title);

  const confirmation = useConfirmation();
  const handleDelete = async () => {
    await confirmation.prompt((resolve) => (
      <DeleteConfirmation
        resolve={async (confirm) => {
          if (!confirm) {
            resolve(false);
            return;
          }
          await api.www.workspaces(workspace.id).purchaseOrders(id).delete();

          await onDeleted();
          await onClose();
          resolve(true);
        }}>
        Are you sure you want to delete this purchase order?
      </DeleteConfirmation>
    ));
  };

  useEffect(() => {
    if (!query.data?.id) return;
    const fetchData = async () => {
      setIsLoading(true);
      setAttachmentsError(null);
      try {
        const { data } = await api.www.workspaces(workspace.id).purchaseOrders(query.data?.id).files().get();
        setFiles(data);
      } catch (error) {
        if (error?.message) {
          setAttachmentsError(error.message);
        } else {
          setAttachmentsError('There was a problem getting your files.');
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
    return fetchData.cancel;
  }, [api, workspace.id, query.data]);

  const addFiles = async (files) => {
    // Generate placeholder files, run in parallel
    const tempFiles = await Promise.all(
      files.map(async (fileData) => ({
        id: _.uniqueId('temp_file_'),
        name: fileData.name,
        type: fileData.type || 'application/octet-stream',
        status: 'uploading',
        dataUrl: await getFileDataUrl(fileData),
      })),
    );

    // Set the files all at once to prevent missing files
    setFiles((files) => [...tempFiles, ...files]);

    // Upload the files, run in parallel
    await Promise.all(
      tempFiles.map(async (file) => {
        try {
          const response = await api.www
            .workspaces(workspace.id)
            .purchaseOrders(query.data?.id)
            .files()
            .upload({
              name: file.name,
              type: file.type,
              data: file.dataUrl.split(';base64,').pop(),
              isConfidential: file.isConfidential,
            });

          const updatedFile = {
            ...response.data,
            status: 'uploaded',
            dataUrl: file.dataUrl,
          };

          setFiles((files) => {
            const fileIndex = _.findIndex(files, { id: file.id });
            if (fileIndex >= 0) {
              return _.assign([], files, { [fileIndex]: updatedFile });
            }
            return files;
          });
        } catch (error) {
          setFiles((files) => {
            const fileIndex = _.findIndex(files, { id: file.id });
            if (fileIndex >= 0) {
              const updatedFile = _.assign({}, files[fileIndex], {
                status: 'error',
              });
              return _.assign([], files, { [fileIndex]: updatedFile });
            }
            return files;
          });
        }
      }),
    );
  };

  const removeFile = (file) => {
    setFiles((files) => _.reject(files, { id: file.id }));
  };

  const onDrop = (acceptedFiles, rejectedFiles) => {
    for (const file of rejectedFiles) {
      if (_.find(file.errors, { code: 'file-too-large' })) {
        toast.error(`The file ${file.file.path} is too large to upload. The file size limit is 5 MB.`);
      }
      if (_.find(file.errors, { code: 'file-invalid-type' })) {
        toast.error(`${_.find(file.errors, { code: 'file-invalid-type' }).message}`);
      }
    }
    if (acceptedFiles.length > 0) {
      addFiles(acceptedFiles);
    }
  };

  if (!query.isReady) return null;
  if (!query.data) return <ErrorPage.NotFound publicSite={false} />;

  const tabErrors = {
    overview: ['amount', 'currency', 'end', 'name', 'notes', 'number', 'start'],
  };

  const initialValues = mergeValues(
    {
      amount: '',
      contact: null,
      currency: client.currency,
      end: null,
      name: '',
      notes: '',
      number: '',
      start: null,
    },
    query.data,
  );

  const validationSchema = Yup.object().shape({
    amount: Yup.number().min(0.01).max(99999999999).label('Amount').required(),
    currency: Yup.string().length(3).label('Currency').required(),
    end: Yup.date()
      .label('End Date')
      .nullable()
      .when('start', (start, schema) =>
        start ? schema.min(Yup.ref('start'), 'End Date must be after Start Date') : schema,
      ),
    name: Yup.string().max(255).label('Name').required(),
    notes: Yup.string().max(5000),
    number: Yup.string().max(255).label('Number').required(),
    start: Yup.date().label('Start Date').nullable(),
  });

  return (
    <Drawer
      isOpen
      title={title}
      onOpen={() => isNew && firstFieldRef.current && firstFieldRef.current.focus()}
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={onClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(values) {
          try {
            form.submit();
            const body = emptyStringToNull({
              ..._.omit(values, ['contact']),
              contactId: values.contact?.id ?? null,
              clientId: client?.id,
            });

            const { data } = await api.www.workspaces(workspace.id).purchaseOrders(id).upsert(body);

            await onSaved(data);
            form.save();
            closeDrawer();
          } catch ({ message }) {
            form.error({ message });
          }
        }

        return (
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={validationSchema}>
            {(formik) => {
              return (
                <Form>
                  <Tabs selectedIndex={tabIndex} onChange={(index) => setTabIndex(index)}>
                    <Tab>
                      General
                      {tabErrors.overview.some((key) => !!formik.errors[key]) && (
                        <Icon icon="exclamation-circle" color={colors.danger} spaceLeft />
                      )}
                    </Tab>
                    {!isNew && <Tab>Files</Tab>}
                    {!isNew && <Tab>History</Tab>}
                  </Tabs>
                  <Content>
                    {status && <FormMessage.Error>{message}</FormMessage.Error>}
                    {
                      [
                        <>
                          <Form.Section title="Purchase Order">
                            <Form.Control>
                              <Field.Text name="name" placeholder="Name" maxLength={255} autoFocus />
                            </Form.Control>
                            <Form.Control>
                              <Field.Text name="number" placeholder="PO Number" maxLength={255} />
                            </Form.Control>
                            <Form.Control>
                              <Field.Currency name="amount" placeholder="PO Amount" currency={formik.values.currency} />
                              <Field.WorkspaceCurrencySelect
                                name="currency"
                                placeholder="Currency"
                                clearable={false}
                                disabled={!features.multicurrency || hasDependencies}
                              />
                            </Form.Control>
                            <Form.Control>
                              <ClientContactSelect
                                clientId={client.id}
                                value={formik.values.contact}
                                onChange={async ({ target: { value } }) => {
                                  formik.setFieldValue('contact', value);
                                }}
                              />
                            </Form.Control>
                            <Form.Control>
                              <Field.DayPicker name="start" placeholder="Start Date" />
                              <Field.DayPicker name="end" placeholder="End Date" />
                            </Form.Control>
                            <Form.Control>
                              <Field.TextArea name="notes" placeholder="Notes" maxLength={5000} />
                            </Form.Control>
                          </Form.Section>

                          {id && query.data.invoices?.length > 0 && (
                            <Container>
                              <SectionTitle>Invoices</SectionTitle>
                              <Table>
                                <Table.BoxHeader>
                                  <Table.Column width="8rem">Invoice #</Table.Column>
                                  <Table.Column>Projects</Table.Column>
                                  <Table.Column align="right">Due Date</Table.Column>
                                  <Table.Column align="right">Issued On</Table.Column>
                                  <Table.Column align="right">Total</Table.Column>
                                </Table.BoxHeader>

                                <Table.Body>
                                  {query.data.invoices.map((invoice) => {
                                    return (
                                      <Table.BoxRow key={invoice.id}>
                                        <Table.Cell>
                                          <RouteLink to={`/app/${workspace.key}/billing/invoices/${invoice.id}`}>
                                            #{invoice.number}
                                          </RouteLink>
                                        </Table.Cell>
                                        <Table.Cell>
                                          <FirstProject entity={invoice} />
                                          <Projects entity={invoice} />
                                        </Table.Cell>
                                        <Table.Cell>
                                          <DateTime value={invoice.dueOn} />
                                        </Table.Cell>
                                        <Table.Cell>
                                          <DateTime value={invoice.dueOn} />
                                        </Table.Cell>
                                        <Table.Cell>
                                          <Currency value={invoice.total} currency={query.data.currency} />
                                        </Table.Cell>
                                      </Table.BoxRow>
                                    );
                                  })}
                                </Table.Body>
                              </Table>
                            </Container>
                          )}

                          {id && query.data.creditNotes?.length > 0 && (
                            <Container>
                              <SectionTitle>Credit Notes</SectionTitle>
                              <Table>
                                <Table.BoxHeader>
                                  <Table.Column width="10rem">Credit Note #</Table.Column>
                                  <Table.Column>Projects</Table.Column>
                                  <Table.Column align="right">Issued On</Table.Column>
                                  <Table.Column align="right">Total</Table.Column>
                                </Table.BoxHeader>

                                <Table.Body>
                                  {query.data.creditNotes.map((creditNote) => {
                                    return (
                                      <Table.BoxRow key={creditNote.id}>
                                        <Table.Cell>
                                          <RouteLink to={`/app/${workspace.key}/billing/credit-notes/${creditNote.id}`}>
                                            #{creditNote.number}
                                          </RouteLink>
                                        </Table.Cell>
                                        <Table.Cell>
                                          <FirstProject entity={creditNote} />
                                          <Projects entity={creditNote} />
                                        </Table.Cell>
                                        <Table.Cell>
                                          <DateTime value={creditNote.issuedOn} />
                                        </Table.Cell>
                                        <Table.Cell>
                                          <Currency value={creditNote.total} currency={query.data.currency} />
                                        </Table.Cell>
                                      </Table.BoxRow>
                                    );
                                  })}
                                </Table.Body>
                              </Table>
                            </Container>
                          )}
                        </>,
                        <>
                          <PurchaseOrderFiles
                            key="files"
                            purchaseOrder={query.data}
                            onDrop={onDrop}
                            files={files}
                            error={attachmentsError}
                            isLoading={isLoading}
                            removeFile={removeFile}
                          />
                        </>,
                        <>
                          <PurchaseOrderHistory purchaseOrderId={query.data?.id} />
                        </>,
                      ][tabIndex]
                    }
                  </Content>

                  {status && <FormMessage.Error>{message}</FormMessage.Error>}
                  <Drawer.Actions>
                    {!isNew && !hasDependencies && <DeleteButton onClick={handleDelete}>Delete</DeleteButton>}

                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>

                      <Button isLoading={isSubmitting} onClick={formik.submitForm}>
                        Save &amp; Close
                      </Button>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}

const FirstProject = ({ entity }) => {
  const project = entity.projects[0];
  if (!project) return null;

  return project.name;
};

const Title = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

const Projects = ({ entity }) => {
  let projectsCount = entity.projects.length - 1; // Remove the first project because it already shows a tag
  if (projectsCount <= 0) return null;

  return (
    <Tooltip
      message={
        <div style={{ fontSize: '1rem' }}>
          <Title>Projects</Title>

          {entity.projects.map((projects) => (
            <Tag style={{ backgroundColor: colors.grey5 }} key={projects.id}>
              <small>{projects.name}</small>
            </Tag>
          ))}
        </div>
      }>
      <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40 }}>
        <small>+{projectsCount}</small>
      </Tag>
    </Tooltip>
  );
};
