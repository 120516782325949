import React, { useMemo } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Duration } from '~/components';
import { useWorkspace } from '~/contexts';
import { colors, weights } from '~/styles';
import { QueryString } from '~/utils';

const Container = styled.div`
  flex: 1;
  display: flex;
  margin: 0.25rem;
  background-color: ${colors.white};
  border-radius: 0.625rem;
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
`;

const TotalHours = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1.25rem 1.5rem;
`;

const SummaryLabel = styled.div`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.bold};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
`;

const TotalHoursValue = styled.div`
  color: ${colors.primary};
  font-size: 2.25rem;
  font-weight: ${weights.light};
`;

const TotalHoursLabel = styled.div`
  margin-top: 0.25rem;
  color: ${colors.grey25};
  font-size: 0.625rem;
  text-align: center;
  text-transform: uppercase;
`;

const TotalHoursInfo = styled(Link)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  cursor: pointer;

  &:hover ${TotalHoursValue} {
    color: ${colors.accent};
  }
`;

const HoursBreakdown = styled.div`
  display: flex;
  flex-direction: column;
`;

const BreakdownLabel = styled.div`
  color: ${colors.grey40};
  font-size: 0.625rem;
`;

const BreakdownValue = styled.div`
  color: ${colors.black};
  font-size: 0.875rem;
  font-weight: ${weights.bold};
`;

const BreakdownItem = styled(Link)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  padding-right: 2rem;
  border: solid 0.125rem ${colors.grey5};
  border-right: none;
  border-bottom: none;
  cursor: pointer;

  &:first-child {
    border-top: none;
  }

  &:hover ${BreakdownValue} {
    color: ${colors.grey55};
  }
`;

export default function HoursSummary({ entries, startDate, endDate, member: selectedMember }) {
  const { workspace } = useWorkspace();

  const member = useMemo(() => {
    return selectedMember ?? workspace.member;
  }, [selectedMember, workspace]);

  const { total, billable, nonBillable, internal, timeOff } = useMemo(() => {
    return {
      total: _.sumBy(entries, 'minutes'),
      billable: _(entries)
        .filter((entry) => entry.typeId === 'project_time' && entry.isActuallyBillable)
        .sumBy('minutes'),
      nonBillable: _(entries)
        .filter(
          (entry) => entry.typeId === 'project_time' && !entry.isActuallyBillable && !entry.project?.client?.isInternal,
        )
        .sumBy('minutes'),
      internal: _(entries)
        .filter((entry) => entry.project && entry.project?.client?.isInternal)
        .sumBy('minutes'),
      timeOff: _(entries).filter(['type.id', 'time_off']).sumBy('minutes'),
    };
  }, [entries]);

  const timeDetail = (query = {}) =>
    `/app/${workspace.key}/reports/time-entries${new QueryString(
      {
        start: startDate,
        end: endDate,
        member: member?.id,
        ...query,
      },
      { multi: true },
    ).toString(true)}`;

  return (
    <Container>
      <TotalHours>
        <SummaryLabel>Hours Summary</SummaryLabel>
        <TotalHoursInfo to={timeDetail()}>
          <TotalHoursValue>
            <Duration useDecimal minutes={total} />
          </TotalHoursValue>
          <TotalHoursLabel>Total Hours</TotalHoursLabel>
        </TotalHoursInfo>
      </TotalHours>
      <HoursBreakdown>
        <BreakdownItem to={timeDetail({ billableType: 'billable' })}>
          <BreakdownLabel>Billable</BreakdownLabel>
          <BreakdownValue>
            <Duration useDecimal minutes={billable} />
          </BreakdownValue>
        </BreakdownItem>
        <BreakdownItem to={timeDetail({ billableType: 'non_billable' })}>
          <BreakdownLabel>Non-billable</BreakdownLabel>
          <BreakdownValue>
            <Duration useDecimal minutes={nonBillable} />
          </BreakdownValue>
        </BreakdownItem>
        <BreakdownItem to={timeDetail({ billableType: 'internal' })}>
          <BreakdownLabel>Internal</BreakdownLabel>
          <BreakdownValue>
            <Duration useDecimal minutes={internal} />
          </BreakdownValue>
        </BreakdownItem>
        {(member.timeOffAllowed || timeOff > 0) && (
          <BreakdownItem to={timeDetail({ billableType: 'time_off' })}>
            <BreakdownLabel>Time Off</BreakdownLabel>
            <BreakdownValue>
              <Duration useDecimal minutes={timeOff} />
            </BreakdownValue>
          </BreakdownItem>
        )}
      </HoursBreakdown>
    </Container>
  );
}
