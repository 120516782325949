import { Buttons, CancelButton, DeleteButton, ModalCard } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useForm } from '~/hooks';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const P = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const ListItem = styled.li`
  list-style: initial;
  list-style-position: inside;
  padding-left: 0.6rem;
`;

function DeleteMemberDialog({ member, onDelete, onCheckDependencies, onClose }) {
  const [{ isSubmitting }, form] = useForm();
  const [associations, setAssociations] = useState(null);
  const api = useApi();
  const { workspace } = useWorkspace();

  const handleSubmit = async (scope) => {
    form.submit(scope);

    await api.www.workspaces(workspace.id).members(member.id).remove();

    if (onDelete) await onDelete();
    form.done();
  };

  useEffect(() => {
    if (!onCheckDependencies) {
      setAssociations({ hasDependencies: false });
      return;
    }

    (async () => {
      const associations = await onCheckDependencies(api.www.workspaces(workspace.id));
      setAssociations(associations);
    })();
  }, [onCheckDependencies, workspace.id, api]);

  if (!associations) return null;

  return (
    <ModalCard title="Delete Member" onClose={onClose}>
      {associations.hasDependencies ? (
        <ModalCard.Body>
          <P>
            This member can't be deleted because the member is associated with the following types of data records. You
            can deactivate the member without removing these associations.
          </P>
          <ul>
            {Object.keys(associations.entities).map(
              (key) => associations.entities[key] && <ListItem key={key}>{key}</ListItem>,
            )}
          </ul>
        </ModalCard.Body>
      ) : (
        <ModalCard.Body>
          <P>
            This will remove <strong>{member.name}</strong> from this workspace. Are you sure?
          </P>
        </ModalCard.Body>
      )}

      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          {!associations.hasDependencies && (
            <DeleteButton isLoading={isSubmitting} onClick={handleSubmit}>
              Delete
            </DeleteButton>
          )}
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}

export default DeleteMemberDialog;
