import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import getGradientColor from './components/gradient';
import { Percent, Tooltip } from '~/components';
import List from '../../reports/components/List';
import opportunityStageCategories from '~/lookups/opportunity-stage-categories';

const BarContainer = styled.div`
  width: 8rem;
  padding-top: 0.1rem;
`;

const Meter = styled.div`
  display: flex;
  min-height: 0.75rem;
  background: ${colors.white};
  border: 1px solid ${colors.grey10};
  position: relative;
  border-radius: 0.5rem;
  padding: 0.125rem;
  min-width: 100%;
`;

const InsideBar = styled.div`
  min-width: 0.1rem;
  border-radius: 0.25rem;
  width: ${({ width }) => width};
  background: ${({ color }) => color};
`;

export default function ProbabilityBar({ probability, stageStatus }) {
  const probabilityColor = {
    open: getGradientColor(probability),
    won: colors.success,
    lost: colors.danger,
  }[stageStatus];

  return (
    <BarContainer>
      <Tooltip
        message={
          <List>
            <List.Item label="Stage Probability">
              <Percent value={probability / 100} minimumFractionDigits={0} maximumFractionDigits={0} />
            </List.Item>
            <List.Item label="Stage Category">{opportunityStageCategories[stageStatus].name}</List.Item>
          </List>
        }>
        <Meter>
          <InsideBar width={`${probability}%`} color={probabilityColor}></InsideBar>
        </Meter>
      </Tooltip>
    </BarContainer>
  );
}
