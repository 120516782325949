import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faFacebookSquare,
  faLinkedin,
  faLinkedinIn,
  faSalesforce,
  faTwitterSquare,
} from '@fortawesome/free-brands-svg-icons';

import {
  faCalendarAlt as farCalendarAlt,
  faEnvelope as farEnvelope,
  faIdCard as farIdCard,
  faQuestionCircle as farQuestionCircle,
} from '@fortawesome/free-regular-svg-icons';

import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowUpRightFromSquare,
  faAt,
  faBars,
  faBuilding,
  faCalendar,
  faCalendarAlt,
  faCalendarDay,
  faCamera,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faChartLine,
  faChartPie,
  faChartSimple,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faCircle,
  faClipboard,
  faClock,
  faCloudUploadAlt,
  faCog,
  faDollarSign,
  faDownload,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEnvelopeCircleCheck,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faFax,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFileImport,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faFilter,
  faFilterCircleDollar,
  faFlask,
  faFloppyDisk,
  faFolder,
  faGripLinesVertical,
  faGripVertical,
  faHistory,
  faHome,
  faHourglass,
  faImage,
  faInfoCircle,
  faKey,
  faLightbulb,
  faLink,
  faLock,
  faMinus,
  faMobile,
  faPause,
  faPenAlt,
  faPencilAlt,
  faPercent,
  faPlay,
  faPlus,
  faPrint,
  faReceipt,
  faRightLeft,
  faRocket,
  faScrewdriverWrench,
  faSearch,
  faShareAlt,
  faSlidersH,
  faSpinner,
  faStar,
  faSync,
  faTasks,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrash,
  faUndo,
  faUnlock,
  faUpload,
  faUser,
  faUserCircle,
  faUserGear,
  faUsers,
  faUserSecret,
  faVideo,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';

import {
  faBadgeCheck,
  faCalendarStar,
  faChevronsDown,
  faChevronsUp,
  faFileCsv,
  faFileZip,
  faGrid2,
  faHandshake,
  faImageSlash,
  faInboxIn,
  faInboxOut,
  faLockOpen,
  faMessageSms,
  faNote,
  faPhone,
  faPhoneOffice,
  faRectanglePro,
  faRepeat,
  faShield,
  faShieldCheck,
  faShieldExclamation,
  faShoePrints,
  faTableRows,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faBook,
  faBuildings,
  faCommentLines,
  faAngleDown as farAngleDown,
  faBarsProgress as farBarsProgress,
  faCalendar as farCalendar,
  faCalendarDay as farCalendarDay,
  faClipboardCheck as farClipboardCheck,
  faCog as farCog,
  faFileAlt as farFileAlt,
  faFilter as farFilter,
  faGlobe as farGlobe,
  faHourglass as farHourglass,
  faPaperclipVertical as farPaperclipVertical,
  faPenAlt as farPenAlt,
  faStar as farStar,
  faStopwatch as farStopwatch,
  faUser as farUser,
  faUserHardHat as farUserHardHat,
  faTrianglePersonDigging,
} from '@fortawesome/pro-regular-svg-icons';

library.add(
  faAngleDoubleDown,
  faAngleDoubleUp,
  faAngleDown,
  farAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowLeft,
  faArrowRight,
  faArrowUpRightFromSquare,
  faAt,
  faBadgeCheck,
  faBars,
  farBarsProgress,
  faBook,
  farGlobe,
  faBuilding,
  faBuildings,
  faCalendarStar,
  faCamera,
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faCaretLeft,
  faChartLine,
  faChartSimple,
  faChartPie,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faChevronsUp,
  faChevronsDown,
  faCircle,
  faCloudUploadAlt,
  faClock,
  faClipboard,
  faCog,
  farCog,
  faCommentLines,
  faDollarSign,
  faDownload,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEnvelopeCircleCheck,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faFacebookSquare,
  faFax,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFileImport,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faFilter,
  faFilterCircleDollar,
  farFilter,
  faFlask,
  faFloppyDisk,
  faFolder,
  faGripLinesVertical,
  faGripVertical,
  faHandshake,
  faHistory,
  faHome,
  faHourglass,
  faImage,
  faImageSlash,
  faInboxIn,
  faInboxOut,
  faInfoCircle,
  faKey,
  faLink,
  faLightbulb,
  faLinkedin,
  faLinkedinIn,
  faLock,
  faLockOpen,
  faMessageSms,
  faMinus,
  faMobile,
  faNote,
  farPaperclipVertical,
  faPause,
  faPenAlt,
  faPencilAlt,
  faPercent,
  faPhone,
  faPhoneOffice,
  faPlay,
  faPlus,
  faPrint,
  faRightLeft,
  faCalendar,
  faCalendarDay,
  faCalendarAlt,
  farCalendar,
  farCalendarDay,
  farCalendarAlt,
  farClipboardCheck,
  farHourglass,
  faGrid2,
  farPenAlt,
  faReceipt,
  faRectanglePro,
  farEnvelope,
  farUserHardHat,
  faRepeat,
  farFileAlt,
  farIdCard,
  faRocket,
  farQuestionCircle,
  farStopwatch,
  faSalesforce,
  faScrewdriverWrench,
  faSearch,
  faShareAlt,
  faShield,
  faShieldCheck,
  faShieldExclamation,
  faShoePrints,
  faSlidersH,
  faSpinner,
  faStar,
  farStar,
  faSync,
  faTableRows,
  faFileZip,
  faTasks,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrianglePersonDigging,
  faTwitterSquare,
  faUndo,
  faUnlock,
  faUpload,
  faUser,
  farUser,
  faUserCircle,
  faUserGear,
  faUsers,
  faUserSecret,
  faVideo,
  faWrench,
  faArrowUp,
);
