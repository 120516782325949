import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';

const Article = styled.article`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${({ backgroundColor }) => {
    return backgroundColor ? backgroundColor : 'transparent';
  }};
  padding: ${({ padding }) => {
    return padding ? padding : '1rem';
  }};

  ${({ scrollable }) =>
    scrollable &&
    css`
      overflow-y: scroll;
      height: ${({ $height }) => $height};
      flex: unset;
    `}
`;

function Page(props) {
  useEffect(() => {
    if (props.scrollable) {
      document.body.style.overflowY = 'visible';
    }

    return () => {
      document.body.style.overflowY = 'scroll';
    };
  }, [props.scrollable]);

  // If the header isn't rendered yet, default to 60 px (the standard header height)
  const headerBottom = document.querySelector('#root > header')?.getBoundingClientRect().bottom ?? 60;
  return <Article $height={`calc(100vh - ${headerBottom}px)`} {...props} />;
}

Page.Header = styled.header`
  display: flex;
  align-items: center;
  position: sticky;
  left: 0;
  z-index: 7;
`;

Page.TabHeader = styled.header`
  display: flex;
  align-items: center;
  position: sticky;
  left: 0;
  z-index: 6;
  margin-top: 1.5rem;
`;

Page.Info = styled.div`
  display: flex;
  flex-direction: column;
`;

Page.Eyebrow = styled.h3`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  margin-top: 0.1rem;
`;

Page.Title = styled.h1`
  font-size: 1.25rem;
  font-weight: ${weights.light};
`;

Page.Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  gap: 1rem;
`;

Page.Section = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;

  ${({ sticky }) =>
    sticky &&
    css`
      position: sticky;
      left: 0;
      z-index: 4;
    `}
`;

Page.Filters = styled.div`
  margin-top: 1rem;
  position: sticky;
  left: 0;
  z-index: 5;

  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap-reverse;
`;

Page.ListView = styled.div`
  margin-top: 1.5rem;
  margin-left: -1rem;
  margin-right: -1rem;
`;

const TabsContainer = styled.div`
  position: sticky;
  left: 0;
  margin-top: 1.6rem;
`;

const Tabs = styled.div`
  display: flex;
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  min-height: max-content;

  > ul {
    flex: 1;

    li {
      white-space: nowrap;
    }
  }
`;

Page.Tabs = function PageTabs(props) {
  return (
    <TabsContainer>
      <Tabs {...props} />
    </TabsContainer>
  );
};

Page.Content = styled.div`
  flex: 1;
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
`;

Page.Main = styled.main`
  display: flex;
  flex-direction: column;
  flex: 4;
  flex-basis: 40rem;
`;

Page.Widgets = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  grid-auto-rows: 1fr;
  gap: 1.5rem;
`;

Page.Description = styled.div`
  margin-top: 0.25rem;
  font-size: 0.875rem;
  margin-right: 1rem;
  color: ${colors.grey55};
`;

export default Page;
