import React from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import Avatar from './Avatar';
import MemberContactPopover from './MemberContactPopover';

const Panel = styled.div`
  background: ${colors.grey5};
  padding: 1.5rem 1.5rem;
  z-index: 1;
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: 15rem;
`;

Panel.Title = styled.h3`
  font-size: 1.25rem;
  font-weight: ${weights.light};
`;

Panel.Separator = styled.div`
  margin: 0 -1.5rem;
  position: relative;
  border-top: 1px solid ${colors.grey25};
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoBlockTitle = styled.h4`
  font-size: 0.75rem;
  font-weight: ${weights.black};
  color: ${colors.grey40};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
`;

const InfoBlockContent = styled.div`
  margin-top: 0.375rem;
  font-size: 0.875rem;
  flex: 1;
  overflow-wrap: anywhere;
`;

function Info({ title, children, ...props }) {
  return (
    <InfoBlock {...props}>
      <InfoBlockTitle>{title}</InfoBlockTitle>
      <InfoBlockContent>{children}</InfoBlockContent>
    </InfoBlock>
  );
}

Panel.Info = Info;

const MemberAvatar = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

const MemberName = styled.div`
  font-size: 0.875rem;
  margin-left: 0.5rem;

  .icon {
    margin-right: 0.5rem;
  }
`;

Panel.Member = function ({ member }) {
  return (
    <MemberAvatar>
      <MemberContactPopover member={member}>
        <Avatar value={member} isCircle hasBackground initialsFontSize=".9rem" />
      </MemberContactPopover>

      <MemberName>{member.name}</MemberName>
    </MemberAvatar>
  );
};

const Contact = styled.div`
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  a {
    color: ${colors.grey40};
  }
`;

Panel.Contact = function ({ contact }) {
  return (
    <Contact key={contact.id}>
      <p>
        {contact.name} {contact.title && `(${contact.title})`}
      </p>
      {contact.email && <a href={`mailto:${contact.email}`}>{contact.email}</a>}
    </Contact>
  );
};

export default Panel;
