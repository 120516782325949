import React, { useEffect, useState } from 'react';
import { useApi, useWorkspace } from '~/contexts';
import { useField } from '~/hooks';
import FieldControl from './FieldControl';
import SingleSelect from './SingleSelect';
import { colors } from '~/styles';
import Icon from './Icon';
import Tooltip from './Tooltip';

function AuthProviderSelect({ name, value, placeholder = 'Authentication Provider', disabled, onChange }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await api.www.workspaces(workspace.id).authProviders().get({ isActive: true });
      setOptions(data);
    })();
  }, [workspace.id, api]);

  const handleChange = (event) => {
    const option = options.find((o) => o.id === event.target.value);
    let value = null;
    if (option) {
      value = {
        id: option.id,
        name: option.name,
        isActive: option.isActive,
        isDefault: option.isDefault,
      };
    }

    onChange({
      target: {
        name: event.target.name,
        value,
      },
    });
  };

  return (
    <SingleSelect
      name={name}
      value={value}
      valueRenderer={
        value && (
          <div style={{ display: 'flex' }}>
            {value.name}
            {!value.isActive && (
              <Tooltip
                message="This authentication provider is inactive and will be ignored."
                style={{ marginLeft: '0.5rem' }}>
                <Icon icon="exclamation-triangle" color={colors.warning} />
              </Tooltip>
            )}
            {value.isDefault && (
              <Tooltip
                message="The default authentication provider for this workspace."
                style={{ marginLeft: '0.5rem' }}>
                <Icon icon="badge-check" />
              </Tooltip>
            )}
          </div>
        )
      }
      placeholder={placeholder}
      disabled={disabled}
      onChange={handleChange}>
      {options.map(({ id, name, isDefault }) => (
        <option key={id} value={id}>
          <div style={{ display: 'flex' }}>
            {name}{' '}
            {isDefault && (
              <Tooltip
                message="The default authentication provider for this workspace."
                style={{ marginLeft: '0.5rem' }}>
                <Icon icon="badge-check" />
              </Tooltip>
            )}
          </div>
        </option>
      ))}
    </SingleSelect>
  );
}

function FieldAuthProviderSelect(props) {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error;

  return (
    <FieldControl error={error}>
      <AuthProviderSelect {...field} {...props} />
    </FieldControl>
  );
}

export default AuthProviderSelect;
export { FieldAuthProviderSelect };
