import Big from 'big.js';
import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import React, { useRef } from 'react';
import * as Yup from 'yup';
import {
  ActionButton,
  Button,
  Buttons,
  CancelButton,
  CurrencyInput,
  DeleteButton,
  DeleteConfirmation,
  Drawer,
  Field,
  Form,
  FormMessage,
  Icon,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDirtyCheck, useDocumentTitle, useForm } from '~/hooks';
import { mergeValues } from '~/utils';

export default function ExchangeRatePeriod({ exchangeRatePeriod, onClose, onSaved, onDeleted }) {
  const title = `${exchangeRatePeriod?.id ? 'Edit' : 'New'} Exchange Rate Period`;
  useDocumentTitle(title);

  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const [{ status, message, isSubmitting, saved }, form] = useForm();
  const api = useApi();
  const { workspace } = useWorkspace();
  const toast = useToast();
  const confirmation = useConfirmation();

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  const initialValues = mergeValues(
    {
      start: moment().format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
      fromCurrency: workspace.currency,
      toCurrency: workspace.currency,
      rate: 1,
    },
    exchangeRatePeriod,
  );

  return (
    <Drawer
      isOpen
      title={title}
      byline="Custom Data"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(values, { resetForm }) {
          try {
            form.submit(formRef.current.status.action);

            const body = {
              fromCurrency: values.fromCurrency,
              toCurrency: values.toCurrency,
              rate: values.rate,
              start: values.start,
              end: values.end,
            };

            const { data } = await api.www
              .workspaces(workspace.id)
              .exchangeRatePeriods(exchangeRatePeriod?.id)
              .upsert(body);

            if (onSaved) {
              await onSaved(data);
            }
            form.save(formRef.current.status.action);

            switch (formRef.current.status.action) {
              case 'new':
                resetForm();
                toast.success('Exchange Rate Period successfully saved.');
                break;
              case 'close':
                closeDrawer();
                break;
            }
            form.done();
          } catch ({ message }) {
            form.error({ message });
          }
        }

        async function handleDelete() {
          const confirm = await confirmation.prompt((resolve) => (
            <DeleteConfirmation resolve={resolve}>
              Are you sure that you want to delete this Exchange Rate Period?
            </DeleteConfirmation>
          ));
          if (!confirm) return;

          try {
            await api.www.workspaces(workspace.id).exchangeRatePeriods(exchangeRatePeriod.id).delete();
            closeDrawer();
            onDeleted();
            return true;
          } catch ({ message }) {
            toast.error(message);
          }
        }

        return (
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              start: Yup.date().label('Start Date').nullable().required(),
              end: Yup.date()
                .label('End Date')
                .nullable()
                .required()
                .min(Yup.ref('start'), 'End Date must be after Start Date'),
              fromCurrency: Yup.string().label('From Currency').nullable().required(),
              toCurrency: Yup.string()
                .label('To Currency')
                .nullable()
                .required()
                .notOneOf([Yup.ref('fromCurrency')], 'From Currency and To Currency must be different'),
              rate: Yup.number().label('Rate').nullable().min(0.000000000001).max(99999999).required(),
            })}>
            {(formik) => {
              const submit = async (action) => {
                formik.setStatus({ action });
                formik.submitForm();
              };

              return (
                <Form>
                  {status && <FormMessage.Error>{message}</FormMessage.Error>}
                  <Form.Section title="Details">
                    <Form.Control>
                      <Field.WorkspaceCurrencySelect name="fromCurrency" placeholder="Currency" clearable={false} />

                      <Button
                        style={{ flex: 0, padding: 0, fontSize: '0.75rem' }}
                        isAnchor
                        onClick={() => {
                          formik.setValues({
                            ...formik.values,
                            flip: !formik.values.flip,
                            fromCurrency: formik.values.toCurrency,
                            toCurrency: formik.values.fromCurrency,
                            rate:
                              _.isNumber(formik.values.rate) && formik.values.rate > 0
                                ? Big(1).div(formik.values.rate).round(12).toNumber()
                                : null,
                          });
                        }}>
                        <Icon icon="right-left" />
                      </Button>

                      <Field.WorkspaceCurrencySelect name="toCurrency" placeholder="Currency" clearable={false} />
                    </Form.Control>
                    <Form.Control style={{ display: 'flex', alignItems: 'center' }}>
                      <CurrencyInput value={1} currency={formik.values.fromCurrency} disabled onChange={() => {}} />
                      =
                      <Field.Currency
                        name="rate"
                        placeholder="Rate"
                        precision={12}
                        currency={formik.values.toCurrency}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.DayPicker name="start" placeholder="Start Date" />
                      <Field.DayPicker name="end" placeholder="End Date" />
                    </Form.Control>
                    <Form.Control></Form.Control>
                  </Form.Section>
                  <Drawer.Actions>
                    {exchangeRatePeriod.id && <DeleteButton onClick={handleDelete}>Delete</DeleteButton>}

                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                      {!exchangeRatePeriod?.id && (
                        <ActionButton
                          isLoading={isSubmitting === 'new'}
                          ok={saved === 'new'}
                          type="submit"
                          onClick={() => submit('new')}>
                          Save &amp; New
                        </ActionButton>
                      )}

                      <ActionButton
                        isLoading={isSubmitting === 'close'}
                        ok={saved === 'close'}
                        type="submit"
                        onClick={() => submit('close')}>
                        Save &amp; Close
                      </ActionButton>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}
