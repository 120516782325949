import React, { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Button, DateTime, Buttons, Icon, Tooltip } from '~/components';
import { useIsMounted } from '~/hooks';
import { colors, weights } from '~/styles'; // Reuse existing styles
import SyncSettingsDrawer from './SyncSettingsDrawer';
import ManualSyncModal from './ManualSyncModal';
import SyncResults from './SyncResults';
import SalesforceSetup from './SalesforceSetup';
// import { useUser } from '~/contexts';

const REFRESH_DELAY_SECONDS = 5;

const Blocks = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 64rem;
  margin: -1rem;
  margin-top: 1rem;
`;

const Block = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: calc(50% - 2rem);
  margin: 1rem;
  padding: 1.5rem;
  background-color: ${colors.white};
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
  border-radius: 0.3125rem;
`;

const FullBlock = styled(Block)`
  width: calc(100% - 2rem);
`;

const IndicatorIcon = styled(Icon)`
  width: 0.675rem;
  margin: 0.25rem 0.5rem 0 0.25rem;
`;

const DisconnectMessage = styled.div`
  padding: 1rem;
  font-size: 0.75rem;
  font-weight: ${weights.medium};
  background-color: ${colors.danger10};
  color: ${colors.danger100};
  width: 100%;
  margin: 0.5rem;
  border-radius: 0.25rem;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: ${weights.light};
`;

const Actions = styled.div`
  margin-top: auto;
  padding-top: 1rem;
`;

const Table = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.grey10};
  // :not(:last-child) {
  //   border-bottom: 1px solid ${colors.grey10};
  // }
`;

const Attribute = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
`;

const Value = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0.75rem;
  font-weight: ${weights.bold};
`;

const RefreshButton = styled.div`
  margin-left: auto;
  padding-left: 1rem;
  color: ${({ disabled }) => (disabled ? colors.grey25 : colors.black)};
  cursor: pointer;
`;

// Main SalesforceSettings component
export default function SalesforceSettings({ integration, reloadIntegration }) {
  const isMounted = useIsMounted();

  const [showManualSync, setShowManualSync] = useState(false);
  const [showSyncSettings, setShowSyncSettings] = useState(false);
  const [showEditConnection, setShowEditConnection] = useState(false);
  const [forceRefreshSyncsDate, setForceRefreshSyncsDate] = useState(moment().format());
  const [canRefresh, setCanRefresh] = useState(true);
  // const { user, admin } = useUser();
  // const isSysAdmin = !!user?.isAdmin || !!admin?.isAdmin;

  const reloadIntegrationWithDelay = () => {
    if (!canRefresh) {
      return;
    }
    setCanRefresh(false);
    reloadIntegration();
    setTimeout(() => {
      if (isMounted.current) {
        setCanRefresh(true);
      }
    }, REFRESH_DELAY_SECONDS * 1000);
  };

  const confirmedActiveConnection = !!integration?.connectionTest?.confirmedActive;
  const connectionErrorMessage = integration?.connectionTest?.errorMessage;

  return (
    integration && (
      <>
        <Blocks>
          <Block>
            <Title>Connection Information</Title>
            <small>{integration.info.instanceUrl}</small>
            <Table>
              <Row>
                <Attribute>Username</Attribute>
                <Value>{integration.info.username}</Value>
              </Row>
              <Row>
                <Attribute>Passphrase</Attribute>
                <Value>******</Value> {/* Mask sensitive data */}
              </Row>
              <Row>
                <Attribute>Connection Status</Attribute>
                <Value>
                  {confirmedActiveConnection ? (
                    <>
                      <IndicatorIcon icon="circle" color={colors.green} />
                      Active
                    </>
                  ) : (
                    <>
                      <IndicatorIcon icon="circle" color={colors.red} />
                      Disconnected
                    </>
                  )}
                </Value>
              </Row>
              {!confirmedActiveConnection && connectionErrorMessage && (
                <Row>
                  <DisconnectMessage>{connectionErrorMessage}</DisconnectMessage>
                </Row>
              )}
            </Table>
            <Actions>
              <Buttons>
                <Button onClick={() => setShowEditConnection(true)}>Edit Connection</Button>
              </Buttons>
            </Actions>
          </Block>
          <Block>
            <Title>Sync Settings</Title>
            <Table>
              <Row>
                <Attribute>Automatically Sync</Attribute>
                <Value>{integration.settings.autoSync ? 'On' : 'Off'}</Value>
              </Row>
              <Row>
                <Attribute>Sync Start Date</Attribute>
                <Value>
                  <DateTime value={integration.settings.syncStartDate} format="l" titleFormat="LL" />
                </Value>
              </Row>
              <Row>
                <Attribute>Last Sync Date</Attribute>
                <Value>
                  {integration.settings.mostRecentSync ? (
                    <DateTime value={integration.settings.mostRecentSync} format="l" titleFormat="LL" />
                  ) : (
                    <span>Never been run</span>
                  )}
                </Value>
              </Row>
            </Table>
            <Actions>
              <Buttons>
                <Button onClick={() => setShowSyncSettings(true)} disabled={!confirmedActiveConnection}>
                  Change Settings
                </Button>
                <Button onClick={() => setShowManualSync(true)} disabled={!confirmedActiveConnection}>
                  New Manual Sync
                </Button>
              </Buttons>
            </Actions>
          </Block>
        </Blocks>
        <Blocks>
          <FullBlock>
            <TitleRow>
              <Title>Imported Data Stats</Title>
              <RefreshButton disabled={!canRefresh} onClick={() => reloadIntegrationWithDelay()}>
                <Tooltip message="Refresh data counts">
                  <Icon icon="sync" />
                </Tooltip>
              </RefreshButton>
            </TitleRow>
            <Table>
              <Row>
                <Attribute>Members</Attribute>
                <Value>{integration.data.members?.count}</Value>
              </Row>

              <Row>
                <Attribute>Contacts</Attribute>
                <Value>{integration.data.contacts?.count}</Value>
              </Row>
              <Row>
                <Attribute>Opportunities</Attribute>
                <Value>{integration.data.opportunities?.count}</Value>
              </Row>
              <Row>
                <Attribute>Companies</Attribute>
                <Value>{integration.data.companies?.count}</Value>
              </Row>
              <Row>
                <Attribute>Activities</Attribute>
                <Value>{integration.data.activities?.count}</Value>
              </Row>
            </Table>
          </FullBlock>
          <FullBlock>
            <SyncResults refreshDate={forceRefreshSyncsDate} />
          </FullBlock>
        </Blocks>

        {showEditConnection && (
          <SalesforceSetup
            onClose={() => setShowEditConnection(false)}
            onFinish={() => {
              setShowEditConnection(false);
              reloadIntegration();
            }}
          />
        )}

        {showManualSync && (
          <ManualSyncModal
            integration={integration}
            onClose={() => setShowManualSync(false)}
            onFinish={() => setForceRefreshSyncsDate(moment().format())}
          />
        )}
        {showSyncSettings && (
          <SyncSettingsDrawer
            integration={integration}
            onClose={() => setShowSyncSettings(false)}
            onSave={() => reloadIntegration()}
          />
        )}
      </>
    )
  );
}
