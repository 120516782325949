import { Confirmation, Page, Tab, Tabs } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { Route, Switch, useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import ContactDetailsPage from './ContactDetailsPage';
import ContactHeader from './ContactHeader';
import ContactForm from './ContactForm';

export default function ContactArea() {
  const api = useApi();
  const { workspace } = useWorkspace();
  const history = useHistory();
  const [query, setQuery] = useState({ isReady: false, contact: null, error: null });
  const documentTitle = useDocumentTitle(query.contact?.name);

  const { contactId: contactId } = useParams();
  const { url, path } = useRouteMatch();
  const confirmation = useConfirmation();

  const fetchData = useCallback(async () => {
    try {
      const { data: contact } = await api.www.workspaces(workspace.id).contacts(contactId).get();
      setQuery({ isReady: true, contact });
    } catch (error) {
      setQuery({ isReady: true, contact: null, error });
    }
  }, [workspace.id, contactId, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  async function handleSaved() {
    fetchData();
  }

  const handleDelete = async () => {
    const confirm = await confirmation.prompt((resolve) => (
      <Confirmation resolve={resolve}>Are you sure you want to delete this contact?</Confirmation>
    ));

    if (!confirm) return;
    await api.www.workspaces(workspace.id).contacts(contact.id).delete();

    history.push(`/app/${workspace.key}/pipeline/contacts`);
  };

  if (!query.isReady) return <PageLoader />;
  if (!query.contact) return <ErrorPage />;

  const contact = query.contact;

  return (
    <Page scrollable>
      <ContactHeader contact={contact} />

      <Page.Tabs>
        <Tabs>
          <Tab to={`${url}/overview`}>Overview</Tab>
          <Tab />
        </Tabs>
      </Page.Tabs>

      <Switch>
        <Route path={`${path}/overview`}>
          <ContactDetailsPage contact={contact} />
        </Route>
      </Switch>

      <Route path={`${path}/:contactTab/edit`}>
        <ContactForm
          onSaved={handleSaved}
          onClose={(contactTab) => {
            history.push({ pathname: `${url}/${contactTab}`, search: location.search });
            documentTitle.set(contact.name);
          }}
          onDeleted={handleDelete}
        />
      </Route>
    </Page>
  );
}
