import React, { useCallback, useEffect, useState } from 'react';
import {
  BackLink,
  DeleteConfirmation,
  ExportDialog,
  ExportDropdown,
  FiltersBar,
  IconButton,
  ListView,
  ListViewActions,
  ListViewMenu,
  Page,
  SearchInput,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { mimeTypes } from '~/utils';
import { ActiveStatusSelect } from '../custom-data/components';
import EditDrawer from './EditDrawer';
import ViewDrawer from './ViewDrawer';

function InvoiceItemsPage() {
  const documentTitle = useDocumentTitle('Invoice Items');

  const api = useApi();
  const { workspace } = useWorkspace();
  const confirmation = useConfirmation();
  const toast = useToast();

  const [query, setQuery] = useState({ isReady: false, data: null, error: null });
  const [params, setParams] = useState({ q: '', isActive: 'true', size: 1000 });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .invoiceItems()
        .get({ ...params, isActive: params.isActive ?? undefined });
      setQuery({ isReady: true, data });
    } catch (error) {
      setQuery({ isReady: true, data: null, error });
    }
  }, [workspace.id, params, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [dialog, setDialog] = useState(null);

  const handleFilterChange = ({ target: { name, value } }) => {
    setParams((state) => ({ ...state, [name]: value }));
  };

  const handleExport = async (filename, mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onClose={resolve}
        onLoad={api.www
          .workspaces(workspace.id)
          .invoiceItems()
          .get(
            { ...params, isActive: params.isActive ?? undefined },
            { headers: { accept: mimeType }, responseType: 'blob' },
          )}
      />
    ));
  };

  const handleEdit = async (item) => {
    setDialog(
      <EditDrawer
        item={item}
        onClose={() => {
          setDialog(null);
          documentTitle.set('Invoice Items');
        }}
        onDeleted={fetchData}
        onSaved={fetchData}
      />,
    );
  };

  const handleView = async (item) => {
    setDialog(
      <ViewDrawer
        item={item}
        onClose={() => {
          setDialog(null);
          documentTitle.set('Invoice Items');
        }}
      />,
    );
  };

  async function handleActiveStatusChange(item, flag) {
    try {
      await api.www.workspaces(workspace.id).invoiceItems(item.id).setActiveStatus(flag);
      await fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  async function handleDelete(item) {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this Invoice Item?</DeleteConfirmation>
    ));
    if (!confirm) return;

    try {
      await api.www
        .workspaces(workspace.id)
        .invoiceItems(item ? item.id : undefined)
        .delete();
      await fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  if (!query.isReady) return <PageLoader />;
  if (!query.data) return <ErrorPage publicSite={false} />;

  const data = query.data;

  return (
    <>
      <Page.Header>
        <BackLink defaultPath={`/app/${workspace.key}/settings/custom-data`} />

        <Page.Info>
          <Page.Title>Invoice Items</Page.Title>
          <Page.Description>
            Every line item on a Ruddr invoice must have an invoice item. The invoice item is the category of the charge
            to the customer, such as "service" or "expense".
          </Page.Description>
        </Page.Info>

        <Page.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('invoice_items.csv', mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('invoice_items.xlsx', mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <IconButton icon="plus" tooltip="New Invoice Item" onClick={() => handleEdit({})} />
        </Page.Actions>
      </Page.Header>

      <Page.Filters>
        <FiltersBar>
          <SearchInput value={params.q} placeholder="Search" onChange={handleFilterChange} />

          <ActiveStatusSelect value={params.isActive} onChange={handleFilterChange} />
        </FiltersBar>
      </Page.Filters>

      <Page.ListView>
        <ListView>
          <ListView.Header>
            <ListView.Column name="name">Name</ListView.Column>
            <ListView.Column type="type">Type</ListView.Column>
            <ListViewActions.Column />
          </ListView.Header>

          <ListView.Body>
            {data.map((item) => {
              const { name, isActive, Type, id } = item;

              return (
                <ListView.Row
                  onClick={() => {
                    item.source === 'custom' ? handleEdit(item) : handleView(item);
                  }}
                  key={id}
                  isDisabled={!isActive}>
                  <ListView.Cell>{name}</ListView.Cell>
                  <ListView.Cell>{Type.name}</ListView.Cell>
                  <ListViewActions>
                    {item.source === 'custom' ? (
                      <>
                        <ListViewActions.Edit onClick={() => handleEdit(item)} />

                        <hr />

                        <ListViewMenu>
                          {({ setIsOpen }) => (
                            <>
                              <ListViewMenu.Item onClick={() => handleEdit(item)}>Edit</ListViewMenu.Item>
                              <ListViewMenu.Item
                                onClick={async () => {
                                  await handleActiveStatusChange(item, !isActive);
                                  setIsOpen(false);
                                }}>
                                {isActive ? 'Deactivate' : 'Activate'}
                              </ListViewMenu.Item>
                              <ListViewMenu.DeleteItem
                                tooltip="This item is currently in use."
                                onCheckDependencies={async (workspace) =>
                                  (await workspace.invoiceItems(id).hasDependencies()).data
                                }
                                onClick={() => handleDelete(item)}>
                                Delete
                              </ListViewMenu.DeleteItem>
                            </>
                          )}
                        </ListViewMenu>
                      </>
                    ) : (
                      <ListViewActions.View onClick={() => handleView(item)} />
                    )}
                  </ListViewActions>
                </ListView.Row>
              );
            })}

            {data.length === 0 && <ListView.Empty />}
          </ListView.Body>
          <ListView.Status total={data.length} label="Invoice Item" />
        </ListView>
      </Page.ListView>

      {dialog}
    </>
  );
}

export default InvoiceItemsPage;
