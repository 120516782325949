import React, { useState } from 'react';
import styled from 'styled-components';
import { Buttons, CancelButton, Currency, DeleteButton, HelpTooltip, ModalCard, Radio, RadioGroup } from '~/components';

const RadioGroupContainer = styled.div`
  margin-top: 2.5rem;

  & > *:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

function IssueCreditNoteDialog({ onResolve, total, currency }) {
  const [action, setAction] = useState('');

  const handleActionChange = (value) => {
    setAction(value);
  };

  const handleClose = () => {
    onResolve(false);
  };

  return (
    <ModalCard title="Issue Credit Note" onClose={handleClose}>
      <ModalCard.Body>
        <div>
          Are you sure that you want to issue a credit note for <Currency value={total} currency={currency} />?
        </div>

        <RadioGroupContainer>
          <RadioGroup
            name="employmentTypeId"
            value={action}
            onChange={handleActionChange}
            style={{ display: 'flex', flexDirection: 'column' }}>
            <Radio value="" label="Leave the credited items associated with the invoice as billable items" />
            <Radio
              value="setToNonBillable"
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  Leave the credited items associated with the invoice as non-billable items
                  <HelpTooltip
                    style={{ marginLeft: '.5rem' }}
                    message={
                      <div>
                        Credited fixed fee billing milestones and other items to bill will be set to a{' '}
                        {<Currency value={0} currency={currency} />} value.
                      </div>
                    }
                  />
                </div>
              }
            />
            <Radio
              value="unlinkFromInvoice"
              label="Disassociate the credited items and move them back to the Ready to Bill screen"
            />
          </RadioGroup>
        </RadioGroupContainer>
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <DeleteButton onClick={() => onResolve(action)}>Issue</DeleteButton>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}

export default IssueCreditNoteDialog;
