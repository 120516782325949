export default function getWarningMessage(warning) {
  switch (warning) {
    case 'time_pending_approval':
      return 'The services total includes time entries that are not approved.';
    case 'time_pending_client_approval':
      return 'The services total includes time entries that are not client approved.';
    case 'expenses_pending_approval':
      return 'The expenses total includes expense items that are not approved.';
    case 'expenses_pending_client_approval':
      return 'The expenses total includes expense items that are not client approved.';
    case 'unsubmitted_timesheets':
      return 'The services total includes time entries that are not on a submitted timesheet.';
    case 'time_missing_rate':
      return 'There are one or more billable time entries without a rate or with a $0 rate.';
    case 'exceeds_po_amount':
      return 'This invoice will exceed the available amount on the purchase order.';
    default:
      return null;
  }
}
