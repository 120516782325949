import _ from 'lodash';
import React from 'react';
import { HelpTooltip, MultiSelect } from '~/components';
import timeBillableTypes from '~/lookups/time-billable-types';

const options = _.values(timeBillableTypes);

export default function VarianceBillableTypeFilter({
  materialPlaceholder = 'Variance Billability',
  placeholder = 'All',
  name,
  value,
  onChange,
}) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ flex: 1 }}>
        <MultiSelect.Filter
          materialAlwaysVisible
          materialPlaceholder={materialPlaceholder}
          placeholder={placeholder}
          name={name}
          value={value}
          renderValue={(value) => value.map((v) => v.name).join('; ')}
          options={options}
          onChange={onChange}
        />
      </div>
      <HelpTooltip
        message="Applying this filter will provide you with the variance for hours and allocations of this billable type."
        style={{ marginLeft: '0.5rem' }}
      />
    </div>
  );
}
