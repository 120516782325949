import _ from 'lodash';
import React from 'react';
import { FiltersBar, InvoiceStatusFilter, Page, SearchInput, SingleSelectFilter } from '~/components';
import { useWorkspace } from '~/contexts';
import intervals from '../invoices/invoices-list/intervals';
import InvoicesListPage from '../invoices/invoices-list/InvoicesListPage';

const Filters = ({ query, handleFilter }) => (
  <>
    <Page.TabHeader>
      <Page.Title>Invoices</Page.Title>
    </Page.TabHeader>

    <Page.Filters>
      <FiltersBar>
        <SearchInput
          value={query.q}
          placeholder="Search"
          onChange={({ target: { value } }) => handleFilter({ q: value })}
        />

        <InvoiceStatusFilter
          value={query.invoiceStatuses}
          onChange={({ target: { value } }) => handleFilter({ invoiceStatuses: value })}
        />

        <SingleSelectFilter
          icon="calendar-alt"
          placeholder="Issue Date"
          options={_.map(intervals, ({ label }, key) => ({ id: key, name: label }))}
          value={query.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />
      </FiltersBar>
    </Page.Filters>
  </>
);

function ClientInvoicesTab({ client }) {
  const { workspace } = useWorkspace();

  return (
    <InvoicesListPage
      mode="tab"
      clientId={client.id}
      renderFilters={Filters}
      onRowClick={(invoice) => window.open(`/${workspace.key}/invoices/${invoice.id}?preview=true`)}
    />
  );
}

export default ClientInvoicesTab;
