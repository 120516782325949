import React, { useCallback, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import {
  DeleteConfirmation,
  FiltersBar,
  Icon,
  IconLink,
  ListView,
  ListViewActions,
  ListViewMenu,
  Page,
  SingleSelectFilter,
  Tooltip,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle, useIsMounted } from '~/hooks';
import SecurityRoleCloneConfirmation from './SecurityRoleCloneConfirmation';
import SecurityRoleForm from './SecurityRoleForm';

function SecurityRolesListPage() {
  const documentTitle = useDocumentTitle('Security Roles');

  const api = useApi();
  const { workspace } = useWorkspace();
  const [data, setData] = useState([]);
  const [cloneTarget, setCloneTarget] = useState(null);
  const toast = useToast();
  const [params, setParams] = useState({ isActive: 'true' });

  const { path, url } = useRouteMatch();
  const history = useHistory();

  const confirmation = useConfirmation();

  const isMounted = useIsMounted();

  const fetchData = useCallback(async () => {
    const { data } = await api.www
      .workspaces(workspace.id)
      .securityRoles()
      .get({ ...params, isActive: params.isActive ?? undefined });

    if (!isMounted.current) return;

    setData(data);
  }, [workspace.id, api, isMounted, params]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleEdit = (item) => {
    history.push(url.concat(`/${item.id}/edit`));
  };

  const handleDelete = async (item) => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve} title="Delete Security Role">
        Are you sure that you want to delete this security role?
      </DeleteConfirmation>
    ));
    if (!confirm) return;

    await api.www.workspaces(workspace.id).securityRoles(item.id).delete();

    fetchData();

    return true;
  };

  async function handleActiveStatusChange(item, flag) {
    try {
      await api.www.workspaces(workspace.id).securityRoles(item.id).setActiveStatus(flag);
      fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  async function handleDefaultStatusChange(item) {
    try {
      await api.www.workspaces(workspace.id).securityRoles(item.id).setDefaultStatus();
      fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  function handleCloseDrawer() {
    history.push(`/app/${workspace.key}/settings/security-roles`);
    documentTitle.set('Security Roles');
  }

  function handleCloned(item) {
    try {
      setCloneTarget(null);
      fetchData();
      handleEdit(item);
      toast.success('The security role has been cloned successfully.');
    } catch ({ message }) {
      toast.error(message);
    }
  }

  const handleChange = ({ target: { name, value } }) => {
    setParams({ ...params, [name]: value });
  };

  return (
    <Page scrollable>
      <Page.Header>
        <Page.Info>
          <Page.Eyebrow>Settings</Page.Eyebrow>
          <Page.Title>Security Roles</Page.Title>
        </Page.Info>

        <Page.Actions>
          <IconLink icon="plus" tooltip="New Security Role" to={url.concat('/new')} />
        </Page.Actions>
      </Page.Header>

      <Page.Filters>
        <FiltersBar>
          <SingleSelectFilter
            name="isActive"
            icon="filter"
            placeholder="Status"
            options={[
              { id: 'true', name: 'Active' },
              { id: 'false', name: 'Inactive' },
            ]}
            value={params.isActive}
            onChange={handleChange}
          />
        </FiltersBar>
      </Page.Filters>

      <Page.ListView>
        <ListView>
          <ListView.Header>
            <ListView.Column width="15rem" sticky>
              Name
            </ListView.Column>
            <ListView.Column minWidth="20rem">Description</ListView.Column>
            <ListView.Column width="10rem" align="right">
              Active Members
            </ListView.Column>
            <ListViewActions.Column />
          </ListView.Header>

          <ListView.Body>
            {data.map((item) => (
              <ListView.Row key={item.id} onClick={() => handleEdit(item)} isDisabled={!item.isActive}>
                <ListView.Cell>
                  <div>
                    {item.name}
                    {item.isDefault && (
                      <Tooltip
                        placement="right"
                        style={{ display: 'inline', marginLeft: '0.5rem' }}
                        message="The default security role for newly invited members.">
                        <Icon icon="badge-check" />
                      </Tooltip>
                    )}
                  </div>
                </ListView.Cell>
                <ListView.Cell style={{ whiteSpace: 'pre-wrap' }}>{item.description}</ListView.Cell>
                <ListView.Cell>{item.activeMemberCount}</ListView.Cell>
                <ListViewActions>
                  {item.manageWorkspace ? (
                    <ListViewActions.View onClick={() => handleEdit(item)} />
                  ) : (
                    <>
                      <ListViewActions.Edit onClick={() => handleEdit(item)} />
                      <hr />
                      <ListViewMenu>
                        {({ setIsOpen }) => {
                          const handleAction = async (action) => {
                            setIsOpen(false);
                            await action();
                          };

                          const disabled = item.memberCount > 0;

                          return (
                            <>
                              <ListViewMenu.Item onClick={() => handleAction(() => handleEdit(item))}>
                                Edit
                              </ListViewMenu.Item>

                              <ListViewMenu.Item
                                onClick={async () => {
                                  await handleActiveStatusChange(item, !item.isActive);
                                  setIsOpen(false);
                                }}>
                                {item.isActive ? 'Deactivate' : 'Activate'}
                              </ListViewMenu.Item>

                              <ListViewMenu.Item
                                onClick={() => {
                                  setIsOpen(false);
                                  setCloneTarget({ projectId: item.id, ...item });
                                }}>
                                Clone
                              </ListViewMenu.Item>

                              <ListViewMenu.Item
                                disabled={disabled}
                                tooltip={
                                  disabled
                                    ? 'There are active or inactive members currently assigned to this security role.'
                                    : undefined
                                }
                                onClick={() => setIsOpen(false) || handleDelete(item)}>
                                Delete
                              </ListViewMenu.Item>

                              {!item.isDefault && item.isActive && (
                                <ListViewMenu.Item
                                  onClick={async () => {
                                    await handleDefaultStatusChange(item);
                                    setIsOpen(false);
                                  }}>
                                  Set to default
                                </ListViewMenu.Item>
                              )}
                            </>
                          );
                        }}
                      </ListViewMenu>
                    </>
                  )}
                </ListViewActions>
              </ListView.Row>
            ))}

            {data.length === 0 && <ListView.Empty />}
          </ListView.Body>

          <ListView.Status total={data.length} label="Security Role" />
        </ListView>
      </Page.ListView>

      <Switch>
        <Route path={`${path}/new`}>
          <SecurityRoleForm onClose={handleCloseDrawer} onSaved={fetchData} />
        </Route>

        <Route path={`${path}/:securityRoleId/edit`}>
          <SecurityRoleForm onClose={handleCloseDrawer} onSaved={fetchData} onDelete={handleDelete} />
        </Route>
      </Switch>

      {cloneTarget && (
        <SecurityRoleCloneConfirmation
          target={cloneTarget}
          onClose={() => setCloneTarget(null)}
          onSaved={handleCloned}
        />
      )}
    </Page>
  );
}

export default SecurityRolesListPage;
