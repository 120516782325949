import { useFeatures } from '~/hooks';
import UtilizationByMember from './UtilizationByMember';
import UtilizationByMemberPractice from './UtilizationByMemberPractice';
import UtilizationByTimeUnit from './UtilizationByTimeUnit';

export default function useUtilizationReports() {
  const features = useFeatures();

  return [
    features.utilization && {
      key: 'utilization_by_time_unit',
      name: 'Utilization by Time Unit',
      description:
        'Utilization by month or week, including the total billable hours, client hours, productive hours and total capacity for each period.',
      path: '/utilization-by-time-unit',
      component: UtilizationByTimeUnit,
    },
    features.utilization && {
      key: 'utilization_by_member',
      name: 'Utilization by Member',
      description: "Utilization for each workspace member, including each member's billable hours and capacity.",
      path: '/utilization-by-member',
      component: UtilizationByMember,
    },
    features.utilization &&
      features.practices && {
        key: 'utilization_by_member_practice',
        name: 'Utilization by Member Practice',
        description:
          "Utilization for each workspace member practice, including each member's billable hours and capacity.",
        path: '/utilization-by-member-practice',
        component: UtilizationByMemberPractice,
      },
  ].filter(Boolean);
}
