import path from 'path-browserify';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { BackLink, CreateButton, Icon, IconLink, Page, Percent, RouteLink } from '~/components';
import { useIntegrations, useWorkspace } from '~/contexts';
import { colors } from '~/styles';
import { Tag, Tags } from '../components/HeaderStyledComponents';
import HubspotIndicator from '../components/HubspotIndicator';
import SalesforceIndicator from '../components/SalesforceIndicator';
import OpportunityStagesPopover from './OpportunityStagesPopover';
import OpportunityToProjectForm from './OpportunityToProjectForm';
import getGradientColor from './components/gradient';

const resolveEditPath = (to, { pathname } = {}) =>
  pathname ? (pathname.endsWith(to) ? pathname : path.resolve(pathname, to)) : '';

const PipelineStatusTag = styled(Tag)`
  .icon {
    font-size: 0.625rem;
    margin-right: 0.7rem;
    color: ${({ status, probability }) => {
      if (status === 'open') {
        // Get interpolated color based on probability
        return getGradientColor(probability);
      } else {
        // Use predefined colors for other statuses
        return (
          {
            won: colors.success,
            lost: colors.danger,
          }[status] || colors.warning0
        ); // Fallback to 'warning0' if status is not recognized
      }
    }};
  }
`;

function OpportunityHeader({ opportunity, onStageChange }) {
  const location = useLocation();
  const { workspace } = useWorkspace();
  const history = useHistory();
  const { name, opportunityStage, company, permissions } = opportunity;
  const [opportunityToProject, setOpportunityToProject] = useState(null);
  const integrations = useIntegrations();

  return (
    <>
      <Page.Header>
        <BackLink defaultPath={`/app/${workspace.key}/opportunities`} />

        <Page.Info>
          <Page.Eyebrow>Opportunity Dashboard</Page.Eyebrow>
          {opportunity && <Page.Title>{name}</Page.Title>}

          <Tags>
            <Tag>
              {company && (
                <RouteLink to={`/app/${workspace.key}/pipeline/companies/${company.id}/overview`}>
                  {company.name}
                </RouteLink>
              )}
            </Tag>
            {integrations.salesforce && opportunity.salesforceOpportunity?.salesforceId && (
              <Tag>
                <SalesforceIndicator id={opportunity.salesforceOpportunity?.salesforceId} />
              </Tag>
            )}
            {integrations.hubspot && opportunity.hubspotOpportunity?.hubspotId && (
              <Tag>
                <HubspotIndicator />
              </Tag>
            )}
            <PipelineStatusTag status={opportunityStage?.statusId} probability={opportunityStage?.probability}>
              {opportunityStage && (
                <OpportunityStagesPopover opportunity={opportunity} onStageChange={onStageChange}>
                  <Icon icon="circle" />
                  <span>
                    {opportunityStage.name}&nbsp;-&nbsp;
                    <Percent value={opportunityStage.probability / 100} minimumFractionDigits={0} />
                  </span>
                </OpportunityStagesPopover>
              )}
            </PipelineStatusTag>
            {opportunityStage.description && (
              <Tag>
                <i>{opportunityStage.description}</i>
              </Tag>
            )}
          </Tags>
        </Page.Info>

        <Page.Actions>
          {permissions.manage && !opportunity.project && (
            <CreateButton tooltip="Associate a New Project" onClick={() => setOpportunityToProject(opportunity)} />
          )}

          {permissions.manage && (
            <IconLink
              tooltip="Edit Opportunity"
              icon="pencil-alt"
              to={`${resolveEditPath('edit', location)}${location.search}`}
            />
          )}
        </Page.Actions>
      </Page.Header>

      {permissions.manage && opportunityToProject && (
        <OpportunityToProjectForm
          opportunity={opportunityToProject}
          onClose={() => setOpportunityToProject(false)}
          onSaved={(project) =>
            history.push(`/app/${workspace.key}/projects/${project.client.key}/${project.key}/overview/edit`)
          }
        />
      )}
    </>
  );
}

export default OpportunityHeader;
