import { rgba } from 'polished';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import Button from '~/components/Button';
import Icon from '~/components/Icon';
import InlineTooltip from '~/components/InlineTooltip';
import { useIsMounted, useOnClickOutside } from '~/hooks';
import { colors } from '~/styles';
import { DropdownItem, DropdownLink } from '../Dropdown';
import DropdownDeleteItem from '../DropdownDeleteItem';

const Arrow = styled.div`
  &,
  &::before {
    position: absolute;
    width: 20px;
    height: 20px;
    background: ${colors.white};
    right: 14px;
  }
  z-index: -1;
  visibility: hidden;

  &::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
`;

const Message = styled.div`
  max-width: ${({ maxWidth }) => maxWidth ?? '16rem'};
  background-color: ${colors.white};
  border: solid 1px ${colors.grey10};
  border-radius: 8px;
  z-index: 20;
  padding: 1rem;
  box-shadow: 0 3px 15px ${rgba(colors.grey100, 0.25)};
  cursor: default;

  button,
  a {
    line-height: 1.5rem;
    font-size: 0.875rem;

    &:hover,
    &:focus,
    &:active {
      border-radius: 16rem;
      background: ${colors.primary};
      color: ${colors.white};
    }
  }

  &[data-popper-placement^='top'] > ${Arrow} {
    bottom: -10px;
  }

  &[data-popper-placement^='bottom'] > ${Arrow} {
    top: -10px;
  }
`;

const PopperDropdownContainer = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  vertical-align: top;
  .icon {
    font-size: 0.75rem;
  }
`;

const PopperDropdownTrigger = styled.div`
  flex: 1;
  display: flex;

  .button {
    flex: 1;
    height: calc(100% + 1px);
    background: transparent;
    color: ${colors.primary};
    border-width: 0;
    border-radius: 0;
    margin: 0 0 -1px 0;
    padding: 1px 0 1px 0;

    .icon {
      pointer-events: none;
    }
  }

  &.active,
  &:hover,
  &:focus-within {
    .button:not(:disabled) {
      border-color: ${colors.primary};
      background-color: ${colors.primary};
      color: ${colors.white};
    }
  }
`;

const PopperDropdownContext = React.createContext();

const PopperDropdown = forwardRef(function Dropdown(
  { children, align = 'left', position = 'auto', offsetToShowMenuOnTop = 25, className, ...props },
  ref,
) {
  const [isOpen, setIsOpen] = useState(false);
  const isMounted = useIsMounted();

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const handleSetIsOpen = (flag) => {
    if (!isMounted.current) return;
    setIsOpen(!!flag);
  };

  return (
    <PopperDropdownContext.Provider value={{ align, position, isOpen, offsetToShowMenuOnTop }}>
      <PopperDropdownContainer ref={ref} className={className} {...props}>
        {typeof children === 'function' ? children({ setIsOpen: handleSetIsOpen, isOpen }) : children}
      </PopperDropdownContainer>
    </PopperDropdownContext.Provider>
  );
});

PopperDropdown.Trigger = PopperDropdownTrigger;

function Popper({
  children,
  placement = 'top-start',
  maxWidth,
  offsetTop = 8,
  offsetLeft = 0,
  visible,
  onClickOutside,
}) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const modifiers = useMemo(() => {
    const modifiers = [
      { name: 'offset', options: { offset: [offsetLeft, offsetTop] } },
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: ['top-start', 'bottom-start'],
        },
      },
      { name: 'arrow', options: { element: arrowElement } },
    ];

    return modifiers;
  }, [arrowElement, offsetTop, offsetLeft]);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers,
    placement,
    strategy: 'fixed',
  });

  const ref = useRef();
  useOnClickOutside(ref, onClickOutside);

  return (
    <div ref={setReferenceElement}>
      {visible &&
        children &&
        ReactDOM.createPortal(
          <Message ref={setPopperElement} maxWidth={maxWidth} style={styles.popper} {...attributes.popper}>
            <div ref={ref}>{children}</div>
            <Arrow ref={setArrowElement} style={styles.arrow} />
          </Message>,
          document.body,
        )}
    </div>
  );
}

function ListViewMenu({ tooltip, children, disabled, ...props }) {
  const dropdownRef = useRef();
  return (
    <PopperDropdown ref={dropdownRef} align="right" {...props} onClick={(e) => e.stopPropagation()}>
      {({ setIsOpen, ...props }) => (
        <>
          <PopperDropdown.Trigger className={props.isOpen ? 'active' : undefined}>
            <Button
              disabled={disabled}
              onClick={(event) => {
                event.stopPropagation();
                setIsOpen(true);
              }}>
              <Icon icon="chevron-down" />
              {tooltip && <InlineTooltip message={tooltip} placement="left" />}
            </Button>
          </PopperDropdown.Trigger>
          <Popper anchor={dropdownRef} visible={props.isOpen} onClickOutside={() => setIsOpen(false)}>
            {typeof children === 'function' ? children({ setIsOpen }) : children}
          </Popper>
        </>
      )}
    </PopperDropdown>
  );
}

ListViewMenu.Item = DropdownItem;
ListViewMenu.Link = DropdownLink;
ListViewMenu.DeleteItem = DropdownDeleteItem;

export default ListViewMenu;
