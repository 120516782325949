import { Formik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Button, Buttons, CancelButton, Field, Form, FormMessage, ModalCard } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';
import { dateFormats, emptyStringToNull } from '~/utils';

const Description = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const ErrorMessage = styled(FormMessage.Error)`
  margin-bottom: 1.5rem;
`;

const Checkboxes = styled.div`
  display: flex;
  flex-direction: column;

  > :not(:first-child) {
    margin-top: 0.5rem;
  }
`;

export default function ManualSync({ integration, onClose, onFinish }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const startDate = integration.settings.timeOffStartDate;
  const endDate = moment().isAfter(startDate)
    ? moment().add(6, 'months').format(dateFormats.isoDate)
    : moment(startDate).add(6, 'months').format(dateFormats.isoDate);

  const initialValues = {
    syncMembers: integration.settings.syncMembers,
    syncTimeOff: integration.settings.syncTimeOff,
    startDate,
    endDate,
  };

  async function handleSubmit(values) {
    setIsLoading(true);
    setErrorMessage();

    if (!values.syncTimeOff) {
      values.startDate = null;
      values.endDate = null;
    }

    try {
      await api.www.workspaces(workspace.id).rippling.manualSync(emptyStringToNull(values));
      onFinish();
      onClose();
    } catch ({ message }) {
      if (message) {
        setErrorMessage(message);
      } else {
        setErrorMessage('There was a problem completing your request. Please try again later.');
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false);
      }
    }
  }

  return (
    <ModalCard title="Manual Rippling Sync" onClose={onClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          syncMembers: Yup.bool()
            .label('Sync Members')
            .required()
            .test({
              name: 'sync-one',
              message: 'When syncing, you must select at least one of members or time off requests',
              test: (_, context) => context.parent.syncMembers || context.parent.syncTimeOff,
            }),
          syncTimeOff: Yup.bool().label('Sync Time Off Requests').required(),
          startDate: Yup.date()
            .label('Time Off Start Date')
            .when('syncTimeOff', { is: true, then: (schema) => schema.required() }),
          endDate: Yup.date()
            .label('Time Off End Date')
            .when('syncTimeOff', {
              is: true,
              then: (schema) => schema.min(Yup.ref('startDate'), 'End Date must be on or after Start Date.').required(),
              else: (schema) => schema.nullable(),
            }),
        })}>
        {({ values }) => {
          return (
            <Form>
              <ModalCard.Body>
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                <Description>
                  Start a new manual sync to test your settings or import legacy data. Choose which data and a start and
                  end date (if syncing time off requests) to sync with Rippling.
                </Description>
                <Form.Control>
                  <Checkboxes>
                    <Field.Checkbox name="syncMembers" label="Sync Members" />
                    <Field.Checkbox name="syncTimeOff" label="Sync Time Off Requests" />
                  </Checkboxes>
                </Form.Control>
                {values.syncTimeOff && (
                  <>
                    <Form.Control>
                      <Field.DayPicker name="startDate" placeholder="Time Off Start Date" clearable={false} />
                    </Form.Control>
                    <Form.Control>
                      <Field.DayPicker name="endDate" placeholder="Time Off End Date" clearable={false} />
                    </Form.Control>
                  </>
                )}
              </ModalCard.Body>
              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={onClose} style={{ marginRight: 'auto' }}>
                    Close
                  </CancelButton>
                  <Button type="submit" isLoading={isLoading}>
                    Start Sync
                  </Button>
                </Buttons>
              </ModalCard.Footer>
            </Form>
          );
        }}
      </Formik>
    </ModalCard>
  );
}
