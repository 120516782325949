import _ from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import { useApi, useWorkspace } from '~/contexts';
import { clientRecordStatuses, memberInvitationStatuses, projectHealthRatings, projectRecordStatuses } from '~/lookups';
import allocationBillableTypes from '~/lookups/allocation-billable-types';
import approvalStatuses from '~/lookups/approval-statuses';
import clientApprovalStatuses from '~/lookups/client-approval-statuses';
import creditNoteStatuses from '~/lookups/credit-note-statuses';
import employmentTypes from '~/lookups/employment-types';
import invoiceStatuses from '~/lookups/invoice-statuses.js';
import opportunityStageCategories from '~/lookups/opportunity-stage-categories';
import projectBillingTypes from '~/lookups/project-billing-types';
import projectStatuses from '~/lookups/project-statuses';
import projectTaskStatuses from '~/lookups/project-task-statuses';
import timeBillableTypes from '~/lookups/time-billable-types';
import { intervalOptions, QuerySort } from '~/utils';
import { sortIntervals } from '~/utils/intervalOptions';
import useFeatures from './useFeatures';

export default function useSearchParamsConfig() {
  const api = useApi();
  const { workspace } = useWorkspace();
  const features = useFeatures();

  const config = useMemo(
    () => ({
      allocationBillableType: {
        valid: ['billable', 'non_billable', 'internal', 'time_off'],
        serialize: function (value) {
          return this.default && !value ? 'all' : value;
        },
        deserialize: (value) => (value === 'all' ? null : value),
      },

      allocationBillableTypes: {
        multi: true,
        key: 'allocationBillableType',
        valid: ['all', ..._.map(allocationBillableTypes, (v) => v.id)],
        serialize: function (value) {
          return !!this.default && !value.length ? 'all' : value?.map((v) => v.id);
        },
        deserialize: (ids) =>
          ids.length === 1 && ids[0] === 'all' ? [] : ids.map((id) => allocationBillableTypes[id]),
      },

      amount: {
        key: 'amount',
        serialize: function (value) {
          return Number(value) ? value : 0;
        },
        deserialize: (value) => {
          return Number(value) && !_.isNaN(value) ? Number(value) : 0;
        },
      },

      approvalStatus: { valid: _.map(approvalStatuses, (v) => v.id) },

      approvalStatuses: {
        multi: true,
        key: 'status',
        valid: _.map(approvalStatuses, (v) => v.id),
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) => ids.map((id) => approvalStatuses[id]),
      },

      businessUnits: {
        multi: true,
        valid: !features.businessUnits ? [] : undefined,
        serialize: function (value) {
          return !!this.default && value.length === 0 ? ['all'] : value?.map((v) => v.id);
        },
        deserialize: (ids) =>
          ids.length === 1 && ids[0] === 'all'
            ? []
            : api.www
                .workspaces(workspace.id)
                .businessUnits()
                .get({ ids })
                .then(({ data }) => data),
      },

      boolean: {
        default: false,
        serialize: (value) => (value === true ? 'true' : 'false'),
        deserialize: (value) => value === 'true',
      },

      company: {
        serialize: (value) => value?.id,
        deserialize: (ids) =>
          ids === 'all'
            ? null
            : api.www
                .workspaces(workspace.id)
                .companies()
                .get({ ids })
                .then(({ data }) => data[0]),
      },

      companies: {
        multi: true,
        key: 'company',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .companies()
            .get({ ids })
            .then(({ data }) => data),
      },

      client: {
        serialize: (value) => value?.id,
        deserialize: (ids) =>
          ids === 'all'
            ? null
            : api.www
                .workspaces(workspace.id)
                .clients()
                .get({ ids })
                .then(({ data }) => data[0]),
      },

      clients: {
        multi: true,
        key: 'client',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .clients()
            .get({ ids })
            .then(({ data }) => data),
      },

      clientApprovalStatuses: {
        multi: true,
        key: 'clientStatus',
        valid: features.clientApprovals ? _.map(clientApprovalStatuses, (v) => v.id) : [],
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) => ids.map((id) => clientApprovalStatuses[id]),
      },

      clientRecordStatusId: {
        valid: ['all', ..._.map(clientRecordStatuses, (v) => v.id)],
        serialize: function (value) {
          return this.default && !value ? 'all' : value;
        },
        deserialize: (value) => (value === 'all' ? null : value),
      },

      clientTags: {
        multi: true,
        key: 'clientTag',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .clientTags()
            .get({ ids })
            .then(({ data }) => data),
      },

      creditNoteStatuses: {
        multi: true,
        key: 'creditNoteStatus',
        default: [creditNoteStatuses.draft, creditNoteStatuses.open],
        valid: ['all', ..._.map(creditNoteStatuses, (v) => v.id)],
        serialize: function (value) {
          return !!this.default && value.length === 0 ? ['all'] : value?.map((v) => v.id);
        },
        deserialize: (ids) => (ids.length === 1 && ids[0] === 'all' ? [] : ids.map((id) => creditNoteStatuses[id])),
      },

      currency: {
        default: workspace.currency,
        deserialize: (code) =>
          api
            .currencies()
            .get({ code })
            .then(({ data }) => data[0]?.code),
      },

      disciplines: {
        multi: true,
        valid: !features.disciplines ? [] : undefined,
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .disciplines()
            .get({ ids })
            .then(({ data }) => data),
      },

      employmentType: { valid: _.map(employmentTypes, (v) => v.id) },

      employmentTypes: {
        multi: true,
        key: 'employmentType',
        valid: ['all', ..._.map(employmentTypes, (v) => v.id)],
        serialize: function (value) {
          return !!this.default && value.length === 0 ? ['all'] : value?.map((v) => v.id);
        },
        deserialize: (ids) => (ids.length === 1 && ids[0] === 'all' ? [] : ids.map((id) => employmentTypes[id])),
      },

      expenseCategories: {
        multi: true,
        key: 'expenseCategory',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .expenseCategories()
            .get({ ids })
            .then(({ data }) => data),
      },

      expenseType: { valid: ['member', 'project'] },

      expenseBillableType: { valid: ['billable', 'non_billable'] },

      expenseReimbursableType: { valid: ['reimbursable', 'non_reimbursable'] },

      industries: {
        multi: true,
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .industries()
            .get({ ids })
            .then(({ data }) => data),
      },

      invoiced: { valid: ['yes', 'no'] },

      invoiceStatuses: {
        multi: true,
        key: 'invoiceStatus',
        valid: ['all', ..._.map(invoiceStatuses, (v) => v.id)],
        serialize: function (value) {
          return !!this.default && value.length === 0 ? ['all'] : value?.map((v) => v.id);
        },
        deserialize: (ids) => (ids.length === 1 && ids[0] === 'all' ? [] : ids.map((id) => invoiceStatuses[id])),
      },

      invoiceItems: {
        multi: true,
        key: 'invoiceItem',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .invoiceItems()
            .get({ ids })
            .then(({ data }) => data),
      },

      isReimbursed: { valid: ['yes', 'no'] },

      jobTitles: {
        multi: true,
        key: 'jobTitle',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .jobTitles()
            .get({ ids })
            .then(({ data }) => data),
      },

      locations: {
        multi: true,
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .locations()
            .get({ ids })
            .then(({ data }) => data),
      },

      member: {
        serialize: (value) => value?.id,
        deserialize: (ids) =>
          ids === 'all'
            ? null
            : api.www
                .workspaces(workspace.id)
                .members()
                .options({ ids })
                .then(({ data }) => data[0]),
      },

      members: {
        multi: true,
        key: 'member',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .members()
            .options({ ids })
            .then(({ data }) => data),
      },

      excludeMembers: {
        multi: true,
        key: 'x_member',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .members()
            .options({ ids })
            .then(({ data }) => data),
      },

      memberStatus: {
        valid: ['all', 'active', 'inactive'],
        serialize: function (value) {
          return this.default && !value ? 'all' : value;
        },
        deserialize: (value) => (value === 'all' ? null : value),
      },

      projectTeamStatus: {
        valid: ['all', 'active', 'inactive'],
        serialize: function (value) {
          return this.default && !value ? 'all' : value;
        },
        deserialize: (value) => (value === 'all' ? null : value),
      },

      memberInvitationStatus: { valid: _.map(memberInvitationStatuses, (v) => v.id) },

      memberInvitationStatuses: {
        multi: true,
        key: 'memberInvitationStatus',
        valid: _.map(memberInvitationStatuses, (v) => v.id),
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) => ids.map((id) => memberInvitationStatuses[id]),
      },

      memberBillableType: {
        valid: ['all', 'billable', 'non_billable'],
        serialize: function (value) {
          return this.default && !value ? 'all' : value;
        },
        deserialize: (value) => (value === 'all' ? null : value),
      },

      memberCostMethod: { valid: ['fixed', 'hourly'] },

      skills: {
        multi: true,
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .skills()
            .get({ ids })
            .then(({ data }) => data),
      },

      memberTags: {
        multi: true,
        key: 'memberTag',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .memberTags()
            .get({ ids })
            .then(({ data }) => data),
      },

      opportunityStages: {
        multi: true,
        key: 'opportunityStage',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) => {
          return api.www
            .workspaces(workspace.id)
            .opportunityStages()
            .get({ ids })
            .then(({ data }) => data);
        },
      },

      opportunityStageCategories: {
        multi: true,
        key: 'opportunityStageCategoryId',
        default: [opportunityStageCategories.open],
        valid: ['all', ..._.map(opportunityStageCategories, (v) => v.id)],
        serialize: function (value) {
          return !!this.default && value.length === 0 ? ['all'] : value?.map((v) => v.id);
        },
        deserialize: (ids) => {
          return ids.length === 1 && ids[0] === 'all' ? [] : ids.map((id) => opportunityStageCategories[id]);
        },
      },

      opportunityType: {
        serialize: (value) => value?.id,
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .opportunityTypes()
            .get({ opportunityTypeId: ids })
            .then(({ data }) => data[0]),
      },

      opportunityTypes: {
        multi: true,
        key: 'opportunityType',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) => {
          return api.www
            .workspaces(workspace.id)
            .opportunityTypes()
            .get({ opportunityTypeId: ids })
            .then(({ data }) => data);
        },
      },

      opportunityTags: {
        multi: true,
        key: 'opportunityTag',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .opportunityTags()
            .get({ ids })
            .then(({ data }) => data),
      },

      paymentMethod: {
        serialize: (value) => value?.id,
        deserialize: (ids) =>
          ids === 'all'
            ? null
            : api.www
                .workspaces(workspace.id)
                .paymentMethods()
                .get({ ids })
                .then(({ data }) => data[0]),
      },

      paymentMethods: {
        multi: true,
        key: 'paymentMethods',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .paymentMethods()
            .get({ ids })
            .then(({ data }) => data),
      },

      period: {
        keys: ['period', 'start', 'end'],
        intervals: intervalOptions,
        serialize: function (value) {
          const [periodKey, startKey, endKey] = this.keys;
          return { [periodKey]: value.key, [startKey]: value.start ?? 'not_set', [endKey]: value.end ?? 'not_set' };
        },
        deserialize: function (value, urlSearchParams) {
          const [periodKey, startKey, endKey] = this.keys;
          const dates = urlSearchParams.get('dates');
          const period = urlSearchParams.get(periodKey);

          let option;
          if (dates === 'relative' && period && period !== 'custom') {
            option = _.find(sortIntervals(this.intervals), (i) => i.key === period);
          }

          if (option) {
            return { start: option.start, end: option.end, key: option.key, unit: option.unit };
          } else {
            let start = urlSearchParams.get(startKey);
            if (!moment(start, true).isValid()) {
              if (start !== 'not_set') urlSearchParams.delete(startKey);
              start = null;
            }

            let end = urlSearchParams.get(endKey);
            if (!moment(end, true).isValid()) {
              if (end !== 'not_set') urlSearchParams.delete(endKey);
              end = null;
            }

            option =
              _.find(sortIntervals(this.intervals), (i) => i.start === start && i.end === end) ??
              intervalOptions.custom;

            return { start, end, key: option.key, unit: option.unit };
          }
        },
      },

      periodByUnit: {
        keys: ['period', 'start', 'end', 'unit'],
        intervals: () => intervalOptions,
        units: ['day', 'week', 'month'],
        serialize: function (value) {
          const [periodKey, startKey, endKey] = this.keys;
          return { [periodKey]: value.key, [startKey]: value.start ?? 'not_set', [endKey]: value.end ?? 'not_set' };
        },
        deserialize: function (value, urlSearchParams) {
          const [periodKey, startKey, endKey, unitKey] = this.keys;
          let unit = this.units.find((u) => u === urlSearchParams.get(unitKey)) ?? 'month';

          const intervals = this.intervals(unit);

          const dates = urlSearchParams.get('dates');
          const period = urlSearchParams.get(periodKey);
          let option;
          if (dates === 'relative' && period && period !== 'custom') {
            option = _.find(intervals, (i) => i.key === period);
          }

          if (option) {
            return { start: option.start, end: option.end, key: option.key, unit: option.unit };
          } else {
            urlSearchParams.delete(periodKey);

            let start = urlSearchParams.get(startKey);
            if (!moment(start, true).isValid()) {
              if (start !== 'not_set') urlSearchParams.delete(startKey);
              start = null;
            }

            let end = urlSearchParams.get(endKey);
            if (!moment(end, true).isValid()) {
              if (end !== 'not_set') urlSearchParams.delete(endKey);
              end = null;
            }

            if (!start || !end) {
              option = this.defaults[unit];
            } else {
              option =
                _.find(sortIntervals(intervals), (i) => i.unit === unit && i.start === start && i.end === end) ??
                intervalOptions.custom;
            }

            return { start: start ?? option.start, end: end ?? option.end, key: option.key, unit: unit };
          }
        },
      },

      practice: {
        valid: !features.practices ? [] : undefined,
        serialize: (value) => value?.id,
        deserialize: (ids) =>
          ids === 'all'
            ? null
            : api.www
                .workspaces(workspace.id)
                .practices()
                .get({ ids })
                .then(({ data }) => data[0]),
      },

      practices: {
        multi: true,
        valid: !features.practices ? [] : undefined,
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .practices()
            .get({ ids })
            .then(({ data }) => data),
      },

      project: {
        serialize: (value) => value?.id,
        deserialize: (ids) =>
          ids === 'all'
            ? null
            : api.www
                .workspaces(workspace.id)
                .projects()
                .selectOptions({ ids, activeOnly: false })
                .then(({ data }) => data[0]),
      },

      projects: {
        multi: true,
        key: 'project',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .projects()
            .selectOptions({ ids, activeOnly: false })
            .then(({ data }) => data),
      },

      projectBillingType: {
        valid: ['all', 'tm', 'fixed', 'fixed_recurring', 'non_billable'],
        serialize: (value) => value || 'all',
        deserialize: (value) => (value === 'all' ? null : value),
      },

      projectBillingTypes: {
        multi: true,
        key: 'projectBillingType',
        valid: ['all', ..._.map(projectBillingTypes, (v) => v.id)],
        serialize: function (value) {
          return !!this.default && value.length === 0 ? ['all'] : value?.map((v) => v.id);
        },
        deserialize: (ids) => (ids.length === 1 && ids[0] === 'all' ? [] : ids.map((id) => projectBillingTypes[id])),
      },

      projectRecordStatusId: {
        valid: ['all', ..._.map(projectRecordStatuses, (v) => v.id)],
        serialize: function (value) {
          return this.default && !value ? 'all' : value;
        },
        deserialize: (value) => (value === 'all' ? null : value),
      },

      projectBudgetHealth: {
        multi: true,
        key: 'projectBudgetHealth',
        valid: features.projectHealth ? _.map(projectHealthRatings, (v) => v.id) : [],
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) => ids.map((id) => projectHealthRatings[id]),
      },

      projectScheduleHealth: {
        multi: true,
        key: 'projectScheduleHealth',
        valid: features.projectHealth ? _.map(projectHealthRatings, (v) => v.id) : [],
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) => ids.map((id) => projectHealthRatings[id]),
      },

      projectClientSatisfaction: {
        multi: true,
        key: 'projectClientSatisfaction',
        valid: features.projectHealth ? _.map(projectHealthRatings, (v) => v.id) : [],
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) => ids.map((id) => projectHealthRatings[id]),
      },

      projectTeamSatisfaction: {
        multi: true,
        key: 'projectTeamSatisfaction',
        valid: features.projectHealth ? _.map(projectHealthRatings, (v) => v.id) : [],
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) => ids.map((id) => projectHealthRatings[id]),
      },

      projectRoles: {
        multi: true,
        key: 'projectRole',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids, urlSearchParams) => {
          const projectId = urlSearchParams.get('project');

          if (!projectId) {
            urlSearchParams.delete('projectRole');
            return null;
          }

          return api.www
            .workspaces(workspace.id)
            .projects(projectId)
            .roles()
            .get({ ids })
            .then(({ data }) => data);
        },
      },

      projectTasks: {
        multi: true,
        key: 'projectTask',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids, urlSearchParams) => {
          const projectId = urlSearchParams.get('project');

          if (!projectId) {
            urlSearchParams.delete('projectTask');
            return null;
          }

          return api.www
            .workspaces(workspace.id)
            .projects(projectId)
            .tasks()
            .get({ ids })
            .then(({ data }) => data);
        },
      },

      projectRecordStatuses: {
        multi: true,
        key: 'projectRecordStatus',
        valid: ['all', 'active', 'archived'],
        serialize: function (value) {
          return !!this.default && value.length === 0 ? ['all'] : value?.map((v) => v.id);
        },
        deserialize: (ids) => (ids.length === 1 && ids[0] === 'all' ? [] : ids.map((id) => projectRecordStatuses[id])),
      },

      projectStatus: { valid: _.map(projectStatuses, (v) => v.id) },

      projectStatuses: {
        multi: true,
        key: 'projectStatus',
        valid: ['all', ..._.map(projectStatuses, (v) => v.id)],
        serialize: function (value) {
          return !!this.default && value.length === 0 ? ['all'] : value?.map((v) => v.id);
        },
        deserialize: (ids) => (ids.length === 1 && ids[0] === 'all' ? [] : ids.map((id) => projectStatuses[id])),
      },

      projectTaskStatuses: {
        multi: true,
        key: 'projectTaskStatus',
        valid: ['all', ..._.map(projectTaskStatuses, (v) => v.id)],
        serialize: function (value) {
          return !!this.default && value.length === 0 ? ['all'] : value?.map((v) => v.id);
        },
        deserialize: (ids) => (ids.length === 1 && ids[0] === 'all' ? [] : ids.map((id) => projectTaskStatuses[id])),
      },

      projectTypes: {
        multi: true,
        key: 'projectType',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .projectTypes()
            .get({ ids })
            .then(({ data }) => data),
      },

      recordStatusId: {
        valid: ['all', 'active', 'archived'],
        serialize: function (value) {
          return this.default && !value ? 'all' : value;
        },
        deserialize: (value) => (value === 'all' ? null : value),
      },

      resourceStatus: {
        valid: ['all', 'active', 'inactive'],
        serialize: function (value) {
          return this.default && !value ? 'all' : value;
        },
        deserialize: (value) => (value === 'all' ? null : value),
      },

      securityRole: {
        serialize: (value) => value?.id,
        deserialize: (ids) =>
          ids === 'all'
            ? null
            : api.www
                .workspaces(workspace.id)
                .securityRoles()
                .get({ ids })
                .then(({ data }) => data[0]),
      },

      securityRoles: {
        multi: true,
        key: 'securityRole',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .securityRoles()
            .get({ ids })
            .then(({ data }) => data),
      },

      sort: {
        deserialize: (value) => {
          const parts = value.split(':');
          const column = parts.slice(0, parts.length - 1).join(':');
          const direction = parts[parts.length - 1];

          return new QuerySort(column, direction);
        },
      },

      projectTags: {
        multi: true,
        key: 'projectTag',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .projectTags()
            .get({ ids })
            .then(({ data }) => data),
      },

      projectTaskTags: {
        multi: true,
        key: 'projectTaskTag',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) =>
          api.www
            .workspaces(workspace.id)
            .projectTaskTags()
            .get({ ids })
            .then(({ data }) => data),
      },

      timeBillableType: { valid: ['billable', 'non_billable', 'internal', 'time_off'] },

      timeBillableTypes: {
        multi: true,
        key: 'billableType',
        valid: ['all', ..._.map(timeBillableTypes, (v) => v.id)],
        serialize: function (value) {
          return !!this.default && value.length === 0 ? ['all'] : value?.map((v) => v.id);
        },
        deserialize: (ids) => (ids.length === 1 && ids[0] === 'all' ? [] : ids.map((id) => timeBillableTypes[id])),
      },

      timeOffTypes: {
        multi: true,
        key: 'timeOffType',
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) => {
          return api.www
            .workspaces(workspace.id)
            .timeOffTypes()
            .get({ ids })
            .then(({ data }) => data);
        },
      },

      varianceBillableTypes: {
        multi: true,
        key: 'varianceBillableType',
        valid: _.map(timeBillableTypes, (v) => v.id),
        serialize: (value) => value?.map((v) => v.id),
        deserialize: (ids) => ids.map((id) => timeBillableTypes[id]),
      },
    }),
    [api, workspace.id, features, workspace.currency],
  );

  return config;
}
