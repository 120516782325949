import path from 'path-browserify';
import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { BackLink, ClientLink, Icon, InternalClientTooltip, Page } from '~/components';
import IconLink from '~/components/IconLink.jsx';
import { useWorkspace } from '~/contexts';
import { colors } from '~/styles';
import ProjectDropdown from './ProjectDropdown.jsx';
import ProjectStatusesPopover from './ProjectStatusesPopover';

const resolveEditPath = (to, { pathname } = {}) =>
  pathname ? (pathname.endsWith(to) ? pathname : path.resolve(pathname, to)) : '';

const Tags = styled.div`
  display: flex;
  color: ${colors.grey55};
  font-size: 0.75rem;
  margin-top: 0.25rem;
`;

const Tag = styled.span`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem 0.4rem;
  border-left: solid 1px ${colors.grey10};

  &:first-child {
    padding-left: 0;
    border-left: none;
  }

  &:last-child {
    padding-right: 0;
  }
`;

const StatusTag = styled(Tag)`
  color: ${({ status }) =>
    ({
      tentative: colors.grey25,
      not_started: colors.warning,
      in_progress: colors.success,
      paused: colors.danger,
      completed: colors.black,
      cancelled: colors.grey55,
    })[status]};

  .icon {
    font-size: 0.625rem;
    margin-right: 0.7rem;
  }
`;

function ProjectHeader({ project, onChange }) {
  const location = useLocation();
  const { workspace } = useWorkspace();
  const { status, client } = project;

  return (
    <Page.Header>
      <BackLink defaultPath={`/app/${workspace.key}/projects`} />
      <Page.Info>
        <Page.Eyebrow>Project Dashboard</Page.Eyebrow>
        <ProjectDropdown project={project} />
        <Tags>
          <Tag>
            <ClientLink client={client} />
            {client.isInternal && <InternalClientTooltip />}
          </Tag>
          <StatusTag status={status.id}>
            {project.permissions.edit ? (
              <ProjectStatusesPopover projectId={project.id} currentStatus={status} onStatusChange={onChange}>
                <Icon icon="circle" />
                <span>{status.name}</span>
              </ProjectStatusesPopover>
            ) : (
              <>
                <Icon icon="circle" />
                <span>{status.name}</span>
              </>
            )}
          </StatusTag>
        </Tags>
      </Page.Info>

      <Page.Actions>
        {project.permissions.edit && (
          <>
            <IconLink
              tooltip="Project History"
              icon="history"
              to={`${resolveEditPath('history', location)}${location.search}`}
            />

            <IconLink
              tooltip="Edit Project"
              icon="pencil-alt"
              to={`${resolveEditPath('edit', location)}${location.search}`}
              data-testid="edit-project"
            />
          </>
        )}
      </Page.Actions>
    </Page.Header>
  );
}

export default ProjectHeader;
