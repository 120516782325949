const holidaySchedules = [
  { name: 'Argentinian Holidays', description: 'Traditional holidays in Argentina.', code: 'AR' },
  { name: 'Australian Holidays', description: 'Traditional holidays in Australia.', code: 'AU' },
  { name: 'Belgian Holidays', description: 'Traditional holidays in Belgium.', code: 'BE' },
  { name: 'Brazilian Holidays', description: 'Traditional holidays in Brazil.', code: 'BR' },
  { name: 'Canadian Holidays', description: 'Traditional holidays in Canada.', code: 'CA' },
  { name: 'Chilean Holidays', description: 'Traditional holidays in Chile.', code: 'CL' },
  { name: 'Danish Holidays', description: 'Traditional holidays in Denmark.', code: 'DK' },
  { name: 'Finnish Holidays', description: 'Traditional holidays in Finland.', code: 'FI' },
  { name: 'French Holidays', description: 'Traditional holidays in France.', code: 'FR' },
  { name: 'German Holidays', description: 'Traditional holidays in Germany.', code: 'DE' },
  { name: 'Indian Holidays', description: 'Traditional holidays in India.', code: 'IN' },
  { name: 'Irish Holidays', description: 'Traditional holidays in Ireland.', code: 'IE' },
  { name: 'Israeli Holidays', description: 'Traditional holidays in Israel.', code: 'IL' },
  { name: 'Italian Holidays', description: 'Traditional holidays in Italy.', code: 'IT' },
  { name: 'Japanese Holidays', description: 'Traditional holidays in Japan.', code: 'JP' },
  { name: 'Mexican Holidays', description: 'Traditional holidays in Mexico.', code: 'MX' },
  { name: 'Dutch Holidays', description: 'Traditional holidays in the Netherlands.', code: 'NL' },
  { name: 'New Zealand Holidays', description: 'Traditional holidays in New Zealand.', code: 'NZ' },
  { name: 'Norwegian Holiday', description: 'Traditional holidays in Norway.', code: 'NO' },
  { name: 'Polish Holidays', description: 'Traditional holidays in Poland.', code: 'PL' },
  { name: 'Portuguese Holidays', description: 'Traditional holidays in Portugal.', code: 'PT' },
  { name: 'South African Holidays', description: 'Traditional holidays in South Africa.', code: 'ZA' },
  { name: 'Spanish Holidays', description: 'Traditional holidays in Spain.', code: 'ES' },
  { name: 'Swedish Holidays', description: 'Traditional holidays in Sweden.', code: 'SE' },
  { name: 'Swiss Holidays', description: 'Traditional holidays in Switzerland.', code: 'CH' },
  { name: 'Ukrainian Holidays', description: 'Traditional holidays in Ukraine.', code: 'UA' },
  { name: 'U.K. Holidays', description: 'Traditional holidays in the U.K.', code: 'GB' },
  { name: 'U.S. Holidays', description: 'Traditional holidays in the United States.', code: 'US' },
];

export default holidaySchedules;
