import Big from 'big.js';
import _ from 'lodash';

const getInitialMarkupRatioValue = ({ isNew = false, markupRatio, defaultMarkupPercentage }) => {
  if (!isNew && _.isNumber(markupRatio)) {
    return Big(markupRatio).times(100).toNumber();
  }

  if (_.isNumber(defaultMarkupPercentage)) {
    return defaultMarkupPercentage;
  }

  return '';
};

export default getInitialMarkupRatioValue;
