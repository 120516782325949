import path from 'path-browserify';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { BackLink, IconLink, Page } from '~/components';
import { useIntegrations, useWorkspace } from '~/contexts';
import { Tag, Tags } from '../components/HeaderStyledComponents';
import HubspotIndicator from '../components/HubspotIndicator';
import SalesforceIndicator from '../components/SalesforceIndicator';

const resolveEditPath = (to, { pathname } = {}) =>
  pathname ? (pathname.endsWith(to) ? pathname : path.resolve(pathname, to)) : '';

function ContactHeader({ contact }) {
  const location = useLocation();
  const { workspace } = useWorkspace();
  const integrations = useIntegrations();

  return (
    <Page.Header>
      <BackLink defaultPath={`/app/${workspace.key}/contacts`} />
      <Page.Info>
        <Page.Eyebrow>Contact Dashboard</Page.Eyebrow>
        <Page.Title>{contact.name}</Page.Title>

        <Tags>
          {contact.title && (
            <Tag>
              <i>{contact.title}</i>
            </Tag>
          )}
          {integrations.salesforce && contact.salesforceId && (
            <Tag>
              <SalesforceIndicator />
            </Tag>
          )}
          {integrations.hubspot && contact.hubspotContact?.hubspotId && (
            <Tag>
              <HubspotIndicator />
            </Tag>
          )}
        </Tags>
      </Page.Info>

      <Page.Actions>
        {contact.permissions.manage && (
          <IconLink
            tooltip="Edit Contact"
            icon="pencil-alt"
            to={`${resolveEditPath('edit', location)}${location.search}`}
          />
        )}
      </Page.Actions>
    </Page.Header>
  );
}

export default ContactHeader;
