import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import {
  Currency,
  DeleteConfirmation,
  FiltersBar,
  IconLink,
  ListView,
  ListViewActions,
  ListViewMenu,
  Page,
  SearchInput,
  SingleSelectFilter,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { QuerySort } from '~/utils';
import PurchaseOrderForm from './PurchaseOrderForm';
import PurchaseOrderView from './PurchaseOrderView';

const Small = styled.small`
  display: block;
`;

export default function PurchaseOrdersTab({ client }) {
  const documentTitle = useDocumentTitle();

  const { workspace } = useWorkspace();
  const api = useApi();
  const history = useHistory();
  const { purchaseOrderId, action } = useParams();
  const { url } = useRouteMatch();
  const [{ data, isReady, isFetching }, setQuery] = useState({ data: null, isReady: false, isFetching: false });
  const toast = useToast();
  const confirmation = useConfirmation();

  const [params, setParams] = useState({
    q: '',
    isActive: 'true',
    sort: new QuerySort('name', 'asc'),
  });

  const fetchData = useCallback(async () => {
    setQuery((query) => ({ ...query, isFetching: true }));

    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .clients(client.id)
        .purchaseOrders({ ...params, isActive: params.isActive ?? undefined });

      setQuery({ data, isReady: true, isFetching: false });
    } catch (error) {
      setQuery({ data: null, isReady: true, isFetching: false });
    }
  }, [workspace.id, api, params, client.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = ({ target: { name, value } }) => {
    setQuery((query) => ({ ...query, isFetching: true }));
    setParams((params) => ({ ...params, [name]: value }));
  };

  const handleSort = ({ column, sort }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams((state) => ({ ...state, sort: new QuerySort(column, direction) }));
  };

  function handleClose() {
    history.push(`/app/${workspace.key}/clients/${client.key}/purchase-orders`);
    documentTitle.set(client.name);
  }

  const handleDeleteConfirmation = async (purchaseOrder) => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this purchase order?</DeleteConfirmation>
    ));

    if (!confirm) return;
    await api.www.workspaces(workspace.id).purchaseOrders(purchaseOrder.id).delete();

    await handleDeleted();
  };

  async function handleSaved() {
    fetchData();
  }

  async function handleDeleted() {
    fetchData();
    handleClose();
  }

  async function handleCreated() {
    fetchData();
  }

  async function handleActiveStatusChange(purchaseOrder, flag) {
    try {
      await api.www.workspaces(workspace.id).purchaseOrders(purchaseOrder.id).setActiveStatus(flag);

      await fetchData();
    } catch (error) {
      toast.error(error.message || 'There was a problem updating the purchase order status. Please try again.');
    }
  }

  const handleView = ({ id }) => {
    history.push(`/app/${workspace.key}/clients/${client.key}/purchase-orders/${id}/view`);
  };

  const handleEdit = ({ id }) => {
    history.push(`/app/${workspace.key}/clients/${client.key}/purchase-orders/${id}/edit`);
  };

  if (!isReady) return <PageLoader />;
  if (!data) return <ErrorPage.NotFound publicSite={false} />;

  return (
    <>
      <Page.TabHeader>
        <Page.Title>Purchase Orders</Page.Title>

        <Page.Actions>
          <IconLink icon="plus" tooltip="New Purchase Order" to={`${url}/new`} />
        </Page.Actions>
      </Page.TabHeader>

      <Page.Filters>
        <FiltersBar>
          <SearchInput value={params.q} placeholder="Search" onChange={handleChange} />

          <SingleSelectFilter
            icon="filter"
            name="isActive"
            value={params.isActive}
            options={[
              { id: 'true', name: 'Active' },
              { id: 'false', name: 'Inactive' },
            ]}
            placeholder="Status"
            onChange={handleChange}
          />
        </FiltersBar>
      </Page.Filters>

      <Page.ListView>
        <ListView>
          <ListView.Header>
            <ListView.Column sticky minWidth="15rem" name="name" onSort={handleSort} sort={params.sort}>
              Name
            </ListView.Column>
            <ListView.Column minWidth="15rem">PO Number</ListView.Column>
            <ListView.Column align="right">PO Amount</ListView.Column>
            <ListView.Column align="right">Amount Invoiced</ListView.Column>
            <ListView.Column align="right">Amount Available</ListView.Column>
            <ListViewActions.Column />
          </ListView.Header>

          <ListView.Body fade={isFetching}>
            {data.map((purchaseOrder) => {
              const {
                amount,
                convertedAmount,
                invoicedAmount,
                convertedInvoicedAmount,
                remainingAmount,
                convertedRemainingAmount,
                currency,
                id,
                isActive,
                name,
                number,
                hasInvoices,
                hasCreditNotes,
                hasProjects,
              } = purchaseOrder;

              async function handleRowClick(purchaseOrder) {
                if (client.permissions.edit) {
                  handleEdit(purchaseOrder);
                } else {
                  handleView(purchaseOrder);
                }
              }

              const hasDependencies = hasInvoices || hasCreditNotes || hasProjects;

              return (
                <ListView.Row isDisabled={!isActive} key={id} onClick={() => handleRowClick(purchaseOrder)}>
                  <ListView.Cell>{name}</ListView.Cell>
                  <ListView.Cell>{number}</ListView.Cell>
                  <ListView.Cell>
                    <p>
                      <Currency
                        value={convertedAmount}
                        currency={client.currency}
                        minimumFractionDigits={0}
                        maximumFractionDigits={2}
                      />
                      {currency !== client.currency && (
                        <Small>
                          <Currency
                            value={amount}
                            currency={currency}
                            minimumFractionDigits={0}
                            maximumFractionDigits={2}
                          />
                        </Small>
                      )}
                    </p>
                  </ListView.Cell>
                  <ListView.Cell>
                    <p>
                      <Currency
                        value={convertedInvoicedAmount}
                        currency={client.currency}
                        minimumFractionDigits={0}
                        maximumFractionDigits={2}
                      />
                      {currency !== client.currency && (
                        <Small>
                          <Currency
                            value={invoicedAmount}
                            currency={currency}
                            minimumFractionDigits={0}
                            maximumFractionDigits={2}
                          />
                        </Small>
                      )}
                    </p>
                  </ListView.Cell>
                  <ListView.Cell>
                    <p>
                      <Currency
                        value={convertedRemainingAmount}
                        currency={client.currency}
                        minimumFractionDigits={0}
                        maximumFractionDigits={2}
                      />
                      {currency !== client.currency && (
                        <Small>
                          <Currency
                            value={remainingAmount}
                            currency={currency}
                            minimumFractionDigits={0}
                            maximumFractionDigits={2}
                          />
                        </Small>
                      )}
                    </p>
                  </ListView.Cell>

                  <ListViewActions>
                    {client.permissions.edit ? (
                      <ListViewActions.Edit onClick={() => handleEdit(purchaseOrder)} />
                    ) : (
                      <ListViewActions.View onClick={() => handleView(purchaseOrder)} />
                    )}

                    <hr />

                    <ListViewMenu>
                      {({ setIsOpen }) => {
                        const handleAction = (action) => setIsOpen(false) || action;

                        return (
                          <>
                            <ListViewMenu.Item onClick={() => handleAction(handleView(purchaseOrder))}>
                              View
                            </ListViewMenu.Item>

                            <ListViewMenu.Item
                              disabled={!client.permissions.edit}
                              tooltip={
                                !client.permissions.edit
                                  ? 'Insufficient permissions to edit this purchase order.'
                                  : undefined
                              }
                              onClick={() => handleAction(handleEdit(purchaseOrder))}>
                              Edit
                            </ListViewMenu.Item>

                            <ListViewMenu.DeleteItem
                              disabled={!client.permissions.edit || hasDependencies}
                              tooltip={
                                !client.permissions.edit
                                  ? 'Insufficient permissions to delete this purchase order.'
                                  : hasDependencies
                                    ? 'This purchase order has one or more projects, invoices or credit notes associated.'
                                    : undefined
                              }
                              onClick={() => handleAction(handleDeleteConfirmation(purchaseOrder))}>
                              Delete
                            </ListViewMenu.DeleteItem>

                            <ListViewMenu.Item
                              disabled={!client.permissions.edit}
                              tooltip={
                                !client.permissions.edit
                                  ? `Insufficient permissions to ${isActive ? 'deactivate' : 'activate'} this purchase order.`
                                  : undefined
                              }
                              onClick={() => handleAction(handleActiveStatusChange(purchaseOrder, !isActive))}>
                              {isActive ? 'Deactivate' : 'Activate'}
                            </ListViewMenu.Item>
                          </>
                        );
                      }}
                    </ListViewMenu>
                  </ListViewActions>
                </ListView.Row>
              );
            })}

            {data.length === 0 && <ListView.Empty />}
          </ListView.Body>

          <ListView.Status total={data.length} label="Purchase Order" isLoading={isFetching} />
        </ListView>
      </Page.ListView>

      {action &&
        {
          new: <PurchaseOrderForm client={client} onSaved={handleCreated} onClose={handleClose} />,
          edit: purchaseOrderId && (
            <PurchaseOrderForm
              id={purchaseOrderId}
              client={client}
              onSaved={handleSaved}
              onDeleted={handleDeleted}
              onClose={handleClose}
            />
          ),
          view: purchaseOrderId && <PurchaseOrderView id={purchaseOrderId} onClose={handleClose} />,
        }[action]}
    </>
  );
}
