import _ from 'lodash';
import React from 'react';
import {
  ApprovalStatusFilter,
  ClientApprovalStatusFilter,
  DisciplineFilter,
  InvoicedFilter,
  TimeBillableTypeFilter,
  TimeOffTypeFilter,
} from '~/components';
import ReportPeriodFilter from './ReportPeriodFilter';

export default function TimeFiltersBar({ filters, onChange }) {
  return (
    <>
      {(filters.createdPeriod?.start || filters.createdPeriod?.end) && (
        <ReportPeriodFilter
          placeholder="Created Date Range"
          value={filters.createdPeriod}
          onChange={({ value }) => onChange({ createdPeriod: value })}
        />
      )}

      {(filters.modifiedPeriod?.start || filters.modifiedPeriod?.end) && (
        <ReportPeriodFilter
          placeholder="Modified Date Range"
          value={filters.modifiedPeriod}
          onChange={({ value }) => onChange({ modifiedPeriod: value })}
        />
      )}

      {!_.isEmpty(filters.billableTypes) && (
        <TimeBillableTypeFilter
          value={filters.billableTypes}
          onChange={({ target: { value } }) => onChange({ billableTypes: value })}
        />
      )}

      {!_.isEmpty(filters.timeOffTypes) && (
        <TimeOffTypeFilter
          value={filters.timeOffTypes}
          onChange={({ target: { value } }) => onChange({ timeOffTypes: value })}
        />
      )}

      {!_.isEmpty(filters.approvalStatuses) && (
        <ApprovalStatusFilter
          value={filters.approvalStatuses}
          onChange={({ target: { value } }) => onChange({ approvalStatuses: value })}
        />
      )}

      {!_.isEmpty(filters.clientApprovalStatuses) && (
        <ClientApprovalStatusFilter
          value={filters.clientApprovalStatuses}
          onChange={({ target: { value } }) => onChange({ clientApprovalStatuses: value })}
        />
      )}

      {!_.isEmpty(filters.invoiced) && (
        <InvoicedFilter value={filters.invoiced} onChange={({ target: { value } }) => onChange({ invoiced: value })} />
      )}

      {!_.isEmpty(filters.roleDisciplines) && (
        <DisciplineFilter
          value={filters.roleDisciplines}
          placeholder="Role Discipline"
          onChange={({ target: { value } }) => onChange({ roleDisciplines: value })}
        />
      )}
    </>
  );
}
