import React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import {
  FiltersBar,
  IconButton,
  Page,
  ProjectRecordStatusFilter,
  ProjectStatusFilter,
  SearchInput,
} from '~/components';
import { useAuth } from '~/hooks';
import ProjectsListPage from '../projects/ProjectsListPage';

const Filters = ({ query: { params }, onFilter }) => {
  const auth = useAuth();
  const history = useHistory();
  const { url } = useRouteMatch();
  const location = useLocation();

  const handleChange = ({ target: { name, value } }) => {
    onFilter({ [name]: value });
  };

  return (
    <>
      <Page.TabHeader>
        <Page.Title>Projects</Page.Title>

        <Page.Actions>
          <IconButton
            tooltip={auth.projects.create ? 'New Project' : 'Your security role prohibits you from creating projects.'}
            icon="plus"
            onClick={() => history.push(`${url}/new${location.search}`)}
            disabled={!auth.projects.create}
          />
        </Page.Actions>
      </Page.TabHeader>

      <Page.Filters>
        <FiltersBar>
          <SearchInput value={params.q} placeholder="Search" onChange={handleChange} />

          <ProjectStatusFilter name="projectStatuses" value={params.projectStatuses} onChange={handleChange} />

          <ProjectRecordStatusFilter
            name="projectRecordStatusId"
            value={params.projectRecordStatusId}
            onChange={handleChange}
          />
        </FiltersBar>
      </Page.Filters>
    </>
  );
};

function ClientProjectsTab({ client }) {
  return (
    <ProjectsListPage
      mode="tab"
      clientId={client.id}
      client={client}
      renderFilters={(props) => <Filters {...props} />}
    />
  );
}

export default ClientProjectsTab;
