import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { DeleteConfirmation, Dropdown, IconButton } from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import SavedReportForm from './SavedReportForm';
import { SavedReportContext } from './SavedReportPage';

export default function SavedReportActions({ report, onSave }) {
  const savedReportContext = useContext(SavedReportContext);
  const savedReport = savedReportContext?.savedReport;

  const { workspace } = useWorkspace();
  const location = useLocation();

  const toast = useToast();
  const api = useApi();

  const [dialog, setDialog] = useState(null);

  const confirmation = useConfirmation();

  const handleCloseDialog = () => {
    setDialog(null);
  };

  const handleAdd = async () => {
    let search = location.search;
    if (_.isFunction(onSave)) search = onSave();
    setDialog(
      <SavedReportForm
        title="Save Report"
        savedReport={{ name: report.name, description: report.description, reportKey: report.reportKey }}
        search={search}
        onClose={handleCloseDialog}
        onSaved={handleCloseDialog}
      />,
    );
  };

  const handleSave = async () => {
    let search = location.search;
    if (_.isFunction(onSave)) search = onSave();
    const params = _.toArray(new URLSearchParams(search ?? location.search).entries());
    await api.www.workspaces(workspace.id).reports().saved.update(savedReport?.id, { params });
    toast.success('Saved report updated.');
    savedReportContext.onRefetch();
  };

  const handleSaveAs = async () => {
    let search = location.search;
    if (_.isFunction(onSave)) search = onSave();
    setDialog(
      <SavedReportForm
        title="Save Report As"
        savedReport={{
          name: report.name,
          description: report.description,
          reportKey: report.reportKey,
          params: report.params,
        }}
        search={search}
        onClose={handleCloseDialog}
        onSaved={handleCloseDialog}
      />,
    );
  };

  const handleDelete = async () => {
    await confirmation.prompt((resolve) => {
      return (
        <DeleteConfirmation
          title="Delete saved report"
          resolve={async (confirm) => {
            if (confirm) {
              await api.www.workspaces(workspace.id).reports().saved.remove(savedReport.id);
              toast.success('Saved report deleted.');
              history.replace(`/app/${workspace.key}/reports/saved`);
            }
            resolve(true);
          }}>
          Are you sure you want to delete the saved report "{savedReport.name}"?
        </DeleteConfirmation>
      );
    });
  };

  const handleEdit = async () => {
    let search = location.search;
    if (_.isFunction(onSave)) search = onSave();
    setDialog(
      <SavedReportForm
        title="Edit Report"
        savedReport={savedReport}
        search={search}
        onClose={handleCloseDialog}
        onSaved={() => {
          savedReportContext.onRefetch();
          handleCloseDialog();
        }}
        onDelete={() => handleDelete()}
      />,
    );
  };

  const history = useHistory();

  return (
    <>
      {savedReport ? (
        <>
          {savedReport.permissions.manage ? (
            <>
              <IconButton icon="pencil" onClick={handleEdit} tooltip="Edit" />

              <Dropdown align="right">
                {({ setIsOpen }) => (
                  <>
                    <Dropdown.Trigger>
                      <IconButton icon="floppy-disk" onClick={() => setIsOpen(true)} tooltip="Save" />
                    </Dropdown.Trigger>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          setIsOpen(false);
                          handleSave();
                        }}>
                        Save
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setIsOpen(false);
                          handleSaveAs();
                        }}>
                        Save as
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </>
                )}
              </Dropdown>
            </>
          ) : (
            <IconButton tooltip="Save As" icon="floppy-disk" onClick={handleSaveAs} />
          )}
        </>
      ) : (
        <IconButton tooltip="Save" icon="floppy-disk" onClick={handleAdd} />
      )}

      {dialog}
    </>
  );
}
