import _ from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import {
  CreateButton,
  Currency,
  DateTime,
  Duration,
  FiltersBar,
  Icon,
  MultilineText,
  Page,
  Panel,
  Pill,
  Pills,
  PipelineActivityTypeFilter,
  ProjectLink,
  RouteLink,
  SearchInput,
  Stack,
} from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useNumberFormat } from '~/hooks';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';
import PipelineActivityForm from '../activity/PipelineActivityForm';
import { ActivityItem } from '../components/ActivityItem';
import OpportunityStatusSection from './OpportunityStatusSection';
import OpportunityBoxes from './OpportunitySummaryBoxes';

const GroupHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  background-color: ${colors.grey5};
  color: ${colors.grey75};
  width: 100%;
  margin-bottom: 1rem;
  cursor: pointer;
  &:hover {
    background-color: ${colors.grey25};
  }
`;

const NoResults = styled.div`
  color: ${colors.grey40};
`;

function OpportunityDetailsPage({ opportunity, onChange }) {
  const documentTitle = useDocumentTitle(opportunity.name);

  const { workspace } = useWorkspace();
  const api = useApi();
  const auth = useAuth();
  const [company, setCompany] = useState(null);
  const [groupedOptions, setGroupedOptions] = useState([]);
  const [showActivities, setShowActivities] = useState({ upcoming: true, last30Days: true, olderThan30Days: true });
  const [status, setStatus] = useState('loading');
  const [dialog, setDialog] = useState(null);
  const [params, setParams] = useState({ q: '', typeIds: [] });

  const fetchData = useCallback(async () => {
    try {
      const { data: activities } = await api.www
        .workspaces(workspace.id)
        .pipelineActivities()
        .get({
          opportunityId: opportunity.id,
          q: params.q,
          typeId: params.typeIds.length ? params.typeIds.map((v) => v.id) : [],
        });

      const groups =
        activities &&
        activities.reduce((a, v) => {
          let activityDate = moment(v.date);
          let now = moment();
          let thirtyDaysAgo = moment().subtract(30, 'days');
          let key;

          if (activityDate.isAfter(now)) {
            key = 'upcoming';
          } else if (activityDate.isAfter(thirtyDaysAgo)) {
            key = 'last30Days';
          } else {
            key = 'olderThan30Days';
          }

          a[key] = a[key] || { key, activities: [] };
          a[key].activities.push(v);
          return a;
        }, {});

      const groupOrder = ['upcoming', 'last30Days', 'olderThan30Days'];
      const orderedGroups = _.orderBy(groups, (group) => groupOrder.indexOf(group.key));

      const options = [];
      for (const group of orderedGroups) {
        options.push({ type: 'group', group, count: group.activities.length });
        group.activities.forEach((activity) => {
          options.push({ type: 'activity', activity, group });
        });
      }

      setGroupedOptions(options);
      setStatus('ready');
    } catch (error) {
      setGroupedOptions(null);
    }
  }, [api, opportunity, params.q, params.typeIds, workspace.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.www.workspaces(workspace.id).companies(opportunity.company.id).get();
        setCompany(data);
      } catch (err) {
        // Do nothing
      }
    })();
  }, [api, workspace.id, opportunity.company.id]);

  const handleChange = ({ target: { name, value } }) => {
    setStatus('filtering');
    setParams((params) => ({ ...params, [name]: value }));
  };

  function handleOpenActivityForm({ activity }) {
    setDialog(
      <PipelineActivityForm
        opportunity={opportunity}
        activity={activity}
        onClose={() => {
          setDialog(null);
          documentTitle.set(opportunity.name);
        }}
        onSaved={() => {
          setDialog(null);
          documentTitle.set(opportunity.name);
          fetchData();
          onChange();
        }}
      />,
    );
  }

  const toggleShowActivities = (key) => {
    setShowActivities((current) => ({
      ...current,
      [key]: !current[key],
    }));
  };

  const numberFormat = useNumberFormat({ minimumFractionDigits: 0, maximumFractionDigits: 0 });

  if (!opportunity) return <ErrorPage.NotFound />;

  return (
    <>
      <Page.Content>
        <Page.Main>
          <Page.Section sticky>
            {opportunity && (
              <>
                <OpportunityBoxes data={opportunity} />
                <OpportunityStatusSection data={opportunity} />
              </>
            )}

            <Page.Header>
              <Page.Title>Activities</Page.Title>

              <Page.Actions>
                {auth.pipeline.manage && (
                  <CreateButton tooltip="Add Activity" onClick={() => handleOpenActivityForm({})} />
                )}
              </Page.Actions>
            </Page.Header>

            <Page.Filters>
              <FiltersBar>
                <SearchInput value={params.q} placeholder="Search" onChange={handleChange} />

                <PipelineActivityTypeFilter
                  name="typeIds"
                  value={params.typeIds}
                  entity="opportunity"
                  onChange={handleChange}
                />
              </FiltersBar>
            </Page.Filters>
          </Page.Section>

          <Page.Section>
            <Stack style={{ opacity: `${status === 'filtering' ? 0.2 : 1}` }}>
              {status === 'loading' && <PageLoader />}

              {groupedOptions?.length > 0 &&
                groupedOptions.map((o) => {
                  switch (o.type) {
                    case 'group':
                      return (
                        <GroupHeader key={o.group.key} onClick={() => toggleShowActivities(o.group.key)}>
                          <div style={{ textTransform: 'uppercase' }}>
                            <strong>
                              {
                                {
                                  upcoming: 'Upcoming',
                                  last30Days: 'Last 30 Days',
                                  olderThan30Days: ' Older Than 30 Days',
                                }[o.group.key]
                              }
                            </strong>
                            <Icon icon={showActivities[o.group.key] ? 'angle-up' : 'angle-down'} spaceLeft />
                          </div>

                          <div>{pluralize('Activity', parseInt(numberFormat.format(o.count), 10), true)}</div>
                        </GroupHeader>
                      );
                    case 'activity': {
                      const item = o.activity;
                      const participants = [
                        ...item.members.map((activityMember) => activityMember.member.name),
                        ...item.contacts.map((activityContact) => activityContact.contact.name),
                      ];

                      if (!showActivities[o.group.key]) return null;

                      return (
                        <ActivityItem
                          key={item.id}
                          item={item}
                          handleOpenActivityForm={handleOpenActivityForm}
                          participants={participants}
                          entity="opportunity"
                        />
                      );
                    }
                    default:
                      return null;
                  }
                })}
              {status === 'ready' && groupedOptions?.length === 0 && <NoResults>No Results</NoResults>}
            </Stack>
          </Page.Section>
        </Page.Main>

        <Panel>
          <Panel.Title>Opportunity Details</Panel.Title>

          {opportunity.timeSpent > 0 && (
            <Panel.Info title="Time Spent">
              <Duration minutes={opportunity.timeSpent} useDecimal={false} /> hs
            </Panel.Info>
          )}

          {opportunity.owner && (
            <Panel.Info title="Owner">
              <Panel.Member member={opportunity.owner} />
            </Panel.Info>
          )}

          {opportunity.practice && <Panel.Info title="Practice">{opportunity.practice.name}</Panel.Info>}

          {opportunity.description && (
            <Panel.Info title="Description">
              <MultilineText
                value={opportunity.description}
                chars={280}
                align="left"
                buttonProps={{ isAnchor: true }}
              />
            </Panel.Info>
          )}

          <Panel.Info title="Currency">
            {opportunity.currencyName} ({opportunity.currency})
          </Panel.Info>

          {opportunity.project && (
            <Panel.Info title="Project">
              <ProjectLink project={opportunity.project} />
            </Panel.Info>
          )}

          {opportunity?.tags?.length > 0 && (
            <Panel.Info title="Tags">
              <Pills>
                {opportunity.tags.map((tag) => (
                  <Link key={tag.id} to={`/app/${workspace.key}/pipeline/opportunities?opportunityTag=${tag.id}`}>
                    <Pill variant="grey10">{tag.name}</Pill>
                  </Link>
                ))}
              </Pills>
            </Panel.Info>
          )}

          {opportunity.opportunityType?.name && (
            <Panel.Info title="Type">{opportunity.opportunityType?.name}</Panel.Info>
          )}

          {opportunity.opportunityLeadSource?.name && (
            <Panel.Info title="Lead Source">{opportunity.opportunityLeadSource?.name}</Panel.Info>
          )}

          {opportunity.closeDate && (
            <Panel.Info title="Expected Close">
              <DateTime value={opportunity.closeDate} />
            </Panel.Info>
          )}

          <Panel.Separator />

          <Panel.Title>Company Details</Panel.Title>

          <Panel.Info title="Company Name">
            <RouteLink to={`/app/${workspace.key}/pipeline/companies/${opportunity.company.id}/overview`}>
              {opportunity.company.name}
            </RouteLink>
          </Panel.Info>

          {company && (
            <>
              {company.owner && (
                <Panel.Info title="Relationship Owner">
                  <Panel.Member member={company.owner} />
                </Panel.Info>
              )}

              {company.client && (
                <Panel.Info title="Client">
                  <RouteLink to={`/app/${workspace.key}/clients/${company.client.key}`}>
                    {company.client.name}
                  </RouteLink>
                </Panel.Info>
              )}

              {company.industry && <Panel.Info title="Company Industry">{company.industry.name}</Panel.Info>}

              {company.practice && <Panel.Info title="Practice">{company.practice.name}</Panel.Info>}

              <Panel.Info title="Currency">
                {company.currencyName} ({company.currency})
              </Panel.Info>

              {company.revenue && (
                <Panel.Info title="Revenue">
                  <Currency value={company.revenue} currency={company.currency} />
                </Panel.Info>
              )}
            </>
          )}
        </Panel>
      </Page.Content>

      {dialog}
    </>
  );
}

export default OpportunityDetailsPage;
