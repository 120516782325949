import _ from 'lodash';
import React from 'react';
import {
  BusinessUnitFilter,
  MemberFilter,
  PracticeFilter,
  ProjectBillingTypeFilter,
  ProjectFilter,
  ProjectHealthFilter,
  ProjectRecordStatusFilter,
  ProjectRoleFilter,
  ProjectStatusFilter,
  ProjectTagFilter,
  ProjectTypeFilter,
} from '~/components';
import { useAuth, useFeatures } from '~/hooks';
import FiltersGroup from './FiltersGroup';
import ReportPeriodFilter from './ReportPeriodFilter';

function ProjectFiltersGroup({ filters, onChange }) {
  const features = useFeatures();
  const auth = useAuth();

  return (
    <FiltersGroup
      label="Project Filters"
      filters={[
        filters.projects,
        filters.projectRoles,
        filters.projectBillingTypes,
        filters.projectStatuses,
        filters.projectRecordStatusId,
        filters.projectTags,
        filters.projectPractices,
        filters.projectBusinessUnits,
        filters.projectTypes,
        filters.projectAdmins,
        filters.projectSalesRepresentatives,
        filters.projectBudgetHealth,
        filters.projectScheduleHealth,
        filters.projectClientSatisfaction,
        filters.projectTeamSatisfaction,
        filters.startedDatePeriod?.start,
        filters.startedDatePeriod?.end,
        filters.endedDatePeriod?.start,
        filters.endedDatePeriod?.end,
        filters.createdAtPeriod?.start,
        filters.createdAtPeriod?.end,
        filters.completedOnPeriod?.start,
        filters.completedOnPeriod?.end,
      ]}>
      {_.has(filters, 'projects') && (
        <ProjectFilter
          clients={filters.clients}
          value={filters.projects}
          onChange={({ target: { value } }) => {
            const result = {
              projects: value,
            };

            if (_.has(filters, 'projectRoles'))
              result.projectRoles =
                value.length > 1 || value[0]?.id !== filters.projects[0]?.id ? [] : filters.projectRoles;

            if (_.has(filters, 'projectTasks'))
              result.projectTasks =
                value.length > 1 || value[0]?.id !== filters.projects[0]?.id ? [] : filters.projectTasks;

            onChange(result);
          }}
        />
      )}

      {_.has(filters, 'projectRoles') && filters.projects?.length === 1 && filters.projects[0]?.useRoles && (
        <ProjectRoleFilter
          project={filters.projects[0]}
          value={filters.projectRoles}
          onChange={({ target: { value } }) => onChange({ projectRoles: value })}
        />
      )}

      {_.has(filters, 'projectBillingTypes') && (
        <ProjectBillingTypeFilter
          value={filters.projectBillingTypes}
          onChange={({ target: { value } }) => onChange({ projectBillingTypes: value })}
        />
      )}

      {_.has(filters, 'projectStatuses') && (
        <ProjectStatusFilter
          value={filters.projectStatuses}
          onChange={({ target: { value } }) => onChange({ projectStatuses: value })}
        />
      )}

      {_.has(filters, 'projectRecordStatusId') && (
        <ProjectRecordStatusFilter
          value={filters.projectRecordStatusId}
          onChange={({ target: { value } }) => onChange({ projectRecordStatusId: value })}
        />
      )}

      {_.has(filters, 'projectTags') && (
        <ProjectTagFilter
          value={filters.projectTags}
          onChange={({ target: { value } }) => onChange({ projectTags: value })}
        />
      )}

      {features.practices && _.has(filters, 'projectPractices') && (
        <PracticeFilter
          value={filters.projectPractices}
          placeholder="Project Practice"
          onChange={({ target: { value } }) => onChange({ projectPractices: value })}
        />
      )}

      {features.businessUnits && _.has(filters, 'projectBusinessUnits') && (
        <BusinessUnitFilter
          value={filters.projectBusinessUnits}
          placeholder="Project Business Unit"
          onChange={({ target: { value } }) => onChange({ projectBusinessUnits: value })}
        />
      )}

      {_.has(filters, 'projectTypes') && (
        <ProjectTypeFilter
          value={filters.projectTypes}
          onChange={({ target: { value } }) => onChange({ projectTypes: value })}
        />
      )}

      {_.has(filters, 'projectAdmins') && (
        <MemberFilter
          value={filters.projectAdmins}
          placeholder="Project Admin"
          onChange={({ target: { value } }) => onChange({ projectAdmins: value })}
        />
      )}

      {_.has(filters, 'projectSalesRepresentatives') && (
        <MemberFilter
          value={filters.projectSalesRepresentatives}
          placeholder="Project Sales Representative"
          onChange={({ target: { value } }) => onChange({ projectSalesRepresentatives: value })}
        />
      )}

      {_.has(filters, 'startedDatePeriod') && (
        <ReportPeriodFilter
          placeholder="Project Start Date"
          value={filters.startedDatePeriod}
          onChange={({ target: { value } }) => onChange({ startedDatePeriod: value })}
        />
      )}

      {_.has(filters, 'endedDatePeriod') && (
        <ReportPeriodFilter
          placeholder="Project End Date"
          value={filters.endedDatePeriod}
          onChange={({ target: { value } }) => onChange({ endedDatePeriod: value })}
        />
      )}

      {_.has(filters, 'createdAtPeriod') && (
        <ReportPeriodFilter
          placeholder="Project Create Date"
          value={filters.createdAtPeriod}
          onChange={({ target: { value } }) => onChange({ createdAtPeriod: value })}
        />
      )}

      {_.has(filters, 'completedOnPeriod') && (
        <ReportPeriodFilter
          placeholder="Project Completed Date"
          value={filters.completedOnPeriod}
          onChange={({ target: { value } }) => onChange({ completedOnPeriod: value })}
        />
      )}

      {features.projectHealth && auth.projects.viewHealth && (
        <>
          {_.has(filters, 'projectBudgetHealth') && (
            <ProjectHealthFilter
              placeholder="Budget Health"
              value={filters.projectBudgetHealth}
              onChange={({ target: { value } }) => onChange({ projectBudgetHealth: value })}
            />
          )}

          {_.has(filters, 'projectScheduleHealth') && (
            <ProjectHealthFilter
              placeholder="Schedule Health"
              value={filters.projectScheduleHealth}
              onChange={({ target: { value } }) => onChange({ projectScheduleHealth: value })}
            />
          )}

          {_.has(filters, 'projectClientSatisfaction') && (
            <ProjectHealthFilter
              placeholder="Client Satisfaction"
              value={filters.projectClientSatisfaction}
              onChange={({ target: { value } }) => onChange({ projectClientSatisfaction: value })}
            />
          )}

          {_.has(filters, 'projectTeamSatisfaction') && (
            <ProjectHealthFilter
              placeholder="Team Satisfaction"
              value={filters.projectTeamSatisfaction}
              onChange={({ target: { value } }) => onChange({ projectTeamSatisfaction: value })}
            />
          )}
        </>
      )}
    </FiltersGroup>
  );
}

export default ProjectFiltersGroup;
