import { Widget } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useActions, useHoursFormat } from '~/hooks';
import React, { useCallback, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom';
import { colors } from '~/styles';
import { dateFormats, QueryString } from '~/utils';
import moment from 'moment';

const initialState = { isReady: false, data: null };
const handlers = { ready: ({ data }) => ({ isReady: true, data }) };

function ProjectHoursWidget({ project, period }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const [{ data, isReady }, actions] = useActions(handlers, initialState);

  const fetchData = useCallback(async () => {
    const { data } = await api.www
      .workspaces(workspace.id)
      .projects(project.id)
      .getProjectHoursKPI({ date: period.start ?? undefined });

    actions.ready({ data });
    // Intentionally passing the full "project" as a dependency since the query needs to re-run if this object changes
  }, [actions, workspace.id, project, period, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Widget stretched centered loading={!isReady}>
      <Widget.Header>
        <Widget.Title>Hours</Widget.Title>
      </Widget.Header>
      <Widget.Preview style={{ minHeight: '14rem' }} />
      <Widget.Content style={{ minHeight: '14rem' }}>
        {data && <Data project={project} data={data} period={period} />}
      </Widget.Content>
    </Widget>
  );
}

function Data({ project, data, period }) {
  const { workspace } = useWorkspace();

  const hoursFormat = {
    tooltip: useHoursFormat({ minimumFractionDigits: 0, maximumFractionDigits: 2 }),
    ticks: useHoursFormat({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
  };

  const history = useHistory();

  const chartData = {
    labels: ['Actual'],
    datasets: [
      {
        id: project.client.isInternal ? 'internal' : 'non_billable',
        label: 'Non-Billable        ',
        data: [data.actual.nonBillable],
        backgroundColor: colors.danger,
        maxBarThickness: 100,
      },
    ],
  };

  if (project.isBillable) {
    chartData.datasets.unshift({
      id: 'billable',
      // There's no option to configure the legend spacing, so adding spaces to separate each legend
      // TODO: find a way to render a custom legend to match the design more closely
      label: 'Billable        ',
      data: [data.actual.billable],
      backgroundColor: colors.success,
      maxBarThickness: 100,
    });
  }

  if (data.budget) {
    chartData.labels.push('Budget');

    if (project.isBillable) {
      chartData.datasets[0].data.push(data.budget.billable);
      chartData.datasets[1].data.push(data.budget.nonBillable);
    } else {
      chartData.datasets[0].data.push(data.budget.nonBillable);
    }
  }

  const indexes = { actual: 0, budget: 1 };

  const handleClick = (_event, [element]) => {
    if (!element) return;

    const index = element.index;
    const datasetIndex = element.datasetIndex;

    if (index === indexes.budget) return;

    const billableType = chartData.datasets[datasetIndex]?.id;

    setTimeout(
      () =>
        history.push({
          pathname: `/app/${workspace.key}/reports/time-entries`,
          search: new QueryString({
            start: period.start ?? 'not_set',
            end: period.end ?? moment().format(dateFormats.isoDate),
            project: project.id,
            billableType,
          }).toString(),
        }),
      1,
    );
  };

  const chartOptions = {
    maintainAspectRatio: false,

    layout: {
      padding: { top: 12 },
    },

    plugins: {
      legend: {
        position: 'bottom',
        onClick: null,
        labels: {
          font: {
            size: 12,
          },
          pointStyleWidth: 14,
          boxHeight: 10,
          filter: (item) => !item.text.includes('Total'),
          usePointStyle: true,
        },
      },

      tooltip: {
        callbacks: {
          title: () => '',
          label: (tooltip) => {
            let label = (tooltip.dataset.label || '').trim();
            if (label) {
              label += ': ';
            }
            label += hoursFormat.tooltip.format(tooltip.parsed.y);
            return label;
          },
        },
      },
    },

    scales: {
      x: {
        stacked: true,
        grid: { display: false },
        border: { display: false },
        ticks: {
          font: {
            size: 12,
            weight: 'bold',
          },
          color: colors.grey40,
        },
      },

      y: {
        display: true,
        stacked: true,
        grid: { display: true, color: colors.grey10 },
        border: { display: false },
        min: 0,
        suggestedMax: 100,
        ticks: {
          maxTicksLimit: 10,
          color: colors.grey40,
          callback: (label) => hoursFormat.ticks.format(label),
        },
      },
    },

    onHover: (e, chartElement) => {
      const index = chartElement[0]?.index;
      e.native.target.style.cursor = index === indexes.actual ? 'pointer' : 'default';
    },

    onClick: handleClick,
  };

  return <Bar data={chartData} options={chartOptions} />;
}

export default ProjectHoursWidget;
