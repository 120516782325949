import {
  Currency,
  DeleteConfirmation,
  ExportDialog,
  SearchInput,
  ExportDropdown,
  Page,
  BackLink,
  IconButton,
  FiltersBar,
  ListView,
  ListViewActions,
  ListViewMenu,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { mimeTypes } from '~/utils';
import { ActiveStatusSelect } from '../custom-data/components';
import ResourcePlaceholderDrawer from './ResourcePlaceholderDrawer';

function ResourcePlaceholdersPage() {
  const documentTitle = useDocumentTitle('Resource Placeholders');

  const api = useApi();
  const { workspace } = useWorkspace();
  const confirmation = useConfirmation();
  const toast = useToast();
  const features = useFeatures();

  const [query, setQuery] = useState({ isReady: false, data: null, error: null });
  const [params, setParams] = useState({ q: '', isActive: 'true' });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .resourcePlaceholders()
        .get({ ...params, isActive: params.isActive ?? undefined });
      setQuery({ isReady: true, data });
    } catch (error) {
      setQuery({ isReady: true, data: null, error });
    }
  }, [workspace.id, params, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [dialog, setDialog] = useState(null);

  const handleFilterChange = ({ target: { name, value } }) => {
    setParams((state) => ({ ...state, [name]: value }));
  };

  const handleExport = async (filename, mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onClose={resolve}
        onLoad={api.www
          .workspaces(workspace.id)
          .resourcePlaceholders()
          .export(
            { ...params, isActive: params.isActive ?? undefined },
            { headers: { accept: mimeType }, responseType: 'blob' },
          )}
      />
    ));
  };

  const handleEdit = async (resourcePlaceholder) => {
    setDialog(
      <ResourcePlaceholderDrawer
        resourcePlaceholder={resourcePlaceholder}
        onClose={() => {
          setDialog(null);
          documentTitle.set('Resource Placeholders');
        }}
        onDeleted={fetchData}
        onSaved={fetchData}
      />,
    );
  };

  async function handleActiveStatusChange(item, flag) {
    try {
      await api.www.workspaces(workspace.id).resourcePlaceholders(item.id).setActiveStatus(flag);
      await fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  async function handleDelete(placeholder) {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>
        Are you sure you want to delete this Resource Placeholder?
      </DeleteConfirmation>
    ));
    if (!confirm) return;

    try {
      await api.www
        .workspaces(workspace.id)
        .resourcePlaceholders(placeholder ? placeholder.id : undefined)
        .delete();
      await fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  if (!query.isReady) return <PageLoader />;
  if (!query.data) return <ErrorPage publicSite={false} />;

  const data = query.data;

  return (
    <>
      <Page.Header>
        <BackLink defaultPath={`/app/${workspace.key}/settings/custom-data`} />

        <Page.Info>
          <Page.Title>Resource Placeholders</Page.Title>
          <Page.Description>
            Within the resources area of Ruddr, you can allocate members or resource placeholders to projects. Resource
            placeholders should be used when you aren't certain which team member will ultimately be assigned to the
            project.
          </Page.Description>
        </Page.Info>

        <Page.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('resource_placeholders.csv', mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('resource_placeholders.xlsx', mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <IconButton icon="plus" tooltip="New Resource Placeholder" onClick={() => handleEdit({})} />
        </Page.Actions>
      </Page.Header>

      <Page.Filters>
        <FiltersBar>
          <SearchInput value={params.q} placeholder="Search" onChange={handleFilterChange} />

          <ActiveStatusSelect value={params.isActive} onChange={handleFilterChange} />
        </FiltersBar>
      </Page.Filters>

      <Page.ListView>
        <ListView>
          <ListView.Header>
            <ListView.Column>Name</ListView.Column>
            <ListView.Column isVisible={features.practices}>Practice</ListView.Column>
            <ListView.Column>Location</ListView.Column>
            <ListView.Column isVisible={features.disciplines}>Discipline</ListView.Column>
            <ListView.Column align="center" width="8rem">
              Cost Currency
            </ListView.Column>
            <ListView.Column align="right" width="8rem">
              Average
              <br />
              Cost/HR
            </ListView.Column>
            <ListViewActions.Column />
          </ListView.Header>
          <ListView.Body>
            {data.map((placeholder) => {
              const { id, name, discipline, location, practice, isActive, costCurrency, costPerHour } = placeholder;

              return (
                <ListView.Row onClick={() => handleEdit(placeholder)} key={id} isDisabled={!isActive}>
                  <ListView.Cell>{name}</ListView.Cell>
                  <ListView.Cell>{practice?.name}</ListView.Cell>
                  <ListView.Cell>{location?.name}</ListView.Cell>
                  <ListView.Cell>{discipline?.name}</ListView.Cell>
                  <ListView.Cell>{costCurrency}</ListView.Cell>
                  <ListView.Cell>
                    <Currency value={costPerHour} maximumFractionDigits={7} currency={costCurrency} />
                  </ListView.Cell>
                  <ListViewActions>
                    <>
                      <ListViewActions.Edit onClick={() => handleEdit(placeholder)} />

                      <hr />

                      <ListViewMenu>
                        {({ setIsOpen }) => (
                          <>
                            <ListViewMenu.Item onClick={() => handleEdit(placeholder)}>Edit</ListViewMenu.Item>
                            <ListViewMenu.Item
                              onClick={async () => {
                                await handleActiveStatusChange(placeholder, !isActive);
                                setIsOpen(false);
                              }}>
                              {isActive ? 'Deactivate' : 'Activate'}
                            </ListViewMenu.Item>
                            <ListViewMenu.DeleteItem
                              tooltip="This item is currently in use."
                              onCheckDependencies={async (workspace) =>
                                (await workspace.resourcePlaceholders(id).hasDependencies()).data
                              }
                              onClick={() => handleDelete(placeholder)}>
                              Delete
                            </ListViewMenu.DeleteItem>
                          </>
                        )}
                      </ListViewMenu>
                    </>
                  </ListViewActions>
                </ListView.Row>
              );
            })}

            {data.length === 0 && <ListView.Empty />}
          </ListView.Body>
          <ListView.Status total={data.length} label="Resource Placeholder" />
        </ListView>
      </Page.ListView>

      {dialog}
    </>
  );
}

export default ResourcePlaceholdersPage;
