import { ActionButton, Grid, Level } from '~/components';
import { Column } from '~/components/Grid';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';

export const ActionsContainer = styled.div`
  display: flex;
`;

export const BilledTo = styled.dl`
  width: 100%;
  margin-bottom: auto;

  dt {
    font-size: 0.75rem;
    font-weight: ${weights.black};
    color: ${colors.grey40};
    letter-spacing: 0.0625rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
`;

export const BottomSummaryDetailsRow = styled(Grid.Row)`
  &:first-child {
    margin-top: 0;
  }

  ${Column} {
    &:last-child {
      border-left: 1px solid ${colors.grey10};
    }

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 6%;
  }
`;

export const DraftNotesLabel = styled.small`
  font-size: 0.75rem;
  text-transform: uppercase;
`;

export const EditButton = styled.button`
  position: relative;
  align-self: center;
  background-color: ${colors.grey5};
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 999rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  color: ${colors.primary};

  &:hover {
    background-color: ${colors.grey10};
    color: ${colors.primary};
  }

  transition: all 100ms ease-in-out;

  &:disabled {
    box-shadow: none;
    opacity: 0.5;

    &:hover {
      cursor: not-allowed;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  padding-bottom: 2rem;
`;

export const HeaderEnd = styled.div`
  margin-left: auto;
  display: flex;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InvoiceFor = styled.dl`
  flex: 1;
  display: flex;

  dt {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    padding: 1.5rem 1.25rem;
    border-bottom-left-radius: 5px;
    color: ${colors.white};
    text-transform: uppercase;
    background: ${colors.grey75};
    font-size: 0.75rem;
    font-weight: ${weights.black};
  }

  dd {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: 1;
    background: ${colors.black};
    color: ${colors.white};
    border-bottom-right-radius: 5px;
    padding: 1.5rem 1.25rem;
  }
`;

export const InvoiceName = styled.h1`
  && {
    font-size: 1.5rem;
    font-weight: ${weights.light};
    margin-top: 0.25rem;
  }
`;

export const InvoiceNotes = styled.div`
  min-height: 11.25rem;
  flex: 1;
  font-size: 0.875rem;

  textarea {
    font-size: 0.875rem;
  }
`;

export const InvoiceNotesLabel = styled.small`
  font-size: 0.75rem;
  display: block;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
`;

export const LineItemsSection = styled.section`
  padding: 1.5rem 0;
`;

export const PreviewButton = styled(ActionButton)`
  width: 9.75rem;
  background-color: ${colors.white};
  color: ${colors.primary};
  border-color: ${colors.primary};
  border-width: 2px;
  position: relative;

  &:hover {
    color: ${colors.accent};
    border-color: ${colors.accent};
    background-color: ${colors.white};
  }

  &:disabled:hover {
    border-color: ${colors.grey40};
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      border-color: ${colors.accent};
      background-color: ${colors.white};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      box-shadow: none;
      opacity: 0.5;
      color: ${colors.grey40};
      background-color: ${colors.grey5};
      border-color: ${colors.grey40};

      &:hover {
        background-color: ${colors.grey5};
        color: ${colors.grey40};
      }
    `}
`;

export const SaveButton = styled(ActionButton)`
  min-width: 8.25rem;
  position: relative;
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  height: 2.5rem;
`;

export const Separator = styled.div`
  margin: 2rem 0;
  position: relative;
  border-top: 1px solid ${colors.grey10};

  &:last-child {
    display: none;
  }
`;

export const SummaryDetails = styled.dl`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  font-size: 0.875rem;

  dt {
    display: flex;
    align-items: center;
    width: 45%;
    margin-right: 10%;
    font-weight: ${weights.bold};
    white-space: nowrap;

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }

  dd {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 45%;
    text-align: right;

    input {
      font-size: 0.875rem;
      padding: 0.25rem;
    }

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
`;

export const SummaryDetailsRow = styled(Grid.Row)`
  &:first-child {
    margin-top: 0;
  }

  ${Column} {
    &:not(:last-child) {
      border-right: 1px solid ${colors.grey10};
    }

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 7.5%;
  }
`;

export const SummaryGrid = styled(Grid)`
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 3px 15px ${colors.grey10};
`;

export const SummarySection = styled.section`
  background: ${colors.grey5};
  padding: 1rem;
  margin: 0 -1rem;
`;

export const SummaryTotal = styled.div`
  display: flex;
  width: 40%;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  strong {
    font-weight: ${weights.bold};
    font-size: 1.5rem;
  }

  small {
    font-size: 0.75rem;
    margin-top: 0.5rem;
    white-space: nowrap;
  }
`;

export const Tag = styled.span`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem 0.4rem;
  border-left: solid 1px ${colors.grey10};
  color: ${({ color }) => color || colors.grey55};
  font-size: 0.75rem;

  &:first-child {
    padding-left: 0;
    border-left: none;
  }

  &:last-child {
    padding-right: 0;
  }
`;

export const Tags = styled.div`
  display: flex;
  margin-top: 0.25rem;
`;

export const TaxRateSelectContainer = styled.div`
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
  width: 100%;
  input {
    font-size: 0.875rem;
    padding: 0.25rem 1rem;
  }
  dt {
    font-weight: ${weights.bold};
    white-space: nowrap;
    margin-bottom: 0.25rem;
  }
  dd {
    margin-bottom: 1rem;
    input {
      font-size: 0.875rem;
      padding: 0.25rem;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 1.5rem;
`;

export const StyledLink = styled.a`
  font-size: 1rem;
  margin-top: -0.25rem;
`;

export const Totals = styled.div`
  flex: 1;
  justify-content: flex-end;
`;

export const TotalRow = styled(Level)`
  border: none;
  height: 3rem;
`;

export const TotalLabel = styled(Level.Item)`
  text-align: right;
`;

export const TotalValue = styled(Level.Item)`
  text-align: right;
  flex: 0;
  flex-basis: 10rem;

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
      transition: all 100ms ease-in-out;

      &:hover {
        opacity: 0.55;
      }
    `}
`;

export const TotalsSeparator = styled.hr`
  width: 23rem;
  margin-left: auto;
`;

export const VariablesContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;

  > a {
    margin-left: auto;
  }
`;
