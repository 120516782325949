import React from 'react';
import { Dropdown, IconButton } from '~/components';

function ExportDropdown({ children, ...props }) {
  return (
    <Dropdown align="right" {...props}>
      {({ setIsOpen, ...props }) => (
        <>
          <Dropdown.Trigger>
            <IconButton tooltip="Export" icon="download" onClick={() => setIsOpen(true)} />
          </Dropdown.Trigger>
          <Dropdown.Menu {...props}>
            {typeof children === 'function' ? children({ setIsOpen }) : children}
          </Dropdown.Menu>
        </>
      )}
    </Dropdown>
  );
}

ExportDropdown.Item = Dropdown.Item;

export default ExportDropdown;
