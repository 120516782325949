import { Button, Buttons, CancelButton, Currency, Icon, ModalCard } from '~/components';
import { useForm } from '~/hooks';
import _ from 'lodash';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import getWarningMessage from '../getWarningMessage';

const Warning = styled.div`
  display: flex;
  margin-top: 1rem;
  padding: 1rem;
  font-size: 0.875rem;
  background-color: ${colors.warning25};
  border-radius: 0.25rem;
`;

const WarningIcon = styled(Icon)`
  color: ${colors.warning};
  font-size: 1rem;
`;

const WarningMessage = styled.div`
  margin-left: 0.5rem;

  ul {
    margin-top: 0.5rem;
    list-style-type: disc;

    li {
      margin-top: 0.25rem;
      margin-left: 1rem;
    }
  }
`;

function CreateInvoiceDialog({ client, currency, projects, onCreate, onClose }) {
  const [{ isSubmitting }, form] = useForm();

  const handleSubmit = async () => {
    form.submit();
    await onCreate(client, currency, projects);
    form.done();
  };

  const total = useMemo(() => _.sumBy(projects, 'total'), [projects]);

  const warnings = useMemo(() => _.reduce(projects, (a, p) => _.uniq(a.concat(p.warnings)), []), [projects]);

  // The purchase order is the same for all projects selected.
  const purchaseOrder = projects[0].purchaseOrder;
  if (purchaseOrder && total > purchaseOrder.remainingAmount) {
    if (!warnings.includes('exceeds_po_amount')) {
      warnings.push('exceeds_po_amount');
    }
  }

  return (
    <ModalCard title="Create Draft Invoice" onClose={onClose}>
      <ModalCard.Body>
        <div>
          This will create a draft invoice of{' '}
          <strong>
            <Currency value={total} currency={currency} />
          </strong>{' '}
          for <strong>{client.name}</strong>. Any running timers for associated time entries will be stopped.
        </div>
        {warnings.length > 0 && (
          <Warning>
            <WarningIcon icon="exclamation-triangle" />
            <WarningMessage>
              <p>Please review the following items:</p>
              <ul>
                {warnings.map((warning) => (
                  <li key={warning}>{getWarningMessage(warning)}</li>
                ))}
              </ul>
            </WarningMessage>
          </Warning>
        )}
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Close</CancelButton>
          <Button isLoading={isSubmitting} onClick={handleSubmit}>
            Create Invoice
          </Button>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}

export default CreateInvoiceDialog;
