import React from 'react';
import SingleSelectFilter from './SingleSelectFilter';

const defaultOptions = [
  { id: 'yes', name: 'Yes' },
  { id: 'no', name: 'No' },
];

export default function YesNoFilter({
  placeholder,
  name,
  value,
  icon,
  onChange,
  options = defaultOptions,
  clearable = false,
}) {
  return (
    <SingleSelectFilter
      placeholder={placeholder}
      name={name}
      value={value}
      options={options}
      clearable={clearable}
      icon={icon}
      onChange={onChange}
    />
  );
}
