import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FiltersBar, Grid, Page, SingleSelectFilter } from '~/components';
import { TeammateFilter } from '~/components/filters';
import { useRating, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useFeatures } from '~/hooks';
import { intervalOptions } from '~/utils';
import AllocatedHoursWidget from '../AllocatedHoursWidget';
import DashboardDropdown from '../DashboardDropdown';
import MemmberTimeSummaryWidget from './MemberTimeSummaryWidget';
import MemberUtilizationWidget from './MemberUtilizationWidget';
import RealizationRateWidget from './RealizationRateWidget';
import VarianceWidget from './VarianceWidget';

const WidgetsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  column-gap: 1.5rem;
  row-gap: 1.5rem;
`;

function PersonalDashboard() {
  useDocumentTitle('Personal Dashboard');
  const features = useFeatures();

  const intervals = useMemo(
    () => ({
      this_week_to_date: { ...intervalOptions.this_week_to_date, period: 'week' },
      this_month_to_date: { ...intervalOptions.this_month_to_date, period: 'month' },
      this_quarter_to_date: { ...intervalOptions.this_quarter_to_date, period: 'quarter' },
      this_year_to_date: { ...intervalOptions.this_year_to_date, period: 'year' },
      last_week: { ...intervalOptions.last_week, period: 'week' },
      last_month: { ...intervalOptions.last_month, period: 'month' },
      last_quarter: { ...intervalOptions.last_quarter, period: 'quarter' },
      last_year: { ...intervalOptions.last_year, period: 'year' },
    }),
    [],
  );

  const personalDashboardInterval = {
    key: 'personal_dashboard.interval',

    get value() {
      let interval = localStorage.getItem(this.key);
      if (_.has(intervals, interval)) return interval;

      interval = intervals.this_week_to_date.key;
      this.value = interval;
      return interval;
    },

    set value(value) {
      localStorage.setItem(this.key, value);
    },
  };

  const { workspace } = useWorkspace();
  const auth = useAuth();
  const [interval, setInterval] = useState(personalDashboardInterval.value);
  const [member, setMember] = useState();
  const { autoOpenRatingModal } = useRating();
  const { start, end, period } = intervals[interval];

  const handleChangeInterval = ({ target: { value } }) => {
    setInterval(value);
    personalDashboardInterval.value = value;
  };

  useEffect(() => {
    autoOpenRatingModal();
  }, [autoOpenRatingModal]);

  const utilization = useMemo(
    () => ({
      billable: ['billable'],
      client: ['billable', 'non_billable'],
      productive: ['billable', 'non_billable', 'productive'],
      total: ['billable', 'non_billable', 'productive', 'internal'],
    }),
    [],
  );

  const currentMember = member || workspace.member;
  const { id: memberId } = currentMember;

  return (
    <Page>
      <Page.Header>
        <Page.Info>
          <Page.Eyebrow>
            {currentMember.name}
            {currentMember.name.toLowerCase().endsWith('s') ? `'` : `'s`}
          </Page.Eyebrow>

          {auth.executiveDashboard.view ? (
            <DashboardDropdown value="personal" />
          ) : (
            <Page.Title>Personal Dashboard</Page.Title>
          )}
        </Page.Info>
      </Page.Header>

      <Page.Filters>
        <FiltersBar>
          <SingleSelectFilter
            clearable={false}
            value={interval}
            options={_.map(intervals, ({ label }, key) => ({ id: key, name: label }))}
            onChange={handleChangeInterval}
          />

          {auth.members.manageTimeAndExpenses && (
            <TeammateFilter value={member} onChange={({ value }) => setMember(value)} />
          )}
        </FiltersBar>
      </Page.Filters>

      <Page.Section>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <MemmberTimeSummaryWidget
                start={start}
                end={end}
                period={period}
                interval={interval}
                memberId={memberId}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Page.Section>

      <Page.Section>
        <WidgetsGrid>
          <RealizationRateWidget start={start} end={end} memberId={memberId} />

          {(!member || member.permissions.manageAllocations) && (
            <>
              {features.allocations && <AllocatedHoursWidget start={start} end={end} memberId={memberId} />}
              {features.allocations && features.forecast && (
                <VarianceWidget start={start} end={end} memberId={memberId} />
              )}
            </>
          )}

          {features.utilization && (
            <>
              <MemberUtilizationWidget
                start={start}
                end={end}
                title="Billable Utilization"
                helpMessage="Your billable hours divided by your capacity."
                types={utilization.billable}
                showTarget
                memberId={memberId}
              />

              <MemberUtilizationWidget
                start={start}
                end={end}
                title="Client Utilization"
                helpMessage="The sum of your billable and non-billable client hours divided by your capacity."
                types={utilization.client}
                memberId={memberId}
              />

              <MemberUtilizationWidget
                start={start}
                end={end}
                title="Productive Utilization"
                helpMessage="The sum of your client hours and internal hours on productive projects divided by your capacity."
                types={utilization.productive}
                memberId={memberId}
              />

              <MemberUtilizationWidget
                start={start}
                end={end}
                title="Total Utilization"
                helpMessage="The sum of all your client and internal hours divided by your capacity."
                types={utilization.total}
                memberId={memberId}
              />
            </>
          )}
        </WidgetsGrid>
      </Page.Section>
    </Page>
  );
}

export default PersonalDashboard;
