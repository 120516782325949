import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useApi, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { weights } from '~/styles';
import BillingInfo from './BillingInfo';
import BillingNotConfigured from './BillingNotConfigured';
import { Page } from '~/components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  font-size: 1.625rem;
  font-weight: ${weights.light};
  line-height: 1.2;
`;

export default function Billing() {
  useDocumentTitle('Workspace Billing');

  const api = useApi();
  const { workspaceId, refreshWorkspace } = useWorkspace();
  const [shouldRefresh, setShouldRefresh] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const fetchStripeCustomer = async () => {
      const { data } = await api.www.workspaces(workspaceId).stripe.get();
      setCustomer(data || null);
      setIsReady(true);
    };
    fetchStripeCustomer();
    return fetchStripeCustomer.cancel;
  }, [api, workspaceId]);

  // Because `refreshWorkspace` may cause this effect to get put in a loop,
  // this should only call it when `shouldRefresh === true`
  useEffect(() => {
    if (!shouldRefresh) {
      return;
    }
    setShouldRefresh(false);
    refreshWorkspace();
  }, [shouldRefresh, refreshWorkspace]);

  // Refreshes the `workspace` after a change to `customer`
  useEffect(() => {
    // On first load, it should set to false instead of true
    setShouldRefresh((refresh) => (refresh === null ? false : true));
  }, [customer]);

  return (
    <Page>
      <Container>
        <Title>Workspace Billing</Title>
        {!isReady ? (
          <PageLoader />
        ) : !customer ? (
          <BillingNotConfigured setCustomer={setCustomer} />
        ) : (
          <BillingInfo customer={customer} setCustomer={setCustomer} />
        )}
      </Container>
    </Page>
  );
}
