import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { DateTime, Icon, Tab, Table, Tabs, Tooltip } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import SyncLogDrawer from './SyncLogDrawer';

const REFRESH_DELAY_SECONDS = 5;
const REFRESH_IMPORT_STATUS_SECONDS = 10;

const Message = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  color: ${colors.grey55};
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: ${weights.light};
`;

const RefreshButton = styled.div`
  margin-left: auto;
  padding-left: 1rem;
  color: ${({ disabled }) => (disabled ? colors.grey25 : colors.black)};
  cursor: pointer;
`;

const TabWrapper = styled.div`
  margin-top: 1rem;
`;

const TableWrapper = styled.div`
  margin-top: 0.5rem;
`;

export default function SyncResults({ refreshDate }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [isLoading, setIsLoading] = useState(true);
  const [syncs, setSyncs] = useState([]);
  const [viewSync, setViewSync] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [canRefresh, setCanRefresh] = useState(true);

  const fetchSyncs = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).rippling.getSyncs();
      const hasAutoSyncs = _.filter(data, { typeId: 'auto' }).length > 0;
      const hasManualSyncs = _.filter(data, { typeId: 'manual' }).length > 0;

      setTabIndex((currentIndex) => {
        if (!hasAutoSyncs && currentIndex === 0) {
          return 1;
        } else if (!hasManualSyncs && currentIndex === 1) {
          return 0;
        } else {
          return currentIndex;
        }
      });
      setSyncs(data);
    } finally {
      setIsLoading(false);
    }
  }, [api, workspace]);

  const isRunning = useMemo(() => {
    return _.filter(syncs, { isRunning: true }).length > 0;
  }, [syncs]);

  const autoSyncs = useMemo(() => {
    return _.filter(syncs, { typeId: 'auto' });
  }, [syncs]);

  const manualSyncs = useMemo(() => {
    return _.filter(syncs, { typeId: 'manual' });
  }, [syncs]);

  const currentSyncs = useMemo(() => {
    return tabIndex === 0 ? autoSyncs : manualSyncs;
  }, [autoSyncs, manualSyncs, tabIndex]);

  useEffect(() => {
    fetchSyncs();
    return fetchSyncs.cancel;
  }, [fetchSyncs, refreshDate]);

  useEffect(() => {
    let interval = null;
    if (isRunning) {
      interval = setInterval(fetchSyncs, REFRESH_IMPORT_STATUS_SECONDS * 1000);
    }
    return () => {
      if (interval) {
        clearTimeout(interval);
      }
    };
  }, [isRunning, fetchSyncs]);

  const refresh = () => {
    if (!canRefresh) {
      return;
    }
    setCanRefresh(false);
    fetchSyncs();
    setTimeout(() => {
      if (isMounted.current) {
        setCanRefresh(true);
      }
    }, REFRESH_DELAY_SECONDS * 1000);
  };

  if (isLoading) {
    return <PageLoader />;
  }
  if (syncs.length === 0) {
    return (
      <>
        <TitleRow>
          <Title>Latest Sync Results</Title>
        </TitleRow>
        <TableWrapper>
          <Message>No results available</Message>
        </TableWrapper>
      </>
    );
  }
  return (
    <>
      <TitleRow>
        <Title>Latest Sync Results</Title>
        <RefreshButton disabled={!canRefresh} onClick={refresh}>
          <Tooltip message="Refresh results">
            <Icon icon="sync" />
          </Tooltip>
        </RefreshButton>
      </TitleRow>
      <TabWrapper>
        <Tabs selectedIndex={tabIndex} onChange={(index) => setTabIndex(index)}>
          {autoSyncs.length > 0 && <Tab>Automatic</Tab>}
          {manualSyncs.length > 0 && <Tab>Manual</Tab>}
        </Tabs>
      </TabWrapper>
      <TableWrapper>
        <Table small>
          <Table.BoxHeader>
            <Table.Column>Date</Table.Column>
            <Table.Column>Status</Table.Column>
          </Table.BoxHeader>
          <Table.Body>
            {currentSyncs.map((sync) => (
              <Table.BoxRow
                key={sync.id}
                isDisabled={!sync.hasLog}
                onClick={() => (sync.hasLog ? setViewSync(sync.id) : null)}>
                <Table.Cell>
                  <DateTime value={sync.createdAt} format="LLLL" />
                </Table.Cell>
                <Table.Cell>{sync.status?.name || 'N/A'}</Table.Cell>
              </Table.BoxRow>
            ))}
          </Table.Body>
        </Table>
      </TableWrapper>
      {viewSync && <SyncLogDrawer id={viewSync} onClose={() => setViewSync()} />}
    </>
  );
}
