import _ from 'lodash';
import { darken } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';
import Button from './Button';
import Icon from './Icon';
import Input from './Input';
import TextInput from './TextInput';

const Control = styled.div`
  position: relative;

  input {
    padding-right: 2.25rem;
  }
`;

const Status = styled.div`
  position: absolute;
  display: flex;
  gap: 0.125rem;
  height: 100%;
  top: 0;
  right: 0;
  padding-right: 0.375rem;
  justify-content: center;
  align-items: center;
  justify-content: center;
  z-index: 1;
  font-size: 0.75rem;

  .icon {
    width: 1rem;
  }
`;

const ClearIndicator = styled(Button)`
  height: 100%;
  color: ${colors.primary};

  &:hover {
    color: ${darken(0.1, colors.primary)};
  }
`;

function SearchInput({ name = 'q', ...props }) {
  const handleClear = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (_.isFunction(props.onChange)) {
      props.onChange({ value: '', target: { name, value: '' } });
    }
  };

  return (
    <Control>
      <TextInput component={Input} wait={300} name={name} type="search" icon="search" {...props} />
      <Status>
        {props.value?.length > 0 && (
          <ClearIndicator onMouseDown={(e) => e.stopPropagation()} isAnchor tabIndex={-1} onClick={handleClear}>
            <Icon icon="times" />
          </ClearIndicator>
        )}

        <Icon icon="search" color={colors.grey20} />
      </Status>
    </Control>
  );
}
export default SearchInput;
