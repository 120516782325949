import React from 'react';
import styled from 'styled-components';
import Field from '../Field';

const Group = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ direction }) => (direction === 'vertical' ? 'column' : 'row')};
  margin: -0.375rem -0.75rem;

  > * {
    margin: 0.375rem 0.75rem;
  }
`;

function FieldReadRadioGroup({ direction = 'horizontal', children, value }) {
  return (
    <Field.Control>
      <Group direction={direction}>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, {
            name: child.key,
            checked: child.props.value === value,
            readOnly: true,
            disabled: true,
          });
        })}
      </Group>
    </Field.Control>
  );
}

export default FieldReadRadioGroup;
