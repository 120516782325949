import { Button, Buttons, CancelButton, DeleteButton, Drawer, Field, Form, FormMessage } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useDocumentTitle, useFeatures, useForm } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorPage } from '~/routes/public/pages';
import { combineNames, emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';

function ContactForm({ onSaved, onDeleted, onClose }) {
  useDocumentTitle('Edit Contact');

  const api = useApi();
  const { workspace } = useWorkspace();
  const [query, setQuery] = useState({ isReady: false, contact: null, error: null });
  const [{ status, message, isSubmitting }, form] = useForm();
  const { contactId, contactTab } = useParams();
  const features = useFeatures();

  const formRef = useRef();
  const firstFieldRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);

  const fetchData = useCallback(async () => {
    try {
      const { data: contact } = await api.www.workspaces(workspace.id).contacts(contactId).get();
      setQuery({ isReady: true, contact });
    } catch (error) {
      setQuery({ isReady: true, contact: null, error });
    }
  }, [workspace.id, contactId, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function handleClose() {
    if (onClose) {
      onClose(contactTab);
    }
  }

  const contact = query.contact;
  const salesforceReadOnly = !!contact?.salesforceContact?.salesforceId;
  const hubspotReadOnly = !!contact?.hubspotContact?.hubspotId;

  const handleDelete = async () => {
    onDeleted(query.contact);
  };

  if (!query.isReady) return null;
  if (!contact) return <ErrorPage.NotFound />;

  const initialValues = mergeValues(
    {
      description: '',
      email: '',
      fax: '',
      firstName: '',
      lastName: '',
      mobilePhone: '',
      name: '',
      officePhone: '',
      owner: contact?.owner,
      timeZoneId: '',
      title: '',
      websiteUrl: '',
    },
    contact,
  );

  return (
    <Drawer
      isOpen
      title="Edit Contact"
      byline={contact?.name}
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(values) {
          try {
            form.submit();
            const body = emptyStringToNull({
              ..._.omit(values, ['owner']),
              ownerId: values.owner?.id ?? null,
              name: combineNames(values.firstName, values.lastName),
            });

            await api.www.workspaces(workspace.id).contacts(contactId).update(body);
            await onSaved();
            closeDrawer();
          } catch ({ message }) {
            form.error({ message });
          }
        }

        return (
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape(
              salesforceReadOnly || hubspotReadOnly
                ? {
                    timeZoneId: Yup.string().label('Time Zone').max(255).nullable(),
                    websiteUrl: Yup.string()
                      .label('Website URL')
                      .url('Please include http:// or https:// protocols in your URL')
                      .max(1024),
                  }
                : {
                    description: Yup.string().label('Description').max(5000),
                    email: Yup.string().label('Email Address').email().max(255).required(),
                    fax: Yup.string().label('Fax').max(255),
                    firstName: Yup.string().label('First Name').max(127).required(),
                    lastName: Yup.string().label('Last Name').max(127).required(),
                    mobilePhone: Yup.string().label('Mobile Phone').max(255),
                    officePhone: Yup.string().label('Office Phone').max(255),
                    owner: Yup.object().label('Owner').nullable(),
                    title: Yup.string().label('Title').max(255),
                    timeZoneId: Yup.string().label('Time Zone').max(255).nullable(),
                    websiteUrl: Yup.string()
                      .label('Website URL')
                      .url('Please include http:// or https:// protocols in your URL')
                      .max(1024),
                  },
            )}>
            {(formik) => {
              const { submitForm, values } = formik;

              return (
                <Form>
                  {status && <FormMessage.Error spaceBottom>{message}</FormMessage.Error>}

                  <Form.Section title="Contact details">
                    <Form.Control>
                      <Field.Text
                        ref={firstFieldRef}
                        name="firstName"
                        placeholder="First Name"
                        maxLength={127}
                        disabled={salesforceReadOnly || hubspotReadOnly}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.Text
                        name="lastName"
                        placeholder="Last Name"
                        maxLength={127}
                        disabled={salesforceReadOnly || hubspotReadOnly}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.Text
                        name="name"
                        placeholder="Full Name"
                        maxLength={255}
                        disabled
                        value={
                          values.firstName && values.lastName ? combineNames(values.firstName, values.lastName) : ''
                        }
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.Text
                        name="email"
                        placeholder="Email Address"
                        type="email"
                        disabled={salesforceReadOnly || hubspotReadOnly}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.MemberSelect
                        name="owner"
                        placeholder="Owner"
                        initialValue={contact && contact.contact?.owner}
                        disabled={salesforceReadOnly || hubspotReadOnly}
                        clearable={!salesforceReadOnly && !hubspotReadOnly}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.TimeZoneSelect name="timeZoneId" placeholder="Time Zone" />
                    </Form.Control>

                    <Form.Control>
                      <Field.Text
                        name="title"
                        placeholder="Title"
                        maxLength={255}
                        disabled={salesforceReadOnly || hubspotReadOnly}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.TextArea
                        name="description"
                        placeholder="Description"
                        maxLength={5000}
                        disabled={salesforceReadOnly || hubspotReadOnly}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.Text
                        name="officePhone"
                        placeholder="Office Phone"
                        maxLength={255}
                        disabled={salesforceReadOnly || hubspotReadOnly}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.Text
                        name="mobilePhone"
                        placeholder="Mobile Phone"
                        maxLength={255}
                        disabled={salesforceReadOnly || hubspotReadOnly}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.Text
                        name="fax"
                        placeholder="Fax"
                        maxLength={255}
                        disabled={salesforceReadOnly || hubspotReadOnly}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.Text name="websiteUrl" placeholder="Website URL" maxLength={1024} />
                    </Form.Control>
                  </Form.Section>

                  <Drawer.Actions>
                    {contact.id && (
                      <DeleteButton onClick={handleDelete}>{features.pipeline ? 'Remove' : 'Delete'}</DeleteButton>
                    )}

                    <Buttons align="right">
                      <Buttons align="right">
                        <CancelButton onClick={handleCloseClick}>Close</CancelButton>

                        <Button isLoading={isSubmitting} onClick={submitForm}>
                          Save &amp; Close
                        </Button>
                      </Buttons>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}

export default ContactForm;
