import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';

export const Row = styled.div`
  display: flex;
  align-items: stretch;
  border-bottom: 1px solid ${colors.grey10};

  &.is-clickable:hover,
  &.focused {
    z-index: 2;
    box-shadow: 0 3px 15px ${colors.grey10};
  }

  &.is-clickable {
    cursor: pointer;
    transition: all 250ms ease-in-out;
  }

  &.is-disabled {
    position: relative;

    &.is-disabled::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      pointer-events: none;
      z-index: 1;
    }
  }
`;

function ListViewRow({ className, isDisabled, onClick, children, ...props }) {
  const cells = React.Children.toArray(children).map((child, index) => React.cloneElement(child, { index }));
  return (
    <Row
      {...props}
      className={classNames(className, { 'is-disabled': isDisabled, 'is-clickable': !!onClick })}
      onClick={onClick}>
      {cells}
    </Row>
  );
}

export default ListViewRow;
