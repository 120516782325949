import {
  Button,
  Buttons,
  CancelButton,
  DeleteButton,
  DeleteConfirmation,
  Drawer,
  Field,
  Form,
  FormMessage,
  HelpTooltip,
  Icon,
  Radio,
  Tab,
  Tabs,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useAuth, useDirtyCheck, useDocumentTitle, useForm } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorPage } from '~/routes/public/pages';
import styled from 'styled-components';
import { dateFormats, emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';
import { colors } from '~/styles';
import AllocationHistory from './AllocationHistory';
import Big from 'big.js';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 1.625rem;
  margin-bottom: 1.625rem;
`;

function AllocationForm({ initialValues: defaultInitialValues, onSaved, onDeleted, onClose }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const auth = useAuth();
  const [query, setQuery] = useState({ status: 'loading', data: null });
  const [tabIndex, setTabIndex] = useState(0);
  const [project, setProject] = useState(null);
  const allocation = query.data;

  const [{ status, message, isSubmitting }, form] = useForm();

  const { allocationId } = useParams();

  const confirmation = useConfirmation();

  const formRef = useRef();
  const firstFieldRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);

  const toast = useToast();

  const isNew = !allocationId;
  const title = isNew ? 'New Allocation' : 'Edit Allocation';

  useDocumentTitle(title);

  const actions = useMemo(() => {
    return {
      ready: (data) => setQuery((state) => ({ ...state, status: 'ready', data })),
    };
  }, []);

  const fetchData = useCallback(async () => {
    if (!allocationId) {
      if (defaultInitialValues?.projectId) {
        try {
          const { data } = await api.www
            .workspaces(workspace.id)
            .allocations()
            .getProject(defaultInitialValues.projectId);

          setProject(data);
        } catch {
          // Do nothing
        }
      }

      actions.ready({});
      return;
    }

    try {
      const { data } = await api.www.workspaces(workspace.id).allocations(allocationId).get();

      actions.ready(data);
    } catch (error) {
      toast.error(error.message || 'There was a problem reading this allocation. Please try again.');
    }
  }, [actions, defaultInitialValues?.projectId, workspace.id, allocationId, api, toast]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDelete = async () => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this allocation?</DeleteConfirmation>
    ));

    if (!confirm) return;

    await api.www.workspaces(workspace.id).allocations(allocation.id).delete();

    if (onDeleted) onDeleted(allocation);
    onClose();
  };

  if (query.status === 'loading') return null;
  if (query.status === 'ready' && !allocation) return <ErrorPage.NotFound publicSite={false} />;

  const initialValues = mergeValues(
    {
      allocationMethod: '',
      unit: workspace.defaultAllocationUnit,
      hoursPerDay: '',
      hoursPerWeek: '',
      hoursPerMonth: '',
      hoursPerAllocation: '',
      hoursPercentageOfCapacity: '',
      allocateOnTimeOffDays: workspace.defaultAllocateOnTimeOffDays,
      start: null,
      end: null,
      notes: '',
      resourceTypeId: 'member',
      member: null,
      placeholder: null,
      assignmentTypeId: 'project',
      project: null,
      role: null,
      task: null,
      timeOffType: null,
    },
    {
      ...defaultInitialValues,
      project,
      ...{
        ...allocation,
        hoursPercentageOfCapacity: _.isNumber(allocation.hoursRatioOfCapacity)
          ? Big(allocation.hoursRatioOfCapacity).times(100).toNumber()
          : '',
      },
    },
  );

  if (isNew) {
    if (
      initialValues.unit === 'ratio_of_capacity' &&
      (initialValues.resourceTypeId === 'placeholder' || initialValues.assignmentTypeId === 'time_off')
    ) {
      initialValues.hoursPerDay = 8;
      initialValues.unit = 'day';
      initialValues.allocationMethod = 'period';
    }

    switch (initialValues.unit) {
      case 'day':
        initialValues.hoursPerDay = 8;
        break;

      case 'week':
        initialValues.hoursPerWeek = 40;
        initialValues.start = moment(initialValues.start).startOf('isoWeek').format(dateFormats.isoDate);
        initialValues.end = moment(initialValues.end).endOf('isoWeek').format(dateFormats.isoDate);
        break;

      case 'month':
        initialValues.hoursPerMonth = 160;
        initialValues.start = moment(initialValues.start).startOf('month').format(dateFormats.isoDate);
        initialValues.end = moment(initialValues.end).endOf('month').format(dateFormats.isoDate);
        break;

      case 'allocation': {
        const dayCount = moment(initialValues.end).diff(initialValues.start, 'days') + 1;
        if (dayCount > 0) {
          initialValues.hoursPerAllocation = Big(dayCount).times(8).toNumber();
        }
        break;
      }

      case 'ratio_of_capacity':
        initialValues.hoursPercentageOfCapacity = 100;
        break;
    }
  }

  switch (initialValues.unit) {
    case 'day':
    case 'week':
    case 'month':
      initialValues.allocationMethod = 'period';
      break;

    default:
      initialValues.allocationMethod = initialValues.unit;
      break;
  }

  const validationSchema = Yup.object().shape({
    unit: Yup.string().label('Unit').required(),
    hoursPerDay: Yup.number()
      .label('Hours Per Day')
      .min(0)
      .max(24)
      .nullable()
      .when('unit', {
        is: 'day',
        then: (schema) => schema.required(),
      }),
    hoursPerWeek: Yup.number()
      .label('Hours Per Week')
      .min(0)
      .max(168)
      .nullable()
      .when('unit', {
        is: 'week',
        then: (schema) => schema.required(),
      }),
    hoursPerMonth: Yup.number()
      .label('Hours Per Month')
      .min(0)
      .max(744)
      .nullable()
      .when('unit', {
        is: 'month',
        then: (schema) => schema.required(),
      }),
    hoursPerAllocation: Yup.number()
      .label('Total Hours')
      .min(0)
      .max(99999)
      .nullable()
      .when('unit', {
        is: 'allocation',
        then: (schema) => schema.required(),
      }),
    hoursPercentageOfCapacity: Yup.number()
      .label('Percentage of Capacity')
      .min(0)
      .max(100)
      .nullable()
      .when('unit', {
        is: 'ratio_of_capacity',
        then: (schema) => schema.required(),
      }),
    start: Yup.date()
      .label('Start Date')
      .nullable()
      .required()
      .when('unit', {
        is: 'week',
        then: (schema) =>
          schema.test({
            message: 'Start Date must be the first day of the week.',
            test: (value) => moment(value).isoWeekday() === 1,
          }),
      })
      .when('unit', {
        is: 'month',
        then: (schema) =>
          schema.test({
            message: 'Start Date must be the first day of the month.',
            test: (value) => moment(value).date() === 1,
          }),
      }),
    end: Yup.date()
      .label('End Date')
      .nullable()
      .min(Yup.ref('start'), 'End Date must be after Start Date')
      .required()
      .when('unit', {
        is: 'week',
        then: (schema) =>
          schema.test({
            message: 'End Date must be the last day of the week.',
            test: (value) => moment(value).isoWeekday() === 7,
          }),
      })
      .when('unit', {
        is: 'month',
        then: (schema) =>
          schema.test({
            message: 'End Date must be the last day of the month.',
            test: (value) => moment(value).date() === moment(value).endOf('month').date(),
          }),
      }),
    resourceTypeId: Yup.string().label('Resource Type').oneOf(['member', 'placeholder']).required(),
    member: Yup.mixed()
      .label('Member')
      .when('resourceTypeId', {
        is: 'member',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.oneOf([null]),
      }),
    placeholder: Yup.mixed()
      .label('Placeholder')
      .when('resourceTypeId', {
        is: 'placeholder',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.oneOf([null]),
      }),
    assignmentTypeId: Yup.string()
      .label('Assignment Type')
      .when('resourceTypeId', (resourceTypeId, schema) => {
        switch (resourceTypeId) {
          case 'member':
            return schema.oneOf(['project', 'time_off']);

          case 'placeholder':
            return schema.oneOf(['project']);
        }
      }),
    project: Yup.mixed()
      .label('Project')
      .when('assignmentTypeId', {
        is: 'project',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.oneOf([null]),
      }),
    role: Yup.mixed()
      .label('Role')
      .when('assignmentTypeId', {
        is: 'time_off',
        then: (schema) => schema.oneOf([null]),
      }),
    task: Yup.mixed()
      .label('Task')
      .when('assignmentTypeId', {
        is: 'time_off',
        then: (schema) => schema.oneOf([null]),
      }),
    timeOffType: Yup.mixed()
      .label('Time Off Type')
      .when('assignmentTypeId', {
        is: 'time_off',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.oneOf([null]),
      }),
    notes: Yup.string().label('Notes').max(5000),
  });

  const tabErrors = {
    allocation: [
      'assignmentTypeId',
      'end',
      'hoursPerDay',
      'hoursPerWeek',
      'hoursPerMonth',
      'hoursPerAllocation',
      'member',
      'notes',
      'placeholder',
      'project',
      'resourceTypeId',
      'role',
      'start',
      'task',
      'timeOffType',
      'unit',
    ],
  };

  return (
    <Drawer
      isOpen
      title={title}
      onOpen={() => isNew && firstFieldRef.current && firstFieldRef.current.focus()}
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={onClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(values) {
          try {
            form.submit();
            const body = emptyStringToNull({
              ..._.omit(values, [
                'member',
                'placeholder',
                'project',
                'role',
                'task',
                'timeOffType',
                'allocationMethod',
                'hoursPercentageOfCapacity',
              ]),
              memberId: values.member?.id ?? null,
              resourcePlaceholderId: values.placeholder?.id ?? null,
              projectId: values.project?.id ?? null,
              projectRoleId: values.role?.id ?? null,
              projectTaskId: values.task?.id ?? null,
              timeOffTypeId: values.timeOffType?.id ?? null,
              hoursRatioOfCapacity: _.isNumber(values.hoursPercentageOfCapacity)
                ? Big(values.hoursPercentageOfCapacity).div(100).toNumber()
                : null,
            });

            switch (values.unit) {
              case 'day':
                body.hoursPerWeek = null;
                body.hoursPerMonth = null;
                body.hoursPerAllocation = null;
                body.hoursRatioOfCapacity = null;
                break;

              case 'week':
                body.hoursPerDay = null;
                body.hoursPerMonth = null;
                body.hoursPerAllocation = null;
                body.hoursRatioOfCapacity = null;
                break;

              case 'month':
                body.hoursPerDay = null;
                body.hoursPerWeek = null;
                body.hoursPerAllocation = null;
                body.hoursRatioOfCapacity = null;
                break;

              case 'allocation':
                body.hoursPerDay = null;
                body.hoursPerWeek = null;
                body.hoursPerMonth = null;
                body.hoursRatioOfCapacity = null;
                break;

              case 'ratio_of_capacity':
                body.hoursPerDay = null;
                body.hoursPerWeek = null;
                body.hoursPerMonth = null;
                body.hoursPerAllocation = null;
                break;
            }

            const { data } = await api.www.workspaces(workspace.id).allocations(allocation?.id).upsert(body);

            await onSaved(data);
            form.save();

            closeDrawer();
          } catch ({ message }) {
            form.error({ message });
          }
        }

        return (
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={validationSchema}>
            {(formik) => {
              const handleResourceTypeChange = (event) => {
                const values = { ...formik.values, resourceTypeId: event.target.value };

                switch (event.target.value) {
                  case 'member':
                    values.placeholder = null;
                    break;

                  case 'placeholder':
                    values.member = null;
                    if (values.assignmentTypeId === 'time_off') {
                      values.assignmentTypeId = 'project';
                      values.timeOffType = null;
                    }
                    if (values.placeholder && values.placeholder?.rateCurrency !== values.project?.currency) {
                      values.project = null;
                      values.role = null;
                      values.task = null;
                    }
                    if (values.unit === 'ratio_of_capacity') {
                      switch (workspace.defaultAllocationUnit) {
                        case 'day':
                        case 'week':
                        case 'month':
                        case 'allocation':
                          values.unit = workspace.defaultAllocationUnit;
                          values.allocationMethod = ['day', 'week', 'month'].includes(workspace.defaultAllocationUnit)
                            ? 'period'
                            : 'allocation';

                          switch (values.unit) {
                            case 'week':
                              values.start = moment(values.start).startOf('isoWeek').format(dateFormats.isoDate);
                              values.end = moment(values.end).endOf('isoWeek').format(dateFormats.isoDate);
                              break;

                            case 'month':
                              values.start = moment(values.start).startOf('month').format(dateFormats.isoDate);
                              values.end = moment(values.end).endOf('month').format(dateFormats.isoDate);
                              break;
                          }
                          break;

                        default:
                          values.allocationMethod = 'period';
                          values.unit = 'day';
                          break;
                      }
                      values.hoursPercentageOfCapacity = null;
                    }
                    values.allocateOnTimeOffDays = false;
                    break;
                }

                formik.setValues(values);
              };

              const handleMemberChange = (event) => {
                const values = { ...formik.values, member: event.target.value };

                if (!values.member?.permissions.manageAllocations) {
                  if (values.assignmentTypeId === 'time_off') {
                    values.assignmentTypeId = 'project';
                    values.timeOffTypeId = null;
                  }
                  if (!values.project?.permissions.manageAllocations) values.project = null;
                }

                formik.setValues(values);
              };

              const handlePlaceholderChange = (event) => {
                const values = { ...formik.values, placeholder: event.target.value };

                if (values.placeholder && values.placeholder?.rateCurrency !== values.project?.currency) {
                  values.project = null;
                  values.role = null;
                  values.task = null;
                }

                if (!values.placeholder?.permissions.manageAllocations) {
                  if (!values.project?.permissions.manageAllocations) values.project = null;
                }

                formik.setValues(values);
              };

              const handleAssignmentTypeChange = (event) => {
                const values = { ...formik.values, assignmentTypeId: event.target.value };

                switch (event.target.value) {
                  case 'project':
                    values.timeOffType = null;
                    break;

                  case 'time_off':
                    values.project = null;
                    values.role = null;
                    values.task = null;
                    if (!values.member?.permissions.manageAllocations) values.member = null;
                    if (!values.placeholder?.permissions.manageAllocations) values.placeholder = null;
                    values.allocateOnTimeOffDays = false;
                    if (values.allocationMethod === 'ratio_of_capacity') {
                      switch (workspace.defaultAllocationUnit) {
                        case 'day':
                        case 'week':
                        case 'month':
                        case 'allocation':
                          values.unit = workspace.defaultAllocationUnit;
                          values.allocationMethod = ['day', 'week', 'month'].includes(workspace.defaultAllocationUnit)
                            ? 'period'
                            : 'allocation';

                          switch (values.unit) {
                            case 'week':
                              values.start = moment(values.start).startOf('isoWeek').format(dateFormats.isoDate);
                              values.end = moment(values.end).endOf('isoWeek').format(dateFormats.isoDate);
                              break;

                            case 'month':
                              values.start = moment(values.start).startOf('month').format(dateFormats.isoDate);
                              values.end = moment(values.end).endOf('month').format(dateFormats.isoDate);
                              break;
                          }
                          break;

                        default:
                          values.allocationMethod = 'period';
                          values.unit = 'day';
                          break;
                      }
                      values.hoursPercentageOfCapacity = null;
                    }
                    break;
                }

                formik.setValues(values);
              };

              const handleProjectChange = (event) => {
                const values = {
                  ...formik.values,
                  project: event.target.value,
                };

                if (values.project?.id !== formik.values.project?.id) {
                  values.role = null;
                  values.task = null;
                }

                if (!values.project?.permissions.manageAllocations) {
                  if (!values.member?.permissions.manageAllocations) values.member = null;
                  if (!values.placeholder?.permissions.manageAllocations) values.placeholder = null;
                }

                formik.setValues(values);
              };

              const handleUnitChange = (event) => {
                const values = {
                  ...formik.values,
                  unit: event.target.value,
                };

                switch (values.unit) {
                  case 'week':
                    values.start = moment(values.start).startOf('isoWeek').format(dateFormats.isoDate);
                    values.end = moment(values.end).endOf('isoWeek').format(dateFormats.isoDate);
                    break;

                  case 'month':
                    values.start = moment(values.start).startOf('month').format(dateFormats.isoDate);
                    values.end = moment(values.end).endOf('month').format(dateFormats.isoDate);
                    break;
                }

                formik.setValues(values);
              };

              const handleStartChange = (value) => {
                if (value) {
                  switch (formik.values.unit) {
                    case 'week':
                      value = moment(value).startOf('isoWeek').format(dateFormats.isoDate);
                      break;

                    case 'month':
                      value = moment(value).startOf('month').format(dateFormats.isoDate);
                      break;
                  }
                }

                formik.setFieldValue('start', value);
              };

              const handleEndChange = (value) => {
                if (value) {
                  switch (formik.values.unit) {
                    case 'week':
                      value = moment(value).endOf('isoWeek').format(dateFormats.isoDate);
                      break;

                    case 'month':
                      value = moment(value).endOf('month').format(dateFormats.isoDate);
                      break;
                  }
                }

                formik.setFieldValue('end', value);
              };

              let dayPickerScope;
              switch (formik.values.unit) {
                case 'allocation':
                case 'ratio_of_capacity':
                  dayPickerScope = 'day';
                  break;

                default:
                  dayPickerScope = formik.values.unit;
                  break;
              }

              const allocationMethods = [
                { value: 'period', label: 'Hours per Day/Week/Month' },
                { value: 'allocation', label: 'Total Hours' },
              ];

              if (formik.values.resourceTypeId === 'member' && formik.values.assignmentTypeId === 'project') {
                allocationMethods.push({ value: 'ratio_of_capacity', label: `Percentage of the Member's Capacity` });
              }

              const handleAllocationMethodChange = (event) => {
                const values = {
                  ...formik.values,
                  allocationMethod: event.target.value,
                };

                switch (values.allocationMethod) {
                  case 'period':
                    switch (workspace.defaultAllocationUnit) {
                      case 'day':
                      case 'week':
                      case 'month':
                        values.unit = workspace.defaultAllocationUnit;

                        switch (values.unit) {
                          case 'week':
                            values.start = moment(values.start).startOf('isoWeek').format(dateFormats.isoDate);
                            values.end = moment(values.end).endOf('isoWeek').format(dateFormats.isoDate);
                            break;

                          case 'month':
                            values.start = moment(values.start).startOf('month').format(dateFormats.isoDate);
                            values.end = moment(values.end).endOf('month').format(dateFormats.isoDate);
                            break;
                        }
                        break;

                      default:
                        values.unit = 'day';
                        break;
                    }

                    break;

                  default:
                    values.unit = values.allocationMethod;
                    break;
                }

                formik.setValues(values);
              };

              return (
                <Form>
                  <Tabs selectedIndex={tabIndex} onChange={(index) => setTabIndex(index)}>
                    <Tab>
                      Allocation
                      {tabErrors.allocation.some((key) => !!formik.errors[key]) && (
                        <Icon icon="exclamation-circle" color={colors.danger} spaceLeft />
                      )}
                    </Tab>
                    {!isNew && <Tab>History</Tab>}
                  </Tabs>
                  <Content>
                    {[
                      () => (
                        <>
                          <Form.Section title="Details">
                            <Form.Control>
                              <Field.SingleSelect
                                name="resourceTypeId"
                                placeholder="Resource Type"
                                disabled={!!defaultInitialValues?.resourceTypeId}
                                onChange={handleResourceTypeChange}>
                                <option value="member">Member</option>
                                <option value="placeholder">Placeholder</option>
                              </Field.SingleSelect>
                            </Form.Control>

                            {{
                              member: () => (
                                <Form.Control>
                                  <Field.MemberSelect
                                    key={formik.values.project?.id}
                                    name="member"
                                    placeholder="Member"
                                    initialValue={allocation.member}
                                    disabled={
                                      !!defaultInitialValues?.member ||
                                      (!auth.allocations.members.manage &&
                                        !formik.values.project?.permissions.manageAllocations)
                                    }
                                    clearable={!defaultInitialValues?.member}
                                    withPermissions
                                    onFilter={(data) => {
                                      if (formik.values.project?.permissions.manageAllocations) return data;
                                      return data.filter((m) => m.permissions.manageAllocations);
                                    }}
                                    onChange={handleMemberChange}
                                  />
                                </Form.Control>
                              ),

                              placeholder: () => (
                                <Form.Control>
                                  <Field.PlaceholderSelect
                                    name="placeholder"
                                    placeholder="Placeholder"
                                    disabled={
                                      !!defaultInitialValues?.placeholder ||
                                      (!auth.allocations.members.manage &&
                                        !formik.values.project?.permissions.manageAllocations)
                                    }
                                    clearable={!defaultInitialValues?.placeholder}
                                    withPermissions
                                    onFilter={(data) => {
                                      let filteredData = _(data);

                                      if (formik.values.project) {
                                        filteredData = filteredData.filter(
                                          (rp) => rp.rateCurrency === formik.values.project.currency,
                                        );
                                      }

                                      if (!formik.values.project?.permissions.manageAllocations) {
                                        filteredData = filteredData.filter((rp) => rp.permissions.manageAllocations);
                                      }

                                      return filteredData.value();
                                    }}
                                    onChange={handlePlaceholderChange}
                                  />
                                </Form.Control>
                              ),
                            }[formik.values.resourceTypeId]()}

                            <Form.Control>
                              <Field.SingleSelect
                                name="assignmentTypeId"
                                placeholder="Assignment Type"
                                disabled={!!defaultInitialValues?.assignmentTypeId}
                                onChange={handleAssignmentTypeChange}>
                                <option value="project">Project</option>
                                <option
                                  value="time_off"
                                  disabled={
                                    formik.values.resourceTypeId === 'placeholder' ||
                                    (!auth.allocations.members.manage &&
                                      !formik.values.member?.permissions.manageAllocations)
                                  }>
                                  Time Off
                                </option>
                              </Field.SingleSelect>
                            </Form.Control>

                            {{
                              project: () => (
                                <>
                                  <Form.Control>
                                    <Field.ClientProjectSelect
                                      key={formik.values?.member?.id ?? formik.values?.placeholder?.id}
                                      name="project"
                                      placeholder="Project"
                                      initialValue={allocation.project}
                                      currency={formik.values.placeholder?.rateCurrency}
                                      disabled={
                                        !!defaultInitialValues?.projectId ||
                                        (!auth.allocations.projects.manage &&
                                          !formik.values.member?.permissions.manageAllocations &&
                                          !formik.values.placeholder?.permissions.manageAllocations)
                                      }
                                      clearable={!defaultInitialValues?.projectId}
                                      withPermissions
                                      onFilter={(data) => {
                                        if (formik.values.member?.permissions.manageAllocations) return data;
                                        if (formik.values.placeholder?.permissions.manageAllocations) return data;
                                        return data.filter((p) => p.permissions.manageAllocations);
                                      }}
                                      onChange={handleProjectChange}
                                    />
                                  </Form.Control>

                                  {formik.values.project && (
                                    <>
                                      {formik.values.project.useRoles && (
                                        <Form.Control>
                                          <Field.ProjectRoleSelect
                                            name="role"
                                            placeholder="Role"
                                            project={formik.values.project}
                                            initialValue={allocation.role}
                                          />
                                        </Form.Control>
                                      )}

                                      <Form.Control>
                                        <Field.ProjectTaskSelect
                                          name="task"
                                          placeholder="Task"
                                          project={formik.values.project}
                                          initialValue={allocation.task}
                                        />
                                      </Form.Control>
                                    </>
                                  )}
                                </>
                              ),

                              time_off: () => (
                                <Form.Control>
                                  <Field.TimeOffTypeSelect name="timeOffType" placeholder="Time Off Type" />
                                </Form.Control>
                              ),
                            }[formik.values.assignmentTypeId]()}

                            <Form.Control>
                              <Field.TextArea name="notes" placeholder="Notes" rows={2} maxLength={5000} />
                            </Form.Control>
                          </Form.Section>

                          <Form.Section title="Scheduling">
                            <Form.Control>
                              <Field.SingleSelect
                                name="allocationMethod"
                                placeholder="Allocation Method"
                                onChange={handleAllocationMethodChange}>
                                {allocationMethods.map((option) => (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field.SingleSelect>
                            </Form.Control>

                            {formik.values.allocationMethod === 'period' && (
                              <Form.Control>
                                <Field.RadioGroup name="unit" onChange={handleUnitChange}>
                                  <Radio value="day" label="Day" />
                                  <Radio value="week" label="Week" />
                                  <Radio value="month" label="Month" />
                                </Field.RadioGroup>
                              </Form.Control>
                            )}

                            <Form.Control>
                              <Field.DayPicker
                                name="start"
                                placeholder="Start Date"
                                position="top"
                                scope={dayPickerScope}
                                clearable={false}
                                onChange={handleStartChange}
                              />

                              <Field.DayPicker
                                name="end"
                                placeholder="End Date"
                                position="top"
                                scope={dayPickerScope}
                                clearable={false}
                                onChange={handleEndChange}
                              />

                              {
                                {
                                  day: (
                                    <Field.Number
                                      name="hoursPerDay"
                                      placeholder="Hours Per Day"
                                      min={0}
                                      max={24}
                                      precision={2}
                                    />
                                  ),

                                  week: (
                                    <Field.Number
                                      name="hoursPerWeek"
                                      placeholder="Hours Per Week"
                                      min={0}
                                      max={168}
                                      precision={2}
                                    />
                                  ),

                                  month: (
                                    <Field.Number
                                      name="hoursPerMonth"
                                      placeholder="Hours Per Month"
                                      min={0}
                                      max={744}
                                      precision={2}
                                    />
                                  ),

                                  allocation: (
                                    <Field.Number
                                      name="hoursPerAllocation"
                                      placeholder="Total Hours"
                                      min={0}
                                      max={99999}
                                      precision={2}
                                    />
                                  ),

                                  ratio_of_capacity: (
                                    <Field.Number
                                      prefix="%"
                                      name="hoursPercentageOfCapacity"
                                      placeholder="Percentage of Capacity"
                                      min={0}
                                      max={100}
                                      precision={2}
                                    />
                                  ),
                                }[formik.values.unit]
                              }
                            </Form.Control>

                            {formik.values.resourceTypeId === 'member' &&
                              formik.values.assignmentTypeId === 'project' && (
                                <Form.Control>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Field.Checkbox
                                      name="allocateOnTimeOffDays"
                                      label="Allocate on days when the member has time off"
                                    />
                                    <HelpTooltip
                                      style={{ marginLeft: '.5rem' }}
                                      message="When checked, Ruddr will allocate time to a day where the member has a full day of time off scheduled."
                                    />
                                  </div>
                                </Form.Control>
                              )}
                          </Form.Section>
                        </>
                      ),
                      () => <AllocationHistory allocationId={allocation?.id} />,
                    ][tabIndex]()}
                    {status && <FormMessage.Error>{message}</FormMessage.Error>}
                  </Content>
                  <Drawer.Actions>
                    {allocation.id && <DeleteButton onClick={handleDelete}>Delete</DeleteButton>}

                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>

                      <Button isLoading={isSubmitting} onClick={formik.submitForm}>
                        Save &amp; Close
                      </Button>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}

export default AllocationForm;
