import { Button } from '~/components';
import { useConfirmation, useWorkspace } from '~/contexts';
import moment from 'moment';
import React, { useMemo } from 'react';
import SubmitTimesheetConfirmation from './SubmitTimesheetConfirmation';
import { useTimesheets } from './TimesheetContext';

export default function SubmitTimesheetButton({ date, member, onSubmit }) {
  const timesheetContext = useTimesheets();
  const { workspace } = useWorkspace();

  const dates = useMemo(() => {
    return {
      start: moment(date).startOf('isoWeek'),
      end: moment(date).endOf('isoWeek'),

      get isSemiMonthCrossOver() {
        return moment(this.start).date(15).isBetween(this.start, this.end, 'day');
      },

      get isMonthCrossOver() {
        return this.start.month() !== this.end.month();
      },
    };
  }, [date]);

  const isTimesheetOpen = useMemo(() => {
    const isOpen = timesheetContext.isTimesheetOpen({
      start: dates.start,
      end: dates.end,
    });

    const periodTimesheets = timesheetContext.timesheets?.filter(
      (ts) => moment(ts.start).isSameOrBefore(dates.end) && moment(ts.end).isSameOrAfter(dates.start),
    );

    const isPartialySubmitted = isOpen && periodTimesheets.length;

    // If split timesheet setting is off and the week is partially submitted we should hide the submit button
    if (isPartialySubmitted) {
      if (dates.isMonthCrossOver && !workspace.allowTimesheetSplitAtEndOfMonth) return false;
      if (dates.isSemiMonthCrossOver && !workspace.allowTimesheetSplitAtEndOfSemiMonth) return false;
    }
    return isOpen;
  }, [timesheetContext, dates, workspace]);

  const confirmation = useConfirmation();

  const handleWeekSubmitted = () => {
    onSubmit();
    timesheetContext.fetchTimesheets();
  };

  const handleSubmit = async () => {
    await confirmation.prompt((resolve) => (
      <SubmitTimesheetConfirmation
        date={date}
        member={member}
        onSubmit={handleWeekSubmitted}
        resolve={resolve}
        timesheetContext={timesheetContext}
      />
    ));
  };

  if (!isTimesheetOpen) return null;

  return <Button onClick={handleSubmit}>Submit Timesheet</Button>;
}
