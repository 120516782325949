import _ from 'lodash';
import { del, get, patch, post } from './agent';

const rippling = {
  public: {
    postRedirectUri: (body) => post('/rippling/redirect-uri', body),
  },

  workspace: (workspacesUrl) => {
    const url = _([workspacesUrl, 'rippling']).compact().join('/');

    return {
      get: () => get(url.concat('/integration')),
      remove: () => del(url.concat('/integration')),
      updateSettings: (body) => patch(url.concat('/settings'), body),
      deleteData: () => del(url.concat('/data')),

      manualSync: (body) => post(url.concat('/manual-sync'), body),
      getSyncs: () => get(url.concat('/syncs')),
      getSyncLog: (id) => get(url.concat('/syncs/', id, '/log')),

      getMembers: () => get(url.concat('/members')),
      getEmployees: () => get(url.concat('/employees')),
      getMemberMappings: () => get(url.concat('/member-mappings')),
      updateMemberMappings: (body) => post(url.concat('/member-mappings'), body),

      getExternalTimeOffTypes: () => get(url.concat('/external-time-off-types')),
      getInternalTimeOffTypes: () => get(url.concat('/internal-time-off-types')),
      getTimeOffTypeMappings: () => get(url.concat('/time-off-type-mappings')),
      updateTimeOffTypeMappings: (body) => post(url.concat('/time-off-type-mappings'), body),
    };
  },
};

export default rippling;
