import React from 'react';
import styled from 'styled-components';
import { ModalCard } from '~/components';
import { CaptureCredentials } from './CaptureCredentials';

export const Description = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export default function SalesforceSetup({ onClose, onFinish }) {
  return (
    <ModalCard title="Setup Salesforce Integration" onClose={onClose}>
      <CaptureCredentials onClose={onClose} onFinish={onFinish} />
    </ModalCard>
  );
}
