import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { usePathHistory } from '~/contexts';
import { colors } from '~/styles';
import Icon from './Icon';

const StyledLink = styled(Link)`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.625rem;
  height: auto;
  min-height: 2rem;
  margin-right: 1.5rem;
  padding: 0;
  color: ${colors.primary};
  background-color: ${colors.grey5};
  border-radius: 0.3125rem;
  font-size: 0.6rem;
  align-self: stretch;

  &:hover {
    color: ${colors.primary};
    background-color: ${colors.grey10};
  }
`;

export default function BackLink({ defaultPath, ignoreHistory = false }) {
  const pathHistory = usePathHistory();
  const [lastPath] = useState(() => (ignoreHistory ? defaultPath : pathHistory.lastPath() || defaultPath));
  const [path, setPath] = useState(lastPath);
  const location = useLocation();

  // Logs the history of the last path (when this button shows up)
  useEffect(() => {
    if (!ignoreHistory) {
      pathHistory.initBackPath(lastPath);
    }
  }, [pathHistory, lastPath, ignoreHistory]);

  // Sets the current location path key
  useEffect(() => {
    const path = pathHistory.setLastPath(location.key, lastPath);
    setPath(path);
  }, [pathHistory, lastPath, location]);

  // In order to set a state on the link, the individual properties need to be
  // provided instead of the path string
  const pathParts = useMemo(() => {
    const { pathname, search, hash } = new URL(path, window.location);
    return {
      pathname,
      search,
      hash,
    };
  }, [path]);

  return (
    <StyledLink to={{ ...pathParts, state: { goBack: true } }}>
      <Icon icon="arrow-left" />
    </StyledLink>
  );
}
