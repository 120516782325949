import { useApi, useWorkspace } from '~/contexts';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import InternalClientTooltip from '../InternalClientTooltip';
import Fuse from 'fuse.js';
import { SingleSelectFilter } from '.';

const SingleClientFilter = ({ placeholder = 'Project', name, value, activeOnly, permission, onChange }) => {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [data, setData] = useState(null);
  const [options, setOptions] = useState([]);

  const handleSearch = useCallback(
    async (q) => {
      let options = data;
      if (!data) {
        const result = await api.www
          .workspaces(workspace.id)
          .clients()
          .get({
            q,
            recordStatusId: activeOnly ? 'active' : undefined,
            size: 1000,
            permission,
          });
        setData(result.data);
        options = result.data;
      }
      if (q) {
        options = new Fuse(options, { keys: ['name'], threshold: 0.4 }).search(q).map((result) => result.item);
      }
      setOptions(options);
    },
    [workspace.id, api, data, activeOnly, permission],
  );

  const clientsById = useMemo(() => _.keyBy(options, 'id'), [options]);
  const handleChange = (event) => {
    const changeArgument = { target: { name: event.target.name, value: clientsById[event.target.value] } };
    onChange(changeArgument);
  };

  const groups = useMemo(() => {
    if (!options) return null;
    return _(options)
      .orderBy((o) => (o.active ? 0 : 1))
      .groupBy((o) => (o.recordStatusId === 'active' ? 'Active' : 'Archived'))
      .map((clients, status) => ({
        status,
        clients: _.orderBy(clients, (o) => (o.active ? 0 : 1)),
      }))
      .value();
  }, [options]);

  return (
    <SingleSelectFilter
      icon="gear"
      placeholder={placeholder}
      name={name}
      value={value}
      renderValue={(value) => value.name}
      options={options}
      renderOptions={(opts) => {
        const optionsById = _.keyBy(opts, 'id');
        return groups.map(({ status, clients }) => (
          <SingleSelectFilter.Group key={status} label={status}>
            {clients.map((option) => (
              <SingleSelectFilter.Option
                key={option?.id}
                inactive={!option?.active}
                style={{ display: 'flex' }}
                {...optionsById[option?.id]?.props}>
                <div style={{ display: 'flex' }}>
                  {option.name} {option.isInternal ? <InternalClientTooltip /> : ''}
                </div>
              </SingleSelectFilter.Option>
            ))}
          </SingleSelectFilter.Group>
        ));
      }}
      isLoading={!data}
      onSearch={handleSearch}
      onChange={handleChange}
    />
  );
};

export default SingleClientFilter;
