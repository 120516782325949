import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { dateFormats } from '~/utils';
import { useSelectableCells, useVirtualCells } from '../../components/schedule';
import { Cell, RowCell } from '../../components/schedule/cells';
import AllocatedHoursCell from './AllocatedHoursCell';
import AllocatedHoursSimpleCell from './AllocatedHoursSimpleCell';
import AvailableHoursCell from './AvailableHoursCell';
import BillableUtilizationCell from './BillableUtilizationCell';
import ProductiveUtilizationCell from './ProductiveUtilizationCell';
import TargetAttainmentCell from './TargetAttainmentCell';
import BillableHoursCell from './BillableHoursCell';
import ProjectHoursCell from './ProjectHoursCell';
import ProductiveHoursCell from './ProductiveHoursCell';

function Cells({
  bodyRef,
  start,
  end,
  unit,
  styles,
  rows,
  metric,
  cells,
  readOnly,
  allocations,
  resources,
  onSelectEnd,
}) {
  const Component = useMemo(() => {
    switch (metric) {
      case 'allocated_hours_simple':
        return AllocatedHoursSimpleCell;

      case 'all_hours':
        return AllocatedHoursCell;

      case 'project_hours':
        return ProjectHoursCell;

      case 'billable_hours':
        return BillableHoursCell;

      case 'productive_hours':
        return ProductiveHoursCell;

      case 'available_hours':
        return AvailableHoursCell;

      case 'billable_utilization':
        return BillableUtilizationCell;

      case 'productive_utilization':
        return ProductiveUtilizationCell;

      case 'target_attainment':
        return TargetAttainmentCell;
    }
  }, [metric]);

  const virtualCells = useVirtualCells({
    rows,
    cells,
    parentRef: bodyRef,
    start,
    end,
    unit,
    dateWidth: styles.date.width,
    paddingStart: styles.sidebar.width,
  });

  const cellsData = useMemo(() => {
    return virtualCells.map((cell) => {
      return {
        ...cell,

        get selectable() {
          return !readOnly && ['group', 'row'].includes(this.type) && cell.manage;
        },

        get available() {
          if (this.capacity == null) return null;
          return _.round(this.capacity - this.allocated, 2);
        },

        get utilization() {
          if (this.capacity == null) return null;
          return _.round(this.billable / this.capacity, 2);
        },

        get productiveUtilization() {
          if (this.capacity == null) return null;
          return _.round(this.productive / this.capacity, 2);
        },

        get targetAttainment() {
          if (this.targetBillable == null) return null;
          return _.round(this.billable / this.targetBillable, 2);
        },

        get hasWorkingDays() {
          return this.capacity > 0 || this.workingDays > 0;
        },

        get resourceIds() {
          switch (cell.type) {
            case 'group':
              return [cell.group.id];

            case 'header':
              return resources.map((r) => r.id);

            case 'total':
              return resources.map((r) => r.id);

            default:
              return [];
          }
        },

        get allocations() {
          const overlapping = (allocation) =>
            moment(this.start).isSameOrBefore(allocation.end) && moment(this.end).isSameOrAfter(allocation.start);

          switch (cell.type) {
            case 'group':
              return allocations.filter(
                (allocation) => overlapping(allocation) && allocation.resourceId === cell.group.id,
              );

            case 'header':
              return allocations.filter(
                (allocation) => overlapping(allocation) && allocation.resourceTypeId === cell.resourceType,
              );

            case 'total':
              return allocations.filter((allocation) => overlapping(allocation));

            default:
              return [];
          }
        },

        get start() {
          return cell.date;
        },

        get end() {
          return moment(cell.date).endOf(unit).format(dateFormats.isoDate);
        },

        get unit() {
          return unit;
        },
      };
    });
  }, [virtualCells, readOnly, allocations, unit, resources]);

  const { selection, onSelectStart, onSelect } = useSelectableCells({
    cells: virtualCells,
    onSelectEnd: useCallback(
      (cells) => {
        onSelectEnd(cells);
      },
      [onSelectEnd],
    ),
  });

  return (
    <>
      {cellsData.map((cell) => {
        const selected = selection?.cells.some((c) => c?.key === cell?.key);
        const selecting = selection?.rowIndex === cell.meta.rowIndex;

        const handlePointerDown = (event) => {
          if (event.button !== 0) return;
          onSelectStart(cell);
        };
        const handlePointerEnter = () => onSelect(cell);

        return (
          <Cell
            key={cell.key}
            style={cell.meta.style}
            selected={selected}
            selecting={selecting}
            data-testid={`cell_${cell.meta.row}`}
            data-testid-capacity={cell.capacity}
            selectable={cell.selectable}
            onPointerDown={cell.selectable ? handlePointerDown : undefined}
            onPointerEnter={selecting ? handlePointerEnter : undefined}>
            {cell.type === 'row' ? <RowCell /> : <Component cell={cell} />}
          </Cell>
        );
      })}
    </>
  );
}

export default React.memo(Cells);
