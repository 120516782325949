import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Button, Field, Form, FormMessage, Level } from '~/components';
import { useApi, useReferrer } from '~/contexts';
import { useActions, useDocumentTitle } from '~/hooks';
import { colors, devices, weights } from '~/styles';
import { Footer, Header } from '../components';
import EnvelopeIcon from './assets/envelope.svg?react';
import JoinIcon from './assets/join.svg?react';

const Container = styled.div`
  flex: 1;
  width: 49.5rem;
  max-width: 90%;
  margin: 4.5rem auto;
`;

const Box = styled.div`
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 3px 45px 0px ${colors.grey10};
  padding: 4.75rem 13% 3.25rem;
  margin-top: 3.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Heading = styled.div`
  margin: 0 auto;
  text-align: center;
  max-width: 39rem;
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: ${weights.light};
  line-height: 3.625rem;
  margin-bottom: 0.5rem;
`;

const Byline = styled.div`
  font-size: 1.25rem;
  line-height: 2rem;
  color: ${colors.grey55};
`;

const BoxTitle = styled.div`
  font-size: 2.25rem;
  font-weight: ${weights.light};
  text-align: center;
  margin-bottom: 2rem;
`;

const FormAction = styled.div`
  margin-top: 2.25rem;
  display: flex;
  justify-content: center;

  ${Button} {
    width: 14.6875rem;
    margin: 0 auto;
  }
`;

const Login = styled.div`
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
  color: ${colors.grey55};
`;

const JoinCallout = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4.5rem;
  padding: 2rem 4rem;
  border: solid 1px ${colors.grey10};
  border-radius: 0.625rem;

  @media ${devices.mobile} {
    padding: 1rem 2rem;
  }
`;

const JoinImage = styled(JoinIcon)`
  flex-shrink: 0;
  width: 8rem;
  height: 8rem;

  @media ${devices.mobile} {
    width: 4rem;
    height: 4rem;
  }
`;

const JoinText = styled.div`
  margin-left: 4rem;

  @media ${devices.mobile} {
    margin-left: 2em;
  }
`;

const JoinTitle = styled.div`
  margin-bottom: 0.5rem;
  font-size: 2rem;
  font-weight: ${weights.light};
  line-height: 2.5rem;
`;

const JoinDescription = styled.div`
  line-height: 1.5rem;
  color: ${colors.grey55};
`;

const initialState = { isSubmitting: false, status: null, message: null };
const handlers = {
  submit: () => ({ isSubmitting: true, status: null, message: null }),
  error: ({ message } = {}) => ({
    isSubmitting: false,
    status: 'form-error',
    message,
  }),
};

function Signup() {
  useDocumentTitle('Start your free trial');

  const [view, setView] = useState('form');

  return (
    <>
      <Header showSignUp={false} />
      <Container>
        {{
          form: () => <NewWorkspaceForm onSuccess={() => setView('success')} />,
          success: () => <Success />,
        }[view]()}
      </Container>
      <Footer />
    </>
  );
}

function NewWorkspaceForm({ onSuccess }) {
  const api = useApi();
  const [{ isSubmitting, status, message }, actions] = useActions(handlers, initialState);

  const referrer = useReferrer();
  const searchParams = new URLSearchParams(location.search);

  async function handleSubmit(values) {
    try {
      actions.submit();

      await api.www.signup({
        // Form info
        companyName: values.companyName,
        website: values.website,
        memberName: values.memberName,
        email: values.email,
        // Referrer data
        utmSource: referrer.utmSource,
        utmCampaign: referrer.utmCampaign,
      });

      onSuccess();
    } catch (error) {
      switch (error.status) {
        case 409:
          actions.error({ message: error.message ?? 'There was a problem signing up for a new workspace.' });
          return;
        default:
          actions.error(error?.message);
      }
    }
  }

  return (
    <>
      <Heading>
        <Title>Create a new workspace</Title>
        <Byline>
          Once our team validates the information below, you will receive an invitation to finish setting up your new
          Ruddr workspace.
        </Byline>
      </Heading>
      <Box>
        <Formik
          initialValues={{
            // Workspace details
            companyName: '',
            website: '',
            // Account details
            memberName: searchParams.get('name') ?? '',
            email: searchParams.get('email') ?? '',
          }}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            // Workspace details
            companyName: Yup.string().label('Company Name').max(255).required(),
            website: Yup.string().label('Company Website').max(255).required(),
            // Account details
            memberName: Yup.string().label('Your Name').max(255).required(),
            email: Yup.string().label('Your Email').email().max(255).required(),
          })}>
          <Form autoComplete="off">
            <Form.Control>
              <Field.Text autoFocus name="companyName" placeholder="Company Name" maxLength={255} />
            </Form.Control>
            <Form.Control>
              <Field.Text name="website" placeholder="Company Website" maxLength={255} />
            </Form.Control>
            <Form.Control>
              <Field.Text name="memberName" placeholder="Your Name" maxLength={255} />
            </Form.Control>
            <Form.Control>
              <Field.Text name="email" placeholder="Your Email" maxLength={255} type="email" />
            </Form.Control>
            {status && <FormMessage.Error spaceTop>{message}</FormMessage.Error>}
            <FormAction>
              <Button type="submit" isLoading={isSubmitting}>
                Sign Up
              </Button>
            </FormAction>
            <Login>
              Already have an account?&nbsp;<Link to="/login">Login here</Link>.
            </Login>
          </Form>
        </Formik>
      </Box>
      <JoinCallout>
        <JoinImage />
        <JoinText>
          <JoinTitle>Request an invitation</JoinTitle>
          <JoinDescription>
            Is your team already using Ruddr? Ask your workspace admin to send you an invite.
          </JoinDescription>
        </JoinText>
      </JoinCallout>
    </>
  );
}

function loadCapterraConversionScript() {
  const hostname = window.location.hostname;
  if (!hostname.endsWith('ruddr.io')) {
    return;
  }

  const id = '2154093';
  const key = 'cb2512e6561d3ef295aa47e7e06f39d2';

  const script = document.createElement('script');
  script.async = true;
  script.src = `https://ct.capterra.com/capterra_tracker.js?vid=${id}&vkey=${key}`;

  document.body.appendChild(script);
}

function Success() {
  useEffect(() => {
    loadCapterraConversionScript();
  }, []);

  return (
    <Box>
      <Level gap="5%">
        <Level.Item narrow>
          <EnvelopeIcon />
        </Level.Item>
        <Level.Item>
          <BoxTitle>Thanks, we've got it!</BoxTitle>
          <Byline>
            Once we've verified the information that you provided, you will receive an email with the final steps to
            create your trial workspace.
          </Byline>
        </Level.Item>
      </Level>
    </Box>
  );
}

export default Signup;
