import React from 'react';
import styled from 'styled-components';
import xeroIcon from '~/assets/public/xero-icon.png';
import { BackLink, Page } from '~/components';
import { useWorkspace } from '~/contexts';
import XeroSettingsPage from './XeroSettingsPage';

const XeroLogo = styled.img`
  width: 2.875rem;
  height: 2.875rem;
  max-width: unset;
`;

export default function XeroSettingsArea() {
  const { workspace } = useWorkspace();

  return (
    <>
      <Page>
        <Page.Header>
          <BackLink to={`/app/${workspace.key}/settings/integrations`} />

          <Page.Info>
            <Page.Eyebrow>Settings</Page.Eyebrow>
            <Page.Title>Xero</Page.Title>
          </Page.Info>

          <Page.Actions>
            <XeroLogo src={xeroIcon} />
          </Page.Actions>
        </Page.Header>

        <XeroSettingsPage />
      </Page>
    </>
  );
}
