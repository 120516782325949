import React, { useEffect, useMemo, useState } from 'react';
import SingleSelectFilter from '~/components/filters/SingleSelectFilter';
import { useApi, useWorkspace } from '~/contexts';
import { useFeatures, useIsMounted } from '~/hooks';

export default function CurrencyFilter({ placeholder = 'Currency', name, value, onChange }) {
  const { workspace } = useWorkspace();
  const features = useFeatures();
  const api = useApi();
  const isMounted = useIsMounted();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await api.currencies().get();
      if (!isMounted.current) return;
      setOptions(data);
    })();
  }, [api, isMounted]);

  const availableOptions = useMemo(() => {
    return options
      ?.filter(
        (o) =>
          o.isActive &&
          (o.code === value ||
            o.code === workspace.currency ||
            (features.multicurrency && workspace.currencies.includes(o.code))),
      )
      .map((o) => ({ id: o.code, name: o.name }));
  }, [options, value, workspace.currency, workspace.currencies, features.multicurrency]);

  return (
    <SingleSelectFilter
      icon="gear"
      placeholder={placeholder}
      name={name}
      value={value}
      renderValue={(value) => value.name}
      options={availableOptions}
      clearable={false}
      onChange={onChange}
    />
  );
}
