import { Field, Form, Tooltip, Radio } from '~/components';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import AggregatedBudgetMembersTable from './AggregatedBudgetMembersTable';
import AggregatedBudgetRolesTable from './AggregatedBudgetRolesTable';
import BudgetExpensesTable from './BudgetExpensesTable';
import BudgetForm from './BudgetForm';
import BudgetHoursAlertSetting from './BudgetHoursAlertSetting';
import BudgetMembersTable from './BudgetMembersTable';
import BudgetOtherItemsTable from './BudgetOtherItemsTable';
import BudgetRevenueAlertSetting from './BudgetRevenueAlertSetting';
import BudgetRolesTable from './BudgetRolesTable';
import CapHoursCheckbox from './CapHoursCheckbox';
import CapRevenueCheckbox from './CapRevenueCheckbox';
import FinancialSummary from './FinancialSummary';
import CapExpensesCostCheckbox from './CapExpensesCostCheckbox';
import CapExpensesRevenueCheckbox from './CapExpensesRevenueCheckbox';

const ProjectSettings = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;

  > label {
    display: inline-flex;

    &:not(:first-child) {
      margin-left: 1.5rem;
    }
  }

  > div:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

const Separator = styled.hr`
  margin: ${({ margin }) => margin ?? '0rem 0 1.25rem'};
  border-color: ${colors.grey10};
`;

const TableTitle = styled.span`
  flex: 0;
  color: ${colors.grey75};
  padding-left: 0rem;
  font-size: 0.875rem;
  font-weight: ${weights.bold};
  padding-top: 1rem;
`;

function OverallBudget({
  formik,
  project,
  projectModel,
  onRolesChange,
  onMembersChange,
  onExpensesChange,
  onOtherItemsChange,
}) {
  const currency = projectModel.currency;

  const summaryBudgetDisabled = useMemo(
    () =>
      ['fixed', 'fixed_recurring'].includes(projectModel.billingTypeId) &&
      projectModel.revenueRecognitionMethod === 'automated_tm_basis',
    [projectModel],
  );

  return (
    <>
      <ProjectSettings>
        <Field.RadioGroup name="budgetMode">
          <Tooltip
            message={
              summaryBudgetDisabled
                ? 'A summary budget cannot be used with the selected Revenue Recognition Method.'
                : undefined
            }>
            <Radio value="" label="Summary budget" disabled={summaryBudgetDisabled} />
          </Tooltip>
          <Radio value="detailed" label="Detailed budget" />
          <Radio value="aggregated" label="Detailed budget aggregated from tasks" />
        </Field.RadioGroup>
      </ProjectSettings>

      {!projectModel.budgetMode ? (
        <BudgetForm formik={formik} project={project} projectModel={projectModel} />
      ) : (
        <>
          {['fixed', 'fixed_recurring'].some((b) => b === projectModel.billingTypeId) && (
            <Form.Section
              title="Services Revenue"
              style={{
                borderBottom: `1px solid ${colors.grey10}`,
                marginBottom: '1rem',
              }}>
              <Form.Control>
                <Field.Currency name="estimatedServiceRevenue" placeholder="Services Revenue" currency={currency} />
              </Form.Control>
            </Form.Section>
          )}

          {projectModel.isBillable && projectModel.billingTypeId === 'tm' ? (
            <>
              <Separator margin="0" />
              <TableTitle>Hours and Services Revenue</TableTitle>
            </>
          ) : (
            <TableTitle>Hours</TableTitle>
          )}

          <Form.Control>
            {
              {
                detailed: projectModel.useRoles ? (
                  <BudgetRolesTable projectModel={projectModel} onChange={onRolesChange} />
                ) : (
                  <BudgetMembersTable projectModel={projectModel} onChange={onMembersChange} />
                ),
                aggregated: projectModel.useRoles ? (
                  <AggregatedBudgetRolesTable projectModel={projectModel} />
                ) : (
                  <AggregatedBudgetMembersTable projectModel={projectModel} />
                ),
              }[projectModel.budgetMode]
            }
          </Form.Control>

          <Form.Control>
            <CapRevenueCheckbox project={projectModel} />
          </Form.Control>

          <Form.Control>
            <BudgetRevenueAlertSetting project={projectModel} />
          </Form.Control>

          <Form.Control>
            <CapHoursCheckbox />
          </Form.Control>

          <Form.Control>
            <BudgetHoursAlertSetting project={projectModel} />
          </Form.Control>

          <TableTitle>Expenses</TableTitle>
          <Form.Control>
            <BudgetExpensesTable projectModel={projectModel} onChange={onExpensesChange} />
          </Form.Control>

          <Form.Control>
            <CapExpensesCostCheckbox />
          </Form.Control>

          <Form.Control>
            <Form.Control>
              {projectModel.isBillable ? (
                <CapExpensesRevenueCheckbox />
              ) : (
                <CapExpensesRevenueCheckbox disabled={true} checked={projectModel.capExpensesCost} />
              )}
            </Form.Control>
          </Form.Control>

          {projectModel.isBillable && (
            <>
              <TableTitle>Other Items to Bill</TableTitle>
              <Form.Control>
                <BudgetOtherItemsTable projectModel={projectModel} onChange={onOtherItemsChange} />
              </Form.Control>
            </>
          )}
        </>
      )}

      {projectModel.isBillable && <FinancialSummary project={project} projectModel={projectModel} />}
    </>
  );
}

export default OverallBudget;
