import _, { debounce } from 'lodash';
import React from 'react';
import {
  CurrencyInput,
  MemberFilter,
  OpportunityStageCategoryFilter,
  OpportunityStageFilter,
  OpportunityTypeFilter,
  PracticeFilter,
} from '~/components';
import OpportunityTagFilter from '~/components/filters/OpportunityTagFilter';
import { useFeatures } from '~/hooks';

function OpportunityFiltersBar({ filters, onChange }) {
  const features = useFeatures();

  return (
    <>
      {!_.isEmpty(filters.opportunityStageCategoryId) && (
        <OpportunityStageCategoryFilter
          name="opportunityStageCategoryId"
          value={filters.opportunityStageCategoryId}
          onChange={({ target: { value } }) => onChange({ opportunityStageCategoryId: value })}
        />
      )}

      {!_.isEmpty(filters.opportunityStages) && (
        <OpportunityStageFilter
          name="opportunityStages"
          value={filters.opportunityStages}
          opportunityStageStatusId={filters.opportunityStageCategoryId.map((status) => status.id)}
          onChange={({ target: { value } }) => onChange({ opportunityStages: value })}
        />
      )}

      {!_.isEmpty(filters.opportunityOwner) && (
        <MemberFilter
          value={filters.opportunityOwner}
          name="opportunityOwner"
          placeholder="Opportunity Owner"
          onChange={({ target: { value } }) => onChange({ opportunityOwner: value })}
        />
      )}

      {!_.isEmpty(filters.opportunityTags) && (
        <OpportunityTagFilter
          name="opportunityTags"
          placeholder="Opportunity Tags"
          value={filters.opportunityTags}
          onChange={({ target: { value } }) => onChange({ opportunityTags: value })}
        />
      )}

      {!_.isEmpty(filters.opportunityType) && (
        <OpportunityTypeFilter
          name="opportunityType"
          value={filters.opportunityType}
          placeholder="Opportunity Type"
          onChange={({ target: { value } }) => onChange({ opportunityType: value })}
        />
      )}

      {!!filters.opportunityAmountGreaterThanOrEqual && (
        <CurrencyInput
          name="opportunityAmountGreaterThanOrEqual"
          placeholder="Minimum Opportunity Amount"
          value={filters.opportunityAmountGreaterThanOrEqual}
          onChange={debounce((value) => {
            onChange({ opportunityAmountGreaterThanOrEqual: value });
          }, 500)}
        />
      )}

      {!_.isEmpty(filters.opportunityPractice) && features.practices && (
        <PracticeFilter
          name="opportunityPractice"
          value={filters.opportunityPractice}
          placeholder="Practice"
          onChange={({ target: { value } }) => onChange({ opportunityPractice: value })}
        />
      )}
    </>
  );
}

export default OpportunityFiltersBar;
