import { Field, Form, Radio } from '~/components';
import React from 'react';
import BudgetHoursAlertSetting from './BudgetHoursAlertSetting';
import BudgetRevenueAlertSetting from './BudgetRevenueAlertSetting';
import CapHoursCheckbox from './CapHoursCheckbox';
import CapRevenueCheckbox from './CapRevenueCheckbox';
import { calculateExpenseCost, calculateExpenseMarkup, calculateExpenseRevenue } from '~/utils';
import CapExpensesCostCheckbox from './CapExpensesCostCheckbox';
import CapExpensesRevenueCheckbox from './CapExpensesRevenueCheckbox';
import { useFeatures } from '~/hooks';

function BudgetForm({ formik, project, projectModel }) {
  const currency = projectModel.currency;
  const features = useFeatures();

  const markup = calculateExpenseMarkup({
    enableMarkup: projectModel.budgetEnableMarkup,
    markupMethod: projectModel.budgetMarkupMethod,
    markupAmount: projectModel.budgetMarkupAmount,
    markupRatio: projectModel.budgetMarkupRatio,
    amount: projectModel.budgetExpensesBillableAmount,
  });

  const totalExpenseCost = calculateExpenseCost({
    isBillable: projectModel.isBillable,
    billableAmount: projectModel.budgetExpensesBillableAmount,
    nonBillableAmount: projectModel.budgetExpensesNonBillableAmount,
  });

  const totalExpenseRevenue = calculateExpenseRevenue({ markup, amount: projectModel.budgetExpensesBillableAmount });

  const handleExpensesBillableAmountChange = (value) => {
    // Deactivate "budgetEnableMarkup" if billable amount is 0 or null
    if (!value) formik.setFieldValue('budgetEnableMarkup', false);
    formik.setFieldValue('budgetExpensesBillableAmount', value);
  };

  return (
    <>
      {projectModel.isBillable && (
        <Form.Section title="Services Revenue">
          <Form.Control>
            <Field.Currency name="estimatedServiceRevenue" placeholder="Services Revenue" currency={currency} />
          </Form.Control>

          <Form.Control>
            <CapRevenueCheckbox project={projectModel} />
          </Form.Control>

          <Form.Control>
            <BudgetRevenueAlertSetting project={projectModel} />
          </Form.Control>
        </Form.Section>
      )}

      <Form.Section title="Hours">
        {projectModel.isBillable && (
          <Form.Control>
            <Field.Number name="estimatedBillableHours" min={0} precision={2} placeholder="Billable Hours" />
          </Form.Control>
        )}

        <Form.Control>
          <Field.Number name="estimatedNonBillableHours" min={0} precision={2} placeholder="Non-Billable Hours" />
        </Form.Control>

        <Form.Control>
          <CapHoursCheckbox />
        </Form.Control>

        <Form.Control>
          <BudgetHoursAlertSetting project={projectModel} />
        </Form.Control>
      </Form.Section>

      <Form.Section title="Expenses">
        {projectModel.isBillable && (
          <Form.Control>
            <Field.Currency
              onChange={handleExpensesBillableAmountChange}
              name="budgetExpensesBillableAmount"
              placeholder="Billable Cost"
              currency={currency}
            />
          </Form.Control>
        )}

        <Form.Control>
          <Field.Currency name="budgetExpensesNonBillableAmount" placeholder="Non-Billable Cost" currency={currency} />
        </Form.Control>

        <Form.Control>
          <Field.Currency
            name="totalExpenseCost"
            value={totalExpenseCost}
            materialPlaceholder="Total Expense Cost"
            disabled
            materialAlwaysVisible
            currency={currency}
          />
        </Form.Control>

        {(features.expenseMarkup || project.budgetEnableMarkup) && (
          <Form.Control>
            <Field.Checkbox
              name="budgetEnableMarkup"
              label="Markup the billable expense budget amount"
              disabled={!projectModel.isBillable || !(projectModel?.budgetExpensesBillableAmount > 0)}
            />
          </Form.Control>
        )}

        {projectModel.budgetEnableMarkup && projectModel.isBillable && (
          <>
            <Form.Control>
              <Field.RadioGroup name="budgetMarkupMethod">
                <Radio key="percentage" value="percentage" label="Markup by Percentage" />
                <Radio key="amount" value="amount" label="Markup by Fixed Amount" />
              </Field.RadioGroup>
            </Form.Control>

            {projectModel.budgetMarkupMethod && (
              <Form.Control>
                {projectModel.budgetMarkupMethod === 'amount' && (
                  <Field.Currency name="budgetMarkupAmount" placeholder="Markup Amount" currency={currency} />
                )}

                {projectModel.budgetMarkupMethod === 'percentage' && (
                  <Field.Number
                    suffix="%"
                    name="budgetMarkupRatio"
                    placeholder="Markup Percentage"
                    min={0}
                    max={100}
                    precision={2}
                  />
                )}
              </Form.Control>
            )}
          </>
        )}

        <Form.Control>
          <Field.Currency
            name="totalExpenseRevenue"
            value={totalExpenseRevenue}
            materialPlaceholder="Total Expense Revenue"
            disabled
            materialAlwaysVisible
            currency={currency}
          />
        </Form.Control>

        <Form.Control>
          <CapExpensesCostCheckbox />
        </Form.Control>

        <Form.Control>
          {projectModel.budgetEnableMarkup ? (
            <CapExpensesRevenueCheckbox />
          ) : (
            <CapExpensesRevenueCheckbox disabled={true} checked={projectModel.capExpensesCost} />
          )}
        </Form.Control>
      </Form.Section>

      {projectModel.isBillable && (
        <Form.Section
          title={
            <>
              Other Items
              <br />
              to Bill
            </>
          }>
          <Form.Control help="The amount we charge the client for Other Items.">
            <Field.Currency name="budgetOtherItemsFee" placeholder="Amount" currency={currency} />
          </Form.Control>
        </Form.Section>
      )}
    </>
  );
}

export default BudgetForm;
