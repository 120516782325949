import _ from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  BackLink,
  Buttons,
  ClientLink,
  Currency,
  DateTime,
  DeleteButton,
  FieldControl,
  Form,
  Grid,
  Icon,
  IconButton,
  InlineTooltip,
  MultilineText,
  Page,
  Percent,
  RouteLink,
  SplitButton,
  Table,
  Tooltip,
  TooltipButton,
} from '~/components';
import { useConfirmation, useIntegrations, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useNumberFormat } from '~/hooks';
import { colors, weights } from '~/styles';
import ClientPaymentView from '../../../invoices/payments/ClientPaymentView';
import ProjectTags from '../components/ProjectTags';
import QBOIndicator from '../components/QBOIndicator';
import XeroIndicator from '../components/XeroIndicator';
import LoadFromQuickBooksDialog from '../dialogs/LoadFromQuickBooksDialog';
import LoadFromXeroDialog from '../dialogs/LoadFromXeroDialog';
import UnpublishDialog from '../dialogs/UnpublishDialog';
import WebLinkModal from '../dialogs/WebLinkModal';
import HistoryDrawer from './HistoryDrawer';
import {
  ActionsContainer,
  BilledTo,
  CreditNoteFor,
  Footer,
  LineItemsSection,
  Notes,
  NotesLabel,
  PreviewButton,
  SaveButton,
  Separator,
  SummaryDetails,
  SummaryDetailsRow,
  SummaryGrid,
  SummarySection,
  SummaryTotal,
  Tag,
  Tags,
  TotalLabel,
  TotalRow,
  TotalValue,
  Totals,
  TotalsSeparator,
} from './StyledComponents';

const P = styled.p`
  white-space: pre-wrap;
`;

function HiddenColumnTooltip() {
  return (
    <Tooltip
      style={{ marginLeft: '0.5rem' }}
      message="This column will not be shown on the published version of this credit note.">
      <Icon icon="info-circle" />
    </Tooltip>
  );
}

function PublishedView({ creditNote, onChange, onDelete, onPreview, onSend }) {
  const { workspace } = useWorkspace();
  const features = useFeatures();
  const integrations = useIntegrations();
  const [dialog, setDialog] = useState(null);

  const documentTitle = useDocumentTitle(creditNote ? `Credit Note #${creditNote.number}` : undefined);

  const confirmation = useConfirmation();

  const handleGetWebLink = () => {
    setDialog('webLink');
  };

  const handleViewHistory = () => {
    setDialog('history');
  };

  const handleCloseDialog = () => {
    setDialog(null);
    documentTitle.set(creditNote ? `Credit Note #${creditNote.number}` : undefined);
  };

  const handleUnpublish = async () => {
    await confirmation.prompt((resolve) => (
      <UnpublishDialog
        creditNoteId={creditNote.id}
        onClose={() => resolve()}
        onSaved={async () => {
          await onChange();
          resolve();
        }}
      />
    ));
  };

  const handleSend = () => {
    onSend();
  };

  const handleReloadFromQuickBooks = async (creditNote) => {
    await confirmation.prompt((resolve) => (
      <LoadFromQuickBooksDialog
        creditNote={creditNote}
        resolve={async () => {
          await onChange();
          resolve();
        }}
      />
    ));
  };

  const handleReloadFromXero = async (creditNote) => {
    await confirmation.prompt((resolve) => (
      <LoadFromXeroDialog
        creditNote={creditNote}
        resolve={async () => {
          await onChange();
          resolve();
        }}
      />
    ));
  };

  const { nonTaxableSubtotal, taxableSubtotal, tax, total } = creditNote;

  const quantityFormat = useNumberFormat({ minimumFractionDigits: 0 });

  const currency = creditNote.currency;

  const column = (name) => creditNote.displayColumns.includes(name);

  const unpublish = !creditNote.permissions.manage
    ? {
        disabled: true,
        tooltip: 'Insufficient permissions to unpublish this credit note.',
      }
    : creditNote.qboCreditMemoId
      ? { disabled: true, tooltip: 'This credit note has been saved to QuickBooks Online.' }
      : creditNote.xeroCreditNoteId
        ? { disabled: true, tooltip: 'This credit note has been saved to Xero.' }
        : { disabled: false, tooltip: undefined };

  return (
    <Page>
      <Page.Header>
        <BackLink defaultPath={`/app/${workspace.key}/billing/credit-notes`} />
        <Page.Info>
          <Page.Title>Credit Note #{creditNote.number}</Page.Title>
          <Tags>
            <Tag>
              <ClientLink client={creditNote.client} />
            </Tag>

            {!_.isEmpty(creditNote.projects) && (
              <Tag>
                <ProjectTags client={creditNote.client} projects={creditNote.projects} />
              </Tag>
            )}

            {features.multicurrency && <Tag>{currency}</Tag>}

            <Tag color={{ open: colors.primary, paid: colors.success }[creditNote.status.id]}>
              {creditNote.status.name}

              {creditNote.qboCreditMemoId && <QBOIndicator />}
              {creditNote.xeroCreditNoteId && <XeroIndicator xeroCreditNoteId={creditNote.xeroCreditNoteId} />}
            </Tag>

            {creditNote.sentAt && (
              <Tag>
                Sent on&nbsp;
                <DateTime value={creditNote.sentAt} />
              </Tag>
            )}

            {creditNote.isLate && (
              <Tag color={colors.danger}>
                {creditNote.daysLate} {creditNote.daysLate === 1 ? 'Day' : 'Days'} Late
              </Tag>
            )}
          </Tags>
        </Page.Info>
        <Page.Actions>
          <IconButton icon="history" tooltip="Credit Note History" onClick={handleViewHistory} />
        </Page.Actions>
      </Page.Header>

      <Page.Section>
        <SummarySection>
          <SummaryGrid>
            <SummaryDetailsRow equalHeight>
              <Grid.Column>
                <BilledTo>
                  <dt>Bill To</dt>
                  <dl>
                    <MultilineText value={creditNote.billTo} />
                  </dl>
                </BilledTo>
              </Grid.Column>
              <Grid.Column>
                <SummaryDetails>
                  {creditNote.poNumber && (
                    <>
                      <dt>PO #</dt>
                      <dd>{creditNote.poNumber}</dd>
                    </>
                  )}

                  <dt>Issued</dt>
                  <dd>
                    <DateTime value={creditNote.issuedOn} />
                  </dd>
                </SummaryDetails>
              </Grid.Column>

              <Grid.Column>
                <SummaryTotal>
                  <FieldControl>
                    <strong>
                      <Currency value={total} currency={currency} />
                    </strong>
                  </FieldControl>
                  <small>Total Credit</small>
                </SummaryTotal>
              </Grid.Column>
            </SummaryDetailsRow>
            <Grid.Row>
              <Grid.Column>
                <CreditNoteFor>
                  <dt>Credit Note For</dt>
                  <dd>{creditNote.creditNoteFor}</dd>
                </CreditNoteFor>
              </Grid.Column>
            </Grid.Row>
          </SummaryGrid>
        </SummarySection>
      </Page.Section>

      <LineItemsSection>
        <Table style={{ fontSize: '.875rem' }}>
          <Table.BoxHeader>
            <Table.Column width="15rem">Item {!column('item') && <HiddenColumnTooltip />}</Table.Column>
            <Table.Column>Details {!column('details') && <HiddenColumnTooltip />}</Table.Column>
            <Table.Column align="right" width="7rem">
              QTY {!column('quantity') && <HiddenColumnTooltip />}
            </Table.Column>
            <Table.Column align="right" width="11rem">
              Rate {!column('rate') && <HiddenColumnTooltip />}
            </Table.Column>
            <Table.Column align="right" width="10rem">
              Amount
            </Table.Column>
            <Table.Column align="center" width="4rem">
              Tax {!column('tax') && <HiddenColumnTooltip />}
            </Table.Column>
          </Table.BoxHeader>

          {creditNote.lines.map((line) => (
            <Table.BoxRow key={line.id}>
              <Table.Cell>{line.item}</Table.Cell>

              <Table.Cell>
                <div>
                  <P>{line.description}</P>
                </div>
              </Table.Cell>

              <Table.Cell align="right">{line.quantity ? quantityFormat.format(line.quantity) : ''}</Table.Cell>

              <Table.Cell align="right">
                <Currency value={line.rate} useDash={false} maximumFractionDigits={7} currency={currency} />
              </Table.Cell>

              <Table.Cell align="right">
                <Currency value={line.amount} useDash={false} currency={currency} />
              </Table.Cell>

              <Table.Cell>{line.taxable && <Icon icon="check" color={colors.success} />}</Table.Cell>
            </Table.BoxRow>
          ))}
        </Table>

        <Separator />
      </LineItemsSection>

      <Footer>
        {creditNote.notes && (
          <Notes>
            <NotesLabel>Note to Client</NotesLabel>
            <P>{creditNote.notes}</P>
          </Notes>
        )}

        <Totals>
          {tax > 0 && (
            <>
              <TotalRow>
                <TotalLabel>Subtotal</TotalLabel>
                <TotalValue>
                  <Currency value={creditNote.linesSubtotal} currency={currency} />
                </TotalValue>
              </TotalRow>

              <TotalsSeparator />

              <TotalRow>
                <TotalLabel>Non-Taxable Subtotal</TotalLabel>
                <TotalValue>
                  <Currency value={nonTaxableSubtotal} currency={currency} />
                </TotalValue>
              </TotalRow>

              <TotalRow>
                <TotalLabel>Taxable Subtotal</TotalLabel>
                <TotalValue>
                  <Currency value={taxableSubtotal} currency={currency} />
                </TotalValue>
              </TotalRow>

              <TotalRow>
                <TotalLabel>
                  Tax{' '}
                  {creditNote.taxRate !== null && (
                    <Percent value={creditNote.taxRate / 100} minimumFractionDigits={0} maximumFractionDigits={7} />
                  )}
                </TotalLabel>
                <TotalValue>
                  <Currency value={tax} currency={currency} />
                </TotalValue>
              </TotalRow>

              <TotalsSeparator />
            </>
          )}

          <TotalRow style={{ fontWeight: weights.bold }}>
            <TotalLabel>Total Credit</TotalLabel>
            <TotalValue>
              <Currency value={total} currency={currency} />
            </TotalValue>
          </TotalRow>
          {creditNote.invoices?.length > 0 &&
            creditNote.invoices.map(({ amount, invoiceId }) => {
              return (
                <TotalRow key={invoiceId} data-testid={invoiceId}>
                  <TotalLabel>Applied Credit</TotalLabel>
                  <TotalValue>
                    <RouteLink to={`/app/${workspace.key}/billing/invoices/${invoiceId}`}>
                      <Currency value={amount} currency={currency} />
                    </RouteLink>
                  </TotalValue>
                </TotalRow>
              );
            })}

          {creditNote.payments?.length > 0 &&
            creditNote.payments.map(({ amount, clientPaymentId }) => {
              function handleViewPayment() {
                setDialog({ type: 'viewPayment', clientPaymentId });
              }
              return (
                <TotalRow key={clientPaymentId} data-testid={clientPaymentId}>
                  <TotalLabel>Applied to Payment</TotalLabel>
                  <TotalValue clickable onClick={handleViewPayment}>
                    <Currency value={amount} currency={currency} />
                  </TotalValue>
                </TotalRow>
              );
            })}
          <TotalRow style={{ fontWeight: weights.bold }}>
            <TotalLabel>Remaining Credit</TotalLabel>
            <TotalValue>
              <Currency value={creditNote.balance} currency={currency} />
            </TotalValue>
          </TotalRow>
        </Totals>
      </Footer>

      <Form.Actions>
        <TooltipButton
          component={DeleteButton}
          disabled={!creditNote.permissions.manage}
          tooltip={!creditNote.permissions.manage ? 'Insufficient permissions to delete this credit note.' : undefined}
          onClick={onDelete}>
          Delete
        </TooltipButton>

        <ActionsContainer>
          <Buttons>
            <PreviewButton onClick={onPreview}>Preview</PreviewButton>

            <SplitButton>
              <SaveButton disabled={!creditNote.permissions.manage} onClick={handleSend}>
                Send
                {!creditNote.permissions.manage && (
                  <InlineTooltip message="Insufficient permissions to send this credit note." />
                )}
              </SaveButton>

              <SplitButton.Menu position="top">
                {({ setIsOpen }) => {
                  const handleAction = async (action) => {
                    setIsOpen(false);
                    await action();
                  };

                  return (
                    <>
                      <SplitButton.Item
                        onClick={handleSend}
                        disabled={!creditNote.permissions.manage}
                        tooltip={
                          !creditNote.permissions.manage
                            ? 'Insufficient permissions to send this credit note.'
                            : undefined
                        }>
                        Send
                      </SplitButton.Item>

                      <SplitButton.Item
                        disabled={unpublish.disabled}
                        tooltip={unpublish.tooltip}
                        onClick={() => handleAction(handleUnpublish)}>
                        Unpublish
                      </SplitButton.Item>

                      <SplitButton.Item onClick={handleGetWebLink}>Get Web Link</SplitButton.Item>

                      {integrations.qbo && creditNote.qboCreditMemoId && (
                        <SplitButton.Item
                          disabled={!creditNote.permissions.manage}
                          tooltip={
                            !creditNote.permissions.manage
                              ? 'Insufficient permissions to reload this credit note from QuickBooks Online.'
                              : undefined
                          }
                          onClick={() => handleAction(() => handleReloadFromQuickBooks(creditNote))}>
                          Reload from QuickBooks
                        </SplitButton.Item>
                      )}

                      {!!integrations.xero && creditNote.xeroCreditNoteId && (
                        <SplitButton.Item
                          disabled={!creditNote.permissions.manage}
                          tooltip={
                            !creditNote.permissions.manage
                              ? 'Insufficient permissions to reload this credit note from Xero.'
                              : undefined
                          }
                          onClick={() => handleAction(() => handleReloadFromXero(creditNote))}>
                          Reload from Xero
                        </SplitButton.Item>
                      )}
                    </>
                  );
                }}
              </SplitButton.Menu>
            </SplitButton>
          </Buttons>
        </ActionsContainer>
      </Form.Actions>

      {
        {
          webLink: <WebLinkModal creditNote={creditNote} onClose={handleCloseDialog} />,
          viewPayment: <ClientPaymentView paymentId={dialog?.clientPaymentId} onClose={handleCloseDialog} />,
          history: <HistoryDrawer creditNoteId={creditNote.id} onClose={handleCloseDialog} />,
        }[dialog?.type ?? dialog]
      }
    </Page>
  );
}

export default PublishedView;
