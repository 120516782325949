import { Page, Tab, Tabs } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import OpportunityCreateForm from '../opportunities/OpportunityCreateForm';
import CompanyContactsTab from './CompanyContactsTab';
import CompanyDetailsPage from './CompanyDetailsPage';
import CompanyHeader from './CompanyHeader';
import CompanyOpportunitiesTab from './CompanyOpportunitiesTab';
import CompanyForm from './CompanyForm';
import { CompanyFilesTab } from './files';

export default function CompanyArea() {
  const [query, setQuery] = useState({ isReady: false, data: null, error: null });
  const history = useHistory();
  const documentTitle = useDocumentTitle(query.data?.name);

  const api = useApi();
  const { workspace } = useWorkspace();
  const auth = useAuth();

  const { companyId: companyId } = useParams();
  const { url, path } = useRouteMatch();

  const fetchData = useCallback(async () => {
    try {
      const { data: company } = await api.www.workspaces(workspace.id).companies(companyId).get();
      setQuery({ isReady: true, data: company });
    } catch (error) {
      setQuery({ isReady: true, data: null, error });
    }
  }, [workspace.id, companyId, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  async function handleSaved() {
    fetchData();
  }

  if (!query.isReady) return <PageLoader />;
  if (!query.data) return <ErrorPage />;

  const company = query.data;

  return (
    <Page scrollable>
      <CompanyHeader company={company} />

      <Page.Tabs>
        <Tabs>
          <Tab to={`${url}/overview`}>Overview</Tab>
          <Tab to={`${url}/opportunities`}>Opportunities</Tab>
          <Tab to={`${url}/contacts`}>Contacts</Tab>
          <Tab to={`${url}/files`}>Files</Tab>
        </Tabs>
      </Page.Tabs>

      <Switch>
        <Route path={`${path}/overview`}>
          <CompanyDetailsPage company={company} />
        </Route>

        <Route path={`${path}/opportunities`}>
          <CompanyOpportunitiesTab companyId={company.id} />
        </Route>

        <Route path={[`${path}/contacts`, `${path}/contacts/:action`, `${path}/contacts/:contactId/:action`]} exact>
          <CompanyContactsTab company={company} />
        </Route>

        <Route path={`${path}/files`}>
          <CompanyFilesTab company={company} />
        </Route>

        <Redirect path="/" to={`${url}/overview`} />
      </Switch>

      <Route path={`${path}/opportunities/new`}>
        {auth.pipeline.manage ? (
          <OpportunityCreateForm
            onSaved={(opportunity) =>
              history.push(`/app/${workspace.key}/pipeline/opportunities/${opportunity.id}/overview/edit`)
            }
            onClose={() => history.push({ pathname: `${url}/opportunities`, search: location.search })}
            company={company}
          />
        ) : (
          <Redirect to={`${url}/opportunities`} />
        )}
      </Route>

      <Route path={`${path}/:companyTab/edit`}>
        {auth.pipeline.manage ? (
          <CompanyForm
            onSaved={handleSaved}
            onClose={(companyTab) => {
              history.push({ pathname: `${url}/${companyTab}`, search: location.search });
              documentTitle.set(company.name);
            }}
          />
        ) : (
          <Redirect to={`${url}/overview`} />
        )}
      </Route>
    </Page>
  );
}
