import _ from 'lodash';
import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import {
  Dropdown,
  ExportDropdown,
  FiltersBar,
  IconButton,
  Page,
  ProjectTaskRecordStatusFilter,
  ProjectTaskStatusFilter,
  SearchInput,
} from '~/components';
import { mimeTypes } from '~/utils';

function ProjectTasksListFilters({ project, filters: { q, statusId, recordStatusId } = {}, onChange, onExport }) {
  const { url } = useRouteMatch();
  const location = useLocation();

  return (
    <>
      <Page.TabHeader>
        <Page.Title>Tasks</Page.Title>

        <Page.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await onExport(`${_.snakeCase(project.key)}_tasks.csv`, mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await onExport(`${_.snakeCase(project.key)}_tasks.xlsx`, mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <Dropdown align="right">
            {({ setIsOpen }) => (
              <>
                <Dropdown.Trigger>
                  <IconButton
                    icon="plus"
                    onClick={() => setIsOpen(true)}
                    disabled={!project.permissions.edit}
                    tooltip={
                      project.permissions.edit ? 'New Task' : 'Your security role prohibits you from creating tasks.'
                    }
                  />
                </Dropdown.Trigger>

                <Dropdown.Menu style={{ width: '15rem' }}>
                  <Dropdown.Link to={`${url}/new${location.search}`}>New Task</Dropdown.Link>
                  <Dropdown.Link to={`${url}/add-from-templates`}>Add from Task Template</Dropdown.Link>
                </Dropdown.Menu>
              </>
            )}
          </Dropdown>
        </Page.Actions>
      </Page.TabHeader>

      <Page.Filters>
        <FiltersBar>
          <SearchInput value={q} placeholder="Search" onChange={({ target: { value } }) => onChange({ q: value })} />

          <ProjectTaskStatusFilter
            value={statusId}
            onChange={({ target: { value } }) => onChange({ statusId: value })}
          />

          <ProjectTaskRecordStatusFilter
            name="recordStatusId"
            value={recordStatusId}
            onChange={({ target: { value } }) => onChange({ recordStatusId: value })}
          />
        </FiltersBar>

        <Page.Actions></Page.Actions>
      </Page.Filters>
    </>
  );
}

export default ProjectTasksListFilters;
