import _ from 'lodash';
import { QueryString } from '~/utils';
import { del, get, patch, post, put } from './agent';
import clientApprovalSchedules from './clientApprovalSchedules';
import clientApprovals from './clientApprovals';
import projectDashboard from './projectDashboard';
import projectFiles from './projectFiles';
import projectHealthReports from './projectHealthReports.js';
import projectMembers from './projectMembers';
import projectRoles from './projectRoles';
import projectTasks from './projectTasks';

const projects =
  (workspacesUrl) =>
  (id = '') => {
    const url = _([workspacesUrl, 'projects', id]).compact().join('/');

    return {
      get: (query = {}) => get(url.concat(new QueryString(query, { multi: true }).toString(true))),
      export: (query = {}, headers = {}) =>
        get(url.concat('/export').concat(new QueryString(query, { multi: true }).toString(true)), headers),
      selectOptions: (query = {}) => get(url.concat('/select-options').concat(new QueryString(query).toString(true))),
      graph: (query = {}) => get(url.concat('/graph').concat(new QueryString(query).toString(true))),
      clientRoles: (query = {}) => get(url.concat('/client-roles').concat(new QueryString(query).toString(true))),
      getProjectRealizationRateKPI: (query = {}) =>
        get(url.concat('/kpi/realization-rate').concat(new QueryString(query).toString(true))),
      getEffectiveRateKPI: (query = {}) =>
        get(url.concat('/kpi/effective-rate').concat(new QueryString(query).toString(true))),
      getProjectMarginKPI: (query = {}) => get(url.concat('/kpi/margin').concat(new QueryString(query).toString(true))),
      getProjectHoursKPI: (query = {}) => get(url.concat('/kpi/hours').concat(new QueryString(query).toString(true))),
      getProjectExpensesKPI: (query = {}) =>
        get(url.concat('/kpi/expenses').concat(new QueryString(query).toString(true))),
      getProjectRevenueKPI: (query = {}) =>
        get(url.concat('/kpi/revenue').concat(new QueryString(query).toString(true))),
      hasDependencies: () => get(url.concat('/has-dependencies')),
      detailsCard: () => get(url.concat('/details-card')),
      history: () => get(url.concat('/history')),
      hasInvoices: () => get(url.concat('/has-invoices')),
      hasCreditNotes: () => get(url.concat('/has-credit-notes')),
      upsert: (body) => (id ? patch : post)(url, body),
      clone: (body) => post(url.concat('/clone'), body),
      updateGraph: (body) => patch(url.concat('/graph'), body),
      setRecordStatus: ({ recordStatusId, statusId }) =>
        put(url.concat('/record-status'), { recordStatusId, statusId }),
      setStatus: ({ statusId }) => put(url.concat('/statusId'), { statusId }),
      setUseRoles: ({ useRoles }) => put(url.concat('/use-roles'), { useRoles }),
      delete: () => del(url),
      files: projectFiles(url.concat('/files')),
      healthReports: projectHealthReports(url.concat('/health-reports')),
      roles: projectRoles(url.concat('/roles')),
      dashboard: projectDashboard(url.concat('/dashboard')),
      members: projectMembers(url.concat('/members')),
      tasks: projectTasks(url.concat('/tasks')),
      clientApprovals: clientApprovals.project(url.concat('/client-approvals')),
      clientApprovalSchedules: clientApprovalSchedules(url.concat('/client-approval-schedules')),
      client: () => get(url.concat('/client')),
      purchaseOrders: (query = {}) => get(url.concat('/purchase-orders').concat(new QueryString(query).toString(true))),
      workspaceMembers: (query = {}) =>
        get(url.concat('/workspace-members').concat(new QueryString(query).toString(true))),
      defaultInvoiceItem: (query = {}) =>
        get(url.concat('/default-invoice-item').concat(new QueryString(query).toString(true))),
      timeApproval: {
        get: () => get(url.concat('/time-approval')),
        update: (body) => put(url.concat('/time-approval'), body),
      },
      expenseApproval: {
        get: () => get(url.concat('/expense-approval')),
        update: (body) => put(url.concat('/expense-approval'), body),
      },
      getUnassignedTasksBudget: () => get(url.concat('/unassigned-tasks-budget')),
      generateRecurringDates: (body) => post(url.concat('/generate-recurring-dates'), body),
      generateRevRecEntries: (body) => post(url.concat('/generate-rev-rec-entries'), body),
    };
  };

export default projects;
