import _ from 'lodash';
import { QueryString } from '~/utils';
import { get, post, patch, put, del } from './agent';

const businessUnits =
  (workspacesUrl) =>
  (id = '') => {
    const url = _([workspacesUrl, 'business-units', id]).compact().join('/');

    return {
      get: (query = {}, config) => get(url.concat(new QueryString(query).toString(true)), config),
      hasDependencies: () => get(url.concat('/has-dependencies')),
      upsert: (body) => (id ? patch : post)(url, body),
      setActiveStatus: (flag = true) => put(url.concat('/is-active'), { isActive: flag }),
      setInvoiceImage: (file) =>
        put(url.concat('/invoice-image'), file?.bytes, file ? { headers: { 'Content-Type': file?.type } } : undefined),
      setDefaultInvoiceImage: () => put(url.concat('/set-default-invoice-image')),
      delete: () => del(url),
    };
  };

export default businessUnits;
