import React from 'react';
import SingleSelectFilter from '~/components/filters/SingleSelectFilter';

export default function ExpenseTypeFilter(props) {
  return (
    <SingleSelectFilter
      icon="filter"
      placeholder="Expense Type"
      renderValue={(value) => value.name}
      options={[
        { id: 'member', name: 'Member' },
        { id: 'project', name: 'Project' },
      ]}
      {...props}
    />
  );
}
