import { useApi, useToast, useWorkspace } from '~/contexts';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PageLoader } from '~/routes/public/pages';

export default function HubSpotRedirect() {
  const api = useApi();
  const location = useLocation();
  const { navigateWorkspace } = useWorkspace();
  const toast = useToast();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const state = new URLSearchParams(location.search).get('state');
      const workspace = await api.www.workspaces(state).get();
      try {
        await api.hubspot.postRedirectUri({ uri: window.location.href });
        toast.success('Successfully connected with HubSpot');
        await navigateWorkspace(workspace.key, '/settings/integrations/hubspot?action=setup');
        // history.push(`/app/${workspace.key}/settings/integrations/hubspot?action=setup`);
      } catch (error) {
        toast.error(error.message);
        await navigateWorkspace(workspace.key, '/settings/integrations');
        // history.push(`/app/${workspace.key}/settings/integrations`);
      }
    })();
  }, [location.search, api, navigateWorkspace, toast, history]);

  return <PageLoader />;
}
