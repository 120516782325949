import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import icon from '~/assets/public/expensify-icon.svg';
import { BackLink, Icon, Page } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import ExpensifySettings from './ExpensifySettings';

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Info = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.h1`
  margin-bottom: 0.75rem;
  font-size: 3rem;
  font-weight: 300;
`;

const Byline = styled.h3`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
`;

const Logo = styled.img`
  display: block;
  width: 3.875rem;
  height: 3.875rem;
  margin-left: 2rem;
`;

const ErrorContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
`;

const ErrorIcon = styled(Icon)`
  color: ${colors.error};
  font-size: 2rem;
  margin-right: 1rem;
`;

const ErrorText = styled.p`
  font-size: 1.25rem;
`;

function ErrorMessage() {
  return (
    <ErrorContainer>
      <ErrorIcon icon="exclamation-triangle" />
      <ErrorText>
        There was a problem getting your Expensify information or the Expensify integration is not enabled.
      </ErrorText>
    </ErrorContainer>
  );
}

export default function ExpensifyArea() {
  useDocumentTitle('Expensify Integration Settings');

  const api = useApi();
  const { workspace } = useWorkspace();
  const [integration, setIntegration] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const isConnected = useMemo(() => Boolean(integration && integration.isConnected), [integration]);

  const fetchIntegration = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).expensify.get();
      setIntegration(data);
    } finally {
      setIsLoading(false);
    }
  }, [api, workspace]);

  useEffect(() => {
    fetchIntegration();
    return fetchIntegration.cancel;
  }, [fetchIntegration]);

  return (
    <Page>
      <Page.Header>
        <BackLink defaultPath={`/app/${workspace.key}/settings/integrations`} />
        <InfoContainer>
          <Info>
            <Byline>Integration Settings</Byline>
            <Title>Expensify</Title>
          </Info>
          <Logo src={icon} />
        </InfoContainer>
      </Page.Header>
      {isLoading ? (
        <PageLoader />
      ) : isConnected ? (
        <ExpensifySettings integration={integration} reloadIntegration={fetchIntegration} />
      ) : (
        <ErrorMessage />
      )}
    </Page>
  );
}
