import { rgba } from 'polished';
import React, { useEffect, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import styled, { css } from 'styled-components';
import { DayPicker, Icon } from '~/components';
import { useDateTimeFormat } from '~/hooks';
import { colors } from '~/styles';

const Text = styled.div`
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 0 0 0.875rem;
  font-size: 0.75rem;
`;

const Placeholder = styled.span`
  color: ${colors.grey40};
`;

const Box = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  color: ${colors.grey75};
  background-color: transparent;
  border: 1px solid ${colors.grey10};
  border-radius: 0.3125rem;
  user-select: none;

  ${({ $hasValue }) =>
    $hasValue &&
    css`
      border-color: ${colors.grey40};
    `}
`;

const Control = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 2rem;
  outline: none;
  box-shadow: none;
  cursor: pointer;

  &:focus {
    ${Box} {
      border: 1px solid ${colors.primary};
      border-radius: 0.3125rem;
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${Box} {
        border: 1px solid ${colors.primary};
        border-radius: 0.3125rem;
      }
    `}
`;

const Status = styled.div`
  font-size: 0.75rem;
  display: flex;
  gap: 0.125rem;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: 0.125rem;
  margin-right: 0.25rem;

  .icon {
    width: 1rem;
  }
`;

const MaterialPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0.625rem; /* input padding - placeholder padding */
  margin-left: 1px; /* offsets the 1px input border */
  padding: 0 0.25rem;
  color: ${colors.grey40};
  font-size: 0.75rem;
  background-color: ${colors.white};
  border-radius: 0.3125rem;
  transform: translateY(-50%);
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  transition: opacity 100ms;
  pointer-events: none;
`;

const Container = styled.div`
  background-color: ${colors.white};
  border-radius: 0.3125rem;
  box-shadow: 0 0.25rem 1rem -0.125em ${rgba(colors.black, 0.15)};
  z-index: 3;
  user-select: none;
  outline: none;
`;

function SingleFilter({ placeholder, name, renderValue, defaultIsOpen = false, tabIndex = '0', onChange, ...props }) {
  const value = props.value;

  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const handleOpen = (flag) => {
    setIsOpen(flag);
  };
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    strategy: 'absolute',
    modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
  });

  const handleChange = (value) => {
    onChange({ name, value, target: { name, value } });
    setIsOpen(false);
  };

  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (referenceElement?.contains(event.target) || popperElement?.contains(event.target)) {
        return;
      }

      setIsOpen(false);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [referenceElement, popperElement]);

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'Escape':
      case 'Tab':
        setIsOpen(false);
        break;

      case 'Enter':
      case ' ':
        handleOpen(!isOpen);
        break;
    }
  };

  const hasValue = !!value;

  return (
    <Control
      ref={setReferenceElement}
      isOpen={isOpen}
      tabIndex={tabIndex}
      onClick={() => handleOpen(!isOpen)}
      onKeyDown={handleKeyDown}>
      <Box $hasValue={hasValue}>
        {hasValue ? (
          renderValue(value)
        ) : (
          <Text>
            <Placeholder>{placeholder}</Placeholder>
          </Text>
        )}

        {!hasValue && (
          <Status>
            <Icon icon="calendar-alt" color={colors.grey20} />
          </Status>
        )}
      </Box>

      <MaterialPlaceholder isVisible={!!value}>{placeholder}</MaterialPlaceholder>

      {isOpen && (
        <div
          ref={setPopperElement}
          style={{
            ...styles.popper,
            minWidth: referenceElement?.scrollWidth,
            maxWidth: '100%',
            zIndex: 110,
          }}
          onClick={(e) => e.stopPropagation()}
          {...attributes.popper}>
          <Container>
            <DayPicker {...props} onChange={handleChange} />
          </Container>
        </div>
      )}
    </Control>
  );
}

export default function DayPickerFilter({ placeholder, value, onChange, ...props }) {
  const dateTimeFormat = useDateTimeFormat();

  const handleChange = (newValue) => {
    onChange(newValue);
  };

  const text = useMemo(() => {
    if (!value) return null;
    return dateTimeFormat.format(value);
  }, [value, dateTimeFormat]);

  return (
    <SingleFilter
      {...props}
      placeholder={placeholder}
      value={value}
      renderValue={() => <Text>{text}</Text>}
      onChange={handleChange}
    />
  );
}
