import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { Currency, Percent } from '~/components';
import { useNumberFormat } from '~/hooks';
import { colors, weights } from '~/styles';

const Summary = styled.div`
  display: flex;
  align-items: center;
  background: ${colors.white};
  box-shadow: 0 3px 15px ${colors.grey10};
  border-radius: 5px;
  transition: opacity 250ms;
  opacity: ${({ fade }) => (fade ? 0.2 : 1)};
  width: 100%;
  margin-bottom: 1rem;
`;

const SummaryBox = styled.div`
  display: flex;
  flex: 1;
  padding: 1rem 1rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 1rem;
  font-weight: ${weights.bold};
  font-size: 1rem;

  &:not(:first-child) {
    border-left: 1px solid ${colors.grey10};
  }

  p {
    font-size: 0.75rem;
    font-weight: ${weights.normal};
    line-height: 1rem;
    white-space: nowrap;
  }
`;

function OpportunityBoxes({ data }) {
  let daysToClose = 0;
  if (data.closeDate) {
    const closeDate = moment(data.closeDate);
    const currentDate = moment();
    daysToClose = closeDate.diff(currentDate, 'days');
  }

  const numberFormat = useNumberFormat({ minimumFractionDigits: 0, maximumFractionDigits: 0 });

  return (
    <Summary>
      <SummaryBox>
        <Currency value={data.amount} currency={data.currency} minimumFractionDigits={0} maximumFractionDigits={0} />
        <p>Amount</p>
      </SummaryBox>
      <SummaryBox>
        <Percent value={data.probability / 100} />
        <p>Probability to Close</p>
      </SummaryBox>
      <SummaryBox>
        <Currency
          value={data.weightedAmount}
          currency={data.currency}
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
        <p>Weighted Amount</p>
      </SummaryBox>
      <SummaryBox>
        {numberFormat.format(daysToClose)}
        <p>Days to close</p>
      </SummaryBox>
    </Summary>
  );
}

export default OpportunityBoxes;
