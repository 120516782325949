import { Formik, setNestedObjectValues } from 'formik';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import * as Yup from 'yup';
import {
  BackLink,
  Button,
  Buttons,
  CancelButton,
  ClientLink,
  Confirmation,
  Currency,
  DateTime,
  DeleteButton,
  Field,
  FieldControl,
  Form,
  FormMessage,
  Grid,
  IconButton,
  InlineTooltip,
  MultilineText,
  Page,
  PromptNavigation,
  SplitButton,
  TooltipButton,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle, useForm } from '~/hooks';
import { colors, weights } from '~/styles';
import { emptyStringToNull, mergeValues } from '~/utils';
import ProjectTags from '../components/ProjectTags';
import PublishDialog from '../dialogs/PublishDialog';
import WebLinkModal from '../dialogs/WebLinkModal';
import HeaderDrawer from './HeaderDrawer';
import HistoryDrawer from './HistoryDrawer';
import LinesTable from './LinesTable';
import SettingsDrawer from './SettingsDrawer';
import {
  ActionsContainer,
  BilledTo,
  CreditNoteFor,
  Footer,
  LineItemsSection,
  Notes,
  NotesLabel,
  PreviewButton,
  SaveButton,
  Separator,
  SummaryDetails,
  SummaryDetailsRow,
  SummaryGrid,
  SummarySection,
  SummaryTotal,
  Tag,
  Tags,
  TotalLabel,
  TotalRow,
  TotalValue,
  Totals,
  TotalsSeparator,
} from './StyledComponents';
import calculateAmounts from './calculateAmounts';

function DraftForm({ creditNote, onChange, onDelete, onPreview, onReset, onSend }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ status, message, isSubmitting, saved }, form] = useForm();
  const [dialog, setDialog] = useState(null);

  useDocumentTitle(creditNote ? `Credit Note #${creditNote.number}` : undefined);

  const confirmation = useConfirmation();

  const handleEditSettings = () => {
    setDialog('settings');
  };

  const handleEditHeader = () => {
    setDialog('header');
  };

  const handleGetWebLink = () => {
    setDialog('webLink');
  };

  const handleViewHistory = () => {
    setDialog('history');
  };

  const handleCloseDialog = () => {
    setDialog(null);
  };

  const handleSubmit = async (values, { resetForm }, trigger = 'primary') => {
    try {
      form.submit(trigger);

      const body = emptyStringToNull({
        ..._.omit(values, 'taxRateRef', 'purchaseOrder'),
        taxRateId: values.taxRateRef?.id ?? null,
        taxRate: values.taxRateRef?.rate ?? values.taxRate ?? null,
        projects: values.projects.map((p) => ({ id: p.id })),
        purchaseOrderId: values.purchaseOrder?.id ?? null,
        lines: values.lines.map((line, index) => {
          return emptyStringToNull({
            ..._.pick(line, 'amount', 'description', 'notes', 'projectId', 'quantity', 'rate', 'taxable'),
            id: line.id.includes('line_') ? undefined : line.id,
            invoiceItemId: line.invoiceItem?.id ?? null,
            projectId: line.project?.id ?? null,
            lineNumber: index + 1,
          });
        }),
      });

      const { data } = await api.www.workspaces(workspace.id).creditNotes(creditNote.id).update(body);

      onChange(data);

      form.save(trigger);
      resetForm();

      return true;
    } catch (error) {
      form.error(error);
    }
  };

  const validateForm = async (formik) => {
    const errors = await formik.validateForm();
    if (!_.isEmpty(errors)) {
      formik.setTouched(setNestedObjectValues(errors, true));
    }
    return errors;
  };

  const handlePublish = async (formik) => {
    if (formik.dirty) {
      const errors = await validateForm(formik);
      if (!_.isEmpty(errors)) return;

      const success = await handleSubmit(formik.values, formik);
      if (!success) return;
    }

    return await confirmation.prompt((resolve) => (
      <PublishDialog
        creditNoteId={creditNote.id}
        purchaseOrderId={formik.values.purchaseOrder?.id}
        onClose={() => resolve()}
        onSaved={async () => {
          await onChange(creditNote.id);
          resolve(true);
        }}
      />
    ));
  };

  const handlePublishAndSend = async (formik) => {
    const isPublished = await handlePublish(formik);
    if (isPublished) {
      onSend();
    }
  };

  const handlePreview = async (formik) => {
    if (formik.dirty) {
      const errors = await validateForm(formik);
      if (!_.isEmpty(errors)) return;

      const success = await handleSubmit(formik.values, formik, 'preview');
      if (!success) return;
    }

    onPreview();
  };

  const mergeLines = useCallback((lines) => {
    return lines.map((line) =>
      mergeValues(
        {
          amount: '',
          description: '',
          id: null,
          invoiceItem: null,
          project: null,
          quantity: '',
          rate: '',
          taxable: false,
          transactionType: null,
          transactionAmount: null,
          timeEntries: [],
          expenses: [],
          projectExpenses: [],
          milestones: [],
          otherItems: [],
        },
        line,
      ),
    );
  }, []);

  const initialValues = useMemo(() => {
    if (!creditNote) return null;

    return mergeValues(
      {
        // Header
        issuedOn: null,
        notes: '',
        number: creditNote.transactionNumber,
        transactionNumber: creditNote.transactionNumber,
        setBaseTransactionNumber: false,
        poNumber: '',
        billTo: '',
        creditNoteFor: '',

        // General Settings
        currency: null,
        projects: [],
        purchaseOrder: null,

        lines: [],
        displayColumns: [],

        // Footer
        taxRate: '',
        taxRateRef: '',
      },
      {
        ...creditNote,
        lines: mergeLines(creditNote.lines),
        taxRate: creditNote.taxRateRef?.rate ?? creditNote.taxRate ?? '',
      },
    );
  }, [creditNote, mergeLines]);

  const schema = () =>
    Yup.lazy((values) => {
      const { total } = calculateAmounts(values);

      return Yup.object()
        .shape({
          notes: Yup.string().label('Note to Client').max(5000),
          taxRate: Yup.number().label('Tax Rate').min(0).max(100).nullable(),
          lines: Yup.array().of(
            Yup.object().shape({
              amount: Yup.number().label('Amount').min(-99999999999).max(99999999999).nullable(),
              description: Yup.string().label('Details').max(4000),
              invoiceItem: Yup.object()
                .label('Item')
                .nullable()
                .when('amount', (amount, schema) => (_.isNumber(amount) ? schema.required() : schema)),
              quantity: Yup.number().label('Quantity').min(-99999999999).max(99999999999).nullable(),
              rate: Yup.number().label('Rate').min(-99999999999).max(99999999999).nullable(),
            }),
          ),
        })
        .test('total', function () {
          if (total > 99999999999)
            return this.createError({
              path: 'total',
              message: 'Credit Note total must be lower than $99,999,999,999.',
            });

          if (total < -99999999999)
            return this.createError({
              path: 'total',
              message: 'Credit Note total must be greater than $-99,999,999,999.',
            });

          return true;
        });
    });

  return (
    <Page>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={handleSubmit}>
        {(formik) => {
          const handleResetForm = async () => {
            const confirm = await confirmation.prompt((resolve) => (
              <Confirmation resolve={resolve}>This will discard all changes. Are you sure?</Confirmation>
            ));
            if (!confirm) return;

            await onReset();
            formik.resetForm();
          };

          const handleSettingsChange = async (values) => {
            formik.setValues({
              ...formik.values,
              ...values,
              lines: formik.values.lines.filter(
                (line) => !line.project || values.projects.map((p) => p.id).includes(line.project.id),
              ),
            });
          };

          const handleTaxRateChange = (event) => {
            formik.setValues({
              ...formik.values,
              taxRateRef: event.target.value,
              taxRate: event.target.value?.rate ?? '',
            });
          };

          const { nonTaxableSubtotal, taxableSubtotal, tax, total } = calculateAmounts(formik.values);

          const currency = formik.values.currency;

          const publishCreditNote = !creditNote.permissions.manage
            ? {
                disabled: true,
                tooltip: 'Insufficient permissions to publish this credit note.',
              }
            : total < 0
              ? { disabled: true, tooltip: 'Credit Note total must be zero or greater.' }
              : !formik.isValid
                ? { disabled: true, tooltip: 'Please correct the form errors to perform this action.' }
                : { disabled: false, tooltip: undefined };

          const publishAndSendCreditNote = !creditNote.permissions.manage
            ? {
                disabled: true,
                tooltip: 'Insufficient permissions to publish this credit note.',
              }
            : total < 0
              ? { disabled: true, tooltip: 'Credit Note total must be zero or greater.' }
              : !formik.isValid
                ? { disabled: true, tooltip: 'Please correct the form errors to perform this action.' }
                : formik.dirty
                  ? { disabled: true, tooltip: 'Please save the credit note before sending.' }
                  : { disabled: false, tooltip: undefined };

          return (
            <>
              <Form>
                <Page.Header>
                  <BackLink defaultPath={`/app/${workspace.key}/credit-notes/list`} />
                  <Page.Info>
                    <Page.Title>Credit Note #{formik.values.number}</Page.Title>
                    <Tags>
                      <Tag>
                        <ClientLink client={creditNote.client} />
                      </Tag>

                      {!_.isEmpty(formik.values.projects) && (
                        <Tag>
                          <ProjectTags client={creditNote.client} projects={formik.values.projects} />
                        </Tag>
                      )}

                      <Tag>{currency}</Tag>

                      <Tag color={colors.danger}>{creditNote.status.name}</Tag>

                      {creditNote.sentAt && (
                        <Tag>
                          Sent on&nbsp;
                          <DateTime value={creditNote.sentAt} />
                        </Tag>
                      )}
                    </Tags>
                  </Page.Info>

                  <Page.Actions>
                    <IconButton icon="history" tootlip="Credit Note History" onClick={handleViewHistory} />
                    <IconButton icon="cog" tooltip="Credit Note Settings" onClick={handleEditSettings} />
                    <IconButton icon="pencil-alt" tooltip="Credit Note Header" onClick={handleEditHeader} />
                  </Page.Actions>
                </Page.Header>

                <Page.Section>
                  <SummarySection>
                    <SummaryGrid>
                      <SummaryDetailsRow equalHeight>
                        <Grid.Column>
                          <BilledTo>
                            <dt>Bill To</dt>
                            <dl>
                              <MultilineText value={formik.values.billTo} />
                            </dl>
                          </BilledTo>
                        </Grid.Column>
                        <Grid.Column>
                          <SummaryDetails>
                            <dt>PO #</dt>
                            <dd>
                              {formik.values.poNumber || (
                                <Button isAnchor onClick={handleEditHeader}>
                                  Add PO#
                                </Button>
                              )}
                            </dd>

                            <dt>Issued</dt>
                            <dd>
                              <DateTime value={formik.values.issuedOn} />
                            </dd>

                            <dt>Due</dt>
                            <dd>
                              {formik.values.paymentTermsId === 'due_on_receipt' ? (
                                'On Receipt'
                              ) : (
                                <DateTime value={formik.values.dueOn} />
                              )}
                            </dd>
                          </SummaryDetails>
                        </Grid.Column>

                        <Grid.Column>
                          <SummaryTotal>
                            <FieldControl error={formik.errors.total}>
                              <strong>
                                <Currency value={total} currency={currency} />
                              </strong>
                            </FieldControl>
                            <small>Total Credit</small>
                          </SummaryTotal>
                        </Grid.Column>
                      </SummaryDetailsRow>
                      <Grid.Row>
                        <Grid.Column>
                          <CreditNoteFor>
                            <dt>Credit Note For</dt>
                            <dd>
                              {formik.values.creditNoteFor || (
                                <Button isAnchor onClick={handleEditHeader}>
                                  Add credit note for
                                </Button>
                              )}
                            </dd>
                          </CreditNoteFor>
                        </Grid.Column>
                      </Grid.Row>
                    </SummaryGrid>
                  </SummarySection>
                </Page.Section>

                <LineItemsSection>
                  <LinesTable formik={formik} creditNote={creditNote} />
                  <Separator />
                </LineItemsSection>

                <Footer>
                  <Notes>
                    <NotesLabel>Note to Client</NotesLabel>
                    <Field.TextArea name="notes" maxLength={5000} />
                  </Notes>

                  <Totals>
                    <TotalRow>
                      <TotalLabel>Subtotal</TotalLabel>
                      <TotalValue>
                        <Currency
                          value={_.sumBy(formik.values.lines, (line) => line.amount || 0)}
                          currency={currency}
                        />
                      </TotalValue>
                    </TotalRow>

                    <TotalsSeparator />

                    <TotalRow>
                      <TotalLabel>Non-taxable Subtotal</TotalLabel>
                      <TotalValue>
                        <Currency value={nonTaxableSubtotal} currency={currency} />
                      </TotalValue>
                    </TotalRow>

                    <TotalRow>
                      <TotalLabel>Taxable Subtotal</TotalLabel>
                      <TotalValue>
                        <Currency value={taxableSubtotal} currency={currency} />
                      </TotalValue>
                    </TotalRow>

                    <TotalsSeparator />

                    <TotalRow
                      style={{ flexDirection: 'row-reverse', marginTop: '1rem', height: '2rem', marginBottom: '1rem' }}>
                      <Field.TaxRateSelect
                        style={{ width: '20rem', marginBottom: '0rem' }}
                        name="taxRateRef"
                        placeholder="Tax Rate"
                        onChange={handleTaxRateChange}
                      />
                    </TotalRow>

                    <TotalRow
                      style={{
                        display: 'flex',
                        width: '100%',
                        flexWrap: 'wrap',
                        fontSize: '0.875rem',
                      }}>
                      <TotalLabel>Tax Rate</TotalLabel>
                      <TotalValue>
                        <Field.Number
                          prefix="%"
                          name="taxRate"
                          min={0}
                          max={100}
                          precision={7}
                          disabled={formik.values.taxRateRef}
                        />
                      </TotalValue>
                    </TotalRow>

                    <TotalRow>
                      <TotalLabel>Tax</TotalLabel>
                      <TotalValue>
                        <Currency value={tax} currency={currency} />
                      </TotalValue>
                    </TotalRow>

                    <TotalsSeparator />

                    <TotalRow style={{ fontWeight: weights.bold }}>
                      <TotalLabel>Total Credit</TotalLabel>
                      <TotalValue>
                        <Currency value={total} currency={currency} />
                      </TotalValue>
                    </TotalRow>
                  </Totals>
                </Footer>

                <Form.Actions>
                  <TooltipButton
                    component={DeleteButton}
                    disabled={!creditNote.permissions.manage}
                    tooltip={
                      !creditNote.permissions.manage
                        ? 'Insufficient permissions to delete this credit note.'
                        : undefined
                    }
                    onClick={onDelete}>
                    Delete
                  </TooltipButton>

                  <ActionsContainer>
                    {status && <FormMessage.Error style={{ marginRight: '1.5rem' }}>{message}</FormMessage.Error>}

                    <Buttons>
                      <CancelButton disabled={!formik.dirty} onClick={handleResetForm}>
                        Cancel
                      </CancelButton>

                      <PreviewButton
                        disabled={!formik.isValid}
                        isLoading={isSubmitting === 'preview'}
                        onClick={() => handlePreview(formik)}
                        ok={saved === 'preview'}>
                        {formik.dirty ? `Save & Preview` : `Preview`}
                        {!formik.isValid && (
                          <InlineTooltip message="Please correct the form errors to perform this action." />
                        )}
                      </PreviewButton>

                      <SplitButton>
                        {formik.dirty ? (
                          <SaveButton
                            isLoading={isSubmitting === 'primary'}
                            ok={saved === 'primary'}
                            disabled={!creditNote.permissions.manage}
                            onClick={formik.submitForm}>
                            Save
                            {!creditNote.permissions.manage && (
                              <InlineTooltip message="Insufficient permissions to save this credit note." />
                            )}
                          </SaveButton>
                        ) : (
                          <SaveButton
                            isLoading={isSubmitting === 'primary'}
                            ok={saved === 'primary'}
                            disabled={!formik.isValid || !creditNote.permissions.manage || total < 0}
                            onClick={() => handlePublish(formik)}>
                            Publish
                            {!creditNote.permissions.manage ? (
                              <InlineTooltip message="Insufficient permissions to publish this credit note." />
                            ) : total < 0 ? (
                              <InlineTooltip message="Credit Note total must be zero or greater." />
                            ) : !formik.isValid ? (
                              <InlineTooltip message="Please correct the form errors to perform this action." />
                            ) : undefined}
                          </SaveButton>
                        )}

                        <SplitButton.Menu position="top">
                          {({ setIsOpen }) => {
                            const handleAction = async (action) => {
                              setIsOpen(false);
                              await action();
                            };

                            return (
                              <>
                                <SplitButton.Item
                                  disabled={!creditNote.permissions.manage}
                                  tooltip={
                                    !creditNote.permissions.manage
                                      ? 'Insufficient permissions to save this credit note.'
                                      : undefined
                                  }
                                  onClick={() => handleAction(() => formik.submitForm())}>
                                  Save
                                </SplitButton.Item>

                                <SplitButton.Item
                                  disabled={publishCreditNote.disabled}
                                  tooltip={publishCreditNote.tooltip}
                                  onClick={() => handleAction(() => handlePublish(formik))}>
                                  {formik.dirty ? `Save & Publish` : `Publish`}
                                </SplitButton.Item>

                                <SplitButton.Item
                                  disabled={publishAndSendCreditNote.disabled}
                                  tooltip={publishAndSendCreditNote.tooltip}
                                  onClick={() => handleAction(() => handlePublishAndSend(formik))}>
                                  Publish & Send
                                </SplitButton.Item>

                                <SplitButton.Item onClick={handleGetWebLink}>Get Web Link</SplitButton.Item>
                              </>
                            );
                          }}
                        </SplitButton.Menu>
                      </SplitButton>
                    </Buttons>
                  </ActionsContainer>
                </Form.Actions>

                {
                  {
                    settings: (
                      <SettingsDrawer
                        creditNote={creditNote}
                        values={formik.values}
                        onApply={handleSettingsChange}
                        onClose={handleCloseDialog}
                      />
                    ),

                    header: (
                      <HeaderDrawer
                        creditNote={creditNote}
                        values={formik.values}
                        project={creditNote.project}
                        onApply={(v) => formik.setValues({ ...formik.values, ...v })}
                        onClose={handleCloseDialog}
                      />
                    ),

                    webLink: <WebLinkModal creditNote={creditNote} onClose={handleCloseDialog} />,

                    history: <HistoryDrawer creditNoteId={creditNote.id} onClose={handleCloseDialog} />,
                  }[dialog?.type ?? dialog]
                }

                <PromptNavigation when={formik.dirty} />
              </Form>
            </>
          );
        }}
      </Formik>
    </Page>
  );
}

export default DraftForm;
