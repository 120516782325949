import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ClientLink, Hours, RouteLink, Widget } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import Timestamp from './Timestamp.jsx';
import colors from '~/styles/colors.js';

const List = styled.ul`
  li {
    display: flex;
    justify-content: space-between;
    padding: 0.25rem 0;
  }
`;

export default function TopClientsByBillableHours({ start, end }) {
  const { workspace } = useWorkspace();

  const api = useApi();
  const [{ data, isReady, timestamp }, setQuery] = useState({ data: null, isReady: false });

  const fetchData = useCallback(
    async (behavior) => {
      try {
        if (workspace.sampleData) behavior = 'bypass';
        const { data, headers } = await api.www
          .workspaces(workspace.id)
          .executiveDashboard()
          .topClientsByBillableHours({ start, end }, behavior);
        setQuery({ data, isReady: true, timestamp: headers['x-cache-timestamp'] });
      } catch (error) {
        setQuery({ data: null, isReady: true });
      }
    },
    [workspace.id, workspace.sampleData, api, start, end],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Widget loading={!isReady}>
      <Widget.Header>
        <Widget.Title>Top Clients by Billable Hours</Widget.Title>
        <Timestamp timestamp={timestamp} onClick={() => fetchData('invalidate')} />
      </Widget.Header>
      <Widget.Preview style={{ minHeight: '20rem' }} />
      <Widget.Content>
        {data &&
          (data.length > 0 ? (
            <List>
              {data.map((item) => (
                <li key={item.id}>
                  <h3>
                    <ClientLink client={item} />
                  </h3>
                  <p>
                    <RouteLink
                      to={`/app/${workspace.key}/reports/time-summary-by-project?${new URLSearchParams([
                        ['client', item.id],
                        ['start', start],
                        ['end', end],
                        ['projectBillingType', 'tm'],
                        ['projectBillingType', 'fixed'],
                        ['projectBillingType', 'fixed_recurring'],
                      ])}`}>
                      <Hours value={item.billableHours} />
                    </RouteLink>
                  </p>
                </li>
              ))}
            </List>
          ) : (
            <p style={{ color: colors.grey25 }}>No data available.</p>
          ))}
      </Widget.Content>
    </Widget>
  );
}
