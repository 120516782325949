import Big from 'big.js';

const calculateExpenseMarkup = ({ enableMarkup, markupMethod, markupRatio, markupAmount, amount }) => {
  if (!markupMethod || !enableMarkup) return null;

  let markup = 0;
  switch (markupMethod) {
    case 'amount':
      markup = markupAmount ? markupAmount : 0;
      break;
    case 'percentage':
      markup = amount && markupRatio ? Big(amount).div(100).mul(markupRatio).round(2).toNumber() : 0;
  }

  return markup;
};

export default calculateExpenseMarkup;
