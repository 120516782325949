import { useAuth } from '~/hooks';
import OpportunitiesByMonth from './OpportunitiesByMonth';
import OpportunityDetail from './OpportunityDetail';

export default function usePipelineReports() {
  const auth = useAuth();

  return [
    auth.reports.pipeline && {
      key: 'opportunity_detail',
      name: 'Opportunity Detail',
      description:
        'Individual opportunities in the sales pipeline including the opportunity name, stage, amount, probability, and expected close date.',
      path: '/opportunity-detail',
      component: OpportunityDetail,
    },
    auth.reports.pipeline && {
      key: 'opportunities_by_month',
      name: 'Opportunities by Month',
      description:
        'A summary of the opportunities created per month and expected to close per month, including both gross and probability-weighted opportunity values.',
      path: '/opportunities-by-month',
      component: OpportunitiesByMonth,
    },
  ].filter(Boolean);
}
