import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import _ from 'lodash';
import styled from 'styled-components';
import {
  ClientFilter,
  CreateButton,
  Currency,
  DateTime,
  ExportDialog,
  ExportDropdown,
  FiltersBar,
  ListView,
  ListViewActions,
  ListViewMenu,
  Page,
  PeriodFilter,
  RouteLink,
  SearchInput,
  Tag,
  Tooltip,
} from '~/components';
import CreditNoteStatusFilter from '~/components/filters/CreditNoteStatusFilter';
import { useApi, useConfirmation, useIntegrations, useWorkspace } from '~/contexts';
import {
  useAuth,
  useClientFilters,
  useDocumentTitle,
  useIsMounted,
  useProjectFilters,
  useSearchParams,
  useSearchParamsConfig,
} from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort, intervalOptions, intervalsByScope, mimeTypes } from '~/utils';
import QBOIndicator from '../components/QBOIndicator';
import SentIndicator from '../components/SentIndicator';
import XeroIndicator from '../components/XeroIndicator';
import CreateDialog from '../dialogs/CreateDialog';
import DeleteDialog from '../dialogs/DeleteDialog';
import LoadFromQuickBooksDialog from '../dialogs/LoadFromQuickBooksDialog';
import LoadFromXeroDialog from '../dialogs/LoadFromXeroDialog';
import PublishDialog from '../dialogs/PublishDialog';
import UnpublishDialog from '../dialogs/UnpublishDialog';
import WebLinkModal from '../dialogs/WebLinkModal';
import SendCreditNoteDrawer from '../send-drawer/SendCreditNoteDrawer';
import ClientFiltersBar from '~/components/filters/ClientFiltersBar.jsx';
import ClientFiltersGroup from '~/components/filters/ClientFiltersGroup.jsx';
import CreditNoteSentFilter from '~/components/filters/CreditNoteSentFilter.jsx';
import FilterButton from '~/components/filters/FilterButton.jsx';
import FiltersDrawer from '~/components/filters/FiltersDrawer.jsx';
import FiltersGroup from '~/components/filters/FiltersGroup.jsx';
import ProjectFiltersBar from '~/components/filters/ProjectFiltersBar.jsx';
import ProjectFiltersGroup from '~/components/filters/ProjectFiltersGroup.jsx';

const Small = styled.small`
  display: block;
`;

function CreditNotesListPage({ onRowClick, sessionKey }) {
  useDocumentTitle('Credit Notes');

  const { workspace } = useWorkspace();
  const api = useApi();

  const isMounted = useIsMounted();

  const history = useHistory();
  const { url } = useRouteMatch();

  const [dialog, setDialog] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();

  const [query, setQuery] = useState({
    isReady: false,
    searchParamsStatus: 'pending',
    data: null,
    params: {
      q: '',
      creditNoteSent: null,
      creditNoteStatuses: [],
      sort: new QuerySort('issuedOn', 'desc'),
      page: 0,
      period: null,
      size: 50,
      ...clientFilters.filters,
      ...projectFilters.filters,
    },
    action: 'load',
  });

  const setParams = (params) => {
    setQuery((state) => ({
      ...state,
      action: 'filter',
      params: { ...state.params, ...params, page: 0 },
      searchParamsStatus: 'ready',
    }));
  };

  const loadMore = useCallback(() => {
    setQuery((state) => {
      if (
        state.searchParamsStatus !== 'ready' ||
        state.action !== null ||
        !state.data ||
        state.data.total <= state.data.results.length
      ) {
        return state;
      }

      return {
        ...state,
        params: { ...state.params, page: state.params.page + 1 },
        action: 'load-more',
      };
    });
  }, []);

  const removeItem = (id) => {
    setQuery((state) => ({
      ...state,
      dialog: null,
      data: {
        ...state.data,
        results: state.data?.results.filter((i) => i.id !== id),
        total: state.data.total - 1,
      },
    }));
  };

  const updateItems = (items) => {
    setQuery((state) => ({
      ...state,
      data: {
        ...state.data,
        results: state.data?.results.map((result) => {
          const item = items.find((i) => i.id === result.id);
          return item ? { ...result, ...item } : result;
        }),
      },
    }));
  };

  const { action, data, isReady, params, searchParamsStatus } = query;

  const auth = useAuth();
  const confirmation = useConfirmation();

  const searchParamsConfig = useSearchParamsConfig();
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        q: { default: '' },
        creditNoteSent: {
          valid: ['all', 'yes', 'no'],
          serialize: function (value) {
            return this.default && !value ? 'all' : value;
          },
          deserialize: (value) => (value === 'all' ? null : value),
        },
        creditNoteStatuses: searchParamsConfig.creditNoteStatuses,
        period: {
          ...searchParamsConfig.period,
          default: intervalOptions.all_dates,
        },
        clients: searchParamsConfig.clients,
        sort: { default: new QuerySort('issuedOn', 'desc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
      }),

      [searchParamsConfig, clientFilters, projectFilters],
    ),
    sessionKey,
    onChange: useCallback((params) => setParams((state) => ({ ...state, ...params })), []),
  });

  const urlSearchParams = useMemo(() => {
    const { start, end } = params.period || {};

    return {
      q: params.q,
      sort: params.sort,
      page: params.page,
      size: params.size,
      start: start ?? undefined,
      end: end ?? undefined,
      creditNoteSent: params.creditNoteSent ?? undefined,
      creditNoteStatusId: params.creditNoteStatuses?.map((v) => v.id),
      clientId: params.clients?.map((v) => v.id),
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
    };
  }, [params, clientFilters, projectFilters]);

  useEffect(() => {
    if (searchParamsStatus !== 'pending') return;
    searchParams.get().then((params) => {
      if (params) setParams(params);
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).creditNotes().get(urlSearchParams);

      if (!isMounted.current) return;

      setQuery((state) => ({
        ...state,
        action: null,
        dialog: null,
        searchParamsStatus: 'ready',
        data: {
          ...data,
          results: state.action === 'load-more' ? [...state.data.results, ...data.results] : data.results,
          total: data.total,
        },
      }));
    } catch (error) {
      setQuery((state) => ({ ...state, data: { total: 0, results: [] } }));
    }
  }, [workspace.id, api, isMounted, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const integrations = useIntegrations();

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);

  if (!isReady && !data) return <PageLoader />;

  const handleFilter = (value) => {
    setParams({ ...value });
    searchParams.set({ ...value });
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleRowClick = (creditNote) => {
    if (onRowClick) onRowClick(creditNote);
    else history.push(`${url}/${creditNote.id}`);
  };

  const refreshSummary = () => {
    setRefreshKey(refreshKey + 1);
  };

  const reload = async (...ids) => {
    const creditNotes = await api.www
      .workspaces(workspace.id)
      .creditNotes()
      .get({ ids })
      .then((res) => res.data);

    updateItems(creditNotes);

    refreshSummary();

    return creditNotes;
  };

  const handleDelete = async (creditNote) => {
    await confirmation.prompt((resolve) => (
      <DeleteDialog
        creditNote={creditNote}
        onClose={resolve}
        onDelete={() => {
          removeItem(creditNote.id);
          refreshSummary();
          resolve();
        }}
      />
    ));
  };

  const handlePublish = async (creditNote) => {
    return await confirmation.prompt((resolve) => (
      <PublishDialog
        creditNoteId={creditNote.id}
        onClose={() => resolve()}
        onSaved={async () => {
          await reload(creditNote.id);
          resolve(true);
        }}
      />
    ));
  };

  const handleUnpublish = async (creditNote) => {
    await confirmation.prompt((resolve) => (
      <UnpublishDialog
        creditNoteId={creditNote.id}
        onClose={() => resolve()}
        onSaved={async () => {
          await reload(creditNote.id);
          resolve();
        }}
      />
    ));
  };

  const handleCloseDialog = () => {
    setDialog(null);
  };

  const handleGetWebLink = (creditNote) => {
    setDialog({ type: 'webLink', creditNote });
  };

  const handleSend = async (creditNote) => {
    const { data } = await api.www.workspaces(workspace.id).creditNotes(creditNote.id).get();
    setDialog({ type: 'send', creditNote: data });
  };

  const handlePublishAndSend = async (creditNote) => {
    const isPublished = await handlePublish(creditNote);
    if (isPublished) {
      await handleSend(creditNote);
    }
  };

  const handleSent = async (creditNote) => {
    await reload(creditNote.id);
  };

  const handleCreateCreditNote = () => {
    setDialog({ type: 'create' });
  };

  const handleReloadFromQuickBooks = async (creditNote) => {
    await confirmation.prompt((resolve) => (
      <LoadFromQuickBooksDialog
        creditNote={creditNote}
        resolve={async () => {
          await reload(creditNote.id);
          resolve();
        }}
      />
    ));
  };

  const handleReloadFromXero = async (creditNote) => {
    await confirmation.prompt((resolve) => (
      <LoadFromXeroDialog
        creditNote={creditNote}
        resolve={async () => {
          await reload(creditNote.id);
          resolve();
        }}
      />
    ));
  };

  const handleExport = async (filename, mimeType) => {
    await confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .creditNotes()
          .export(
            {
              ...urlSearchParams,
              size: null,
            },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  return (
    <Page scrollable>
      <Page.Header>
        <Page.Info>
          <Page.Eyebrow>Billing</Page.Eyebrow>
          <Page.Title>Credit Notes</Page.Title>
        </Page.Info>

        <Page.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(`credit-notes.csv`, mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(`credit-notes.xlsx`, mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />

          <CreateButton
            tooltip={
              auth.invoices.manage.client
                ? 'Create Credit Note'
                : 'Your security role prohibits you from creating credit notes.'
            }
            disabled={!auth.invoices.manage.client}
            onClick={handleCreateCreditNote}
          />
        </Page.Actions>
      </Page.Header>

      <>
        <Page.Filters>
          <FiltersBar>
            <SearchInput
              value={params.q}
              placeholder="Search"
              onChange={({ target: { value } }) => handleFilter({ q: value })}
            />

            <ClientFilter
              name="clients"
              activeOnly={false}
              value={params.clients}
              onChange={({ target: { value } }) => handleFilter({ clients: value })}
            />

            <CreditNoteStatusFilter
              value={params.creditNoteStatuses}
              onChange={({ target: { value } }) => handleFilter({ creditNoteStatuses: value })}
            />

            <PeriodFilter
              name="period"
              placeholder="Issue Date"
              intervals={[intervalOptions.all_dates, ...intervalsByScope.month]}
              value={params.period}
              onChange={({ target: { value } }) => handleFilter({ period: value })}
            />

            <ClientFiltersBar filters={{ ...params, clients: null }} onChange={handleFilter} />

            <ProjectFiltersBar filters={params} onChange={handleFilter} />

            {!_.isEmpty(params.creditNoteSent) && (
              <CreditNoteSentFilter
                value={params.creditNoteSent}
                onChange={({ target: { value } }) => handleFilter({ creditNoteSent: value })}
              />
            )}
          </FiltersBar>
        </Page.Filters>
        <Filters values={params} isOpen={filtersVisible} onApply={handleFilter} onClose={hideFilters} />
      </>

      <Page.ListView>
        <ListView>
          <ListView.Header>
            <ListView.Column sticky width="8rem" name="transactionNumber" onSort={handleSort} sort={params.sort}>
              #
            </ListView.Column>
            <ListView.Column minWidth="16rem" name="client.name" onSort={handleSort} sort={params.sort}>
              Client
            </ListView.Column>
            <ListView.Column minWidth="16rem">Projects</ListView.Column>
            <ListView.Column width="10rem" name="issuedOn" onSort={handleSort} sort={params.sort}>
              Issued
            </ListView.Column>
            <ListView.Column width="6rem" name="statusId" onSort={handleSort} sort={params.sort}>
              Status
            </ListView.Column>
            <ListView.Column width="10rem" align="right" name="convertedBalance" onSort={handleSort} sort={params.sort}>
              Remaining
            </ListView.Column>
            <ListView.Column width="10rem" align="right" name="convertedTotal" onSort={handleSort} sort={params.sort}>
              Total
            </ListView.Column>
            <ListViewActions.Column />
          </ListView.Header>
          <ListView.Body fade={action === 'filter'}>
            {data.results.map((creditNote) => {
              const { id, number, issuedOn, status, statusId, sentAt, qboCreditMemoId, xeroCreditNoteId } = creditNote;

              const publish = !creditNote.permissions.manage
                ? {
                    disabled: true,
                    tooltip: 'Insufficient permissions to publish this credit note.',
                  }
                : creditNote.total < 0
                  ? { disabled: true, tooltip: 'Credit Note total must be zero or greater.' }
                  : qboCreditMemoId
                    ? { disabled: true, tooltip: 'This credit note has been saved to QuickBooks Online.' }
                    : xeroCreditNoteId
                      ? { disabled: true, tooltip: 'This credit note has been saved to Xero.' }
                      : { disabled: false, tooltip: undefined };

              const unpublish = !creditNote.permissions.manage
                ? {
                    disabled: true,
                    tooltip: 'Insufficient permissions to unpublish this credit note.',
                  }
                : qboCreditMemoId
                  ? { disabled: true, tooltip: 'This credit note has been saved to QuickBooks Online.' }
                  : xeroCreditNoteId
                    ? { disabled: true, tooltip: 'This credit note has been saved to Xero.' }
                    : { disabled: false, tooltip: undefined };

              return (
                <ListView.Row key={id} data-testid={id} onClick={() => handleRowClick(creditNote)}>
                  <ListView.Cell>
                    <RouteLink
                      to={`/app/${workspace.key}/billing/credit-notes/${id}`}
                      style={{ wordBreak: 'break-word' }}>
                      {number}
                    </RouteLink>
                  </ListView.Cell>
                  <ListView.Cell>{creditNote.client.name}</ListView.Cell>
                  <ListView.Cell>
                    <FirstProject creditNote={creditNote} />
                    <Projects creditNote={creditNote} />
                  </ListView.Cell>
                  <ListView.Cell>
                    <DateTime value={issuedOn} />
                    {sentAt && <SentIndicator sentAt={sentAt} />}
                  </ListView.Cell>
                  <ListView.Cell>
                    {status.name}

                    {qboCreditMemoId && (
                      <span>
                        <QBOIndicator />
                      </span>
                    )}

                    {xeroCreditNoteId && (
                      <span>
                        <XeroIndicator xeroCreditNoteId={xeroCreditNoteId} />
                      </span>
                    )}
                  </ListView.Cell>
                  <ListView.Cell>
                    <p>
                      <Currency value={creditNote.convertedBalance} currency={workspace.currency} />
                      {creditNote.currency !== workspace.currency && (
                        <Small>
                          <Currency value={creditNote.balance} currency={creditNote.currency} />
                        </Small>
                      )}
                    </p>
                  </ListView.Cell>
                  <ListView.Cell>
                    <p>
                      <Currency value={creditNote.convertedTotal} currency={workspace.currency} />
                      {creditNote.currency !== workspace.currency && (
                        <Small>
                          <Currency value={creditNote.total} currency={creditNote.currency} />
                        </Small>
                      )}
                    </p>
                  </ListView.Cell>

                  <ListViewActions>
                    {status.id === 'draft' ? (
                      <ListViewActions.Edit
                        onClick={() => history.push(`/app/${workspace.key}/billing/credit-notes/${creditNote.id}`)}
                      />
                    ) : (
                      <ListViewActions.View
                        onClick={() => history.push(`/app/${workspace.key}/billing/credit-notes/${creditNote.id}`)}
                      />
                    )}

                    <hr />

                    <ListViewMenu>
                      {({ setIsOpen }) => {
                        const handleAction = async (action) => {
                          setIsOpen(false);
                          await action();
                        };

                        return {
                          draft: (
                            <>
                              <ListViewMenu.Item
                                disabled={publish.disabled}
                                tooltip={publish.tooltip}
                                onClick={() => handleAction(() => handlePublish(creditNote))}>
                                Publish
                              </ListViewMenu.Item>

                              <ListViewMenu.Item
                                disabled={publish.disabled}
                                tooltip={publish.tooltip}
                                onClick={() => handleAction(() => handlePublishAndSend(creditNote))}>
                                Publish & Send
                              </ListViewMenu.Item>

                              <ListViewMenu.Link to={`/app/${workspace.key}/billing/credit-notes/${creditNote.id}`}>
                                Edit
                              </ListViewMenu.Link>

                              <ListViewMenu.Link
                                to={`/${workspace.key}/credit-notes/${creditNote.id}?preview=true`}
                                target="_blank">
                                Preview
                              </ListViewMenu.Link>

                              <ListViewMenu.Item onClick={() => handleAction(() => handleGetWebLink(creditNote))}>
                                Get Web Link
                              </ListViewMenu.Item>

                              <ListViewMenu.Item
                                disabled={!creditNote.permissions.manage}
                                tooltip={
                                  !creditNote.permissions.manage
                                    ? 'Insufficient permissions to delete this credit note.'
                                    : undefined
                                }
                                onClick={() => handleAction(() => handleDelete(creditNote))}>
                                Delete
                              </ListViewMenu.Item>
                            </>
                          ),

                          open: (
                            <>
                              <ListViewMenu.Item
                                disabled={!creditNote.permissions.manage}
                                tooltip={
                                  !creditNote.permissions.manage
                                    ? 'Insufficient permissions to send this credit note.'
                                    : undefined
                                }
                                onClick={() => handleAction(() => handleSend(creditNote))}>
                                Send
                              </ListViewMenu.Item>

                              <ListViewMenu.Item
                                disabled={unpublish.disabled}
                                tooltip={unpublish.tooltip}
                                onClick={() => handleAction(() => handleUnpublish(creditNote))}>
                                Unpublish
                              </ListViewMenu.Item>

                              <ListViewMenu.Link to={`/app/${workspace.key}/billing/credit-notes/${creditNote.id}`}>
                                View
                              </ListViewMenu.Link>

                              <ListViewMenu.Link
                                to={`/${workspace.key}/credit-notes/${creditNote.id}?preview=true`}
                                target="_blank">
                                Preview
                              </ListViewMenu.Link>

                              <ListViewMenu.Item onClick={() => handleAction(() => handleGetWebLink(creditNote))}>
                                Get Web Link
                              </ListViewMenu.Item>

                              {integrations.qbo && creditNote.qboCreditMemoId && (
                                <ListViewMenu.Item
                                  disabled={!creditNote.permissions.manage}
                                  tooltip={
                                    !creditNote.permissions.manage
                                      ? 'Insufficient permissions to reload this credit note from QuickBooks Online.'
                                      : undefined
                                  }
                                  onClick={() => handleAction(() => handleReloadFromQuickBooks(creditNote))}>
                                  Reload from QuickBooks
                                </ListViewMenu.Item>
                              )}

                              {!!integrations.xero && creditNote.xeroCreditNoteId && (
                                <ListViewMenu.Item
                                  disabled={!creditNote.permissions.manage}
                                  tooltip={
                                    !creditNote.permissions.manage
                                      ? 'Insufficient permissions to reload this credit note from Xero.'
                                      : undefined
                                  }
                                  onClick={() => handleAction(() => handleReloadFromXero(creditNote))}>
                                  Reload from Xero
                                </ListViewMenu.Item>
                              )}

                              <ListViewMenu.Item
                                disabled={!creditNote.permissions.manage}
                                tooltip={
                                  !creditNote.permissions.manage
                                    ? 'Insufficient permissions to delete this credit note.'
                                    : undefined
                                }
                                onClick={() => handleAction(() => handleDelete(creditNote))}>
                                Delete
                              </ListViewMenu.Item>
                            </>
                          ),
                        }[statusId];
                      }}
                    </ListViewMenu>
                  </ListViewActions>
                </ListView.Row>
              );
            })}

            {data.results.length === 0 && <ListView.Empty />}

            {data.total > data.results.length && (
              <ListView.Loader key={data.results.length} onIntersecting={loadMore} />
            )}
          </ListView.Body>

          <ListView.Status total={data.total} label="Credit Note" isLoading={!!action} />
        </ListView>
      </Page.ListView>

      {dialog &&
        {
          webLink: () => <WebLinkModal creditNote={dialog.creditNote} onClose={handleCloseDialog} />,
          send: () => (
            <SendCreditNoteDrawer creditNote={dialog.creditNote} onSent={handleSent} onClose={handleCloseDialog} />
          ),
          create: () => <CreateDialog onClose={handleCloseDialog} />,
        }[dialog.type]()}
    </Page>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <FiltersGroup
        label="Credit Note Filters"
        filters={[filters.period, filters.creditNoteStatuses, filters.creditNoteSent]}>
        <PeriodFilter
          name="period"
          placeholder="Issue Date"
          intervals={[intervalOptions.all_dates, ...intervalsByScope.month]}
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />

        <CreditNoteStatusFilter
          value={filters.creditNoteStatuses}
          onChange={({ target: { value } }) => handleFilter({ creditNoteStatuses: value })}
        />

        <CreditNoteSentFilter
          value={filters.creditNoteSent}
          onChange={({ target: { value } }) => handleFilter({ creditNoteSent: value })}
        />
      </FiltersGroup>
    </FiltersDrawer>
  );
}

const FirstProject = ({ creditNote }) => {
  const project = creditNote.projects[0];
  if (!project) return null;

  return project.name;
};

const Title = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

const Projects = ({ creditNote }) => {
  let projectsCount = creditNote.projects.length - 1; // Remove the first project because it already shows a tag
  if (projectsCount <= 0) return null;

  return (
    <Tooltip
      message={
        <div style={{ fontSize: '1rem' }}>
          <Title>Projects</Title>

          {creditNote.projects.map((projects) => (
            <Tag style={{ backgroundColor: colors.grey5 }} key={projects.id}>
              <small>{projects.name}</small>
            </Tag>
          ))}
        </div>
      }>
      <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40 }}>
        <small>+{projectsCount}</small>
      </Tag>
    </Tooltip>
  );
};

export default CreditNotesListPage;
