import { TooltipButton } from '~/components';
import * as TableBoxRowActions from '~/components/table/TableBoxRowActions';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';

export const ListHeader = styled.div`
  display: flex;
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  background-color: ${colors.grey5};
  border-bottom: 5px;
  border-radius: 5px;
  position: sticky;
  z-index: 3;
`;

export const ListGroup = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.grey10};
  border-radius: 5px;
  margin-top: 0.375rem;
  font-size: 0.875rem;

  &:last-child {
    border-bottom-width: 1px;
    margin-bottom: 0;
  }
`;

export const ListGroupHeader = styled.div`
  display: flex;
  position: sticky;
  border-radius: 5px 5px 0 0;
  background-color: white;
  margin-top: 1px;
  margin-bottom: 1px;
  background-clip: content-box;
  z-index: 2;
  transition: all 250ms ease-in-out;

  &:hover {
    cursor: pointer;
    border-color: transparent;
    box-shadow: 0 3px 15px ${colors.grey10};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.grey10};
  }
`;

export const ListGroupContent = styled.div``;

export const Row = styled.div`
  flex: 1;
  border-bottom: 1px solid ${colors.grey10};
  display: flex;
  align-items: center;
  transition: all 250ms ease-in-out;
  padding: ${({ padding = '0 0 0 3rem' }) => padding};

  &:last-child {
    border-width: 0;
  }

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;

      &:hover {
        border-color: transparent;
        box-shadow: 0 3px 15px ${colors.grey10};
      }
    `}
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  flex: ${({ flex = '1' }) => flex};
  padding: ${({ padding = '0.5rem 1rem' }) => padding};

  ${({ $width }) =>
    $width &&
    css`
      flex: 0 ${$width};
    `}

  ${({ right }) =>
    right &&
    css`
      justify-content: flex-end;
      text-align: right;
    `}

    ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`;

export const ToggleSwitch = styled.div`
  flex: 1;
  display: flex;
  min-width: 2rem;
  justify-content: center;
`;

export const ApprovalActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
`;

export const ApprovalAction = styled.div`
  &:last-child {
    margin-left: 1rem;
  }
`;

export const Action = styled(TooltipButton)`
  font-size: 0.75rem;
  background: ${colors.grey5};
  color: ${colors.grey25};
  width: 1.75rem;
  height: 1.75rem;
  padding: 0.5rem;
  border-radius: 100%;
  transition: all 100ms ease-in-out;
`;

export const Approve = styled(Action)`
  &:hover {
    color: ${colors.primary};
    background: ${colors.primary10};

    &:disabled {
      background: ${colors.grey5};
      color: ${colors.grey25};
    }
  }

  ${({ status }) =>
    ({
      approved: css`
        &,
        &:hover,
        &:hover:disabled {
          color: ${colors.white};
          background: ${colors.primary};
        }
      `,

      partially_approved: css`
        &,
        &:hover,
        &:hover:disabled {
          color: ${colors.white};
          background: ${colors.primary50};
        }
      `,
    })[status]}
`;

export const Reject = styled(Action)`
  &:hover {
    background: ${colors.danger10};
    color: ${colors.danger};
    &:disabled {
      background: ${colors.grey5};
      color: ${colors.grey25};
    }
  }

  ${({ status }) =>
    ({
      rejected: css`
        &,
        &:hover,
        &:hover:disabled {
          color: ${colors.white};
          background: ${colors.danger};
        }
      `,

      partially_rejected: css`
        &,
        &:hover,
        &:hover:disabled {
          color: ${colors.white};
          background: ${colors.danger50};
        }
      `,
    })[status]}
`;

export const Details = styled.div`
  flex: 1;
  p:not(:last-child) {
    margin-bottom: 0.125rem;
  }
`;

export const Notes = styled.p`
  font-size: 0.75rem;
  color: ${colors.grey25};

  em {
    font-style: normal;
    color: ${colors.black};
  }
`;

export const RejectionReason = styled.p`
  padding: 0.75rem;
  margin-top: 0.5rem;
  color: ${colors.danger};
  font-size: 0.75rem;
  background-color: ${colors.danger5};
  border-radius: 0.3125rem;
  flex: 1;
  width: 100%;

  span {
    font-style: italic;
  }

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }
`;

export const Total = styled.div`
  text-align: right;
  font-size: 0.875rem;
  font-weight: ${weights.light};
  margin-bottom: 0.5rem;
`;

export const PublicList = styled.div`
  flex: 1;

  ${ListHeader} {
    top: 0;
  }

  ${ListGroupHeader} {
    top: 2.75rem;
  }

  input[type='checkbox'] + div {
    margin-right: 0;
  }
`;

export const ProjectList = styled.div`
  flex: 1;

  ${ListHeader} {
    top: 3.75rem;
  }

  ${ListGroupHeader} {
    top: 7.5rem;
  }

  ${Row} {
    padding: 0;
  }

  input[type='checkbox'] + div {
    margin-right: 0;
  }
`;

export const SelectAll = styled.div`
  background-color: white;
`;

export const NoResultsCell = styled(Cell)`
  flex: 1;
  color: ${colors.grey40};
`;

export const DeleteCell = TableBoxRowActions.Container;
export const DeleteButton = TableBoxRowActions.DeleteButton;
