import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  ActionButton,
  ActionsBanner,
  ClientFilter,
  Currency,
  FiltersBar,
  Level,
  MemberFilter,
  Page,
  PeriodFilter,
  PracticeFilter,
  ProjectBillingTypeFilter,
  ProjectStatusFilter,
  ProjectTaskStatusFilter,
  RouteLink,
  YesNoFilter,
} from '~/components';
import { useApi, useSubscription, useToast, useWorkspace } from '~/contexts';
import {
  useClientFilters,
  useDocumentTitle,
  useFeatures,
  useProjectFilters,
  useSearchParams,
  useSearchParamsConfig,
} from '~/hooks';
import billingTypes from '~/lookups/project-billing-types';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { dateFormats, intervalOptions, QueryString } from '~/utils';
import ApprovedExpensesIcon from './assets/approved-expenses.svg?react';
import ApprovedServicesIcon from './assets/approved-services.svg?react';
import OtherItemsIcon from './assets/other-items.svg?react';
import ReadyToBillIcon from './assets/ready-to-bill.svg?react';
import CreateInvoiceDialog from './invoice-dialogs/CreateInvoiceDialog';
import ReadyToBillResults from './ReadyToBillResults';
import ClientFiltersBar from '~/components/filters/ClientFiltersBar.jsx';
import ClientFiltersGroup from '~/components/filters/ClientFiltersGroup.jsx';
import FilterButton from '~/components/filters/FilterButton.jsx';
import FiltersDrawer from '~/components/filters/FiltersDrawer.jsx';
import ProjectFiltersBar from '~/components/filters/ProjectFiltersBar.jsx';
import ProjectFiltersGroup from '~/components/filters/ProjectFiltersGroup.jsx';
import TaskFiltersGroup from '~/components/filters/TaskFiltersGroup.jsx';

const SummarySection = styled.section`
  background: ${colors.grey5};
  padding: 1rem;
  margin: 0 -1rem;
  overflow: auto;
  scrollbar-width: none;
`;

const Summary = styled.div`
  display: flex;
  align-items: center;
  background: ${colors.white};
  box-shadow: 0 3px 15px ${colors.grey10};
  border-radius: 5px;
  transition: opacity 250ms;
  opacity: ${({ fade }) => (fade ? 0.2 : 1)};
  min-width: fit-content;
`;

const SummaryBox = styled.div`
  display: flex;
  flex: 1;
  padding: 1.5rem 1rem;
  justify-content: center;
  align-items: center;

  &:not(:first-child) {
    border-left: 1px solid ${colors.grey10};
  }
`;

const SummaryIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  width: 3.125rem;
  height: 3.125rem;
`;

const SummaryAmount = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  font-weight: ${weights.bold};
  font-size: 1rem;

  p {
    font-size: 0.75rem;
    font-weight: ${weights.normal};
    line-height: 1rem;
    white-space: nowrap;
  }
`;

function ReadyToBillPage() {
  useDocumentTitle('Ready to Bill');

  const api = useApi();
  const { workspace } = useWorkspace();
  const [selection, setSelection] = useState([]);
  const [{ status, data }, setQuery] = useState({ status: 'loading', data: null });

  const intervals = useMemo(
    () =>
      _.pick(
        intervalOptions,
        'custom',
        'this_week',
        'this_semi_month',
        'this_month',
        'this_month_to_date',
        'last_week',
        'last_semi_month',
        'last_month',
      ),
    [],
  );

  const { notify } = useSubscription();

  const [dialogItem, setDialogItem] = useState(null);

  const toast = useToast();

  const features = useFeatures();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();

  const [params, setParams] = useState({
    period: intervals.this_month,
    includePriorUnbilledItems: 'yes',
    approvedItemsOnly: 'no',
    projectTaskStatuses: [],
    ...clientFilters.filters,
    ...projectFilters.filters,
  });

  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParamsConfig = useSearchParamsConfig();
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: { ...searchParamsConfig.period, default: intervals.this_month },
        includePriorUnbilledItems: { valid: ['yes', 'no'], default: 'yes' },
        approvedItemsOnly: { valid: ['yes', 'no'], default: 'no' },
        projectTaskStatuses: searchParamsConfig.projectTaskStatuses,
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
      }),

      [searchParamsConfig, intervals, clientFilters, projectFilters],
    ),
    sessionKey: 'ready_to_bill',

    onChange: useCallback((params) => setParams((state) => ({ ...state, ...params })), []),
  });

  const urlSearchParams = useMemo(() => {
    return {
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      includePriorUnbilledItems: params.includePriorUnbilledItems === 'yes' || undefined,
      approvedItemsOnly: params.approvedItemsOnly === 'yes' || undefined,
      projectTaskStatusId: params.projectTaskStatuses?.map((v) => v.id),
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
    };
  }, [params, clientFilters, projectFilters]);

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      if (params) {
        setParams((state) => ({ ...state, ...params }));
        setSearchParamsStatus('ready');
      }
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    setQuery((state) => (state.status === 'ready' ? { ...state, status: 'filtering' } : state));

    const { data } = await api.www
      .workspaces(workspace.id)
      .invoices()
      .readyToBill({ ...urlSearchParams, size: 200 });

    setQuery({ status: 'ready', data });
  }, [workspace.id, api, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const history = useHistory();

  const handleCreateClick = () => {
    const projects = data.projects.filter((p) => selection.includes(p.id));
    const { client, currency } = projects[0];
    setDialogItem({ client, currency, projects });
  };

  const handleSelectionChange = (selection) => {
    setSelection(selection);
  };

  const handleCreate = async (client, currency, projects) => {
    try {
      const servicesThrough = moment(params.period?.end || new Date()).format(dateFormats.isoDate);
      const { data: invoice } = await api.www
        .workspaces(workspace.id)
        .invoices()
        .createReadyToBill({
          clientId: client.id,
          currency,
          projectIds: projects.map((p) => p.id),
          issuedOn:
            workspace.invoiceIssueOn === 'through_date' ? servicesThrough : moment().format(dateFormats.isoDate),
          servicesThrough,
          periodStart: params.includePriorUnbilledItems === 'yes' ? null : params.period?.start,
          periodEnd: params.period?.end,
          approvedItemsOnly: params.approvedItemsOnly === 'yes' || undefined,
        });

      notify(useSubscription.keys.refresh_timer);
      notify(useSubscription.keys.refresh_time_approval_count);
      notify(useSubscription.keys.refresh_expense_approval_count);

      history.push(`/app/${workspace.key}/billing/invoices/${invoice.id}`);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);

  const handleFilter = (value) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams((state) => ({ ...state, ...value }));
    searchParams.set({ ...value });
    hideFilters();
  };

  const uninvoicedRevenue = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/uninvoiced-revenue?${new QueryString(
        {
          start: params.includePriorUnbilledItems === 'yes' ? 'not_set' : params.period?.start ?? 'not_set',
          end: params.period?.end ?? 'not_set',
          projectRecordStatusId: params.projectRecordStatusId ?? 'all',
          clientRecordStatusId: params.clientRecordStatusId ?? 'all',
          client: params.clients?.map((v) => v.id),
          clientPractice: params.clientPractices?.map((v) => v.id),
          clientBusinessUnit: params.clientBusinessUnits?.map((v) => v.id),
          clientSalesRepresentative: params.clientSalesRepresentatives?.map((v) => v.id),
          clientOwner: params.clientOwners?.map((v) => v.id),
          clientTag: params.clientTags?.map((v) => v.id),
          clientLocation: params.clientLocations?.map((v) => v.id),
          clientIndustry: params.clientIndustries?.map((v) => v.id),
          project: params.projects?.map((v) => v.id),
          projectAdmin: params.projectAdmins?.map((v) => v.id),
          projectBillingType: params.projectBillingTypes?.map((v) => v.id),
          projectStatus: params.projectStatuses?.map((v) => v.id),
          projectTag: params.projectTags?.map((v) => v.id),
          projectType: params.projectTypes?.map((v) => v.id),
          projectPractice: params.projectPractices?.map((v) => v.id),
          projectBusinessUnit: params.projectBusinessUnits?.map((v) => v.id),
          projectSalesRepresentative: params.projectSalesRepresentatives?.map((v) => v.id),
          projectTaskStatus: params.projectTaskStatuses?.map((v) => v.id),
          timeStatus: params.approvedItemsOnly === 'yes' ? ['approved'] : undefined,
          expenseStatus: params.approvedItemsOnly === 'yes' ? ['approved'] : undefined,
          ...query,
        },
        { multi: true },
      ).toString()}`,
    [params, workspace.key],
  );

  if (status === 'loading') return <PageLoader />;

  return (
    <>
      <Page scrollable>
        <Page.Header>
          <Page.Info>
            <Page.Eyebrow>Billing</Page.Eyebrow>
            <Page.Title>Ready to Bill</Page.Title>
          </Page.Info>

          <Page.Actions>
            <FilterButton isOutline onClick={showFilters} />
          </Page.Actions>
        </Page.Header>

        <>
          <Page.Filters>
            <FiltersBar>
              <PeriodFilter
                name="period"
                placeholder="Date Range"
                value={params.period}
                intervals={_.values(intervals)}
                onChange={({ target: { value } }) => handleFilter({ period: value })}
              />

              <ClientFilter
                name="clients"
                placeholder="Client"
                value={params.clients}
                onChange={({ target: { value } }) => handleFilter({ clients: value })}
              />

              {features.practices && (
                <PracticeFilter
                  name="clientPractices"
                  placeholder="Client Practice"
                  value={params.clientPractices}
                  onChange={({ target: { value } }) => handleFilter({ clientPractices: value })}
                />
              )}

              <ProjectBillingTypeFilter
                name="projectBillingTypes"
                value={params.projectBillingTypes}
                onChange={({ target: { value } }) => handleFilter({ projectBillingTypes: value })}
                options={[billingTypes.fixed, billingTypes.fixed_recurring, billingTypes.tm]}
              />

              <YesNoFilter
                icon="filter"
                name="approvedItemsOnly"
                placeholder="Approved Items Only"
                value={params.approvedItemsOnly}
                onChange={({ target: { value } }) => handleFilter({ approvedItemsOnly: value })}
              />

              <YesNoFilter
                icon="filter"
                name="includePriorUnbilledItems"
                placeholder="Include Unbilled Prior Items"
                value={params.includePriorUnbilledItems}
                onChange={({ target: { value } }) => handleFilter({ includePriorUnbilledItems: value })}
              />

              {features.practices && (
                <PracticeFilter
                  name="projectPractices"
                  placeholder="Project Practice"
                  value={params.projectPractices}
                  onChange={({ target: { value } }) => handleFilter({ projectPractices: value })}
                />
              )}

              <MemberFilter
                name="projectAdmins"
                placeholder="Project Admin"
                value={params.projectAdmins}
                onChange={({ target: { value } }) => handleFilter({ projectAdmins: value })}
              />

              <ProjectStatusFilter
                name="projectStatuses"
                value={params.projectStatuses}
                onChange={({ target: { value } }) => handleFilter({ projectStatuses: value })}
              />

              <ProjectTaskStatusFilter
                name="projectTaskStatuses"
                value={params.projectTaskStatuses}
                onChange={({ target: { value } }) => handleFilter({ projectTaskStatuses: value })}
              />

              <ClientFiltersBar filters={{ ...params, clients: null, clientPractices: null }} onChange={handleFilter} />

              <ProjectFiltersBar
                filters={{
                  ...params,
                  projectAdmins: null,
                  projectBillingTypes: null,
                  projectPractices: null,
                  projectStatuses: null,
                }}
                onChange={handleFilter}
              />
            </FiltersBar>
          </Page.Filters>
          <Filters
            values={params}
            intervals={intervals}
            isOpen={filtersVisible}
            onApply={handleFilter}
            onClose={hideFilters}
          />
        </>

        <Page.Section sticky>
          <SummarySection>
            <Summary fade={status === 'filtering'}>
              <SummaryBox>
                <SummaryIcon>
                  <ApprovedServicesIcon />
                </SummaryIcon>
                <SummaryAmount>
                  <div>
                    <RouteLink to={uninvoicedRevenue({ itemType: ['time_entry', 'fixed_fee_milestone'] })}>
                      <Currency value={data.totals.services} minimumFractionDigits={0} maximumFractionDigits={0} />
                      <p>Total Services</p>
                    </RouteLink>
                  </div>
                </SummaryAmount>
              </SummaryBox>
              <SummaryBox>
                <SummaryIcon>
                  <ApprovedExpensesIcon />
                </SummaryIcon>
                <SummaryAmount>
                  <div>
                    <RouteLink to={uninvoicedRevenue({ itemType: ['expense_item', 'project_expense_item'] })}>
                      <Currency value={data.totals.expenses} minimumFractionDigits={0} maximumFractionDigits={0} />
                      <p>Total Expenses</p>
                    </RouteLink>
                  </div>
                </SummaryAmount>
              </SummaryBox>
              <SummaryBox>
                <SummaryIcon>
                  <OtherItemsIcon />
                </SummaryIcon>
                <SummaryAmount>
                  <div>
                    <RouteLink to={uninvoicedRevenue({ itemType: 'other_item' })}>
                      <Currency value={data.totals.otherItems} minimumFractionDigits={0} maximumFractionDigits={0} />
                      <p>Total Other Items</p>
                    </RouteLink>
                  </div>
                </SummaryAmount>
              </SummaryBox>
              <SummaryBox>
                <SummaryIcon>
                  <ReadyToBillIcon />
                </SummaryIcon>
                <SummaryAmount>
                  <div>
                    <RouteLink to={uninvoicedRevenue()}>
                      <Currency value={data.total} minimumFractionDigits={0} maximumFractionDigits={0} />
                      <p>Total to Bill</p>
                    </RouteLink>
                  </div>
                </SummaryAmount>
              </SummaryBox>
            </Summary>
          </SummarySection>
        </Page.Section>

        <Page.ListView>
          <ReadyToBillResults
            includePriorUnbilledItems={params.includePriorUnbilledItems}
            start={params.period?.start}
            end={params.period?.end}
            data={data}
            selection={selection}
            onSelectionChange={handleSelectionChange}
            status={status}
            approvedItemsOnly={params.approvedItemsOnly}
            projectTaskStatuses={params.projectTaskStatuses}
          />
        </Page.ListView>

        {selection.length > 0 && (
          <ActionsBanner>
            <Level right>
              <Level.Item>
                <ActionButton disabled={selection.length === 0} onClick={() => handleCreateClick()}>
                  Create Invoice
                </ActionButton>
              </Level.Item>
            </Level>
          </ActionsBanner>
        )}
      </Page>

      {dialogItem && (
        <CreateInvoiceDialog
          client={dialogItem.client}
          currency={dialogItem.currency}
          projects={dialogItem.projects}
          onCreate={handleCreate}
          onClose={() => setDialogItem(null)}
        />
      )}
    </>
  );
}

function Filters({ values, intervals, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <PeriodFilter
        name="period"
        placeholder="Date Range"
        value={filters.period}
        intervals={_.values(intervals)}
        onChange={({ target: { value } }) => handleFilter({ period: value })}
      />
      <YesNoFilter
        icon="filter"
        name="approvedItemsOnly"
        placeholder="Approved Items Only"
        value={filters.approvedItemsOnly}
        onChange={({ target: { value } }) => handleFilter({ approvedItemsOnly: value })}
      />
      <YesNoFilter
        icon="filter"
        name="includePriorUnbilledItems"
        placeholder="Include Unbilled Prior Items"
        value={filters.includePriorUnbilledItems}
        onChange={({ target: { value } }) => handleFilter({ includePriorUnbilledItems: value })}
      />

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <TaskFiltersGroup filters={filters} onChange={handleFilter} />
    </FiltersDrawer>
  );
}

export default ReadyToBillPage;
