import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Currency,
  ExportDialog,
  FiltersBar,
  ListView,
  Page,
  RouteLink,
  ExportDropdown,
  MemberFilter,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { weights } from '~/styles';
import { dateFormats, mimeTypes, QueryString } from '~/utils';

export default function ExpensesByCategory({ project }) {
  const api = useApi();
  const { workspace } = useWorkspace();

  const [{ isReady, data }, setQuery] = useState({ data: null, isReady: false });
  const [params, setParams] = useState({ members: [] });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .projects(project.id)
        .dashboard()
        .expensesByCategory({ memberId: params.members?.map((v) => v.id) });
      setQuery({ data, isReady: true });
    } catch (error) {
      setQuery({ data: null, isReady: true });
    }
  }, [workspace.id, project, params, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleFilter = (filter) => {
    setParams({ ...params, ...filter });
  };

  const confirmation = useConfirmation();

  const handleExport = async (filename, mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .projects(project.id)
          .dashboard()
          .expensesByCategory(
            { memberId: params.member?.map((v) => v.id) },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  if (!isReady && !data) return <PageLoader />;
  if (!data) return <ErrorPage.NotFound publicSite={false} />;

  const { records, totals, columns } = data;

  const expenseDetail = (query = {}) =>
    `/app/${workspace.key}/reports/expense-items${new QueryString(
      {
        start: 'not_set',
        end: moment().format(dateFormats.isoDate),
        project: project.id,
        member: params.members?.map((v) => v.id),
        ...query,
      },
      { multi: true },
    ).toString(true)}`;

  const currency = project.currency;

  return (
    <>
      <Page.TabHeader>
        <Page.Title>Expenses</Page.Title>

        <Page.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(`${_.snakeCase(project.key)}_expenses_by_category.csv`, mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(`${_.snakeCase(project.key)}_expenses_by_category.xlsx`, mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>
        </Page.Actions>
      </Page.TabHeader>

      <Page.Filters>
        <FiltersBar>
          <MemberFilter
            name="member"
            placeholder="Member"
            value={params.members}
            onChange={({ target: { value } }) => handleFilter({ members: value })}
          />
        </FiltersBar>
      </Page.Filters>

      <Page.ListView>
        <ListView>
          <ListView.Header>
            <ListView.Column sticky minWidth="16rem">
              Category
            </ListView.Column>

            <ListView.Column align="center" width="5rem">
              Items
            </ListView.Column>

            <ListView.Column align="right" width="10rem">
              Cost
            </ListView.Column>

            <ListView.Column align="right" width="10rem" isVisible={columns.expensesCostBudget}>
              Cost Budget
            </ListView.Column>

            <ListView.Column align="right" width="10rem" isVisible={columns.expensesCostBudgetLeft}>
              Cost Budget Left
            </ListView.Column>

            <ListView.Column align="right" width="10rem" isVisible={columns.expensesRevenue}>
              Revenue
            </ListView.Column>

            <ListView.Column align="right" width="10rem" isVisible={columns.expensesRevenueBudget}>
              Revenue Budget
            </ListView.Column>

            <ListView.Column align="right" width="10rem" isVisible={columns.expensesRevenueBudget}>
              Revenue Budget Left
            </ListView.Column>
          </ListView.Header>

          <ListView.Body showNoResults={false}>
            {records.map((category) => {
              return (
                <ListView.Row key={category.id} data-testid="row">
                  <ListView.Cell>
                    <RouteLink to={expenseDetail({ expenseCategory: category.id })}>{category.name}</RouteLink>
                  </ListView.Cell>

                  <ListView.Cell>{category.itemCount}</ListView.Cell>

                  <ListView.Cell>
                    <ListView.CellContent align="right">
                      <RouteLink to={expenseDetail({ expenseCategory: category.id })}>
                        <Currency value={category.expensesCost} currency={currency} />
                      </RouteLink>
                    </ListView.CellContent>
                  </ListView.Cell>

                  <ListView.Cell>
                    <ListView.CellContent align="right">
                      <Currency value={category.expensesCostBudget} currency={currency} />
                    </ListView.CellContent>
                  </ListView.Cell>

                  <ListView.Cell>
                    <ListView.CellContent align="right">
                      <Currency value={category.expensesCostBudgetLeft} currency={currency} />
                    </ListView.CellContent>
                  </ListView.Cell>

                  <ListView.Cell>
                    <ListView.CellContent align="right">
                      <Currency value={category.expensesRevenue} currency={currency} />
                    </ListView.CellContent>
                  </ListView.Cell>

                  <ListView.Cell>
                    <ListView.CellContent align="right">
                      <Currency value={category.expensesRevenueBudget} currency={currency} />
                    </ListView.CellContent>
                  </ListView.Cell>

                  <ListView.Cell>
                    <ListView.CellContent align="right">
                      <Currency value={category.expensesRevenueBudgetLeft} currency={currency} />
                    </ListView.CellContent>
                  </ListView.Cell>
                </ListView.Row>
              );
            })}
          </ListView.Body>

          <ListView.Row style={{ fontWeight: weights.bold }}>
            <ListView.Cell>
              <RouteLink to={expenseDetail()}>Total</RouteLink>
            </ListView.Cell>

            <ListView.Cell>{totals.itemCount}</ListView.Cell>

            <ListView.Cell>
              <RouteLink to={expenseDetail()}>
                <Currency value={totals.expensesCost} currency={currency} />
              </RouteLink>
            </ListView.Cell>

            <ListView.Cell>
              <Currency value={totals.expensesCostBudget} currency={currency} />
            </ListView.Cell>

            <ListView.Cell>
              <Currency value={totals.expensesCostBudgetLeft} currency={currency} />
            </ListView.Cell>

            <ListView.Cell>
              <Currency value={totals.expensesRevenue} currency={currency} />
            </ListView.Cell>

            <ListView.Cell>
              <Currency value={totals.expensesRevenueBudget} currency={currency} />
            </ListView.Cell>

            <ListView.Cell>
              <Currency value={totals.expensesRevenueBudgetLeft} currency={currency} />
            </ListView.Cell>
          </ListView.Row>

          <ListView.Status total={records.length} />
        </ListView>
      </Page.ListView>
    </>
  );
}
