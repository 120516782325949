import { rgba } from 'polished';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Dropdown, Icon, Page, Pill } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { colors, weights } from '~/styles';

const Component = styled(Dropdown)`
  font-size: 0.875rem;
  font-weight: ${weights.normal};
  white-space: nowrap;
  width: 15rem;
  text-align: left;
  justify-self: center;
  margin-top: 0.25rem;

  ${Dropdown.Trigger} {
    button {
      color: ${colors.black};

      &:hover {
        color: ${colors.grey55};
      }
    }
  }
`;

const Menu = styled(Dropdown.Menu)`
  box-shadow: 0 6px 8px ${rgba(colors.black, 0.16)};
  min-width: 20rem;
  max-height: 20rem;
  overflow-y: auto;
`;

const Caret = styled(Icon)`
  font-size: 0.875rem;
`;

const Trigger = styled.div`
  position: relative;
  display: flex;
`;

export default function ClientDropdown({ client }) {
  const { workspace } = useWorkspace();
  const api = useApi();

  const [clients, setClients] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).clients().get({
        size: 1000,
        recordStatusId: 'active',
      });

      setClients(data);
    } catch (error) {
      setClients([]);
    }
  }, [api, workspace.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Component position="bottom" align="left">
      {({ setIsOpen, ...props }) => {
        return (
          <>
            <Dropdown.Trigger>
              <Button isAnchor onClick={() => setIsOpen(true)}>
                <Trigger>
                  <Page.Title>{client.name}</Page.Title>
                  {client.recordStatus.id === 'archived' && (
                    <Pill
                      style={{
                        marginLeft: '.5rem',
                        backgroundColor: colors.grey25,
                      }}>
                      Archived
                    </Pill>
                  )}
                </Trigger>
                <Caret spaceLeft icon="angle-down" />
              </Button>
            </Dropdown.Trigger>

            <Menu {...props}>
              {clients?.length > 0 ? (
                clients
                  .filter((c) => c.id !== client.id)
                  .map((client) => (
                    <Dropdown.Link
                      key={client.id}
                      onClick={() => setIsOpen(false)}
                      to={`/app/${workspace.key}/clients/${client.key}`}
                      value={client.id}>
                      {client.name}
                    </Dropdown.Link>
                  ))
              ) : (
                <Dropdown.Item>No clients available</Dropdown.Item>
              )}
            </Menu>
          </>
        );
      }}
    </Component>
  );
}
