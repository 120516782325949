import { Field, Form, Radio } from '~/components';
import React from 'react';
import CapMonthlyHoursCheckbox from './CapMonthlyHoursCheckbox';
import { calculateExpenseCost, calculateExpenseMarkup, calculateExpenseRevenue } from '~/utils';
import { useFeatures } from '~/hooks';

function BudgetForm({ formik, project, projectModel }) {
  const features = useFeatures();

  const currency = projectModel.currency;

  const markup = calculateExpenseMarkup({
    enableMarkup: projectModel.monthlyBudgetEnableMarkup,
    markupMethod: projectModel.monthlyBudgetMarkupMethod,
    markupAmount: projectModel.monthlyBudgetMarkupAmount,
    markupRatio: projectModel.monthlyBudgetMarkupRatio,
    amount: projectModel.monthlyBudgetExpensesBillableAmount,
  });

  const totalExpenseCost = calculateExpenseCost({
    isBillable: projectModel.isBillable,
    billableAmount: projectModel.monthlyBudgetExpensesBillableAmount,
    nonBillableAmount: projectModel.monthlyBudgetExpensesNonBillableAmount,
  });

  const totalExpenseRevenue = calculateExpenseRevenue({
    markup,
    amount: projectModel.monthlyBudgetExpensesBillableAmount,
  });

  const handleExpensesBillableAmountChange = (value) => {
    // Deactivate "monthlyBudgetEnableMarkup" checkbox if billable amount is 0 or null
    if (!value) formik.setFieldValue('monthlyBudgetEnableMarkup', false);
    formik.setFieldValue('monthlyBudgetExpensesBillableAmount', value);
  };

  return (
    <>
      {projectModel.isBillable && (
        <Form.Section title="Monthly Services Revenue">
          <Form.Control>
            <Field.Currency name="estimatedMonthlyServiceRevenue" placeholder="Services Revenue" currency={currency} />
          </Form.Control>
        </Form.Section>
      )}

      <Form.Section title="Monthly Hours">
        {projectModel.isBillable && (
          <Form.Control>
            <Field.Number name="estimatedMonthlyBillableHours" min={0} precision={2} placeholder="Billable Hours" />
          </Form.Control>
        )}

        <Form.Control>
          <Field.Number
            name="estimatedMonthlyNonBillableHours"
            min={0}
            precision={2}
            placeholder="Non-Billable Hours"
          />
        </Form.Control>

        <Form.Control>
          <CapMonthlyHoursCheckbox />
        </Form.Control>
      </Form.Section>
      <Form.Section title="Monthly Expenses">
        {projectModel.isBillable && (
          <Form.Control>
            <Field.Currency
              onChange={handleExpensesBillableAmountChange}
              name="monthlyBudgetExpensesBillableAmount"
              placeholder="Billable Cost"
              currency={currency}
            />
          </Form.Control>
        )}

        <Form.Control>
          <Field.Currency
            name="monthlyBudgetExpensesNonBillableAmount"
            placeholder="Non-Billable Cost"
            currency={currency}
          />
        </Form.Control>

        <Form.Control>
          <Field.Currency
            name="totalExpenseCost"
            value={totalExpenseCost}
            materialPlaceholder="Total Expense Cost"
            disabled
            materialAlwaysVisible
            currency={currency}
          />
        </Form.Control>

        {(features.expenseMarkup || project.monthlyBudgetEnableMarkup) && (
          <Form.Control>
            <Field.Checkbox
              name="monthlyBudgetEnableMarkup"
              label="Markup the billable monthly expense budget amount"
              disabled={!projectModel.isBillable || !(projectModel?.monthlyBudgetExpensesBillableAmount > 0)}
            />
          </Form.Control>
        )}

        {projectModel.monthlyBudgetEnableMarkup && projectModel.isBillable && (
          <>
            <Form.Control>
              <Field.RadioGroup name="monthlyBudgetMarkupMethod">
                <Radio key="percentage" value="percentage" label="Markup by Percentage" />
                <Radio key="amount" value="amount" label="Markup by Fixed Amount" />
              </Field.RadioGroup>
            </Form.Control>

            {projectModel.monthlyBudgetMarkupMethod && (
              <Form.Control>
                {projectModel.monthlyBudgetMarkupMethod === 'amount' && (
                  <Field.Currency name="monthlyBudgetMarkupAmount" placeholder="Markup Amount" currency={currency} />
                )}

                {projectModel.monthlyBudgetMarkupMethod === 'percentage' && (
                  <Field.Number
                    suffix="%"
                    name="monthlyBudgetMarkupRatio"
                    placeholder="Markup Percentage"
                    min={0}
                    max={100}
                    precision={2}
                  />
                )}
              </Form.Control>
            )}
          </>
        )}

        <Form.Control>
          <Field.Currency
            name="totalExpenseRevenue"
            value={totalExpenseRevenue}
            materialPlaceholder="Total Budgeted to Bill"
            disabled
            materialAlwaysVisible
            currency={currency}
          />
        </Form.Control>
      </Form.Section>
      {projectModel.isBillable && (
        <Form.Section
          title={
            <>
              Monthly
              <br />
              Other Items
              <br />
              to Bill
            </>
          }>
          <Form.Control help="The amount we charge the client for Other Items.">
            <Field.Currency name="monthlyBudgetOtherItemsFee" placeholder="Amount" currency={currency} />
          </Form.Control>
        </Form.Section>
      )}
    </>
  );
}

export default BudgetForm;
