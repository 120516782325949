import {
  ActionButton,
  BillableIcon,
  Button,
  Buttons,
  CancelButton,
  Currency,
  DeleteConfirmation,
  Dropdown,
  Field,
  Form,
  FormMessage,
  Icon,
  InlineTooltip,
  Level,
  ModalCard,
  Radio,
  SplitButton,
  Table,
} from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useAuth, useFeatures, useIsMounted } from '~/hooks';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';
import WorkspaceRolesModal from './WorkspaceRolesModal';

const Section = styled.section`
  &:not(:first-child) {
    margin-top: 1.5rem;
  }
`;

const AddRoleDropdown = styled(Dropdown)`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  > ${Dropdown.Trigger} {
    display: flex;
    align-items: center;
  }
`;

const AddRoleButton = styled(ActionButton)`
  position: relative;
  margin-left: 0.5rem;
  border-radius: 999rem;
`;

function RolesTab({ clientModel, onChange }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const features = useFeatures();
  const auth = useAuth();

  const [workspaceRoles, setWorkspaceRoles] = useState([]);

  useEffect(() => {
    (async () => {
      if (!auth.workspace.manage) return;

      const params = { isActive: true, size: 1000 };
      if (!features.multicurrency) params.currency = workspace.currency;
      const { data } = await api.www.workspaces(workspace.id).workspaceRoles().get(params);
      if (!isMounted.current) return;
      setWorkspaceRoles(data);
    })();
  }, [api, isMounted, workspace.id, workspace.currency, auth.workspace.manage, features.multicurrency]);

  const [action, setAction] = useState(null);

  const [formEntry, setEditForm] = useState(null);
  const handleAdd = () => setEditForm({});
  const handleEdit = (entry) => setEditForm(entry);
  const handleCancel = () => setEditForm(null);

  const handleSubmit = (value) => {
    value = emptyStringToNull(value);

    const roles = value.id
      ? clientModel.roles.map((cr) =>
          cr.id === value.id
            ? {
                ...cr,
                ...value,
                disciplineId: value.discipline?.id ?? null,
                practiceId: value.practice?.id ?? null,
                locationId: value.location?.id ?? null,
              }
            : cr,
        )
      : [
          ...clientModel.roles,
          {
            ...value,
            id: _.uniqueId('cr_'),
            disciplineId: value.discipline?.id ?? null,
            practiceId: value.practice?.id ?? null,
            locationId: value.location?.id ?? null,
          },
        ];

    onChange(roles);
    handleCancel();
  };

  const addRoles = async (workspaceRoles) => {
    let currentNewIndex = _.reduce(
      clientModel.roles,
      (index, cr) => {
        if (cr.newIndex >= 0 && cr.newIndex > index) {
          return cr.newIndex;
        }
        return index;
      },
      0,
    );

    let clientRoles = [...clientModel.roles];

    for (const workspaceRole of [workspaceRoles].flat()) {
      const clientRole = {
        id: _.uniqueId('cr_'),
        newIndex: currentNewIndex + 1,
        name: workspaceRole.name,
        isBillable: workspaceRole.isBillable,
        currency: workspaceRole.currency,
        rate: workspaceRole.rate,
        discipline: workspaceRole.discipline,
        disciplineId: workspaceRole.disciplineId,
        practice: workspaceRole.practice,
        practiceId: workspaceRole.practiceId,
        location: workspaceRole.location,
        locationId: workspaceRole.locationId,
        workspaceRoleId: workspaceRole.id,
        workspaceRole: workspaceRole,
      };

      clientRoles.unshift(clientRole);
    }

    onChange(clientRoles);
  };

  const handleAddRoles = async (roles) => {
    addRoles(roles.map((value) => _.omit(value, ['id', 'workspaceRole'])));
  };

  const handleLinkRoles = async (roles) => {
    addRoles(roles);
  };

  const handleDelete = (item) => {
    onChange(clientModel.roles.filter((r) => r.id !== item.id));
  };

  const validateUnique = (a, b) => {
    if (features.practices) {
      return (
        _.trim(_.lowerCase(a.name)) === _.trim(_.lowerCase(b.name)) &&
        a.practice?.id === b.practice?.id &&
        a.location?.id === b.location?.id
      );
    }

    return _.trim(_.lowerCase(a.name)) === _.trim(_.lowerCase(b.name)) && a.location?.id === b.location?.id;
  };

  const roles = clientModel.roles;

  return (
    <>
      <Section>
        {auth.workspace.manage && (
          <Level>
            <Level.Item />
            <Level.Item>
              <AddRoleDropdown>
                {({ setIsOpen, isOpen }) => {
                  const actions = {
                    add: {
                      options: workspaceRoles.filter((wr) => !clientModel.roles.some((cr) => validateUnique(wr, cr))),

                      get tooltip() {
                        if (workspaceRoles.length === 0) return 'No workspace roles exist.';
                        if (this.options.length === 0) return 'All workspace-defined roles are in use.';
                        return null;
                      },

                      get disabled() {
                        return !!this.tooltip;
                      },
                    },

                    add_all: {
                      options: workspaceRoles.filter((wr) => !clientModel.roles.some((cr) => validateUnique(wr, cr))),

                      get tooltip() {
                        if (workspaceRoles.length === 0) return 'No workspace roles exist.';
                        if (this.options.length === 0) return 'All workspace-defined roles are in use.';
                        return null;
                      },

                      get disabled() {
                        return !!this.tooltip;
                      },
                    },

                    link: {
                      options: workspaceRoles.filter((wr) => !clientModel.roles.some((cr) => validateUnique(wr, cr))),

                      get tooltip() {
                        if (workspaceRoles.length === 0) return 'No workspace roles exist.';
                        if (this.options.length === 0) return 'All workspace-defined roles are in use.';
                        return null;
                      },

                      get disabled() {
                        return !!this.tooltip;
                      },
                    },
                  };

                  const handleToggle = (action) => {
                    setIsOpen(!isOpen);

                    if (!isOpen) {
                      setAction(action);
                    }
                  };

                  const handleAddAllRoles = () => {
                    setIsOpen(false);
                    addRoles(actions.add_all.options.map((wr) => _.omit(wr, ['id', 'workspaceRole'])));
                  };

                  const handleLinkAllRoles = () => {
                    setIsOpen(false);
                    addRoles(actions.link.options);
                  };

                  return (
                    <>
                      <Dropdown.Trigger>
                        <SplitButton style={{ alignItems: 'center' }}>
                          <AddRoleButton disabled={actions.add.disabled} onClick={() => handleToggle('add')}>
                            Add a Workspace Role
                            {actions.add.tooltip && <InlineTooltip message={actions.add.tooltip} />}
                          </AddRoleButton>

                          <SplitButton.Menu>
                            {({ setIsOpen }) => (
                              <>
                                <SplitButton.Item
                                  disabled={actions.add_all.disabled}
                                  onClick={() => setIsOpen(false) || handleAddAllRoles()}>
                                  Add all Workspace Roles
                                  {actions.add_all.tooltip && <InlineTooltip message={actions.add_all.tooltip} />}
                                </SplitButton.Item>

                                <SplitButton.Item
                                  disabled={actions.link.disabled}
                                  onClick={() => {
                                    handleToggle('link');
                                    setIsOpen(false);
                                  }}>
                                  Link to a Workspace Role
                                  {actions.link.tooltip && <InlineTooltip message={actions.link.tooltip} />}
                                </SplitButton.Item>

                                <SplitButton.Item
                                  disabled={actions.link.disabled}
                                  onClick={() => setIsOpen(false) || handleLinkAllRoles()}>
                                  Link to all Workspace Roles
                                  {actions.link.tooltip && <InlineTooltip message={actions.link.tooltip} />}
                                </SplitButton.Item>
                              </>
                            )}
                          </SplitButton.Menu>
                        </SplitButton>
                      </Dropdown.Trigger>
                    </>
                  );
                }}
              </AddRoleDropdown>
            </Level.Item>
          </Level>
        )}
      </Section>

      <Section>
        <Table small>
          <Table.BoxHeader>
            <Table.Column width="12rem">Role</Table.Column>
            <Table.Column width="12rem" isVisible={features.practices}>
              Practice
            </Table.Column>
            <Table.Column width="12rem">Location</Table.Column>
            <Table.Column width="12rem" isVisible={features.disciplines}>
              Discipline
            </Table.Column>
            <Table.Column align="right">Rate</Table.Column>
            <Table.BoxActionsColumn />
          </Table.BoxHeader>
          <Table.Body>
            {roles.map((item) => (
              <RoleRow
                key={item.id}
                client={clientModel}
                clientRole={item}
                onEdit={() => handleEdit(item)}
                onDeleted={handleDelete}
              />
            ))}

            <Table.Row>
              <Table.Cell>
                <Button isAnchor isStrong onClick={handleAdd}>
                  <Icon icon="plus" size="xs" spaceRight />
                  Quick Add
                </Button>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Section>

      {action && (
        <WorkspaceRolesModal
          workspaceRoles={
            {
              add: workspaceRoles,
              link: workspaceRoles,
            }[action]
          }
          onChange={{ add: handleAddRoles, link: handleLinkRoles }[action]}
          onClose={() => setAction()}
          action={action}
          roles={roles}
        />
      )}

      {formEntry && (
        <ClientRoleForm
          clientRole={formEntry}
          clientRoles={clientModel.roles}
          currency={clientModel.currency}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
}

function RoleRow({ client, clientRole, disableActions, onEdit, onDeleted }) {
  async function handleDelete() {
    onDeleted(clientRole);
  }

  if (clientRole.workspaceRoleId)
    return <WorkspaceRoleRowDetails clientRole={clientRole} disableActions={disableActions} onDelete={handleDelete} />;

  return (
    <RoleRowDetails
      client={client}
      clientRole={clientRole}
      disableActions={disableActions}
      onEdit={onEdit}
      onDelete={handleDelete}
    />
  );
}

function RoleRowDetails({
  clientRole: { name, isBillable, discipline, practice, location, currency, rate },
  disableActions,
  onEdit,
  onDelete,
}) {
  const confirmation = useConfirmation();

  const handleDelete = async () => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>
        Are you sure you want to delete this role from the client? This will not remove this role from any projects
        within this client.
      </DeleteConfirmation>
    ));
    if (!confirm) return;

    onDelete();
  };

  return (
    <Table.BoxRow>
      <Table.Cell>{name}</Table.Cell>
      <Table.Cell>{practice?.name}</Table.Cell>
      <Table.Cell>{location?.name}</Table.Cell>
      <Table.Cell>{discipline?.name}</Table.Cell>
      <Table.Cell>
        <Currency value={isBillable ? rate : null} maximumFractionDigits={7} currency={currency} />
        <BillableIcon style={{ marginLeft: '0.4rem' }} value={isBillable} />
      </Table.Cell>
      <TableBoxRowActions>
        <TableBoxRowActions.Edit disabled={disableActions} onClick={onEdit} />

        <hr />

        <TableBoxRowActions.Dropdown disabled={disableActions}>
          {() => (
            <>
              <Dropdown.Item onClick={onEdit}>Edit</Dropdown.Item>

              <Dropdown.DeleteItem onClick={handleDelete}>Delete</Dropdown.DeleteItem>
            </>
          )}
        </TableBoxRowActions.Dropdown>
      </TableBoxRowActions>
    </Table.BoxRow>
  );
}

function WorkspaceRoleRowDetails({
  clientRole: {
    workspaceRole: { currency, name, isActive, isBillable, discipline, practice, location, rate },
  },
  disableActions,
  onDelete,
}) {
  const confirmation = useConfirmation();

  const handleDelete = async () => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>
        Are you sure you want to delete this role from the client? This will not remove this role from any projects
        within this client.
      </DeleteConfirmation>
    ));
    if (!confirm) return;

    onDelete();
  };

  return (
    <Table.BoxRow isDisabled={!isActive}>
      <Table.Cell>{name}</Table.Cell>
      <Table.Cell>{practice?.name}</Table.Cell>
      <Table.Cell>{location?.name}</Table.Cell>
      <Table.Cell>{discipline?.name}</Table.Cell>
      <Table.Cell>
        <Currency value={rate} maximumFractionDigits={7} currency={currency} />
        <BillableIcon style={{ marginLeft: '0.4rem' }} value={isBillable} />
      </Table.Cell>
      <TableBoxRowActions>
        <Button disabled>
          <Icon icon="link" />
          <InlineTooltip message="You cannot edit a role that is linked to a workspace role." placement="left" />
        </Button>

        <hr />

        <TableBoxRowActions.Delete disabled={disableActions} onClick={handleDelete} />
      </TableBoxRowActions>
    </Table.BoxRow>
  );
}

function ClientRoleForm({ clientRole, clientRoles, currency, onSubmit, onCancel }) {
  const { workspace } = useWorkspace();
  const features = useFeatures();

  const initialValues = mergeValues(
    {
      currency,
      discipline: null,
      practice: null,
      location: null,
      id: null,
      isBillable: true,
      name: '',
      rate: '',
    },
    clientRole,
  );

  return (
    <>
      <ModalCard title={clientRole.id ? 'Edit Client Role' : 'Add Client Role'} onClose={onCancel}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Yup.object()
            .shape({
              isBillable: Yup.bool().label('Is Billable'),
              currency: Yup.string()
                .label('Currency')
                .when('isBillable', { is: true, then: (schema) => schema.required() }),
              name: Yup.string().label('Name').max(255).required(),
              rate: Yup.number().label('Rate').min(0).max(99999999999).nullable(),
            })
            .test('unique', function (value) {
              const { name, practice, location, id } = value;

              const duplicatedArray = clientRoles.filter((role) => {
                // return false when comparing with itself
                if (role.id === id) return false;

                if (features.practices) {
                  return (
                    _.trim(_.lowerCase(role.name)) === _.trim(_.lowerCase(name)) &&
                    role.practiceId == practice?.id &&
                    role.locationId == location?.id
                  );
                }

                return _.trim(_.lowerCase(role.name)) == _.trim(_.lowerCase(name)) && role.locationId == location?.id;
              });

              if (duplicatedArray.length > 0) {
                return this.createError({
                  path: 'unique',
                  message: features.practices
                    ? `A Role with the same name, practice and location already exists.`
                    : `A Role with the same name and location already exists.`,
                });
              }

              return value;
            })}>
          {(formik) => {
            return (
              <Form>
                <ModalCard.Body>
                  {formik.errors.unique && (
                    <FormMessage.Error style={{ marginBottom: '1rem' }}>{formik.errors.unique}</FormMessage.Error>
                  )}
                  <Form.Control>
                    <Field.Text autoFocus name="name" placeholder="Name" maxLength={255} />
                  </Form.Control>
                  {features.practices && (
                    <Form.Control>
                      <Field.PracticeSelect name="practice" placeholder="Practice" />
                    </Form.Control>
                  )}
                  <Form.Control>
                    <Field.LocationSelect name="location" placeholder="Location" />
                  </Form.Control>
                  <Form.Control>
                    <Field.DisciplineSelect name="discipline" placeholder="Discipline" />
                  </Form.Control>
                  <Form.Control>
                    <Form.Control>
                      <Field.RadioGroup name="isBillable">
                        <Radio value={true} label="Billable" />
                        <Radio value={false} label="Non-billable" />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Control>
                  {formik.values.isBillable && (
                    <>
                      <Form.Control>
                        <Field.WorkspaceCurrencySelect
                          name="currency"
                          materialPlaceholder="Currency"
                          clearable={false}
                          disabled={!features.multicurrency && initialValues.currency === workspace.currency}
                        />
                      </Form.Control>
                      <Form.Control>
                        <Field.Currency
                          name="rate"
                          placeholder="Rate"
                          precision={7}
                          currency={formik.values.currency}
                        />
                      </Form.Control>
                    </>
                  )}
                </ModalCard.Body>

                <ModalCard.Footer>
                  <Buttons align="right">
                    <CancelButton onClick={onCancel}>Close</CancelButton>

                    <Button onClick={formik.submitForm}>Save &amp; Close</Button>
                  </Buttons>
                </ModalCard.Footer>
              </Form>
            );
          }}
        </Formik>
      </ModalCard>
    </>
  );
}

export default RolesTab;
