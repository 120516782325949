import React from 'react';
import styled from 'styled-components';
import { colors } from '~/styles';

const Row = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.grey10};
`;

const NoResults = styled.div`
  color: ${colors.grey40};
  position: sticky;
  left: calc(50% - 2rem);
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
`;

export default function ListViewEmpty() {
  return (
    <Row>
      <NoResults>No results</NoResults>
    </Row>
  );
}
