import classNames from 'classnames';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Button, CreateButton, DayPickerDropdown, ExportDropdown, Icon, Level, Page, Tooltip } from '~/components';
import { useAuth } from '~/hooks';
import FilterButton from '~/components/filters/FilterButton';
import mimeTypes from '~/utils/mimeTypes';
import { ActionBarStyles, DateNav, DateNavButton, DatePickerToggleButton, ViewNav } from './ActionBarStyles';
import Loader from '../Loader';

export default function ActionBar({
  start,
  unit,
  date,
  children,
  filters = true,
  create = true,
  disabled,
  onCreateAllocation,
  onDateChange,
  onDateNavPrevious,
  onDateNavNext,
  onUnitChange,
  onFilter,
  onExport,
}) {
  const auth = useAuth();

  const displayDate = useMemo(() => {
    return {
      month: moment(start).format('MMMM, YYYY'),
      week: moment(start).format('MMMM D, YYYY'),
      day: moment(start).format('MMMM D, YYYY'),
    }[unit];
  }, [unit, start]);

  const scope = useMemo(() => {
    return { day: 'week', week: 'week', month: 'month' }[unit];
  }, [unit]);

  return (
    <ActionBarStyles>
      <Level width="100%">
        <Level.Item narrow>
          <DateNav>
            <DateNavButton disabled={disabled} onClick={onDateNavPrevious} isAnchor>
              <Icon icon="angle-left" />
            </DateNavButton>

            <DayPickerDropdown
              value={date}
              disabled={disabled}
              onChange={onDateChange}
              alignOffset="1rem"
              positionOffset="1rem"
              scope={scope}>
              {({ setIsOpen }) => (
                <DatePickerToggleButton isAnchor onClick={() => setIsOpen((isOpen) => !isOpen)}>
                  {displayDate}
                </DatePickerToggleButton>
              )}
            </DayPickerDropdown>

            <DateNavButton disabled={disabled} onClick={onDateNavNext} isAnchor>
              <Icon icon="angle-right" />
            </DateNavButton>
            <Tooltip style={{ marginLeft: '0.75rem' }} message="Today">
              <DateNavButton onClick={() => onDateChange()} isAnchor>
                <Icon style={{ fontSize: '0.875rem' }} icon="calendar-day" type="far" />
              </DateNavButton>
            </Tooltip>
          </DateNav>
        </Level.Item>

        <Level.Item>
          <ViewNav>
            <Button
              className={classNames({ 'is-active': unit === 'month' })}
              disabled={disabled}
              onClick={() => onUnitChange('month')}>
              Month
            </Button>
            <Button
              className={classNames({ 'is-active': unit === 'week' })}
              disabled={disabled}
              onClick={() => onUnitChange('week')}>
              Week
            </Button>
            <Button
              className={classNames({ 'is-active': unit === 'day' })}
              disabled={disabled}
              onClick={() => onUnitChange('day')}>
              Day
            </Button>
          </ViewNav>
        </Level.Item>

        <Level.Item right narrow>
          <Page.Actions>
            {disabled && <Loader />}

            <ExportDropdown>
              {({ setIsOpen }) => (
                <>
                  <ExportDropdown.Item
                    onClick={async () => {
                      await onExport(mimeTypes.csv);
                      setIsOpen(false);
                    }}>
                    Export to CSV
                  </ExportDropdown.Item>

                  <ExportDropdown.Item
                    onClick={async () => {
                      await onExport(mimeTypes.xlsx);
                      setIsOpen(false);
                    }}>
                    Export to Excel
                  </ExportDropdown.Item>
                </>
              )}
            </ExportDropdown>

            {filters && <FilterButton onClick={onFilter} />}

            {create && (
              <CreateButton
                tooltip={
                  auth.allocations.manage
                    ? 'New Allocation'
                    : 'Your security role prohibits you from creating allocations.'
                }
                onClick={onCreateAllocation}
                disabled={!auth.allocations.manage}
              />
            )}
          </Page.Actions>

          <Level>{children}</Level>
        </Level.Item>
      </Level>
    </ActionBarStyles>
  );
}
