import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { Button, Dropdown, Icon, Page } from '~/components';
import { useWorkspace } from '~/contexts';
import { colors } from '~/styles';

const Component = styled(Dropdown)`
  font-size: 0.875rem;
  white-space: nowrap;
  width: 15rem;
  text-align: left;
  justify-self: center;
  margin-top: 0.25rem;

  ${Dropdown.Trigger} {
    button {
      color: ${colors.black};

      &:hover {
        color: ${colors.grey55};
      }
    }
  }
`;

const Menu = styled(Dropdown.Menu)`
  box-shadow: 0 6px 8px ${rgba(colors.black, 0.16)};
  align-self: flex-end;
  justify-self: flex-end;
`;

const Caret = styled(Icon)`
  font-size: 0.875rem;
`;

export default function DashboardDropdown({ value }) {
  const { workspace } = useWorkspace();

  return (
    <Component position="bottom" align="right">
      {({ setIsOpen, ...props }) => {
        return (
          <>
            <Dropdown.Trigger>
              <Button isAnchor onClick={() => setIsOpen(true)}>
                <Page.Title>
                  {
                    {
                      executive: 'Executive Dashboard',
                      personal: 'Personal Dashboard',
                    }[value]
                  }
                </Page.Title>
                <Caret spaceLeft icon="angle-down" />
              </Button>
            </Dropdown.Trigger>

            <Menu {...props}>
              {[
                {
                  value: 'executive',
                  label: 'Executive Dashboard',
                },
                {
                  value: 'personal',
                  label: 'Personal Dashboard',
                },
              ]
                .filter((o) => o.value !== value)
                .map((option) => (
                  <Dropdown.Link
                    key={option.value}
                    to={`/app/${workspace.key}/dashboard/${option.value}`}
                    value={option.value}>
                    {option.label}
                  </Dropdown.Link>
                ))}
            </Menu>
          </>
        );
      }}
    </Component>
  );
}
