import pluralize from 'pluralize';
import React from 'react';
import styled from 'styled-components';
import { Spinner } from '~/components';
import { useNumberFormat } from '~/hooks';
import { colors, weights } from '~/styles';
import PartialResultsTooltip from '../PartialResultsTooltip';

const Bar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.75rem;
  padding: 0.375rem 1rem;

  .icon {
    font-size: 0.75rem;
  }

  z-index: 4;
  background: linear-gradient(to top, ${colors.white} 90%, transparent 100%);

  position: sticky;
  bottom: -1rem;
  margin-top: auto;
  margin-bottom: -1rem;
  width: 100%;
`;

const Content = styled.div`
  position: sticky;
  margin-left: auto;
  right: 0;
  display: flex;
  align-items: center;
`;

const Total = styled.div`
  font-size: 0.75rem;
  font-weight: ${weights.light};
`;

export default function TableStatus({ total, label = 'Result', isLoading, partial }) {
  const numberFormat = useNumberFormat({ minimumFractionDigits: 0, maximumFractionDigits: 0 });

  return (
    <Bar>
      <Content>
        {isLoading && <Spinner />}

        {total !== undefined ? (
          <Total>{`${numberFormat.format(total)} ${pluralize(label, total)}`}</Total>
        ) : (
          <Total>{label}</Total>
        )}

        {partial && <PartialResultsTooltip />}
      </Content>
    </Bar>
  );
}
