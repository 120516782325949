import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import {
  Currency,
  DateTime,
  ExportDialog,
  ExportDropdown,
  FiltersBar,
  IconLink,
  ListView,
  ListViewActions,
  ListViewMenu,
  Page,
  PaymentMethodFilter,
  PeriodFilter,
  PracticeFilter,
  SearchInput,
  Tag,
  Tooltip,
} from '~/components';
import { useApi, useConfirmation, useIntegrations, useToast, useWorkspace } from '~/contexts';
import {
  useAuth,
  useClientFilters,
  useDocumentTitle,
  useFeatures,
  useIsMounted,
  useProjectFilters,
  useSearchParams,
  useSearchParamsConfig,
} from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort, intervalOptions, intervalsByScope, mimeTypes } from '~/utils';
import QBOIndicator from '../components/QBOIndicator';
import XeroPaymentIndicator from '../components/XeroPaymentIndicator';
import ClientPaymentForm from './ClientPaymentForm';
import ClientPaymentView from './ClientPaymentView';
import DeletePaymentDialog from './DeletePaymentDialog';
import LoadPaymentFromQuickBooksDialog from './LoadFromQuickBooksDialog';
import SavePaymentToQuickBooksDialog from './SavePaymentToQuickBooksDialog';
import SavePaymentToXeroDialog from './SavePaymentToXeroDialog';
import ClientFiltersBar from '~/components/filters/ClientFiltersBar.jsx';
import ClientFiltersGroup from '~/components/filters/ClientFiltersGroup.jsx';
import FilterButton from '~/components/filters/FilterButton.jsx';
import FiltersDrawer from '~/components/filters/FiltersDrawer.jsx';
import FiltersGroup from '~/components/filters/FiltersGroup.jsx';
import ProjectFiltersBar from '~/components/filters/ProjectFiltersBar.jsx';
import ProjectFiltersGroup from '~/components/filters/ProjectFiltersGroup.jsx';

const Small = styled.small`
  display: block;
`;

function ClientPaymentsListPage() {
  const documentTitle = useDocumentTitle('Payments');

  const { workspace } = useWorkspace();
  const api = useApi();

  const isMounted = useIsMounted();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();

  const [query, setQuery] = useState({
    isReady: false,
    searchParamsStatus: 'pending',
    data: null,
    params: {
      q: '',
      paymentMethods: [],
      clientPractices: [],
      period: null,
      sort: new QuerySort('receivedOn', 'desc'),
      page: 0,
      size: 50,
      ...clientFilters.filters,
      ...projectFilters.filters,
    },
    action: 'load',
  });

  const setParams = (params) => {
    setQuery((state) => ({
      ...state,
      action: 'filter',
      params: { ...state.params, ...params, page: 0 },
      searchParamsStatus: 'ready',
    }));
  };

  const loadMore = useCallback(() => {
    setQuery((state) => {
      if (
        state.searchParamsStatus !== 'ready' ||
        state.action !== null ||
        !state.data ||
        state.data.total <= state.data.results.length
      ) {
        return state;
      }

      return {
        ...state,
        params: { ...state.params, page: state.params.page + 1 },
        action: 'load-more',
      };
    });
  }, []);

  const removeItem = (id) => {
    setQuery((state) => ({
      ...state,
      data: {
        ...state.data,
        results: state.data?.results.filter((i) => i.id !== id),
        total: state.data.total - 1,
      },
    }));
  };

  const updateItem = (item) => {
    setQuery((state) => ({
      ...state,
      data: {
        ...state.data,
        results: state.data.results.some((i) => i.id === item.id)
          ? state.data.results.map((i) => (i.id === item.id ? { ...i, ...item } : i))
          : [...state.data.results, item],
      },
    }));
  };

  const { action, data, isReady, params, searchParamsStatus } = query;

  const auth = useAuth();

  const confirmation = useConfirmation();
  const toast = useToast();

  const history = useHistory();
  const { url, path } = useRouteMatch();
  const location = useLocation();

  const integrations = useIntegrations();

  const searchParamsConfig = useSearchParamsConfig();
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        q: { default: '' },
        paymentMethods: searchParamsConfig.paymentMethods,
        period: {
          ...searchParamsConfig.period,
          default: intervalOptions.all_dates,
        },
        clientPractices: { ...searchParamsConfig.practices, key: 'clientPractice' },
        sort: { default: new QuerySort('receivedOn', 'desc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
      }),
      [searchParamsConfig, clientFilters, projectFilters],
    ),
    sessionKey: 'payments',
    onChange: useCallback((params) => setParams((state) => ({ ...state, ...params })), []),
  });

  const urlSearchParams = useMemo(() => {
    const { start, end } = params.period || {};

    return {
      start: start ?? undefined,
      end: end ?? undefined,
      q: params.q || undefined,
      sort: params.sort,
      page: params.page,
      size: params.size,
      paymentMethodId: params.paymentMethods?.map((v) => v.id),
      clientPracticeId: params.clientPractices?.map((v) => v.id),
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
    };
  }, [params, clientFilters, projectFilters]);

  useEffect(() => {
    if (searchParamsStatus !== 'pending') return;
    searchParams.get().then((params) => {
      if (params) setParams(params);
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).clientPayments().get(urlSearchParams);

      if (!isMounted.current) return;

      setQuery((state) => ({
        ...state,
        action: null,
        searchParamsStatus: 'ready',
        data: {
          ...data,
          results: state.action === 'load-more' ? [...state.data.results, ...data.results] : data.results,
          total: data.total,
        },
      }));
    } catch (error) {
      setQuery((state) => ({ ...state, data: { total: 0, results: [] } }));
    }
  }, [workspace.id, api, isMounted, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const features = useFeatures();

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);

  if (!isReady && !data) return <PageLoader />;

  const handleFilter = (value) => {
    setParams({ ...value });
    searchParams.set({ ...value });
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const handleView = async (payment) => {
    history.push({ pathname: `${url}/${payment.id}/view`, search: location.search });
  };

  const handleEdit = async (payment) => {
    history.push({ pathname: `${url}/${payment.id}/edit`, search: location.search });
  };

  const reloadPayment = async (paymentId) => {
    const payment = await api.www
      .workspaces(workspace.id)
      .clientPayments()
      .get({ ids: [paymentId] })
      .then((res) => res.data[0]);

    updateItem(payment);

    return payment;
  };

  const handleDelete = async (payment) => {
    await confirmation.prompt((resolve) => (
      <DeletePaymentDialog
        payment={payment}
        onClose={() => resolve(false)}
        onDelete={() => {
          removeItem(payment.id);
          toast.success(`Payment has been deleted`);
          resolve(true);
        }}
      />
    ));
  };

  const handleCloseDrawer = () => {
    history.push({ pathname: url, search: location.search });
    documentTitle.set('Payments');
  };

  const handleSaveToQuickBooks = async (payment) => {
    await confirmation.prompt((resolve) => (
      <SavePaymentToQuickBooksDialog
        payment={payment}
        resolve={async () => {
          await reloadPayment(payment.id);
          resolve();
        }}
      />
    ));
  };

  const handleReloadFromQuickBooks = async (payment) => {
    await confirmation.prompt((resolve) => (
      <LoadPaymentFromQuickBooksDialog
        payment={payment}
        resolve={async () => {
          await reloadPayment(payment.id);
          resolve();
        }}
      />
    ));
  };

  const handleSaveToXero = async (payment) => {
    await confirmation.prompt((resolve) => (
      <SavePaymentToXeroDialog
        payment={payment}
        resolve={async () => {
          await reloadPayment(payment.id);
          resolve();
        }}
      />
    ));
  };

  const handleExport = async (filename, mimeType) => {
    await confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .clientPayments()
          .export(
            {
              ...urlSearchParams,
              size: null,
            },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  return (
    <Page scrollable>
      <Page.Header>
        <Page.Info>
          <Page.Eyebrow>Billing</Page.Eyebrow>
          <Page.Title>Payments</Page.Title>
        </Page.Info>

        <Page.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(`payments.csv`, mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(`payments.xlsx`, mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />

          <IconLink
            icon="plus"
            tooltip={
              auth.payments.manage ? 'Receive Payment' : 'Your security role prohibits you from creating payments.'
            }
            disabled={!auth.payments.manage}
            to={`${url}/new${location.search}`}
          />
        </Page.Actions>
      </Page.Header>

      <>
        <Page.Filters>
          <FiltersBar>
            <SearchInput
              value={params.q}
              placeholder="Search"
              onChange={({ target: { value } }) => handleFilter({ q: value })}
            />

            {features.practices && (
              <PracticeFilter
                name="clientPractices"
                placeholder="Client Practice"
                value={params.clientPractices}
                onChange={({ target: { value } }) => handleFilter({ clientPractices: value })}
              />
            )}

            <PaymentMethodFilter
              name="paymentMethods"
              value={params.paymentMethods}
              onChange={({ target: { value } }) => handleFilter({ paymentMethods: value })}
            />

            <PeriodFilter
              name="period"
              placeholder="Issue Date"
              intervals={[intervalOptions.all_dates, ...intervalsByScope.month]}
              value={params.period}
              onChange={({ target: { value } }) => handleFilter({ period: value })}
            />

            <ClientFiltersBar filters={{ ...params, clientPractices: null }} onChange={handleFilter} />

            <ProjectFiltersBar filters={params} onChange={handleFilter} />
          </FiltersBar>
        </Page.Filters>
        <Filters values={params} isOpen={filtersVisible} onApply={handleFilter} onClose={hideFilters} />
      </>

      <Page.ListView>
        <ListView>
          <ListView.Header>
            <ListView.Column sticky width="10rem" name="receivedOn" onSort={handleSort} sort={params.sort}>
              Date
            </ListView.Column>
            <ListView.Column minWidth="16rem" name="client.name" onSort={handleSort} sort={params.sort}>
              Client
            </ListView.Column>
            <ListView.Column minWidth="16rem">Invoices</ListView.Column>
            <ListView.Column minWidth="10rem" name="referenceNumber" onSort={handleSort} sort={params.sort}>
              Ref #
            </ListView.Column>
            <ListView.Column width="12rem" name="paymentMethod.name" onSort={handleSort} sort={params.sort}>
              Payment Method
            </ListView.Column>
            <ListView.Column width="10rem" align="right" name="convertedAmount" onSort={handleSort} sort={params.sort}>
              Amount
            </ListView.Column>
            <ListViewActions.Column />
          </ListView.Header>
          <ListView.Body fade={action === 'filter'}>
            {data.results.map((payment) => {
              const { currency, id, receivedOn, referenceNumber, client, amount, convertedAmount, paymentMethod } =
                payment;

              const savedToQuickBooks = integrations.qbo && payment.qboPaymentId;
              const savedToXero = !!integrations.xero && payment.xeroPaymentId;
              const isEditable = payment.permissions.manage && !savedToQuickBooks && !savedToXero;

              return (
                <ListView.Row key={id} onClick={() => (isEditable ? handleEdit(payment) : handleView(payment))}>
                  <ListView.Cell>
                    <DateTime value={receivedOn} />
                  </ListView.Cell>
                  <ListView.Cell>
                    {client.name}
                    {payment.xeroPaymentId && <XeroPaymentIndicator xeroPaymentId={payment.xeroPaymentId} />}
                    {payment.qboPaymentId && <QBOIndicator message="This payment is in QuickBooks." />}
                  </ListView.Cell>
                  <ListView.Cell>
                    <FirstInvoice payment={payment} />
                    <Invoices payment={payment} />
                  </ListView.Cell>
                  <ListView.Cell>{referenceNumber}</ListView.Cell>
                  <ListView.Cell>{paymentMethod?.name}</ListView.Cell>
                  <ListView.Cell>
                    <p>
                      <Currency value={convertedAmount} currency={workspace.currency} />
                      {currency !== workspace.currency && (
                        <Small>
                          <Currency value={amount} currency={currency} />
                        </Small>
                      )}
                    </p>
                  </ListView.Cell>

                  <ListViewActions>
                    {isEditable ? (
                      <ListViewActions.Edit onClick={() => handleEdit(payment)} />
                    ) : (
                      <ListViewActions.View onClick={() => handleView(payment)} />
                    )}

                    <hr />
                    <ListViewMenu>
                      {({ setIsOpen }) => {
                        const handleAction = async (action) => {
                          setIsOpen(false);
                          await action();
                        };

                        return (
                          <>
                            <ListViewMenu.Item onClick={() => handleAction(() => handleView(payment))}>
                              View
                            </ListViewMenu.Item>

                            <ListViewMenu.Item
                              disabled={!isEditable}
                              tooltip={
                                !payment.permissions.manage
                                  ? 'Insufficient permissions to edit this payment.'
                                  : payment.qboPaymentId
                                    ? 'This payment must be edited in QuickBooks.'
                                    : undefined
                              }
                              onClick={() => handleAction(() => handleEdit(payment))}>
                              Edit
                            </ListViewMenu.Item>

                            {integrations.qbo &&
                              (payment.qboPaymentId ? (
                                <ListViewMenu.Item
                                  disabled={!payment.permissions.manage}
                                  tooltip={
                                    !payment.permissions.manage
                                      ? 'Insufficient permissions to reload this payment from QuickBooks.'
                                      : undefined
                                  }
                                  onClick={() => handleAction(() => handleReloadFromQuickBooks(payment))}>
                                  Reload from QuickBooks
                                </ListViewMenu.Item>
                              ) : (
                                <ListViewMenu.Item
                                  disabled={!payment.permissions.manage || payment.qboStatus !== 'ready'}
                                  tooltip={
                                    !payment.permissions.manage
                                      ? 'Insufficient permissions to save this payment to QuickBooks.'
                                      : payment.qboStatus !== 'ready'
                                        ? 'This payment is not ready to save to QuickBooks.'
                                        : undefined
                                  }
                                  onClick={() => handleAction(() => handleSaveToQuickBooks(payment))}>
                                  Save to QuickBooks
                                </ListViewMenu.Item>
                              ))}

                            {!!integrations.xero && !payment.xeroPaymentId && (
                              <ListViewMenu.Item
                                disabled={!payment.permissions.manage || payment.xeroStatus !== 'ready'}
                                tooltip={
                                  !payment.permissions.manage
                                    ? 'Insufficient permissions to save this payment to Xero.'
                                    : payment.xeroStatus !== 'ready'
                                      ? 'This payment is not ready to save to Xero.'
                                      : undefined
                                }
                                onClick={() => handleAction(() => handleSaveToXero(payment))}>
                                Save to Xero
                              </ListViewMenu.Item>
                            )}

                            <ListViewMenu.Item
                              disabled={!payment.permissions.manage}
                              tooltip={
                                !payment.permissions.manage
                                  ? 'Insufficient permissions to delete this payment.'
                                  : undefined
                              }
                              onClick={() => handleDelete(payment)}>
                              Delete
                            </ListViewMenu.Item>
                          </>
                        );
                      }}
                    </ListViewMenu>
                  </ListViewActions>
                </ListView.Row>
              );
            })}

            {data.results.length === 0 && <ListView.Empty />}

            {data.total > data.results.length && (
              <ListView.Loader key={data.results.length} onIntersecting={loadMore} />
            )}
          </ListView.Body>

          <ListView.Status total={data.total} label="Payment" isLoading={!!action} />
        </ListView>
      </Page.ListView>

      <Switch>
        <Route path={`${path}/new`}>
          <ClientPaymentForm
            onSaved={fetchData}
            onDeleted={(payment) => removeItem(payment.id)}
            onClose={handleCloseDrawer}
          />
        </Route>

        <Route path={`${path}/:paymentId/edit`}>
          <ClientPaymentForm
            onSaved={(payment) => reloadPayment(payment.id)}
            onDeleted={(payment) => removeItem(payment.id)}
            onClose={handleCloseDrawer}
          />
        </Route>

        <Route path={`${path}/:paymentId/view`}>
          <ClientPaymentView onClose={handleCloseDrawer} />
        </Route>
      </Switch>
    </Page>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <FiltersGroup label="Payment Filters" filters={[filters.period, filters.paymentMethods]}>
        <PeriodFilter
          name="period"
          placeholder="Issue Date"
          intervals={[intervalOptions.all_dates, ...intervalsByScope.month]}
          value={filters.period}
          onChange={({ target: { value } }) => handleFilter({ period: value })}
        />

        <PaymentMethodFilter
          value={filters.paymentMethods}
          onChange={({ target: { value } }) => handleFilter({ paymentMethods: value })}
        />
      </FiltersGroup>
    </FiltersDrawer>
  );
}

const FirstInvoice = ({ payment }) => {
  const paymentInvoice = payment.paymentInvoices[0];
  if (!paymentInvoice) return null;

  return `Invoice #${paymentInvoice.invoice.number}`;
};

const Title = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

const Invoices = ({ payment }) => {
  let paymentInvoicesCount = payment.paymentInvoices.length - 1;
  if (paymentInvoicesCount <= 0) return null;

  return (
    <Tooltip
      message={
        <div style={{ fontSize: '1rem' }}>
          <Title>Invoices</Title>
          {payment.paymentInvoices.map((paymentInvoice) => (
            <Tag style={{ backgroundColor: colors.grey5 }} key={paymentInvoice.invoice.id}>
              <small>Invoice #{paymentInvoice.invoice.number}</small>
            </Tag>
          ))}
        </div>
      }>
      <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40 }}>
        <small>+{paymentInvoicesCount}</small>
      </Tag>
    </Tooltip>
  );
};

export default ClientPaymentsListPage;
