import { useAuth } from '~/hooks';
import PerformanceForecastByProject from './PerformanceForecastByProject';
import PerformanceForecastByProjectByTimeUnit from './PerformanceForecastByProjectByTimeUnit';

export default function useForecastReports() {
  const auth = useAuth();

  return [
    auth.reports.performanceForecastbyProject && {
      key: 'performance_forecast_by_project',
      name: 'Performance Forecast by Project',
      description:
        'A project performance forecast combining past actuals and the future plan. Considers time entries, resource allocations, billing schedule milestones, revenue recognition ledger entries, expenses, and other items to bill.',
      path: '/performance-forecast-by-project',
      component: PerformanceForecastByProject,
    },
    auth.reports.performanceForecastbyProject && {
      key: 'performance_forecast_by_project_by_time_unit',
      name: 'Performance Forecast by Project by Time Unit',
      description:
        'A project performance forecast for a specific timeframe. The report shows starting values prior to the reporting period, values for each time unit, and ending forecast values.',
      path: '/performance-forecast-by-project-by-time-unit',
      component: PerformanceForecastByProjectByTimeUnit,
    },
  ].filter(Boolean);
}
