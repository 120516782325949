import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ProjectHealthScoreBar, ProjectLink, RouteLink, Widget } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import colors from '~/styles/colors.js';
import Timestamp from './Timestamp.jsx';

const List = styled.ul`
  li {
    display: flex;
    justify-content: space-between;
    padding: 0.25rem 0;

    > div {
      flex: 0.25;
      align-self: center;
    }
  }

  h3 {
    flex: 1;
    word-wrap: break-word;
  }
`;

export default function MostHealthyProjects({ start, end }) {
  const { workspace } = useWorkspace();

  const api = useApi();
  const [{ data, isReady, timestamp }, setQuery] = useState({ data: null, isReady: false });

  const fetchData = useCallback(
    async (behavior) => {
      try {
        if (workspace.sampleData) behavior = 'bypass';
        const { data, headers } = await api.www
          .workspaces(workspace.id)
          .executiveDashboard()
          .mostHealthyProjects({ start, end }, behavior);
        setQuery({ data, isReady: true, timestamp: headers['x-cache-timestamp'] });
      } catch (error) {
        setQuery({ data: null, isReady: true });
      }
    },
    [workspace.id, workspace.sampleData, api, start, end],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Widget loading={!isReady}>
      <Widget.Header>
        <Widget.Help message="The active projects with the highest aggregate project health score." />
        <Widget.Title>Most Healthy Active Projects</Widget.Title>
        <Timestamp timestamp={timestamp} onClick={() => fetchData('invalidate')} />
      </Widget.Header>
      <Widget.Preview style={{ minHeight: '20rem' }} />
      <Widget.Content>
        {data &&
          (data.length > 0 ? (
            <List>
              {data.map((item) => (
                <li key={item.id}>
                  <h3>
                    <ProjectLink project={item} />
                  </h3>
                  <div>
                    <RouteLink
                      to={`/app/${workspace.key}/reports/project-health-by-project?${new URLSearchParams({ project: item.id, sort: 'score:desc' }).toString()}`}>
                      <ProjectHealthScoreBar score={item.score} />
                    </RouteLink>
                  </div>
                </li>
              ))}
            </List>
          ) : (
            <p style={{ color: colors.grey25 }}>No data available.</p>
          ))}
      </Widget.Content>
    </Widget>
  );
}
