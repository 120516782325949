import React from 'react';
import {
  CurrencyInput,
  MemberFilter,
  OpportunityStageCategoryFilter,
  OpportunityStageFilter,
  OpportunityTypeFilter,
  PracticeFilter,
} from '~/components';
import OpportunityTagFilter from '~/components/filters/OpportunityTagFilter';
import { useFeatures } from '~/hooks';
import FiltersGroup from './FiltersGroup';
import ReportPeriodFilter from './ReportPeriodFilter';

function OpportunityFiltersGroup({ filters, onChange }) {
  const features = useFeatures();

  return (
    <FiltersGroup
      label="Opportunity Filters"
      filters={[
        filters.closedPeriod,
        filters.opportunityStages,
        filters.opportunityStageCategoryId,
        filters.opportunityTags,
        filters.opportunityType,
        filters.opportunityOwner,
        filters.opportunityPractice,
        filters.opportunityAmountGreaterThanOrEqual,
      ]}>
      <ReportPeriodFilter
        value={filters.closedPeriod}
        placeholder="Expected Close Date Range"
        onChange={({ target: { value } }) => onChange({ closedPeriod: value })}
      />

      <OpportunityStageCategoryFilter
        name="opportunityStageCategoryId"
        value={filters.opportunityStageCategoryId}
        onChange={({ target: { value } }) => onChange({ opportunityStageCategoryId: value })}
      />

      <OpportunityStageFilter
        name="opportunityStages"
        value={filters.opportunityStages}
        opportunityStageStatusId={filters.opportunityStageCategoryId.map((status) => status.id)}
        onChange={({ target: { value } }) => onChange({ opportunityStages: value })}
      />

      <MemberFilter
        name="opportunityOwner"
        value={filters.opportunityOwner}
        onChange={({ target: { value } }) => onChange({ opportunityOwner: value })}
        placeholder="Opportunity Owner"
        materialAlwaysVisible
      />

      <OpportunityTagFilter
        name="opportunityTags"
        value={filters.opportunityTags}
        onChange={({ target: { value } }) => onChange({ opportunityTags: value })}
        placeholder="Opportunity Tags"
        materialAlwaysVisible
      />

      <OpportunityTypeFilter
        name="opportunityType"
        value={filters.opportunityType}
        onChange={({ target: { value } }) => onChange({ opportunityType: value })}
        placeholder="Opportunity Type"
        materialAlwaysVisible
      />

      <CurrencyInput
        name="opportunityAmountGreaterThanOrEqual"
        value={filters.opportunityAmountGreaterThanOrEqual}
        onChange={(value) => onChange({ opportunityAmountGreaterThanOrEqual: value })}
        placeholder="0"
        materialPlaceholder="Minimum Opportunity Amount"
        materialAlwaysVisible
      />

      {features.practices && (
        <PracticeFilter
          name="opportunityPractice"
          value={filters.opportunityPractice}
          onChange={({ target: { value } }) => onChange({ opportunityPractice: value })}
          placeholder="Opportunity Practice"
          materialAlwaysVisible
        />
      )}
    </FiltersGroup>
  );
}

export default OpportunityFiltersGroup;
