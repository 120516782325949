import _ from 'lodash';
import React from 'react';
import { CompanyFilter } from '~/components';

function CompanyFiltersBar({ filters, onChange }) {
  return (
    <>
      {!_.isEmpty(filters.company) && (
        <CompanyFilter
          name="company"
          value={filters.company}
          onChange={({ target: { value } }) => onChange({ company: value })}
        />
      )}
    </>
  );
}

export default CompanyFiltersBar;
