import _ from 'lodash';
import React from 'react';
import projectBillingTypes from '~/lookups/project-billing-types';
import MultiSelect from '../MultiSelect';

const billingTypes = _.values(projectBillingTypes);

export default function ProjectBillingTypeFilter({
  placeholder = 'Project Billing Type',
  name,
  value,
  onChange,
  options = billingTypes,
}) {
  return (
    <MultiSelect.Filter
      icon="filter"
      placeholder={placeholder}
      name={name}
      value={value}
      renderValue={(value) => value.map((v) => v.name).join('; ')}
      options={options}
      onChange={onChange}
    />
  );
}
