import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Spinner } from '~/components';
import getGradientColor from '~/routes/app/pipeline/opportunities/components/gradient';
import { colors } from '~/styles';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 0.75rem;
`;

const ProgressContent = styled.div`
  flex: 1;
`;

const ProgressHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const ProgressName = styled.span`
  display: flex;
  align-items: center;
`;

const ProgressValue = styled.span`
  font-size: 0.875rem;
`;

const BarContainer = styled.div`
  width: 100%;
  padding-top: 0.1rem;
`;

const Meter = styled.div`
  display: flex;
  min-height: 0.75rem;
  background: ${colors.white};
  border: 1px solid ${colors.grey10};
  position: relative;
  border-radius: 0.5rem;
  padding: 0.125rem;
  min-width: 100%;
  overflow: hidden;
`;

const InsideBar = styled.div`
  min-width: 0.1rem;
  border-radius: 0.25rem;
  width: ${({ width }) => width};
  background: ${({ color }) => color};
  transition: width 0.5s linear;
`;

export default function SyncStatusBar({ sync }) {
  if (!sync) {
    sync = { statusId: 'never-run' };
  }
  const steps = [
    { id: 'pending', name: 'Pending', percentage: 0 },
    { id: 'initializing', name: 'Initializing', percentage: 10 },
    { id: 'stages', name: 'Syncing Opportunity Stages', percentage: 20 },
    { id: 'companies', name: 'Syncing Companies', percentage: 40 },
    { id: 'opportunities', name: 'Syncing Opportunities', percentage: 60 },
    { id: 'contacts', name: 'Syncing Contacts', percentage: 70 },
    { id: 'activities', name: 'Syncing Activities', percentage: 80 },
    { id: 'completed', name: 'Completed', percentage: 100 },
    { id: 'failed', name: 'Failed', percentage: 0 },
    { id: 'never-run', name: 'Never Been Run', percentage: 0 },
  ];

  const currentStep = steps.find((step) => step.id === sync?.statusId) || steps[0];
  const isCompleted = sync?.statusId === 'completed';
  const isFailed = sync?.statusId === 'failed';
  const isNeverRun = sync?.statusId === 'never-run';

  const [progress, setProgress] = useState(0);
  useEffect(() => {
    if (isCompleted) {
      setProgress(100);
    } else if (isFailed) {
      setProgress(0);
    } else {
      if (progress < currentStep.percentage) {
        const timer = setTimeout(() => setProgress(progress + 1), 30);
        return () => clearTimeout(timer);
      } else if (progress > currentStep.percentage) {
        setProgress(currentStep.percentage);
      }
    }
  }, [progress, currentStep.percentage, isCompleted, isFailed]);

  const probabilityColor = isCompleted
    ? colors.success
    : isFailed
      ? colors.danger
      : getGradientColor(currentStep.percentage);

  return (
    <Container>
      <ProgressContent>
        <ProgressHeader>
          <span>
            {isCompleted ? (
              <div>{sync.fullSync ? 'Full' : 'Incremental'} Sync Completed</div>
            ) : (
              <ProgressName>
                <div style={{ marginRight: '0.5rem' }}>{currentStep.name}</div>
                {!isCompleted && !isFailed && !isNeverRun && <Spinner />}
              </ProgressName>
            )}
          </span>
          <ProgressValue>{progress === 100 ? moment(sync.endedAt).fromNow() : `${progress}%`}</ProgressValue>
        </ProgressHeader>
        <BarContainer>
          <Meter>
            <InsideBar width={`${progress}%`} color={probabilityColor} />
          </Meter>
        </BarContainer>
      </ProgressContent>
    </Container>
  );
}
