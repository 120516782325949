import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { Avatar, BackLink, Icon, IconButton, Page, Tab, Tabs, Tooltip } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useActions, useFeatures } from '~/hooks';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';
import { AvailabilityPeriods } from './availability-periods';
import { SuppressedTooltip } from './components';
import { CostPeriods } from './cost-periods';
import MemberFilesTab from './files/MemberFilesTab';
import MemberDetailsForm from './MemberDetailsForm';
import MemberDropdown from './MemberDropdown.jsx';
import MemberHistoryDrawer from './MemberHistoryDrawer';
import MemberProjectsTab from './projects/MemberProjectsTab';
import { UtilizationTargetPeriods } from './utilization-target-periods';

const Tags = styled.div`
  display: flex;
  color: ${colors.grey55};
  font-size: 0.75rem;
  margin-top: 0.25rem;
`;

const Tag = styled.span`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem 0.4rem;
  border-left: solid 1px ${colors.grey10};

  &:first-child {
    padding-left: 0;
    border-left: none;
  }

  &:last-child {
    padding-right: 0;
  }
`;

const SuppressedIcon = styled(Icon)`
  margin-left: 0.5rem;
  font-size: 1rem;
`;

const StatusTag = styled(Tag)`
  color: ${({ isActive }) => (isActive ? colors.green : colors.grey25)};

  .icon {
    font-size: 0.625rem;
  }
`;

const Billable = styled.div`
  background-color: ${({ isBillable }) => (isBillable ? colors.green : colors.grey40)};
  color: white;
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
  font-size: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.4375rem;
`;

const initialState = {
  isReady: false,
  project: null,
};
const handlers = {
  ready: ({ member, isSlackConnected, bamboohrIntegration }) => ({
    isReady: true,
    member,
    isSlackConnected,
    bamboohrIntegration,
  }),
};

function MemberDetailsArea(props) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ isReady, member, isSlackConnected, bamboohrIntegration }, actions] = useActions(handlers, initialState);

  const { path, url } = useRouteMatch();
  const { memberId } = useParams();
  const features = useFeatures();
  const [dialog, setDialog] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const [{ data: member }, { data: isSlackConnected }, { data: bamboohrIntegration }] = await Promise.all([
        api.www.workspaces(workspace.id).members(memberId).get(),
        api.www.workspaces(workspace.id).integrations().slack.getConnected(),
        api.www.workspaces(workspace.id).bamboohr.get(),
      ]);

      actions.ready({ member, isSlackConnected, bamboohrIntegration });
    } catch (error) {
      actions.ready({ member: null, isSlackConnected: false });
    }
  }, [actions, workspace.id, memberId, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSave = (member) => {
    actions.ready({ member, isSlackConnected });
  };

  const handleViewHistory = () => {
    setDialog('history');
  };

  const handleCloseDialog = () => {
    setDialog(null);
  };

  if (!isReady) return <PageLoader />;
  if (!member) return <ErrorPage.NotFound publicSite={false} />;
  return (
    <Page scrollable>
      <Page.Header>
        <BackLink defaultPath={`/app/${workspace.key}/settings/members`} />

        <Page.Info>
          <Page.Eyebrow>Member Dashboard</Page.Eyebrow>
          <MemberDropdown member={member} />
          <Tags>
            <StatusTag isActive={member.isActive}>
              <Icon icon="circle" spaceRight />
              {member.isActive ? 'Active' : 'Inactive'}
            </StatusTag>
            <Tag>
              {member.email}{' '}
              {member.suppressedEmail && (
                <SuppressedTooltip suppressedEmail={member.suppressedEmail}>
                  <SuppressedIcon icon="exclamation-circle" color={colors.danger} />
                </SuppressedTooltip>
              )}
            </Tag>
            {member.jobTitle && <Tag>{member.jobTitle?.name}</Tag>}
            <Tag>{member.securityRole.name}</Tag>
            <Tag style={{ padding: '0.3rem 1rem 0.4rem' }}>
              <Tooltip message={member.isBillable ? 'Billable' : 'Non-billable'}>
                <Billable isBillable={member.isBillable}>
                  <Icon icon="dollar-sign" />
                </Billable>
              </Tooltip>
            </Tag>
          </Tags>
        </Page.Info>

        <Page.Actions>
          <IconButton icon="history" tooltip="Member History" onClick={handleViewHistory} />

          <Avatar value={member} size={80} isCircle hasBackground hasBorder />
        </Page.Actions>
      </Page.Header>

      <Page.Tabs>
        <Tabs>
          <Tab to={`${url}/general`}>General</Tab>
          <Tab to={`${url}/capacity`}>Capacity</Tab>
          <Tab to={`${url}/cost`}>Cost</Tab>
          {features.utilization && <Tab to={`${url}/target`}>Target</Tab>}
          <Tab to={`${url}/projects`}>Projects</Tab>
          <Tab to={`${url}/files`}>Files</Tab>
        </Tabs>
      </Page.Tabs>

      {props.children || (
        <Switch>
          <Route path={`${path}/general`}>
            <MemberDetailsForm
              member={member}
              onSaved={handleSave}
              isSlackConnected={isSlackConnected}
              bamboohrIntegration={bamboohrIntegration}
            />
          </Route>

          <Route path={`${path}/capacity`}>
            <AvailabilityPeriods member={member} />
          </Route>

          <Route path={`${path}/cost`}>
            <CostPeriods member={member} />
          </Route>

          {features.utilization && (
            <Route path={`${path}/target`}>
              <UtilizationTargetPeriods member={member} />
            </Route>
          )}

          <Route path={`${path}/projects`}>
            <MemberProjectsTab member={member} />
          </Route>

          <Route path={`${path}/files`}>
            <MemberFilesTab member={member} />
          </Route>
          <Redirect from="/" to={`${url}/general`} />
        </Switch>
      )}

      {
        {
          history: <MemberHistoryDrawer memberId={member.id} onClose={handleCloseDialog} />,
        }[dialog?.type ?? dialog]
      }
    </Page>
  );
}

export default MemberDetailsArea;
