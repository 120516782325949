import { AutoComplete, AutoCompleteMulti, Dropdown } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useFeatures, useIsMounted } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const Group = styled(Dropdown.Text)`
  font-weight: ${weights.bold};
  font-size: 1rem;
  color: ${colors.grey100};
`;

export default function InvoiceProjectMultiSelect({ invoice, currency, ...props }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const features = useFeatures();
  const [defaultOptions, setDefaultOptions] = useState([]);
  const isMounted = useIsMounted();

  const handleSearch = useCallback(
    async (q) => {
      const { data } = await api.www.workspaces(workspace.id).invoices(invoice.id).projects({ q, currency });

      const groups = _.orderBy(
        data.reduce((a, v) => {
          const status = v.active ? 'Active' : 'Archived';
          a[status] = a[status] || { key: status, projects: [] };
          a[status].projects.push(v);
          return a;
        }, {}),
        'key',
      );

      const options = [];
      for (const group of groups) {
        options.push({ type: 'group', key: group.key, disabled: true });

        for (const project of group.projects) {
          options.push({ type: 'project', project, disabled: false });
        }
      }
      return options;
    },
    [workspace.id, api, invoice.id, currency],
  );

  const refreshDefaultOptions = useCallback(async () => {
    const data = await handleSearch('');
    if (!isMounted.current) return;
    setDefaultOptions(data);
  }, [handleSearch, isMounted]);

  useEffect(() => {
    refreshDefaultOptions();
  }, [refreshDefaultOptions]);

  const handleChange = (value) => {
    // Adhere to standard HTML events
    props.onChange({ target: { name: props.name, value } });
  };

  const handleAdd = (selectedValue) => {
    if (!_.some(props.value, { id: selectedValue.project.id })) {
      handleChange([...props.value, selectedValue.project]);
    }
  };

  const handleRemove = (item) => {
    handleChange(props.value.filter(({ id }) => id !== item.id));
  };

  const filterOptions = (options) => options.filter((o) => !props.value.some((v) => v.id === o.project?.id));

  const handleFilteredSearch = async (q) => {
    const options = await handleSearch(q);
    return filterOptions(options);
  };

  return (
    <AutoCompleteMulti
      defaultOptions={filterOptions(defaultOptions)}
      renderOption={(option) => option.project.name}
      renderTag={(option, props) => (
        <AutoCompleteMulti.Tag key={option.id} {...props}>
          {option.name}
        </AutoCompleteMulti.Tag>
      )}
      compare={(a, b) => a.id === b.id}
      placeholder="Projects"
      onSearch={handleFilteredSearch}
      onAdd={handleAdd}
      onRemove={handleRemove}
      {...props}>
      {({ options }) => {
        let index = 0;
        const filteredOptions = options.filter((o) => {
          if (o.type === 'group') {
            const result = options.some((v) => o.key.includes(v.key));
            return result;
          } else {
            return true;
          }
        });

        return filteredOptions.length > 0 ? (
          filteredOptions.map((o, idx) => {
            switch (o.type) {
              case 'group':
                return filteredOptions[idx + 1]?.type == 'project' ? (
                  <Group style={{ marginTop: '0.5rem' }} key={o.key}>
                    <strong>{o.key}</strong>
                  </Group>
                ) : null;
              case 'project':
                return (
                  <AutoComplete.Option
                    grey={o.project.recordStatusId === 'archived' ? 'true' : undefined}
                    padding="0.25rem 0 0.25rem 2rem"
                    key={o.project.id}
                    value={o}
                    index={index++}>
                    {o.project.name}
                    {features.purchaseOrders && o.project.purchaseOrder && ` (PO #${o.project.purchaseOrder?.number})`}
                  </AutoComplete.Option>
                );
              default:
                return null;
            }
          })
        ) : (
          <Dropdown.Text>None</Dropdown.Text>
        );
      }}
    </AutoCompleteMulti>
  );
}
