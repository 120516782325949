import { Percent } from '~/components';
import { useWorkspace } from '~/contexts';
import { useFeatures } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { QueryString } from '~/utils';

const Container = styled.div`
  flex: 1;
  display: flex;
  margin: 0.25rem;
  background-color: ${colors.white};
  border-radius: 0.625rem;
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
`;

const Summary = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1.25rem 1.5rem;
`;

const Title = styled.div`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.bold};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
`;

const SummaryValue = styled.div`
  color: ${colors.primary};
  font-size: 2.25rem;
  font-weight: ${weights.light};
`;

const SummaryLabel = styled.div`
  margin-top: 0.25rem;
  color: ${colors.grey25};
  font-size: 0.625rem;
  text-align: center;
  text-transform: uppercase;
`;

const SummaryInfo = styled(Link)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;

  &:hover ${SummaryValue} {
    color: ${colors.accent};
  }
`;

const Breakdowns = styled.div`
  display: flex;
  flex-direction: column;
`;

const BreakdownLabel = styled.div`
  color: ${colors.grey40};
  font-size: 0.625rem;
`;

const BreakdownValue = styled.div`
  color: ${colors.black};
  font-size: 0.875rem;
  font-weight: ${weights.bold};
`;

const BreakdownItem = styled(Link)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  padding-right: 2rem;
  border: solid 0.125rem ${colors.grey5};
  border-right: none;
  border-bottom: none;

  &:first-child {
    border-top: none;
  }

  &:hover ${BreakdownValue} {
    color: ${colors.grey55};
  }
`;

export default function Utilization({ entries, startDate, endDate, member: selectedMember, memberTargets }) {
  const features = useFeatures();
  const { workspace } = useWorkspace();

  const member = useMemo(() => {
    return selectedMember ?? workspace.member;
  }, [selectedMember, workspace]);

  const targets = useMemo(() => {
    const start = moment(startDate);
    const end = moment(endDate);

    if (
      !startDate ||
      !start.isValid() ||
      !endDate ||
      !end.isValid() ||
      !memberTargets?.capacity?.length ||
      !memberTargets?.utilization?.length
    ) {
      return;
    }

    const totalDays = end.diff(start, 'days') + 1;

    start.subtract(1, 'days');

    const dayTargets = _.times(totalDays, () => {
      const date = start.add(1, 'days');

      const periodLookup = (period) => {
        const periodStart = moment(period.start);
        const periodEnd = moment(period.end);

        if (periodStart.isValid() && periodEnd.isValid()) {
          return date.isBetween(periodStart, periodEnd, 'day', '[]');
        } else if (periodStart.isValid()) {
          return date.isSameOrAfter(periodStart);
        } else if (periodEnd.isValid()) {
          return date.isSameOrBefore(periodEnd);
        }
        return true;
      };

      const capacityPeriod = _.find(memberTargets.capacity, periodLookup);
      const utilizationPeriod = _.find(memberTargets.utilization, periodLookup);

      const capacity = capacityPeriod ? capacityPeriod.hoursPerDay[date.isoWeekday() - 1] : 0;
      const utilizationTarget = (utilizationPeriod?.targetPercentage ?? 0) / 100;
      const billableHoursTarget = capacity * utilizationTarget;

      return { capacity, billableHoursTarget };
    });

    const capacity = _.sumBy(dayTargets, 'capacity');
    const billableHours = _.sumBy(dayTargets, 'billableHoursTarget');

    return { capacity, billableHours };
  }, [startDate, endDate, memberTargets]);

  const billableHours = useMemo(() => {
    const billableMinutes = _(entries)
      .filter((entry) => entry.typeId === 'project_time' && entry.isActuallyBillable)
      .sumBy('minutes');
    return billableMinutes / 60;
  }, [entries]);

  const billableUtilization = targets?.capacity > 0 ? billableHours / targets.capacity : 0;
  const targetBillable = targets?.capacity > 0 ? targets.billableHours / targets.capacity : 0;
  const attainment = targets?.billableHours > 0 ? billableHours / targets.billableHours : 0;

  const utilizationByMember = (query = {}) =>
    `/app/${workspace.key}/reports/utilization-by-member${new QueryString(
      {
        start: startDate,
        end: endDate,
        member: member?.id,
        memberBillableType: 'all',
        employmentType: 'all',
        ...query,
      },
      { multi: true },
    ).toString(true)}`;

  if (!targets?.capacity || !features.utilization) {
    return null;
  }
  return (
    <Container>
      <Summary>
        <Title>Utilization</Title>
        <SummaryInfo to={utilizationByMember()}>
          <SummaryValue>
            <Percent value={billableUtilization} minimumFractionDigits={1} maximumFractionDigits={1} />
          </SummaryValue>
          <SummaryLabel>Billable Utilization</SummaryLabel>
        </SummaryInfo>
      </Summary>
      <Breakdowns>
        <BreakdownItem to={utilizationByMember()}>
          <BreakdownLabel>Target Billable</BreakdownLabel>
          <BreakdownValue>
            <Percent value={targetBillable} minimumFractionDigits={1} maximumFractionDigits={1} />
          </BreakdownValue>
        </BreakdownItem>
        <BreakdownItem to={utilizationByMember()}>
          <BreakdownLabel>Attainment</BreakdownLabel>
          <BreakdownValue>
            <Percent value={attainment} minimumFractionDigits={1} maximumFractionDigits={1} />
          </BreakdownValue>
        </BreakdownItem>
      </Breakdowns>
    </Container>
  );
}
