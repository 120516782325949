import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  BillableIcon,
  Confirmation,
  DeleteConfirmation,
  ExportDialog,
  HoursProgress,
  Icon,
  ListView,
  ListViewActions,
  ListViewMenu,
  Page,
  RevenueProgress,
  Tag,
  Tags,
  Tooltip,
} from '~/components';
import { useApi, useConfirmation, useSubscription, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle, useSearchParams, useSearchParamsConfig } from '~/hooks';
import EditTimeEntry from '~/routes/app/time/edit-time-entry';
import { colors, weights } from '~/styles';
import { arrayMove, dateFormats, QueryString } from '~/utils';
import ProjectTaskCloneConfirmation from './ProjectTaskCloneConfirmation';
import ProjectTaskCreateForm from './ProjectTaskCreateForm';
import ProjectTaskDetails from './ProjectTaskDetails';
import ProjectTaskForm from './ProjectTaskForm';
import ProjectTasksListFilters from './ProjectTasksListFilters';
import ProjectTaskTemplatesDrawer from './ProjectTaskTemplatesDrawer';

const BoxRow = styled(ListView.Row)`
  position: relative;

  ${({ $dragging }) =>
    $dragging &&
    css`
      > div {
        transition: 150ms all ease-in-out;
        opacity: 0.5;
      }
    `}

  ${({ $over }) =>
    $over &&
    css`
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      &::before {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 1px;
        top: -7px;
        left: 0;
        border-top: 2px solid ${colors.primary};
      }
    `}
`;

const Handle = styled.div`
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'move')};
  font-size: 1rem;
  color: ${colors.grey40};
  transition: 150ms all ease-in-out;
  padding: 0.5rem 0.25rem;

  display: flex;
  align-items: center;

  &:hover {
    color: ${({ $disabled }) => ($disabled ? colors.grey40 : colors.primary)};
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.grey40};
  cursor: 'not-allowed';
`;

const Status = styled.span`
  color: ${({ status }) =>
    ({
      not_started: colors.warning,
      in_progress: colors.success,
      completed: colors.black,
    })[status]};
`;

const AssignedTo = styled.p`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
`;

function FirstAssignment({ task, project }) {
  // Find the first active project role
  const taskRole = task.roles.find((v) => v.role.isActive);
  if (project.useRoles && taskRole) {
    return (
      <Tag style={{ backgroundColor: colors.grey5 }}>
        <BillableIcon value={taskRole.role.isActuallyBillable} />
        <small>{taskRole.role.name}</small>
      </Tag>
    );
  } else {
    // Find the first active project member
    const taskMember = task.members.find((v) => v.projectMember.isActive && v.projectMember.member.isActive);
    if (!taskMember) {
      return null;
    }
    return (
      <Tag style={{ backgroundColor: colors.primary10 }} data-testid="first_member">
        <small>{taskMember.projectMember.member.name}</small>
      </Tag>
    );
  }
}

function Assignments({ task, project }) {
  let assignmentsCount = -1; // Remove the first assignment because it already shows a tag
  // Filter active project members
  const activeMembers = _.filter(task?.members, (v) => v.projectMember.isActive && v.projectMember.member.isActive);
  // Filter active project roles
  const activeRoles = _.filter(task?.roles, (v) => v.role.isActive);
  assignmentsCount += activeMembers.length; // Add members
  if (project.useRoles) assignmentsCount += activeRoles.length; // Add roles if the project uses roles
  if (assignmentsCount <= 0) return null;

  return (
    <Tooltip
      data-testid="assignment_tooltip"
      style={{ display: 'flex' }}
      message={
        <div data-testid="assignment_popper" style={{ fontSize: '1rem' }}>
          <AssignedTo>Assigned to</AssignedTo>
          {activeMembers.map(({ projectMember: { member } }) => (
            <Tag style={{ backgroundColor: colors.primary10 }} key={member.id}>
              <small>{member.name}</small>
            </Tag>
          ))}
          {project.useRoles &&
            activeRoles.map(({ role }) => (
              <Tag style={{ backgroundColor: colors.grey5 }} key={role.id}>
                <BillableIcon value={role.isActuallyBillable} />
                <small>{role.name}</small>
              </Tag>
            ))}
        </div>
      }>
      <Tag style={{ backgroundColor: colors.grey5, color: colors.grey40 }}>
        <small>+{assignmentsCount}</small>
      </Tag>
    </Tooltip>
  );
}

export default function ProjectTasksListPage({ project, onChange }) {
  const documentTitle = useDocumentTitle();

  const api = useApi();
  const confirmation = useConfirmation();
  const toast = useToast();
  const { workspace } = useWorkspace();
  const { notify } = useSubscription();

  const [cloneTarget, setCloneTarget] = useState(null);
  const [drag, setDrag] = useState({ index: null, over: null });
  const [isLoading, setIsLoading] = useState(true);
  const [isSearchReady, setIsSearchReady] = useState(false);
  const [moveTaskId, setMoveTaskId] = useState(null);
  const [query, setQuery] = useState({ q: '', statusId: [], recordStatusId: 'active' });
  const [tasks, setTasks] = useState([]);
  const [timeEntryDrawer, setTimeEntryDrawer] = useState(null);

  const { path, url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const searchParamsConfig = useSearchParamsConfig();
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        q: { default: '' },
        statusId: searchParamsConfig.projectTaskStatuses,
        recordStatusId: { default: query.recordStatusId, ...searchParamsConfig.recordStatusId },
      }),
      [searchParamsConfig.recordStatusId, query.recordStatusId, searchParamsConfig.projectTaskStatuses],
    ),
    onChange: useCallback((params) => setQuery((query) => ({ ...query, ...params })), []),
  });

  useEffect(() => {
    if (isSearchReady) {
      return;
    }
    searchParams.get().then((params) => {
      setQuery((query) => ({ ...query, ...params }));
      setIsSearchReady(true);
    });
  }, [searchParams, isSearchReady]);

  const projectChanges = useMemo(() => {
    // TODO: there are other settings on the project that may require reloading the list, such as member
    // or role billable flags. Those are more complex to determine since they're in an array property.
    // Need to investigate what the best option is to do this deep-dependency check.
    //
    // If useRoles changes, the list needs to refresh to show the role assignments and estimated hours
    return {
      id: project.id,
      useRoles: project.useRoles,
    };
  }, [project.id, project.useRoles]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .projects(projectChanges.id)
        .tasks()
        .get({
          q: query.q || undefined,
          statusId: query.statusId?.map((v) => v.id),
          recordStatusId: query.recordStatusId || undefined,
        });
      setTasks(data);
    } catch (error) {
      setTasks([]);
    } finally {
      setIsLoading(false);
    }
  }, [api, projectChanges, query, workspace.id]);

  useEffect(() => {
    if (!isSearchReady) {
      return;
    }
    fetchData();
    return fetchData.cancel;
  }, [fetchData, project, isSearchReady]);

  const handleFilter = (values) => {
    setQuery((query) => ({ ...query, ...values }));
    searchParams.set({ ...values });
  };

  const dragDisabled = useMemo(
    () => !isSearchReady || moveTaskId || !!query.q || !_.isEmpty(query.statusId) || !!query.recordStatusId,
    [isSearchReady, moveTaskId, query],
  );

  const handleDragStart = (index) => {
    if (dragDisabled) {
      return;
    }
    setDrag({ index, over: null });
  };

  const handleDragEnd = () => {
    setDrag({ index: null, over: null });
  };

  const handleDragOver = (index) => {
    if (!drag || drag.over === index) {
      return;
    }
    setDrag({ ...drag, over: index });
    return false;
  };

  const handleDrop = async (index) => {
    if (drag.index === index) {
      return;
    }

    const oldIndex = drag.index;
    const newIndex = index;
    const task = tasks[oldIndex];
    const move = newIndex - oldIndex;

    if (!task) return;

    setMoveTaskId(task.id);
    setTasks((tasks) => arrayMove(tasks, oldIndex, newIndex));

    try {
      await api.www.workspaces(workspace.id).projects(project.id).tasks(task.id).order({ move });
    } catch ({ message }) {
      toast.error(`Could not reorder: ${message ? message : 'Error'}`);
      setTasks((tasks) => arrayMove(tasks, newIndex, oldIndex));
    } finally {
      setMoveTaskId(null);
    }
  };

  async function handleDelete({ id }) {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this task?</DeleteConfirmation>
    ));
    if (!confirm) {
      return;
    }
    try {
      await api.www.workspaces(workspace.id).projects(project.id).tasks(id).delete();
      setTasks((tasks) => tasks.filter((task) => task.id !== id));
      onChange && onChange();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  async function handleSave(values) {
    const { data } = await api.www
      .workspaces(workspace.id)
      .projects(project.id)
      .tasks()
      .get({
        ids: [values.id],
      });

    if (data.length > 0) {
      const updatedTask = data[0];

      setTasks((tasks) =>
        _.some(tasks, { id: updatedTask.id })
          ? tasks.map((task) => (task.id === updatedTask.id ? { ...task, ...updatedTask } : task))
          : [...tasks, updatedTask],
      );
    }
    onChange && onChange();
  }

  function handleView(task) {
    history.push({ pathname: `${url}/${task.number}/details`, search: location.search });
  }

  function handleEdit(task) {
    history.push({ pathname: `${url}/${task.number}/edit`, search: location.search });
  }

  async function handleCloned() {
    try {
      setCloneTarget(null);
      fetchData();
      onChange && onChange();
      toast.success('Cloned task successfully.');
    } catch ({ message }) {
      toast.error(message);
    }
  }

  function handleCloseDrawer() {
    history.push({ pathname: url, search: location.search });
    documentTitle.set(project.name);
  }

  const showRevenue = useMemo(
    () => project.permissions.viewRates && project.permissions.viewRevenue && project.billingTypeId === 'tm',
    [project],
  );

  const handleExport = async (filename, mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .projects(projectChanges.id)
          .tasks()
          .getExport(
            {
              q: query.q || undefined,
              statusId: query.statusId?.map((v) => v.id),
              recordStatusId: query.recordStatusId || undefined,
            },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  const currency = project.currency;

  return (
    <>
      {isSearchReady && (
        <ProjectTasksListFilters project={project} filters={query} onChange={handleFilter} onExport={handleExport} />
      )}

      <Page.ListView>
        <ListView>
          <ListView.Header>
            <ListView.Column width="0" isVisible={project.permissions.edit} />
            <ListView.Column width="3rem">
              <Icon icon="lock" color={colors.grey40} style={{ fontSize: '0.9rem' }} spaceLeft />
            </ListView.Column>
            <ListView.Column width="3rem" isVisible={project.isBillable}>
              <BillableIcon showTooltip={false} />
            </ListView.Column>
            <ListView.Column minWidth="16rem" sticky>
              Task Name
            </ListView.Column>
            <ListView.Column width="12rem">Task Code</ListView.Column>
            <ListView.Column width="7.25rem">Status</ListView.Column>
            <ListView.Column minWidth="14.5rem">Assigned To</ListView.Column>
            <ListView.Column width="12rem" isVisible={project.permissions.viewTimeAndExpenses}>
              Hours
            </ListView.Column>
            <ListView.Column width="12rem" isVisible={showRevenue}>
              Revenue
            </ListView.Column>
            <ListViewActions.Column />
          </ListView.Header>
          <ListView.Body fade={isLoading}>
            {tasks.map((task, index) => {
              const { id, name, code, status, isBillable, lockTime } = task;

              async function handleSetRecordStatus(recordStatusId) {
                try {
                  const { data } = await api.www
                    .workspaces(workspace.id)
                    .projects(project.id)
                    .tasks(id)
                    .setRecordStatus({ recordStatusId });
                  notify(useSubscription.keys.refresh_timer);
                  await handleSave(data);
                } catch ({ message }) {
                  toast.error(message);
                }
              }

              const handleArchive = async () => {
                const confirm = await confirmation.prompt((resolve) => (
                  <Confirmation resolve={resolve}>
                    Are you sure that you want to archive this project task?
                  </Confirmation>
                ));
                if (!confirm) return;

                handleSetRecordStatus('archived');
              };

              const handleUnarchive = async () => {
                const confirm = await confirmation.prompt((resolve) => (
                  <Confirmation resolve={resolve}>
                    Are you sure that you want to unarchive this project task?
                  </Confirmation>
                ));

                if (!confirm) return;

                handleSetRecordStatus('active');
              };

              return (
                <BoxRow
                  key={id}
                  draggable={index === drag?.index}
                  $dragging={index === drag?.index}
                  $over={index === drag?.over}
                  onDragEnd={handleDragEnd}
                  onDragOver={(e) => {
                    e.preventDefault();
                    handleDragOver(index);
                  }}
                  isDisabled={task.recordStatusId === 'archived'}
                  onDrop={() => handleDrop(index)}>
                  <ListView.Cell>
                    <Tooltip
                      message={
                        dragDisabled && moveTaskId !== id
                          ? moveTaskId
                            ? 'Wait for the current reorder to finish.'
                            : 'Clear the task filters to enable ordering'
                          : null
                      }>
                      {moveTaskId !== id ? (
                        <Handle
                          $disabled={dragDisabled}
                          onMouseDown={() => handleDragStart(index)}
                          onMouseUp={handleDragEnd}>
                          <Icon icon="grip-vertical" color={dragDisabled ? colors.grey10 : undefined} />
                        </Handle>
                      ) : (
                        <SpinnerContainer>
                          <Icon icon="spinner" spin={true} />
                        </SpinnerContainer>
                      )}
                    </Tooltip>
                  </ListView.Cell>
                  <ListView.Cell>
                    {lockTime && (
                      <Tooltip message="Time has been locked for this task.">
                        <Icon icon="lock" color={colors.grey40} spaceLeft />
                      </Tooltip>
                    )}
                  </ListView.Cell>
                  <ListView.Cell>
                    <BillableIcon value={isBillable} />
                  </ListView.Cell>
                  <ListView.Cell>{name}</ListView.Cell>
                  <ListView.Cell>{code}</ListView.Cell>
                  <ListView.Cell>
                    <Status status={status.id}>{status.name}</Status>
                  </ListView.Cell>
                  <ListView.Cell>
                    <Tags>
                      <FirstAssignment task={task} project={project} />
                      <Assignments task={task} project={project} />
                    </Tags>
                  </ListView.Cell>
                  <ListView.Cell>
                    <HoursProgress worked={task.hours} estimated={task.budgetHours} />
                  </ListView.Cell>
                  <ListView.Cell>
                    <RevenueProgress earned={task.revenue} budgeted={task.budgetRevenue} currency={currency} />
                  </ListView.Cell>
                  <ListViewActions>
                    {task.permissions.manage ? (
                      <ListViewActions.Edit onClick={() => handleEdit(task)} />
                    ) : (
                      <ListViewActions.View onClick={() => handleView(task)} />
                    )}
                    <hr />
                    <ListViewMenu>
                      {({ setIsOpen }) => {
                        return (
                          <>
                            <ListViewMenu.Item onClick={() => handleView(task)}>View</ListViewMenu.Item>
                            <ListViewMenu.Item
                              disabled={!task.permissions.manage}
                              tooltip={
                                !task.permissions.manage
                                  ? "Insufficient permissions to modify this project's tasks."
                                  : undefined
                              }
                              onClick={() => handleEdit(task)}>
                              Edit
                            </ListViewMenu.Item>
                            <ListViewMenu.Item
                              disabled={!task.permissions.manage}
                              onClick={() => {
                                setIsOpen(false);
                                setCloneTarget({ projectId: project.id, ...task });
                              }}>
                              Clone
                            </ListViewMenu.Item>
                            {task.recordStatusId === 'active' ? (
                              <ListViewMenu.Item
                                disabled={!task.permissions.manage}
                                tooltip={
                                  !task.permissions.manage
                                    ? 'Insufficient permissions to archive this project task.'
                                    : undefined
                                }
                                onClick={() => {
                                  setIsOpen(false);
                                  handleArchive(task);
                                }}>
                                Archive
                              </ListViewMenu.Item>
                            ) : (
                              <ListViewMenu.Item
                                disabled={!task.permissions.manage}
                                tooltip={
                                  !task.permissions.manage
                                    ? 'Insufficient permissions to unarchive this project task.'
                                    : undefined
                                }
                                onClick={() => {
                                  setIsOpen(false);
                                  handleUnarchive(task);
                                }}>
                                Unarchive
                              </ListViewMenu.Item>
                            )}
                            <ListViewMenu.Link
                              to={`/app/${workspace.key}/reports/time-entries?${new QueryString({
                                start: 'not_set',
                                end: 'not_set',
                                project: project.id,
                                projectTask: id,
                              })}`}>
                              View Time Entries
                            </ListViewMenu.Link>
                            <ListViewMenu.Item
                              onClick={() => setTimeEntryDrawer({ project, task })}
                              disabled={
                                !task.isProjectMember ||
                                task.project.recordStatusId !== 'active' ||
                                (task.forAssignedOnly ? !task.assignedToMe : false) ||
                                task.lockTime ||
                                project.lockTimeAndExpenses ||
                                task.recordStatusId === 'archived'
                              }
                              tooltip={(() => {
                                if (!task.isProjectMember)
                                  return "In order to track time to this task, you must be an active project team member of this task's project.";
                                if (task.project.recordStatusId !== 'active') return 'This project is archived.';
                                if (task.forAssignedOnly && !task.assignedToMe)
                                  return 'In order to track time to this task, you or your project role must be assigned to the task.';
                                if (project.lockTimeAndExpenses)
                                  return "This task's project does not allow adding new time entries.";
                                if (task.lockTime) return 'This task does not allow adding new time entries.';
                                if (task.recordStatusId === 'archived')
                                  return 'New time entries may not be associated with an archived task.';
                              })()}>
                              Track Time
                            </ListViewMenu.Item>
                            <ListViewMenu.DeleteItem
                              disabled={!task.permissions.manage}
                              tooltip={
                                !task.permissions.manage
                                  ? "Insufficient permissions to delete this project's tasks."
                                  : undefined
                              }
                              onCheckDependencies={async (workspace) =>
                                task.permissions.manage &&
                                (await workspace.projects(project.id).tasks(id).hasDependencies()).data
                              }
                              onClick={() => handleDelete(task)}>
                              Delete
                            </ListViewMenu.DeleteItem>
                          </>
                        );
                      }}
                    </ListViewMenu>
                  </ListViewActions>
                </BoxRow>
              );
            })}

            {tasks.length === 0 && <ListView.Empty />}
          </ListView.Body>

          <ListView.Status total={tasks.length} label="Task" isLoading={isLoading} />
        </ListView>
      </Page.ListView>

      {cloneTarget && (
        <ProjectTaskCloneConfirmation
          target={cloneTarget}
          onClose={() => setCloneTarget(null)}
          onSaved={handleCloned}
        />
      )}
      <Switch>
        <Route path={`${path}/new`}>
          {project.permissions.edit ? (
            <ProjectTaskCreateForm
              project={project}
              onSaved={(task) => {
                handleSave(task);
                history.push(`${url}/${task.number}/edit/new`);
              }}
              onClose={handleCloseDrawer}
            />
          ) : (
            <Redirect to={`/app/${workspace.key}/projects/${project.client.key}/${project.key}/tasks`} />
          )}
        </Route>
        <Route path={`${path}/add-from-templates`}>
          {project.permissions.edit ? (
            <ProjectTaskTemplatesDrawer project={project} onSaved={fetchData} onClose={handleCloseDrawer} />
          ) : (
            <Redirect to={`/app/${workspace.key}/projects/${project.client.key}/${project.key}/tasks`} />
          )}
        </Route>
        <Route path={`${path}/:taskId/edit`} exact>
          {project.permissions.edit ? (
            <ProjectTaskForm
              project={project}
              onDelete={handleDelete}
              onSaved={handleSave}
              onClose={handleCloseDrawer}
            />
          ) : (
            <Redirect to={`/app/${workspace.key}/projects/${project.client.key}/${project.key}/tasks`} />
          )}
        </Route>
        <Route path={`${path}/:taskId/edit/new`} exact>
          {project.permissions.edit ? (
            <ProjectTaskForm
              project={project}
              onDelete={handleDelete}
              onSaved={handleSave}
              onClose={handleCloseDrawer}
              onSaveAndNew={() => {
                documentTitle.set(project.name);
                history.replace(`/app/${workspace.key}/projects/${project.client.key}/${project.key}/tasks/new`);
              }}
              isNew
            />
          ) : (
            <Redirect to={`/app/${workspace.key}/projects/${project.client.key}/${project.key}/tasks`} />
          )}
        </Route>
        <Route path={`${path}/:taskId/details`}>
          <ProjectTaskDetails project={project} onClose={handleCloseDrawer} />
        </Route>
      </Switch>
      {timeEntryDrawer && (
        <EditTimeEntry
          initialValues={{
            date: moment().format(dateFormats.isoDate),
            projectId: timeEntryDrawer.project.id,
            projectTaskId: timeEntryDrawer.task.id,
          }}
          onSaved={(values) => {
            notify(useSubscription.keys.refresh_timer);
            values.task ? handleSave({ id: values.task.id }) : null;
          }}
          onClose={() => {
            setTimeEntryDrawer(null);
            documentTitle.set(project.name);
          }}
        />
      )}
    </>
  );
}
