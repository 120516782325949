import React from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

// Parent Container
const StatusSection = styled.div`
  display: flex;
  align-items: stretch; /* Allows children to stretch vertically */
  margin-top: 1rem;
  margin-bottom: 1rem;

  &:empty {
    display: none;
  }
`;

// Priority Indicator
const PriorityIndicator = styled.div`
  display: flex; /* Enable flex properties */
  align-items: center;
  justify-content: center;
  padding: 0.5em 1.5em;
  border-radius: 0.25rem;
  background-color: ${colors.grey10};
  color: ${colors.grey75};
  font-weight: bold;
  font-size: 0.75rem;
  text-transform: uppercase;
  white-space: nowrap;
  margin-right: 0.5rem;

  /* Flex properties to prevent shrinking and allow proper height */
  flex: 0 0 auto;
`;

// NextSteps Container
const NextSteps = styled.div`
  display: flex;
  flex-direction: column; /* Stack label and content vertically */
  flex: 1; /* Allow NextSteps to take up remaining space */
  padding: 0.75rem;
  border-radius: 0.25rem;
  background-color: ${colors.primary10};
  color: ${colors.accent};
  font-weight: ${weights.bold};
  font-size: 0.75rem;

  /* Optional: Add box-sizing to include padding in height calculations */
  box-sizing: border-box;
`;

// NextSteps Label
const NextStepsLabel = styled.div`
  margin-bottom: 0.25rem; /* Space between label and content */
  color: ${colors.accent};
  font-weight: ${weights.bold};
  text-transform: uppercase;
`;

// NextSteps Content
const NextStepsContent = styled.div`
  color: ${colors.grey100};
  font-weight: ${weights.normal};
  text-overflow: ellipsis;
`;

// Main Component
function OpportunityStatusSection({ data }) {
  return (
    <StatusSection>
      {data.priorityId && (
        <PriorityIndicator>
          {
            {
              high: 'High Priority',
              medium: 'Medium Priority',
              low: 'Low Priority',
            }[data.priorityId]
          }
        </PriorityIndicator>
      )}
      {data.nextSteps && (
        <NextSteps>
          <NextStepsLabel>Next Steps</NextStepsLabel>
          <NextStepsContent>{data.nextSteps}</NextStepsContent>
        </NextSteps>
      )}
    </StatusSection>
  );
}

export default OpportunityStatusSection;
