import { TooltipButton, Icon, FormMessage } from '~/components';
import React from 'react';
import { colors, weights } from '~/styles';
import _ from 'lodash';
import styled from 'styled-components';

const CircleButton = styled(TooltipButton)`
  width: 2.5rem;
  min-width: 2.5rem;
  max-width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  background: ${colors.grey5};
  transition: all 100ms ease-in-out;
  border: none;
  color: ${colors.primary};
  flex: 1;
  &:hover {
    background-color: ${colors.grey10};
    color: ${colors.primary};
  }
`;

const BaseIndicator = styled.div`
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 4px;
  background-color: ${colors.white};
  color: ${colors.grey100};
  border: 1px solid ${colors.grey40};
`;

const Separator = styled.hr`
  margin: 0.75rem 0;
  padding: 0rem 0rem 0rem 0rem;
`;

const SkippedResults = styled.div`
  padding: 0rem 0rem 0rem 0rem;
`;

const SkippedResultsBody = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SkipCodesList = styled.ul`
  margin: 0;
  padding: 0rem 0 0 1.5rem;
  font-size: 0.875rem;

  li {
    margin: 0.5rem 0;
  }
`;

const SkipCodeHeader = styled.div`
  font-weight: ${weights.bold};
  margin-top: 1rem;
`;

const SkipRowsList = styled.ul`
  color: ${colors.black};
  margin-top: 0rem;
  li {
    margin-top: 0rem;
  }
`;

const SkipDescription = styled.p`
  font-style: italic;
  font-size: 0.75rem;
  padding-bottom: 0.5rem;
`;

export const StyledErrorMessage = styled(FormMessage.Error)`
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid ${colors.error}; /* Use your theme's error color */
  border-radius: 4px;
  background-color: ${colors.white}; /* Lighter error background color */
  color: ${colors.grey100}; /* Error text color */

  ul {
    margin-top: 0.5rem;
    padding-left: 1.5rem; /* Indent for bullet points */
    list-style-type: disc; /* Bullet point style */
  }

  li {
    margin-bottom: 0.25rem; /* Space between bullet points */
  }
`;

const skipDescriptions = {
  previouslyImported: 'Import ID was used to check for duplicates, and found previously imported items.',
  invalidDate: 'One or more values provided for Date was invalid and the row was skipped.',
  memberEmailNotFound: 'Could not find members with the following email addresses.',
  duplicateClient: 'Client(s) with the same name already exist and were not duplicated.',
  invalidLedgerType: 'The Ledger provided was not valid.',
  invalidAmountRateQuantity: 'The amount did not match the calculated value from quantity and rate.',
  expenseCategoryNotFound: 'The expense category was not found.',
  missingImportId: 'The "Import ID" is required to be used for duplicate checks.',
  notesRequired: '"Notes" is required for "other" and "milestone" ledger items.',
};

export function ImportResults({ result }) {
  const { skipped } = result;
  const handleCopyToClipboard = () => {
    const textToCopy = _.map(skipped.reasons, (reason, key) => {
      const skipDescription = skipDescriptions[key];
      const rowsText = _.map(reason, (skippedRows, skipItemValue) => {
        const itemText =
          skipItemValue && skipItemValue != 'undefined'
            ? `"${skipItemValue}" on rows: ${skippedRows.rowNumber.join(', ')}`
            : `Review rows: ${skippedRows.rowNumber.join(', ')}`;

        return itemText;
      }).join('\n');
      return `Skip Code: ${key}\n${skipDescription ? `${skipDescription}\n` : ''}${rowsText}`;
    }).join('\n\n');

    navigator.clipboard.writeText(textToCopy).then(
      () => {
        // eslint-disable-next-line no-console
        console.log('Copied to clipboard');
      },
      (err) => {
        // eslint-disable-next-line no-console
        console.error('Could not copy text: ', err);
      },
    );
  };

  return (
    <BaseIndicator>
      <div>
        <Icon icon="check" color={colors.success}></Icon>&nbsp;&nbsp;
        <strong>{result.recordsCreated} record(s) were successfully imported.</strong>
      </div>
      <Separator />

      {skipped?.totalCount > 0 && (
        <SkippedResults>
          <Icon icon="exclamation-circle" color={colors.warning100}></Icon>&nbsp;&nbsp;
          <strong>{skipped.totalCount} record(s) were skipped during import.</strong>
          <Separator />
          <SkippedResultsBody>
            <SkipCodesList>
              {_.map(skipped.reasons, (reason, key) => {
                return (
                  <li key={key}>
                    <SkipCodeHeader>Skip Code: {key}</SkipCodeHeader>
                    <SkipDescription>{skipDescriptions[key]}</SkipDescription>
                    <SkipRowsList>
                      {_.map(reason, (skippedRows, skipItemValue) => {
                        return skipItemValue ? (
                          <li key={skipItemValue}>
                            {skipItemValue && skipItemValue != 'undefined'
                              ? `"${skipItemValue}" on rows: `
                              : 'Review rows: '}
                            {skippedRows.rowNumber.join(', ')}
                          </li>
                        ) : (
                          <li>Review rows: {skippedRows.rowNumber.join(', ')}</li>
                        );
                      })}
                    </SkipRowsList>
                  </li>
                );
              })}
            </SkipCodesList>

            <CircleButton onClick={handleCopyToClipboard} tooltip={'Copy skip information to clipboard'}>
              <Icon icon="clipboard" />
            </CircleButton>
          </SkippedResultsBody>
        </SkippedResults>
      )}
    </BaseIndicator>
  );
}
