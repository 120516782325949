import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import bamboohrIcon from '~/assets/public/bamboohr-icon.svg';
import {
  ActionButton,
  ActionsBanner,
  Buttons,
  CancelButton,
  Confirmation,
  DeleteButton,
  Field,
  Form,
  FormMessage,
  HelpTooltip,
  Icon,
  MemberTagMultiSelect,
  PromptNavigation,
  Radio,
  SkillMultiSelect,
  TimeOffTypeMultiSelect,
  Tooltip,
} from '~/components';
import { SectionTitle } from '~/components/Form';
import { useApi, useConfirmation, useMember, useWorkspace } from '~/contexts';
import { useDocumentTitle, useFeatures, useForm } from '~/hooks';
import { colors } from '~/styles';
import { dateFormats, emptyStringToNull, getFileBytes, getFileDataUrl, mergeValues } from '~/utils';
import { SettingsSection, SlackUserSelect } from './components';
import DeleteMemberDialog from './DeleteMemberDialog';
import DeactivateMemberConfirmation from '~/components/DeactivateMemberConfirmation';

const StyledForm = styled(Form)`
  flex: 1;
  display: flex;
  flex-direction: column;

  ${SectionTitle} {
    min-width: 17rem;
    flex: 0.6;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
`;

const ControlWithText = styled(Form.Control)`
  align-items: center;
`;

const SlackWrapper = styled.div`
  display: block;
`;

const WarningTooltip = styled(Tooltip)`
  flex: 0;
`;

const WarningIcon = styled(Icon)`
  font-size: 1.5rem;
`;

const BambooMessage = styled.div`
  display: flex;
`;

const BambooLogo = styled.img`
  display: block;
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
`;

function MemberDetailsForm({ onSaved, member, isSlackConnected, bamboohrIntegration }) {
  useDocumentTitle(`${member.name}`);

  const api = useApi();
  const { workspace, updateMember } = useWorkspace();
  const features = useFeatures();

  const [{ isSubmitting, saved, status, message }, form] = useForm();

  const { member: currentMember } = useMember();
  const isCurrentMember = currentMember.id === member.id;
  const isSampleMember = !!member.sampleDataId;
  const formRef = useRef();

  const confirmation = useConfirmation();
  const history = useHistory();

  async function handleSubmit(values) {
    try {
      const isMemberDeactivated = !values.isActive && member.isActive;

      form.submit();

      const omit = [
        'manager',
        'image',
        'imageUrl',
        'resumeFile',
        'resumeUrl',
        'discipline',
        'jobTitle',
        'location',
        'practice',
        'tags',
        'skills',
        'timeOffApprover',
        'timeOffTypes',
        'internalExpenseApprover',
        'overrideDefaultAuthProvider',
        'defaultAuthProvider',
      ];

      if (values.image !== undefined) {
        // Upload the image (which also sets the member's image URL)
        let file = null;
        if (values.image) {
          const bytes = await getFileBytes(values.image);
          const type = values.image.type;
          file = { bytes, type };
        }
        await api.www.workspaces(workspace.id).members(member.id).setImage(file);

        // Ignore the copy image from user flag since the image has been set already
        omit.push('copyImageFromUser');
      }

      if (values.resumeFile !== undefined) {
        let file = null;
        if (values.resumeFile) {
          file = {
            name: values.resumeFile.name,
            type: values.resumeFile.type,
            data: (await getFileDataUrl(values.resumeFile)).split(';base64,').pop(),
          };
        }
        await api.www.workspaces(workspace.id).members(member.id).setResume(file);
      }

      // For the current user, don't send the values for security role and active status
      if (isCurrentMember) {
        omit.push('securityRoleId', 'isActive');
      }

      const body = emptyStringToNull({
        ..._.omit(values, omit),
        managerId: values.manager?.id ?? null,
        disciplineId: values.discipline?.id ?? null,
        jobTitleId: values.jobTitle?.id ?? null,
        locationId: values.location?.id ?? null,
        practiceId: values.practice?.id ?? null,
        memberTagAssignments: values.tags.map((tag) => ({ memberTagId: tag.id })),
        memberSkills: values.skills.map((skill) => ({ skillId: skill.id })),
        memberTimeOffTypes: values.timeOffTypes.map((timeOffType) => ({ timeOffTypeId: timeOffType.id })),
        timeOffApproverId: {
          auto: null,
          manager: values.manager?.id,
          member: values.timeOffApprover?.id,
        }[values.timeOffApprovalMode],
        internalExpenseApproverId: {
          auto: null,
          manager: values.manager?.id,
          member: values.internalExpenseApprover?.id,
        }[values.internalExpenseApprovalMode],
        defaultAuthProviderId: values.overrideDefaultAuthProvider ? values.defaultAuthProvider?.id ?? null : null,
      });

      if (isMemberDeactivated) {
        const confirm = await confirmation.prompt((resolve) => (
          <DeactivateMemberConfirmation memberId={member.id} workspaceId={workspace.id} resolve={resolve} />
        ));
        if (!confirm) {
          return form.done();
        }
      }

      const { data } = await api.www.workspaces(workspace.id).members(member.id).upsert(body);

      await onSaved(data);
      form.save();

      if (isCurrentMember) {
        updateMember(data);
      }
    } catch ({ message }) {
      form.error({ message });
    }
  }

  const handleDelete = () => {
    confirmation.prompt((resolve) => (
      <DeleteMemberDialog
        onCheckDependencies={async (workspace) => (await workspace.members(member.id).getAssociationTypes()).data}
        member={member}
        onClose={resolve}
        onDelete={async () => {
          history.push(`/app/${workspace.key}/settings/members`);
          resolve(true);
        }}
      />
    ));
  };

  const initialValues = mergeValues(
    {
      activeStartDate: null,
      activeEndDate: null,
      automaticTimesheetSubmissionConfirmation: true,
      costMethodId: '',
      employmentTypeId: '',
      timesheetCapacityPolicy: 'unrestricted',
      holidayScheduleId: '',
      isBillable: true,
      isActive: true,
      loginEnabled: true,
      name: '',
      email: '',
      image: undefined,
      imageUrl: null,
      resumeFile: undefined,
      resumeUrl: member.resume ? member.resume.url : null,
      jobTitle: null,
      internalId: '',
      internalNotes: '',
      location: null,
      manager: null,
      discipline: null,
      defaultRate: member.defaultRate || '',
      defaultRateCurrency: member.defaultRateCurrency,
      practice: null,
      receiveMissingTimeReminders: false,
      unsubmittedTimesheetReminders: false,
      securityRoleId: '',
      slackUserId: '',
      tags: [],
      skills: [],
      timeOffAllowed: false,
      allowedTimeOffTypes: 'all',
      timeOffTypes: [],
      timeOffApprovalMode: 'auto',
      timeOffApprover: null,
      internalExpenseApprovalMode: 'auto',
      internalExpenseApprover: null,
      overrideDefaultAuthProvider: !!member.defaultAuthProviderId,
      defaultAuthProvider: null,
    },
    member,
  );

  return (
    <SettingsSection>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          activeEndDate: Yup.date()
            .label('Active End Date')
            .nullable()
            .when('activeStartDate', (activeStartDate, schema) =>
              activeStartDate
                ? schema.min(Yup.ref('activeStartDate'), 'Active End Date must be after Active Start Date')
                : schema,
            ),
          activeStartDate: Yup.date().label('Active Start Date').nullable(),
          internalId: Yup.string().label('Internal ID').max(255),
          internalNotes: Yup.string().label('Member Notes').max(5000),
          defaultRate: Yup.number().label('Default Bill Rate').min(0).max(99999999999).nullable(),
          defaultRateCurrency: Yup.string().label('Default Rate Currency').nullable().required(),
          name: Yup.string().label('Name').max(255).required(),
          email: Yup.string().label('Email').email().required(),
          securityRoleId: Yup.string().label('Security Role').required(),
          employmentTypeId: Yup.string().label('Member Type').oneOf(['employee', 'contractor', 'other']).required(),
          holidayScheduleId: Yup.string().label('Holiday Schedule'),
          timeOffApprover: Yup.object()
            .label('Time Off Approver')
            .nullable()
            .when('timeOffApprovalMode', {
              is: 'member',
              then: (schema) => schema.required(),
            }),
          internalExpenseApprover: Yup.object()
            .label('Internal Expense Approver')
            .nullable()
            .when('internalExpenseApprovalMode', {
              is: 'member',
              then: (schema) => schema.required(),
            }),
          defaultAuthProvider: Yup.object()
            .label('Default Authentication Provider')
            .nullable()
            .when('overrideDefaultAuthProvider', {
              is: true,
              then: Yup.object().required('Default Authentication Provider is required').nullable(),
            }),
        })}>
        {({ resetForm, dirty, values, setFieldValue, submitForm, setValues }) => {
          const handleCancel = async () => {
            const confirm = await confirmation.prompt((resolve) => (
              <Confirmation resolve={resolve}>This will discard all changes. Are you sure?</Confirmation>
            ));
            if (!confirm) return;

            resetForm();
          };

          const handleManagerChange = ({ target: { value } }) => {
            const newValues = { ...values, manager: value };

            if (!value && values.timeOffApprovalMode === 'manager') {
              newValues.timeOffApprovalMode = 'auto';
            }

            if (!value && values.internalExpenseApprovalMode === 'manager') {
              newValues.internalExpenseApprovalMode = 'auto';
            }

            setValues(newValues);
          };

          const handleMemberStatusChange = ({ target: { value } }) => {
            setValues({
              ...values,
              isActive: value === 'true' ? true : false,
              activeEndDate: value === 'true' ? null : moment().format(dateFormats.isoDate),
            });
          };

          return (
            <StyledForm>
              <Form.Section
                title="Identity"
                subtitle="The team member's name, email address, photo and login settings.">
                <Form.Control>
                  {values.isActive ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Field.Checkbox name="loginEnabled" label="Login enabled" disabled={isSampleMember} />
                      <HelpTooltip
                        style={{ marginLeft: '1rem' }}
                        message="Allow this member to log in to this workspace."
                      />
                    </div>
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Field.Checkbox disabled name="loginEnabled" label="Login enabled" checked={false} />
                      <HelpTooltip
                        style={{ marginLeft: '1rem' }}
                        message="Allow this member to log in to this workspace."
                      />
                    </div>
                  )}
                </Form.Control>

                <Form.Control>
                  <Field.Text autoFocus name="name" placeholder="Name" errorPlacement="left" maxLength={255} />
                </Form.Control>
                <Form.Control>
                  <Field.Text
                    name="email"
                    type="email"
                    placeholder="Email"
                    errorPlacement="left"
                    disabled={isSampleMember}
                  />
                </Form.Control>
                <Form.Control>
                  <Field.AvatarFileInput
                    name="image"
                    previewUrlFieldName="imageUrl"
                    preview={{ imageUrl: values.imageUrl, name: member.name }}
                    size={120}
                    variant="circle"
                  />
                </Form.Control>
              </Form.Section>
              {isSlackConnected && (
                <Form.Section
                  id="slack-identity"
                  title="Slack Identity"
                  subtitle="The member's user identity within Slack.">
                  <Form.Control>
                    <SlackWrapper>
                      <SlackUserSelect name="slackUserId" />
                    </SlackWrapper>
                  </Form.Control>
                </Form.Section>
              )}
              <Form.Section
                title="Member Type"
                subtitle="Fulltime employees, or contractors with a fixed cost, should be set to the fixed labor cost option.">
                <Form.Control>
                  <Field.RadioGroup name="employmentTypeId">
                    <Radio value="employee" label="Employee" />
                    <Radio value="contractor" label="Contractor" />
                    <Radio value="other" label="Other" />
                  </Field.RadioGroup>
                </Form.Control>

                <Form.Control>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Field.RadioGroup name="costMethodId">
                      <Radio value="hourly" label="Hourly labor cost" />
                      <Radio value="fixed" label="Fixed labor cost" />
                    </Field.RadioGroup>

                    <HelpTooltip
                      message="Salaried employees should be set to fixed labor cost."
                      style={{ marginLeft: '1rem' }}
                    />
                  </div>
                </Form.Control>

                <Form.Control>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Field.Checkbox name="isBillable" label="This is a billable member" />

                    <HelpTooltip
                      message="Your workspace is only charged a fee for billable members."
                      style={{ marginLeft: '1rem' }}
                    />
                  </div>
                </Form.Control>
                {values.isBillable && (
                  <Form.Control help="The default bill rate will be used when adding this member to a project that does not use roles.">
                    <Field.WorkspaceCurrencySelect
                      name="defaultRateCurrency"
                      data-testid="default_rate_currency_select"
                      clearable={false}
                      placeholder="Default Bill Rate Currency"
                      disabled={!features.multicurrency && member.defaultRateCurrency === workspace.currency}
                    />

                    <Field.Currency
                      name="defaultRate"
                      materialPlaceholder="Default Bill Rate"
                      materialAlwaysVisible
                      precision={7}
                      currency={values.defaultRateCurrency}
                    />
                  </Form.Control>
                )}
              </Form.Section>
              <Form.Section
                title="Status"
                subtitle="Members that are no longer working with your team should be set to inactive.">
                <Form.Control>
                  <div style={{ flex: 'unset' }}>
                    <Field.RadioGroup name="isActive" onChange={handleMemberStatusChange}>
                      <Radio value={true} label="Active" disabled={isCurrentMember} />
                      <Radio value={false} label="Inactive" disabled={isCurrentMember} />
                    </Field.RadioGroup>
                  </div>
                  {bamboohrIntegration?.settings?.updateMemberStatus === true && (
                    <WarningTooltip
                      message={
                        <BambooMessage>
                          <BambooLogo src={bamboohrIcon} />
                          Your workspace is integrated with BambooHR. Any sync performed for this member may override
                          this setting.
                        </BambooMessage>
                      }>
                      <WarningIcon icon="exclamation-triangle" color={colors.warning} />
                    </WarningTooltip>
                  )}
                </Form.Control>
              </Form.Section>
              <Form.Section
                title="Active Date Range"
                subtitle="For members who are no longer working for the company, ensure that the End Date aligns with their last date of employment.">
                <ControlWithText>
                  <Field.DayPicker name="activeStartDate" placeholder="Start Date" />
                  to
                  <Field.DayPicker name="activeEndDate" placeholder="End Date" errorPlacement="left" />
                </ControlWithText>
              </Form.Section>
              <Form.Section
                title="Security Role"
                subtitle="The security role determines this member's privileges within the workspace.">
                <Field.SecurityRoleSelect
                  name="securityRoleId"
                  placeholder="Security Role"
                  disabled={isCurrentMember}
                  initialValue={member.securityRole}
                />
              </Form.Section>
              {values.loginEnabled && (
                <Form.Section
                  title="Authentication"
                  subtitle="Select the default authentication provider for this member. ">
                  <Form.Control>
                    <Field.RadioGroup name="overrideDefaultAuthProvider">
                      <Radio value={false} label="Use the workspace default authentication provider" />
                      <Radio value={true} label="Use a specific authentication provider" />
                    </Field.RadioGroup>
                  </Form.Control>

                  {values.overrideDefaultAuthProvider && (
                    <Form.Control>
                      <Field.AuthProviderSelect
                        name="defaultAuthProvider"
                        placeholder="Default Authentication Provider"
                      />
                    </Form.Control>
                  )}
                </Form.Section>
              )}
              <Form.Section
                title="Holiday Schedule"
                subtitle="The holidays in the selected holiday schedule will be shown on this member's time screens.">
                <Field.HolidayScheduleSelect
                  name="holidayScheduleId"
                  placeholder="Holiday Schedule"
                  showEmptyOption={true}
                  holidayScheduleId={initialValues.holidayScheduleId}
                />
              </Form.Section>
              <Form.Section
                title="Organization"
                subtitle="The member's organizational information including job title, manager, tags, etc.">
                <Form.Control>
                  <Field.JobTitleSelect name="jobTitle" placeholder="Job Title" allowNew position="top" />
                </Form.Control>
                {features.practices && (
                  <Form.Control>
                    <Field.PracticeSelect name="practice" placeholder="Practice" allowNew position="top" />
                  </Form.Control>
                )}
                {features.disciplines && (
                  <Form.Control>
                    <Field.DisciplineSelect name="discipline" placeholder="Discipline" allowNew position="top" />
                  </Form.Control>
                )}
                <Form.Control>
                  <Field.MemberSelect
                    name="manager"
                    placeholder="Manager"
                    initialValue={member.manager}
                    onChange={handleManagerChange}
                  />
                </Form.Control>
                <Form.Control>
                  <Field.Control>
                    <MemberTagMultiSelect
                      name="tags"
                      placeholder="Tags"
                      value={values.tags}
                      allowNew
                      onChange={({ target: { value } }) => setFieldValue('tags', value)}
                    />
                  </Field.Control>
                </Form.Control>
                <Form.Control>
                  <Field.LocationSelect name="location" placeholder="Location" allowNew position="top" />
                </Form.Control>
                <Form.Control help="If your accounting or HR platform has a unique identifier for this member, you can enter it here.">
                  <Field.Text name="internalId" placeholder="Internal ID" maxLength={255} />
                </Form.Control>

                <Form.Control help="These internal notes are not displayed anywhere within Ruddr.">
                  <Field.TextArea name="internalNotes" placeholder="Internal Notes" maxLength={5000} />
                </Form.Control>
              </Form.Section>

              <Form.Section
                title="Skills and Resume"
                subtitle="The member's set of skills and resume. This resume will only be viewable by members with a security role that provides administrative access to resource allocations.">
                <Field.Control>
                  <SkillMultiSelect
                    name="skills"
                    placeholder="Skills"
                    value={values.skills}
                    allowNew
                    onChange={({ target: { value } }) => setFieldValue('skills', value)}
                  />
                </Field.Control>
                <Field.Control>
                  <Field.ResumeFileInput resume={member.resume} urlFieldName="resumeUrl" name="resumeFile" />
                </Field.Control>
              </Form.Section>

              <Form.Section title="Time Tracking" subtitle="Various settings related to time tracking.">
                {features.timesheets && (
                  <Form.Control>
                    <Field.RadioGroup name="timesheetCapacityPolicy" direction="vertical">
                      <Radio
                        value="unrestricted"
                        label="This member has no minimum time requirement for timesheet submission"
                      />
                      <Radio
                        value="timesheet"
                        label="This member must meet or exceed their capacity hours for every timesheet period"
                      />
                      <Radio
                        value="week"
                        label="This member must meet or exceed their capacity hours for every calendar week"
                      />
                    </Field.RadioGroup>
                  </Form.Control>
                )}

                <Form.Control>
                  <Field.Checkbox name="timeOffAllowed" label="This member can track time off" />
                </Form.Control>

                {values.timeOffAllowed && (
                  <>
                    <Form.Control>
                      <Field.RadioGroup name="allowedTimeOffTypes" direction="vertical">
                        <Radio value="all" label="All time off types" />
                        <Radio value="custom" label="Specific time off types" />
                      </Field.RadioGroup>
                    </Form.Control>

                    {values.allowedTimeOffTypes === 'custom' && (
                      <Form.Control style={{ marginLeft: '2rem' }}>
                        <TimeOffTypeMultiSelect
                          name="timeOffTypes"
                          placeholder="Time Off Types"
                          value={values.timeOffTypes}
                          onChange={({ target: { value } }) => setFieldValue('timeOffTypes', value)}
                        />
                      </Form.Control>
                    )}
                  </>
                )}

                <Form.Control>
                  <Field.RadioGroup name="timeOffApprovalMode" direction="vertical">
                    <Radio value="auto" label="Time off is automatically approved" />
                    <Radio
                      value="manager"
                      label="Time off is approved by this member's manager"
                      disabled={!values.manager}
                    />
                    <Radio value="member" label="Time off is approved by this member:" />
                  </Field.RadioGroup>
                </Form.Control>

                <Form.Control style={{ marginLeft: '2rem', maxWidth: '22rem' }}>
                  <Field.MemberSelect
                    name="timeOffApprover"
                    disabled={values.timeOffApprovalMode !== 'member'}
                    clearable={false}
                  />
                </Form.Control>
              </Form.Section>

              {features.expenseReporting && (
                <Form.Section
                  title="Internal Expenses"
                  subtitle="If an internal expense does not have an associated project, specify how the expense should be approved.">
                  <Form.Control>
                    <Field.RadioGroup name="internalExpenseApprovalMode" direction="vertical">
                      <Radio value="auto" label="Internal expenses are automatically approved" />
                      <Radio
                        value="manager"
                        label="Internal expenses are approved by this member's manager"
                        disabled={!values.manager}
                      />
                      <Radio value="member" label="Internal expenses are approved by this member:" />
                    </Field.RadioGroup>
                  </Form.Control>

                  <Form.Control style={{ marginLeft: '2rem', maxWidth: '22rem' }}>
                    <Field.MemberSelect
                      name="internalExpenseApprover"
                      disabled={values.internalExpenseApprovalMode !== 'member'}
                      clearable={false}
                    />
                  </Form.Control>
                </Form.Section>
              )}

              <Form.Section title="Notifications" subtitle="The types of notifications that this member can receive.">
                <Form.Control>
                  <Field.Checkbox name="receiveMissingTimeReminders" label="Missing Time Reminders" />
                </Form.Control>

                {features.timesheets && (
                  <>
                    <Form.Control>
                      <Field.Checkbox name="unsubmittedTimesheetReminders" label="Timesheet Submission Reminders" />
                    </Form.Control>
                    <Form.Control>
                      <Field.Checkbox
                        name="automaticTimesheetSubmissionConfirmation"
                        label="Automatic Timesheet Submission Confirmation"
                      />
                    </Form.Control>
                  </>
                )}
              </Form.Section>

              <ActionsBanner style={{ justifyContent: 'space-between' }}>
                <DeleteButton onClick={handleDelete}>Delete</DeleteButton>
                <ActionsContainer>
                  {status && <FormMessage.Error spaceRight>{message}</FormMessage.Error>}
                  <Buttons>
                    <CancelButton disabled={!dirty} onClick={handleCancel}>
                      Cancel
                    </CancelButton>
                    <ActionButton ok={saved} isLoading={isSubmitting} onClick={submitForm}>
                      Save
                    </ActionButton>
                  </Buttons>
                </ActionsContainer>
              </ActionsBanner>
              <PromptNavigation when={dirty} />
            </StyledForm>
          );
        }}
      </Formik>
    </SettingsSection>
  );
}

export default MemberDetailsForm;
