import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { Icon, InlineTooltip } from '~/components';
import { colors } from '~/styles';

const StyledLink = styled(Link)`
  margin-left: auto;
  align-self: center;
  background-color: ${colors.grey5};
  width: 2rem;
  height: 2rem;
  border-radius: 999rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  border: none;
  padding: 0;
  position: relative;

  &:hover {
    background-color: ${colors.grey10};
  }

  transition: all 100ms ease-in-out;
`;

export default function IconLink({ icon, children, type, tooltip, ...props }) {
  return (
    <StyledLink {...props}>
      <Icon icon={icon} type={type} />
      {tooltip && <InlineTooltip message={tooltip} delay={500} />}
      {children}
    </StyledLink>
  );
}
