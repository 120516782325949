import { useAuth } from '~/hooks';
import PerformanceByClient from './PerformanceByClient';
import PerformanceByClientPractice from './PerformanceByClientPractice';
import PerformanceByMember from './PerformanceByMember';
import PerformanceByMemberPractice from './PerformanceByMemberPractice';
import PerformanceByProject from './PerformanceByProject';
import PerformanceByProjectAndMember from './PerformanceByProjectAndMember';
import PerformanceByProjectAndTask from './PerformanceByProjectAndTask';
import PerformanceByProjectPractice from './PerformanceByProjectPractice';
import PerformanceByProjectType from './PerformanceByProjectType';

export default function usePerformanceReports() {
  const auth = useAuth();

  return [
    auth.reports.performanceByClient && {
      key: 'performance_by_client',
      name: 'Performance by Client',
      description:
        'The high-level performance of each client including the hours, realization rate, services revenue, effective bill rate, cost, profit and margin.',
      path: '/performance-by-client',
      component: PerformanceByClient,
    },
    auth.reports.performanceByClientPractice && {
      key: 'performance_by_client_practice',
      name: 'Performance by Client Practice',
      description:
        'The high-level performance of each client grouped by its practice, including the hours, realization rate, earned revenue, effective bill rate, cost, profit and margin.',
      path: '/performance-by-client-practice',
      component: PerformanceByClientPractice,
    },
    auth.reports.performanceByMember && {
      key: 'performance_by_member',
      name: 'Performance by Member',
      description:
        'The high-level performance of each member including the hours, realization rate, services revenue, effective bill rate, cost, profit and margin.',
      path: '/performance-by-member',
      component: PerformanceByMember,
    },
    auth.reports.performanceByMemberPractice && {
      key: 'performance_by_member_practice',
      name: 'Performance by Member Practice',
      description:
        'The high-level performance of each member grouped by its practice, including the hours, realization rate, services revenue, effective bill rate, cost, profit and margin.',
      path: '/performance-by-member-practice',
      component: PerformanceByMemberPractice,
    },
    auth.reports.performanceByProject && {
      key: 'performance_by_project',
      name: 'Performance by Project',
      description:
        'The high-level performance of each project including the hours, realization rate, budget revenue, earned revenue, budget left, effective bill rate, cost, profit and margin.',
      path: '/performance-by-project',
      component: PerformanceByProject,
    },
    auth.reports.performanceByProjectAndMember && {
      key: 'performance_by_project_and_member',
      name: 'Performance by Project and Member',
      description:
        'The high-level performance of each project and its members, including the hours, realization rate, revenue, effective bill rate, cost, profit and margin.',
      path: '/performance-by-project-and-member',
      component: PerformanceByProjectAndMember,
    },
    auth.reports.performanceByProjectAndTask && {
      key: 'performance_by_project_and_task',
      name: 'Performance by Project and Task',
      description:
        'The high-level performance of each project and its tasks, including the hours, realization rate, services revenue, effective bill rate, labor cost, services profit and services margin.',
      path: '/performance-by-project-and-task',
      component: PerformanceByProjectAndTask,
    },
    auth.reports.performanceByProjectPractice && {
      key: 'performance_by_project_practice',
      name: 'Performance by Project Practice',
      description:
        'The high-level performance of each project grouped by its practice, including the hours, realization rate, earned revenue, effective bill rate, cost, profit and margin.',
      path: '/performance-by-project-practice',
      component: PerformanceByProjectPractice,
    },
    auth.reports.performanceByProjectType && {
      key: 'performance_by_project_type',
      name: 'Performance by Project Type',
      description:
        'The high-level performance of each project grouped by its type, including the hours, realization rate, earned revenue, effective bill rate, cost, profit and margin.',
      path: '/performance-by-project-type',
      component: PerformanceByProjectType,
    },
  ].filter(Boolean);
}
