import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { Formik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import {
  Button,
  Buttons,
  CancelButton,
  Drawer,
  Field,
  Form,
  FormMessage,
  HelpTooltip,
  Radio,
  Stack,
} from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useDirtyCheck, useFeatures, useForm } from '~/hooks';
import { emptyStringToNull } from '~/utils';

const CheckboxHelp = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxHelpTooltip = styled(HelpTooltip)`
  margin-left: 0.5rem;
`;

function MemberInviteForm({ onClose, onInvited }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const features = useFeatures();
  const [{ isSubmitting, message, status }, form] = useForm();
  const drawerRef = useRef();
  const formRef = useRef();
  const firstFieldRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const [initialSecurityRoleId, setInitialSecurityRoleId] = useState('');
  const [initialHolidayScheduleId, setInitialHolidayScheduleId] = useState('');

  async function handleSubmit(values, closeDrawer) {
    try {
      form.submit();

      const omit = [
        'discipline',
        'practice',
        'manager',
        'jobTitle',
        'overrideDefaultAuthProvider',
        'defaultAuthProvider',
      ];
      const body = emptyStringToNull({
        ..._.omit(values, omit),
        disciplineId: values.discipline?.id ?? null,
        jobTitleId: values.jobTitle?.id ?? null,
        managerId: values.manager?.id ?? null,
        practiceId: values.practice?.id ?? null,
        defaultAuthProviderId:
          values.loginEnabled && values.overrideDefaultAuthProvider ? values.defaultAuthProvider?.id ?? null : null,
      });
      await api.www.workspaces(workspace.id).members().invite(body);

      closeDrawer();
      await onInvited();
    } catch ({ status, message }) {
      drawerRef.current.scrollTo({ top: 0 });
      return form.error({ message });
    }
  }

  return (
    <Drawer
      isOpen
      title="Add Member"
      ref={drawerRef}
      onOpen={() => firstFieldRef.current && firstFieldRef.current.focus()}
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={onClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        return (
          <Formik
            innerRef={formRef}
            initialValues={{
              costMethodId: 'fixed',
              defaultRate: '',
              defaultRateCurrency: workspace.currency,
              discipline: null,
              email: '',
              employmentTypeId: 'employee',
              holidayScheduleId: initialHolidayScheduleId,
              isBillable: true,
              jobTitle: null,
              loginEnabled: true,
              manager: null,
              name: '',
              practice: null,
              receiveMissingTimeReminders: true,
              unsubmittedTimesheetReminders: true,
              securityRoleId: initialSecurityRoleId,
              sendEmail: true,
              timeOffAllowed: true,
              overrideDefaultAuthProvider: false,
              defaultAuthProvider: null,
            }}
            enableReinitialize
            onSubmit={(values) => handleSubmit(values, closeDrawer)}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              defaultRate: Yup.number().label('Default Bill Rate').min(0).max(99999999999).nullable(),
              defaultRateCurrency: Yup.string().label('Default Rate Currency').nullable().required(),
              email: Yup.string()
                .label('Email')
                .email()
                .test('validate-email', '', async function (email) {
                  // We need to create the error with createError so that we can use "email" in the message.
                  // If we use the argument message of test(name, message, func) to create the message we cannot display the email.
                  const { path, createError } = this;

                  const isEmailValid = await Yup.object()
                    .shape({
                      email: Yup.string().email().required(),
                    })
                    .isValid({ email });

                  if (!isEmailValid) return true;

                  try {
                    await api.www.workspaces(workspace.id).members().isEmailAvailable({ email });
                    return true;
                  } catch {
                    return createError({
                      path,
                      message: `A member with email ${email} already exists in this workspace.`,
                    });
                  }
                })
                .required(),
              employmentTypeId: Yup.string().label('Member Type').required(),
              name: Yup.string().label('Name').max(255).required(),
              securityRoleId: Yup.string().label('Security Role').required(),
              holidayScheduleId: Yup.string().label('Holiday Schedule'),
              defaultAuthProvider: Yup.object()
                .label('Default Authentication Provider')
                .nullable()
                .when('overrideDefaultAuthProvider', {
                  is: true,
                  then: Yup.object().required('Default Authentication Provider is required').nullable(),
                }),
            })}>
            {(formik) => {
              const handleLoginEnabledChange = ({ target: { checked } }) => {
                formik.setValues({
                  ...formik.values,
                  loginEnabled: checked,
                  sendEmail: checked,
                });
              };

              const handleEmploymentTypeChange = ({ target: { value } }) => {
                const values = { ...formik.values, employmentTypeId: value };

                switch (values.employmentTypeId) {
                  case 'employee':
                    values.costMethodId = 'fixed';
                    values.timeOffAllowed = true;
                    values.receiveMissingTimeReminders = true;
                    values.unsubmittedTimesheetReminders = true;
                    break;

                  case 'contractor':
                  case 'other':
                    values.costMethodId = 'hourly';
                    values.timeOffAllowed = false;
                    values.receiveMissingTimeReminders = false;
                    values.unsubmittedTimesheetReminders = false;
                    break;
                }

                formik.setValues(values);
              };

              const handleLaborCostMethodChange = ({ target: { value } }) => {
                const values = { ...formik.values, costMethodId: value };

                switch (values.costMethodId) {
                  case 'fixed':
                    values.timeOffAllowed = true;
                    values.receiveMissingTimeReminders = true;
                    values.unsubmittedTimesheetReminders = true;
                    break;

                  case 'hourly':
                    values.timeOffAllowed = false;
                    values.receiveMissingTimeReminders = false;
                    values.unsubmittedTimesheetReminders = false;
                    break;
                }

                formik.setValues(values);
              };

              return (
                <Stack data-testid="invite_member_form">
                  {status && <FormMessage.Error spaceTop>{message}</FormMessage.Error>}

                  <Form.Section
                    title="Identity"
                    subtitle="Provide the new member's name, email address, and login settings.">
                    <Form.Control>
                      <Field.Text ref={firstFieldRef} name="name" placeholder="Name" maxLength={255} />
                    </Form.Control>

                    <Form.Control>
                      <Field.Text
                        name="email"
                        type="email"
                        placeholder="Email"
                        onBlur={() => {
                          formik.setFieldTouched('email', true, false);
                          formik.validateField('email');
                        }}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.Checkbox
                        name="loginEnabled"
                        label="Allow this member to log in to this workspace"
                        onChange={handleLoginEnabledChange}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.Checkbox
                        name="sendEmail"
                        label="Send an email invitation to this member now"
                        disabled={!formik.values.loginEnabled}
                      />
                    </Form.Control>
                  </Form.Section>

                  {formik.values.loginEnabled && (
                    <Form.Section
                      title="Authentication"
                      subtitle="Select the default authentication provider for this member. ">
                      <Form.Control>
                        <Field.RadioGroup name="overrideDefaultAuthProvider">
                          <Radio value={false} label="Use the workspace default authentication provider" />
                          <Radio value={true} label="Use a specific authentication provider" />
                        </Field.RadioGroup>
                      </Form.Control>

                      {formik.values.overrideDefaultAuthProvider && (
                        <Form.Control>
                          <Field.AuthProviderSelect
                            name="defaultAuthProvider"
                            placeholder="Default Authentication Provider"
                          />
                        </Form.Control>
                      )}
                    </Form.Section>
                  )}

                  <Form.Section
                    title="Settings"
                    subtitle="Assign key member settings such as billable status, security role, and member type.">
                    <Form.Control>
                      <CheckboxHelp>
                        <Field.Checkbox name="isBillable" label="This is a billable member" />
                        <CheckboxHelpTooltip message="Non-billable workspace members are free." />
                      </CheckboxHelp>
                    </Form.Control>

                    {formik.values.isBillable && (
                      <Form.Control help="The default bill rate will be used when adding this member to a project that does not use roles.">
                        <Field.WorkspaceCurrencySelect
                          name="defaultRateCurrency"
                          clearable={false}
                          placeholder="Default Bill Rate Currency"
                          disabled={!features.multicurrency}
                        />

                        <Field.Currency
                          name="defaultRate"
                          materialPlaceholder="Default Bill Rate"
                          materialAlwaysVisible
                          precision={7}
                          currency={formik.values.defaultRateCurrency}
                        />
                      </Form.Control>
                    )}

                    <Form.Control>
                      <Field.SecurityRoleSelect
                        name="securityRoleId"
                        placeholder="Security Role"
                        useDefault={true}
                        onDefault={(defaultValue) => setInitialSecurityRoleId(defaultValue)}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.EmploymentTypeSelect
                        name="employmentTypeId"
                        placeholder="Member Type"
                        onChange={handleEmploymentTypeChange}
                      />
                    </Form.Control>

                    <Form.Control>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Field.RadioGroup name="costMethodId" onChange={handleLaborCostMethodChange}>
                          <Radio value="hourly" label="Hourly labor cost" />
                          <Radio value="fixed" label="Fixed labor cost" />
                        </Field.RadioGroup>

                        <HelpTooltip
                          message="Salaried employees should be set to fixed labor cost."
                          style={{ marginLeft: '1rem' }}
                        />
                      </div>
                    </Form.Control>

                    <Form.Control>
                      <Field.Checkbox name="timeOffAllowed" label="This member can track time off" />
                    </Form.Control>

                    <Form.Control>
                      <Field.Checkbox
                        name="receiveMissingTimeReminders"
                        label="This member can receive missing time reminders"
                      />
                    </Form.Control>

                    {features.timesheets && (
                      <Form.Control>
                        <Field.Checkbox
                          name="unsubmittedTimesheetReminders"
                          label="This member can receive timesheet submission reminders"
                        />
                      </Form.Control>
                    )}

                    <Form.Control>
                      <Field.HolidayScheduleSelect
                        name="holidayScheduleId"
                        placeholder="Holiday Schedule"
                        showEmptyOption={true}
                        onDefault={(id) => setInitialHolidayScheduleId(id)}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.JobTitleSelect name="jobTitle" placeholder="Job Title" allowNew position="top" />
                    </Form.Control>

                    {features.practices && (
                      <Form.Control>
                        <Field.PracticeSelect name="practice" placeholder="Practice" allowNew />
                      </Form.Control>
                    )}

                    {features.disciplines && (
                      <Form.Control>
                        <Field.DisciplineSelect name="discipline" placeholder="Discipline" allowNew />
                      </Form.Control>
                    )}

                    <Form.Control>
                      <Field.MemberSelect name="manager" placeholder="Manager" />
                    </Form.Control>
                  </Form.Section>

                  <Drawer.Actions>
                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                      <Button isLoading={isSubmitting} onClick={formik.submitForm}>
                        Add Member
                      </Button>
                    </Buttons>
                  </Drawer.Actions>
                </Stack>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}

export default MemberInviteForm;
