import Big from 'big.js';
import _ from 'lodash';

const calculateExpenseCost = ({ billableAmount, nonBillableAmount, isBillable }) => {
  if (!_.isNumber(billableAmount) && !_.isNumber(nonBillableAmount)) return '';

  if (!isBillable) return nonBillableAmount;

  return Big(billableAmount || 0)
    .add(nonBillableAmount || 0)
    .toNumber();
};

export default calculateExpenseCost;
