import { Field, Form, Radio } from '~/components';
import React from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import MonthlyBudgetExpensesTable from './MonthlyBudgetExpensesTable';
import BudgetForm from './MonthlyBudgetForm';
import MonthlyBudgetMembersTable from './MonthlyBudgetMembersTable';
import MonthlyBudgetOtherItemsTable from './MonthlyBudgetOtherItemsTable';
import MonthlyBudgetRolesTable from './MonthlyBudgetRolesTable';
import CapMonthlyHoursCheckbox from './CapMonthlyHoursCheckbox';

const ProjectSettings = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;

  > label {
    display: inline-flex;

    &:not(:first-child) {
      margin-left: 1.5rem;
    }
  }

  > div:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

const Separator = styled.hr`
  margin: ${({ margin }) => margin ?? '0rem 0 1.25rem'};
  border-color: ${colors.grey10};
`;

const TableTitle = styled.span`
  flex: 0;
  color: ${colors.grey75};
  padding-left: 0rem;
  font-size: 0.875rem;
  font-weight: ${weights.bold};
  padding-top: 1rem;
`;

function MonthlyBudget({
  formik,
  project,
  projectModel,
  onRolesChange,
  onMembersChange,
  onExpensesChange,
  onOtherItemsChange,
}) {
  const currency = projectModel.currency;

  return (
    <>
      <ProjectSettings>
        <Field.RadioGroup name="monthlyBudgetMode">
          <Radio value="summary" label="Summary budget" />
          <Radio value="detailed" label="Detailed budget" />
        </Field.RadioGroup>
      </ProjectSettings>

      {{
        summary: () => <BudgetForm formik={formik} project={project} projectModel={projectModel} />,
        detailed: () => (
          <>
            {['fixed', 'fixed_recurring'].some((b) => b === projectModel.billingTypeId) && (
              <Form.Section
                title="Monthly Services Revenue"
                style={{
                  borderBottom: `1px solid ${colors.grey10}`,
                  marginBottom: '1rem',
                }}>
                <Form.Control>
                  <Field.Currency
                    name="estimatedMonthlyServiceRevenue"
                    placeholder="Services Revenue"
                    currency={currency}
                  />
                </Form.Control>
              </Form.Section>
            )}

            {projectModel.isBillable && projectModel.billingTypeId === 'tm' ? (
              <>
                <Separator margin="0" />
                <TableTitle>Hours and Services Revenue</TableTitle>
              </>
            ) : (
              <TableTitle>Hours</TableTitle>
            )}
            <Form.Control>
              {projectModel.useRoles ? (
                <MonthlyBudgetRolesTable projectModel={projectModel} onChange={onRolesChange} />
              ) : (
                <MonthlyBudgetMembersTable projectModel={projectModel} onChange={onMembersChange} />
              )}
            </Form.Control>

            <Form.Control>
              <CapMonthlyHoursCheckbox />
            </Form.Control>

            <TableTitle margin="0 0 1.25rem 0">Expenses</TableTitle>
            <Form.Control>
              <MonthlyBudgetExpensesTable projectModel={projectModel} onChange={onExpensesChange} />
            </Form.Control>

            {projectModel.isBillable && (
              <>
                <TableTitle margin="0 0 1.25rem 0">Other Items to Bill</TableTitle>
                <Form.Control>
                  <MonthlyBudgetOtherItemsTable projectModel={projectModel} onChange={onOtherItemsChange} />
                </Form.Control>
              </>
            )}
          </>
        ),
      }[projectModel.monthlyBudgetMode]()}
    </>
  );
}

export default MonthlyBudget;
