import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, HelpMessage, HelpTooltip, IconButton, Page } from '~/components';
import { useApi, useRating, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { dateFormats } from '~/utils';
import DashboardDropdown from '../DashboardDropdown';
import BillableUtilization from './BillableUtilization';
import ClientBillableHours from './ClientBillableHours';
import ClientNonBillableHours from './ClientNonBillableHours';
import LeastHealthyProjects from './LeastHealthyProjects';
import MostHealthyProjects from './MostHealthyProjects';
import ServicesEffectiveBillRate from './ServicesEffectiveBillRate';
import ServicesGrossMargin from './ServicesGrossMargin';
import ServicesGrossProfit from './ServicesGrossProfit';
import ServicesRealizationRate from './ServicesRealizationRate';
import ServicesRevenue from './ServicesRevenue';
import TopClientsByBillableHours from './TopClientsByBillableHours';
import TopClientsByServicesRevenue from './TopClientsByServicesRevenue';
import WelcomeDialog from './WelcomeDialog';
import WidgetSettings from './WidgetSettings';
import PipelineByExpectedCloseDate from './PipelineByExpectedCloseDate';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  column-gap: 1.5rem;
  row-gap: 1.5rem;
`;

export default function ExecutiveDashboard() {
  useDocumentTitle('Executive Dashboard');

  const api = useApi();
  const { workspace } = useWorkspace();

  const [memberWidgets, setMemberWidgets] = useState(null);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).executiveDashboard().widgets().get();
    setMemberWidgets(data);
  }, [api, workspace.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const { autoOpenRatingModal } = useRating();

  useEffect(() => {
    autoOpenRatingModal();
  }, [autoOpenRatingModal]);

  const widgets = useMemo(() => {
    if (!memberWidgets) return null;

    const widgets = [
      {
        key: 'services_revenue',
        component: (
          <ServicesRevenue
            actual={{
              start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
              end: moment().format(dateFormats.isoDate),
            }}
            forecast={{
              start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
              end: moment().add(5, 'months').endOf('month').format(dateFormats.isoDate),
            }}
          />
        ),
      },
      {
        key: 'services_gross_profit',
        component: (
          <ServicesGrossProfit
            actual={{
              start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
              end: moment().subtract(1, 'month').endOf('month').format(dateFormats.isoDate),
            }}
            forecast={{
              start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
              end: moment().add(5, 'months').endOf('month').format(dateFormats.isoDate),
            }}
          />
        ),
      },
      {
        key: 'services_gross_margin',
        component: (
          <ServicesGrossMargin
            actual={{
              start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
              end: moment().subtract(1, 'month').endOf('month').format(dateFormats.isoDate),
            }}
            forecast={{
              start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
              end: moment().add(5, 'months').endOf('month').format(dateFormats.isoDate),
            }}
          />
        ),
      },
      {
        key: 'billable_utilization',
        component: (
          <BillableUtilization
            actual={{
              start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
              end: moment().format(dateFormats.isoDate),
            }}
            forecast={{
              start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
              end: moment().add(5, 'months').endOf('month').format(dateFormats.isoDate),
            }}
          />
        ),
      },
      {
        key: 'top_clients_by_services_revenue',
        component: (
          <TopClientsByServicesRevenue
            start={moment().subtract(12, 'months').startOf('month').format(dateFormats.isoDate)}
            end={moment().format(dateFormats.isoDate)}
          />
        ),
      },
      {
        key: 'top_clients_by_billable_hours',
        component: (
          <TopClientsByBillableHours
            start={moment().subtract(12, 'months').startOf('month').format(dateFormats.isoDate)}
            end={moment().format(dateFormats.isoDate)}
          />
        ),
      },
      {
        key: 'services_effective_bill_rate',
        component: (
          <ServicesEffectiveBillRate
            actual={{
              start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
              end: moment().subtract(1, 'month').endOf('month').format(dateFormats.isoDate),
            }}
            forecast={{
              start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
              end: moment().add(5, 'months').endOf('month').format(dateFormats.isoDate),
            }}
          />
        ),
      },
      {
        key: 'services_realization_rate',
        component: (
          <ServicesRealizationRate
            actual={{
              start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
              end: moment().subtract(1, 'month').endOf('month').format(dateFormats.isoDate),
            }}
            forecast={{
              start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
              end: moment().add(5, 'months').endOf('month').format(dateFormats.isoDate),
            }}
          />
        ),
      },
      {
        key: 'client_billable_hours',
        component: (
          <ClientBillableHours
            actual={{
              start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
              end: moment().format(dateFormats.isoDate),
            }}
            forecast={{
              start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
              end: moment().add(5, 'months').endOf('month').format(dateFormats.isoDate),
            }}
          />
        ),
      },
      {
        key: 'client_non_billable_hours',
        component: (
          <ClientNonBillableHours
            actual={{
              start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
              end: moment().format(dateFormats.isoDate),
            }}
            forecast={{
              start: moment().subtract(6, 'months').startOf('month').format(dateFormats.isoDate),
              end: moment().add(5, 'months').endOf('month').format(dateFormats.isoDate),
            }}
          />
        ),
      },
      {
        key: 'most_healthy_projects',
        component: (
          <MostHealthyProjects
            start={moment().subtract(12, 'months').startOf('month').format(dateFormats.isoDate)}
            end={moment().format(dateFormats.isoDate)}
          />
        ),
      },
      {
        key: 'least_healthy_projects',
        component: (
          <LeastHealthyProjects
            start={moment().subtract(12, 'months').startOf('month').format(dateFormats.isoDate)}
            end={moment().format(dateFormats.isoDate)}
          />
        ),
      },
      {
        key: 'pipeline_by_expected_close_date',
        component: (
          <PipelineByExpectedCloseDate
            start={moment().startOf('month').format(dateFormats.isoDate)}
            end={moment().add(11, 'months').endOf('month').format(dateFormats.isoDate)}
          />
        ),
      },
    ];

    return widgets.filter((widget) => memberWidgets.some((memberWidget) => memberWidget.key === widget.key));
  }, [memberWidgets]);

  const [edit, setEdit] = useState(false);

  const handleEdit = () => {
    setEdit(true);
  };

  const handleClose = () => {
    setEdit(false);
  };

  const handleSaved = async () => {
    await fetchData();
    setEdit(false);
  };

  return (
    <>
      <Page>
        <Page.Header>
          <Page.Info>
            <Page.Eyebrow>{workspace.name}</Page.Eyebrow>
            <DashboardDropdown value="executive" />
          </Page.Info>

          <Page.Actions>
            <IconButton tooltip="Personalize Dashboard" icon="cog" onClick={handleEdit} />
            <HelpTooltip message="This executive dashboard is only available to workspace members who have view permissions to all of the data shown below. The data in this dashboard is refreshed once per day." />
          </Page.Actions>
        </Page.Header>

        {widgets ? (
          <Page.Section>
            {widgets.length > 0 ? (
              <Grid>
                {widgets.map((widget) => (
                  <React.Fragment key={widget.key}>{widget.component}</React.Fragment>
                ))}
              </Grid>
            ) : (
              <HelpMessage>
                To personalize your executive dashboard,{' '}
                <Button isAnchor onClick={handleEdit}>
                  open
                </Button>{' '}
                the settings drawer and select one or more widgets.
              </HelpMessage>
            )}
          </Page.Section>
        ) : (
          <PageLoader publicSite={false} />
        )}

        {edit && <WidgetSettings onClose={handleClose} onSaved={handleSaved} />}
      </Page>

      <WelcomeDialog />
    </>
  );
}
