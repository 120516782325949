import {
  Buttons,
  CancelButton,
  DateTime,
  DeleteButton,
  FormMessage,
  ModalCard,
  Radio,
  RadioGroup,
  Tooltip,
} from '~/components';
import { useApi, useSubscription, useToast, useWorkspace } from '~/contexts';
import { useForm } from '~/hooks';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { dateFormats } from '~/utils';

const dateFormat = 'dddd l';

export default function UnsubmitTimesheetConfirmation({ date, onUnsubmitTimesheet, resolve, timesheetContext }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ isSubmitting, message, status }, form] = useForm();

  const [period, setPeriod] = useState('week');

  const toast = useToast();

  const { notify } = useSubscription();

  const dates = useMemo(() => {
    return {
      startOfWeek: moment(date).startOf('isoWeek'),
      endOfWeek: moment(date).endOf('isoWeek'),

      get startOfMonth() {
        return moment(this.endOfWeek).startOf('month');
      },

      get endOfMonth() {
        return moment(this.startOfWeek).endOf('month');
      },

      get startOfSemiMonth() {
        return moment(this.startOfWeek).date(16);
      },

      get endOfSemiMonth() {
        return moment(this.startOfWeek).date(15);
      },

      get isSemiMonthCrossOver() {
        return moment(this.startOfWeek).date(15).isBetween(this.startOfWeek, this.endOfWeek, 'day');
      },

      get isMonthCrossOver() {
        return this.startOfWeek.month() !== this.endOfWeek.month();
      },
    };
  }, [date]);

  const isEntireWeekSubmitted = useMemo(() => {
    return timesheetContext.timesheets.find(
      (t) =>
        t.start === dates.startOfWeek.format(dateFormats.isoDate) &&
        t.end === dates.endOfWeek.format(dateFormats.isoDate),
    )
      ? true
      : false;
  }, [timesheetContext, dates]);

  const periods = useMemo(() => {
    const isPreviousMonthTimesheetSubmitted =
      dates.isMonthCrossOver &&
      timesheetContext.isTimesheetSubmitted({
        start: dates.startOfWeek,
        end: dates.endOfMonth,
      });

    const isNextMonthTimesheetSubmitted =
      dates.isMonthCrossOver &&
      timesheetContext.isTimesheetSubmitted({
        start: dates.startOfMonth,
        end: dates.endOfWeek,
      });

    const monthTimesheet = timesheetContext.timesheets.find(
      (timesheet) =>
        timesheet.start === dates.startOfWeek.format(dateFormats.isoDate) &&
        timesheet.end === dates.endOfMonth.format(dateFormats.isoDate),
    );

    const weekTimesheetMonth = timesheetContext.timesheets.find(
      (timesheet) =>
        timesheet.start === dates.startOfMonth.format(dateFormats.isoDate) &&
        timesheet.end === dates.endOfWeek.format(dateFormats.isoDate),
    );

    const isPreviousSemiMonthTimesheetSubmitted =
      dates.isSemiMonthCrossOver &&
      timesheetContext.isTimesheetSubmitted({ start: dates.startOfWeek, end: dates.endOfSemiMonth });

    const isNextSemiMonthTimesheetSubmitted =
      dates.isSemiMonthCrossOver &&
      timesheetContext.isTimesheetSubmitted({ start: dates.startOfSemiMonth, end: dates.endOfWeek });

    const semiMonthTimesheet = timesheetContext.timesheets.find(
      (timesheet) =>
        timesheet.start === dates.startOfWeek.format(dateFormats.isoDate) &&
        timesheet.end === dates.endOfSemiMonth.format(dateFormats.isoDate),
    );

    const weekTimesheetSemiMonth = timesheetContext.timesheets.find(
      (timesheet) =>
        timesheet.start === dates.startOfSemiMonth.format(dateFormats.isoDate) &&
        timesheet.end === dates.endOfWeek.format(dateFormats.isoDate),
    );

    const weekTimesheet = timesheetContext.timesheets.find(
      (timesheet) =>
        timesheet.start === dates.startOfWeek.format(dateFormats.isoDate) &&
        timesheet.end === dates.endOfWeek.format(dateFormats.isoDate),
    );

    return {
      get week() {
        if (dates.isMonthCrossOver && !workspace.allowTimesheetSplitAtEndOfMonth)
          return {
            start: dates.startOfWeek,
            end: dates.endOfWeek,
            isTimesheetSubmitted: true,
            timesheet: weekTimesheet,
          };

        if (dates.isSemiMonthCrossOver && !workspace.allowTimesheetSplitAtEndOfSemiMonth)
          return {
            start: dates.startOfWeek,
            end: dates.endOfWeek,
            isTimesheetSubmitted: true,
            timesheet: weekTimesheet,
          };

        return {
          start: isEntireWeekSubmitted
            ? dates.startOfWeek
            : isPreviousMonthTimesheetSubmitted || isNextMonthTimesheetSubmitted
              ? dates.startOfMonth
              : isPreviousSemiMonthTimesheetSubmitted || isNextSemiMonthTimesheetSubmitted
                ? dates.startOfSemiMonth
                : dates.startOfWeek,
          end: dates.endOfWeek,
          isTimesheetSubmitted: isNextMonthTimesheetSubmitted || isNextSemiMonthTimesheetSubmitted,
          timesheet: isEntireWeekSubmitted
            ? weekTimesheet
            : isPreviousMonthTimesheetSubmitted || isNextMonthTimesheetSubmitted
              ? weekTimesheetMonth
              : isPreviousSemiMonthTimesheetSubmitted || isNextSemiMonthTimesheetSubmitted
                ? weekTimesheetSemiMonth
                : weekTimesheet,
        };
      },

      get month() {
        if (!workspace.allowTimesheetSplitAtEndOfMonth) return null;

        return {
          start: dates.startOfWeek,
          end: dates.endOfMonth,
          isTimesheetSubmitted: isPreviousMonthTimesheetSubmitted,
          timesheet: monthTimesheet,
        };
      },

      get semiMonth() {
        if (!workspace.allowTimesheetSplitAtEndOfSemiMonth) return null;

        return {
          start: dates.startOfWeek,
          end: dates.endOfSemiMonth,
          isTimesheetSubmitted: isPreviousSemiMonthTimesheetSubmitted,
          timesheet: semiMonthTimesheet,
        };
      },
    };
  }, [dates, timesheetContext, isEntireWeekSubmitted, workspace]);

  const handleUnsubmit = async () => {
    form.submit();

    const { start, end } = periods[period];

    const timesheets = timesheetContext.timesheets.filter(
      (ts) => moment(ts.start).isSameOrBefore(end) && moment(ts.end).isSameOrAfter(start),
    );

    try {
      for (let timesheet of timesheets) {
        await api.www.workspaces(workspace.id).timesheets(timesheet.id).unsubmit();
      }

      form.done();

      await onUnsubmitTimesheet();

      toast.success('The timesheet has been unsubmitted.');

      resolve(true);
      notify(useSubscription.keys.refresh_time_approval_count);
    } catch ({ message }) {
      form.error({ message });
    }
  };

  const periodOptions = useMemo(() => {
    let options = [];

    if (dates.isMonthCrossOver && workspace.allowTimesheetSplitAtEndOfMonth) {
      options.push({
        key: 'month',
        label: (
          <Tooltip
            message={
              periods.month.isTimesheetSubmitted
                ? periods.month.timesheet?.permissions.delete
                  ? null
                  : 'You cannot unsubmit this timesheet.'
                : 'This timesheet is not submitted.'
            }>
            {periods.week.end.diff(periods.week.start, 'days') === 6 ? 'Full' : 'Partial'} week from{' '}
            <DateTime value={periods.month.start} format={dateFormat} /> through{' '}
            <DateTime value={periods.month.end} format={dateFormat} />
          </Tooltip>
        ),
        disabled: !periods.month.isTimesheetSubmitted || !periods.month.timesheet?.permissions.delete,
      });
    }

    if (dates.isSemiMonthCrossOver && workspace.allowTimesheetSplitAtEndOfSemiMonth) {
      options.push({
        key: 'semiMonth',
        label: (
          <Tooltip
            message={
              periods.semiMonth.isTimesheetSubmitted
                ? periods.semiMonth.timesheet?.permissions.delete
                  ? null
                  : 'You cannot unsubmit this timesheet.'
                : 'This timesheet is not submitted.'
            }>
            {periods.week.end.diff(periods.week.start, 'days') === 6 ? 'Full' : 'Partial'} week from{' '}
            <DateTime value={periods.semiMonth.start} format={dateFormat} /> through{' '}
            <DateTime value={periods.semiMonth.end} format={dateFormat} />
          </Tooltip>
        ),
        disabled: !periods.semiMonth.isTimesheetSubmitted || !periods.semiMonth.timesheet?.permissions.delete,
      });
    }

    options.push({
      key: 'week',
      label: (
        <Tooltip
          message={
            periods.week.isTimesheetSubmitted
              ? periods.week.timesheet?.permissions.delete
                ? null
                : 'You cannot unsubmit this timesheet.'
              : 'This timesheet is not submitted.'
          }>
          {periods.week.end.diff(periods.week.start, 'days') === 6 ? 'Full' : 'Partial'} week from{' '}
          <DateTime value={periods.week.start} format={dateFormat} /> through{' '}
          <DateTime value={periods.week.end} format={dateFormat} />
        </Tooltip>
      ),
      disabled: !periods.week.isTimesheetSubmitted || !periods.week.timesheet?.permissions.delete,
    });

    return options;
  }, [dates, periods, workspace]);

  useEffect(() => {
    if (
      workspace.allowTimesheetSplitAtEndOfMonth &&
      dates.isMonthCrossOver &&
      periods.month.isTimesheetSubmitted &&
      periods.month.timesheet?.permissions.delete
    )
      setPeriod('month');
    if (
      workspace.allowTimesheetSplitAtEndOfSemiMonth &&
      dates.isSemiMonthCrossOver &&
      periods.semiMonth.isTimesheetSubmitted &&
      periods.semiMonth.timesheet?.permissions.delete
    )
      setPeriod('semiMonth');
    if (isEntireWeekSubmitted) setPeriod('week');
  }, [dates, periods, isEntireWeekSubmitted, workspace]);

  return (
    <ModalCard title="Unsubmit Timesheet" onClose={() => resolve(false)}>
      <ModalCard.Body>
        {periodOptions.length > 1 && !isEntireWeekSubmitted ? (
          <div>
            <p>This will open the selected time period:</p>

            <RadioGroup
              style={{ marginTop: '1rem' }}
              direction="vertical"
              name="period"
              value={period}
              onChange={(value) => setPeriod(value)}>
              {periodOptions.map((p) => (
                <Radio key={p.key} value={p.key} label={p.label} disabled={p.disabled} />
              ))}
            </RadioGroup>
          </div>
        ) : (
          <p>
            This will open the{' '}
            {dates.isMonthCrossOver || dates.isSemiMonthCrossOver ? 'full week period' : 'time period'} from{' '}
            <DateTime value={dates.startOfWeek} /> to <DateTime value={dates.endOfWeek} />.
          </p>
        )}

        {status && <FormMessage.Error spaceTop>{message}</FormMessage.Error>}
      </ModalCard.Body>
      <ModalCard.Footer>
        <Buttons align="right">
          <CancelButton onClick={() => resolve(false)}>Cancel</CancelButton>
          <DeleteButton isLoading={isSubmitting} onClick={handleUnsubmit}>
            Unsubmit Timesheet
          </DeleteButton>
        </Buttons>
      </ModalCard.Footer>
    </ModalCard>
  );
}
