import _ from 'lodash';
import moment from 'moment';
import { rgba } from 'polished';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import styled, { css } from 'styled-components';
import { Button, DayPickerInput, Icon, IconButton } from '~/components';
import { useDateTimeFormat } from '~/hooks';
import { colors } from '~/styles';
import { dateFormats, intervalOptions } from '~/utils';
import { sortIntervals } from '~/utils/intervalOptions';

const Dates = styled.div`
  padding: 0.5rem 0.875rem;
  border-bottom: solid 1px ${colors.grey10};
  display: flex;
  gap: 1rem;
  align-items: center;
  background-color: ${colors.grey5};
  width: 100%;

  > div {
    flex: 1;
    font-size: 0.75rem;
  }
`;

const Text = styled.div`
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 0 0 0.875rem;
  font-size: 0.75rem;
`;

const Placeholder = styled.span`
  color: ${colors.grey40};
`;

const Box = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  color: ${colors.black};
  background-color: transparent;
  border: 1px solid ${colors.grey10};
  border-radius: 0.3125rem;
  user-select: none;

  ${({ $hasValue }) =>
    $hasValue &&
    css`
      border-color: ${colors.grey40};
    `}
`;

const Control = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 2rem;
  outline: none;
  box-shadow: none;
  cursor: pointer;

  &:focus {
    ${Box} {
      border: 1px solid ${colors.primary};
      border-radius: 0.3125rem;
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${Box} {
        border: 1px solid ${colors.primary};
        border-radius: 0.3125rem;
      }
    `}
`;

const Status = styled.div`
  font-size: 0.75rem;
  display: flex;
  gap: 0.125rem;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: 0.125rem;
  margin-right: 0.25rem;

  .icon {
    width: 1rem;
  }
`;

const Container = styled.div`
  background-color: ${colors.white};
  border-radius: 0.3125rem;
  box-shadow: 0 0.25rem 1rem -0.125em ${rgba(colors.black, 0.15)};
  z-index: 3;
  user-select: none;
  outline: none;
`;

const Options = styled.div`
  overflow: auto;
  max-height: 15rem;
  padding: 0.5rem 0;
  outline: none;
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25rem 0.875rem;
  cursor: pointer;
  outline: none;

  &:hover,
  &:focus {
    background-color: ${colors.grey5};
  }

  ${({ inactive }) =>
    inactive &&
    css`
      color: ${colors.grey40};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${colors.grey25};
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

const OptionIndicator = styled.div`
  flex-shrink: 0;
  display: flex;
  width: 0.75rem;
  margin-right: 0.5rem;
  color: ${colors.grey40};
  font-size: 0.75rem;
`;

const OptionValue = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Option = React.forwardRef(({ isSelected, children, ...props }, ref) => (
  <OptionContainer role="menuitemradio" aria-checked={isSelected} {...props} ref={ref}>
    <OptionIndicator>{!!isSelected && <Icon icon="check" />}</OptionIndicator>
    <OptionValue>{children}</OptionValue>
  </OptionContainer>
));

function Results({ autoFocus, value, options, renderOptions, onToggleOption }) {
  const optionsRef = useRef(null);

  useEffect(() => {
    if (autoFocus && optionsRef.current) {
      const firstTabbable = optionsRef.current.querySelector('[tabindex="0"]');
      if (firstTabbable) {
        firstTabbable.focus();
      }
    }
  }, [autoFocus]);

  const handleKeyDown = (e, o) => {
    switch (e.key) {
      case 'ArrowUp': {
        e.stopPropagation();
        e.preventDefault();

        const allOptions = optionsRef.current.querySelectorAll('[tabindex="0"]');
        const currentIndex = Array.prototype.indexOf.call(allOptions, e.target);
        const previousIndex = currentIndex > 0 ? currentIndex - 1 : allOptions.length - 1;
        allOptions[previousIndex]?.focus();
        break;
      }

      case 'ArrowDown': {
        e.stopPropagation();
        e.preventDefault();

        const allOptions = optionsRef.current.querySelectorAll('[tabindex="0"]');
        const currentIndex = Array.prototype.indexOf.call(allOptions, e.target);
        const nextIndex = currentIndex < allOptions.length - 1 ? currentIndex + 1 : 0;
        allOptions[nextIndex]?.focus();
        break;
      }

      case 'Enter':
      case ' ':
        onToggleOption(o);
        e.stopPropagation();
        e.preventDefault();
        break;
    }
  };

  if (_.isFunction(renderOptions)) {
    return (
      <Options ref={optionsRef}>
        {renderOptions(
          options.map((o) => ({
            ...o,
            props: {
              tabIndex: 0,
              isSelected: _.isEqual(value, o),
              onClick: () => onToggleOption(o),
              onKeyDown: (e) => handleKeyDown(e, o),
            },
          })),
        )}
      </Options>
    );
  }

  return (
    <Options ref={optionsRef}>
      {options.map((option) => {
        const isSelected = _.isEqual(value, option);
        if (option.id === intervalOptions.custom.key) return null;
        return (
          <Option
            key={option.id}
            tabIndex={0}
            isSelected={isSelected}
            onKeyDown={(e) => handleKeyDown(e, option)}
            onClick={() => onToggleOption(option)}>
            {option.name}
          </Option>
        );
      })}
    </Options>
  );
}

const MaterialPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0.625rem; /* input padding - placeholder padding */
  margin-left: 1px; /* offsets the 1px input border */
  padding: 0 0.25rem;
  color: ${colors.grey40};
  font-size: 0.75rem;
  background-color: ${colors.white};
  border-radius: 0.3125rem;
  transform: translateY(-50%);
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  transition: opacity 100ms;
  pointer-events: none;
`;

function Select({ value, options, renderOptions, renderResults, onApply }) {
  const handleToggleOption = (option) => {
    onApply(option);
  };

  return (
    <Container onKeyDown={(e) => e.stopPropagation()}>
      {_.isFunction(renderResults) && renderResults({ onApply })}

      <Results value={value} options={options} renderOptions={renderOptions} onToggleOption={handleToggleOption} />
    </Container>
  );
}

function SingleFilter({ placeholder, name, renderValue, defaultIsOpen = false, tabIndex = '0', onChange, ...props }) {
  const value = props.value;

  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const handleOpen = (flag) => {
    setIsOpen(flag);
  };
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    strategy: 'absolute',
    modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
  });

  const handleApply = (value) => {
    onChange({ name, value, target: { name, value } });
    setIsOpen(false);
  };

  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (referenceElement?.contains(event.target) || popperElement?.contains(event.target)) {
        return;
      }

      setIsOpen(false);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [referenceElement, popperElement]);

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'Escape':
      case 'Tab':
        setIsOpen(false);
        break;

      case 'Enter':
      case ' ':
        handleOpen(!isOpen);
        break;
    }
  };

  const hasValue = !!value;

  return (
    <Control
      ref={setReferenceElement}
      isOpen={isOpen}
      tabIndex={tabIndex}
      onClick={() => handleOpen(!isOpen)}
      onKeyDown={handleKeyDown}>
      <Box $hasValue={hasValue}>
        {hasValue ? (
          renderValue(value)
        ) : (
          <Text>
            <Placeholder>{placeholder}</Placeholder>
          </Text>
        )}

        {!hasValue && (
          <Status>
            <Icon icon="calendar-alt" color={colors.grey20} />
          </Status>
        )}
      </Box>

      <MaterialPlaceholder isVisible={!!value}>{placeholder}</MaterialPlaceholder>

      {isOpen && (
        <div
          ref={setPopperElement}
          style={{
            ...styles.popper,
            minWidth: Math.max(referenceElement?.scrollWidth, 350),
            maxWidth: '100%',
            zIndex: 110,
          }}
          onClick={(e) => e.stopPropagation()}
          {...attributes.popper}>
          <Select value={value} onApply={handleApply} {...props} />
        </div>
      )}
    </Control>
  );
}

SingleFilter.Option = Option;

const Value = styled.div`
  flex: 1;
  padding: 0 0.25rem;
  overflow: hidden;
  width: 100%;
`;

const DateNav = styled.div`
  display: flex;
  width: 100%;
  color: ${colors.grey75};
  align-items: center;
  height: 100%;
`;

const Date = styled.div`
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  font-size: 0.75rem;
`;

const PreviousButton = styled(Button)`
  font-size: 0.75rem;
  padding-left: 0.5rem;
  padding-right: 0.25rem;
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
  height: 100%;
  color: ${colors.primary};
  display: flex;
  align-items: center;
`;

const NextButton = styled(Button)`
  font-size: 0.75rem;
  margin-left: auto;
  padding-right: 0.5rem;
  padding-left: 0.25rem;
  height: 100%;
  color: ${colors.primary};
  display: flex;
  align-items: center;
`;

export default function PeriodFilter({ placeholder, intervals, name, value, scope = 'day', onChange, ...props }) {
  const allowCustom = intervals?.some((i) => i.key === intervalOptions.custom.key);
  const clearable = intervals?.some((i) => i.key === intervalOptions.all_dates.key);

  const handleChange = (newValue) => {
    const val = { ...value, ...newValue };

    onChange({
      name,
      value: val,
      target: {
        name,
        value: { ...value, ...newValue },
      },
    });
  };

  const handlePeriodChange = ({ value: option }) => {
    const interval = option ? _.find(intervals, { key: option.id }) : intervalOptions.all_dates;

    handleChange({
      key: interval.key,
      start: interval.start !== undefined ? interval.start : value.start,
      end: interval.end !== undefined ? interval.end : value.end,
      unit: interval.unit,
    });
  };

  const handleStartChange = (start) => {
    if (clearable || start) {
      switch (scope) {
        case 'month':
          start = moment(start).startOf('month').format('YYYY-MM');
          break;

        case 'week':
          start = moment(start).startOf('isoWeek').format(dateFormats.isoDate);
          break;
      }

      const option = _.find(sortIntervals(intervals), (i) => i.start === start && i.end === value.end);
      handleChange({
        start: start ?? null,
        key: option ? option.key : intervalOptions.custom.key,
        unit: option ? option.unit : intervalOptions.custom.unit,
      });
    }
  };

  const handleEndChange = (end) => {
    if (clearable || end) {
      switch (scope) {
        case 'month':
          end = moment(end).endOf('month').format(dateFormats.isoDate);
          break;

        case 'week':
          end = moment(end).endOf('isoWeek').format(dateFormats.isoDate);
          break;
      }

      const option = _.find(sortIntervals(intervals), (i) => i.start === value.start && i.end === end);
      handleChange({
        end: end ?? null,
        key: option ? option.key : intervalOptions.custom.key,
        unit: option ? option.unit : intervalOptions.custom.unit,
      });
    }
  };

  const dayPickerProps = {
    day: { displayFormat: dateFormats.compactDate },
    week: { displayFormat: dateFormats.compactDate },
    month: { displayFormat: dateFormats.monthYear, locale: 'en-US' },
  }[scope];

  const options = useMemo(
    () =>
      _(intervals)
        .map((i) => ({ id: i.key, name: i.label }))
        .value(),
    [intervals],
  );

  const interval =
    _.find(
      options.filter((o) => o.id !== intervalOptions.all_dates.key),
      { id: value?.key },
    ) ?? null;

  const dateTimeFormat = useDateTimeFormat();

  const hasValue = value?.start || value?.end;
  const notSet = !value?.start || !value?.end ? true : false;

  const handleArrowClick = (direction) => {
    const { start, end, unit } = value;
    let interval;

    switch (unit) {
      case 'custom':
        {
          if (
            moment(start).isSame(moment(start).startOf('month'), 'day') &&
            moment(end).isSame(moment(start).endOf('month'), 'day')
          ) {
            interval = {
              start: moment(start)
                .add(direction === 'next' ? 1 : -1, 'months')
                .format(dateFormats.isoDate),
              end: moment(end)
                .add(direction === 'next' ? 1 : -1, 'months')
                .endOf('months')
                .format(dateFormats.isoDate),
              unit: 'month',
            };
          } else if (
            moment(start).isSame(moment(start).startOf('year'), 'day') &&
            moment(end).isSame(moment(start).endOf('year'), 'day')
          ) {
            interval = {
              start: moment(start)
                .add(direction === 'next' ? 1 : -1, 'years')
                .format(dateFormats.isoDate),
              end: moment(end)
                .add(direction === 'next' ? 1 : -1, 'years')
                .format(dateFormats.isoDate),
              unit: 'year',
            };
          } else {
            const customDays = moment(end).diff(moment(start), 'days') + 1 || 1;
            interval = {
              start: moment(start)
                .add(direction === 'next' ? customDays : customDays * -1, 'days')
                .format(dateFormats.isoDate),
              end: moment(end)
                .add(direction === 'next' ? customDays : customDays * -1, 'days')
                .format(dateFormats.isoDate),
              unit: 'custom',
            };
          }
        }
        break;
      case 'day':
        interval = {
          start: moment(start)
            .add(direction === 'next' ? 1 : -1, 'days')
            .format(dateFormats.isoDate),
          end: moment(end)
            .add(direction === 'next' ? 1 : -1, 'days')
            .format(dateFormats.isoDate),
          unit: 'day',
        };
        break;
      case 'week':
        interval = {
          start: moment(start)
            .add(direction === 'next' ? 1 : -1, 'weeks')
            .format(dateFormats.isoDate),
          end: moment(end)
            .add(direction === 'next' ? 1 : -1, 'weeks')
            .format(dateFormats.isoDate),
          unit: 'week',
        };
        break;
      case 'semi_month':
        interval = {
          start: (moment(start).date() < 16
            ? moment(start)
                .add(direction === 'next' ? 0 : -1, 'months')
                .date(16)
            : moment(start)
                .add(direction === 'next' ? 1 : 0, 'months')
                .startOf('month')
          ).format(dateFormats.isoDate),

          end: (moment(end).date() < 16
            ? moment(end)
                .add(direction === 'next' ? 0 : -1, 'months')
                .endOf('month')
            : moment(end)
                .add(direction === 'next' ? 1 : 0, 'months')
                .date(15)
          ).format(dateFormats.isoDate),
          unit: 'semi_month',
        };
        break;
      case 'month':
        interval = {
          start: moment(start)
            .add(direction === 'next' ? 1 : -1, 'months')
            .format(dateFormats.isoDate),
          end: moment(end)
            .add(direction === 'next' ? 1 : -1, 'months')
            .endOf('months')
            .format(dateFormats.isoDate),
          unit: 'month',
        };
        break;
      case 'quarter':
        interval = {
          start: moment(start)
            .add(direction === 'next' ? 1 : -1, 'quarters')
            .format(dateFormats.isoDate),
          end: moment(end)
            .add(direction === 'next' ? 1 : -1, 'quarters')
            .endOf('quarters')
            .format(dateFormats.isoDate),
          unit: 'quarter',
        };
        break;
      case 'year':
        interval = {
          start: moment(start)
            .add(direction === 'next' ? 1 : -1, 'years')
            .format(dateFormats.isoDate),
          end: moment(end)
            .add(direction === 'next' ? 1 : -1, 'years')
            .format(dateFormats.isoDate),
          unit: 'year',
        };
        break;
    }

    const option = _.find(sortIntervals(intervals), (oi) => oi.start === interval.start && oi.end === interval.end);

    const val = {
      start: interval.start,
      end: interval.end,
      unit: option ? option.unit : interval.unit,
      key: option ? option.key : intervalOptions.custom.key,
    };

    onChange({ name, value: val, target: { name, value: val } });
  };

  const text = useMemo(() => {
    if (!hasValue) return null;
    return _.compact([
      value?.start ? dateTimeFormat.format(value.start) : 'Not Set',
      value?.end ? dateTimeFormat.format(value.end) : 'Not Set',
    ]).join(' - ');
  }, [hasValue, value, dateTimeFormat]);

  return (
    <SingleFilter
      {...props}
      placeholder={placeholder}
      value={interval}
      renderValue={() => {
        return (
          <DateNav>
            <PreviousButton
              isAnchor
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleArrowClick('previous');
              }}
              disabled={notSet}>
              <Icon icon="angle-left" />
            </PreviousButton>

            <Value>
              <Date data-testid="date_range">{text}</Date>
            </Value>

            <NextButton
              isAnchor
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleArrowClick('next');
              }}
              disabled={notSet}>
              <Icon icon="angle-right" />
            </NextButton>
          </DateNav>
        );
      }}
      options={options}
      onChange={handlePeriodChange}
      renderResults={({ onApply }) => {
        if (!allowCustom) return null;

        return (
          <Dates>
            <DayPickerInput
              style={{ height: '2rem', fontSize: '.75rem', flex: 1 }}
              materialPlaceholder={false}
              value={value.start}
              displayFormat={dayPickerProps.displayFormat}
              locale={dayPickerProps.locale}
              clearable={clearable}
              scope={scope}
              onChange={handleStartChange}
            />

            <DayPickerInput
              style={{ height: '2rem', fontSize: '.75rem', flex: 1 }}
              align="right"
              materialPlaceholder={false}
              value={value.end}
              displayFormat={dayPickerProps.displayFormat}
              locale={dayPickerProps.locale}
              clearable={clearable}
              scope={scope}
              onChange={handleEndChange}
            />

            <div style={{ flex: 0 }}>
              <IconButton icon="check" onClick={() => onApply({ id: value.key, ...value })} />
            </div>
          </Dates>
        );
      }}
    />
  );
}
