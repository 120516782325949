import { Button, Buttons, CancelButton, Checkbox, Confirmation, Drawer, Field, Form } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useFeatures, useIsMounted } from '~/hooks';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { mergeValues } from '~/utils';
import * as Yup from 'yup';
import CreditNoteProjectMultiSelect from '../components/CreditNoteProjectMultiSelect';
import CreditNotePurchaseOrderSelect from '../components/CreditNotePurchaseOrderSelect';

const Checkboxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.375rem -0.75rem;

  > label {
    margin: 0.375rem 0.75rem;
  }
`;

export default function SettingsDrawer({ creditNote, values, onApply, onClose }) {
  const [client, setClient] = useState(null);
  const api = useApi();
  const { workspace } = useWorkspace();
  const features = useFeatures();
  const isMounted = useIsMounted();

  useEffect(() => {
    (async () => {
      const { data } = await api.www.workspaces(workspace.id).creditNotes(creditNote.id).client();
      if (!isMounted.current) return;
      setClient(data);
    })();
  }, [creditNote.id, api, workspace.id, isMounted]);

  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);

  function handleClose() {
    onClose();
  }

  const initialValues = mergeValues(
    {
      currency: null,
      displayColumns: [],
      projects: [],
      purchaseOrder: null,
    },
    values,
  );

  const schema = Yup.object().shape({
    projects: Yup.array().when('$permissions', (_, schema) =>
      client?.permissions.manageDraftInvoices
        ? schema
        : schema.min(1, `Your security role requires this credit note to be associated with at least one project.`),
    ),
    displayColumns: Yup.array().of(Yup.string()).label('Credit Note display columns'),
  });

  const confirmation = useConfirmation();

  if (!client) return null;

  return (
    <Drawer
      isOpen
      title="Credit Note Settings"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(formValues) {
          if (
            values.lines.some((line) => line.project && !formValues.projects.map((p) => p.id).includes(line.project.id))
          ) {
            const confirm = await confirmation.prompt((resolve) => (
              <Confirmation resolve={resolve}>
                You have removed a project that is associated with line items on the credit note. Those line items will
                be removed from the credit note. Are you sure that you want to continue?
              </Confirmation>
            ));
            if (!confirm) return;
          }

          onApply(formValues);
          closeDrawer();
        }

        return (
          <Formik
            innerRef={formRef}
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={schema}>
            {({ values, setFieldValue, setValues, submitForm }) => {
              function handleDisplayColumnsChange(name) {
                let fields;
                if (values.displayColumns.some((v) => v === name)) {
                  fields = values.displayColumns.filter((v) => v !== name);
                } else {
                  fields = [...values.displayColumns, name];
                }
                setFieldValue('displayColumns', fields);
              }

              function handleProjectsChange({ target: { value } }) {
                setFieldValue(
                  'projects',
                  value.map((p) => ({ id: p.id, name: p.name, key: p.key, currency: p.currency })),
                );
              }

              function handlePurchaseOrderChange({ target: { value } }) {
                // Only reset the projects if the purchase order actually changed
                if (value?.id !== values.purchaseOrder?.id) {
                  const projectIds = value?.projects.map((p) => p.id) ?? [];
                  const projects = values.projects.filter((p) => projectIds.includes(p.id)) ?? [];

                  setValues({
                    ...values,
                    purchaseOrder: value,
                    projects,
                  });
                }
              }

              function handleCurrencyChange({ target: { value } }) {
                // Only reset the projects and purchase order if the currency actually changed
                if (value !== values.currency) {
                  setValues({
                    ...values,
                    currency: value,
                    projects: [],
                    purchaseOrder: null,
                  });
                }
              }

              return (
                <Form>
                  <Form.Section title="General">
                    <Form.Control>
                      <Field.WorkspaceCurrencySelect
                        name="currency"
                        clearable={false}
                        disabled={!features.multicurrency}
                        onChange={handleCurrencyChange}
                      />
                    </Form.Control>
                    {features.purchaseOrders && (
                      <Form.Control>
                        <CreditNotePurchaseOrderSelect
                          name="purchaseOrder"
                          creditNote={creditNote}
                          currency={values.currency}
                          value={values.purchaseOrder}
                          initialValue={creditNote.purchaseOrder}
                          onChange={handlePurchaseOrderChange}
                        />
                      </Form.Control>
                    )}
                    <Form.Control>
                      <CreditNoteProjectMultiSelect
                        name="projects"
                        value={values.projects}
                        creditNote={creditNote}
                        currency={values.currency}
                        onChange={handleProjectsChange}
                      />
                    </Form.Control>
                  </Form.Section>

                  <Form.Section
                    title="Credit Note Columns"
                    subtitle="Select the columns to include on the published version of this credit note.">
                    <Form.Control>
                      <Checkboxes>
                        <Checkbox
                          label="Item"
                          checked={values.displayColumns.some((v) => v === 'item')}
                          onChange={() => handleDisplayColumnsChange('item')}
                        />
                        <Checkbox
                          label="Details"
                          checked={values.displayColumns.some((v) => v === 'details')}
                          onChange={() => handleDisplayColumnsChange('details')}
                        />
                        <Checkbox
                          label="Quantity"
                          checked={values.displayColumns.some((v) => v === 'quantity')}
                          onChange={() => handleDisplayColumnsChange('quantity')}
                        />
                        <Checkbox
                          label="Rate"
                          checked={values.displayColumns.some((v) => v === 'rate')}
                          onChange={() => handleDisplayColumnsChange('rate')}
                        />
                        <Checkbox label="Amount" checked={true} disabled={true} />
                        <Checkbox
                          label="Tax"
                          checked={values.displayColumns.some((v) => v === 'tax')}
                          onChange={() => handleDisplayColumnsChange('tax')}
                        />
                      </Checkboxes>
                    </Form.Control>
                  </Form.Section>

                  <Drawer.Actions>
                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                      <Button onClick={submitForm}>Apply</Button>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}
