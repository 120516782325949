import slugify from '@sindresorhus/slugify';
import path from 'path-browserify';
import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { BackLink, Currency, DateTime, ExportDialog, IconLink, Page, ExportDropdown } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { colors, weights } from '~/styles';
import { mimeTypes } from '~/utils';

const resolveEditPath = (to, { pathname } = {}) =>
  pathname ? (pathname.endsWith(to) ? pathname : path.resolve(pathname, to)) : '';

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Area = styled.h3`
  color: ${colors.grey40};
  font-size: 0.75rem;
  font-weight: ${weights.black};
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  margin-top: 0.1rem;
`;

const ExpenseReportName = styled.h1`
  && {
    font-size: 1.5rem;
    font-weight: ${weights.light};
    margin-top: 0.25rem;
  }
`;

const Tags = styled.div`
  display: flex;
  color: ${colors.grey55};
  font-size: 0.75rem;
  margin-top: 0.25rem;
`;

const Tag = styled.span`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem 0.4rem;
  border-left: solid 1px ${colors.grey10};

  &:first-child {
    padding-left: 0;
    border-left: none;
  }

  &:last-child {
    padding-right: 0;
  }

  a {
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
`;

function Header({ expenseReport, member }) {
  const api = useApi();
  const location = useLocation();
  const { workspace } = useWorkspace();
  const confirmation = useConfirmation();
  const {
    title,
    number,
    date,
    expenseItems,
    isExternallyManaged,
    id,
    convertedTotalAmount,
    convertedReimbursableAmount,
    convertedRevenue,
  } = expenseReport;

  const handleExport = async (filename, mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .expenseReports(id)
          .export({
            headers: { accept: mimeType },
            responseType: 'blob',
          })}
        onClose={resolve}
      />
    ));
  };

  const getFileName = () => {
    const reportMember = member || workspace.member;

    let memberName = slugify(reportMember.name, { separator: '_', lowercase: false });

    if (!memberName) {
      memberName = slugify(reportMember.email.split('@')[0], { separator: '_', lowercase: false });
    }

    const fileName = `${memberName}_${number}`;

    return fileName;
  };

  return (
    <Page.Header>
      <BackLink defaultPath={`/app/${workspace.key}/expenses/reports`} />
      <InfoContainer>
        <Area>Expense Report</Area>
        <ExpenseReportName>
          {title} (#{number})
        </ExpenseReportName>
        <Tags>
          {(member || workspace.member).permissions?.manageTimeAndExpenses && <Tag>{expenseReport.member?.name}</Tag>}
          <Tag>
            <DateTime value={date} />
          </Tag>
          <Tag>{expenseItems?.length} item(s)</Tag>
          <Tag>
            <Currency value={convertedTotalAmount} /> expense amount
          </Tag>
          <Tag>
            <Currency value={convertedReimbursableAmount} /> reimbursable
          </Tag>
          <Tag>
            <Currency value={convertedRevenue} /> billable
          </Tag>
        </Tags>
      </InfoContainer>

      <Page.Actions>
        <ExportDropdown>
          {({ setIsOpen }) => (
            <>
              <ExportDropdown.Item
                onClick={async () => {
                  const fileName = getFileName();
                  await handleExport(`${fileName}.csv`, mimeTypes.csv);
                  setIsOpen(false);
                }}>
                Export to CSV
              </ExportDropdown.Item>

              <ExportDropdown.Item
                onClick={async () => {
                  const fileName = getFileName();
                  await handleExport(`${fileName}.xlsx`, mimeTypes.xlsx);
                  setIsOpen(false);
                }}>
                Export to Excel
              </ExportDropdown.Item>

              <ExportDropdown.Item
                onClick={async () => {
                  const fileName = getFileName();
                  await handleExport(`${fileName}.pdf`, mimeTypes.pdf);
                  setIsOpen(false);
                }}>
                Export to PDF
              </ExportDropdown.Item>

              <ExportDropdown.Item
                onClick={async () => {
                  const fileName = getFileName();
                  await handleExport(`${fileName}.zip`, mimeTypes.zip);
                  setIsOpen(false);
                }}>
                Export to ZIP
              </ExportDropdown.Item>
            </>
          )}
        </ExportDropdown>

        {!isExternallyManaged && <IconLink icon="pencil-alt" to={resolveEditPath('edit', location)} />}
      </Page.Actions>
    </Page.Header>
  );
}

export default Header;
