import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Icon, Table, Tooltip } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useIsMounted } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import SyncLogDrawer from './SyncLogDrawer';

const REFRESH_DELAY_SECONDS = 5;

const Message = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  color: ${colors.grey55};
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: ${weights.light};
`;

const RefreshButton = styled.div`
  margin-left: auto;
  padding-left: 1rem;
  color: ${({ disabled }) => (disabled ? colors.grey25 : colors.black)};
  cursor: pointer;
`;

const TableWrapper = styled.div`
  margin-top: 0.5rem;
`;

export default function ImportedResults({ refreshDate }) {
  const api = useApi();
  const isMounted = useIsMounted();
  const { workspace } = useWorkspace();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [viewSync, setViewSync] = useState();
  const [canRefresh, setCanRefresh] = useState(true);
  const [lastRefreshDate, setLastRefreshDate] = useState(refreshDate);

  const fetchResults = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).hubspot.getResults();
      setData(data);
    } finally {
      setIsLoading(false);
    }
  }, [api, workspace]);

  const refresh = useCallback(() => {
    if (!canRefresh) {
      return;
    }
    setCanRefresh(false);
    setTimeout(() => {
      fetchResults();
      if (isMounted.current) {
        setCanRefresh(true);
      }
    }, REFRESH_DELAY_SECONDS * 1000);
  }, [canRefresh, fetchResults, isMounted]);

  useEffect(() => {
    // Initial fetch
    fetchResults();
  }, [fetchResults]);

  useEffect(() => {
    // Only refresh if the date has actually changed
    if (refreshDate !== lastRefreshDate) {
      setLastRefreshDate(refreshDate);
      refresh();
    }
  }, [refreshDate, lastRefreshDate, refresh]);

  if (isLoading) {
    return <PageLoader />;
  }
  if (data.results.length === 0) {
    return (
      <>
        <TitleRow>
          <Title>Imported Data Stats</Title>
        </TitleRow>
        <TableWrapper>
          <Message>No results available</Message>
        </TableWrapper>
      </>
    );
  }

  return (
    <>
      <TitleRow>
        <Title>Imported Data Stats</Title>
        <RefreshButton disabled={!canRefresh} onClick={refresh}>
          <Tooltip message="Refresh results">
            <Icon icon="sync" />
          </Tooltip>
        </RefreshButton>
      </TitleRow>
      <TableWrapper>
        <Table small>
          <Table.BoxHeader>
            <Table.Column>Entity</Table.Column>
            <Table.Column align="right">Total Synced</Table.Column>
            <Table.Column align="right">Total in HubSpot</Table.Column>
          </Table.BoxHeader>
          <Table.Body>
            {data.results.map((result) => {
              return (
                <React.Fragment key={result.name}>
                  <Table.BoxRow style={{ fontWeight: 'bold' }}>
                    <Table.Cell>{result.name}</Table.Cell>
                    <Table.Cell align="right">{result.total}</Table.Cell>
                    <Table.Cell align="right">{result.totalHS}</Table.Cell>
                  </Table.BoxRow>
                </React.Fragment>
              );
            })}
          </Table.Body>
        </Table>
      </TableWrapper>
      {viewSync && <SyncLogDrawer id={viewSync} onClose={() => setViewSync()} />}
    </>
  );
}
