import { useWorkspace } from '~/contexts';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const defaultSettings = {
  default: null,
  valid: null,
  keys: [],
  multi: false,
  deserialize: (value) => value,
  serialize: (value) => value?.toString(),
};

function useSession({ sessionKey }) {
  const { workspace } = useWorkspace();
  const key = `${_.snakeCase(workspace.key)}_search_params_${sessionKey}`;

  const enabled = !!(sessionKey && sessionStorage);

  const get = useCallback(() => {
    if (!enabled) return;

    return sessionStorage.getItem(key);
  }, [enabled, key]);

  const set = useCallback(
    (value) => {
      if (!enabled) return;

      sessionStorage.setItem(key, value);
    },
    [enabled, key],
  );

  const hasValue = useCallback(() => {
    return !!get();
  }, [get]);

  return useMemo(() => {
    return { enabled, get, set, hasValue };
  }, [enabled, get, set, hasValue]);
}

export default function useSearchParams({ config, sessionKey, onChange }) {
  const location = useLocation();
  const history = useHistory();
  const searchRef = useRef(location.search);
  const session = useSession({ sessionKey });

  const get = useCallback(
    async (source = location.search) => {
      if (!source && session.enabled && session.hasValue()) {
        const search = session.get();
        searchRef.current = search;
        history.replace({ pathname: location.pathname, search });
        return false;
      }

      const urlSearchParams = new URLSearchParams(source);
      const params = {};

      for await (const key of Object.keys(config)) {
        // Skip if the configuration key is null
        if (config[key] != null) {
          // Merge the default settings with the config settings
          const settings = { ...defaultSettings, ...config[key] };

          const searchKey = settings.key ?? key;

          let value = settings.default;

          let searchParam;

          if (settings.multi) {
            // If multiple values are supported, initialize as an array
            if (!value) value = [];
            // Get all values and Remove duplicates
            searchParam = _.uniq(urlSearchParams.getAll(searchKey));
          } else {
            searchParam = urlSearchParams.get(searchKey);
          }

          let isValid = true;

          // Check if the search param is included in the set of valid values.
          // If any value is invalid, remove the key/value pair(s) and stop processing this param.
          for (const param of [searchParam].flat()) {
            if (param && settings.valid && !settings.valid.includes(param)) {
              // Remove the invalid search param
              urlSearchParams.delete(searchKey);
              isValid = false;
              break;
            }
          }

          if (!isValid) break;

          if (urlSearchParams.has(searchKey) || settings.keys.some((k) => urlSearchParams.has(k))) {
            try {
              value = (await settings.deserialize(searchParam, urlSearchParams)) ?? null;
              if (settings.multi) value = _.compact(value);
            } catch (error) {
              value = null;
              // Remove the invalid search param
              urlSearchParams.delete(searchKey);
            } finally {
              if (settings.multi && value == null) value = [];
            }
          }

          params[key] = value;
        }
      }

      const search = '?' + urlSearchParams.toString();

      // Clean invalid parameters
      if (new URLSearchParams(source).toString() !== urlSearchParams.toString()) {
        searchRef.current = search;
        history.replace({ pathname: location.pathname, search });
      }

      if (session.enabled) session.set(search);

      return params;
    },
    [location.pathname, location.search, config, history, session],
  );

  const set = useCallback(
    (values) => {
      const urlSearchParams = new URLSearchParams(location.search);

      for (const key in values) {
        if (_.has(config, key)) {
          const settings = { ...defaultSettings, ...config[key] };

          const value = settings.serialize(values[key]);

          const searchKey = settings.key ?? key;

          if (value) {
            if (_.isArray(value)) {
              urlSearchParams.delete(searchKey);

              for (const item of value) {
                urlSearchParams.append(searchKey, item);
              }
            } else if (_.isObject(value)) {
              for (const k in value) {
                const v = value[k];
                if (v) urlSearchParams.set(k, v);
                else urlSearchParams.delete(k);
              }
            } else {
              urlSearchParams.set(searchKey, value);
            }
          } else urlSearchParams.delete(searchKey);
        }
      }

      const search = '?' + urlSearchParams.toString();

      // Only update the URL if the params changed
      if (new URLSearchParams(location.search).toString() !== urlSearchParams.toString()) {
        searchRef.current = search;
        history.push({ pathname: location.pathname, search, state: { scrollToTop: false } });
      }

      if (session.enabled) session.set(search);

      return search;
    },
    [location.pathname, location.search, history, config, session],
  );

  // Listen to URL changes
  useEffect(() => {
    // Only fire the change event if the history event search is different from the previous location search
    if (location.search === searchRef.current) return;

    searchRef.current = location.search;

    if (onChange) {
      get(location.search).then(onChange);
    }
  }, [get, onChange, location.search]);

  const obj = useMemo(() => ({ get, set }), [get, set]);

  return obj;
}
