import path from 'path-browserify';
import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { BackLink, IconLink, Page } from '~/components';
import { useWorkspace } from '~/contexts';
import ClientDropdown from './ClientDropdown.jsx';

const resolveEditPath = (to, { pathname } = {}) =>
  pathname ? (pathname.endsWith(to) ? pathname : path.resolve(pathname, to)) : '';

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

function ClientHeader({ client }) {
  const location = useLocation();
  const { workspace } = useWorkspace();

  return (
    <Page.Header>
      <BackLink defaultPath={`/app/${workspace.key}/clients`} />
      <InfoContainer>
        <Page.Eyebrow>Client Dashboard</Page.Eyebrow>
        <ClientDropdown client={client} />
      </InfoContainer>

      {client.permissions.edit && (
        <Page.Actions>
          <IconLink
            tooltip="Client History"
            icon="history"
            to={`${resolveEditPath('history', location)}${location.search}`}
          />

          <IconLink
            tooltip="Edit Client"
            icon="pencil-alt"
            to={`${resolveEditPath('edit', location)}${location.search}`}
          />
        </Page.Actions>
      )}
    </Page.Header>
  );
}

export default ClientHeader;
