import _ from 'lodash';
import moment from 'moment';
import { dateFormats } from '~/utils';

const intervalOptions = {
  get all_dates() {
    return {
      key: 'all_dates',
      label: 'All Dates',
      start: null,
      end: null,
    };
  },
  get all_time() {
    return {
      key: 'all_time',
      label: 'All Time',
      start: null,
      end: null,
    };
  },
  get custom() {
    return {
      key: 'custom',
      label: 'Custom',
      start: undefined,
      end: undefined,
      unit: 'custom',
    };
  },
  get today() {
    return {
      key: 'today',
      label: 'Today',
      start: moment().startOf('day').format(dateFormats.isoDate),
      end: moment().endOf('day').format(dateFormats.isoDate),
      unit: 'day',
    };
  },
  get yesterday() {
    return {
      key: 'yesterday',
      label: 'Yesterday',
      start: moment().subtract(1, 'day').startOf('day').format(dateFormats.isoDate),
      end: moment().subtract(1, 'day').endOf('day').format(dateFormats.isoDate),
      unit: 'day',
    };
  },
  get this_week() {
    return {
      key: 'this_week',
      label: 'This Week',
      start: moment().startOf('isoWeek').format(dateFormats.isoDate),
      end: moment().endOf('isoWeek').format(dateFormats.isoDate),
      unit: 'week',
    };
  },
  get this_week_to_date() {
    return {
      key: 'this_week_to_date',
      label: 'This Week to Date',
      start: moment().startOf('isoWeek').format(dateFormats.isoDate),
      end: moment.min(moment().endOf('isoWeek'), moment()).format(dateFormats.isoDate),
      unit: 'custom',
    };
  },
  get this_semi_month() {
    return {
      key: 'this_semi_month',
      label: 'This Semi-Month',
      start: (moment().date() < 16 ? moment().startOf('month') : moment().date(16)).format(dateFormats.isoDate),
      end: (moment().date() < 16 ? moment().date(15) : moment().endOf('month')).format(dateFormats.isoDate),
      unit: 'semi_month',
    };
  },
  get this_semi_month_to_date() {
    return {
      key: 'this_semi_month_to_date',
      label: 'This Semi-Month to Date',
      start: (moment().date() < 16 ? moment().startOf('month') : moment().date(16)).format(dateFormats.isoDate),
      end: moment().format(dateFormats.isoDate),
      unit: 'custom',
    };
  },
  get this_month() {
    return {
      key: 'this_month',
      label: 'This Month',
      start: moment().startOf('month').format(dateFormats.isoDate),
      end: moment().endOf('month').format(dateFormats.isoDate),
      unit: 'month',
    };
  },
  get this_month_to_date() {
    return {
      key: 'this_month_to_date',
      label: 'This Month to Date',
      start: moment().startOf('month').format(dateFormats.isoDate),
      end: moment.min(moment().endOf('month'), moment()).format(dateFormats.isoDate),
      unit: 'custom',
    };
  },
  get this_quarter() {
    return {
      key: 'this_quarter',
      label: 'This Quarter',
      start: moment().startOf('quarter').format(dateFormats.isoDate),
      end: moment().endOf('quarter').format(dateFormats.isoDate),
      unit: 'quarter',
    };
  },
  get this_quarter_to_date() {
    return {
      key: 'this_quarter_to_date',
      label: 'This Quarter to Date',
      start: moment().startOf('quarter').format(dateFormats.isoDate),
      end: moment.min(moment().endOf('quarter'), moment()).format(dateFormats.isoDate),
      unit: 'custom',
    };
  },
  get this_year() {
    return {
      key: 'this_year',
      label: 'This Year',
      start: moment().startOf('year').format(dateFormats.isoDate),
      end: moment().endOf('year').format(dateFormats.isoDate),
      unit: 'year',
    };
  },
  get this_year_to_date() {
    return {
      key: 'this_year_to_date',
      label: 'This Year to Date',
      start: moment().startOf('year').format(dateFormats.isoDate),
      end: moment.min(moment().endOf('year'), moment()).format(dateFormats.isoDate),
      unit: 'custom',
    };
  },
  get last_week() {
    return {
      key: 'last_week',
      label: 'Last Week',
      start: moment().startOf('isoWeek').subtract(1, 'week').format(dateFormats.isoDate),
      end: moment().subtract(1, 'week').endOf('isoWeek').format(dateFormats.isoDate),
      unit: 'week',
    };
  },
  get last_semi_month() {
    return {
      key: 'last_semi_month',
      label: 'Last Semi-Month',
      start: (moment().date() >= 16 ? moment().startOf('month') : moment().subtract(1, 'month').date(16)).format(
        dateFormats.isoDate,
      ),
      end: (moment().date() >= 16 ? moment().date(15) : moment().subtract(1, 'month').endOf('month')).format(
        dateFormats.isoDate,
      ),
      unit: 'semi_month',
    };
  },
  get last_month() {
    return {
      key: 'last_month',
      label: 'Last Month',
      start: moment().subtract(1, 'month').startOf('month').format(dateFormats.isoDate),
      end: moment().subtract(1, 'month').endOf('month').format(dateFormats.isoDate),
      unit: 'month',
    };
  },
  get last_quarter() {
    return {
      key: 'last_quarter',
      label: 'Last Quarter',
      start: moment().startOf('quarter').subtract(1, 'quarter').format(dateFormats.isoDate),
      end: moment().subtract(1, 'quarter').endOf('quarter').format(dateFormats.isoDate),
      unit: 'quarter',
    };
  },
  get last_year() {
    return {
      key: 'last_year',
      label: 'Last Year',
      start: moment().startOf('year').subtract(1, 'year').format(dateFormats.isoDate),
      end: moment().subtract(1, 'year').endOf('year').format(dateFormats.isoDate),
      unit: 'year',
    };
  },
  get past_7_days() {
    return {
      key: 'past_7_days',
      label: 'Past 7 days',
      start: moment().subtract(7, 'days').format(dateFormats.isoDate),
      end: moment().format(dateFormats.isoDate),
      unit: 'custom',
    };
  },
  get past_30_days() {
    return {
      key: 'past_30_days',
      label: 'Past 30 days',
      start: moment().subtract(30, 'days').format(dateFormats.isoDate),
      end: moment().format(dateFormats.isoDate),
      unit: 'custom',
    };
  },
  get past_60_days() {
    return {
      key: 'past_60_days',
      label: 'Past 60 days',
      start: moment().subtract(60, 'days').format(dateFormats.isoDate),
      end: moment().format(dateFormats.isoDate),
      unit: 'custom',
    };
  },
  get past_90_days() {
    return {
      key: 'past_90_days',
      label: 'Past 90 days',
      start: moment().subtract(90, 'days').format(dateFormats.isoDate),
      end: moment().format(dateFormats.isoDate),
      unit: 'custom',
    };
  },
  get past_180_days() {
    return {
      key: 'past_180_days',
      label: 'Past 180 days',
      start: moment().subtract(180, 'days').format(dateFormats.isoDate),
      end: moment().format(dateFormats.isoDate),
      unit: 'custom',
    };
  },
  get past_365_days() {
    return {
      key: 'past_365_days',
      label: 'Past 365 days',
      start: moment().subtract(365, 'days').format(dateFormats.isoDate),
      end: moment().format(dateFormats.isoDate),
      unit: 'custom',
    };
  },
  get past_4_weeks() {
    return {
      key: 'past_4_weeks',
      label: 'Past 4 weeks',
      start: moment().subtract(4, 'weeks').startOf('isoWeek').format(dateFormats.isoDate),
      end: moment().subtract(1, 'week').endOf('isoWeek').format(dateFormats.isoDate),
      unit: 'week',
    };
  },
  get past_12_weeks() {
    return {
      key: 'past_12_weeks',
      label: 'Past 12 weeks',
      start: moment().subtract(12, 'weeks').startOf('isoWeek').format(dateFormats.isoDate),
      end: moment().subtract(1, 'weeks').endOf('isoWeek').format(dateFormats.isoDate),
      unit: 'week',
    };
  },
  get past_26_weeks() {
    return {
      key: 'past_26_weeks',
      label: 'Past 26 weeks',
      start: moment().subtract(26, 'weeks').startOf('isoWeek').format(dateFormats.isoDate),
      end: moment().subtract(1, 'weeks').endOf('isoWeek').format(dateFormats.isoDate),
      unit: 'week',
    };
  },
  get past_52_weeks() {
    return {
      key: 'past_52_weeks',
      label: 'Past 52 weeks',
      start: moment().subtract(52, 'weeks').startOf('isoWeek').format(dateFormats.isoDate),
      end: moment().subtract(1, 'weeks').endOf('isoWeek').format(dateFormats.isoDate),
      unit: 'week',
    };
  },
};

const timePeriodPrecedence = [
  'this_month',
  'last_month',
  'this_week',
  'last_week',
  'this_year',
  'last_year',
  'this_quarter',
  'last_quarter',
  'this_semi_month',
  'last_semi_month',
  'this_month_to_date',
  'this_year_to_date',
  'this_week_to_date',
];

export const sortIntervals = (intervals) =>
  _.sortBy(intervals, (interval) => {
    const position = timePeriodPrecedence.indexOf(interval.key);
    return position === -1 ? Infinity : position;
  });

export default intervalOptions;
