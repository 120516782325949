import styled from 'styled-components';
import { colors } from '~/styles';
import Icon from './Icon';

const ErrorContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ErrorMessage = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const WarningIcon = styled(Icon)`
  margin-right: 0.5rem;
  color: ${colors.warning};
  font-size: 1.25rem;
`;

const Files = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0.5rem -0.5rem;
  opacity: ${({ fade }) => (fade ? 0.2 : 1)};
`;

const NoFilesMessage = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;
  color: ${colors.grey40};
`;

Files.ErrorContainer = ErrorContainer;
Files.ErrorMessage = ErrorMessage;
Files.WarningIcon = WarningIcon;
Files.NoFilesMessage = NoFilesMessage;

export default Files;
