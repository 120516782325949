import { Button, Buttons, CancelButton, Field, Form, FormMessage, Icon, ModalCard, Tooltip } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useFeatures, useForm } from '~/hooks';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import colors from '~/styles/colors';
import { emptyStringToNull } from '~/utils';
import * as Yup from 'yup';

function DuplicateClientWarning({ client }) {
  return (
    <Tooltip
      placement="left"
      style={{ display: 'flex', alignItems: 'center', padding: '0', margin: '0rem' }}
      message={
        <>
          There is already a client named <b>{client.name}</b>.
        </>
      }>
      <Icon icon="exclamation-triangle" style={{ fontSize: '1.5rem' }} color={colors.warning} />
    </Tooltip>
  );
}

function ClientCreateForm({ onClose, onSaved }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const features = useFeatures();
  const [{ status, message, isSubmitting }, form] = useForm();
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);

  async function handleSubmit(values) {
    try {
      form.submit();

      const body = emptyStringToNull({
        ..._.omit(values, ['businessUnit', 'owner']),
        businessUnitId: values.businessUnit?.id ?? null,
        ownerId: values.owner?.id ?? null,
      });
      const { data } = await api.www.workspaces(workspace.id).clients().upsert(body);

      await onSaved(data);

      form.done();
    } catch ({ message }) {
      form.error({ message });
    }
  }

  const initialValues = {
    businessUnit: null,
    name: '',
    currency: workspace.currency,
    owner: null,
  };

  const handleClose = () => dirtyCheck(onClose);

  const [blockSubmit, setBlockSubmit] = useState();
  const [duplicateClient, setDuplicateClient] = useState(null);

  const findDuplicate = async (name) => {
    if (name) {
      const { data: client } = await api.www.workspaces(workspace.id).clients().findDuplicate({ name });
      return client ? client : null;
    } else {
      return null;
    }
  };

  const handleNameChange = useRef(
    _.debounce(async ({ target: { value } }) => {
      setDuplicateClient(await findDuplicate(value));
      setBlockSubmit(false);
    }, 300),
  ).current;

  return (
    <ModalCard title="New Client" onClose={handleClose}>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          currency: Yup.string().label('Currency').nullable().required(),
          name: Yup.string().label('Name').max(255).trim().required(),
          owner: Yup.object().label('Owner').nullable(),
        })}>
        {(formik) => {
          return (
            <Form>
              <ModalCard.Body>
                <>
                  <Form.Control>
                    <Field.Text
                      onChange={(event) => {
                        setBlockSubmit(true);
                        formik.handleChange(event);
                        handleNameChange(event);
                      }}
                      autoFocus
                      name="name"
                      style={{ minWidth: '37rem' }}
                      placeholder="Client Name"
                      maxLength={255}
                    />

                    {duplicateClient && formik.values.name && <DuplicateClientWarning client={duplicateClient} />}
                  </Form.Control>
                </>

                {features.businessUnits && (
                  <Form.Control>
                    <Field.BusinessUnitSelect name="businessUnit" placeholder="Business Unit" />
                  </Form.Control>
                )}

                <Form.Control>
                  <Field.WorkspaceCurrencySelect
                    name="currency"
                    placeholder="Currency"
                    clearable={false}
                    disabled={!features.multicurrency}
                  />
                </Form.Control>

                <Form.Control>
                  <Field.MemberSelect name="owner" placeholder="Relationship Owner" />
                </Form.Control>
                {status && <FormMessage.Error>{message || 'An error has occurred.'}</FormMessage.Error>}
              </ModalCard.Body>

              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={handleClose}>Close</CancelButton>
                  <Button onClick={formik.submitForm} disabled={blockSubmit} isLoading={isSubmitting}>
                    Save
                  </Button>
                </Buttons>
              </ModalCard.Footer>
            </Form>
          );
        }}
      </Formik>
    </ModalCard>
  );
}

export default ClientCreateForm;
