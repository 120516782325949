import _ from 'lodash';
import React from 'react';
import { memberCostMethods } from '~/lookups';
import SingleSelectFilter from './SingleSelectFilter';

const defaultOptions = _.values(memberCostMethods);

export default function MemberCostMethodFilter({
  placeholder = 'Member Labor Cost',
  name,
  value,
  onChange,
  options = defaultOptions,
}) {
  return (
    <SingleSelectFilter
      icon="filter"
      placeholder={placeholder}
      name={name}
      value={value}
      renderValue={(value) => value.name}
      options={options}
      onChange={onChange}
    />
  );
}
