import { Checkbox, HelpTooltip, Inline, Percent, RouteLink, Widget } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useActions } from '~/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '~/styles';
import { QueryString } from '~/utils';

const Rate = styled.div`
  font-size: 3rem;

  sup {
    font-size: 1.5rem;
  }

  ${({ blurred }) =>
    blurred &&
    css`
      opacity: 0.2;
    `}
`;

const Footer = styled.div`
  margin-top: 1.5rem;
  border-top: 1px solid ${colors.grey10};
  width: calc(100% + 2.5rem);
  margin-left: -1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0 0.75rem;
  position: relative;
`;

const Target = styled.div`
  position: absolute;
  top: -1rem;
  border-radius: 1rem;
  min-width: 9.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  text-align: center;

  ${({ status }) =>
    ({
      green: css`
        color: ${colors.success};
        background-color: ${colors.success10};
      `,
      yellow: css`
        color: ${colors.warning};
        background-color: ${colors.warning10};
      `,
      red: css`
        color: ${colors.danger};
        background-color: ${colors.danger10};
      `,
    })[status]}
`;

function getLabel({ status, utilization, utilizationTarget }) {
  switch (status) {
    case 'green':
      return utilization - utilizationTarget === 0 ? `On Target of` : `Above Target of`;
    default:
      return `Below Target of`;
  }
}

const getReport = ({ data, adjustForTimeOff }) => {
  if (!data) return null;

  const report = adjustForTimeOff
    ? {
        utilization: data.utilizationTimeOffAdjusted,
        status: data.statusTimeOffAdjusted,
      }
    : {
        utilization: data.utilization,
        status: data.status,
      };

  report.utilizationTarget = data.utilizationTarget;
  report.label = getLabel({
    ...report,
    utilizationTarget: data.utilizationTarget,
  });

  return report;
};

const initialState = { isReady: false, data: null };
const handlers = { load: () => ({ isReady: false }), ready: ({ data }) => ({ isReady: true, data }) };

function MemberUtilizationWidget({ start, end, types, title, helpMessage, showTarget, memberId }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const [{ data, isReady }, actions] = useActions(handlers, initialState);
  const [adjustForTimeOff, setAdjustForTimeOff] = useState(workspace.defaultAdjustForTimeOff);

  const fetchData = useCallback(async () => {
    actions.load();

    const { data } = await api.www
      .workspaces(workspace.id)
      .personalDashboard()
      .utilizationReport({ start, end, types, memberId });

    actions.ready({ data });
  }, [actions, workspace.id, api, start, end, types, memberId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const report = getReport({ data, adjustForTimeOff });
  const utilizationByMember = (query = {}) =>
    `/app/${workspace.key}/reports/utilization-by-member${new QueryString(
      {
        start,
        end,
        member: memberId,
        memberBillableType: 'all',
        employmentType: 'all',
        ...query,
      },
      { multi: true },
    ).toString(true)}`;

  return (
    <Widget stretched centered loading={!isReady}>
      <Widget.Header>
        <Widget.Help message={helpMessage} />
        <Widget.Title>{title}</Widget.Title>
      </Widget.Header>
      <Widget.Preview style={{ minHeight: '4.1875rem' }}></Widget.Preview>
      <Widget.Content>
        <Rate blurred={report?.utilization == null}>
          {report?.utilization != null ? (
            <RouteLink to={utilizationByMember()}>
              <Percent value={report.utilization} minimumFractionDigits={0} maximumFractionDigits={1} />
            </RouteLink>
          ) : (
            'N/A'
          )}
        </Rate>
      </Widget.Content>
      <Footer>
        {showTarget && report?.utilizationTarget > 0 && report?.status ? (
          <Target status={report.status}>
            {report.label}{' '}
            <Percent value={report.utilizationTarget} minimumFractionDigits={0} maximumFractionDigits={1} />
          </Target>
        ) : null}
        <Inline>
          <Checkbox
            label="Adjust for Time Off"
            checked={adjustForTimeOff}
            onChange={() => setAdjustForTimeOff(!adjustForTimeOff)}
          />
          <HelpTooltip
            style={{ marginLeft: '.5rem' }}
            message="Reduces the capacity by the number of Time Off hours taken during the period."
          />
        </Inline>
      </Footer>
    </Widget>
  );
}

export default MemberUtilizationWidget;
