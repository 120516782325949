import { Hours } from '~/components';
import { mix } from 'polished';
import React, { useMemo } from 'react';
import { theme } from '../../components/schedule';
import { GroupCell } from '../../components/schedule/cells';
import CellHeatmap from './CellHeatmap';
import convertScale from './convertScale';
import Tooltip from './Tooltip';

function ProductiveHoursCell({ cell }) {
  const { background, color, heatmap } = useMemo(() => {
    switch (cell.mode) {
      case 'header':
        return {
          background: theme.cell.total.background,
          color: !cell.productive || !cell.hasWorkingDays ? theme.cell.empty.color : theme.cell.total.color,
        };

      case 'heatmap': {
        const ratio = cell.productive / cell.capacity || 0;

        let background;
        let color;
        let heatmap;

        if (!cell.productive || !cell.hasWorkingDays) {
          background = theme.cell.empty.background;
          color = theme.cell.empty.color;
        } else if (ratio > 1) {
          background = theme.cell.filled.background;
          heatmap = mix(
            Math.min(convertScale(ratio, { min: 1, max: 1.25 }, { min: 0, max: 1 }), 1),
            theme.cell.heatmap.danger.dark,
            theme.cell.heatmap.danger.light,
          );
          color = theme.cell.filled.color;
        } else {
          background = theme.cell.filled.background;
          heatmap = mix(ratio, theme.cell.heatmap.success.dark, theme.cell.heatmap.success.light);
          color = theme.cell.filled.color;
        }

        return {
          background,
          color,
          heatmap,
        };
      }

      case 'placeholder':
        return {
          background: cell.productive === 0 ? theme.cell.empty.background : theme.cell.filled.background,
          color: cell.productive === 0 || !cell.hasWorkingDays ? theme.cell.empty.color : theme.cell.filled.color,
        };
    }
  }, [cell.mode, cell.productive, cell.capacity, cell.hasWorkingDays]);

  return (
    <GroupCell style={{ background, color }}>
      <Tooltip cell={cell}>
        {cell.hasWorkingDays ? <Hours value={cell.productive} minimumFractionDigits={0} /> : '-'}
        {cell.mode === 'heatmap' && <CellHeatmap style={{ background: heatmap }} />}
      </Tooltip>
    </GroupCell>
  );
}

export default React.memo(ProductiveHoursCell);
