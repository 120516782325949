import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Confirmation,
  CreateButton,
  DateTime,
  Files,
  FiltersBar,
  HelpTooltip,
  Icon,
  IconButton,
  ListView,
  ListViewActions,
  ListViewMenu,
  Page,
  SearchInput,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useSearchParams, useSearchParamsConfig } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { weights } from '~/styles';
import { QuerySort } from '~/utils';
import ClientFilesDrawer from './ClientFilesDrawer';
import FilePreview from './FilePreview';

const Title = styled.h3`
  font-size: 1.25rem;
  font-weight: ${weights.light};
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export default function ClientFilesTab({ client }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const confirmation = useConfirmation();
  const [showDrawer, setShowDrawer] = useState(false);

  const [query, setQuery] = useState({ status: 'loading', data: null });
  const [params, setParams] = useState({
    q: '',
    sort: new QuerySort('name', 'asc'),
    view: 'list',
  });

  // Init and sync URL search params
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParamsConfig = useSearchParamsConfig();
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        q: { default: params.q },
        sort: { default: params.sort, ...searchParamsConfig.sort },
        view: { default: 'list', valid: ['grid', 'list'] },
      }),
      [params, searchParamsConfig],
    ),
    sessionKey: 'client_files_tab',
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      q: params.q,
      sort: params.sort,
    }),
    [params],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      if (params) {
        setParams((state) => ({ ...state, ...params }));
        setSearchParamsStatus('ready');
      }
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).clients(client.id).files().get(urlSearchParams);
      setQuery({ status: 'ready', data, error: null });
    } catch (error) {
      setQuery({ status: 'ready', data: null, error });
    }
  }, [api, workspace.id, client.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const hasFiles = useMemo(() => query.data?.length > 0, [query.data]);
  const hasConfidentialFiles = useMemo(() => _.some(query.data, { isConfidential: true }), [query.data]);
  const hasPublicFiles = useMemo(() => _.some(query.data, { isConfidential: false }), [query.data]);

  const handleChange = ({ target: { name, value } }) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    setParams((params) => ({ ...params, [name]: value }));
    searchParams.set({ [name]: value });
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams((state) => ({ ...state, sort: querySort }));
    searchParams.set({ sort: querySort });
  };

  const handleViewChange = (view) => {
    setQuery((state) => ({ ...state, status: 'filtering' }));
    const querySort = new QuerySort('name', 'asc');
    setParams((state) => ({ ...state, q: '', sort: querySort, view }));
    searchParams.set({ q: null, sort: null, view });
  };

  async function handleDeleteConfirmation(file) {
    const confirm = await confirmation.prompt((resolve) => (
      <Confirmation resolve={resolve}>Are you sure you want to delete this file?</Confirmation>
    ));

    if (!confirm) return;

    await api.www.workspaces(workspace.id).clients(client.id).files(file.id).delete();
    await fetchData();
  }

  return (
    <>
      <Page.TabHeader>
        <Page.Title>Files</Page.Title>

        <Page.Actions>
          {
            {
              list: (
                <IconButton
                  tooltip="Grid View"
                  icon="grid-2"
                  data-testid="grid-button"
                  onClick={() => handleViewChange('grid')}
                />
              ),
              grid: (
                <IconButton
                  tooltip="List View"
                  icon="table-rows"
                  data-testid="list-button"
                  onClick={() => handleViewChange('list')}
                />
              ),
            }[params.view]
          }

          <CreateButton
            tooltip={client.permissions.edit ? 'Add Files' : 'Your security role prohibits you from adding files.'}
            onClick={() => setShowDrawer(true)}
            disabled={!client.permissions.edit}
          />
        </Page.Actions>
      </Page.TabHeader>

      <Page.Filters>
        <FiltersBar>
          {params.view === 'list' && <SearchInput value={params.q} placeholder="Search" onChange={handleChange} />}
        </FiltersBar>
      </Page.Filters>

      {query.status === 'loading' ? (
        <PageLoader />
      ) : query.error ? (
        <Files.ErrorContainer>
          <Files.ErrorMessage>
            <Files.WarningIcon icon="exclamation-triangle" />
            There was a problem getting the client files
          </Files.ErrorMessage>
          <Button onClick={() => fetchData()}>Retry</Button>
        </Files.ErrorContainer>
      ) : (
        <>
          {
            {
              list: (
                <Page.ListView>
                  <ListView data-testid="members-list">
                    <ListView.Header>
                      <ListView.Column sticky minWidth="16rem" name="name" onSort={handleSort} sort={params.sort}>
                        Filename
                      </ListView.Column>
                      <ListView.Column minWidth="16rem" name="isConfidential" onSort={handleSort} sort={params.sort}>
                        Access
                      </ListView.Column>
                      <ListView.Column
                        width="8rem"
                        align="right"
                        name="createdAt"
                        onSort={handleSort}
                        sort={params.sort}>
                        Created
                      </ListView.Column>

                      <ListViewActions.Column />
                    </ListView.Header>

                    <ListView.Body fade={query.status !== 'ready'}>
                      {query.data.map((file) => {
                        const { id, name, url, createdAt, isConfidential } = file;

                        return (
                          <ListView.Row key={id} onClick={() => window.open(url, '_blank')}>
                            <ListView.Cell>{name}</ListView.Cell>
                            <ListView.Cell>{isConfidential ? 'Administrators' : 'Team'}</ListView.Cell>
                            <ListView.Cell>
                              <DateTime value={createdAt} />
                            </ListView.Cell>
                            <ListViewActions>
                              <Button onClick={() => window.open(url, '_blank')}>
                                <Icon icon="external-link-alt" />
                              </Button>

                              <hr />

                              <ListViewMenu>
                                {({ setIsOpen }) => {
                                  const handleAction = (action) => setIsOpen(false) || action;

                                  return (
                                    <>
                                      <ListViewMenu.Item onClick={() => handleAction(window.open(url, '_blank'))}>
                                        View
                                      </ListViewMenu.Item>
                                      <ListViewMenu.Item onClick={() => handleAction(window.open(url + '?download=1'))}>
                                        Download
                                      </ListViewMenu.Item>
                                      <ListViewMenu.Item
                                        disabled={!client.permissions.edit}
                                        tooltip={
                                          !client.permissions.edit
                                            ? 'Insufficient permissions to delete this file.'
                                            : undefined
                                        }
                                        onClick={() => handleAction(handleDeleteConfirmation(file))}>
                                        Delete
                                      </ListViewMenu.Item>
                                    </>
                                  );
                                }}
                              </ListViewMenu>
                            </ListViewActions>
                          </ListView.Row>
                        );
                      })}

                      {query.data.length === 0 && <ListView.Empty />}
                    </ListView.Body>

                    <ListView.Status
                      total={query.data?.length}
                      label="Client File"
                      isLoading={query.status !== 'ready'}
                    />
                  </ListView>
                </Page.ListView>
              ),
              grid: (
                <>
                  {client.permissions.edit && hasConfidentialFiles && (
                    <>
                      <Title>
                        Administrator Files{' '}
                        <HelpTooltip message="These files are accessible to workspace members that can edit this client." />
                      </Title>
                      <Files fade={query.status !== 'ready'}>
                        {_(query.data)
                          .filter({ isConfidential: true })
                          .map((file) => <FilePreview key={file.id} file={file} onRemove={handleDeleteConfirmation} />)
                          .value()}
                      </Files>
                    </>
                  )}
                  {hasPublicFiles && (
                    <>
                      <Title>
                        Team Files{' '}
                        <HelpTooltip message="These files are accessible to workspace members that can access this client." />
                      </Title>

                      <Files fade={query.status !== 'ready'}>
                        {_(query.data)
                          .filter({ isConfidential: false })
                          .map((file) => <FilePreview key={file.id} file={file} onRemove={handleDeleteConfirmation} />)
                          .value()}
                      </Files>
                    </>
                  )}
                  {!hasFiles && <Files.NoFilesMessage>No Files have been uploaded.</Files.NoFilesMessage>}
                </>
              ),
            }[params.view]
          }
        </>
      )}

      {showDrawer && (
        <ClientFilesDrawer client={client} onClose={() => setShowDrawer(false)} onChanged={() => fetchData()} />
      )}
    </>
  );
}
