import React, { useCallback, useEffect, useState } from 'react';
import { Link, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import {
  DeleteConfirmation,
  ExportDialog,
  Icon,
  Tooltip,
  ExportDropdown,
  Page,
  BackLink,
  FiltersBar,
  ListView,
  ListViewActions,
  ListViewMenu,
  Dropdown,
  IconButton,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import { ActiveStatusSelect } from '../custom-data/components';
import HolidayScheduleDrawer from './HolidayScheduleDrawer';
import { mimeTypes } from '~/utils';
import AvaliableSchedulesModal from './AvailableSchedulesModal';

function HolidaySchedulesPage() {
  const title = 'Holiday Schedules';
  const documentTitle = useDocumentTitle(title);

  const api = useApi();
  const confirmation = useConfirmation();
  const toast = useToast();
  const { workspace } = useWorkspace();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const [data, setData] = useState([]);
  const [params, setParams] = useState({ isActive: 'true' });

  const [showAvailableSchedulesModal, setShowAvailableSchedulesModal] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .holidaySchedules()
        .get({ ...params, isActive: params.isActive ?? undefined });
      setData(data);
    } catch {
      setData([]);
    }
  }, [api, workspace, params]);

  useEffect(() => {
    fetchData();
    return fetchData.cancel;
  }, [fetchData]);

  function handleEdit(item) {
    history.push(url.concat(`/${item.id}/edit`));
  }

  async function handleDelete(item) {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>
        Are you sure that you want to delete this Holiday Schedule?
      </DeleteConfirmation>
    ));
    if (!confirm) {
      return false;
    }

    await api.www.workspaces(workspace.id).holidaySchedules(item.id).delete();
    fetchData();
    return true;
  }

  async function handleActiveStatusChange(item, flag) {
    try {
      await api.www.workspaces(workspace.id).holidaySchedules(item.id).setActiveStatus(flag);
      fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  async function handleDefaultStatusChange(item, flag) {
    try {
      await api.www.workspaces(workspace.id).holidaySchedules(item.id).setDefaultStatus(flag);
      fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  function handleCloseDrawer() {
    history.push(`/app/${workspace.key}/settings/custom-data/holiday-schedules`);
    documentTitle.set(title);
  }

  const handleChange = ({ target: { name, value } }) => {
    setParams({ ...params, [name]: value });
  };

  const handleExport = async (filename, mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onClose={resolve}
        onLoad={api.www
          .workspaces(workspace.id)
          .holidaySchedules()
          .get(
            { ...params, isActive: params.isActive ?? undefined },
            { headers: { accept: mimeType }, responseType: 'blob' },
          )}
      />
    ));
  };

  return (
    <>
      <Page.Header>
        <BackLink defaultPath={`/app/${workspace.key}/settings/custom-data`} />

        <Page.Info>
          <Page.Title>Holiday Schedules</Page.Title>
          <Page.Description>
            You can create multiple holiday schedules for your workspace and apply a holiday schedule to each member.
            This allows you to have a different set of{' '}
            <Link to={`/app/${workspace.key}/settings/custom-data/holidays`}>holidays</Link> for each country where you
            have personnel.
          </Page.Description>
        </Page.Info>

        <Page.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('holiday_schedules.csv', mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('holiday_schedules.xlsx', mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <Dropdown align="right">
            {({ setIsOpen }) => (
              <>
                <Dropdown.Trigger>
                  <IconButton icon="plus" onClick={() => setIsOpen(true)} tooltip="New Holiday Schedule" />
                </Dropdown.Trigger>

                <Dropdown.Menu style={{ width: '16rem' }}>
                  <Dropdown.Link to={`${url}/new${location.search}`}>New Holiday Schedule</Dropdown.Link>
                  <Dropdown.Link
                    onClick={() => {
                      setIsOpen(false);
                      setShowAvailableSchedulesModal(true);
                    }}>
                    Add from Available Schedules
                  </Dropdown.Link>
                </Dropdown.Menu>
              </>
            )}
          </Dropdown>
        </Page.Actions>
      </Page.Header>

      <Page.Filters>
        <FiltersBar>
          <ActiveStatusSelect value={params.isActive} onChange={handleChange} />
        </FiltersBar>
      </Page.Filters>

      <Page.ListView>
        <ListView>
          <ListView.Header>
            <ListView.Column width="15rem">Name</ListView.Column>
            <ListView.Column>Description</ListView.Column>
            <ListView.Column width="11.5rem" align="right">
              Number of Holidays
            </ListView.Column>
            <ListViewActions.Column />
          </ListView.Header>
          <ListView.Body>
            {data.map((item) => (
              <ListView.Row key={item.id} onClick={() => handleEdit(item)} isDisabled={!item.isActive}>
                <ListView.Cell>
                  <div>
                    {item.name}
                    {item.isDefault && (
                      <Tooltip
                        placement="right"
                        style={{ display: 'inline', marginLeft: '0.5rem' }}
                        message="The default holiday schedule for newly invited members.">
                        <Icon icon="badge-check" />
                      </Tooltip>
                    )}
                  </div>
                </ListView.Cell>
                <ListView.Cell>{item.description}</ListView.Cell>
                <ListView.Cell>{item.holidays.length}</ListView.Cell>
                <ListViewActions>
                  <ListViewActions.Edit onClick={() => handleEdit(item)} />
                  <hr />
                  <ListViewMenu>
                    {({ setIsOpen }) => (
                      <>
                        <ListViewMenu.Item
                          onClick={() => {
                            handleEdit(item);
                            setIsOpen(false);
                          }}>
                          Edit
                        </ListViewMenu.Item>

                        {((!item.isDefault && item.isActive) || item.isDefault) && (
                          <ListViewMenu.Item
                            onClick={async () => {
                              await handleDefaultStatusChange(item, !item.isDefault);
                              setIsOpen(false);
                            }}>
                            {item.isDefault ? 'Unset as default' : 'Set as default'}
                          </ListViewMenu.Item>
                        )}

                        <ListViewMenu.Item
                          onClick={async () => {
                            await handleActiveStatusChange(item, !item.isActive);
                            setIsOpen(false);
                          }}>
                          {item.isActive ? 'Deactivate' : 'Activate'}
                        </ListViewMenu.Item>

                        <ListViewMenu.DeleteItem
                          tooltip="One or more members are currently assigned to this holiday schedule."
                          onCheckDependencies={async (workspace) =>
                            (await workspace.holidaySchedules(item.id).hasDependencies()).data
                          }
                          onClick={async () => {
                            await handleDelete(item);
                            setIsOpen(false);
                          }}>
                          Delete
                        </ListViewMenu.DeleteItem>
                      </>
                    )}
                  </ListViewMenu>
                </ListViewActions>
              </ListView.Row>
            ))}

            {data.length === 0 && <ListView.Empty />}
          </ListView.Body>
          <ListView.Status total={data.length} label="Holiday Schedule" />
        </ListView>
      </Page.ListView>

      {showAvailableSchedulesModal && (
        <AvaliableSchedulesModal onChange={fetchData} onClose={() => setShowAvailableSchedulesModal(false)} />
      )}

      <Switch>
        <Route path={`${path}/new`}>
          <HolidayScheduleDrawer onClose={handleCloseDrawer} onSaved={fetchData} />
        </Route>
        <Route path={`${path}/:holidayScheduleId/edit`}>
          <HolidayScheduleDrawer onClose={handleCloseDrawer} onDelete={handleDelete} onSaved={fetchData} />
        </Route>
      </Switch>
    </>
  );
}

export default HolidaySchedulesPage;
