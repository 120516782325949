import Big from 'big.js';
import React, { useCallback, useEffect, useState } from 'react';
import {
  BackLink,
  Currency,
  DateTime,
  DeleteConfirmation,
  ExportDialog,
  ExportDropdown,
  IconButton,
  ListView,
  ListViewActions,
  ListViewMenu,
  Page,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import { mimeTypes } from '~/utils';
import ExchangeRatePeriod from './ExchangeRatePeriodDrawer';

export default function ExchangeRatePeriodsPage() {
  const documentTitle = useDocumentTitle('Exchange Rate Periods');

  const api = useApi();
  const { workspace } = useWorkspace();
  const confirmation = useConfirmation();
  const toast = useToast();

  const [query, setQuery] = useState({ isReady: false, data: null, error: null });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).exchangeRatePeriods().get();
      setQuery({ isReady: true, data });
    } catch (error) {
      setQuery({ isReady: true, data: null, error });
    }
  }, [workspace.id, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [dialog, setDialog] = useState(null);

  const handleExport = async (filename, mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onClose={resolve}
        onLoad={api.www
          .workspaces(workspace.id)
          .exchangeRatePeriods()
          .get({}, { headers: { accept: mimeType }, responseType: 'blob' })}
      />
    ));
  };

  const handleEdit = async (exchangeRatePeriod) => {
    setDialog(
      <ExchangeRatePeriod
        exchangeRatePeriod={exchangeRatePeriod}
        onClose={() => {
          setDialog(null);
          documentTitle.set('Exchange Rate Periods');
        }}
        onDeleted={fetchData}
        onSaved={fetchData}
      />,
    );
  };

  async function handleDelete(period) {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>
        Are you sure you want to delete this Exchange Rate Period?
      </DeleteConfirmation>
    ));
    if (!confirm) return;

    try {
      await api.www
        .workspaces(workspace.id)
        .exchangeRatePeriods(period ? period.id : undefined)
        .delete();
      await fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  if (!query.isReady) return <PageLoader />;
  if (!query.data) return <ErrorPage publicSite={false} />;

  const data = query.data;

  return (
    <>
      <Page.Header>
        <BackLink defaultPath={`/app/${workspace.key}/settings/custom-data`} />

        <Page.Info>
          <Page.Title>Exchange Rate Periods</Page.Title>
          <Page.Description>
            Exchange rate periods allow you to set custom exchange rates for specific date ranges. This is useful when
            you need to override the default exchange rates.
          </Page.Description>
        </Page.Info>

        <Page.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('exchangeRatePeriods.csv', mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('exchangeRatePeriods.xlsx', mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <IconButton icon="plus" tooltip="New Exchange Rate Period" onClick={() => handleEdit({})} />
        </Page.Actions>
      </Page.Header>
      <Page.ListView>
        <ListView>
          <ListView.Header>
            <ListView.Column width="10rem">Start Date</ListView.Column>
            <ListView.Column width="10rem">End Date</ListView.Column>
            <ListView.Column minWidth="16rem">Exchange Rate</ListView.Column>
            <ListViewActions.Column />
          </ListView.Header>
          <ListView.Body>
            {data.map((item) => {
              const { id, start, end, fromCurrency, toCurrency, rate } = item;

              return (
                <ListView.Row onClick={() => handleEdit(item)} key={id}>
                  <ListView.Cell>
                    <DateTime value={start} />
                  </ListView.Cell>

                  <ListView.Cell>
                    <DateTime value={end} />
                  </ListView.Cell>

                  <ListView.Cell>
                    <div>
                      <div>
                        <Currency currency={fromCurrency} value={1} currencyDisplay="code" /> ={' '}
                        <Currency currency={toCurrency} value={rate} currencyDisplay="code" />
                      </div>

                      <div>
                        <Currency currency={toCurrency} value={1} currencyDisplay="code" /> ={' '}
                        <Currency
                          currency={fromCurrency}
                          value={Big(1).div(rate).round(12).toNumber()}
                          currencyDisplay="code"
                        />
                      </div>
                    </div>
                  </ListView.Cell>

                  <ListViewActions>
                    <>
                      <ListViewActions.Edit onClick={() => handleEdit(item)} />

                      <hr />

                      <ListViewMenu>
                        {() => (
                          <>
                            <ListViewMenu.Item onClick={() => handleEdit(item)}>Edit</ListViewMenu.Item>
                            <ListViewMenu.DeleteItem onClick={() => handleDelete(item)}>Delete</ListViewMenu.DeleteItem>
                          </>
                        )}
                      </ListViewMenu>
                    </>
                  </ListViewActions>
                </ListView.Row>
              );
            })}

            {data.length === 0 && <ListView.Empty />}
          </ListView.Body>
          <ListView.Status total={data.length} label="Exchange Rate Period" />
        </ListView>
      </Page.ListView>

      {dialog}
    </>
  );
}
