import _ from 'lodash';
import { rgba } from 'polished';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';
import Button from './Button';
import Icon from './Icon';
import Input from './Input';
import MultiSelectInput from './MultiSelectInput';
import Tooltip from './Tooltip';
import MultiSelectFilter from './filters/MultiSelectFilter';

const Container = styled.div`
  background-color: ${colors.white};
  border-radius: 0.3125rem;
  box-shadow: 0 0.25rem 1rem -0.125em ${rgba(colors.black, 0.15)};
  z-index: 3;
  user-select: none;
  outline: none;
`;

const Filter = styled.div`
  padding: 0.5rem 0.875rem;
  background-color: ${colors.grey5};
  border-bottom: solid 1px ${colors.grey10};
  width: 100%;
`;

const Actions = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5rem 0.875rem;
  background: ${colors.grey5};

  ${Button} {
    font-size: 0.75rem;
    border-radius: 5px;

    &:focus {
      outline: 2px solid ${colors.primary};
    }
  }
`;

const Group = styled.div`
  margin-bottom: 0.25rem;
  margin-left: 0.875rem;
  overflow: hidden;
`;

const Options = styled.div`
  overflow: auto;
  max-height: 15rem;
  padding: 0.5rem 0;
  outline: none;

  > ${Group} {
    margin-left: 0;
  }
`;

const NoOptions = styled.div`
  padding: 1rem;
  color: ${colors.grey55};
  text-align: left;
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25rem 0.875rem;
  cursor: pointer;
  outline: none;

  &:hover,
  &:focus&:hover {
    background-color: ${colors.grey5};
  }

  &:focus {
    background-color: ${rgba(colors.grey5, 0.5)};
  }

  ${({ inactive }) =>
    inactive &&
    css`
      color: ${colors.grey40};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${colors.grey25};
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

const OptionIndicator = styled.div`
  flex-shrink: 0;
  display: flex;
  width: 0.75rem;
  margin-right: 0.5rem;
  color: ${colors.grey40};
  font-size: 0.75rem;
`;

const OptionValue = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Option = React.forwardRef(({ isSelected, children, tooltip = children, ...props }, ref) => (
  <OptionContainer role="menuitemradio" aria-checked={isSelected} {...props} ref={ref}>
    <OptionIndicator>{!!isSelected && <Icon icon="check" />}</OptionIndicator>
    <Tooltip
      message={tooltip}
      delay={1000}
      placement="bottom-start"
      style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
      <OptionValue>{children}</OptionValue>
    </Tooltip>
  </OptionContainer>
));

const GroupLabel = styled.div`
  padding: 0.25rem 0.875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: ${weights.bold};
`;

function MultiSelectGroup({ label, children }) {
  return (
    <Group>
      <Tooltip message={label} delay={1000} placement="bottom-start">
        <GroupLabel>{label}</GroupLabel>
      </Tooltip>
      {children}
    </Group>
  );
}

function Results({ autoFocus, values, options, renderOptions, onToggleOption }) {
  const optionsRef = useRef(null);

  useEffect(() => {
    if (autoFocus && optionsRef.current) {
      const firstTabbable = optionsRef.current.querySelector('[tabindex="0"]');
      if (firstTabbable) {
        firstTabbable.focus();
      }
    }
  }, [autoFocus]);

  if (!options?.length > 0) {
    return <NoOptions>No results</NoOptions>;
  }

  const handleKeyDown = (e, o) => {
    switch (e.key) {
      case 'ArrowUp': {
        e.stopPropagation();
        e.preventDefault();

        const allOptions = optionsRef.current.querySelectorAll('[tabindex="0"]');
        const currentIndex = Array.prototype.indexOf.call(allOptions, e.target);
        const previousIndex = currentIndex > 0 ? currentIndex - 1 : allOptions.length - 1;
        allOptions[previousIndex]?.focus();
        break;
      }

      case 'ArrowDown': {
        e.stopPropagation();
        e.preventDefault();

        const allOptions = optionsRef.current.querySelectorAll('[tabindex="0"]');
        const currentIndex = Array.prototype.indexOf.call(allOptions, e.target);
        const nextIndex = currentIndex < allOptions.length - 1 ? currentIndex + 1 : 0;
        allOptions[nextIndex]?.focus();
        break;
      }

      case 'Enter':
      case ' ':
        onToggleOption(o);
        e.stopPropagation();
        e.preventDefault();
        break;
    }
  };

  if (_.isFunction(renderOptions)) {
    return (
      <Options ref={optionsRef}>
        {renderOptions(
          options.map((o) => ({
            ...o,
            props: {
              tabIndex: 0,
              isSelected: values.some((v) => _.isEqual(v, o)),
              onClick: () => onToggleOption(o),
              onKeyDown: (e) => handleKeyDown(e, o),
            },
          })),
        )}
      </Options>
    );
  }

  return (
    <Options ref={optionsRef}>
      {options.map((option) => {
        const isSelected = values.some((v) => _.isEqual(v, option));
        return (
          <Option
            key={option.id}
            tabIndex={0}
            isSelected={isSelected}
            onKeyDown={(e) => handleKeyDown(e, option)}
            onClick={() => onToggleOption(option)}>
            {option.name}
          </Option>
        );
      })}
    </Options>
  );
}

const Loading = styled.div`
  position: absolute;
  top: 50%;
  right: 2.5rem;
  transform: translateY(-50%);
  color: ${colors.primary};
`;

const CancelButton = styled(Button)`
  outline: none !important;
  color: ${colors.grey55};
  &:hover {
    color: ${colors.grey40};
  }
`;

const Buttons = styled.div`
  display: flex;
  gap: 0.875rem;
  margin-left: auto;
`;

export default function MultiSelect({
  values,
  options,
  isLoading,
  renderOptions,
  onApply,
  onSearch,
  onChange,
  onCancel,
}) {
  const [searchValue, setSearchValue] = useState('');
  const handleSearchChange = ({ target: { value } }) => {
    setSearchValue(value);
    onSearch(value);
  };

  const handleClear = () => {
    onApply([]);
  };

  const handleApply = () => {
    onApply(values);
  };

  const handleToggleOption = (option) => {
    if (values.some((v) => _.isEqual(v, option))) {
      onChange(values.filter((v) => !_.isEqual(v, option)));
    } else {
      onChange([...values, option]);
    }
  };

  return (
    <Container onKeyDown={(e) => e.stopPropagation()}>
      {_.isFunction(onSearch) && (
        <Filter>
          <Input autoFocus type="search" wait={300} value={searchValue} onChange={handleSearchChange} />
          {isLoading && (
            <Loading>
              <Icon icon="spinner" spin={true} />
            </Loading>
          )}
        </Filter>
      )}

      {!isLoading && (
        <>
          <Results
            autoFocus={!_.isFunction(onSearch)}
            values={values}
            options={options}
            isLoading={!options}
            renderOptions={renderOptions}
            onToggleOption={handleToggleOption}
          />

          <Actions>
            <CancelButton tabIndex={-1} onClick={onCancel} isAnchor>
              Cancel
            </CancelButton>

            <Buttons align="right">
              <Button tabIndex={-1} onClick={handleClear} isOutline>
                Clear
              </Button>
              <Button onClick={handleApply}>OK</Button>
            </Buttons>
          </Actions>
        </>
      )}
    </Container>
  );
}

MultiSelect.Option = Option;
MultiSelect.Group = MultiSelectGroup;
MultiSelect.Input = MultiSelectInput;
MultiSelect.Filter = MultiSelectFilter;
