import { Formik } from 'formik';
import React, { useRef } from 'react';
import * as Yup from 'yup';
import { Button, Buttons, CancelButton, Drawer, Field, Form, FormMessage, Radio } from '~/components';
import { useWorkspace, useApi } from '~/contexts';
import { useDirtyCheck, useForm } from '~/hooks';
import { mergeValues, emptyStringToNull } from '~/utils';

export default function MemberSettings({ integration, onClose, onSave }) {
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const [{ status, message, isSubmitting }, form] = useForm();
  const api = useApi();
  const { workspace } = useWorkspace();

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  const initialValues = mergeValues(
    {
      autoMemberMapping: true,
      inviteMembers: false,
      setBillable: false,
      updateMemberActiveDates: false,
      updateMemberEmail: false,
      updateMemberInternalId: false,
      updateMemberJobTitle: false,
      updateMemberManager: false,
      updateMemberName: false,
      updateMemberStatus: false,
    },
    integration.settings,
  );

  return (
    <Drawer
      isOpen
      title="Member Settings"
      byline="Rippling Integration"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(values) {
          try {
            form.submit();

            const body = emptyStringToNull(values);
            await api.www.workspaces(workspace.id).rippling.updateSettings(body);

            form.save();
            onSave();
            closeDrawer();
          } catch ({ message }) {
            form.error({ message });
          }
        }

        return (
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              autoMemberMapping: Yup.bool().label('Member Mapping').required(),
              inviteMembers: Yup.bool().label('Invite Created Members').required(),
              setBillable: Yup.bool().label('Create Billable Members').required(),
              updateMemberActiveDates: Yup.bool().label('Update Member Active Dates').required(),
              updateMemberEmail: Yup.bool().label('Update Email').required(),
              updateMemberInternalId: Yup.bool().label('Update Internal ID').required(),
              updateMemberJobTitle: Yup.bool().label('Update Job Title').required(),
              updateMemberManager: Yup.bool().label('Update Manager').required(),
              updateMemberName: Yup.bool().label('Update Name').required(),
              updateMemberStatus: Yup.bool().label('Update Member Status').required(),
            })}>
            {(formik) => {
              return (
                <Form>
                  {status && <FormMessage.Error>{message}</FormMessage.Error>}
                  <Form.Section
                    title="Invite Created Members"
                    subtitle="When members are created in Ruddr, automatically send them an invite.">
                    <Form.Control>
                      <Field.RadioGroup name="inviteMembers" direction="vertical">
                        <Radio value={true} label="On - Newly created members will automatically be sent an invite" />
                        <Radio value={false} label="Off - Do not send invites to newly created members" />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Create Billable Members"
                    subtitle="When members are created in Ruddr, set them as billable members.">
                    <Form.Control>
                      <Field.RadioGroup name="setBillable" direction="vertical">
                        <Radio value={true} label="On - Newly created members will be set as billable" />
                        <Radio value={false} label="Off - Newly created members will be set as non-billable" />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Update Member Status"
                    subtitle="Sync the active or inactive employee status from Rippling to Ruddr members.">
                    <Form.Control>
                      <Field.RadioGroup name="updateMemberStatus" direction="vertical">
                        <Radio value={true} label="On - Set the member status based on the Rippling setting" />
                        <Radio value={false} label="Off - Do not set the member status based on the Rippling setting" />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Update Member Active Dates"
                    subtitle="Sync the Rippling hire date and termination dates as the Ruddr member's active date range.">
                    <Form.Control>
                      <Field.RadioGroup name="updateMemberActiveDates" direction="vertical">
                        <Radio
                          value={true}
                          label="On - Set and update the member active date range based on the Rippling settings"
                        />
                        <Radio
                          value={false}
                          label="Off - Do not set or update the member active date range based on the Rippling settings"
                        />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Update Member Name"
                    subtitle="Sync the employee name from Rippling to Ruddr members.">
                    <Form.Control>
                      <Field.RadioGroup name="updateMemberName" direction="vertical">
                        <Radio value={true} label="On - Update the member names" />
                        <Radio value={false} label="Off - Do not update member names" />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Update Job Title"
                    subtitle="Sync the job title from Rippling to Ruddr members. This will create additional job titles in Ruddr if they do not exist already.">
                    <Form.Control>
                      <Field.RadioGroup name="updateMemberJobTitle" direction="vertical">
                        <Radio value={true} label="On - Set and update job titles" />
                        <Radio value={false} label="Off - Do not set or update job titles" />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Update Manager"
                    subtitle="Set the manager for Ruddr members. This will depend on the manager email address being mapped to a Ruddr member.">
                    <Form.Control>
                      <Field.RadioGroup name="updateMemberManager" direction="vertical">
                        <Radio value={true} label="On - Set and update a Ruddr member's manager" />
                        <Radio value={false} label="Off - Do not set or update a Ruddr member's manager" />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Update Internal ID"
                    subtitle="Set the Ruddr member's internal ID to the Rippling employee ID.">
                    <Form.Control>
                      <Field.RadioGroup name="updateMemberInternalId" direction="vertical">
                        <Radio value={true} label="On - Set and update the Ruddr member's internal ID" />
                        <Radio value={false} label="Off - Do not set or update a Ruddr member's internal ID" />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Update Member Email"
                    subtitle="Sync the employee email from Rippling to Ruddr members.">
                    <Form.Control>
                      <Field.RadioGroup name="updateMemberEmail" direction="vertical">
                        <Radio
                          value={true}
                          label="On - Set and update the member email based on the Rippling setting"
                        />
                        <Radio
                          value={false}
                          label="Off - Do not set or update the member email based on the Rippling setting"
                        />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Form.Section
                    title="Member Mapping"
                    subtitle="Choose the type of mapping you would like to use for Rippling employees to Ruddr members.">
                    <Form.Control>
                      <Field.RadioGroup name="autoMemberMapping" direction="vertical">
                        <Radio value={true} label="Automatic - Map automatically by matching on work email address" />
                        <Radio value={false} label="Manual - Manually control the mapping" />
                      </Field.RadioGroup>
                    </Form.Control>
                  </Form.Section>
                  <Drawer.Actions>
                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                      <Button isLoading={isSubmitting} onClick={formik.submitForm}>
                        Save &amp; Close
                      </Button>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}
