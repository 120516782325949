import moment from 'moment';
import { dateFormats } from '~/utils';

const intervals = {
  this_month: {
    label: 'This Month',
    start: moment().startOf('month').format(dateFormats.isoDate),
    end: moment().endOf('month').format(dateFormats.isoDate),
  },
  this_quarter: {
    label: 'This Quarter',
    start: moment().startOf('quarter').format(dateFormats.isoDate),
    end: moment().endOf('quarter').format(dateFormats.isoDate),
  },
  this_year: {
    label: 'This Year',
    period: 'this_year',
    start: moment().startOf('year').format(dateFormats.isoDate),
    end: moment().endOf('year').format(dateFormats.isoDate),
  },
  last_month: {
    label: 'Last Month',
    start: moment().subtract(1, 'month').startOf('month').format(dateFormats.isoDate),
    end: moment().subtract(1, 'month').endOf('month').format(dateFormats.isoDate),
  },
  last_quarter: {
    label: 'Last Quarter',
    start: moment().subtract(1, 'quarter').startOf('quarter').format(dateFormats.isoDate),
    end: moment().subtract(1, 'quarter').endOf('quarter').format(dateFormats.isoDate),
  },
  last_year: {
    label: 'Last Year',
    period: 'last_year',
    start: moment().startOf('year').subtract(1, 'year').format(dateFormats.isoDate),
    end: moment().endOf('year').subtract(1, 'year').format(dateFormats.isoDate),
  },
};

export default intervals;
