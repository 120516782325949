import Big from 'big.js';
import {
  Button,
  Buttons,
  CancelButton,
  Currency,
  DeleteConfirmation,
  Field,
  Form,
  Icon,
  ModalCard,
  Radio,
  Table,
} from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useConfirmation, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import _ from 'lodash';
import React, { useState } from 'react';
import { weights } from '~/styles';
import {
  calculateExpenseMarkup,
  calculateExpenseRevenue,
  calculateExpenseCost,
  emptyStringToNull,
  getInitialMarkupRatioValue,
  mergeValues,
} from '~/utils';
import * as Yup from 'yup';
import { useFeatures } from '~/hooks';

function MonthlyBudgetExpensesTable({ projectModel, onChange }) {
  const [editIndex, setEditIndex] = useState(null);

  const handleSave = (value) => {
    value = emptyStringToNull({ ..._.omit(value, ['enableMarkup']) });

    const expenses = value.id
      ? projectModel.monthlyBudgetExpenses.map((mbe) =>
          mbe.id === value.id
            ? {
                ...mbe,
                ...value,
                markupRatio: _.isNumber(value.markupRatio) ? Big(value.markupRatio).div(100).toNumber() : null,
                expenseCategoryId: value.expenseCategory?.id,
              }
            : mbe,
        )
      : [
          ...projectModel.monthlyBudgetExpenses,
          {
            ...value,
            id: _.uniqueId('mbe_'),
            markupRatio: _.isNumber(value.markupRatio) ? Big(value.markupRatio).div(100).toNumber() : null,
            expenseCategoryId: value.expenseCategory?.id,
          },
        ];

    setEditIndex(null);
    onChange(expenses);
  };

  const handleDelete = (item) => {
    onChange(projectModel.monthlyBudgetExpenses.filter((r) => r.id !== item.id));
  };

  const expenses = {
    billableAmount: projectModel.isBillable
      ? projectModel.monthlyBudgetExpenses.reduce((a, v) => a + v.billableAmount, 0)
      : 0,
    nonBillableAmount: projectModel.monthlyBudgetExpenses.reduce((a, v) => a + v.nonBillableAmount, 0),
    markup: projectModel.monthlyBudgetExpenses.reduce((a, v) => a + v.markup, 0),
    get totalCost() {
      return calculateExpenseCost({
        isBillable: projectModel.isBillable,
        billableAmount: this.billableAmount,
        nonBillableAmount: this.nonBillableAmount,
      });
    },
    get totalRevenue() {
      return calculateExpenseRevenue({ markup: this.markup, amount: this.billableAmount });
    },
  };

  const currency = projectModel.currency;

  return (
    <div>
      <Table small>
        <Table.BoxHeader>
          <Table.Column>Category</Table.Column>
          <Table.Column align="right" width="10rem" isVisible={projectModel.isBillable}>
            Billable Cost
          </Table.Column>
          <Table.Column align="right" width="10rem">
            Non-Billable Cost
          </Table.Column>
          <Table.Column align="right" width="10rem">
            Total Cost
          </Table.Column>
          <Table.Column align="right" width="10rem">
            Total Revenue
          </Table.Column>
          <Table.BoxActionsColumn />
        </Table.BoxHeader>
        <Table.Body>
          {projectModel.monthlyBudgetExpenses.map((item, index) => (
            <MonthlyBudgetExpenseRowDetails
              key={item.id}
              projectModel={projectModel}
              budgetExpense={item}
              currency={currency}
              disableActions={editIndex !== null}
              onEdit={() => setEditIndex(index)}
              onDelete={handleDelete}
            />
          ))}

          <MonthlyBudgetExpenseRowDetails
            projectModel={projectModel}
            budgetExpense={{}}
            disableActions={editIndex !== null}
            onEdit={() => setEditIndex(-1)}
          />

          <Table.Row style={{ borderBottom: 'none', fontWeight: weights.bold, textTransform: 'uppercase' }}>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell>
              <Currency value={expenses.billableAmount} currency={currency} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={expenses.nonBillableAmount} currency={currency} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={expenses.totalCost} currency={currency} />
            </Table.Cell>
            <Table.Cell>
              <Currency value={expenses.totalRevenue} currency={currency} />
            </Table.Cell>
            <Table.Cell />
          </Table.Row>
        </Table.Body>
      </Table>

      {editIndex != null && (
        <MonthlyBudgetExpenseModalForm
          projectModel={projectModel}
          budgetExpense={editIndex >= 0 ? projectModel.monthlyBudgetExpenses[editIndex] : null}
          currency={currency}
          onSubmit={handleSave}
          onCancel={() => setEditIndex(null)}
        />
      )}
    </div>
  );
}

function MonthlyBudgetExpenseRowDetails({ currency, projectModel, budgetExpense, disableActions, onEdit, onDelete }) {
  const confirmation = useConfirmation();

  const { id, nonBillableAmount, billableAmount, expenseCategory, markup } = budgetExpense;

  if (!id)
    return (
      <Table.Row>
        <Table.Cell>
          <Button isAnchor isStrong disabled={disableActions} onClick={onEdit}>
            <Icon icon="plus" size="xs" spaceRight />
            Quick Add
          </Button>
        </Table.Cell>
      </Table.Row>
    );

  const totalCost = calculateExpenseCost({ billableAmount, nonBillableAmount, isBillable: projectModel.isBillable });

  const totalRevenue = calculateExpenseRevenue({ amount: billableAmount, markup });

  const handleDelete = async () => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this budget expense?</DeleteConfirmation>
    ));
    if (!confirm) return;

    onDelete({ id });
  };

  return (
    <Table.BoxRow>
      <Table.Cell>{expenseCategory.name}</Table.Cell>
      <Table.Cell>
        <Currency value={billableAmount} currency={currency} />
      </Table.Cell>
      <Table.Cell>
        <Currency value={nonBillableAmount} currency={currency} />
      </Table.Cell>
      <Table.Cell>
        <Currency value={totalCost} currency={currency} />
      </Table.Cell>
      <Table.Cell>
        <Currency value={totalRevenue} currency={currency} />
      </Table.Cell>
      <TableBoxRowActions>
        <TableBoxRowActions.Edit disabled={disableActions} onClick={onEdit} />

        <hr />

        <TableBoxRowActions.Delete disabled={disableActions} onClick={handleDelete} />
      </TableBoxRowActions>
    </Table.BoxRow>
  );
}

function MonthlyBudgetExpenseModalForm({ currency, projectModel, budgetExpense, onSubmit, onCancel }) {
  const { workspace } = useWorkspace();
  const features = useFeatures();

  const isNew = budgetExpense === null;

  const initialValues = mergeValues(
    {
      id: null,
      nonBillableAmount: '',
      billableAmount: '',
      expenseCategory: null,
      notes: '',
      enableMarkup: false,
      markupMethod: 'percentage',
      markupAmount: '',
      markupRatio: workspace.defaultMarkupPercentage ?? '',
    },
    {
      ...budgetExpense,
      markupRatio: getInitialMarkupRatioValue({
        isNew,
        markupRatio: budgetExpense?.markupRatio,
        defaultMarkupPercentage: workspace.defaultMarkupPercentage,
      }),
      enableMarkup: !isNew && !!budgetExpense.markupMethod,
    },
  );

  const handleSubmit = (values) => {
    if (!values.enableMarkup || !(values?.billableAmount > 0)) {
      values.markupMethod = null;
      values.markupAmount = null;
      values.markupRatio = null;
    }

    onSubmit({ ...values, markup: calculateExpenseMarkup({ ...values, amount: values.billableAmount }) });
  };

  return (
    <ModalCard title={isNew ? 'Add Expense Category Budget' : 'Edit Expense Category Budget'} onClose={onCancel}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          nonBillableAmount: Yup.number().label('Non-Billable Amount').min(0).max(99999999999).nullable(),
          billableAmount: Yup.number().label('Billable Amount').min(0).max(99999999999).nullable(),
          expenseCategory: Yup.mixed().label('Expense Category').required(),
          notes: Yup.string().label('Notes').max(5000),
          markupMethod: Yup.string().label('Type'),
          markupAmount: Yup.number().label('Markup Amount').min(0).max(99999999999).nullable(),
          markupRatio: Yup.number().label('Markup Percentage').min(0).max(100).nullable(),
        })}>
        {({ submitForm, values, setFieldValue }) => {
          const handleExpensesBillableAmountChange = (value) => {
            // Deactivate "enableMarkup" if billable amount is 0 or null
            if (!value) setFieldValue('enableMarkup', false);
            setFieldValue('billableAmount', value);
          };

          return (
            <>
              <ModalCard.Body>
                <Form.Control>
                  <Field.ExpenseCategorySelect
                    autoFocus
                    name="expenseCategory"
                    materialPlaceholder="Expense Category"
                    materialAlwaysVisible
                    allowNew
                    exclude={projectModel.monthlyBudgetExpenses.map((bp) => bp.expenseCategoryId)}
                  />
                </Form.Control>

                <Form.Control>
                  {projectModel.isBillable && (
                    <Field.Currency
                      onChange={handleExpensesBillableAmountChange}
                      name="billableAmount"
                      materialPlaceholder="Billable"
                      materialAlwaysVisible
                      currency={currency}
                    />
                  )}
                  <Field.Currency
                    name="nonBillableAmount"
                    materialPlaceholder="Non-Billable"
                    materialAlwaysVisible
                    currency={currency}
                  />
                </Form.Control>

                <Form.Control>
                  <Field.Currency
                    name="totalCost"
                    value={calculateExpenseCost({
                      billableAmount: values.billableAmount,
                      nonBillableAmount: values.nonBillableAmount,
                    })}
                    materialPlaceholder="Total Budgeted Cost"
                    disabled
                    materialAlwaysVisible
                    currency={currency}
                  />
                </Form.Control>

                {(features.expenseMarkup || budgetExpense?.enableMarkup) && (
                  <Form.Control>
                    <Field.Checkbox
                      name="enableMarkup"
                      label="Markup the billable expense amount"
                      disabled={!projectModel.isBillable || !(values?.billableAmount > 0)}
                    />
                  </Form.Control>
                )}

                {values?.enableMarkup && projectModel.isBillable && (
                  <>
                    <Form.Control>
                      <Field.RadioGroup name="markupMethod">
                        <Radio key="percentage" value="percentage" label="Markup by Percentage" />
                        <Radio key="amount" value="amount" label="Markup by Fixed Amount" />
                      </Field.RadioGroup>
                    </Form.Control>
                    {values.markupMethod && (
                      <Form.Control>
                        {values.markupMethod === 'amount' && (
                          <Field.Currency currency={currency} name="markupAmount" placeholder="Markup Amount" />
                        )}

                        {values.markupMethod === 'percentage' && (
                          <Field.Number
                            suffix="%"
                            name="markupRatio"
                            placeholder="Markup Percentage"
                            min={0}
                            max={100}
                            precision={2}
                          />
                        )}
                      </Form.Control>
                    )}
                  </>
                )}

                <Form.Control>
                  <Field.Currency
                    name="totalBudgetedRevenue"
                    value={calculateExpenseRevenue({ ...values, amount: values.billableAmount })}
                    materialPlaceholder="Total Expense Revenue"
                    disabled
                    materialAlwaysVisible
                    currency={currency}
                  />
                </Form.Control>

                <Form.Control>
                  <Field.TextArea name="notes" placeholder="Notes" rows={2} maxLength={5000} />
                </Form.Control>
              </ModalCard.Body>

              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={onCancel}>Cancel</CancelButton>
                  <Button onClick={submitForm}>{isNew ? 'Add' : 'Save'}</Button>
                </Buttons>
              </ModalCard.Footer>
            </>
          );
        }}
      </Formik>
    </ModalCard>
  );
}

export default MonthlyBudgetExpensesTable;
