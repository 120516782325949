import React from 'react';
import SingleSelectFilter from '~/components/filters/SingleSelectFilter';

const options = [
  { id: 'member', name: 'Member' },
  { id: 'placeholder', name: 'Placeholder' },
];

export default function ResourceTypeFilter(props) {
  return (
    <SingleSelectFilter placeholder="Resource Type" options={options} renderValue={(value) => value.name} {...props} />
  );
}
