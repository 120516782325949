import { Formik, setNestedObjectValues } from 'formik';
import _ from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import {
  BackLink,
  Button,
  Buttons,
  CancelButton,
  ClientLink,
  Confirmation,
  Currency,
  DateTime,
  DeleteButton,
  Field,
  FieldControl,
  Form,
  FormMessage,
  Grid,
  IconButton,
  InlineTooltip,
  InvoiceVariablesDialog,
  MultilineText,
  Page,
  PromptNavigation,
  SplitButton,
  TooltipButton,
} from '~/components';
import { useApi, useConfirmation, useSubscription, useWorkspace } from '~/contexts';
import { useDocumentTitle, useForm } from '~/hooks';
import { colors, weights } from '~/styles';
import { emptyStringToNull, mergeValues } from '~/utils';
import calculateInvoiceAmounts from '../calculateInvoiceAmounts';
import ProjectTags from '../components/ProjectTags';
import InvoiceWebLinkModal from '../invoice-actions/InvoiceWebLinkModal';
import PublishInvoiceDialog from '../invoice-dialogs/PublishInvoiceDialog';
import HeaderDrawer from './HeaderDrawer';
import InvoiceHistoryDrawer from './InvoiceHistoryDrawer';
import InvoiceLinesTable from './InvoiceLinesTable';
import LineSettingsDrawer from './LineSettingsDrawer';
import SettingsDrawer from './SettingsDrawer';
import {
  ActionsContainer,
  BilledTo,
  DraftNotesLabel,
  Footer,
  InvoiceFor,
  InvoiceNotes,
  LineItemsSection,
  PreviewButton,
  SaveButton,
  SectionHeader,
  Separator,
  StyledLink,
  SummaryDetails,
  SummaryDetailsRow,
  SummaryGrid,
  SummarySection,
  SummaryTotal,
  Tag,
  Tags,
  TotalLabel,
  TotalRow,
  TotalValue,
  Totals,
  TotalsSeparator,
  VariablesContainer,
} from './StyledComponents';
import TransactionsDrawer from './TransactionsDrawer';

function DraftInvoiceForm({ invoice, onChange, onDelete, onPreview, onReset, onSend }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ status, message, isSubmitting, saved }, form] = useForm();
  const [dialog, setDialog] = useState(null);
  const { notify } = useSubscription();
  const draftNotesRef = useRef(null);

  useDocumentTitle(invoice ? `Invoice #${invoice.number}` : undefined);

  const confirmation = useConfirmation();

  const handleEditSettings = () => {
    setDialog('settings');
  };

  const handleEditHeader = () => {
    setDialog('header');
  };

  const handleEditLineSettings = () => {
    setDialog('lineSettings');
  };

  const handleEditLineData = () => {
    setDialog('lineData');
  };

  const handleGetWebLink = () => {
    setDialog('webLink');
  };

  const handleViewHistory = () => {
    setDialog('history');
  };

  const handleCloseDialog = () => {
    setDialog(null);
  };

  const handleSubmit = async (values, { resetForm }, trigger = 'primary') => {
    try {
      form.submit(trigger);

      const body = emptyStringToNull({
        ..._.omit(values, 'taxRateRef', 'purchaseOrder'),
        taxRateId: values.taxRateRef?.id ?? null,
        taxRate: values.taxRateRef?.rate ?? values.taxRate ?? null,
        projects: values.projects.map((p) => ({ id: p.id })),
        purchaseOrderId: values.purchaseOrder?.id ?? null,
        lines: values.lines.map((line, index) => {
          switch (line.transactionType) {
            case 'time':
              if (line.timeEntries.length === 0) line.transactionType = null;
              break;

            case 'expense':
              if (line.expenses.length === 0 && line.projectExpenses.length === 0) line.transactionType = null;
              break;

            case 'milestone':
              if (line.milestones.length === 0) line.transactionType = null;
              break;

            case 'other_item':
              if (line.otherItems.length === 0) line.transactionType = null;
          }

          return emptyStringToNull({
            ..._.pick(
              line,
              'amount',
              'description',
              'draftNotes',
              'projectId',
              'quantity',
              'rate',
              'taxable',
              'transactionType',
              'expenses',
              'projectExpenses',
              'milestones',
              'timeEntries',
              'otherItems',
            ),
            id: line.id.includes('line_') ? undefined : line.id,
            invoiceItemId: line.invoiceItem?.id ?? null,
            projectId: line.project?.id ?? null,
            lineNumber: index + 1,
            timeEntries: line.timeEntries.map((entry) => _.pick(entry, 'id')),
            expenses: line.expenses.map((expense) => _.pick(expense, 'id')),
            projectExpenses: line.projectExpenses.map((projectExpense) => _.pick(projectExpense, 'id')),
            milestones: line.milestones.map((milestone) => _.pick(milestone, 'id')),
            otherItems: line.otherItems.map((otherItem) => _.pick(otherItem, 'id')),
          });
        }),
      });

      const { data } = await api.www.workspaces(workspace.id).invoices(invoice.id).update(body);

      onChange(data);

      form.save(trigger);
      resetForm();

      // Notify the workspace timer in case the running timer was stopped
      notify(useSubscription.keys.refresh_timer);

      return true;
    } catch (error) {
      form.error(error);
    }
  };

  const validateForm = async (formik) => {
    const errors = await formik.validateForm();
    if (!_.isEmpty(errors)) {
      formik.setTouched(setNestedObjectValues(errors, true));
    }
    return errors;
  };

  const handlePublish = async (formik) => {
    if (formik.dirty) {
      const errors = await validateForm(formik);
      if (!_.isEmpty(errors)) return;

      const success = await handleSubmit(formik.values, formik);
      if (!success) return;
    }

    return await confirmation.prompt((resolve) => (
      <PublishInvoiceDialog
        invoiceId={invoice.id}
        onClose={() => {
          resolve();
        }}
        resolve={async () => {
          await onChange(invoice.id);
          resolve(true);
        }}
      />
    ));
  };

  const handlePublishAndSend = async (formik) => {
    const isPublished = await handlePublish(formik);
    if (isPublished) {
      onSend();
    }
  };

  const handlePreview = async (formik) => {
    if (formik.dirty) {
      const errors = await validateForm(formik);
      if (!_.isEmpty(errors)) return;

      const success = await handleSubmit(formik.values, formik, 'preview');
      if (!success) return;
    }

    onPreview();
  };

  const mergeLines = useCallback((lines) => {
    return lines.map((line) =>
      mergeValues(
        {
          amount: '',
          description: '',
          id: null,
          invoiceItem: null,
          project: null,
          quantity: '',
          rate: '',
          taxable: false,
          transactionType: null,
          transactionAmount: null,
          timeEntries: [],
          expenses: [],
          projectExpenses: [],
          milestones: [],
          otherItems: [],
        },
        line,
      ),
    );
  }, []);

  const initialValues = useMemo(() => {
    if (!invoice) return null;

    return mergeValues(
      {
        // Header
        dueOn: null,
        issuedOn: null,
        draftNotes: '',
        number: invoice.transactionNumber,
        transactionNumber: invoice.transactionNumber,
        setBaseTransactionNumber: false,
        paymentTermsId: '',
        poNumber: '',
        servicesThrough: null,
        billTo: '',
        draftInvoiceFor: '',
        periodStart: null,
        periodEnd: null,

        // General Settings
        currency: null,
        includeTimeReport: false,
        includeReceipts: true,
        projects: [],
        purchaseOrder: null,

        // Time Report Settings
        groupTimeReportBy: 'role',
        timeReportFields: [],

        // Services Settings
        groupTime: false,
        groupTimeBy: 'role',
        timeFields: [],
        includeTimeDateRange: false,
        includeServiceProject: true,

        // Expenses Settings
        groupExpenses: false,
        groupExpensesBy: 'category',
        expenseFields: [],
        includeExpenseProject: true,

        // Other Items Settings
        includeOtherItemProject: true,

        lines: [],
        displayColumns: [],

        // Footer
        taxRate: '',
        taxRateRef: '',
        discountAmount: '',
      },
      {
        ...invoice,
        lines: mergeLines(invoice.lines),
        taxRate: invoice.taxRateRef?.rate ?? invoice.taxRate ?? '',
      },
    );
  }, [invoice, mergeLines]);

  const schema = () =>
    Yup.lazy((values) => {
      const { total } = calculateInvoiceAmounts(values);

      return Yup.object()
        .shape({
          discountAmount: Yup.number().label('Discount').min(0).max(99999999999).nullable(),
          draftNotes: Yup.string().label('Note to Client').max(5000),
          taxRate: Yup.number().label('Tax Rate').min(0).max(100).nullable(),
          lines: Yup.array().of(
            Yup.object().shape({
              amount: Yup.number().label('Amount').min(-99999999999).max(99999999999).nullable(),
              description: Yup.string().label('Details').max(4000),
              invoiceItem: Yup.object()
                .label('Item')
                .nullable()
                .when('amount', (amount, schema) => (_.isNumber(amount) ? schema.required() : schema)),
              quantity: Yup.number().label('Quantity').min(-99999999999).max(99999999999).nullable(),
              rate: Yup.number().label('Rate').min(-99999999999).max(99999999999).nullable(),
            }),
          ),
        })
        .test('total', function () {
          if (total > 99999999999)
            return this.createError({
              path: 'total',
              message: 'Invoice total must be lower than $99,999,999,999.',
            });

          if (total < -99999999999)
            return this.createError({
              path: 'total',
              message: 'Invoice total must be greater than $-99,999,999,999.',
            });

          return true;
        });
    });

  return (
    <Page>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={handleSubmit}>
        {(formik) => {
          const handleResetForm = async () => {
            const confirm = await confirmation.prompt((resolve) => (
              <Confirmation resolve={resolve}>This will discard all changes. Are you sure?</Confirmation>
            ));
            if (!confirm) return;

            await onReset();
            formik.resetForm();
          };

          const handleSettingsChange = async (values) => {
            formik.setValues({
              ...formik.values,
              ...values,
              lines: formik.values.lines.filter(
                (line) => !line.project || values.projects.map((p) => p.id).includes(line.project.id),
              ),
            });
          };

          const handleRegenerateLines = async ({
            timeEntries,
            groupTime,
            groupTimeBy,
            timeFields,
            includeTimeDateRange,
            periodStart,
            periodEnd,
            expenses,
            projectExpenses,
            groupExpenses,
            groupExpensesBy,
            expenseFields,
            milestones,
            otherItems,
            includeExpenseProject,
            includeServiceProject,
            includeOtherItemProject,
          }) => {
            const { data: transactionLines } = await api.www
              .workspaces(workspace.id)
              .invoices(invoice.id)
              .generateLines({
                timeEntries: timeEntries ?? formik.values.lines.map((line) => line.timeEntries).flat(),
                groupTime: groupTime ?? formik.values.groupTime,
                groupTimeBy: groupTimeBy ?? formik.values.groupTimeBy,
                timeFields: timeFields ?? formik.values.timeFields,
                includeTimeDateRange: includeTimeDateRange ?? formik.values.includeTimeDateRange,
                periodStart: periodStart === undefined ? formik.values.periodStart : periodStart,
                periodEnd: periodEnd === undefined ? formik.values.periodEnd : periodEnd,
                expenses: expenses ?? formik.values.lines.map((line) => line.expenses).flat(),
                projectExpenses: projectExpenses ?? formik.values.lines.map((line) => line.projectExpenses).flat(),
                groupExpenses: groupExpenses ?? formik.values.groupExpenses,
                groupExpensesBy: groupExpensesBy ?? formik.values.groupExpensesBy,
                expenseFields: expenseFields ?? formik.values.expenseFields,
                milestones: milestones ?? formik.values.lines.map((line) => line.milestones).flat(),
                otherItems: otherItems ?? formik.values.lines.map((line) => line.otherItems).flat(),
                includeServiceProject: includeServiceProject ?? formik.values.includeServiceProject,
                includeExpenseProject: includeExpenseProject ?? formik.values.includeExpenseProject,
                includeOtherItemProject: includeOtherItemProject ?? formik.values.includeOtherItemProject,
              });

            const lines = formik.values.lines
              .filter((line) => !line.transactionType)
              .concat(transactionLines)
              .map((line, index) => ({
                ...line,
                id: line.id || _.uniqueId('line_'),
                lineNumber: index + 1,
              }));

            // Notify the workspace timer in case the running timer was stopped
            notify(useSubscription.keys.refresh_timer);

            return lines;
          };

          const handleItemsChange = async ({
            timeEntries,
            milestones,
            expenses,
            projectExpenses,
            otherItems,
            periodStart,
            periodEnd,
          }) => {
            const lines = await handleRegenerateLines({
              timeEntries,
              milestones,
              expenses,
              projectExpenses,
              otherItems,
              periodStart,
              periodEnd,
            });
            formik.setValues({ ...formik.values, lines: mergeLines(lines), periodStart, periodEnd });
          };

          const handleLineSettingsChange = async (settings) => {
            const lines = await handleRegenerateLines(settings);
            formik.setValues({ ...formik.values, ...settings, lines: mergeLines(lines) });
          };

          const handleTaxRateChange = (event) => {
            formik.setValues({
              ...formik.values,
              taxRateRef: event.target.value,
              taxRate: event.target.value?.rate ?? '',
            });
          };

          const { nonTaxableSubtotal, taxableSubtotal, tax, total } = calculateInvoiceAmounts(formik.values);

          const hasProjects = formik.values.projects.length > 0;

          const currency = formik.values.currency;

          const publishInvoice = !invoice.permissions.publish
            ? {
                disabled: true,
                tooltip: 'Insufficient permissions to publish this invoice.',
              }
            : total < 0
              ? { disabled: true, tooltip: 'Invoice total must be zero or greater.' }
              : !formik.isValid
                ? { disabled: true, tooltip: 'Please correct the form errors to perform this action.' }
                : { disabled: false, tooltip: undefined };

          const publishAndSendInvoice = !invoice.permissions.publish
            ? {
                disabled: true,
                tooltip: 'Insufficient permissions to publish this invoice.',
              }
            : total < 0
              ? { disabled: true, tooltip: 'Invoice total must be zero or greater.' }
              : !formik.isValid
                ? { disabled: true, tooltip: 'Please correct the form errors to perform this action.' }
                : formik.dirty
                  ? { disabled: true, tooltip: 'Please save the invoice before sending.' }
                  : { disabled: false, tooltip: undefined };

          const handleOpenInvoiceVariablesDialog = async () => {
            await confirmation.prompt((resolve) => (
              <InvoiceVariablesDialog
                onClose={() => resolve(true)}
                onSaved={(variables) => {
                  let selectionStart = draftNotesRef.current.selectionStart;
                  if (!formik.touched.draftNotes) {
                    selectionStart = formik.values.draftNotes.length;
                  }

                  const updatedValue =
                    formik.values.draftNotes.slice(0, selectionStart) +
                    variables.join(' ') +
                    formik.values.draftNotes.slice(selectionStart);

                  formik.setFieldValue('draftNotes', updatedValue);
                  resolve(true);
                }}
              />
            ));
          };

          return (
            <>
              <Form>
                <Page.Header>
                  <BackLink defaultPath={`/app/${workspace.key}/billing/invoices`} />
                  <Page.Info>
                    <Page.Title>Invoice #{formik.values.number}</Page.Title>
                    <Tags>
                      <Tag>
                        <ClientLink client={invoice.client} />
                      </Tag>

                      {!_.isEmpty(formik.values.projects) && (
                        <Tag>
                          <ProjectTags client={invoice.client} projects={formik.values.projects} />
                        </Tag>
                      )}

                      <Tag>{currency}</Tag>

                      <Tag color={colors.danger}>{invoice.status.name}</Tag>

                      {invoice.sentAt && (
                        <Tag>
                          Sent on&nbsp;
                          <DateTime value={invoice.sentAt} />
                        </Tag>
                      )}
                    </Tags>
                  </Page.Info>

                  <Page.Actions>
                    <IconButton icon="history" tooltip="Invoice History" onClick={handleViewHistory} />

                    <IconButton icon="cog" tooltip="Invoice Settings" onClick={handleEditSettings} />

                    <IconButton icon="pencil-alt" tooltip="Invoice Header" onClick={handleEditHeader} />
                  </Page.Actions>
                </Page.Header>

                <Page.Section>
                  <SummarySection>
                    <SummaryGrid>
                      <SummaryDetailsRow equalHeight>
                        <Grid.Column>
                          <BilledTo>
                            <dt>Bill To</dt>
                            <dl>
                              <MultilineText value={formik.values.billTo} />
                            </dl>
                          </BilledTo>
                        </Grid.Column>
                        <Grid.Column>
                          <SummaryDetails>
                            <dt>PO #</dt>
                            <dd>
                              {formik.values.poNumber || (
                                <Button isAnchor onClick={handleEditHeader}>
                                  Add PO#
                                </Button>
                              )}
                            </dd>

                            <dt>Through</dt>
                            <dd>
                              {formik.values.servicesThrough ? (
                                <DateTime value={formik.values.servicesThrough} />
                              ) : (
                                <Button isAnchor onClick={handleEditHeader}>
                                  Set date
                                </Button>
                              )}
                            </dd>

                            <dt>Issued</dt>
                            <dd>
                              <DateTime value={formik.values.issuedOn} />
                            </dd>

                            <dt>Due</dt>
                            <dd>
                              {formik.values.paymentTermsId === 'due_on_receipt' ? (
                                'On Receipt'
                              ) : (
                                <DateTime value={formik.values.dueOn} />
                              )}
                            </dd>
                          </SummaryDetails>
                        </Grid.Column>

                        <Grid.Column>
                          <SummaryTotal>
                            <FieldControl error={formik.errors.total}>
                              <strong>
                                <Currency value={total} currency={currency} />
                              </strong>
                            </FieldControl>
                            <small>Total Invoiced</small>
                          </SummaryTotal>
                        </Grid.Column>
                      </SummaryDetailsRow>
                      <Grid.Row>
                        <Grid.Column>
                          <InvoiceFor>
                            <dt>Subject</dt>
                            <dd>
                              {formik.values.draftInvoiceFor || (
                                <Button isAnchor onClick={handleEditHeader}>
                                  Add subject
                                </Button>
                              )}
                            </dd>
                          </InvoiceFor>
                        </Grid.Column>
                      </Grid.Row>
                    </SummaryGrid>
                  </SummarySection>
                </Page.Section>

                <LineItemsSection>
                  <SectionHeader>
                    <div />
                    <Buttons>
                      <IconButton
                        icon="cog"
                        tooltip={hasProjects ? 'Line Settings' : 'Select a project in the invoice settings.'}
                        disabled={!hasProjects}
                        onClick={handleEditLineSettings}
                      />

                      <IconButton
                        icon="pencil-alt"
                        tooltip={hasProjects ? 'Items to Bill' : 'Select a project in the invoice settings.'}
                        disabled={!hasProjects}
                        onClick={handleEditLineData}
                      />
                    </Buttons>
                  </SectionHeader>

                  <InvoiceLinesTable formik={formik} invoice={invoice} />
                  <Separator />
                </LineItemsSection>

                <Footer>
                  <InvoiceNotes>
                    <VariablesContainer>
                      <DraftNotesLabel>Note to Client</DraftNotesLabel>
                      <StyledLink onClick={() => handleOpenInvoiceVariablesDialog('')}>Insert Variables</StyledLink>
                    </VariablesContainer>

                    <Field.TextArea name="draftNotes" maxLength={5000} ref={draftNotesRef} />
                  </InvoiceNotes>

                  <Totals>
                    <TotalRow>
                      <TotalLabel>Subtotal</TotalLabel>
                      <TotalValue>
                        <Currency
                          value={_.sumBy(formik.values.lines, (line) => line.amount || 0)}
                          currency={currency}
                        />
                      </TotalValue>
                    </TotalRow>

                    <TotalRow>
                      <TotalLabel>Discount</TotalLabel>
                      <TotalValue style={{ textAlign: 'right' }}>
                        <Field.Currency name="discountAmount" currency={currency} />
                      </TotalValue>
                    </TotalRow>

                    <TotalsSeparator />

                    <TotalRow>
                      <TotalLabel>Non-taxable Subtotal</TotalLabel>
                      <TotalValue>
                        <Currency value={nonTaxableSubtotal} currency={currency} />
                      </TotalValue>
                    </TotalRow>

                    <TotalRow>
                      <TotalLabel>Taxable Subtotal</TotalLabel>
                      <TotalValue>
                        <Currency value={taxableSubtotal} currency={currency} />
                      </TotalValue>
                    </TotalRow>

                    <TotalsSeparator />

                    <TotalRow
                      style={{ flexDirection: 'row-reverse', marginTop: '1rem', height: '2rem', marginBottom: '1rem' }}>
                      <Field.TaxRateSelect
                        style={{ width: '20rem', marginBottom: '0rem' }}
                        name="taxRateRef"
                        placeholder="Tax Rate"
                        onChange={handleTaxRateChange}
                      />
                    </TotalRow>

                    <TotalRow
                      style={{
                        display: 'flex',
                        width: '100%',
                        flexWrap: 'wrap',
                        fontSize: '0.875rem',
                      }}>
                      <TotalLabel>Tax Rate</TotalLabel>
                      <TotalValue>
                        <Field.Number
                          prefix="%"
                          name="taxRate"
                          min={0}
                          max={100}
                          precision={7}
                          disabled={formik.values.taxRateRef}
                        />
                      </TotalValue>
                    </TotalRow>

                    <TotalRow>
                      <TotalLabel>Tax</TotalLabel>
                      <TotalValue>
                        <Currency value={tax} currency={currency} />
                      </TotalValue>
                    </TotalRow>

                    <TotalsSeparator />

                    <TotalRow style={{ fontWeight: weights.bold }}>
                      <TotalLabel>Total Invoiced</TotalLabel>
                      <TotalValue>
                        <Currency value={total} currency={currency} />
                      </TotalValue>
                    </TotalRow>
                  </Totals>
                </Footer>

                <Form.Actions>
                  <TooltipButton
                    component={DeleteButton}
                    disabled={!invoice.permissions.manage}
                    tooltip={
                      !invoice.permissions.manage ? 'Insufficient permissions to delete this invoice' : undefined
                    }
                    onClick={onDelete}>
                    Delete
                  </TooltipButton>

                  <ActionsContainer>
                    {status && <FormMessage.Error style={{ marginRight: '1.5rem' }}>{message}</FormMessage.Error>}

                    <Buttons>
                      <CancelButton disabled={!formik.dirty} onClick={handleResetForm}>
                        Cancel
                      </CancelButton>

                      <PreviewButton
                        disabled={!formik.isValid}
                        isLoading={isSubmitting === 'preview'}
                        onClick={() => handlePreview(formik)}
                        ok={saved === 'preview'}>
                        {formik.dirty ? `Save & Preview` : `Preview`}
                        {!formik.isValid && (
                          <InlineTooltip message="Please correct the form errors to perform this action." />
                        )}
                      </PreviewButton>

                      <SplitButton>
                        {formik.dirty ? (
                          <SaveButton
                            isLoading={isSubmitting === 'primary'}
                            ok={saved === 'primary'}
                            disabled={!invoice.permissions.manage}
                            onClick={formik.submitForm}>
                            Save
                            {!invoice.permissions.manage && (
                              <InlineTooltip message="Insufficient permissions to save this invoice." />
                            )}
                          </SaveButton>
                        ) : (
                          <SaveButton
                            isLoading={isSubmitting === 'primary'}
                            ok={saved === 'primary'}
                            disabled={!formik.isValid || !invoice.permissions.publish || total < 0}
                            onClick={() => handlePublish(formik)}>
                            Publish
                            {!invoice.permissions.publish ? (
                              <InlineTooltip message="Insufficient permissions to publish this invoice." />
                            ) : total < 0 ? (
                              <InlineTooltip message="Invoice total must be zero or greater." />
                            ) : !formik.isValid ? (
                              <InlineTooltip message="Please correct the form errors to perform this action." />
                            ) : undefined}
                          </SaveButton>
                        )}

                        <SplitButton.Menu position="top" data-testid="invoice_actions">
                          {({ setIsOpen }) => {
                            const handleAction = async (action) => {
                              setIsOpen(false);
                              await action();
                            };

                            return (
                              <>
                                <SplitButton.Item
                                  disabled={!invoice.permissions.manage}
                                  tooltip={
                                    !invoice.permissions.manage
                                      ? 'Insufficient permissions to save this invoice.'
                                      : undefined
                                  }
                                  onClick={() => handleAction(() => formik.submitForm())}>
                                  Save
                                </SplitButton.Item>

                                <SplitButton.Item
                                  disabled={publishInvoice.disabled}
                                  tooltip={publishInvoice.tooltip}
                                  onClick={() => handleAction(() => handlePublish(formik))}>
                                  {formik.dirty ? `Save & Publish` : `Publish`}
                                </SplitButton.Item>

                                <SplitButton.Item
                                  disabled={publishAndSendInvoice.disabled}
                                  tooltip={publishAndSendInvoice.tooltip}
                                  onClick={() => handleAction(() => handlePublishAndSend(formik))}>
                                  Publish & Send
                                </SplitButton.Item>

                                <SplitButton.Item onClick={handleGetWebLink}>Get Web Link</SplitButton.Item>
                              </>
                            );
                          }}
                        </SplitButton.Menu>
                      </SplitButton>
                    </Buttons>
                  </ActionsContainer>
                </Form.Actions>

                {
                  {
                    settings: (
                      <SettingsDrawer
                        invoice={invoice}
                        values={formik.values}
                        onApply={handleSettingsChange}
                        onClose={handleCloseDialog}
                      />
                    ),

                    header: (
                      <HeaderDrawer
                        invoice={invoice}
                        values={formik.values}
                        project={invoice.project}
                        onApply={(v) => formik.setValues({ ...formik.values, ...v })}
                        onClose={handleCloseDialog}
                      />
                    ),

                    lineSettings: (
                      <LineSettingsDrawer
                        values={formik.values}
                        onApply={handleLineSettingsChange}
                        onClose={handleCloseDialog}
                      />
                    ),

                    lineData: (
                      <TransactionsDrawer
                        invoice={invoice}
                        values={formik.values}
                        currency={currency}
                        onApply={handleItemsChange}
                        onClose={handleCloseDialog}
                      />
                    ),

                    webLink: <InvoiceWebLinkModal invoice={invoice} onClose={handleCloseDialog} />,

                    history: <InvoiceHistoryDrawer invoiceId={invoice.id} onClose={handleCloseDialog} />,
                  }[dialog?.type ?? dialog]
                }

                <PromptNavigation when={formik.dirty} />
              </Form>
            </>
          );
        }}
      </Formik>
    </Page>
  );
}

export default DraftInvoiceForm;
