import { useCallback, useMemo, useState } from 'react';
import useSearchParamsConfig from './useSearchParamsConfig';

export default function useProjectFilters(defaultSearchParams) {
  const filters = useMemo(
    () => ({
      projects: [],
      projectAdmins: [],
      projectRecordStatusId: null,
      projectBillingTypes: [],
      projectStatuses: [],
      projectTags: [],
      projectTypes: [],
      projectPractices: [],
      projectBusinessUnits: [],
      projectSalesRepresentatives: [],
    }),
    [],
  );

  const [defaultParams] = useState(defaultSearchParams);

  const searchParamsConfig = useSearchParamsConfig();

  const projectSearchParamsConfig = useMemo(
    () => ({
      projects: searchParamsConfig.projects,
      projectAdmins: { ...searchParamsConfig.members, key: 'projectAdmin' },
      projectRecordStatusId: searchParamsConfig.projectRecordStatusId,
      projectBillingTypes: searchParamsConfig.projectBillingTypes,
      projectStatuses: searchParamsConfig.projectStatuses,
      projectTags: searchParamsConfig.projectTags,
      projectTypes: searchParamsConfig.projectTypes,
      projectPractices: { ...searchParamsConfig.practices, key: 'projectPractice' },
      projectBusinessUnits: { ...searchParamsConfig.businessUnits, key: 'projectBusinessUnit' },
      projectSalesRepresentatives: { ...searchParamsConfig.members, key: 'projectSalesRepresentative' },
      ...defaultParams,
    }),
    [searchParamsConfig, defaultParams],
  );

  const mapUrlSearchParams = useCallback((filters) => {
    return {
      projectId: filters.projects?.map((v) => v.id),
      projectAdminId: filters.projectAdmins?.map((v) => v.id),
      projectRecordStatusId: filters.projectRecordStatusId ?? undefined,
      projectBillingTypeId: filters.projectBillingTypes?.map((v) => v.id),
      projectStatusId: filters.projectStatuses?.map((v) => v.id),
      projectTagId: filters.projectTags?.map((v) => v.id),
      projectTypeId: filters.projectTypes?.map((v) => v.id),
      projectPracticeId: filters.projectPractices?.map((v) => v.id),
      projectBusinessUnitId: filters.projectBusinessUnits?.map((v) => v.id),
      projectSalesRepresentativeId: filters.projectSalesRepresentatives?.map((v) => v.id),
    };
  }, []);

  const mapClickThroughParams = useCallback(
    (filters) => ({
      project: filters.projects?.map((v) => v.id),
      projectAdmin: filters.projectAdmins?.map((v) => v.id),
      projectRecordStatusId: filters.projectRecordStatusId ?? undefined,
      projectBillingType: filters.projectBillingTypes?.map((v) => v.id),
      projectStatus: filters.projectStatuses?.map((v) => v.id),
      projectTag: filters.projectTags?.map((v) => v.id),
      projectType: filters.projectTypes?.map((v) => v.id),
      projectPractice: filters.projectPractices?.map((v) => v.id),
      projectBusinessUnit: filters.projectBusinessUnits?.map((v) => v.id),
      projectSalesRepresentative: filters.projectSalesRepresentatives?.map((v) => v.id),
    }),
    [],
  );

  return useMemo(
    () => ({ filters, searchParamsConfig: projectSearchParamsConfig, mapUrlSearchParams, mapClickThroughParams }),
    [filters, projectSearchParamsConfig, mapUrlSearchParams, mapClickThroughParams],
  );
}
