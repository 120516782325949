import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Link, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import {
  ExportDialog,
  ExportDropdown,
  Page,
  BackLink,
  FiltersBar,
  IconButton,
  ListView,
  ListViewActions,
} from '~/components';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import { ActiveStatusSelect, HolidayScheduleFilter } from '../custom-data/components';
import HolidayDrawer from './HolidayDrawer';
import HolidayRow from './HolidayRow';
import { mimeTypes } from '~/utils';

function HolidaysPage() {
  const title = 'Holidays';
  const documentTitle = useDocumentTitle(title);

  const api = useApi();
  const toast = useToast();
  const { workspace } = useWorkspace();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const [data, setData] = useState([]);
  const [params, setParams] = useState({ isActive: 'true', holidayScheduleId: '', withSchedules: true });

  const confirmation = useConfirmation();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .holidays()
        .get({ ...params, isActive: params.isActive ?? undefined });
      setData(data);
    } catch {
      setData([]);
    }
  }, [api, workspace, params]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function handleEdit(item) {
    history.push(url.concat(`/${item.id}/edit`));
  }

  function handleCloseDrawer() {
    history.push(`/app/${workspace.key}/settings/custom-data/holidays`);
    documentTitle.set(title);
  }

  const handleSaved = (holiday) => {
    const newData = _(data).reject({ id: holiday.id }).concat(holiday).sortBy('name').value();
    setData(newData);
  };

  const handleActiveStatusChange = async (holiday, flag) => {
    try {
      const { data } = await api.www.workspaces(workspace.id).holidays(holiday.id).setActiveStatus(flag);
      handleSaved(data);
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const handleDelete = async (holiday) => {
    try {
      await api.www.workspaces(workspace.id).holidays(holiday.id).delete();
      const newData = _.reject(data, { id: holiday.id });
      setData(newData);
      return true;
    } catch ({ message }) {
      toast.error(message);
      return false;
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setParams({ ...params, [name]: value });
  };

  const handleExport = async (filename, mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onClose={resolve}
        onLoad={api.www
          .workspaces(workspace.id)
          .holidays()
          .get({ isActive: params.isActive ?? undefined }, { headers: { accept: mimeType }, responseType: 'blob' })}
      />
    ));
  };

  return (
    <>
      <Page.Header>
        <BackLink defaultPath={`/app/${workspace.key}/settings/custom-data`} />

        <Page.Info>
          <Page.Title>Holidays</Page.Title>
          <Page.Description>
            Ruddr has several built-in standard holidays that can be added to your{' '}
            <Link to={`/app/${workspace.key}/settings/custom-data/holiday-schedules`}>holiday schedules</Link>. If your
            company observes a holiday that isn't built-in, you can create a custom holiday.
          </Page.Description>
        </Page.Info>

        <Page.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('holidays.csv', mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport('holidays.xlsx', mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <Link to={url.concat('/new')}>
            <IconButton icon="plus" tooltip="New Holiday" />
          </Link>
        </Page.Actions>
      </Page.Header>

      <Page.Filters>
        <FiltersBar>
          <HolidayScheduleFilter
            materialPlaceholder="Holiday Schedule"
            materialAlwaysVisible
            placeholder="All"
            showEmptyOption={true}
            value={params.holidayScheduleId}
            name="holidayScheduleId"
            onChange={handleChange}
          />

          <ActiveStatusSelect value={params.isActive} onChange={handleChange} />
        </FiltersBar>
      </Page.Filters>

      <Page.ListView>
        <ListView>
          <ListView.Header>
            <ListView.Column>Name</ListView.Column>
            <ListView.Column>Holiday Schedule</ListView.Column>
            <ListView.Column>Next Occurrence</ListView.Column>
            <ListViewActions.Column />
          </ListView.Header>
          <ListView.Body>
            {data.map((item) => (
              <HolidayRow
                key={item.id}
                holiday={item}
                onDelete={handleDelete}
                onEdit={() => handleEdit(item)}
                onActiveStatusChange={handleActiveStatusChange}
              />
            ))}

            {data.length === 0 && <ListView.Empty />}
          </ListView.Body>
          <ListView.Status total={data.length} label="Holiday" />
        </ListView>
      </Page.ListView>
      <Switch>
        <Route path={`${path}/new`}>
          <HolidayDrawer onClose={handleCloseDrawer} onSaved={handleSaved} />
        </Route>
        <Route path={`${path}/:holidayId/edit`}>
          <HolidayDrawer onClose={handleCloseDrawer} onDelete={handleDelete} onSaved={handleSaved} />
        </Route>
      </Switch>
    </>
  );
}

export default HolidaysPage;
