import React from 'react';
import { CompanyFilter } from '~/components';
import FiltersGroup from './FiltersGroup';

function CompanyFiltersGroup({ filters, onChange }) {
  return (
    <FiltersGroup label="Company Filters" filters={[filters.company]}>
      <CompanyFilter
        name="company"
        value={filters.company}
        onChange={({ target: { value } }) => onChange({ company: value })}
      />
    </FiltersGroup>
  );
}

export default CompanyFiltersGroup;
