import {
  BillableIcon,
  Button,
  Buttons,
  CancelButton,
  Checkbox,
  Currency,
  DateTime,
  DeleteButton,
  DeleteConfirmation,
  Field,
  Form,
  HelpTooltip,
  Icon,
  Level,
  ModalCard,
  Radio,
  Table,
  Tooltip,
  MarkupIcon,
} from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { colors, weights } from '~/styles';
import {
  calculateExpenseMarkup,
  calculateExpenseRevenue,
  dateFormats,
  emptyStringToNull,
  getInitialMarkupRatioValue,
  mergeValues,
} from '~/utils';
import * as Yup from 'yup';
import { Header } from './Header';
import { TableTitle } from './TableTitle';
import styled from 'styled-components';
import Big from 'big.js';
import { useFeatures } from '~/hooks';

const StyledCheckbox = styled.div`
  > label > div {
    background: ${colors.white};
  }
`;

const InvoicedIcon = styled(Icon)`
  color: ${colors.primary};
`;

const ContainerControl = styled.span`
  display: flex;
  align-items: center;
`;

const Checkboxes = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    margin-right: 1.35rem;
  }
`;

function ProjectExpenses({ projectModel, onChange }) {
  const [selectedIds, setSelectedIds] = useState([]);
  const [formEntry, setEditForm] = useState(null);
  const handleAdd = () => setEditForm({});
  const handleEdit = (entry) => setEditForm(entry);
  const handleCancel = () => setEditForm(null);

  const selected = useMemo(() => {
    return {
      get items() {
        return this.available.filter((item) => selectedIds.some((id) => id === item.id));
      },
      get some() {
        return this.items.length > 0;
      },
      get available() {
        return projectModel.projectExpenses.filter(({ invoice }) => !invoice || invoice.statusId === 'draft');
      },
      get all() {
        return this.items.length === this.available.length;
      },
    };
  }, [selectedIds, projectModel.projectExpenses]);

  const handleBulkSelectionChange = () => {
    setSelectedIds(selected.some ? [] : [...selectedIds, ...selected.available.map(({ id }) => id)]);
  };

  const handleSubmit = (value) => {
    value = emptyStringToNull(value);

    value.total = calculateExpenseRevenue({ markup: value.markup, amount: value.amount });

    const expenses = value.id
      ? projectModel.projectExpenses.map((rre) => (rre.id === value.id ? { ...rre, ...value } : rre))
      : [...projectModel.projectExpenses, { ...value, id: _.uniqueId('pe_') }];

    onChange(expenses);
    handleCancel();
  };

  const handleDelete = (item) => {
    onChange(projectModel.projectExpenses.filter((r) => r.id !== item.id));
  };

  const total = _.round(_.sumBy(projectModel.projectExpenses, 'amount'), 2);

  const handleDeleteMultiple = async () => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>
        This will remove the selected project expenses from the project. Are you sure?
      </DeleteConfirmation>
    ));
    if (!confirm) return;
    onChange(projectModel.projectExpenses.filter((item) => !selectedIds.includes(item.id)));
  };

  const confirmation = useConfirmation();
  const currency = projectModel.currency;

  const handleAddMultiple = async () => {
    await confirmation.prompt((resolve) => {
      const handleSubmit = (values) => {
        const expenses = [
          ...projectModel.projectExpenses,
          ...values.map((pe) => {
            pe.total = calculateExpenseRevenue({ markup: pe.markup, amount: pe.amount });

            return { ...pe, id: _.uniqueId('pe_') };
          }),
        ];
        onChange(expenses);
        resolve(true);
      };

      return (
        <MultipleProjectExpensesForm
          project={projectModel}
          currency={currency}
          onSubmit={handleSubmit}
          onCancel={() => resolve(false)}
        />
      );
    });
  };

  return (
    <>
      <Header>
        <Level>
          <Level.Item>
            <TableTitle>
              Expenses Ledger
              <HelpTooltip
                message="Schedule the recognition of project expenses below. These are expenses that are not reflected on a workspace member's expense report."
                style={{ marginLeft: '0.5rem' }}
              />
            </TableTitle>
          </Level.Item>

          <Level.Item right narrow>
            <DeleteButton disabled={!selected.some} onClick={handleDeleteMultiple}>
              Delete
            </DeleteButton>
          </Level.Item>
          <Level.Item right narrow>
            <Button isOutline onClick={handleAddMultiple}>
              Add Multiple
            </Button>
          </Level.Item>
        </Level>
      </Header>

      <Form.Control>
        <Table small>
          <Table.BoxHeader>
            <Table.Column width="3rem">
              <StyledCheckbox>
                <Checkbox
                  data-testid="project_expenses_bulk_checkbox"
                  disabled={!selected.available.length}
                  checked={selected.some}
                  partial={!selected.all}
                  onChange={handleBulkSelectionChange}
                />
              </StyledCheckbox>
            </Table.Column>
            <Table.Column width="8rem">Date</Table.Column>
            <Table.Column>Expense Category</Table.Column>
            <Table.Column>Notes</Table.Column>
            <Table.Column align="center" width="6rem">
              Billable
            </Table.Column>
            <Table.Column align="right" width="7rem">
              Amount
            </Table.Column>
            <Table.Column width="2rem" />
            <Table.BoxActionsColumn />
          </Table.BoxHeader>
          <Table.Body>
            {projectModel.projectExpenses
              .filter((item) => !item.isBillable || (item.isBillable && projectModel.isBillable))
              .map((item) => {
                const checked = selectedIds.some((id) => id === item.id);

                const handleSelectionChange = () => {
                  setSelectedIds(checked ? selectedIds.filter((id) => id !== item.id) : [...selectedIds, item.id]);
                };
                return (
                  <ProjectExpenseRow
                    key={item.id}
                    currency={currency}
                    projectExpense={item}
                    onEdit={() => handleEdit(item)}
                    onDelete={() => handleDelete(item)}
                    checked={checked}
                    onSelectionChange={() => handleSelectionChange(item)}
                  />
                );
              })}

            <Table.Row>
              <Table.Cell>
                <Button isAnchor isStrong onClick={handleAdd}>
                  <Icon icon="plus" size="xs" spaceRight />
                  Quick Add
                </Button>
              </Table.Cell>
            </Table.Row>

            <Table.Row style={{ borderBottom: 'none', fontWeight: weights.bold, textTransform: 'uppercase' }}>
              <Table.Cell>Total</Table.Cell>
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
              <Table.Cell />
              <Table.Cell>
                <Currency value={total} currency={currency} />
              </Table.Cell>
              <Table.Cell />
              <Table.Cell />
            </Table.Row>
          </Table.Body>
        </Table>
      </Form.Control>

      {formEntry && (
        <ProjectExpenseForm
          project={projectModel}
          currency={currency}
          projectExpense={formEntry}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
}

function ProjectExpenseRow({
  currency,
  projectExpense: { date, expenseCategory, notes, amount, isBillable, invoice, markup, total },
  onEdit,
  onDelete,
  onSelectionChange,
  checked,
}) {
  const confirmation = useConfirmation();

  const isInvoiced = invoice && invoice.statusId !== 'draft';
  const areActionsDisabled = isInvoiced;
  const tooltip = areActionsDisabled ? "Invoiced expenses can't be modified." : undefined;

  const handleDelete = async () => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this project expense?</DeleteConfirmation>
    ));
    if (!confirm) return;

    onDelete();
  };

  return (
    <Table.BoxRow>
      <Table.Cell>
        <Checkbox
          data-testid={`checkbox_project_expense`}
          disabled={areActionsDisabled}
          checked={checked}
          onChange={onSelectionChange}
        />
      </Table.Cell>
      <Table.Cell>
        <DateTime value={date} />
      </Table.Cell>
      <Table.Cell>{expenseCategory.name}</Table.Cell>
      <Table.Cell>{notes}</Table.Cell>
      <Table.Cell>
        {markup > 0 && isBillable ? (
          <MarkupIcon total={total} currency={currency} />
        ) : (
          <BillableIcon value={isBillable} />
        )}
      </Table.Cell>
      <Table.Cell>
        <Currency value={amount} currency={currency} />
      </Table.Cell>
      <Table.Cell style={{ padding: 3 }}>
        {isInvoiced && (
          <Tooltip message="An invoice has been published for this project expense.">
            <InvoicedIcon icon="file-invoice-dollar" />
          </Tooltip>
        )}
      </Table.Cell>

      <TableBoxRowActions>
        <TableBoxRowActions.Edit disabled={areActionsDisabled} tooltip={tooltip} onClick={onEdit} />

        <hr />

        <TableBoxRowActions.Delete disabled={areActionsDisabled} tooltip={tooltip} onClick={handleDelete} />
      </TableBoxRowActions>
    </Table.BoxRow>
  );
}

function ProjectExpenseForm({ project, projectExpense, currency, onSubmit, onCancel }) {
  const { workspace } = useWorkspace();

  const initialValues = mergeValues(
    {
      amount: '',
      date: moment().format(dateFormats.isoDate),
      expenseCategory: null,
      id: null,
      isBillable: false,
      notes: '',
      vendor: '',
      enableMarkup: false,
      markupMethod: 'percentage',
      markupAmount: '',
      markupRatio: '',
    },
    {
      ...projectExpense,
      markupRatio: getInitialMarkupRatioValue({
        markupRatio: projectExpense.markupRatio,
        defaultMarkupPercentage: workspace.defaultMarkupPercentage,
      }),
      enableMarkup: !!projectExpense.markupMethod,
    },
  );

  const handleSubmit = (values) => {
    if (!values.isBillable || !values.enableMarkup) {
      values.markupMethod = null;
      values.markupAmount = null;
      values.markupRatio = null;
    }

    onSubmit(
      emptyStringToNull({
        ..._.omit(values, ['enableMarkup']),
        markup: calculateExpenseMarkup(values),
        markupRatio: _.isNumber(values.markupRatio) ? Big(values.markupRatio).div(100).toNumber() : null,
        expenseCategoryId: values.expenseCategory.id,
      }),
    );
  };

  const features = useFeatures();

  return (
    <ModalCard title={initialValues.id ? 'Edit Project Expense' : 'Add Project Expense'} onClose={onCancel}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          amount: Yup.number().label('Amount').min(-99999999999).max(99999999999).nullable().required(),
          date: Yup.date().label('Date').nullable().required(),
          expenseCategory: Yup.object().label('Expense Category').nullable().required(),
          notes: Yup.string().label('Notes').max(1024),
          vendor: Yup.string().label('Vendor').max(255),
          markupMethod: Yup.string().label('Type'),
          markupAmount: Yup.number().label('Markup Amount').min(0).max(99999999999).nullable(),
          markupRatio: Yup.number().label('Markup Amount').min(0).max(100).nullable(),
        })}>
        {(formik) => {
          const markup = calculateExpenseMarkup(formik.values);

          const expensesRevenue = formik.values.isBillable
            ? calculateExpenseRevenue({ markup, amount: formik.values.amount })
            : '';

          const handleExpensesAmountChange = (value) => {
            // Deactivate "enableMarkup" if amount is 0 or null
            if (!value) formik.setFieldValue('enableMarkup', false);
            formik.setFieldValue('amount', value);
          };

          const handleIsBillableChange = ({ target: { value } }) => {
            // Deactivate "enableMarkup" if expense is non billable
            const newValue = value !== 'true';
            if (!newValue) formik.setFieldValue('enableMarkup', false);
            formik.setFieldValue('isBillable', newValue);
          };

          return (
            <Form>
              <ModalCard.Body>
                <Form.Control>
                  <Field.DayPicker name="date" placeholder="Date" clearable={false} />
                </Form.Control>

                <Form.Control>
                  <Field.ExpenseCategorySelect name="expenseCategory" placeholder="Expense Category" allowNew />
                </Form.Control>

                <Form.Control>
                  <Field.Text name="vendor" placeholder="Vendor" maxLength={255} />
                </Form.Control>

                <Form.Control>
                  <Field.Currency
                    onChange={handleExpensesAmountChange}
                    name="amount"
                    placeholder="Amount"
                    currency={currency}
                  />
                </Form.Control>

                <Form.Control>
                  <Checkboxes>
                    <Field.Checkbox
                      onChange={handleIsBillableChange}
                      name="isBillable"
                      label="Bill to client"
                      disabled={!project.isBillable}
                    />

                    {(features.expenseMarkup || projectExpense.enableMarkup) && (
                      <Field.Checkbox
                        name="enableMarkup"
                        label="Markup Expense"
                        disabled={!formik.values.isBillable || !(formik.values.amount > 0)}
                      />
                    )}
                  </Checkboxes>
                </Form.Control>

                {formik.values.enableMarkup && formik.values.isBillable && (
                  <>
                    <Form.Control>
                      <Field.RadioGroup name="markupMethod">
                        <Radio key="percentage" value="percentage" label="Markup by Percentage" />
                        <Radio key="amount" value="amount" label="Markup by Fixed Amount" />
                      </Field.RadioGroup>
                    </Form.Control>
                    {formik.values.markupMethod && (
                      <Form.Control>
                        {formik.values.markupMethod === 'amount' && (
                          <Field.Currency currency={currency} name="markupAmount" placeholder="Markup Amount" />
                        )}

                        {formik.values.markupMethod === 'percentage' && (
                          <Field.Number
                            suffix="%"
                            name="markupRatio"
                            placeholder="Markup Percentage"
                            min={0}
                            max={100}
                            precision={2}
                          />
                        )}
                      </Form.Control>
                    )}

                    <Form.Control>
                      <Field.Currency
                        name="expensesRevenue"
                        value={expensesRevenue}
                        materialPlaceholder="Expense Revenue"
                        disabled
                        materialAlwaysVisible
                        currency={currency}
                      />
                    </Form.Control>
                  </>
                )}

                <Form.Control>
                  <Field.Text name="notes" placeholder="Notes" maxLength={1024} />
                </Form.Control>
              </ModalCard.Body>

              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={onCancel}>Close</CancelButton>

                  <Button type="submit">{initialValues.id ? 'Save' : 'Add'}</Button>
                </Buttons>
              </ModalCard.Footer>
            </Form>
          );
        }}
      </Formik>
    </ModalCard>
  );
}

function MultipleProjectExpensesForm({ project, currency, onSubmit, onCancel }) {
  const { workspace } = useWorkspace();
  const features = useFeatures();
  const api = useApi();

  const initialValues = {
    start: null,
    end: null,
    dayOfMonth: null,
    amount: '',
    expenseCategory: null,
    isBillable: false,
    notes: '',
    vendor: '',
    enableMarkup: false,
    markupMethod: 'percentage',
    markupAmount: '',
    markupRatio: workspace.defaultMarkupPercentage ?? '',
  };

  const handleSubmit = async (values) => {
    if (!values.isBillable || !values.enableMarkup) {
      values.markupMethod = null;
      values.markupRatio = null;
      values.markupAmount = null;
    }

    const {
      start,
      end,
      dayOfMonth,
      amount,
      expenseCategory,
      isBillable,
      notes,
      vendor,
      markupMethod,
      markupAmount,
      markupRatio,
    } = emptyStringToNull(values);

    const { data: dates } = await api.www
      .workspaces(workspace.id)
      .projects(project.id)
      .generateRecurringDates({ start, end, dayOfMonth });

    const instances = dates.map((date) => {
      return {
        date: moment(date).format(dateFormats.isoDate),
        amount,
        expenseCategoryId: expenseCategory?.id,
        expenseCategory,
        isBillable,
        notes,
        vendor,
        markup: calculateExpenseMarkup(values),
        markupMethod,
        markupAmount,
        markupRatio: _.isNumber(markupRatio) ? Big(markupRatio).div(100).toNumber() : null,
      };
    });

    onSubmit(instances);
  };

  return (
    <ModalCard title="Add Multiple Project Expenses" onClose={onCancel}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          start: Yup.date().label('Starting Month').nullable().required(),
          end: Yup.date()
            .label('Ending Month')
            .nullable()
            .min(Yup.ref('start'), 'Ending Month must be after Starting Month')
            .required(),
          dayOfMonth: Yup.mixed()
            .label('Day of Month')
            .oneOf([...Array(28).keys()].map((i) => i + 1).concat('last'))
            .required(),
          amount: Yup.number().label('Amount').min(-99999999999).max(99999999999).nullable().required(),
          expenseCategory: Yup.object().label('Expense Category').nullable().required(),
          notes: Yup.string().label('Notes').max(255),
          vendor: Yup.string().label('Vendor').max(255),
          markupMethod: Yup.string().label('Type'),
          markupAmount: Yup.number().label('Markup Amount').min(-99999999999).max(99999999999).nullable(),
          markupRatio: Yup.number().label('Markup Amount').min(0).max(100).nullable(),
        })}>
        {({ values, setFieldValue }) => {
          const markup = calculateExpenseMarkup(values);

          const expenseRevenue = values.isBillable ? calculateExpenseRevenue({ markup, amount: values.amount }) : '';

          const handleExpensesAmountChange = (value) => {
            // Deactivate "enableMarkup" if amount is 0 or null
            if (!value) setFieldValue('enableMarkup', false);
            setFieldValue('amount', value);
          };

          const handleIsBillableChange = ({ target: { value } }) => {
            // Deactivate "enableMarkup" if expense is non billable
            const newValue = value !== 'true';
            if (!newValue) setFieldValue('enableMarkup', false);
            setFieldValue('isBillable', newValue);
          };

          return (
            <Form>
              <ModalCard.Body>
                <Form.Control>
                  <Field.DayPicker
                    name="start"
                    placeholder="Starting Month"
                    locale="en-US"
                    displayFormat={dateFormats.monthYear}
                    scope="month"
                  />
                  <Field.DayPicker
                    name="end"
                    placeholder="Ending Month"
                    locale="en-US"
                    displayFormat={dateFormats.monthYear}
                    scope="month"
                  />
                </Form.Control>

                <Form.Control>
                  <ContainerControl style={{ marginTop: '0' }}>
                    Scheduled on the
                    <div style={{ margin: '0 0.5rem' }}>
                      <Field.SingleSelect
                        name="dayOfMonth"
                        style={{ width: '7rem', margin: '0 0.5rem', textAlign: 'right' }}
                        materialPlaceholder={false}>
                        {[...Array(28).keys()].map((day) => (
                          <option key={day} value={day + 1}>
                            {day + 1}
                            {{
                              1: 'st',
                              2: 'nd',
                              3: 'rd',
                            }[day + 1] || 'th'}
                          </option>
                        ))}
                        <option value="last">Last</option>
                      </Field.SingleSelect>
                    </div>
                    <span>day of each month</span>
                  </ContainerControl>
                </Form.Control>

                <Form.Control>
                  <Field.ExpenseCategorySelect name="expenseCategory" placeholder="Expense Category" allowNew />
                </Form.Control>

                <Form.Control>
                  <Field.Text name="vendor" placeholder="Vendor" maxLength={255} />
                </Form.Control>

                <Form.Control>
                  <Field.Currency
                    onChange={handleExpensesAmountChange}
                    name="amount"
                    placeholder="Amount"
                    currency={currency}
                  />
                </Form.Control>

                <Form.Control>
                  <Checkboxes>
                    <Field.Checkbox
                      onChange={handleIsBillableChange}
                      name="isBillable"
                      label="Bill to client"
                      disabled={!project.isBillable}
                    />

                    {features.expenseMarkup && (
                      <Field.Checkbox
                        name="enableMarkup"
                        label="Markup Expense"
                        disabled={!values.isBillable || !(values.amount > 0)}
                      />
                    )}
                  </Checkboxes>
                </Form.Control>

                {values.enableMarkup && values.isBillable && (
                  <>
                    <Form.Control>
                      <Field.RadioGroup name="markupMethod">
                        <Radio key="percentage" value="percentage" label="Markup by Percentage" />
                        <Radio key="amount" value="amount" label="Markup by Fixed Amount" />
                      </Field.RadioGroup>
                    </Form.Control>
                    {values.markupMethod && (
                      <Form.Control>
                        {values.markupMethod === 'amount' && (
                          <Field.Currency currency={currency} name="markupAmount" placeholder="Markup Amount" />
                        )}

                        {values.markupMethod === 'percentage' && (
                          <Field.Number
                            prefix="%"
                            name="markupRatio"
                            placeholder="Markup Percentage"
                            min={0}
                            max={100}
                            precision={2}
                          />
                        )}
                      </Form.Control>
                    )}

                    <Form.Control>
                      <Field.Currency
                        name="expenseRevenue"
                        value={expenseRevenue}
                        materialPlaceholder="Expense Revenue"
                        disabled
                        materialAlwaysVisible
                        currency={currency}
                      />
                    </Form.Control>
                  </>
                )}

                <Form.Control>
                  <Field.Text name="notes" placeholder="Notes" maxLength={255} />
                </Form.Control>
              </ModalCard.Body>

              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={onCancel}>Close</CancelButton>
                  <Button type="submit">Add</Button>
                </Buttons>
              </ModalCard.Footer>
            </Form>
          );
        }}
      </Formik>
    </ModalCard>
  );
}

export default ProjectExpenses;
