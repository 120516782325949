import { useDateTimeFormat, usePercentFormat } from '~/hooks';
import _ from 'lodash';
import moment from 'moment';
import { rgba } from 'polished';
import React, { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { colors } from '~/styles';
import { dateFormats } from '~/utils';

const TopPadding = {
  beforeInit: function (chart) {
    chart.legend.afterFit = function () {
      this.height = this.height + 10;
    };
  },
};

function getActualDataset(data) {
  if (!data.actual.dates.length) return null;

  let points = _.reduce(
    data.actual.dates,
    (result, value) => {
      result.push({ x: value.date, y: value.margin, tooltip: true });
      return result;
    },
    [],
  );

  let end = moment(points[points.length - 1]?.x);

  if (data.forecast?.dates.length > 0) {
    if (end.isBefore(moment(data.actualsThrough), 'day')) end = moment(data.actualsThrough);

    points.push({
      x: moment(data.actualsThrough).format(dateFormats.isoDate),
      y: points[points.length - 1]?.y ?? 0,
      tooltip: false,
    });
  }

  return {
    id: 'margin',
    label: 'Total Gross Margin      ',
    data: points,
    yAxisID: 'y',
    fill: false,
    backgroundColor: colors.primary,
    borderColor: colors.primary,
    tension: 0,
  };
}

function getForecastDataset(data) {
  if (!data.forecast?.dates.length) return null;

  let points = _.reduce(
    data.forecast.dates,
    (result, value) => {
      result.push({ x: value.date, y: value.margin, tooltip: true });
      return result;
    },
    [],
  );

  let start = moment(points[0]?.x);
  if (start.isAfter(moment(data.actualsThrough), 'day')) start = moment(data.actualsThrough);

  if (data.actual.dates.length > 0) {
    points.unshift({
      x: moment(data.actualsThrough).format(dateFormats.isoDate),
      y: data.actual.margin,
      tooltip: true,
    });
  }

  return {
    id: 'forecasted',
    label: 'Forecasted Total Gross Margin      ',
    data: points,
    yAxisID: 'y',
    fill: false,
    backgroundColor: colors.primary25,
    borderColor: colors.primary25,
    tension: 0,
    borderDash: [7],
    borderWidth: 3,
  };
}

export default function TotalMarginProgressChart({ data, project }) {
  const [version, setVersion] = useState(0);

  // Set a new version when the chart metrics change
  useEffect(() => {
    setVersion((version) => version + 1);
  }, [data, project.start, project.end]);

  const dateTimeFormat = useDateTimeFormat();

  const percentFormat = {
    ticks: usePercentFormat({ minimumFractionDigits: 0, maximumFractionDigits: 0 }),
    tooltip: usePercentFormat(),
  };

  const { chartData, chartConfig } = useMemo(() => {
    const chartData = {
      datasets: _.compact([getActualDataset(data, project), getForecastDataset(data, project)]),
    };

    const chartConfig = {
      options: {
        maintainAspectRatio: false,
        responsive: true,
        elements: {
          point: {
            radius: 0,
          },
        },

        plugins: {
          legend: {
            display: true,
            position: 'top',
            onClick: null,
            padding: 12,
            labels: {
              font: {
                size: 12,
              },
              usePointStyle: true,
              pointStyleWidth: 14,
              boxHeight: 10,
            },
          },

          tooltip: {
            intersect: false,
            filter: (point) => {
              return point.raw.tooltip;
            },
            callbacks: {
              title: ([tooltip]) => {
                if (!tooltip) return;
                return dateTimeFormat.format(tooltip.raw.x);
              },
              label: (tooltip) => {
                let label = (tooltip.dataset.label || '').trim();
                if (label) {
                  label += ': ';
                }
                label += tooltip.parsed.y !== null ? percentFormat.tooltip.format(tooltip.parsed.y) : '-';
                return label;
              },
            },
          },

          annotation: {
            annotations: {},
          },
        },

        scales: {
          x: {
            type: 'time',
            distribution: 'linear',
            ticks: {
              maxTicksLimit: 10,
            },

            time: {
              unit: 'week',
              isoWeekday: true,
              displayFormats: {
                day: 'MMM DD',
                week: 'MMM DD',
                month: 'MMM DD',
                quarter: 'MMM DD',
                year: 'MMM DD',
              },
            },
          },

          y: {
            grid: { display: false },
            count: 10,
            ticks: {
              font: {
                weight: 'bold',
              },
              color: colors.grey100,
              callback: function (value) {
                return percentFormat.ticks.format(value);
              },
            },
            type: 'linear',
            display: true,
            position: 'right',
            id: 'y',
            suggestedMin: 0,
            max: 1,
          },
        },
      },
    };

    if (data.budget?.margin.estimated > 0) {
      // Set budget line
      chartConfig.options.plugins.annotation.annotations.budget = {
        type: 'line',
        scaleID: 'y',
        value: data.budget.margin.estimated,
        borderColor: rgba(colors.danger, 0.5),
        borderWidth: 3,
        borderDash: [3],
        label: {
          display: true,
          content: `Budget: ${percentFormat.ticks.format(data.budget.margin.estimated)}`,
          padding: { top: 4, bottom: 2, left: 6, right: 6 },
          backgroundColor: colors.danger,
          font: { weight: 'normal' },
          z: 1,
        },
      };
    }

    // Add a vertical line on today's date
    chartConfig.options.plugins.annotation.annotations.today = {
      type: 'line',
      mode: 'vertical',
      scaleID: 'x',
      value: moment().format(dateFormats.isoDate),
      borderWidth: 3,
      borderColor: rgba(colors.grey75, 0.5),
      label: {
        content: 'Today',
        display: true,
        position: 'start',
        padding: { top: 4, bottom: 2, left: 6, right: 6 },
        backgroundColor: colors.grey55,
        font: { weight: 'normal' },
        yAdjust: -4,
      },
    };

    let start = moment.min(
      _.compact([_.first(data.actual.dates)?.date, _.first(data.forecast?.dates)?.date, project.start])
        .map((d) => moment(d))
        .filter((d) => d.isValid()),
    );

    if (!start.isValid()) start = moment();

    chartConfig.options.scales.x.min = start.startOf('isoWeek').format(dateFormats.isoDate);

    if (project.start) {
      chartConfig.options.plugins.annotation.annotations.start = {
        type: 'line',
        mode: 'vertical',
        scaleID: 'x',
        value: moment(project.start).format(dateFormats.isoDate),
        borderWidth: 3,
        borderColor: rgba(colors.success, 0.5),
        label: {
          content: 'Start',
          display: true,
          position: 'start',
          padding: { top: 4, bottom: 2, left: 6, right: 6 },
          backgroundColor: colors.success,
          font: { weight: 'normal' },
          xAdjust: -6,
          yAdjust: -4,
        },
      };
    }

    let end = moment.max(
      _.compact([_.last(data.actual.dates)?.date, _.last(data.forecast?.dates)?.date, project.end])
        .map((d) => moment(d))
        .filter((d) => d.isValid()),
    );

    if (!end.isValid()) end = moment();

    chartConfig.options.scales.x.max = end.endOf('isoWeek').add(1, 'day').format(dateFormats.isoDate);

    if (project.end) {
      chartConfig.options.plugins.annotation.annotations.end = {
        type: 'line',
        mode: 'vertical',
        scaleID: 'x',
        value: moment(project.end).format(dateFormats.isoDate),
        borderWidth: 3,
        borderColor: rgba(colors.danger, 0.5),
        label: {
          content: 'End',
          display: true,
          position: 'start',
          padding: { top: 4, bottom: 2, left: 6, right: 6 },
          backgroundColor: colors.danger,
          font: { weight: 'normal' },
          textAlign: 'center',
          xAdjust: -8,
          yAdjust: -4,
        },
      };
    }

    return { chartConfig, chartData };
  }, [data, project, dateTimeFormat, percentFormat.ticks, percentFormat.tooltip]);

  return <Line key={version} data={chartData} options={chartConfig.options} plugins={[TopPadding]} />;
}
