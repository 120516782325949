import { useAuth } from '~/hooks';
import HoursPlanByProjectAndResource from './HoursPlanByProjectAndResource';
import HoursPlanByResourceAndProject from './HoursPlanByResourceAndProject';
import PerformancePlanByProject from './PerformancePlanByProject';
import ServicesRevenuePlanByClientAndProject from './ServicesRevenuePlanByClientAndProject';
import UtilizationPlanByMember from './UtilizationPlanByMember';
import UtilizationPlanByMemberPractice from './UtilizationPlanByMemberPractice';

export default function usePlanReports() {
  const auth = useAuth();

  return [
    auth.reports.hoursPlan && {
      key: 'hours_plan_by_project_and_resource',
      name: 'Hours Plan by Project and Resource',
      description: 'A summary of allocated hours grouped by project and resource.',
      path: '/hours-plan-by-project-and-resource',
      component: HoursPlanByProjectAndResource,
    },
    auth.reports.hoursPlan && {
      key: 'hours_plan_by_resource_and_project',
      name: 'Hours Plan by Resource and Project',
      description: 'A summary of allocated hours grouped by resource and project.',
      path: '/hours-plan-by-resource-and-project',
      component: HoursPlanByResourceAndProject,
    },
    auth.reports.performancePlanByProject && {
      key: 'performance_plan_by_project',
      name: 'Performance Plan by Project',
      description:
        'A project performance plan. The plan is derived from resource allocations, billing schedule milestones, revenue recognition ledger entries, expense items, and other items to bill.',
      path: '/performance-plan-by-project',
      component: PerformancePlanByProject,
    },
    auth.reports.revenuePlan && {
      key: 'services_revenue_plan_by_client_and_project',
      name: 'Services Revenue Plan by Client and Project',
      description:
        'A services revenue plan grouped by client and project. The plan is derived from resource allocations, billing schedule milestones, and revenue recognition ledger entries.',
      path: '/services-revenue-plan-by-client-and-project',
      component: ServicesRevenuePlanByClientAndProject,
    },
    auth.reports.utilizationPlanByMember && {
      key: 'utilization_plan_by_member',
      name: 'Utilization Plan by Member',
      description: 'The planned utilization based on allocated hours.',
      path: '/utilization-plan-by-member',
      component: UtilizationPlanByMember,
    },
    auth.reports.utilizationPlanByMemberPractice && {
      key: 'utilization_plan_by_member_practice',
      name: 'Utilization Plan by Member Practice',
      description: 'The planned utilization based on allocated hours by member practice.',
      path: '/utilization-plan-by-member-practice',
      component: UtilizationPlanByMemberPractice,
    },
  ].filter(Boolean);
}
