import {
  Button,
  Buttons,
  CancelButton,
  DeleteButton,
  Drawer,
  Field,
  Form,
  FormMessage,
  Icon,
  OpportunityTagMultiSelect,
  Tooltip,
} from '~/components';
import { useApi, useConfirmation, useMember, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useDocumentTitle, useFeatures, useForm } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ErrorPage } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors } from '~/styles';
import { emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';
import OpportunityDeleteConfirmation from './OpportunityDeleteConfirmation';
import ProjectSelect from './ProjectSelect';

const CompanySelectWrapper = styled.div`
  flex-grow: 1;
`;

const ViewCompanyIcon = styled(Icon)`
  left: calc(100% + 1rem);
  position: absolute;
  top: 1.5rem;
  color: ${colors.grey25};
  &:hover {
    color: ${colors.grey55};
    cursor: pointer;
  }
`;

function OpportunityForm({ onSaved, onDeleted, onClose }) {
  useDocumentTitle('Edit Opportunity');

  const api = useApi();
  const { workspace } = useWorkspace();
  const { member } = useMember();
  const features = useFeatures();
  const [query, setQuery] = useState({ isReady: false, data: null });
  const [{ status, message, isSubmitting }, form] = useForm();

  const { opportunityId, opportunityTab } = useParams();
  const history = useHistory();

  const confirmation = useConfirmation();

  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).opportunities(opportunityId).get();
      setQuery({ isReady: true, data });
    } catch (error) {
      setQuery({ isReady: true, data: null, error });
    }
  }, [workspace.id, opportunityId, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function handleClose() {
    if (onClose) {
      onClose(opportunityTab);
    }
  }

  const opportunity = query.data;
  const salesforceReadOnly = !!opportunity?.salesforceOpportunity?.salesforceId;
  const hubspotReadOnly = !!opportunity?.hubspotOpportunity?.hubspotId;

  const handleDelete = () => {
    confirmation.prompt((resolve) => (
      <OpportunityDeleteConfirmation
        opportunity={opportunity}
        onClose={resolve}
        onDelete={() => {
          history.push(`/app/${workspace.key}/pipeline/opportunities`);
          if (onDeleted) onDeleted(opportunity.id);
          resolve(true);
        }}
      />
    ));
  };

  if (!query.isReady) return null;
  if (!opportunity) return <ErrorPage.NotFound />;

  const initialValues = mergeValues(
    {
      amount: '',
      closeDate: null,
      company: null,
      currency: workspace.currency,
      description: '',
      name: '',
      nextSteps: '',
      opportunityLeadSource: null,
      opportunityType: null,
      tags: [],
      owner: null,
      opportunityStage: null,
      practice: null,
      priorityId: '',
      probability: '',
      project: null,
    },
    opportunity,
  );

  return (
    <Drawer
      isOpen
      title="Edit Opportunity"
      byline={opportunity.name}
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(values) {
          try {
            form.submit();
            const body = emptyStringToNull({
              ..._.omit(values, [
                'company',
                'opportunityLeadSource',
                'opportunityType',
                'tags',
                'owner',
                'opportunityStage',
                'practice',
                'project',
              ]),
              companyId: values.company?.id ?? null,
              opportunityLeadSourceId: values.opportunityLeadSource?.id ?? null,
              opportunityTypeId: values.opportunityType?.id ?? null,
              opportunityTagAssignments: values.tags.map((tag) => ({ opportunityTagId: tag.id })),
              ownerId: values.owner?.id ?? null,
              opportunityStageId: values.opportunityStage?.id ?? null,
              practiceId: values.practice?.id ?? null,
              projectId: values.project?.id ?? null,
            });

            await api.www.workspaces(workspace.id).opportunities(opportunity?.id).upsert(body);
            await onSaved();
            closeDrawer();
          } catch ({ message }) {
            form.error({ message });
          }
        }

        return (
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape(
              hubspotReadOnly
                ? {
                    practice: Yup.object().label('Practice').nullable(),
                    project: Yup.object().label('Project').nullable(),
                  }
                : salesforceReadOnly
                  ? {
                      practice: Yup.object().label('Practice').nullable(),
                      priorityId: Yup.string().label('Priority').oneOf(['low', 'medium', 'high']).nullable(),
                      project: Yup.object().label('Project').nullable(),
                    }
                  : {
                      amount: Yup.number().label('Amount').min(0.01).max(99999999999).nullable(),
                      closeDate: Yup.date().label('Expected Close Date').nullable(),
                      company: Yup.mixed().label('Company').required(),
                      currency: Yup.string().label('Currency').required(),
                      description: Yup.string().label('Description').max(5000),
                      name: Yup.string().label('Name').max(255).required(),
                      nextSteps: Yup.string().label('Next Steps').max(255).nullable(),
                      owner: Yup.object().label('Owner').nullable(),
                      opportunityStage: Yup.object().label('Opportunity Stage').nullable().required(),
                      practice: Yup.object().label('Practice').nullable(),
                      priorityId: Yup.string().label('Priority').oneOf(['low', 'medium', 'high']).nullable(),
                      probability: Yup.number()
                        .label('Probability')
                        .min(0)
                        .max(100)
                        .nullable()
                        .required('Probability must be a between 0 and 100'),
                      project: Yup.object().label('Project').nullable(),
                    },
            )}>
            {(formik) => {
              const { values, setValues, setFieldValue, submitForm } = formik;

              const handleOpportunityStageChange = ({ target: { value } }) => {
                setValues({
                  ...values,
                  opportunityStage: value,
                  probability: value ? value.probability : values.probability,
                });
              };

              const handleCompanyChange = ({ target: { value } }) => {
                setValues({
                  ...values,
                  company: value,
                  project: value.client?.id === opportunity.project?.client.id ? opportunity.project : null,
                });
              };

              return (
                <Form>
                  {status && <FormMessage.Error spaceBottom>{message}</FormMessage.Error>}

                  <Form.Section title="Basic Information">
                    <Form.Control>
                      <Field.Text
                        name="name"
                        placeholder="Name"
                        maxLength={255}
                        disabled={salesforceReadOnly || hubspotReadOnly}
                      />
                    </Form.Control>

                    <Form.Control>
                      <div style={{ display: 'flex' }}>
                        <CompanySelectWrapper>
                          <Field.CompanySelect
                            name="company"
                            placeholder="Company"
                            allowNew
                            onChange={handleCompanyChange}
                            clearable={false}
                            disabled={salesforceReadOnly || hubspotReadOnly}
                          />
                        </CompanySelectWrapper>

                        {values.company && (
                          <a
                            href={`/app/${workspace.key}/pipeline/companies/${values.company.id}/overview`}
                            target="_blank"
                            rel="noopener noreferrer">
                            <Tooltip message={'View Company'} placement="top">
                              <ViewCompanyIcon icon="external-link-alt" />
                            </Tooltip>
                          </a>
                        )}
                      </div>
                    </Form.Control>

                    <Form.Control>
                      <ProjectSelect
                        name="project"
                        placeholder="Project"
                        value={values.project}
                        client={values.company?.client}
                        initialValue={opportunity?.project}
                        onChange={(event) => {
                          setFieldValue('project', event.target.value);
                        }}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.MemberSelect
                        name="owner"
                        placeholder="Owner"
                        initialValue={opportunity?.owner}
                        disabled={salesforceReadOnly || hubspotReadOnly}
                        clearable={!salesforceReadOnly && !hubspotReadOnly}
                      />
                    </Form.Control>

                    {features.practices && (
                      <Form.Control>
                        <Field.PracticeSelect name="practice" placeholder="Practice" allowNew />
                      </Form.Control>
                    )}

                    <Form.Control>
                      <Field.OpportunityStageSelect
                        name="opportunityStage"
                        placeholder="Stage"
                        clearable={false}
                        onChange={handleOpportunityStageChange}
                        initialValue={opportunity?.opportunityStage}
                        disabled={salesforceReadOnly || hubspotReadOnly}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.Currency
                        name="amount"
                        placeholder="Amount"
                        currency={values.currency}
                        disabled={salesforceReadOnly || hubspotReadOnly}
                      />
                      <Field.WorkspaceCurrencySelect
                        name="currency"
                        placeholder="Currency"
                        clearable={false}
                        disabled={!features.multicurrency || salesforceReadOnly || hubspotReadOnly}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.Number
                        name="probability"
                        placeholder="Probability to Close"
                        min={0}
                        max={100}
                        precision={2}
                        prefix="%"
                        locale={member.locale}
                        style={{ textAlign: 'left' }}
                        disabled={salesforceReadOnly || hubspotReadOnly}
                      />
                      <Field.DayPicker
                        name="closeDate"
                        placeholder="Expected Close Date"
                        disabled={salesforceReadOnly || hubspotReadOnly}
                        clearable={!salesforceReadOnly && !hubspotReadOnly}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.TextArea
                        name="nextSteps"
                        placeholder="Next Steps"
                        maxLength={255}
                        rows={3}
                        disabled={salesforceReadOnly || hubspotReadOnly}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.SingleSelect
                        name="priorityId"
                        placeholder="Priority"
                        showEmptyOption={!hubspotReadOnly}
                        disabled={hubspotReadOnly}>
                        <option value="high">High</option>
                        <option value="medium">Medium</option>
                        <option value="low">Low</option>
                      </Field.SingleSelect>
                    </Form.Control>

                    <Form.Control>
                      <Field.OpportunityTypeSelect
                        name="opportunityType"
                        placeholder="Opportunity Type"
                        allowNew
                        initialValue={opportunity?.opportunityType}
                        disabled={salesforceReadOnly || hubspotReadOnly}
                        clearable={!salesforceReadOnly && !hubspotReadOnly}
                      />
                    </Form.Control>

                    <Form.Control>
                      <OpportunityTagMultiSelect
                        name="tags"
                        placeholder="Tags"
                        value={formik.values.tags}
                        allowNew
                        onChange={({ target: { value } }) => formik.setFieldValue('tags', value)}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.OpportunityLeadSourceSelect
                        name="opportunityLeadSource"
                        placeholder="Opportunity Lead Source"
                        allowNew
                        initialValue={opportunity?.opportunityLeadSource}
                        disabled={salesforceReadOnly || hubspotReadOnly}
                        clearable={!salesforceReadOnly && !hubspotReadOnly}
                      />
                    </Form.Control>

                    <Form.Control>
                      <Field.TextArea
                        name="description"
                        placeholder="Description"
                        maxLength={5000}
                        disabled={salesforceReadOnly || hubspotReadOnly}
                      />
                    </Form.Control>
                  </Form.Section>

                  <Drawer.Actions>
                    {opportunity.id && opportunity.permissions.manage && (
                      <DeleteButton onClick={handleDelete}>Delete</DeleteButton>
                    )}

                    <Buttons align="right">
                      <Buttons align="right">
                        <CancelButton onClick={handleCloseClick}>Close</CancelButton>

                        <Button isLoading={isSubmitting} onClick={submitForm}>
                          Save &amp; Close
                        </Button>
                      </Buttons>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}

export default OpportunityForm;
