import React, { useCallback, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import {
  Confirmation,
  DeleteConfirmation,
  FiltersBar,
  Icon,
  IconLink,
  Level,
  ListView,
  ListViewActions,
  ListViewMenu,
  Page,
  SingleSelectFilter,
  Tooltip,
} from '~/components';
import { useApi, useConfirmation, useSession, useToast, useWorkspace } from '~/contexts';
import { useDocumentTitle, useIsMounted } from '~/hooks';
import { authMethods, authProviders } from '~/lookups';
import { PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';
import AuthProviderForm from './AuthProviderForm';
import SamlSettings from './SamlSettings';

const WarningIcon = styled(Icon)`
  color: ${colors.warning};
  font-size: 2.5rem;
`;

export default function AuthProviders() {
  const documentTitle = useDocumentTitle('Authentication');

  const api = useApi();
  const { workspace } = useWorkspace();
  const [data, setData] = useState(null);
  const toast = useToast();
  const [params, setParams] = useState({ isActive: 'true' });
  const isMounted = useIsMounted();
  const {
    session: { memberSessions },
    isAdmin,
  } = useSession();

  const authProviderId = memberSessions?.find((ms) => ms.workspaceId === workspace.id).authProviderId;

  const { path, url } = useRouteMatch();
  const history = useHistory();

  const fetchData = useCallback(async () => {
    const { data } = await api.www
      .workspaces(workspace.id)
      .authProviders()
      .get({ params, isActive: params.isActive ?? undefined });
    if (!isMounted.current) return;
    setData(data);
  }, [workspace.id, isMounted, api, params]);

  async function handleActiveStatusChange(item, flag) {
    if (!flag) {
      const confirm = await confirmation.prompt((resolve) => (
        <DeleteConfirmation resolve={resolve} title="Deactivate Authentication Provider" deleteLabel="Deactivate">
          <Level>
            <Level.Item narrow>
              <WarningIcon icon="exclamation-triangle" />
            </Level.Item>
            <Level.Item>
              <p>
                Workspace members won't be able to login using the <strong>{item.name}</strong> provider. Do you want to
                deactivate this authentication provider?
              </p>
            </Level.Item>
          </Level>
        </DeleteConfirmation>
      ));
      if (!confirm) return;
    }

    try {
      await api.www.workspaces(workspace.id).authProviders(item.id).setActiveStatus(flag);
      fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  async function handleSetDefault(item) {
    const confirm = await confirmation.prompt((resolve) => (
      <Confirmation resolve={resolve} title="Set Default Authentication Provider">
        <Level>
          <Level.Item narrow>
            <WarningIcon icon="exclamation-triangle" />
          </Level.Item>
          <Level.Item>
            <p>
              By setting <strong>{item.name}</strong> as the default authentication provider, the login page for all
              workspace members will show the login form for this provider. Do you want to set this authentication
              provider as the default?
            </p>
          </Level.Item>
        </Level>
      </Confirmation>
    ));
    if (!confirm) return;

    try {
      await api.www.workspaces(workspace.id).authProviders(item.id).setDefault();
      fetchData();
    } catch ({ message }) {
      toast.error(message);
    }
  }

  const confirmation = useConfirmation();

  const handleDelete = async (item) => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve} title="Delete Authentication Provider">
        <Level>
          <Level.Item narrow>
            <WarningIcon icon="exclamation-triangle" />
          </Level.Item>
          <Level.Item>
            <p>
              By deleting <strong>{item.name}</strong>, workspace members won't be able to login using this provider. Do
              you want to delete this authentication provider?
            </p>
          </Level.Item>
        </Level>
      </DeleteConfirmation>
    ));
    if (!confirm) return;

    await api.www.workspaces(workspace.id).authProviders(item.id).delete();

    fetchData();

    return true;
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleEdit = async (authProviderId) => {
    history.push(url.concat(`/${authProviderId}/edit`));
  };

  const handleChange = ({ target: { name, value } }) => {
    setParams({ ...params, [name]: value });
  };

  function handleCloseDrawer() {
    history.push(`/app/${workspace.key}/settings/authentication`);
    documentTitle.set('Authentication');
  }

  if (!data) return <PageLoader />;

  return (
    <Page scrollable>
      <Page.Header>
        <Page.Info>
          <Page.Eyebrow>Settings</Page.Eyebrow>
          <Page.Title>Authentication</Page.Title>
        </Page.Info>

        <Page.Actions>
          <IconLink icon="plus" tooltip="New Authentication Provider" to={url.concat('/new')} />
        </Page.Actions>
      </Page.Header>

      <Page.Filters>
        <FiltersBar>
          <SingleSelectFilter
            icon="filter"
            name="isActive"
            placeholder="Status"
            value={params.isActive}
            options={[
              { id: 'true', name: 'Active' },
              { id: 'false', name: 'Inactive' },
            ]}
            onChange={handleChange}
          />
        </FiltersBar>
      </Page.Filters>

      <Page.ListView>
        <ListView>
          <ListView.Header>
            <ListView.Column sticky minWidth="10rem">
              Name
            </ListView.Column>
            <ListView.Column minWidth="10rem">Key</ListView.Column>
            <ListView.Column width="12rem">Provider</ListView.Column>
            <ListView.Column width="10rem">Method</ListView.Column>
            <ListView.Column width="6.5rem" align="right">
              Sessions
            </ListView.Column>
            <ListViewActions.Column />
          </ListView.Header>

          <ListView.Body>
            {data.map((ap) => {
              const isLastActive = data.length === 1 && ap.isActive;
              const isDefault = ap.isDefault;
              const isRuddr = ap.provider === 'ruddr';
              const isCurrent = ap.id === authProviderId;

              return (
                <ListView.Row key={ap.id} isDisabled={!ap.isActive}>
                  <ListView.Cell>
                    {ap.name}{' '}
                    {ap.isDefault && (
                      <Tooltip
                        placement="right"
                        style={{ display: 'inline', marginLeft: '0.5rem' }}
                        message="The default authentication provider.">
                        <Icon icon="badge-check" />
                      </Tooltip>
                    )}
                    {!ap.isValid && (
                      <Tooltip
                        placement="right"
                        style={{ display: 'inline', marginLeft: '0.5rem' }}
                        message="This authentication provider configuration is invalid.">
                        <Icon icon="exclamation-triangle" color={colors.warning} />
                      </Tooltip>
                    )}
                  </ListView.Cell>
                  <ListView.Cell>{ap.key}</ListView.Cell>
                  <ListView.Cell>{authProviders[ap.provider].name}</ListView.Cell>
                  <ListView.Cell>{authMethods[ap.method].name}</ListView.Cell>
                  <ListView.Cell>{ap.memberSessionCount}</ListView.Cell>
                  <ListViewActions>
                    {isRuddr ? (
                      <ListViewActions.View onClick={() => handleEdit(ap.id)} />
                    ) : (
                      <ListViewActions.Edit onClick={() => handleEdit(ap.id)} />
                    )}
                    <hr />
                    <ListViewMenu>
                      {({ setIsOpen }) => {
                        const handleAction = async (action) => {
                          setIsOpen(false);
                          await action();
                        };

                        return (
                          <>
                            <ListViewMenu.Item onClick={() => handleAction(() => handleEdit(ap.id))}>
                              {isRuddr ? 'View' : 'Edit'}
                            </ListViewMenu.Item>

                            <ListViewMenu.Item
                              disabled={isLastActive || isDefault || isCurrent}
                              tooltip={
                                isDefault
                                  ? 'Cannot deactivate the default authentication provider.'
                                  : isLastActive
                                    ? 'Cannot deactivate the last active authentication provider.'
                                    : isCurrent
                                      ? `Cannot deactivate the authentication provider that you're currently logged in with.`
                                      : undefined
                              }
                              onClick={async () => {
                                await handleActiveStatusChange(ap, !ap.isActive);
                                setIsOpen(false);
                              }}>
                              {ap.isActive ? 'Deactivate' : 'Activate'}
                            </ListViewMenu.Item>

                            <ListViewMenu.Item
                              disabled={isRuddr || isLastActive || isDefault || isCurrent}
                              tooltip={
                                isRuddr
                                  ? 'Cannot delete the Ruddr authentication provider.'
                                  : isDefault
                                    ? 'Cannot delete the default authentication provider.'
                                    : isLastActive
                                      ? 'Cannot delete the last active authentication provider.'
                                      : isCurrent
                                        ? `Cannot delete the authentication provider that you're currently logged in with.`
                                        : undefined
                              }
                              onClick={() => setIsOpen(false) || handleDelete(ap)}>
                              Delete
                            </ListViewMenu.Item>

                            {!ap.isDefault && (
                              <ListViewMenu.Item
                                disabled={!ap.isActive || (!isCurrent && !isAdmin)}
                                tooltip={
                                  !ap.isActive
                                    ? 'Cannot set an inactive authentication provider as default.'
                                    : !isCurrent && !isAdmin
                                      ? `Login with this authentication provider to set it as the default.`
                                      : undefined
                                }
                                onClick={async () => {
                                  await handleSetDefault(ap);
                                  setIsOpen(false);
                                }}>
                                Set to default
                              </ListViewMenu.Item>
                            )}

                            {ap.method === 'saml' && (
                              <ListViewMenu.Item
                                onClick={() => {
                                  history.push(url.concat(`/${ap.id}/saml`));
                                }}>
                                SAML Settings
                              </ListViewMenu.Item>
                            )}
                          </>
                        );
                      }}
                    </ListViewMenu>
                  </ListViewActions>
                </ListView.Row>
              );
            })}

            {data.length === 0 && <ListView.Empty />}
          </ListView.Body>

          <ListView.Status total={data.length} label="Authentication Provider" />
        </ListView>
      </Page.ListView>

      <Switch>
        <Route path={`${path}/new`}>
          <AuthProviderForm onClose={handleCloseDrawer} onSaved={fetchData} />
        </Route>

        <Route path={`${path}/:authProviderId/edit`}>
          <AuthProviderForm onClose={handleCloseDrawer} onSaved={fetchData} onDelete={handleDelete} />
        </Route>

        <Route path={`${path}/:authProviderId/saml`}>
          <SamlSettings onClose={handleCloseDrawer} onSaved={fetchData} />
        </Route>
      </Switch>
    </Page>
  );
}
