import Big from 'big.js';
import { sum } from '~/utils/math';
import calculateServicesBudget from './calculateServicesBudget';

export default function calculateBudgetRecognizableRevenue(project) {
  const servicesRevenue = calculateServicesBudget(project)?.revenue || 0;

  let expensesRevenue = 0;
  switch (project.budgetMode) {
    case 'detailed':
    case 'aggregated':
      expensesRevenue = sum(project.budgetExpenses.map((expense) => expense.nonBillableAmount || 0));
      break;

    default: {
      expensesRevenue = project.budgetExpensesNonBillableAmount || 0;
      break;
    }
  }

  return Big(servicesRevenue).add(expensesRevenue).round(2).toNumber();
}
