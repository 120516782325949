import React from 'react';
import styled from 'styled-components';
import { Button, Icon, InlineTooltip } from '~/components';
import { colors } from '~/styles';

const StyledButton = styled(Button)`
  margin-left: auto;
  align-self: center;
  background-color: ${colors.grey5};
  width: 2rem;
  height: 2rem;
  border-radius: 999rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  border: none;
  padding: 0;
  position: relative;

  &:hover {
    background-color: ${colors.grey10};
  }

  transition: all 100ms ease-in-out;
`;

function IconButton({ icon, children, tooltip, ...props }) {
  return (
    <StyledButton isOutline {...props}>
      <Icon icon={icon} />
      {tooltip && <InlineTooltip message={tooltip} delay={500} />}
      {children}
    </StyledButton>
  );
}

export default IconButton;
