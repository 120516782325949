import { Stack, Form, ActionButton, FormMessage, Page } from '~/components';
import { Formik } from 'formik';
import { SectionTitle } from '~/components/Form';
import React, { useState } from 'react';
import styled from 'styled-components';
import { getFileBytes, mergeValues } from '~/utils';
import { colors } from '~/styles';
import { useApi, useWorkspace, useSession } from '~/contexts';
import { useForm } from '~/hooks';
import { SelectImportTypeSection } from './components/SelectImportTypeSection';
import { ImportResults } from './components/ImportResults';
import { InstructionsSection } from './components/InstructionsSection';
import MainImportSection from './components/MainImportSection';
import rules from './rules/index.js';

const SettingsPage = styled.div`
  ${SectionTitle} {
    min-width: 17rem;
    flex: 0.6;
  }

  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ControlWithText = styled(Form.Control)`
  align-items: center;
  justify-content: left;

  span {
    padding-right: 0;
    flex: 0 1 auto;
  }
`;

const ControlBox = styled.div`
  && {
    background-color: ${colors.grey5};
    border-radius: 4px;
    padding: 0.8rem 1.5rem;
    margin: 1rem 0;
  }
`;

export const ExampleBox = styled.div`
  && {
    font-size: 0.875rem;
    background-color: ${colors.white};
    border-radius: 4px;
  }
  table {
    margin: 1rem 0;
  }
`;

export const ImportResultsContainer = styled.div`
  font-size: 0.875rem;
  background-color: ${colors.white};

  hr {
    margin: 1rem 0;
  }
`;

const SaveButton = styled(ActionButton)`
  width: 8.25rem;
`;

export default function CSVImportPage() {
  const [data, setData] = useState(null);
  const [result, setResult] = useState(null);
  const [formModel, setFormModel] = useState({});
  const api = useApi();
  const { workspace } = useWorkspace();
  const { isAdmin } = useSession();
  const [{ status, message, saved, isSubmitting }, form] = useForm();

  const initialValues = mergeValues({ selectedDataType: null }, formModel);

  async function handleSubmit(values) {
    try {
      form.submit();
      const bytes =
        values.csvFile !== null && values.csvFile.target.files[0]
          ? await getFileBytes(values.csvFile.target.files[0])
          : null;

      setResult(
        (await api.www.workspaces(workspace.id).integrations().importCSV.send(bytes, values.selectedDataType)).data,
      );
      form.save();
    } catch (error) {
      form.error(error);
    }
  }

  return (
    <Page>
      <SettingsPage>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validateOnBlur={false} validateOnChange={false}>
          {(formik) => {
            const onFileChanged = (content) => {
              formik.setFieldValue('csvFile', content);
              formik.setStatus(null); // Reset Formik status
              formik.setSubmitting(false); // Reset isSubmitting
              form.done(); // Reset custom form status
              setData(content);
              setResult(null);
            };

            async function handleTypeSelected(evt) {
              const value = evt.target.value;
              formik.setFieldValue('selectedDataType', value);
              setFormModel({ selectedDataType: value });
              setResult(null);
            }

            const skipped = result?.importResults?.skipped;

            const selectedCSVRules = rules.csvRules[formModel.selectedDataType];
            return (
              <Stack>
                {SelectImportTypeSection(isAdmin, handleTypeSelected)}

                {formModel.selectedDataType ? (
                  selectedCSVRules ? (
                    <MainImportSection
                      onFileChanged={onFileChanged}
                      isSubmitting={isSubmitting}
                      saved={saved}
                      formik={formik}
                      result={result?.importResults}
                      status={status}
                      message={message}
                      dataType={formModel.selectedDataType}
                    />
                  ) : (
                    DefaultDataImportSection({
                      formModel,
                      onFileChanged,
                      data,
                      isSubmitting,
                      saved,
                      formik,
                      result,
                      skipped,
                      status,
                      message,
                    })
                  )
                ) : null}
              </Stack>
            );
          }}
        </Formik>
      </SettingsPage>
    </Page>
  );
}

// older version of import section, will be removed when no longer in use
function DefaultDataImportSection({
  formModel,
  onFileChanged,
  data,
  isSubmitting,
  saved,
  formik,
  result,
  status,
  message,
}) {
  return (
    <>
      <Form.Section
        title="Step 2: Provide a CSV file"
        subtitle="A comma-separated values (CSV) file is a delimited text file that uses a comma to separate values, and is commonly edited in Microsoft Excel. Each line of the file is a data record. Each record consists of one or more fields, separated by commas. Utilize the sample file to insure your data is accurately read.">
        {InstructionsSection(formModel)}
        <>
          <ControlBox>
            <input name="csvFile" type="file" accept=".csv, text/csv" onChange={onFileChanged}></input>
          </ControlBox>
          {data && (
            <Form.Control>
              <Form.Control></Form.Control>
              <SaveButton isLoading={isSubmitting} ok={saved} onClick={formik.submitForm}>
                Submit for Import
              </SaveButton>
            </Form.Control>
          )}
        </>
        {result && <ImportResults result={result?.importResults}></ImportResults>}

        {status && (
          <FormMessage.Error>
            {message} The file you provided could not be imported. Please use the sample file as a template to ensure
            your CSV is properly formatted.
          </FormMessage.Error>
        )}
      </Form.Section>
    </>
  );
}
