import { Page, Tab, Tabs } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useActions, useAuth, useDocumentTitle, useFeatures } from '~/hooks';
import React, { useCallback, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { ErrorPage, PageLoader } from '~/routes/public/pages';
import ProjectCreateForm from '../projects/ProjectCreateForm';
import { ClientDrawer, ClientHistoryDrawer } from './client-drawer';
import ClientContactsTab from './ClientContactsTab';
import ClientDetailsPage from './ClientDetailsPage';
import ClientHeader from './ClientHeader';
import ClientInvoicesTab from './ClientInvoicesTab';
import ClientProjectsTab from './ClientProjectsTab';
import { ClientFilesTab } from './files';
import CompanyOpportunitiesTab from './ClientOpportunitiesTab';
import OpportunityCreateForm from '../pipeline/opportunities/OpportunityCreateForm';
import PurchaseOrdersTab from './purchase-orders-tab/PurchaseOrdersTab';

const initialState = { isReady: false, client: null };
const handlers = {
  ready: ({ client }, state) => ({
    isReady: true,
    client: state.client ? { ...state.client, ...client } : client,
  }),
};

function ClientArea({ children }) {
  const features = useFeatures();

  const [{ isReady, client }, actions] = useActions(handlers, initialState);

  const documentTitle = useDocumentTitle(client?.name);

  const api = useApi();
  const { workspace } = useWorkspace();
  const auth = useAuth();

  const { clientId } = useParams();
  const { url, path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const fetchData = useCallback(async () => {
    try {
      const { data: client } = await api.www.workspaces(workspace.id).clients(clientId).get();
      actions.ready({ client });
    } catch (error) {
      actions.ready({ client: null });
    }
  }, [actions, workspace.id, clientId, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSaved = (client) => {
    actions.ready({ client });
  };

  if (!isReady) return <PageLoader />;
  if (!client) return <ErrorPage.NotFound publicSite={false} />;

  return (
    <Page scrollable>
      <ClientHeader client={client} />

      <Page.Tabs>
        <Tabs>
          <Tab to={`${url}/overview`}>Overview</Tab>
          <Tab to={`${url}/projects`}>Projects</Tab>
          {features.pipeline && client.company && auth.pipeline.view && (
            <Tab to={`${url}/opportunities`}>Opportunities</Tab>
          )}
          {!client.isInternal && client.permissions.viewPublishedInvoices && <Tab to={`${url}/invoices`}>Invoices</Tab>}
          {auth.purchaseOrders.manage && <Tab to={`${url}/purchase-orders`}>Purchase Orders</Tab>}
          <Tab to={`${url}/contacts`}>Contacts</Tab>
          {features.clientAndProjectFiles && <Tab to={`${url}/files`}>Files</Tab>}
        </Tabs>
      </Page.Tabs>

      {children || (
        <Switch>
          <Route path={`${path}/overview`}>
            <ClientDetailsPage client={client} />
          </Route>

          <Route
            path={[`${path}/projects`, `${path}/projects/new`, `${path}/projects/edit`, `${path}/projects/history`]}
            exact>
            <ClientProjectsTab client={client} />
          </Route>

          {features.pipeline && client.company && (
            <Route path={`${path}/opportunities`}>
              {auth.pipeline.view ? (
                <CompanyOpportunitiesTab companyId={client.company.id} />
              ) : (
                <ErrorPage.Forbidden publicSite={false} />
              )}
            </Route>
          )}

          <Route path={`${path}/invoices`}>
            {client.permissions.viewPublishedInvoices ? (
              <ClientInvoicesTab client={client} />
            ) : (
              <ErrorPage.Forbidden publicSite={false} />
            )}
          </Route>

          {features.purchaseOrders && (
            <Route
              path={[
                `${path}/purchase-orders`,
                `${path}/purchase-orders/:action`,
                `${path}/purchase-orders/:purchaseOrderId/:action`,
              ]}
              exact>
              {client.permissions.manageDraftInvoices ? (
                <PurchaseOrdersTab client={client} />
              ) : (
                <ErrorPage.Forbidden publicSite={false} />
              )}
            </Route>
          )}

          <Route path={`${path}/contacts/:action`} exact>
            {client.permissions.edit ? (
              <ClientContactsTab client={client} />
            ) : (
              <ErrorPage.Forbidden publicSite={false} />
            )}
          </Route>

          <Route path={[`${path}/contacts`, `${path}/contacts/:contactId/:action`]} exact>
            <ClientContactsTab client={client} />
          </Route>

          {features.clientAndProjectFiles && (
            <Route path={`${path}/files`}>
              <ClientFilesTab client={client} />
            </Route>
          )}

          <Redirect path="/" to={`${url}/overview`} />
        </Switch>
      )}

      <Route path={`${path}/projects/new`}>
        {auth.projects.create ? (
          <ProjectCreateForm
            client={client}
            onSaved={(project) =>
              history.push(`/app/${workspace.key}/projects/${client.key}/${project.key}/overview/edit`)
            }
            onClose={() => history.push({ pathname: `${url}/projects`, search: location.search })}
          />
        ) : (
          <Redirect to={`/app/${workspace.key}/clients/${clientId}/projects`} />
        )}
      </Route>

      {features.pipeline && client.company && (
        <Route path={`${path}/opportunities/new`}>
          {auth.pipeline.manage ? (
            <OpportunityCreateForm
              onSaved={(opportunity) =>
                history.push(`/app/${workspace.key}/pipeline/opportunities/${opportunity.id}/overview/edit`)
              }
              onClose={() => history.push({ pathname: `${url}/opportunities`, search: location.search })}
              company={client.company}
            />
          ) : (
            <Redirect to={`/app/${workspace.key}/clients/${clientId}/opportunities`} />
          )}
        </Route>
      )}

      <Route path={`${path}/:clientTab/edit`}>
        {client.permissions.edit ? (
          <ClientDrawer
            onSaved={handleSaved}
            onClose={(clientTab) => {
              history.push({ pathname: `${url}/${clientTab}`, search: location.search });
              documentTitle.set(client.name);
            }}
          />
        ) : (
          <Redirect to={`/app/${workspace.key}/clients/${clientId}`} />
        )}
      </Route>

      <Route path={`${path}/:clientTab/history`}>
        {client.permissions.edit ? (
          <ClientHistoryDrawer
            clientId={client.id}
            onClose={(clientTab) => {
              history.push({ pathname: `${url}/${clientTab}`, search: location.search });
              documentTitle.set(client.name);
            }}
          />
        ) : (
          <Redirect to={`/app/${workspace.key}/clients/${clientId}`} />
        )}
      </Route>
    </Page>
  );
}

export default ClientArea;
