import {
  ActionButton,
  Buttons,
  CancelButton,
  DeleteButton,
  Drawer,
  FormMessage,
  Icon,
  Stack,
  Tab,
  Tabs,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useActions, useDirtyCheck, useDocumentTitle, useFeatures, useForm } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { ErrorPage } from '~/routes/public/pages';
import styled from 'styled-components';
import { colors } from '~/styles';
import { emptyStringToNull, mergeValues } from '~/utils';
import { slugValidator } from '~/utils/validators';
import * as Yup from 'yup';
import ClientDeleteConfirmation from '../ClientDeleteConfirmation';
import ClientForm from './ClientForm';
import RolesTab from './RolesTab';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 1.625rem;
  margin-bottom: 1.625rem;
`;

const ClientDrawerContext = React.createContext({ forms: [] });

const initialState = { client: null, isReady: false };
const handlers = {
  ready: ({ client }) => ({ isReady: true, client }),
};

function ClientDrawer({ onSaved, onDeleted, onClose }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ isReady, client }, actions] = useActions(handlers, initialState);
  const [{ status, message, isSubmitting, saved }, form] = useForm();

  const { clientId, clientTab } = useParams();
  const location = useLocation();
  const history = useHistory();
  const features = useFeatures();

  const confirmation = useConfirmation();

  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);

  useDocumentTitle('Edit Client');

  const fetchData = useCallback(async () => {
    try {
      const { data: client } = await api.www.workspaces(workspace.id).clients(clientId).get();

      actions.ready({ client });
    } catch (error) {
      actions.ready({ client: null });
    }
  }, [actions, workspace.id, clientId, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [tabIndex, setTabIndex] = useState(0);
  const [forms, setForms] = useState([]);

  function handleClose() {
    if (onClose) {
      onClose(clientTab);
    }
  }

  const handleDelete = () => {
    confirmation.prompt((resolve) => (
      <ClientDeleteConfirmation
        client={client}
        onClose={resolve}
        onDelete={() => {
          history.push(`/app/${workspace.key}/clients`);
          if (onDeleted) onDeleted(client.id);
          resolve(true);
        }}
      />
    ));
  };

  if (!isReady) return null;
  if (!client) return <ErrorPage.NotFound publicSite={false} />;

  // TODO: the logic to authorize opening the form should ideally be located on the parent.
  // For that, it may be ncessary to make this a "presentational" component that receives the client object
  // after it's been fetched (instead of fetching based on params).
  if (clientId) {
    if (!client.permissions.edit) {
      const url = location.pathname.includes('/clients/edit')
        ? `/app/${workspace.key}/clients`
        : location.pathname.replace('/edit', '');

      return <Redirect to={url} />;
    }
  }

  const initialValues = mergeValues(
    {
      businessUnit: null,
      code: '',
      company: null,
      cloudFolderUrl: '',
      email: '',
      emails: [],
      ccEmail: '',
      ccEmails: [],
      currency: null,
      industry: null,
      invoiceSubject: '',
      invoiceNotes: '',
      invoiceEmailSubject: '',
      invoiceEmailBody: '',
      invoiceTaxableItems: [],
      invoiceTaxRate: null,
      locale: null,
      location: null,
      name: '',
      key: '',
      notes: '',
      owner: null,
      practice: null,
      paymentTermsId: '',
      roles: [],
      salesRepresentative: null,
      streetAddress: '',
      tags: [],
      invoiceDetailsSource: 'workspace',
    },
    {
      ...client,
      email: client.emails ? client.emails.join(', ') : '',
      ccEmail: client.ccEmails ? client.ccEmails.join(', ') : '',
    },
  );

  switch (client.invoiceDetailsSource) {
    case 'workspace':
      initialValues.invoiceSubject = workspace.invoiceSubject ?? '';
      initialValues.invoiceNotes = workspace.invoiceNotes ?? '';
      initialValues.invoiceEmailSubject = workspace.invoiceEmailSubject ?? '';
      initialValues.invoiceEmailBody = workspace.invoiceEmailBody ?? '';
      initialValues.paymentTermsId = workspace.paymentTermsId ?? '';
      initialValues.invoiceTaxableItems = workspace.invoiceTaxableItems;
      initialValues.invoiceTaxRate = workspace.invoiceTaxRate;
      break;

    case 'business_unit':
      if (features.businessUnits && client.businessUnitId) {
        initialValues.invoiceSubject = client.businessUnit.invoiceSubject ?? '';
        initialValues.invoiceNotes = client.businessUnit.invoiceNotes ?? '';
        initialValues.invoiceEmailSubject = client.businessUnit.invoiceEmailSubject ?? '';
        initialValues.invoiceEmailBody = client.businessUnit.invoiceEmailBody ?? '';
        initialValues.paymentTermsId = client.businessUnit.paymentTermsId ?? '';
        initialValues.invoiceTaxableItems = client.businessUnit.invoiceTaxableItems;
        initialValues.invoiceTaxRate = client.businessUnit.invoiceTaxRate;
      }
      break;
  }

  const tabErrors = {
    overview: [
      'billingTypeId',
      'client',
      'code',
      'completedOn',
      'description',
      'end',
      'isBillable',
      'isExpensesApprovalRequired',
      'isTimeApprovalRequired',
      'name',
      'poNumber',
      'projectTypeId',
      'start',
      'statusId',
    ],
  };

  return (
    <ClientDrawerContext.Provider value={{ forms, setForms }}>
      <Drawer
        isOpen
        title="Edit Client"
        byline={client.name}
        onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
        onClose={handleClose}
        width="70rem">
        {(closeDrawer) => {
          const handleCloseClick = () => dirtyCheck(() => closeDrawer());

          async function handleSubmit(values) {
            try {
              form.submit(formRef.current?.status?.action);

              const body = emptyStringToNull({
                ..._.omit(values, [
                  'businessUnit',
                  'company',
                  'email',
                  'ccEmail',
                  'industry',
                  'invoiceTaxRate',
                  'location',
                  'owner',
                  'practice',
                  'tags',
                  'salesRepresentative',
                ]),
                businessUnitId: values.businessUnit?.id ?? null,
                companyId: values.company?.id ?? null,
                industryId: values.industry?.id ?? null,
                invoiceTaxRateId: values.invoiceTaxRate?.id ?? null,
                locationId: values.location?.id ?? null,
                ownerId: values.owner?.id ?? null,
                practiceId: values.practice?.id ?? null,
                salesRepresentativeId: values.salesRepresentative?.id ?? null,
                clientTagAssignments: values.tags.map((tag) => ({ clientTagId: tag.id })),
                roles: values.roles.map((cr) =>
                  _.pick(
                    cr,
                    'currency',
                    'id',
                    'isBillable',
                    'name',
                    'rate',
                    'disciplineId',
                    'practiceId',
                    'locationId',
                    'workspaceRoleId',
                  ),
                ),
              });

              const { data } = await api.www.workspaces(workspace.id).clients(client?.id).upsert(body);

              await onSaved(data);

              // If the client key changes, update the URL
              if (data.key !== clientId) {
                const newUrl = location.pathname.replace(`/clients/${clientId}`, `/clients/${data.key}`);
                history.replace(newUrl);
              }

              if (formRef.current.status.action === 'close') {
                closeDrawer();
              } else {
                const { data } = await api.www.workspaces(workspace.id).clients(client.id).get();
                actions.ready({ client: data });
                form.save(formRef.current.status.action);
              }
            } catch ({ message }) {
              form.error({ message });
            }
          }

          return (
            <Formik
              innerRef={formRef}
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={Yup.object().shape({
                code: Yup.string().label('Client Code').max(255),
                company: Yup.mixed().label('Company'),
                cloudFolderUrl: Yup.string()
                  .label('Cloud Folder')
                  .url('Please include http:// or https:// protocols in your URL')
                  .max(1024),
                emails: Yup.array().of(Yup.string().email().max(255).required()).label('To Email Address').ensure(),
                ccEmails: Yup.array().of(Yup.string().email().max(255).required()).label('Cc Email Address').ensure(),
                invoiceSubject: Yup.string().label('Invoice Subject').max(255),
                invoiceNotes: Yup.string().label('Invoice Note').max(5000),
                invoiceEmailSubject: Yup.string().label('Invoice Email Subject').max(255),
                invoiceEmailBody: Yup.string().label('Invoice Email Body').max(5000),
                name: Yup.string().label('Name').max(255).required(),
                key: Yup.string()
                  .label('Client URL ID')
                  .matches(slugValidator.expression, { message: slugValidator.message })
                  .max(255)
                  .required(),
                notes: Yup.string().label('Notes').max(5000),
                owner: Yup.object().label('Owner').nullable(),
                paymentTermsId: Yup.string().label('Payment Terms').required(),
                streetAddress: Yup.string().label('Invoice Street Address').max(5000),
                salesRepresentative: Yup.object().label('Sales Representative').nullable(),
              })}>
              {(formik) => {
                const { errors, setStatus, submitForm, setFieldValue } = formik;

                const submit = async (action) => {
                  // Submit all open inline forms
                  for await (const form of forms) {
                    await form.ref.current.submitForm();
                  }

                  // If at least one form is invalid, don't submit the drawer
                  for await (const form of forms) {
                    if (form.ref.current && !form.ref.current.isValid) return;
                  }

                  setStatus({ action });
                  submitForm();
                };

                return (
                  <Stack>
                    {status && <FormMessage.Error>{message}</FormMessage.Error>}

                    <Tabs selectedIndex={tabIndex} onChange={(index) => setTabIndex(index)}>
                      <Tab>
                        Overview
                        {tabErrors.overview.some((key) => !!errors[key]) && (
                          <Icon icon="exclamation-circle" color={colors.danger} spaceLeft />
                        )}
                      </Tab>

                      {!client.isInternal && client.permissions.manageRoles && features.workspaceRoles && (
                        <Tab>Roles</Tab>
                      )}
                    </Tabs>

                    <Content>
                      {
                        [
                          <ClientForm key="overview" client={client} formik={formik} />,
                          <RolesTab
                            key="roles"
                            clientModel={formik.values}
                            onChange={(value) => setFieldValue('roles', value)}
                          />,
                        ][tabIndex]
                      }
                    </Content>

                    <Drawer.Actions>
                      {client.id && !client.isInternal && client.permissions.delete && (
                        <DeleteButton onClick={handleDelete}>Delete</DeleteButton>
                      )}

                      <Buttons align="right">
                        <CancelButton onClick={handleCloseClick}>Close</CancelButton>

                        <ActionButton
                          isOutline
                          disabled={!!isSubmitting}
                          isLoading={isSubmitting === 'stay'}
                          ok={saved === 'stay'}
                          onClick={() => submit('stay')}>
                          Save
                        </ActionButton>

                        <ActionButton
                          disabled={!!isSubmitting}
                          isLoading={isSubmitting === 'close'}
                          ok={saved === 'close'}
                          onClick={() => submit('close')}>
                          Save &amp; Close
                        </ActionButton>
                      </Buttons>
                    </Drawer.Actions>
                  </Stack>
                );
              }}
            </Formik>
          );
        }}
      </Drawer>
    </ClientDrawerContext.Provider>
  );
}

export { ClientDrawerContext };
export default ClientDrawer;
