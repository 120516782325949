import { useAuth } from '~/hooks';
import AccountsReceivableAging from './AccountsReceivableAging';
import ClientPurchaseOrders from './ClientPurchaseOrders';
import ClientStatement from './ClientStatement';
import CreditNoteDetail from './CreditNoteDetail';
import CreditNotes from './CreditNotes';
import FixedFeeRevenue from './FixedFeeRevenue';
import HourlyFees from './HourlyFees';
import InvoiceDetail from './InvoiceDetail';
import Invoices from './Invoices';
import InvoicesByClient from './InvoicesByClient';
import Payments from './Payments';
import ScheduledItems from './ScheduledItems';
import TransactionSummaryByInvoiceItem from './TransactionSummaryByInvoiceItem';
import UninvoicedRevenue from './UninvoicedRevenue';

export default function useAccountingReports() {
  const auth = useAuth();

  return [
    auth.payments.view && {
      key: 'accounts_receivable_aging',
      name: 'Accounts Receivable Aging',
      description:
        'Receivables by client grouped by current, 1-30 days late, 31-60 days late, 61-90 days late, and over 90 days late.',
      path: '/accounts-receivable-aging',
      component: AccountsReceivableAging,
    },
    auth.invoices.view.client && {
      key: 'client_statement',
      name: 'Client Statement',
      description: 'View invoices, credit notes, payments and balance for a client.',
      path: '/client-statement',
      component: ClientStatement,
    },
    auth.purchaseOrders.manage && {
      key: 'client_purchase_orders',
      name: 'Client Purchase Orders',
      description:
        "Purchase orders including each purchase order's client, number, amount, amount invoiced and amount available.",
      path: '/client-purchase-orders',
      component: ClientPurchaseOrders,
    },
    auth.creditNotes.view && {
      key: 'credit_notes',
      name: 'Credit Notes',
      description:
        "Credit notes including each credit note's number, client, projects, issued date, status, balance and total amount.",
      path: '/credit-notes',
      component: CreditNotes,
    },
    auth.creditNotes.view && {
      key: 'credit_note_detail',
      name: 'Credit Note Detail',
      description: 'Credit notes including the credit note summary information and each of the credit note line items.',
      path: '/credit-note-detail',
      component: CreditNoteDetail,
    },
    auth.reports.fixedFeeRevenue && {
      key: 'fixed_fee_revenue',
      name: 'Fixed Fee Services Revenue by Client and Project',
      description:
        'A summary of fixed fee services revenue, including recognized, unrecognized, invoiced and uninvoiced revenue, grouped by client and project.',
      path: '/fixed-fee-revenue',
      component: FixedFeeRevenue,
    },
    auth.reports.hourlyFees && {
      key: 'hourly_revenue_by_client_and_project',
      name: 'Hourly Revenue by Client and Project',
      description: 'A summary of hourly revenue earned by project role or member, grouped by client and project.',
      path: '/hourly-revenue-by-client-and-project',
      component: HourlyFees,
    },
    auth.invoices.view.project && {
      key: 'invoices',
      name: 'Invoices',
      description:
        "Invoices including each invoice's number, client, projects, issued date, due date, status, balance and total amount.",
      path: '/invoices',
      component: Invoices,
    },
    auth.invoices.view.project && {
      key: 'invoices_by_client',
      name: 'Invoices by Client',
      description:
        "Invoices grouped by client including each invoice's number, projects, issued date, due date, status, balance and total amount.",
      path: '/invoices-by-client',
      component: InvoicesByClient,
    },
    auth.invoices.view.project && {
      key: 'invoice_detail',
      name: 'Invoice Detail',
      description: 'Invoices including the invoice summary information and each of the invoice line items.',
      path: '/invoice-detail',
      component: InvoiceDetail,
    },
    auth.invoices.view.project && {
      key: 'payments',
      name: 'Payments',
      description:
        "Payments including each payment's date, client, related invoices, reference number and total amount.",
      path: '/payments',
      component: Payments,
    },
    auth.invoices.view.project && {
      key: 'transaction_summary_by_invoice_item',
      name: 'Transaction Summary by Invoice Item',
      description: 'A summary of all transactions grouped by invoice item including the item name, type and amount.',
      path: '/transaction-summary-by-invoice-item',
      component: TransactionSummaryByInvoiceItem,
    },
    auth.projects.viewRevenue && {
      key: 'scheduled_items',
      name: 'Scheduled Items',
      description: 'A list of scheduled items including fixed fee milestones, other items and project expense items.',
      path: '/scheduled-items',
      component: ScheduledItems,
    },
    auth.reports.uninvoicedRevenue && {
      key: 'uninvoiced_revenue',
      name: 'Uninvoiced Revenue',
      description:
        'A list of time entries, expense report items, fixed fee milestones, project expenses, and other items that have not been invoiced.',
      path: '/uninvoiced-revenue',
      component: UninvoicedRevenue,
    },
  ].filter(Boolean);
}
