import _ from 'lodash';
import { darken } from 'polished';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import styled, { css } from 'styled-components';
import { colors } from '~/styles';
import Button from '../Button';
import Icon from '../Icon';
import MultiSelect from '../MultiSelect';
import Tooltip from '../Tooltip';

const Text = styled.div`
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 0 0 0.875rem;
  font-size: 0.75rem;
  color: ${colors.grey75};
`;

const TextTooltip = styled(Tooltip)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Placeholder = styled.span`
  color: ${colors.grey40};
`;

const Box = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 1px solid ${colors.grey10};
  border-radius: 0.3125rem;
  user-select: none;

  ${({ $hasValue }) =>
    $hasValue &&
    css`
      border-color: ${colors.grey40};
    `}
`;

const Control = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 2rem;
  outline: none;
  box-shadow: none;
  cursor: pointer;

  &:focus {
    ${Box} {
      border: 1px solid ${colors.primary};
      border-radius: 0.3125rem;
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${Box} {
        border: 1px solid ${colors.primary};
        border-radius: 0.3125rem;
      }
    `}
`;

const Status = styled.div`
  font-size: 0.75rem;
  display: flex;
  gap: 0.125rem;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: 0.125rem;
  margin-right: 0.25rem;

  .icon {
    width: 1rem;
  }
`;

const ClearIndicator = styled(Button)`
  height: 100%;
  color: ${colors.primary};

  &:hover {
    color: ${darken(0.1, colors.primary)};
  }
`;

const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.grey55};
  font-size: 0.75rem;
  font-weight: bold;
  height: 100%;
`;

const MaterialPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0.625rem; /* input padding - placeholder padding */
  margin-left: 1px; /* offsets the 1px input border */
  padding: 0 0.25rem;
  color: ${colors.grey40};
  font-size: 0.75rem;
  background-color: ${colors.white};
  border-radius: 0.3125rem;
  transform: translateY(-50%);
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  transition: opacity 100ms;
  pointer-events: none;
`;

export default function MultiSelectFilter({
  placeholder,
  materialPlaceholder = true,
  materialAlwaysVisible = false,
  name,
  renderValue,
  defaultIsOpen = false,
  isLoading,
  tabIndex = '0',
  icon = 'angle-down',
  onChange,
  ...props
}) {
  const value = props.value;

  const [values, setValues] = useState(() => value ?? []);

  const handleChange = (values) => {
    setValues(values);
  };

  const text = useMemo(() => {
    if (!(value?.length > 0)) return null;
    if (renderValue) return renderValue(value);
    return null;
  }, [value, renderValue]);

  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const handleOpen = (flag) => {
    setIsOpen(flag);
    if (flag && _.isFunction(props.onSearch)) props.onSearch();
  };
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    strategy: 'absolute',
    modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
  });

  const handleApply = useCallback(
    (value) => {
      setValues(value);
      onChange({ name, value, target: { name, value } });
      setIsOpen(false);
    },
    [name, onChange],
  );

  const handleClear = (event) => {
    event.stopPropagation();
    event.preventDefault();
    handleApply([]);
    setIsOpen(false);
  };

  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendant elements
      if (!isOpen || referenceElement?.contains(event.target) || popperElement?.contains(event.target)) {
        return;
      }

      if (_.isEqual(value, values)) {
        setIsOpen(false);
      } else {
        handleApply(values);
      }
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [referenceElement, popperElement, handleApply, values, isOpen, value]);

  const materialPlaceholderValue = useMemo(() => {
    if (!materialPlaceholder) {
      return '';
    } else if (_.isString(materialPlaceholder)) {
      return materialPlaceholder;
    } else if (_.isString(placeholder) && !!placeholder) {
      return placeholder;
    }
    return '';
  }, [placeholder, materialPlaceholder]);

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'Escape':
      case 'Tab':
        setIsOpen(false);
        break;

      case 'Enter':
      case ' ':
        handleOpen(!isOpen);
        break;
    }
  };

  const handleCancel = () => {
    setValues(value);
    setIsOpen(false);
  };

  const hasValue = value?.length > 0;

  return (
    <Control
      ref={setReferenceElement}
      isOpen={isOpen}
      tabIndex={tabIndex}
      onClick={() => handleOpen(!isOpen)}
      onKeyDown={handleKeyDown}>
      <Box $hasValue={hasValue}>
        <Text>
          {text ? (
            <TextTooltip message={text} delay={500}>
              {text}
            </TextTooltip>
          ) : (
            <Placeholder>{placeholder}</Placeholder>
          )}
        </Text>

        <Status>
          {hasValue && (
            <Tooltip style={{ height: '100%' }} message={text} delay={500}>
              <Count data-testid={value.length}>{value.length}</Count>
            </Tooltip>
          )}

          {isOpen && isLoading ? (
            <Icon icon="spinner" spin color={colors.primary} />
          ) : hasValue ? (
            <ClearIndicator onMouseDown={(e) => e.stopPropagation()} isAnchor tabIndex={-1} onClick={handleClear}>
              <Icon icon="times" />
            </ClearIndicator>
          ) : null}

          <Icon icon={icon} color={colors.grey20} />
        </Status>
      </Box>

      {!!materialPlaceholderValue && (
        <MaterialPlaceholder isVisible={materialAlwaysVisible || !!text}>
          {materialPlaceholderValue}
        </MaterialPlaceholder>
      )}

      {isOpen && !isLoading && (
        <div
          ref={setPopperElement}
          style={{
            ...styles.popper,
            minWidth: Math.max(referenceElement?.scrollWidth, 300),
            maxWidth: '100%',
            zIndex: 110,
          }}
          onClick={(e) => e.stopPropagation()}
          {...attributes.popper}>
          <MultiSelect
            values={values}
            onChange={handleChange}
            onApply={handleApply}
            onCancel={handleCancel}
            {...props}
          />
        </div>
      )}
    </Control>
  );
}
