import styled from 'styled-components';
import React from 'react';
import { colors, weights } from '~/styles';

const FeatureStatus = styled.span`
  padding: 0.125rem 0.5rem;
  background-color: ${colors.white};
  color: ${({ color }) => color ?? colors.red};
  font-weight: ${weights.bold};
`;

const FeatureTable = styled.table``;

const featureLabels = {
  allocations: 'Allocations',
  approvalWorkflow: 'Approval Workflow',
  businessUnits: 'Business Units',
  timesheets: 'Timesheets',
  utilization: 'Utilization',
  workspaceRoles: 'Workspace Roles',
  clientApprovals: 'Client Approvals',
  forecast: 'Forecast',
  manualRevenueRecognition: 'Advanced Revenue Recognition',
  practices: 'Practices',
  timeAttachments: 'Time Attachments',
  multicurrency: 'Multicurrency',
  expenseMarkup: 'Expense Markup',
  expenseReporting: 'Expense Reporting',
  disciplines: 'Disciplines',
  projectHealth: 'Project Health',
  pipeline: 'Pipeline',
  clientAndProjectFiles: 'Client and Project Files',
  salesforceBeta: 'Salesforce Beta',
};

const TableHeader = styled.thead`
  font-weight: bold;
  color: ${colors.grey25};
`;

const TableHeaderCell = styled.th`
  padding: 0 0.5rem;
  &:first-child {
    padding: 0 0rem;
  }
`;

const TableCell = styled.td`
  text-align: right;
`;

const FeaturesList = ({ features }) => {
  return (
    <FeatureTable>
      <TableHeader>
        <tr>
          <TableHeaderCell>Name</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
          <TableHeaderCell>Weight</TableHeaderCell>
          <TableHeaderCell>Score</TableHeaderCell>
        </tr>
      </TableHeader>
      <tbody>
        {Object.entries(features).map(([featureKey, featureData]) => {
          const { connected, weight, usageScore } = featureData;
          return (
            <tr key={featureKey}>
              <TableCell>{featureLabels[featureKey]}</TableCell>
              <TableCell>
                <FeatureStatus color={connected ? colors.success100 : colors.danger}>
                  {connected ? 'On' : 'Off'}
                </FeatureStatus>
              </TableCell>
              <TableCell>{weight}</TableCell>
              <TableCell>{usageScore}</TableCell>
            </tr>
          );
        })}
      </tbody>
    </FeatureTable>
  );
};

// Usage:
// <FeaturesList details={...} />

export default FeaturesList;
