import React, { useMemo } from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { useWorkspace } from '~/contexts';
import { FavoriteReportsProvider } from './components/FavoriteReportsContext';
import { SavedReportsProvider } from './components/SavedReportsContext';
import useExpenseReports from './expenses/useExpenseReports';
import useAccountingReports from './financial/useAccountingReports';
import usePerformanceReports from './financial/usePerformanceReports';
import useForecastReports from './forecast/useForecastReports';
import usePlanReports from './forecast/usePlanReports';
import useVarianceReports from './forecast/useVarianceReports';
import usePipelineReports from './pipeline/usePipelineReports';
import ReportsPage from './ReportsPage';
import SavedReportPage from './SavedReportPage';
import SavedReportsPage from './SavedReportsPage';
import useTimeReports from './time/useTimeReports';
import useUtilizationReports from './utilization/useUtilizationReports';
import useWorkspaceReports from './workspace/useWorkspaceReports';

function ReportsArea() {
  const { path, url } = useRouteMatch();
  const { search } = useLocation();

  const timeReports = useTimeReports();
  const expenseReports = useExpenseReports();
  const utilizationReports = useUtilizationReports();
  const planReports = usePlanReports();
  const forecastReports = useForecastReports();
  const varianceReports = useVarianceReports();
  const accountingReports = useAccountingReports();
  const performanceReports = usePerformanceReports();
  const pipelineReports = usePipelineReports();
  const workspaceReports = useWorkspaceReports();

  const reports = [
    ...timeReports,
    ...expenseReports,
    ...utilizationReports,
    ...planReports,
    ...forecastReports,
    ...varianceReports,
    ...accountingReports,
    ...performanceReports,
    ...pipelineReports,
    ...workspaceReports,
  ];

  const legacyRoutesRedirect = useMemo(
    () => [
      ['/forecast', '/library'],
      ['/forecast/hours-forecast-by-project-and-resource', '/library/hours-plan-by-project-and-resource'],
      ['/forecast/hours-forecast-by-resource-and-project', '/library/hours-plan-by-resource-and-project'],
      ['/forecast/performance-forecast-by-project', '/library/performance-plan-by-project'],
      [
        '/forecast/services-revenue-forecast-by-client-and-project',
        '/library/services-revenue-plan-by-client-and-project',
      ],
      ['/forecast/utilization-forecast-by-member', '/library/utilization-plan-by-member'],
      ['/forecast/utilization-forecast-by-member-practice', '/library/utilization-plan-by-member-practice'],
      ['/variance', '/library'],
      ['/variance/hours-variance-by-member-and-project', '/library/hours-variance-by-member-and-project'],
      ['/variance/hours-variance-by-project-and-resource', '/library/hours-variance-by-project-and-resource'],
      ['/variance/hours-variance-by-project-and-role', '/library/hours-variance-by-project-and-role'],
      ['/time/time-detail', '/library/time-entries'],
      ['/expenses/expense-detail', '/library/expense-items'],
      ['/workspace/members-list', '/library/members'],
      ['/workspace/projects-list', '/library/projects'],
      ['/workspace/clients-list', '/library/clients'],
      ['/workspace/project-tasks-list', '/library/project-tasks'],

      ...timeReports
        .map((report) => [
          [`/time${report.path}`, report.path],
          [report.path, `/library${report.path}`],
        ])
        .flat(),
      ...expenseReports
        .map((report) => [
          [`/expenses${report.path}`, report.path],
          [report.path, `/library${report.path}`],
        ])
        .flat(),
      ...utilizationReports
        .map((report) => [
          [`/utilization${report.path}`, report.path],
          [report.path, `/library${report.path}`],
        ])
        .flat(),
      ...planReports
        .map((report) => [
          [`/plan-and-forecast${report.path}`, report.path],
          [report.path, `/library${report.path}`],
        ])
        .flat(),
      ...forecastReports
        .map((report) => [
          [`/plan-and-forecast${report.path}`, report.path],
          [report.path, `/library${report.path}`],
        ])
        .flat(),
      ...varianceReports
        .map((report) => [
          [`/plan-and-forecast${report.path}`, report.path],
          [report.path, `/library${report.path}`],
        ])
        .flat(),
      ...accountingReports
        .map((report) => [
          [`/financial${report.path}`, report.path],
          [report.path, `/library${report.path}`],
        ])
        .flat(),
      ...performanceReports
        .map((report) => [
          [`/financial${report.path}`, report.path],
          [report.path, `/library${report.path}`],
        ])
        .flat(),
      ...pipelineReports
        .map((report) => [
          [`/pipeline${report.path}`, report.path],
          [report.path, `/library${report.path}`],
        ])
        .flat(),
      ...workspaceReports
        .map((report) => [
          [`/workspace${report.path}`, report.path],
          [report.path, `/library${report.path}`],
        ])
        .flat(),
    ],
    [
      timeReports,
      expenseReports,
      utilizationReports,
      planReports,
      forecastReports,
      varianceReports,
      accountingReports,
      performanceReports,
      pipelineReports,
      workspaceReports,
    ],
  );

  const { workspace } = useWorkspace();

  return (
    <FavoriteReportsProvider>
      <SavedReportsProvider>
        <Switch>
          <Route path={path.concat('/library')} exact>
            <ReportsPage />
          </Route>

          {reports.map((report) => (
            <Route
              key={path}
              path={path.concat('/library').concat(report.path)}
              render={() => {
                const Component = report.component;
                return <Component report={{ ...report, reportKey: report.key }} />;
              }}
            />
          ))}

          <Route path={path.concat('/saved')} exact>
            <SavedReportsPage />
          </Route>

          <Route path={path.concat('/saved/:savedReportId')}>
            <SavedReportPage />
          </Route>

          {legacyRoutesRedirect.map(([from, to]) => (
            <Redirect key={from} from={path.concat(from)} to={url.concat(to).concat(search)} exact />
          ))}

          <Redirect to={`/app/${workspace.key}/reports/library`} />
        </Switch>
      </SavedReportsProvider>
    </FavoriteReportsProvider>
  );
}

export default ReportsArea;
