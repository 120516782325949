import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { ActionButton, Buttons, CancelButton, Drawer, Icon, InlineTooltip, Tab, Tabs } from '~/components';
import { useDirtyCheck, useForm } from '~/hooks';
import EmailTab from './EmailTab';
import AttachmentsTab from './AttachmentsTab';
import _ from 'lodash';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { emptyStringToNull, getFileDataUrl, mergeValues } from '~/utils';
import * as Yup from 'yup';
import UploadingWarning from './UploadingWarning';
import SendCreditNoteConfirmation from './SendCreditNoteConfirmation';
import { Formik } from 'formik';
import AttachmentsSize from './AttachmentsSize';
import { colors } from '~/styles';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: -1rem;
  margin-top: 1.625rem;
`;

const MAX_EMAIL_ATTACHMENTS_SIZE = 26214400;

function SendCreditNoteDrawer({ creditNote, onSent, onClose, tab }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const toast = useToast();
  const confirmation = useConfirmation();

  const [tabIndex, setTabIndex] = useState({ email: 0, attachments: 1 }[tab] || 0);
  const drawerRef = useRef();
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => (formRef ? formRef.current?.dirty : null));
  const [{ status, message, isSubmitting, saved }, form] = useForm();

  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [attachmentsError, setAttachmentsError] = useState(null);
  const [filesSize, setFilesSize] = useState();
  const [isFileSizeLoading, setIsFileSizeLoading] = useState(false);
  const [showUploadingWarning, setShowUploadingWarning] = useState(false);

  const isFileSizeExceeded = useMemo(() => filesSize > MAX_EMAIL_ATTACHMENTS_SIZE, [filesSize]);

  const initialValues = mergeValues(
    {
      email: '',
      emails: [],
      ccEmail: '',
      ccEmails: [],
      replyTo: '',
      sendBcc: true,
      emailFromName: '',
      emailSubject: `Credit Note #${creditNote.number} for ${creditNote.client.name}`.substr(0, 255),
      emailBody: '',
      includePdfAttachment: false,
    },
    {
      ...creditNote,
      email: creditNote.emails ? creditNote.emails.join(', ') : '',
      ccEmail: creditNote.ccEmails ? creditNote.ccEmails.join(', ') : '',
    },
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setAttachmentsError(null);
      try {
        const { data } = await api.www.workspaces(workspace.id).creditNotes(creditNote.id).files().get();
        setFiles(data);
      } catch (error) {
        if (error?.message) {
          setAttachmentsError(error.message);
        } else {
          setAttachmentsError('There was a problem getting your files.');
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
    return fetchData.cancel;
  }, [api, workspace.id, creditNote.id]);

  useEffect(() => {
    (async () => {
      setIsFileSizeLoading(true);
      const { data } = await api.www.workspaces(workspace.id).creditNotes(creditNote.id).files().getFilesSize();
      setFilesSize(data);
      setIsFileSizeLoading(false);
    })();
  }, [files, api, creditNote.id, workspace.id]);

  const addFiles = async (files) => {
    // Generate placeholder files, run in parallel
    const tempFiles = await Promise.all(
      files.map(async (fileData) => ({
        id: _.uniqueId('temp_file_'),
        name: fileData.name,
        type: fileData.type || 'application/octet-stream',
        status: 'uploading',
        dataUrl: await getFileDataUrl(fileData),
      })),
    );

    // Set the files all at once to prevent missing files
    setFiles((files) => [...tempFiles, ...files]);

    // Upload the files, run in parallel
    await Promise.all(
      tempFiles.map(async (file) => {
        try {
          const response = await api.www
            .workspaces(workspace.id)
            .creditNotes(creditNote.id)
            .files()
            .upload({
              name: file.name,
              type: file.type,
              data: file.dataUrl.split(';base64,').pop(),
              isConfidential: file.isConfidential,
            });

          const updatedFile = {
            ...response.data,
            status: 'uploaded',
            dataUrl: file.dataUrl,
          };

          setFiles((files) => {
            const fileIndex = _.findIndex(files, { id: file.id });
            if (fileIndex >= 0) {
              return _.assign([], files, { [fileIndex]: updatedFile });
            }
            return files;
          });
        } catch (error) {
          setFiles((files) => {
            const fileIndex = _.findIndex(files, { id: file.id });
            if (fileIndex >= 0) {
              const updatedFile = _.assign({}, files[fileIndex], {
                status: 'error',
              });
              return _.assign([], files, { [fileIndex]: updatedFile });
            }
            return files;
          });
        }
      }),
    );
  };

  const removeFile = (file) => {
    setFiles((files) => _.reject(files, { id: file.id }));
  };

  const onDrop = (acceptedFiles, rejectedFiles) => {
    for (const file of rejectedFiles) {
      if (_.find(file.errors, { code: 'file-too-large' })) {
        toast.error(`The file ${file.file.path} is too large to upload. The file size limit is 5 MB.`);
      }
      if (_.find(file.errors, { code: 'file-invalid-type' })) {
        toast.error(`${_.find(file.errors, { code: 'file-invalid-type' }).message}`);
      }
    }
    if (acceptedFiles.length > 0) {
      addFiles(acceptedFiles);
    }
  };

  const schema = Yup.object().shape({
    emails: Yup.array()
      .of(Yup.string().email().max(255).required())
      .label('To')
      .min(1, 'To field must have at least one email')
      .required(),
    ccEmails: Yup.array().of(Yup.string().email().max(255).required()).label('Cc'),
    replyTo: Yup.string().label('Reply To').email().max(255).required(),
    sendBcc: Yup.boolean().label('Bcc Me').required(),
    emailFromName: Yup.string().label('From Name').max(255),
    emailSubject: Yup.string().label('Subject').max(255).required(),
    emailBody: Yup.string().label('Body').max(5000).required(),
  });

  function handleClose() {
    onClose();
  }

  const onBeforeClose = (callback) => {
    if (_.some(files, { status: 'uploading' }) || isFileSizeLoading) {
      setShowUploadingWarning(true);
    } else if (typeof callback === 'function') {
      callback();
    }
  };

  const tabErrors = {
    email: ['emails', 'ccEmails', 'replyTo', 'sendBcc', 'emailSubject', 'emailBody'],
  };

  return (
    <>
      <Drawer
        isOpen
        title="Send Credit Note via Email"
        byline={`Credit Note #${creditNote.number}`}
        ref={drawerRef}
        onBeforeClose={({ setIsOpen }) => onBeforeClose(() => dirtyCheck(() => setIsOpen(false)))}
        onClose={handleClose}>
        {(closeDrawer) => {
          const handleCloseClick = () => {
            if (_.some(files, { status: 'uploading' }) || isFileSizeLoading) {
              setShowUploadingWarning(true);
            } else {
              dirtyCheck(() => closeDrawer());
            }
          };

          const handleSend = async (values) => {
            try {
              form.submit('send');

              const sendValues = emptyStringToNull(_.omit(values, ['email', 'ccEmail']));
              await api.www.workspaces(workspace.id).creditNotes(creditNote.id).sendEmail(sendValues);

              const { data: result } = await api.www.workspaces(workspace.id).creditNotes(creditNote.id).get();

              form.save('send');
              toast.success(`Credit Note #${creditNote.number} sent to ${sendValues.emails.join(', ')}`);
              closeDrawer();
              if (onSent) onSent(result);
            } catch (error) {
              form.error(error);
              drawerRef.current.scrollTo({ top: 0 });
            }
          };

          const handleSendTest = async (values) => {
            try {
              form.submit('test');

              const sendValues = emptyStringToNull(_.omit(values, ['email', 'ccEmail']));
              await api.www.workspaces(workspace.id).creditNotes(creditNote.id).sendTestEmail(sendValues);

              form.save('test');
              toast.success(`Credit Note #${creditNote.number} sent to ${workspace.member.email}`);
              if (onSent) onSent();
            } catch (error) {
              form.error(error);
              drawerRef.current.scrollTo({ top: 0 });
            }
          };

          async function handleSubmit(values, formik) {
            switch (formRef.current.status) {
              case 'test':
                await handleSendTest(values);
                formik.resetForm({ values });
                break;

              case 'send': {
                const result = await confirmation.prompt((resolve) => (
                  <SendCreditNoteConfirmation creditNote={creditNote} onResolve={resolve} />
                ));
                if (!result) return;

                await handleSend(values);
                break;
              }

              default:
                break;
            }
          }

          return (
            <Formik
              enableReinitialize
              initialValues={initialValues}
              innerRef={formRef}
              onSubmit={handleSubmit}
              validationSchema={schema}>
              {(formik) => {
                return (
                  <>
                    <Tabs selectedIndex={tabIndex} onChange={(index) => setTabIndex(index)}>
                      <Tab>
                        Email
                        {tabErrors.email.some((key) => !!formik.errors[key]) && (
                          <Icon
                            icon="exclamation-circle"
                            color={colors.danger}
                            spaceLeft
                            data-testid="exclamation_icon"
                          />
                        )}
                      </Tab>
                      <Tab>
                        Attachments
                        {isFileSizeExceeded && <Icon icon="exclamation-circle" color={colors.danger} spaceLeft />}
                      </Tab>
                    </Tabs>
                    <Content>
                      {
                        [
                          <EmailTab key="email" formik={formik} message={message} status={status} />,
                          <AttachmentsTab
                            key="attachments"
                            creditNote={creditNote}
                            onDrop={onDrop}
                            files={files}
                            error={attachmentsError}
                            maxFileSize={MAX_EMAIL_ATTACHMENTS_SIZE}
                            isLoading={isLoading}
                            removeFile={removeFile}
                          />,
                        ][tabIndex]
                      }
                    </Content>
                    <Drawer.Actions>
                      {files.length > 0 && (
                        <AttachmentsSize
                          style={{ marginRight: '2rem' }}
                          used={filesSize}
                          maxSize={MAX_EMAIL_ATTACHMENTS_SIZE}
                        />
                      )}

                      <Buttons align="right">
                        <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                        <ActionButton
                          isOutline
                          style={{ position: 'relative' }}
                          isLoading={isSubmitting === 'test'}
                          ok={saved === 'test'}
                          onClick={() => formik.setStatus('test') || formik.submitForm()}
                          disabled={isFileSizeExceeded || isFileSizeLoading || _.some(files, { status: 'uploading' })}>
                          Send Test to Me
                          {isFileSizeExceeded && (
                            <InlineTooltip message="The combined attachments size cannot exceed 5 MB." />
                          )}
                        </ActionButton>

                        <ActionButton
                          style={{ position: 'relative' }}
                          isLoading={isSubmitting === 'send'}
                          ok={saved === 'send'}
                          onClick={() => formik.setStatus('send') || formik.submitForm()}
                          disabled={isFileSizeExceeded || isFileSizeLoading || _.some(files, { status: 'uploading' })}>
                          Send
                          {isFileSizeExceeded && (
                            <InlineTooltip message="The combined attachments size cannot exceed 5 MB." />
                          )}
                        </ActionButton>
                      </Buttons>
                    </Drawer.Actions>
                  </>
                );
              }}
            </Formik>
          );
        }}
      </Drawer>
      {showUploadingWarning && <UploadingWarning onClose={() => setShowUploadingWarning(false)} />}
    </>
  );
}

export default SendCreditNoteDrawer;
