import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  ClientLink,
  Hours,
  InternalClientTooltip,
  Percent,
  ProjectLink,
  RouteLink,
  Widget,
  ExportDropdown,
} from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useAuth, useDocumentTitle, useFeatures, useSearchParams, useSearchParamsConfig } from '~/hooks';
import useClientFilters from '~/hooks/useClientFilters';
import useMemberFilters from '~/hooks/useMemberFilters';
import useProjectFilters from '~/hooks/useProjectFilters';
import { PageLoader } from '~/routes/public/pages';
import { colors, weights } from '~/styles';
import { QuerySort, QueryString } from '~/utils';
import mimeTypes from '~/utils/mimeTypes';
import ClientFiltersBar from '~/components/filters/ClientFiltersBar';
import ClientFiltersGroup from '~/components/filters/ClientFiltersGroup';
import ExportDialogAsync from '../components/ExportDialogAsync';
import FilterButton from '~/components/filters/FilterButton';
import MemberFiltersBar from '~/components/filters/MemberFiltersBar';
import MemberFiltersGroup from '~/components/filters/MemberFiltersGroup';
import Meter from '../components/Meter';
import { Metric, Metrics } from '../components/Metric';
import ProjectFiltersBar from '~/components/filters/ProjectFiltersBar';
import ProjectFiltersGroup from '~/components/filters/ProjectFiltersGroup';
import Report from '../components/Report';
import ReportPeriodFilter from '~/components/filters/ReportPeriodFilter';
import Table from '../components/table';
import TimeFiltersBar from '~/components/filters/TimeFiltersBar';
import TimeFiltersGroup from '~/components/filters/TimeFiltersGroup';
import useReportsSearchParamsConfig from '../hooks/useReportsSearchParamsConfig';
import SavedReportActions from '../SavedReportActions';

const ProjectInfo = styled.div`
  line-height: 1;

  small {
    font-size: 0.75rem;
    color: ${colors.grey40};
    display: flex;
    padding-top: 0.25rem;
  }
`;

const Role = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

export default function TimeSummaryByProjectAndRole({ report }) {
  useDocumentTitle(report.name);
  const { workspace } = useWorkspace();
  const api = useApi();
  const features = useFeatures();

  const [query, setQuery] = useState({ report: null, status: 'loading' });

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();
  const memberFilters = useMemberFilters();

  const [params, setParams] = useState({
    period: null,
    approvalStatuses: [],
    clientApprovalStatuses: [],
    projectRoles: [],
    sort: new QuerySort('project.name', 'asc'),
    ...clientFilters.filters,
    ...projectFilters.filters,
    ...memberFilters.filters,
  });

  // Init and sync URL search params
  const searchParamsConfig = useSearchParamsConfig();
  const reportsSearchParamsConfig = useReportsSearchParamsConfig();
  const [searchParamsStatus, setSearchParamsStatus] = useState('pending');
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: reportsSearchParamsConfig.period,
        approvalStatuses: searchParamsConfig.approvalStatuses,
        clientApprovalStatuses: searchParamsConfig.clientApprovalStatuses,
        projectRoles: searchParamsConfig.projectRoles,
        sort: { default: new QuerySort('project.name', 'asc'), ...searchParamsConfig.sort },
        ...clientFilters.searchParamsConfig,
        ...projectFilters.searchParamsConfig,
        ...memberFilters.searchParamsConfig,
      }),
      [searchParamsConfig, reportsSearchParamsConfig, clientFilters, projectFilters, memberFilters],
    ),

    onChange: (params) => setParams((state) => ({ ...state, ...params })),
  });

  // Map the values to perform the API query
  const urlSearchParams = useMemo(
    () => ({
      start: params.period?.start ?? undefined,
      end: params.period?.end ?? undefined,
      approvalStatusId: params.approvalStatuses?.map((v) => v.id),
      clientApprovalStatusId: features.clientApprovals ? params.clientApprovalStatuses?.map((v) => v.id) : undefined,
      projectRoleId: params.projectRoles?.map((v) => v.id),
      sort: params.sort,
      ...clientFilters.mapUrlSearchParams(params),
      ...projectFilters.mapUrlSearchParams(params),
      ...memberFilters.mapUrlSearchParams(params),
    }),
    [params, features.clientApprovals, clientFilters, projectFilters, memberFilters],
  );

  useEffect(() => {
    if (searchParamsStatus === 'ready') return;
    searchParams.get().then((params) => {
      setParams((state) => ({ ...state, ...params }));
      setSearchParamsStatus('ready');
    });
  }, [searchParams, searchParamsStatus]);

  const fetchData = useCallback(async () => {
    const { data } = await api.www.workspaces(workspace.id).reports().timeSummaryByProjectAndRole(urlSearchParams);
    setQuery((state) => ({ ...state, data, status: 'ready' }));
  }, [api, workspace.id, urlSearchParams]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const showFilters = () => setFiltersVisible(true);
  const hideFilters = () => setFiltersVisible(false);
  const handleApplyFilters = (values) => {
    if (values !== params) setQuery((state) => ({ ...state, status: 'filtering' }));

    setParams({ ...params, ...values });
    searchParams.set(_.omit(values, 'sort'));
    hideFilters();
  };

  const handleSort = ({ column, sort }) => {
    setQuery((state) => ({ ...state, status: 'sorting' }));
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    setParams({ ...params, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  const confirmation = useConfirmation();

  const handleExport = (mimeType) => {
    confirmation.prompt((resolve) => (
      <ExportDialogAsync
        onLoad={api.www
          .workspaces(workspace.id)
          .reports()
          .timeSummaryByProjectAndRole(urlSearchParams, {
            headers: { accept: mimeType },
          })}
        onClose={resolve}
      />
    ));
  };

  return (
    <Report>
      <Report.Header>
        <Report.Info report={report} />

        <Report.Actions>
          <SavedReportActions report={report} onSave={() => searchParams.set(params)} />
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(mimeTypes.pdf);
                    setIsOpen(false);
                  }}>
                  Export to PDF
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <FilterButton isOutline onClick={showFilters} />
        </Report.Actions>
      </Report.Header>

      <Report.FiltersBar>
        <ReportPeriodFilter value={params.period} onChange={({ value }) => handleApplyFilters({ period: value })} />

        <ClientFiltersBar filters={params} onChange={handleApplyFilters} />

        <ProjectFiltersBar filters={params} onChange={handleApplyFilters} />

        <TimeFiltersBar filters={params} onChange={handleApplyFilters} />

        <MemberFiltersBar filters={params} onChange={handleApplyFilters} />
      </Report.FiltersBar>

      {(() => {
        switch (query.status) {
          case 'loading':
          case 'filtering':
            return <PageLoader />;

          default:
            return (
              <>
                <Data query={query} params={params} onSort={handleSort} />
                <Filters values={params} isOpen={filtersVisible} onApply={handleApplyFilters} onClose={hideFilters} />
              </>
            );
        }
      })()}
    </Report>
  );
}

function Data({ query, params, onSort }) {
  const report = query.data;
  const { workspace } = useWorkspace();
  const auth = useAuth();

  const clientFilters = useClientFilters();
  const projectFilters = useProjectFilters();
  const memberFilters = useMemberFilters();

  const timeDetail = useCallback(
    (query = {}) =>
      `/app/${workspace.key}/reports/time-entries?${new QueryString(
        {
          start: params.period?.start ?? 'not_set',
          end: params.period?.end ?? 'not_set',
          status: params.approvalStatuses?.map((v) => v.id),
          projectRole: params.projectRoles?.map((v) => v.id),
          ...clientFilters.mapClickThroughParams(params),
          ...projectFilters.mapClickThroughParams(params),
          ...memberFilters.mapClickThroughParams(params),
          ...query,
        },
        { multi: true },
      ).toString()}`,
    [params, workspace.key, clientFilters, projectFilters, memberFilters],
  );

  return (
    <>
      <Report.Summary>
        <Widget>
          <Meter>
            <Meter.Value
              label="Client Billable"
              value={report.totals.billable}
              color={colors.success}
              tooltip={
                <>
                  <Hours value={report.totals.billable} /> hours (
                  <Percent value={report.totals.billable / report.totals.total} />)
                </>
              }
            />
            <Meter.Value
              label="Client Non-Billable"
              value={report.totals.nonBillable}
              color={colors.danger}
              tooltip={
                <>
                  <Hours value={report.totals.nonBillable} /> hours (
                  <Percent value={report.totals.nonBillable / report.totals.total} />)
                </>
              }
            />
            <Meter.Value
              label="Internal"
              value={report.totals.internal}
              color={colors.warning}
              tooltip={
                <>
                  <Hours value={report.totals.internal} /> hours (
                  <Percent value={report.totals.internal / report.totals.total} />)
                </>
              }
            />
          </Meter>

          <Metrics>
            <Metric>
              <Metric.Value>
                <Hours value={report.totals.billable} />
              </Metric.Value>
              <Metric.Label>Client Billable</Metric.Label>
            </Metric>
            <Metric>
              <Metric.Value>
                <Hours value={report.totals.nonBillable} />
              </Metric.Value>
              <Metric.Label>Client Non-Billable</Metric.Label>
            </Metric>
            <Metric>
              <Metric.Value>
                <Hours value={report.totals.internal} />
              </Metric.Value>
              <Metric.Label>Internal</Metric.Label>
            </Metric>
            <Metric>
              <Metric.Value>
                <Hours value={report.totals.total} />
              </Metric.Value>
              <Metric.Label>Total</Metric.Label>
            </Metric>
          </Metrics>
        </Widget>
      </Report.Summary>

      <Report.Table>
        <Table>
          <Table.Header>
            <Table.Column sticky minWidth="16rem" name="project.name" onSort={onSort} sort={params.sort}>
              Project/Client
            </Table.Column>
            <Table.Column width="10rem" align="right">
              Client
              <br />
              Billable
            </Table.Column>
            <Table.Column width="10rem" align="right">
              Client
              <br />
              Non-Billable
            </Table.Column>
            <Table.Column width="10rem" align="right">
              Internal
            </Table.Column>
            <Table.Column width="10rem" align="right">
              Total
            </Table.Column>
            <Table.Column isVisible={auth.projects.viewBudgetHours} width="9rem" align="right">
              Budget
            </Table.Column>
            <Table.Column isVisible={auth.projects.viewBudgetHours} width="9rem" align="right">
              Budget Left
            </Table.Column>
            <Table.Column isVisible={auth.projects.viewBudgetHours} width="9rem" align="right">
              Percent
              <br />
              Left
            </Table.Column>
          </Table.Header>

          <Table.Body fade={query.status === 'sorting'}>
            {report.records.map((project) => {
              return (
                <React.Fragment key={project.id}>
                  <Table.GroupRow top="4rem">
                    <Table.Cell>
                      <ProjectInfo>
                        <p>
                          <ProjectLink project={project} />
                        </p>
                        <small>
                          <ClientLink client={project.client} />
                          {project.client.isInternal && <InternalClientTooltip />}
                        </small>
                      </ProjectInfo>
                    </Table.Cell>
                    <Table.Cell>
                      <strong>
                        <RouteLink to={timeDetail({ project: project.id, billableType: 'billable' })}>
                          <Hours value={project.billableHours} />
                        </RouteLink>
                      </strong>
                    </Table.Cell>
                    <Table.Cell>
                      <strong>
                        <RouteLink to={timeDetail({ project: project.id, billableType: 'non_billable' })}>
                          <Hours value={project.nonBillableHours} />
                        </RouteLink>
                      </strong>
                    </Table.Cell>
                    <Table.Cell>
                      <strong>
                        <RouteLink to={timeDetail({ project: project.id, billableType: 'internal' })}>
                          <Hours value={project.internalHours} />
                        </RouteLink>
                      </strong>
                    </Table.Cell>
                    <Table.Cell>
                      <strong>
                        <RouteLink to={timeDetail({ project: project.id })}>
                          <Hours value={project.totalHours} />
                        </RouteLink>
                      </strong>
                    </Table.Cell>
                    <Table.Cell />
                    <Table.Cell />
                    <Table.Cell />
                  </Table.GroupRow>
                  {project.roles.map((role) => {
                    return (
                      <Table.Row key={role.id}>
                        <Table.Cell>
                          <Role>{role.name}</Role>
                        </Table.Cell>
                        <Table.Cell>
                          <RouteLink
                            to={timeDetail({ project: project.id, projectRole: role.id, billableType: 'billable' })}>
                            <Hours value={role.billableHours} />
                          </RouteLink>
                        </Table.Cell>
                        <Table.Cell>
                          <RouteLink
                            to={timeDetail({
                              project: project.id,
                              projectRole: role.id,
                              billableType: 'non_billable',
                            })}>
                            <Hours value={role.nonBillableHours} />
                          </RouteLink>
                        </Table.Cell>
                        <Table.Cell>
                          <RouteLink
                            to={timeDetail({ project: project.id, projectRole: role.id, billableType: 'internal' })}>
                            <Hours value={role.internalHours} />
                          </RouteLink>
                        </Table.Cell>
                        <Table.Cell>
                          <RouteLink to={timeDetail({ project: project.id, projectRole: role.id })}>
                            <Hours value={role.totalHours} />
                          </RouteLink>
                        </Table.Cell>
                        <Table.Cell>
                          <Hours value={role.totalHoursBudget} />
                        </Table.Cell>
                        <Table.Cell>
                          <Hours value={role.totalHoursBudgetLeft} />
                        </Table.Cell>
                        <Table.Cell>
                          <Percent value={role.totalHoursPercentLeft} />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </React.Fragment>
              );
            })}

            <Table.Row style={{ fontWeight: weights.bold }}>
              <Table.Cell>Total</Table.Cell>
              <Table.Cell>
                <Hours value={report.totals.billable} />
              </Table.Cell>
              <Table.Cell>
                <Hours value={report.totals.nonBillable} />
              </Table.Cell>
              <Table.Cell>
                <Hours value={report.totals.internal} />
              </Table.Cell>
              <Table.Cell>
                <Hours value={report.totals.total} />
              </Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
          </Table.Body>

          <Table.Status
            total={report.records.length}
            label="Project"
            isLoading={query.status !== 'ready'}
            partialResults={report.partialResults}
          />
        </Table>
      </Report.Table>
    </>
  );
}

function Filters({ values, isOpen, onClose, onApply }) {
  const [filters, setFilters] = useState(values);

  const handleApply = () => {
    onApply(filters);
  };

  const handleFilter = (filter) => {
    setFilters({ ...filters, ...filter });
  };

  const handleCancel = () => {
    setFilters(values);
    onClose();
  };

  useEffect(() => {
    setFilters(values);
  }, [values]);

  return (
    <Report.FiltersDrawer isOpen={isOpen} onApply={handleApply} onClose={handleCancel}>
      <ReportPeriodFilter
        value={filters.period}
        onChange={({ target: { value } }) => handleFilter({ period: value })}
      />

      <ClientFiltersGroup filters={filters} onChange={handleFilter} />

      <ProjectFiltersGroup filters={filters} onChange={handleFilter} />

      <MemberFiltersGroup filters={filters} onChange={handleFilter} />

      <TimeFiltersGroup filters={filters} onChange={handleFilter} />
    </Report.FiltersDrawer>
  );
}
