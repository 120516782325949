import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { CloudFolderLink, CompanyLink, HelpMessage, MultilineText, Page, Panel, Pill, Pills } from '~/components';
import { useWorkspace } from '~/contexts';
import EffectiveRateWidget from './widgets/ClientEffectiveRateWidget';
import ClientExpensesWidget from './widgets/ClientExpensesWidget';
import ClientHoursWidget from './widgets/ClientHoursWidget';
import ClientMarginWidget from './widgets/ClientMarginWidget';
import ClientRealizationRateWidget from './widgets/ClientRealizationRateWidget';
import ClientRevenueWidget from './widgets/ClientRevenueWidget';

function ClientDetailsPage({ client }) {
  const { workspace } = useWorkspace();

  const kpi = {
    realizationRate: !client.isInternal && client.permissions.viewTimeAndExpenses,
    effectiveRate: !client.isInternal && client.permissions.viewRates,
    servicesMargin: !client.isInternal && client.permissions.viewMargin,
    hours: client.permissions.viewTimeAndExpenses,
    expenses: client.permissions.viewTimeAndExpenses,
    revenue: !client.isInternal && client.permissions.viewRevenue,
    get any() {
      return (
        this.realizationRate || this.effectiveRate || this.servicesMargin || this.hours || this.expenses || this.revenue
      );
    },
  };

  return (
    <Page.Content>
      <Page.Main>
        {!client.permissions.viewTimeAndExpenses && (
          <Page.Section>
            <HelpMessage>
              Your visibility into this client is limited because you do not have permission to view the time and
              expenses for every project within this client.
            </HelpMessage>
          </Page.Section>
        )}

        {kpi.any && (
          <>
            <Page.TabHeader>
              <Page.Title>Key Performance Indicators</Page.Title>
            </Page.TabHeader>

            <Page.Section>
              <Page.Widgets>
                {kpi.realizationRate && <ClientRealizationRateWidget client={client} />}

                {kpi.effectiveRate && <EffectiveRateWidget client={client} />}

                {kpi.servicesMargin && <ClientMarginWidget client={client} />}

                {kpi.hours && <ClientHoursWidget client={client} />}

                {kpi.expenses && <ClientExpensesWidget client={client} />}

                {kpi.revenue && <ClientRevenueWidget client={client} />}
              </Page.Widgets>
            </Page.Section>
          </>
        )}
      </Page.Main>

      <Panel>
        <Panel.Title>Client Details</Panel.Title>

        {client && (
          <>
            {client.owner && (
              <Panel.Info title="Relationship Owner">
                <Panel.Member member={client.owner} />
              </Panel.Info>
            )}

            {client.businessUnit && <Panel.Info title="Business Unit">{client.businessUnit.name}</Panel.Info>}

            {client.salesRepresentative && (
              <Panel.Info title="Sales Representative">
                <Panel.Member member={client.salesRepresentative} />
              </Panel.Info>
            )}

            {client.company && (
              <Panel.Info title={'Company'}>
                <CompanyLink company={client.company} />
              </Panel.Info>
            )}

            <Panel.Info title="Currency">
              {client.currencyName} ({client.currency})
            </Panel.Info>

            {client.industry && <Panel.Info title="Industry">{client.industry.name}</Panel.Info>}

            {client.practice && <Panel.Info title="Practice">{client.practice.name}</Panel.Info>}

            {client.code && <Panel.Info title="Client Code">{client.code}</Panel.Info>}

            {client.tags?.length > 0 && (
              <Panel.Info title="Tags">
                <Pills>
                  {client.tags.map((tag) => (
                    <Link key={tag.id} to={`/app/${workspace.key}/clients?clientTag=${tag.id}`}>
                      <Pill variant="grey10">{tag.name}</Pill>
                    </Link>
                  ))}
                </Pills>
              </Panel.Info>
            )}

            {client.location && <Panel.Info title="Location">{client.location.name}</Panel.Info>}

            {client.cloudFolderUrl && (
              <Panel.Info title="Cloud Folder">
                <CloudFolderLink url={client.cloudFolderUrl} />
              </Panel.Info>
            )}

            {client.notes && (
              <Panel.Info title="Notes">
                <MultilineText value={client.notes} chars={280} buttonProps={{ isAnchor: true }} />
              </Panel.Info>
            )}
          </>
        )}
      </Panel>
    </Page.Content>
  );
}
export default ClientDetailsPage;
export { ClientDetailsPage };
