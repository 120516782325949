import admin from './admin';
import auth from './auth';
import mfa from './mfa';
import pub from './public';
import qbo from './qbo';
import slack from './slack';
import stripe from './stripe';
import workspaces from './workspaces';
import xero from './xero';
import salesforce from './salesforce';
import rippling from './rippling';
import hubspot from './hubspot';

const api = {
  ...pub,
  www: {
    ...auth,
    mfa,
    workspaces,
  },
  admin,
  slack,
  qbo: qbo.public,
  stripe: stripe.public,
  xero: xero.public,
  salesforce: salesforce.public,
  rippling: rippling.public,
  hubspot: hubspot.public,
};

export { api };
export default api;
