import _ from 'lodash';
import React from 'react';
import {
  AllocationBillableTypeFilter,
  BusinessUnitFilter,
  ClientFilter,
  ClientTagFilter,
  DisciplineFilter,
  EmploymentTypeFilter,
  IndustryFilter,
  JobTitleFilter,
  LocationFilter,
  MemberBillableTypeFilter,
  MemberFilter,
  MemberTagFilter,
  PracticeFilter,
  ProjectBillingTypeFilter,
  ProjectFilter,
  ProjectRecordStatusFilter,
  ProjectStatusFilter,
  ProjectTagFilter,
  ProjectTypeFilter,
  SkillFilter,
  YesNoFilter,
} from '~/components';
import { useFeatures } from '~/hooks';
import FiltersBarContainer from '../../components/FiltersBar';
import ResourceStatusFilter from '../../member-schedule/filters/ResourceStatusFilter';
import ResourceTypeFilter from '../../member-schedule/filters/ResourceTypeFilter';

export default function FiltersBar({ params, onFilterChange }) {
  const features = useFeatures();

  return (
    <FiltersBarContainer>
      <YesNoFilter
        icon="filter"
        placeholder="Only projects with allocations"
        value={params.onlyAllocatedProjects ? 'yes' : 'no'}
        onChange={({ target: { value } }) => onFilterChange({ onlyAllocatedProjects: value === 'yes' })}
      />

      {!_.isEmpty(params.client) && (
        <ClientFilter
          value={params.client}
          onChange={({ target: { value } }) => onFilterChange({ client: value, project: [] })}
        />
      )}

      {!_.isEmpty(params.clientOwner) && (
        <MemberFilter
          value={params.clientOwner}
          placeholder="Client Relationship Owner"
          onChange={({ target: { value } }) => onFilterChange({ clientOwner: value })}
        />
      )}

      {!_.isEmpty(params.clientTags) && (
        <ClientTagFilter
          value={params.clientTags}
          onChange={({ target: { value } }) => onFilterChange({ clientTags: value })}
        />
      )}

      {!_.isEmpty(params.clientLocations) && (
        <LocationFilter
          value={params.clientLocations}
          placeholder="Client Location"
          onChange={({ target: { value } }) => onFilterChange({ clientLocations: value })}
        />
      )}

      {!_.isEmpty(params.clientIndustries) && (
        <IndustryFilter
          value={params.clientIndustries}
          placeholder="Client Industry"
          onChange={({ target: { value } }) => onFilterChange({ clientIndustries: value })}
        />
      )}

      {features.businessUnits && !_.isEmpty(params.clientBusinessUnits) && (
        <BusinessUnitFilter
          value={params.clientBusinessUnits}
          placeholder="Client Business Unit"
          onChange={({ target: { value } }) => onFilterChange({ clientBusinessUnits: value })}
        />
      )}

      {!_.isEmpty(params.project) && (
        <ProjectFilter
          clients={params.client}
          value={params.project}
          onChange={({ target: { value } }) => onFilterChange({ project: value })}
        />
      )}

      {!_.isEmpty(params.projectAdmin) && (
        <MemberFilter
          value={params.projectAdmin}
          placeholder="Project Admin"
          onChange={({ target: { value } }) => onFilterChange({ projectAdmin: value })}
        />
      )}

      {!_.isEmpty(params.billingType) && (
        <ProjectBillingTypeFilter
          value={params.billingType}
          onChange={({ target: { value } }) => onFilterChange({ billingType: value })}
        />
      )}

      {!_.isEmpty(params.status) && (
        <ProjectStatusFilter
          value={params.status}
          onChange={({ target: { value } }) => onFilterChange({ status: value })}
        />
      )}

      {!_.isEmpty(params.recordStatusId) && (
        <ProjectRecordStatusFilter
          value={params.recordStatusId}
          onChange={({ target: { value } }) => onFilterChange({ recordStatusId: value })}
        />
      )}

      {!_.isEmpty(params.projectTags) && (
        <ProjectTagFilter
          value={params.projectTags}
          onChange={({ target: { value } }) => onFilterChange({ projectTags: value })}
        />
      )}

      {features.practices && !_.isEmpty(params.projectPractice) && (
        <PracticeFilter
          placeholder="Project Practice"
          value={params.projectPractice}
          onChange={({ target: { value } }) => onFilterChange({ projectPractice: value })}
        />
      )}

      {features.businessUnits && !_.isEmpty(params.projectBusinessUnits) && (
        <BusinessUnitFilter
          placeholder="Project Business Unit"
          value={params.projectBusinessUnits}
          onChange={({ target: { value } }) => onFilterChange({ projectBusinessUnits: value })}
        />
      )}

      {!_.isEmpty(params.projectTypes) && (
        <ProjectTypeFilter
          value={params.projectTypes}
          onChange={({ target: { value } }) => onFilterChange({ projectTypes: value })}
        />
      )}

      {!_.isEmpty(params.member) && (
        <MemberFilter
          value={params.member}
          placeholder="Member"
          onChange={({ target: { value } }) => onFilterChange({ member: value })}
        />
      )}

      {!_.isEmpty(params.memberEmploymentType) && (
        <EmploymentTypeFilter
          value={params.memberEmploymentType}
          onChange={({ target: { value } }) => onFilterChange({ memberEmploymentType: value })}
        />
      )}

      {!_.isEmpty(params.memberBillableTypeId) && (
        <MemberBillableTypeFilter
          value={params.memberBillableTypeId}
          onChange={({ target: { value } }) => onFilterChange({ memberBillableTypeId: value })}
        />
      )}

      {!_.isEmpty(params.jobTitles) && (
        <JobTitleFilter
          value={params.jobTitles}
          onChange={({ target: { value } }) => onFilterChange({ jobTitles: value })}
        />
      )}

      {!_.isEmpty(params.memberTags) && (
        <MemberTagFilter
          value={params.memberTags}
          onChange={({ target: { value } }) => onFilterChange({ memberTags: value })}
        />
      )}

      {!_.isEmpty(params.memberLocations) && (
        <LocationFilter
          value={params.memberLocations}
          placeholder="Member Location"
          onChange={({ target: { value } }) => onFilterChange({ memberLocations: value })}
        />
      )}

      {features.practices && !_.isEmpty(params.resourcePractice) && (
        <PracticeFilter
          placeholder="Resource Practice"
          value={params.resourcePractice}
          onChange={({ target: { value } }) => onFilterChange({ resourcePractice: value })}
        />
      )}

      {features.disciplines && !_.isEmpty(params.resourceDiscipline) && (
        <DisciplineFilter
          value={params.resourceDiscipline}
          placeholder="Resource Discipline"
          onChange={({ target: { value } }) => onFilterChange({ resourceDiscipline: value })}
        />
      )}

      {!_.isEmpty(params.resourceLocation) && (
        <LocationFilter
          value={params.resourceLocation}
          placeholder="Resource Location"
          onChange={({ target: { value } }) => onFilterChange({ resourceLocation: value })}
        />
      )}

      {!_.isEmpty(params.resourceSkill) && (
        <SkillFilter
          value={params.resourceSkill}
          placeholder="Resource Skills"
          onChange={({ target: { value } }) => onFilterChange({ resourceSkill: value })}
        />
      )}

      {!_.isEmpty(params.resourceStatusId) && (
        <ResourceStatusFilter
          value={params.resourceStatusId}
          onChange={({ target: { value } }) => onFilterChange({ resourceStatusId: value })}
        />
      )}

      {!_.isEmpty(params.resourceTypeId) && (
        <ResourceTypeFilter
          value={params.resourceTypeId}
          onChange={({ target: { value } }) => onFilterChange({ resourceTypeId: value })}
        />
      )}

      {!_.isEmpty(params.allocationBillableTypes) && (
        <AllocationBillableTypeFilter
          value={params.allocationBillableTypes}
          onChange={({ target: { value } }) => onFilterChange({ allocationBillableTypes: value })}
        />
      )}
    </FiltersBarContainer>
  );
}
