import { useAuth } from '~/hooks';
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { ErrorPage } from '~/routes/public/pages';
import InvoicesArea from '../invoices/InvoicesArea';
import ReadyToBillPage from '../invoices/ReadyToBillPage';
import PaymentsArea from '../invoices/payments/PaymentsArea';
import CreditNotesArea from './credit-notes/CreditNotesArea';

function BillingArea() {
  const { path, url } = useRouteMatch();
  const auth = useAuth();

  return (
    <Switch>
      <Route path={`${path}/ready-to-bill`}>
        {auth.invoices.manage.project ? <ReadyToBillPage /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>
      <Route path={`${path}/invoices`}>
        <InvoicesArea />
      </Route>
      <Route path={`${path}/payments`}>
        {auth.payments.view ? <PaymentsArea /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>
      <Route path={`${path}/credit-notes`}>
        {auth.creditNotes.view ? <CreditNotesArea /> : <ErrorPage.Forbidden publicSite={false} />}
      </Route>
      <Redirect path="/" to={auth.invoices.manage.project ? `${url}/ready-to-bill` : `${url}/invoices`} />
    </Switch>
  );
}

export default BillingArea;
