import _ from 'lodash';
import pluralize from 'pluralize';
import { rgba } from 'polished';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { Hours, Percent } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useFeatures } from '~/hooks';
import { colors, weights } from '~/styles';
import { Arrow } from '../../components/schedule/allocations/AllocationStyles';

const Container = styled.div`
  max-width: 22rem;
  padding: 0.75rem 0;
  display: flex;
  flex-direction: column;
`;

const Info = styled.div`
  display: flex;
  font-size: 0.75rem;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

const Label = styled.div`
  width: 11.5rem;
  padding-right: 0.5rem;
  color: ${colors.grey40};
  font-weight: ${weights.black};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  flex-shrink: 0;
  white-space: nowrap;
`;

const Value = styled.div`
  white-space: nowrap;
`;

function Capacity({ cell }) {
  if (cell.capacity == null) return null;

  return (
    <Info>
      <Label>Capacity</Label>
      <Value>
        <Hours value={cell.capacity} minimumFractionDigits={0} /> {pluralize('hours', cell.capacity)}
      </Value>
    </Info>
  );
}

function AllHours({ cell }) {
  return (
    <Info>
      <Label>All Hours</Label>
      <Value>
        <Hours value={cell.allocated} minimumFractionDigits={0} />{' '}
        {cell.capacity > 0 && (
          <>
            (<Percent value={cell.allocated / cell.capacity} minimumFractionDigits={0} /> allocated)
          </>
        )}
      </Value>
    </Info>
  );
}

function ProjectHours({ cell }) {
  return (
    <Info>
      <Label>Project Hours</Label>
      <Value>
        <Hours value={cell.project} minimumFractionDigits={0} />{' '}
        {cell.capacity > 0 && (
          <>
            (<Percent value={cell.project / cell.capacity} minimumFractionDigits={0} /> allocated)
          </>
        )}
      </Value>
    </Info>
  );
}

function BillableHours({ cell }) {
  return (
    <Info>
      <Label>Billable Hours</Label>
      <Value>
        <Hours value={cell.billable} minimumFractionDigits={0} /> {pluralize('hours', cell.billable)}
      </Value>
    </Info>
  );
}

function ProductiveHours({ cell }) {
  return (
    <Info>
      <Label>Productive Hours</Label>
      <Value>
        <Hours value={cell.productive} minimumFractionDigits={0} /> {pluralize('hours', cell.productive)}
      </Value>
    </Info>
  );
}

function AvailableHours({ cell }) {
  if (cell.available == null) return null;

  return (
    <Info>
      <Label>Available Hours</Label>
      <Value>
        <Hours value={cell.available} minimumFractionDigits={0} />{' '}
        {cell.capacity > 0 && (
          <>
            (<Percent value={cell.available / cell.capacity} minimumFractionDigits={0} /> available)
          </>
        )}
      </Value>
    </Info>
  );
}

function BillableUtilization({ cell }) {
  if (cell.utilization == null) return null;

  return (
    <Info>
      <Label>Billable Utilization</Label>
      <Value>{!isNaN(cell.utilization) ? <Percent value={cell.utilization} minimumFractionDigits={0} /> : 'N/A'}</Value>
    </Info>
  );
}

function ProductiveUtilization({ cell }) {
  if (cell.productiveUtilization == null) return null;

  return (
    <Info>
      <Label>Productive Utilization</Label>
      <Value>
        {!isNaN(cell.productiveUtilization) ? (
          <Percent value={cell.productiveUtilization} minimumFractionDigits={0} />
        ) : (
          'N/A'
        )}
      </Value>
    </Info>
  );
}

function UtilizationTarget({ cell }) {
  if (cell.targetBillable == null) return null;

  const target = cell.targetBillable / cell.capacity;

  return (
    <Info>
      <Label>Utilization Target</Label>
      <Value>
        {!isNaN(target) ? <Percent value={target} minimumFractionDigits={0} /> : 'N/A'} (
        <Hours value={cell.targetBillable} minimumFractionDigits={0} />{' '}
        {pluralize('billable hours', cell.targetBillable)})
      </Value>
    </Info>
  );
}

function TargetAttainment({ cell }) {
  if (cell.targetAttainment == null) return null;

  return (
    <Info>
      <Label>Target Attainment</Label>
      <Value>
        {!isNaN(cell.targetAttainment) ? <Percent value={cell.targetAttainment} minimumFractionDigits={0} /> : 'N/A'}
      </Value>
    </Info>
  );
}

export default function TooltipMessage({ cell, referenceElement }) {
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: ['top', 'bottom'],
        },
      },
      { name: 'arrow', options: { element: arrowElement } },
    ],
    placement: 'auto',
    strategy: 'fixed',
  });

  const features = useFeatures();

  const { workspace } = useWorkspace();
  const [data, setData] = useState();
  const api = useApi();

  useEffect(() => {
    const { start, end, unit, allocations, resourceIds } = cell;
    (async () => {
      const params = {
        start,
        end,
        unit,
        resourceIds,
        allocationIds: allocations.map((allocation) => allocation.id),
      };

      try {
        let metric = null;

        switch (cell.type) {
          case 'total': {
            await api.www
              .workspaces(workspace.id)
              .allocations()
              .memberSchedule.metrics.headers(params)
              .then((res) => (metric = res.data.total[0]));

            break;
          }

          case 'header':
            {
              switch (cell.resourceType) {
                case 'member': {
                  await api.www
                    .workspaces(workspace.id)
                    .allocations()
                    .memberSchedule.metrics.headers(params)
                    .then((res) => (metric = res.data.members[0]));

                  break;
                }

                case 'placeholder': {
                  await api.www
                    .workspaces(workspace.id)
                    .allocations()
                    .memberSchedule.metrics.headers(params)
                    .then((res) => (metric = res.data.placeholders[0]));

                  break;
                }
              }
            }
            break;

          case 'group': {
            switch (cell.group.resourceType) {
              case 'member': {
                await api.www
                  .workspaces(workspace.id)
                  .allocations()
                  .memberSchedule.metrics.resources(params)
                  .then((res) => (metric = res.data.members[0]));

                break;
              }

              case 'placeholder': {
                await api.www
                  .workspaces(workspace.id)
                  .allocations()
                  .memberSchedule.metrics.resources(params)
                  .then((res) => (metric = res.data.placeholders[0]));

                break;
              }
            }
          }
        }

        if (!metric) return;

        setData({
          allocated: metric.allocated,
          billable: metric.billable,
          project: metric.project,
          capacity: metric.capacity,
          productive: metric.productive,
          targetBillable: metric.targetBillable,
          workingDays: metric.workingDays,

          get available() {
            if (this.capacity == null) return null;
            return _.round(this.capacity - this.allocated, 2);
          },

          get utilization() {
            if (this.capacity == null) return null;
            return _.round(this.billable / this.capacity, 2);
          },

          get productiveUtilization() {
            if (this.capacity == null) return null;
            return _.round(this.productive / this.capacity, 2);
          },

          get targetAttainment() {
            if (this.targetBillable == null) return null;
            return _.round(this.billable / this.targetBillable, 2);
          },

          get hasWorkingDays() {
            return this.capacity > 0 || this.workingDays > 0;
          },
        });
      } catch {
        // Do nothing
      }
    })();
  }, [cell, workspace.id, api]);

  if (!data) return null;

  return ReactDOM.createPortal(
    <CellTooltip ref={setPopperElement} style={styles.popper} {...attributes.popper}>
      <Container>
        <Capacity cell={data} />

        <AllHours cell={data} />

        <ProjectHours cell={data} />

        <BillableHours cell={data} />

        <ProductiveHours cell={data} />

        <AvailableHours cell={data} />

        {features.utilization && (
          <>
            <BillableUtilization cell={data} />

            <ProductiveUtilization cell={data} />

            <UtilizationTarget cell={data} />

            <TargetAttainment cell={data} />
          </>
        )}
      </Container>

      <Arrow ref={setArrowElement} style={styles.arrow} />
    </CellTooltip>,
    document.body,
  );
}

const CellTooltip = styled.div`
  min-width: 7.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  border-radius: 0.3125rem;
  background: ${colors.white};
  border: solid 1px ${colors.grey10};
  box-shadow: 0 0.1875rem 1rem ${rgba(colors.black, 0.25)};
  z-index: 300;

  &[data-popper-placement^='top'] > ${Arrow} {
    bottom: -4px;
  }

  &[data-popper-placement^='bottom'] > ${Arrow} {
    top: -4px;
  }

  &[data-popper-placement^='left'] > ${Arrow} {
    right: -4px;
  }

  &[data-popper-placement^='right'] > ${Arrow} {
    left: -4px;
  }
`;
