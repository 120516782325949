import _ from 'lodash';
import { QueryString } from '~/utils';
import { get, post, del } from './agent';

const purchaseOrderFiles =
  (purchaseOrderFilesUrl) =>
  (id = '') => {
    const url = _([purchaseOrderFilesUrl, id]).compact().join('/');

    return {
      get: (query = {}) => get(url.concat(new QueryString(query).toString(true))),
      upload: (body) => post(url, body),
      delete: () => del(url),
    };
  };

export default purchaseOrderFiles;
