import { Formik } from 'formik';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Button,
  Buttons,
  CancelButton,
  DeleteButton,
  DeleteConfirmation,
  Drawer,
  Field,
  Form,
  FormMessage,
} from '~/components';
import ReadTextbox from '~/components/read-only/ReadTextbox';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import { useDirtyCheck, useDocumentTitle, useFeatures, useForm } from '~/hooks';
import { authProviders } from '~/lookups';
import { emptyStringToNull, mergeValues, slugify } from '~/utils';
import { slugValidator } from '~/utils/validators';

export default function AuthProviderForm({ onClose, onSaved, onDeleted }) {
  const { authProviderId } = useParams();
  const title = `${authProviderId ? 'Edit' : 'New'} Authentication Provider`;
  useDocumentTitle(title);

  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const [{ status, message, isSubmitting }, form] = useForm();
  const api = useApi();
  const { workspace } = useWorkspace();
  const features = useFeatures();
  const toast = useToast();
  const confirmation = useConfirmation();
  const [authProvider, setAuthProvider] = useState(null);

  useEffect(() => {
    if (!authProviderId) {
      setAuthProvider({});
      return;
    }

    (async function fetchData() {
      const { data } = await api.www.workspaces(workspace.id).authProviders(authProviderId).get();
      setAuthProvider(data);
    })();
  }, [api, authProviderId, workspace]);

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  const initialValues = mergeValues(
    {
      description: '',
      samlMetadataXml: '',
      key: '',
      method: '',
      name: '',
      provider: '',
    },
    authProvider,
  );

  if (!authProvider) return null;

  return (
    <Drawer
      isOpen
      title={title}
      byline="Authentication"
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={handleClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        async function handleSubmit(values) {
          try {
            form.submit();

            const body = emptyStringToNull(values);

            const { data } = await api.www.workspaces(workspace.id).authProviders(authProviderId).upsert(body);

            if (onSaved) {
              await onSaved(data);
            }
            form.done();
            closeDrawer();
          } catch ({ message }) {
            form.error({ message });
          }
        }

        async function handleDelete() {
          const confirm = await confirmation.prompt((resolve) => (
            <DeleteConfirmation resolve={resolve}>
              Are you sure that you want to delete this Authentication Provider?
            </DeleteConfirmation>
          ));
          if (!confirm) return;

          try {
            await api.www.workspaces(workspace.id).authProviders(authProviderId).delete();
            closeDrawer();
            onDeleted();
            return true;
          } catch ({ message }) {
            toast.error(message);
          }
        }

        return (
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              description: Yup.string().label('Description').max(5000),
              key: Yup.string()
                .label('Key')
                .max(255)
                .matches(slugValidator.expression, { message: slugValidator.message })
                .required(),
              name: Yup.string().label('Name').max(255).required(),
              provider: Yup.string().label('Provider').required(),
              method: Yup.string().label('Method').required(),
            })}>
            {(formik) => {
              const disabled = authProvider?.provider === 'ruddr';
              const methods = [{ value: 'saml', label: 'SAML' }];

              return (
                <Form>
                  {status && <FormMessage.Error>{message}</FormMessage.Error>}
                  <Form.Section
                    title="Configuration"
                    subtitle="Select the identity provider and the authentication method.">
                    <Form.Control>
                      {authProvider?.provider === 'ruddr' ? (
                        <ReadTextbox name="provider" label="Provider" value="Ruddr" disabled={disabled} />
                      ) : (
                        <Field.SingleSelect
                          autoFocus
                          name="provider"
                          placeholder="Provider"
                          onChange={({ target: { value } }) => {
                            const values = { ...formik.values, provider: value };

                            if (value) {
                              values.name = authProviders[value].name;
                              values.method = authProviders[value].method;
                              values.key = slugify(value);
                              values.description = authProviders[value].description;
                            }

                            formik.setValues(values);
                          }}>
                          {_(authProviders)
                            .filter((ap) => ap.id !== 'ruddr')
                            .filter((ap) => features.ripplingBeta || ap.id !== 'rippling')
                            .map(({ id, name }) => (
                              <option key={id} value={id}>
                                {name}
                              </option>
                            ))
                            .value()}
                        </Field.SingleSelect>
                      )}
                    </Form.Control>

                    <Form.Control>
                      {authProvider?.method === 'password' ? (
                        <ReadTextbox name="method" label="Method" value="Password" disabled={disabled} />
                      ) : (
                        <Field.SingleSelect name="method" placeholder="Method">
                          {methods.map(({ value, label }) => (
                            <option value={value} key={value}>
                              {label}
                            </option>
                          ))}
                        </Field.SingleSelect>
                      )}
                    </Form.Control>
                  </Form.Section>

                  <Form.Section
                    title="Details"
                    subtitle="Type the authentication provider name, unique key and description.">
                    <Form.Control>
                      <Field.Text name="name" placeholder="Name" maxLength={255} disabled={disabled} />
                    </Form.Control>

                    <Form.Control>
                      <Field.Text name="key" placeholder="Key" maxLength={255} disabled={disabled} />
                    </Form.Control>

                    <Form.Control>
                      <Field.TextArea
                        name="description"
                        placeholder="Description"
                        maxLength={5000}
                        disabled={disabled}
                      />
                    </Form.Control>
                  </Form.Section>

                  {formik.values.method === 'saml' && (
                    <Form.Section title="SAML" subtitle="Upload or copy/paste the identity provider metadata XML.">
                      <Form.Control>
                        <input
                          type="file"
                          onChange={(event) => {
                            const file = event.target.files?.[0];
                            if (!file) return;

                            const reader = new FileReader();
                            reader.onload = () => {
                              formik.setFieldValue('samlMetadataXml', reader.result);
                            };
                            reader.readAsText(event.target.files[0]);
                          }}
                          accept={['application/xml']}
                        />
                      </Form.Control>

                      <Form.Control>
                        <Field.TextArea
                          style={{ fontSize: '.75rem' }}
                          name="samlMetadataXml"
                          rows={30}
                          placeholder="Metadata XML"
                          disabled={disabled}
                        />
                      </Form.Control>
                    </Form.Section>
                  )}

                  <Drawer.Actions>
                    {authProvider && !disabled && <DeleteButton onClick={handleDelete}>Delete</DeleteButton>}

                    <Buttons align="right">
                      <CancelButton onClick={handleCloseClick}>Close</CancelButton>

                      <Button isLoading={isSubmitting} onClick={formik.submitForm}>
                        Save &amp; Close
                      </Button>
                    </Buttons>
                  </Drawer.Actions>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    </Drawer>
  );
}
