import Fuse from 'fuse.js';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Avatar, MemberContactPopover, MultiSelect } from '~/components';
import { useApi, useWorkspace } from '~/contexts';

export default function MemberFilter({ placeholder = 'Member', name, value, onChange, isActive, permission }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [data, setData] = useState(null);
  const [options, setOptions] = useState([]);

  const handleSearch = useCallback(
    async (q) => {
      let options = data;
      if (!data) {
        const result = await api.www.workspaces(workspace.id).members().options({ isActive, permission, size: 10000 });
        setData(result.data);
        options = result.data;
      }
      if (q) {
        options = new Fuse(options, { keys: ['name'], threshold: 0.4 }).search(q).map((result) => result.item);
      }
      setOptions(options);
    },
    [workspace.id, api, data, isActive, permission],
  );

  const groups = useMemo(() => {
    if (!options) return null;
    return _(options)
      .orderBy((o) => (value?.some((v) => v.id === o.id) ? 0 : o.isActive ? 1 : 2))
      .groupBy((o) => (value?.some((v) => v.id === o.id) ? 'Selected' : o.isActive ? 'Active' : 'Inactive'))
      .value();
  }, [options, value]);

  return (
    <MultiSelect.Filter
      icon="filter"
      placeholder={placeholder}
      name={name}
      value={value}
      renderValue={(value) => value.map((v) => v.name).join('; ')}
      options={options}
      renderOptions={(opts) => {
        const optionsById = _.keyBy(opts, 'id');
        return Object.keys(groups).map((status) => (
          <MultiSelect.Group key={status} label={status}>
            {groups[status].map((option) => (
              <MultiSelect.Option
                key={option.id}
                inactive={!option.isActive}
                style={{ display: 'flex' }}
                {...optionsById[option.id].props}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <MemberContactPopover member={option} placement="left">
                    <Avatar value={option} isCircle hasBackground initialsFontSize=".7rem" size="1.5rem" />
                  </MemberContactPopover>
                  <span style={{ marginLeft: '.5rem' }}>{option.name}</span>
                </div>
              </MultiSelect.Option>
            ))}
          </MultiSelect.Group>
        ));
      }}
      isLoading={!data}
      onSearch={handleSearch}
      onChange={onChange}
    />
  );
}
