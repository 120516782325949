import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import {
  EmploymentTypeFilter,
  ExportDialog,
  ExportDropdown,
  FiltersBar,
  IconButton,
  IconLink,
  JobTitleFilter,
  ListView,
  MemberFilter,
  MemberInvitationStatusFilter,
  MemberTagFilter,
  Page,
  PracticeFilter,
  SearchInput,
  SecurityRoleFilter,
  SingleSelectFilter,
  SkillFilter,
} from '~/components';
import DeactivateMemberConfirmation from '~/components/DeactivateMemberConfirmation';
import { useApi, useConfirmation, useToast, useWorkspace } from '~/contexts';
import {
  useActions,
  useDocumentTitle,
  useFeatures,
  useIsMounted,
  useSearchParams,
  useSearchParamsConfig,
} from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { QuerySort, dateFormats, mimeTypes } from '~/utils';
import DeleteMemberDialog from './DeleteMemberDialog';
import MemberInviteForm from './MemberInviteForm';
import MembersGrid from './MembersGrid';
import MembersList from './MembersList';

const initialState = {
  isReady: false,
  searchParamsStatus: 'pending',
  data: null,
  query: {
    q: '',
    isActive: 'true',
    invitationStatuses: [],
    securityRoles: [],
    employmentTypes: [],
    practices: [],
    skills: [],
    tags: [],
    jobTitles: [],
    managers: [],
    page: 0,
    sort: new QuerySort('member.name', 'asc'),
    size: 50,
    view: 'grid',
  },
  action: 'load',
  saved: false,
};

const handlers = {
  load: (values, state) => ({ query: { ...state.query, page: 0 }, action: 'load' }),
  loadMore: (values, state) => {
    if (state.action === null && state.data.total > state.data.results.length) {
      return { query: { ...state.query, page: state.query.page + 1 }, action: 'load-more' };
    }
  },
  filter: (query, state) => ({
    query: { ...state.query, ...query, page: 0 },
    action: 'filter',
    searchParamsStatus: 'ready',
  }),
  ready: ({ data }, state) => ({
    isReady: true,
    action: null,
    data: state.action === 'load-more' ? { ...state.data, results: [...state.data.results, ...data.results] } : data,
  }),
  updateItem: (item, { data }) => ({
    data: { ...data, results: data.results.map((i) => (i.id === item.id ? { ...i, ...item } : i)) },
  }),
  add: (value, state, actions) => {
    setTimeout(() => {
      actions.done();
    }, 1000);
    return { saved: true };
  },
  done: () => ({ saved: false }),
};

function MembersPage() {
  useDocumentTitle('Members');

  const { workspace } = useWorkspace();
  const features = useFeatures();

  const api = useApi();

  const [{ isReady: isDataReady, data, query, searchParamsStatus, action }, actions] = useActions(
    handlers,
    initialState,
  );
  const [isSlackInfoLoading, setIsSlackInfoLoading] = useState(true);
  const [isSlackConnected, setIsSlackConnected] = useState(false);
  const toast = useToast();
  const confirmation = useConfirmation();
  const isMounted = useIsMounted();

  // Filter persistency
  const searchParamsConfig = useSearchParamsConfig();
  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        q: { default: initialState.query.q },
        securityRoles: searchParamsConfig.securityRoles,
        employmentTypes: { ...searchParamsConfig.employmentTypes, default: null },
        isActive: {
          default: 'true',
          valid: ['true', 'false', 'all'],
          serialize: (value) => value || 'all',
          deserialize: (value) => (value === 'all' ? null : value),
        },
        skills: searchParamsConfig.skills,
        tags: searchParamsConfig.memberTags,
        practices: searchParamsConfig.practices,
        invitationStatuses: searchParamsConfig.memberInvitationStatuses,
        jobTitles: searchParamsConfig.jobTitles,
        managers: searchParamsConfig.members,
        view: { default: 'grid', valid: ['grid', 'list'] },
        sort: { default: initialState.query.sort, ...searchParamsConfig.sort },
      }),
      [searchParamsConfig],
    ),
    sessionKey: 'members_list',
    onChange: useCallback((params) => actions.filter(params), [actions]),
  });

  useEffect(() => {
    if (searchParamsStatus !== 'pending') return;
    searchParams.get().then((params) => {
      if (params) actions.filter(params);
    });
  }, [searchParams, searchParamsStatus, actions]);

  const history = useHistory();
  const location = useLocation();
  const { url, path } = useRouteMatch();

  const isReady = useMemo(() => isDataReady && !isSlackInfoLoading, [isDataReady, isSlackInfoLoading]);

  const urlSearchParams = useMemo(() => {
    return {
      q: query.q,
      securityRoleId: query.securityRoles?.map((v) => v.id),
      managerId: query.managers?.map((v) => v.id),
      employmentTypeId: query.employmentTypes?.map((v) => v.id),
      invitationStatusId: query.invitationStatuses?.map((v) => v.id),
      isActive: query.isActive ?? undefined,
      skillIds: query.skills?.map((v) => v.id),
      tagIds: query.tags?.map((v) => v.id),
      jobTitleId: query.jobTitles?.map((v) => v.id),
      practiceId: query.practices?.map((v) => v.id),
      sort: query.sort,
    };
  }, [query]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .members()
        .list({ ...urlSearchParams, page: query.page, size: 50 });

      actions.ready({ data });
    } catch (error) {
      actions.ready({ data: [] });
    }
  }, [actions, workspace.id, urlSearchParams, api, query.page]);

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  useEffect(() => {
    (async () => {
      setIsSlackInfoLoading(true);

      const { data: isConnected } = await api.www.workspaces(workspace.id).integrations().slack.getConnected();

      setIsSlackInfoLoading(false);
      setIsSlackConnected(isConnected);
    })();
  }, [api, workspace.id, isMounted]);

  const handleFilter = ({ target: { name, value } }) => {
    actions.filter({ ...query, [name]: value });
    searchParams.set({ [name]: value });
  };

  async function handleActiveStatusChange(member, isActive) {
    if (!isActive) {
      const confirm = await confirmation.prompt((resolve) => (
        <DeactivateMemberConfirmation memberId={member.id} workspaceId={workspace.id} resolve={resolve} />
      ));
      if (!confirm) return;
    }

    let activeEndDate = null;
    if (!isActive) {
      if (member.activeStartDate) {
        activeEndDate = moment.max(moment(member.activeStartDate), moment()).format(dateFormats.isoDate);
      } else {
        activeEndDate = moment().format(dateFormats.isoDate);
      }
    }

    try {
      const { data } = await api.www.workspaces(workspace.id).members(member.id).setActiveStatus({
        isActive,
        activeStartDate: member.activeStartDate,
        activeEndDate,
      });
      actions.updateItem(data);
    } catch ({ message }) {
      toast.error(message);
    }
  }

  async function handleRemove(member) {
    confirmation.prompt((resolve) => (
      <DeleteMemberDialog
        onCheckDependencies={async (workspace) => (await workspace.members(member.id).getAssociationTypes()).data}
        member={member}
        onClose={resolve}
        onDelete={async () => {
          fetchData();
          resolve(true);
        }}
      />
    ));
  }

  async function handleInviteSuccess() {
    actions.add();
    await fetchData();
  }

  async function handleSendInvitation(member) {
    try {
      await api.www.workspaces(workspace.id).members(member.id).sendInvitation();
      const { data } = await api.www.workspaces(workspace.id).members().get({ ids: member.id });
      actions.updateItem(data[0]);
      toast.success(`Successfully sent the invitation to join ${workspace.name} to ${member.name}.`);
    } catch (error) {
      toast.error(error?.message || `An error has occurred sending the invitation to ${member.name}.`);
    }
  }

  const handleSort = ({ column, sort }) => {
    const direction = column === sort.column && sort.direction === 'asc' ? 'desc' : 'asc';
    const querySort = new QuerySort(column, direction);
    actions.filter({ ...query, sort: querySort });
    searchParams.set({ sort: querySort });
  };

  function handleMemberClick(member) {
    history.push(`${url}/details/${member.id}`);
  }

  const handleViewChange = (view) => {
    const querySort = new QuerySort('member.name', 'asc');
    actions.filter({ ...query, sort: querySort, view });
    searchParams.set({ sort: null, view });
  };

  const handleExport = async (filename, mimeType) => {
    await confirmation.prompt((resolve) => (
      <ExportDialog
        filename={filename}
        onLoad={api.www
          .workspaces(workspace.id)
          .members()
          .export(
            { ...urlSearchParams, size: null },
            {
              headers: { accept: mimeType },
              responseType: 'blob',
            },
          )}
        onClose={resolve}
      />
    ));
  };

  if (!isReady || !data) return <PageLoader />;
  return (
    <Page scrollable>
      <Page.Header>
        <Page.Info>
          <Page.Eyebrow>Settings</Page.Eyebrow>
          <Page.Title>Members</Page.Title>
        </Page.Info>

        <Page.Actions>
          {
            {
              list: (
                <IconButton
                  tooltip="Grid View"
                  icon="grid-2"
                  data-testid="grid-button"
                  onClick={() => handleViewChange('grid')}
                />
              ),
              grid: (
                <IconButton
                  tooltip="List View"
                  icon="table-rows"
                  data-testid="list-button"
                  onClick={() => handleViewChange('list')}
                />
              ),
            }[query.view]
          }

          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(`members.csv`, mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await handleExport(`members.xlsx`, mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>

          <IconLink tooltip="Add Member" icon="plus" to={{ pathname: `${url}/new`, search: location.search }} />
        </Page.Actions>
      </Page.Header>

      <Page.Filters>
        <FiltersBar>
          <SearchInput value={query.q} placeholder="Search" onChange={handleFilter} />

          <SecurityRoleFilter name="securityRoles" value={query.securityRoles} onChange={handleFilter} />

          <MemberFilter name="managers" placeholder="Manager" value={query.managers} onChange={handleFilter} />

          <EmploymentTypeFilter name="employmentTypes" value={query.employmentTypes} onChange={handleFilter} />

          <MemberInvitationStatusFilter
            name="invitationStatuses"
            value={query.invitationStatuses}
            onChange={handleFilter}
          />

          <SingleSelectFilter
            icon="filter"
            name="isActive"
            value={query.isActive}
            placeholder="Status"
            options={[
              { id: 'true', name: 'Active' },
              { id: 'false', name: 'Inactive' },
            ]}
            onChange={handleFilter}
          />

          <SkillFilter name="skills" value={query.skills} onChange={handleFilter} />

          <MemberTagFilter name="tags" value={query.tags} onChange={handleFilter} />

          <JobTitleFilter name="jobTitles" value={query.jobTitles} onChange={handleFilter} />

          {features.practices && (
            <PracticeFilter
              name="practices"
              placeholder="Member Practice"
              value={query.practices}
              onChange={handleFilter}
            />
          )}
        </FiltersBar>
      </Page.Filters>

      {query.view === 'grid' && (
        <>
          <Page.Section>
            <MembersGrid
              data={data}
              action={action}
              isSlackConnected={isSlackConnected}
              onLoadMore={actions.loadMore}
              onCardClick={handleMemberClick}
              onSendInvitation={handleSendInvitation}
              onActiveStatusChange={handleActiveStatusChange}
              onRemove={handleRemove}
            />

            <ListView.Status total={data.total} label="Member" isLoading={!!action} />
          </Page.Section>
        </>
      )}

      {query.view === 'list' && (
        <MembersList
          data={data}
          action={action}
          query={query}
          isSlackConnected={isSlackConnected}
          onLoadMore={actions.loadMore}
          onRowClick={handleMemberClick}
          onSendInvitation={handleSendInvitation}
          onActiveStatusChange={handleActiveStatusChange}
          onRemove={handleRemove}
          onSort={handleSort}
        />
      )}

      <Route path={`${path}/new`}>
        <MemberInviteForm
          onClose={() => history.push({ pathname: url, search: location.search })}
          onInvited={handleInviteSuccess}
        />
      </Route>
    </Page>
  );
}

export default MembersPage;
