import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Confirmation, CreateButton, Files, Page } from '~/components';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { useDocumentTitle } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import FilePreview from './FilePreview';
import MemberFilesDrawer from './MemberFilesDrawer';

export default function MemberFilesTab({ member }) {
  useDocumentTitle(`${member.name} Files`);

  const api = useApi();
  const { workspace } = useWorkspace();

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [files, setFiles] = useState([]);

  const hasFiles = useMemo(() => files?.length > 0, [files]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setHasError(false);
    try {
      const { data } = await api.www.workspaces(workspace.id).members(member.id).files().get();
      setFiles(data);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [api, workspace.id, member.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const confirmation = useConfirmation();
  async function handleDeleteConfirmation(file) {
    const confirm = await confirmation.prompt((resolve) => (
      <Confirmation resolve={resolve}>Are you sure you want to delete this file?</Confirmation>
    ));

    if (!confirm) return;

    await api.www.workspaces(workspace.id).members(member.id).files(file.id).delete();
    await fetchData();
  }

  return (
    <>
      <Page.TabHeader>
        <Page.Title>Files</Page.Title>

        <Page.Actions>
          <CreateButton tooltip="Add Files" onClick={() => setShowDrawer(true)} />
        </Page.Actions>
      </Page.TabHeader>

      {isLoading ? (
        <PageLoader />
      ) : hasError ? (
        <Files.ErrorContainer>
          <Files.ErrorMessage>
            <Files.WarningIcon icon="exclamation-triangle" />
            There was a problem getting the member files
          </Files.ErrorMessage>
          <Button onClick={() => fetchData()}>Retry</Button>
        </Files.ErrorContainer>
      ) : (
        <div>
          {hasFiles ? (
            <>
              <Files>
                {_(files)
                  .map((file) => (
                    <FilePreview key={file.id} file={file} onRemove={() => handleDeleteConfirmation(file)} />
                  ))
                  .value()}
              </Files>
            </>
          ) : (
            <>
              <Files.NoFilesMessage>No Files have been uploaded.</Files.NoFilesMessage>
            </>
          )}
        </div>
      )}
      {showDrawer && (
        <MemberFilesDrawer member={member} onClose={() => setShowDrawer(false)} onChanged={() => fetchData()} />
      )}
    </>
  );
}
