import _ from 'lodash';
import React, { useMemo } from 'react';
import {
  BusinessUnitFilter,
  ClientFilter,
  ClientRecordStatusFilter,
  ClientTagFilter,
  IndustryFilter,
  LocationFilter,
  MemberFilter,
  PracticeFilter,
} from '~/components';
import { useFeatures } from '~/hooks';

function ClientFiltersBar({ filters, sticky, onChange }) {
  const features = useFeatures();

  const visible = useMemo(() => {
    const isVisible = (key) => sticky?.includes(key) || !_.isEmpty(filters[key]);

    return {
      clients: isVisible('clients'),
      clientRecordStatusId: isVisible('clientRecordStatusId'),
      clientPractices: features.practices && isVisible('clientPractices'),
      clientBusinessUnits: features.businessUnits && isVisible('clientBusinessUnits'),
      clientSalesRepresentatives: isVisible('clientSalesRepresentatives'),
      clientOwners: isVisible('clientOwners'),
      clientTags: isVisible('clientTags'),
      clientLocations: isVisible('clientLocations'),
      clientIndustries: isVisible('clientIndustries'),
    };
  }, [filters, sticky, features]);

  return (
    <>
      {visible.clients && (
        <ClientFilter value={filters.clients} onChange={({ value }) => onChange({ clients: value })} />
      )}

      {visible.clientRecordStatusId && (
        <ClientRecordStatusFilter
          value={filters.clientRecordStatusId}
          onChange={({ value }) => onChange({ clientRecordStatusId: value })}
        />
      )}

      {visible.clientPractices && (
        <PracticeFilter
          placeholder="Client Practice"
          value={filters.clientPractices}
          onChange={({ value }) => onChange({ clientPractices: value })}
        />
      )}

      {visible.clientBusinessUnits && (
        <BusinessUnitFilter
          placeholder="Client Business Unit"
          value={filters.clientBusinessUnits}
          onChange={({ value }) => onChange({ clientBusinessUnits: value })}
        />
      )}

      {visible.clientSalesRepresentatives && (
        <MemberFilter
          placeholder="Client Sales Representative"
          value={filters.clientSalesRepresentatives}
          onChange={({ value }) => onChange({ clientSalesRepresentatives: value })}
        />
      )}

      {visible.clientOwners && (
        <MemberFilter
          placeholder="Client Relationship Owner"
          value={filters.clientOwners}
          onChange={({ value }) => onChange({ clientOwners: value })}
        />
      )}

      {visible.clientTags && (
        <ClientTagFilter value={filters.clientTags} onChange={({ value }) => onChange({ clientTags: value })} />
      )}

      {visible.clientLocations && (
        <LocationFilter
          placeholder="Client Location"
          value={filters.clientLocations}
          onChange={({ value }) => onChange({ clientLocations: value })}
        />
      )}

      {visible.clientIndustries && (
        <IndustryFilter
          placeholder="Client Industry"
          value={filters.clientIndustries}
          onChange={({ value }) => onChange({ clientIndustries: value })}
        />
      )}
    </>
  );
}

export default ClientFiltersBar;
