import { BillableIcon, Currency, ExpenseApprovalPopover, Icon, Tooltip, MarkupIcon } from '~/components';
import React from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

const ItemAmount = styled.div`
  padding: 0.25rem 1rem 0.25rem 1rem;
  min-width: 7rem;
  border-radius: 0rem 1.5rem 1.5rem 0rem;
  border-left: 0.125rem solid ${colors.white};
  background-color: ${({ status }) =>
    ({
      not_submitted: colors.grey5,
      pending_approval: colors.warning10,
      rejected: colors.danger10,
    })[status] || colors.primary10};

  font-weight: ${weights.medium};
  text-align: right;
  color: ${({ status }) =>
    ({
      not_submitted: colors.grey100,
      pending_approval: colors.warning100,
      rejected: colors.danger,
    })[status] || colors.primary};
`;
const ItemFinances = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${colors.grey5};
  border-radius: 1.5rem 1.5rem 1.5rem 1.5rem;
`;

const ItemReimbursable = styled.div`
  color: ${({ isReimbursed, isReimbursable }) =>
    isReimbursed ? colors.green : isReimbursable ? colors.black : colors.grey25};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem 0 0rem;
`;

const ItemBillable = styled.div`
  color: ${({ isBillable }) => (isBillable ? colors.green : colors.grey55)};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0rem 0 0.5rem;
`;

const ItemReceiptLoaded = styled.div`
  color: ${({ hasReceipts }) => (hasReceipts ? colors.black : colors.grey25)};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0rem 0 0.7rem;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

function ExpenseAmountWidget({ item, showApprovalActions = false, onApprovalChange }) {
  const { isReimbursable, expenseAmount, isBillable, hasReceipts, isReimbursed, markup, total } = item;

  const currency = item.project?.currency;

  return (
    <ItemFinances>
      <ItemReceiptLoaded hasReceipts={hasReceipts}>
        <Tooltip Container={IconContainer} message={hasReceipts ? 'Receipt uploaded' : 'No receipt'}>
          <Icon icon="receipt" />
        </Tooltip>
      </ItemReceiptLoaded>

      <ItemBillable>
        {markup > 0 && isBillable ? (
          <MarkupIcon total={total} currency={currency} />
        ) : (
          <BillableIcon value={isBillable} />
        )}
      </ItemBillable>

      <ItemReimbursable isReimbursed={isReimbursed} isReimbursable={isReimbursable}>
        <Tooltip
          Container={IconContainer}
          message={isReimbursed ? 'Reimbursed' : isReimbursable ? 'Reimbursable' : 'Non-reimbursable'}>
          <Icon icon="repeat" />
        </Tooltip>
      </ItemReimbursable>

      <ExpenseApprovalPopover expenseItemId={item.id} showActions={showApprovalActions} onChange={onApprovalChange}>
        <ItemAmount data-testid="expense_amount" status={item.statusId}>
          <Currency value={expenseAmount} currency={currency} />
        </ItemAmount>
      </ExpenseApprovalPopover>
    </ItemFinances>
  );
}
export default ExpenseAmountWidget;
export { ExpenseAmountWidget };
