import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  FiltersBar,
  IconLink,
  OpportunityStageCategoryFilter,
  OpportunityStageFilter,
  Page,
  SearchInput,
} from '~/components';
import OpportunitiesListPage from '../pipeline/opportunities/OpportunitiesListPage';

const Filters = ({ params, onFilter }) => {
  const { url } = useRouteMatch();

  const handleChange = ({ target: { name, value } }) => {
    onFilter({ [name]: value });
  };

  return (
    <>
      <Page.TabHeader>
        <Page.Title>Opportunities</Page.Title>

        <Page.Actions>
          <IconLink tooltip="New Opportunity" icon="plus" to={`${url}/new`} />
        </Page.Actions>
      </Page.TabHeader>

      <Page.Filters>
        <FiltersBar>
          <SearchInput value={params.q} placeholder="Search" onChange={handleChange} />

          <OpportunityStageCategoryFilter
            name="opportunityStageStatuses"
            value={params.opportunityStageStatuses}
            onChange={handleChange}
          />

          <OpportunityStageFilter
            name="opportunityStages"
            value={params.opportunityStages}
            opportunityStageStatusId={params.opportunityStageStatuses.map((status) => status.id)}
            onChange={handleChange}
          />
        </FiltersBar>
      </Page.Filters>
    </>
  );
};

function CompanyOpportunitiesTab({ companyId }) {
  return <OpportunitiesListPage mode="tab" companyId={companyId} renderFilters={(props) => <Filters {...props} />} />;
}

export default CompanyOpportunitiesTab;
