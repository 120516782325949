import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  ClientLink,
  CloudFolderLink,
  CompanyLink,
  Currency,
  DateTime,
  MultilineText,
  OpportunityLink,
  Panel,
  Pill,
  Pills,
  ProjectHealthCircle,
  ProjectHealthCircles,
} from '~/components';
import { useApi, useIntegrations, useWorkspace } from '~/contexts';
import revenueAttributionMethods from '~/lookups/revenue-attribution-methods';
import revenueRecognitionMethods from '~/lookups/revenue-recognition-methods';
import SlackChannel from './components/SlackChannel';

export default function ProjectDetails({ project }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const integrations = useIntegrations();

  const [client, setClient] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.www.workspaces(workspace.id).projects(project.id).client();
        setClient(data);
      } catch (err) {
        // Do nothing
      }
    })();
  }, [api, workspace.id, project]);

  const currency = project.currency;

  return (
    <Panel>
      <Panel.Title>Project Details</Panel.Title>

      <Panel.Info title="Project Admin">
        {project.administrators.map((admin) => (
          <Panel.Member key={admin.id} member={admin.member} />
        ))}
      </Panel.Info>

      {project.businessUnit && <Panel.Info title="Business Unit">{project.businessUnit.name}</Panel.Info>}

      {project.salesRepresentative && (
        <Panel.Info title="Sales Representative">
          <Panel.Member member={project.salesRepresentative} />
        </Panel.Info>
      )}

      {project.recordStatusId === 'archived' && (
        <Panel.Info title="Active Status">{project.recordStatus.name}</Panel.Info>
      )}

      <Panel.Info title="Currency">
        {project.currencyName} ({project.currency})
      </Panel.Info>

      <Panel.Info title="Billing Type">{project.billingType.name}</Panel.Info>

      {['fixed', 'fixed_recurring'].includes(project.billingTypeId) && (
        <Panel.Info title="Revenue Recognition Method">
          {revenueRecognitionMethods[project.revenueRecognitionMethod].name}
        </Panel.Info>
      )}

      {['fixed', 'fixed_recurring'].includes(project.billingTypeId) && (
        <Panel.Info title="Revenue Attribution Method">
          {revenueAttributionMethods[project.revenueAttributionMethod].name}
        </Panel.Info>
      )}

      {project.isBillable && project.useBudget && project.permissions.viewRevenue && (
        <Panel.Info title="Total Revenue Budget">
          <Currency value={project.budgetRevenue} currency={currency} />
        </Panel.Info>
      )}

      {project.isBillable && project.useMonthlyBudget && project.permissions.viewRevenue && (
        <Panel.Info title="Monthly Budget">
          <Currency value={project.monthlyBudgetRevenue} currency={currency} /> per month
        </Panel.Info>
      )}

      {project.projectType && <Panel.Info title="Project Type">{project.projectType.name}</Panel.Info>}

      {project.practice && <Panel.Info title="Project Practice">{project.practice.name}</Panel.Info>}

      {project?.tags?.length > 0 && (
        <Panel.Info title="Tags">
          <Pills>
            {project.tags.map((tag) => (
              <Link key={tag.id} to={`/app/${workspace.key}/projects?projectTag=${tag.id}`}>
                <Pill variant="grey10">{tag.name}</Pill>
              </Link>
            ))}
          </Pills>
        </Panel.Info>
      )}

      {project.code && <Panel.Info title="Project Code">{project.code}</Panel.Info>}

      {project.latestHealthReport && project.permissions.edit && project.useHealthReports && (
        <Panel.Info title="Project Health">
          <ProjectHealthCircles>
            <ProjectHealthCircle value={project.latestHealthReport.budgetHealth} />
            <ProjectHealthCircle value={project.latestHealthReport.scheduleHealth} />
            <ProjectHealthCircle value={project.latestHealthReport.clientSatisfaction} />
            <ProjectHealthCircle value={project.latestHealthReport.teamSatisfaction} />
          </ProjectHealthCircles>
        </Panel.Info>
      )}

      {(project.start || project.end) && (
        <Panel.Info title="Project Time Frame">
          <ProjectDates project={project} />
        </Panel.Info>
      )}

      <Panel.Info title="Project Status">{project.status.name}</Panel.Info>

      {project.statusId === 'completed' && (
        <Panel.Info title="Completed On">
          <DateTime value={project.completedOn} />
        </Panel.Info>
      )}

      {project.poNumber && <Panel.Info title="PO Number">{project.poNumber}</Panel.Info>}

      {project?.cloudFolderUrl && (
        <Panel.Info title="Cloud Folder">
          <CloudFolderLink url={project.cloudFolderUrl} />
        </Panel.Info>
      )}

      {project.slackChannelId && (
        <Panel.Info title="Slack Channel">
          <SlackChannel project={project} />
        </Panel.Info>
      )}

      {project.description && (
        <Panel.Info title="Notes">
          <MultilineText value={project.description} chars={280} buttonProps={{ isAnchor: true }} />
        </Panel.Info>
      )}

      {project.opportunity && (integrations.salesforce || integrations.hubspot) && (
        <Panel.Info title="Opportunity">
          <OpportunityLink opportunity={project.opportunity} excludeProject />
        </Panel.Info>
      )}

      <Panel.Separator />

      <Panel.Title>Client Details</Panel.Title>

      <Panel.Info title="Client Name">
        <ClientLink client={project.client} />
      </Panel.Info>

      {client && (
        <>
          {client.owner && (
            <Panel.Info title="Relationship Owner">
              <Panel.Member member={client.owner} />
            </Panel.Info>
          )}

          {client.salesRepresentative && (
            <Panel.Info title="Sales Representative">
              <Panel.Member member={client.salesRepresentative} />
            </Panel.Info>
          )}

          {project?.client.company && (
            <Panel.Info title={'Company'}>
              <CompanyLink company={project.client.company} />
            </Panel.Info>
          )}

          {client.industry && <Panel.Info title="Client Industry">{client.industry.name}</Panel.Info>}

          {client.practice && <Panel.Info title="Client Practice">{client.practice.name}</Panel.Info>}

          {client.tags?.length > 0 && (
            <Panel.Info title="Tags">
              <Pills>
                {client.tags.map((tag) => (
                  <Link key={tag.id} to={`/app/${workspace.key}/clients?clientTag=${tag.id}`}>
                    <Pill variant="grey10">{tag.name}</Pill>
                  </Link>
                ))}
              </Pills>
            </Panel.Info>
          )}

          {client.location && <Panel.Info title="Client Location">{client.location.name}</Panel.Info>}

          {client.contacts.length > 0 && (
            <Panel.Info title="Contacts">
              {client.contacts.map((contact) => (
                <Panel.Contact key={contact.id} contact={contact} />
              ))}
            </Panel.Info>
          )}

          {client.cloudFolderUrl && (
            <Panel.Info title="Cloud Folder">
              <CloudFolderLink url={client.cloudFolderUrl} />
            </Panel.Info>
          )}

          {client.notes && (
            <Panel.Info title="Notes">
              <MultilineText value={client.notes} chars={280} buttonProps={{ isAnchor: true }} />
            </Panel.Info>
          )}
        </>
      )}
    </Panel>
  );
}

const ProjectDatesContainer = styled.div`
  flex: 1;
`;

function ProjectDates({ project: { start, end } }) {
  return (
    <ProjectDatesContainer>
      {start && end ? (
        <p>
          <DateTime value={start} /> - <DateTime value={end} />
        </p>
      ) : start ? (
        <p>
          {moment(start).isBefore() ? 'Started' : 'Starts'} <DateTime value={start} />
        </p>
      ) : end ? (
        <p>
          {moment(end).isBefore() ? 'Ended' : 'Ends'} <DateTime value={end} />
        </p>
      ) : null}
    </ProjectDatesContainer>
  );
}
