import _ from 'lodash';
import { darken, rgba } from 'polished';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';
import Button from '../Button';
import Icon from '../Icon';
import Input from '../Input';
import Tooltip from '../Tooltip';

const Text = styled.div`
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 0 0 0.875rem;
  font-size: 0.75rem;
  color: ${colors.grey75};
`;

const Placeholder = styled.span`
  color: ${colors.grey40};
`;

const Box = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  color: ${colors.black};
  background-color: transparent;
  border: 1px solid ${colors.grey10};
  border-radius: 0.3125rem;
  user-select: none;

  ${({ $hasValue }) =>
    $hasValue &&
    css`
      border-color: ${colors.grey40};
    `}
`;

const Control = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 2rem;
  outline: none;
  box-shadow: none;
  cursor: pointer;

  &:focus {
    ${Box} {
      border: 1px solid ${colors.primary};
      border-radius: 0.3125rem;
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${Box} {
        border: 1px solid ${colors.primary};
        border-radius: 0.3125rem;
      }
    `}
`;

const Status = styled.div`
  font-size: 0.75rem;
  display: flex;
  gap: 0.125rem;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: 0.125rem;
  margin-right: 0.25rem;

  .icon {
    width: 1rem;
  }
`;

const ClearIndicator = styled(Button)`
  height: 100%;
  color: ${colors.primary};

  &:hover {
    color: ${darken(0.1, colors.primary)};
  }
`;

const Container = styled.div`
  background-color: ${colors.white};
  border-radius: 0.3125rem;
  box-shadow: 0 0.25rem 1rem -0.125em ${rgba(colors.black, 0.15)};
  z-index: 3;
  user-select: none;
  outline: none;
`;

const Filter = styled.div`
  padding: 0.5rem 0.875rem;
  background-color: ${colors.grey5};
  border-bottom: solid 1px ${colors.grey10};
  width: 100%;
`;

const Group = styled.div`
  margin-bottom: 0.25rem;
  margin-left: 0.875rem;
  overflow: hidden;
`;

const Options = styled.div`
  overflow: auto;
  max-height: 15rem;
  padding: 0.5rem 0;
  outline: none;

  > ${Group} {
    margin-left: 0;
  }
`;

const NoOptions = styled.div`
  padding: 1rem;
  color: ${colors.grey55};
  text-align: left;
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25rem 0.875rem;
  cursor: pointer;
  outline: none;

  &:hover,
  &:focus {
    background-color: ${colors.grey5};
  }

  ${({ inactive }) =>
    inactive &&
    css`
      color: ${colors.grey40};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${colors.grey25};
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

const OptionIndicator = styled.div`
  flex-shrink: 0;
  display: flex;
  width: 0.75rem;
  margin-right: 0.5rem;
  color: ${colors.grey40};
  font-size: 0.75rem;
`;

const OptionValue = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Option = React.forwardRef(({ isSelected, children, tooltip = children, ...props }, ref) => (
  <OptionContainer role="menuitemradio" aria-checked={isSelected} {...props} ref={ref}>
    <OptionIndicator>{!!isSelected && <Icon icon="check" />}</OptionIndicator>
    <Tooltip
      message={tooltip}
      delay={1000}
      placement="bottom-start"
      style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
      <OptionValue>{children}</OptionValue>
    </Tooltip>
  </OptionContainer>
));

const GroupLabel = styled.div`
  padding: 0.25rem 0.875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: ${weights.bold};
`;

function SelectGroup({ label, children }) {
  return (
    <Group>
      <Tooltip message={label} delay={1000} placement="bottom-start">
        <GroupLabel>{label}</GroupLabel>
      </Tooltip>
      {children}
    </Group>
  );
}

function Results({ autoFocus, value, options, renderOptions, onToggleOption }) {
  const optionsRef = useRef(null);

  useEffect(() => {
    if (autoFocus && optionsRef.current) {
      const firstTabbable = optionsRef.current.querySelector('[tabindex="0"]');
      if (firstTabbable) {
        firstTabbable.focus();
      }
    }
  }, [autoFocus]);

  if (!options?.length > 0) {
    return <NoOptions>No results</NoOptions>;
  }

  const handleKeyDown = (e, o) => {
    switch (e.key) {
      case 'ArrowUp': {
        e.stopPropagation();
        e.preventDefault();

        const allOptions = optionsRef.current.querySelectorAll('[tabindex="0"]');
        const currentIndex = Array.prototype.indexOf.call(allOptions, e.target);
        const previousIndex = currentIndex > 0 ? currentIndex - 1 : allOptions.length - 1;
        allOptions[previousIndex]?.focus();
        break;
      }

      case 'ArrowDown': {
        e.stopPropagation();
        e.preventDefault();

        const allOptions = optionsRef.current.querySelectorAll('[tabindex="0"]');
        const currentIndex = Array.prototype.indexOf.call(allOptions, e.target);
        const nextIndex = currentIndex < allOptions.length - 1 ? currentIndex + 1 : 0;
        allOptions[nextIndex]?.focus();
        break;
      }

      case 'Enter':
      case ' ':
        onToggleOption(o);
        e.stopPropagation();
        e.preventDefault();
        break;
    }
  };

  if (_.isFunction(renderOptions)) {
    return (
      <Options ref={optionsRef}>
        {renderOptions(
          options.map((o) => ({
            ...o,
            props: {
              tabIndex: 0,
              isSelected: _.isEqual(value, o),
              onClick: () => onToggleOption(o),
              onKeyDown: (e) => handleKeyDown(e, o),
            },
          })),
        )}
      </Options>
    );
  }

  return (
    <Options ref={optionsRef}>
      {options.map((option) => {
        const isSelected = _.isEqual(value, option);
        return (
          <Option
            key={option.id}
            tabIndex={0}
            isSelected={isSelected}
            onKeyDown={(e) => handleKeyDown(e, option)}
            onClick={() => onToggleOption(option)}>
            {option.name}
          </Option>
        );
      })}
    </Options>
  );
}

const MaterialPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0.625rem; /* input padding - placeholder padding */
  margin-left: 1px; /* offsets the 1px input border */
  padding: 0 0.25rem;
  color: ${colors.grey40};
  font-size: 0.75rem;
  background-color: ${colors.white};
  border-radius: 0.3125rem;
  transform: translateY(-50%);
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  transition: opacity 100ms;
  pointer-events: none;
`;

const Loading = styled.div`
  position: absolute;
  top: 50%;
  right: 2.5rem;
  transform: translateY(-50%);
  color: ${colors.primary};
`;

function Select({ value, options, isLoading, renderOptions, onApply, onSearch }) {
  const [searchValue, setSearchValue] = useState('');
  const handleSearchChange = ({ target: { value } }) => {
    setSearchValue(value);
    onSearch(value);
  };

  const handleToggleOption = (option) => {
    onApply(option);
  };

  return (
    <Container onKeyDown={(e) => e.stopPropagation()}>
      {_.isFunction(onSearch) && (
        <Filter>
          <Input autoFocus type="search" wait={300} value={searchValue} onChange={handleSearchChange} />
          {isLoading && (
            <Loading>
              <Icon icon="spinner" spin={true} />
            </Loading>
          )}
        </Filter>
      )}

      {!isLoading && (
        <>
          <Results
            autoFocus={!_.isFunction(onSearch)}
            value={value}
            options={options}
            isLoading={!options}
            renderOptions={renderOptions}
            onToggleOption={handleToggleOption}
          />
        </>
      )}
    </Container>
  );
}

function SingleSelectFilter({
  placeholder,
  materialPlaceholder = true,
  materialAlwaysVisible = false,
  name,
  renderValue,
  defaultIsOpen = false,
  isLoading,
  tabIndex = '0',
  clearable = true,
  options,
  value,
  icon = 'angle-down',
  onChange,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const handleOpen = (flag) => {
    setIsOpen(flag);
    if (flag && _.isFunction(props.onSearch)) props.onSearch();
  };
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    strategy: 'absolute',
    modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
  });

  const handleApply = (selectedValue) => {
    const id = selectedValue?.id ?? null;
    if (!_.isEqual(id, value)) {
      onChange({ name, value: id, target: { name, value: id } });
    }
    setIsOpen(false);
  };

  const handleClear = (event) => {
    event.stopPropagation();
    event.preventDefault();
    handleApply(null);
    setIsOpen(false);
  };

  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (referenceElement?.contains(event.target) || popperElement?.contains(event.target)) {
        return;
      }

      setIsOpen(false);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [referenceElement, popperElement]);

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'Escape':
      case 'Tab':
        setIsOpen(false);
        break;

      case 'Enter':
      case ' ':
        handleOpen(!isOpen);
        break;
    }
  };

  const selectedValue = _.isObject(value) ? value : options.find((option) => option.id === value) ?? null;
  const hasValue = !!selectedValue;

  const text = useMemo(() => {
    if (!selectedValue) return null;
    if (renderValue) return renderValue(selectedValue);
    return selectedValue.name;
  }, [selectedValue, renderValue]);

  const materialPlaceholderValue = useMemo(() => {
    if (!materialPlaceholder) {
      return '';
    } else if (_.isString(materialPlaceholder)) {
      return materialPlaceholder;
    } else if (_.isString(placeholder) && !!placeholder) {
      return placeholder;
    }
    return '';
  }, [placeholder, materialPlaceholder]);

  return (
    <Control
      ref={setReferenceElement}
      isOpen={isOpen}
      tabIndex={tabIndex}
      onClick={() => handleOpen(!isOpen)}
      onKeyDown={handleKeyDown}>
      <Box $hasValue={hasValue}>
        <Text>{hasValue ? text : <Placeholder>{placeholder}</Placeholder>}</Text>

        <Status>
          {isOpen && isLoading ? (
            <Icon icon="spinner" spin color={colors.primary} />
          ) : hasValue && clearable ? (
            <ClearIndicator onMouseDown={(e) => e.stopPropagation()} isAnchor tabIndex={-1} onClick={handleClear}>
              <Icon data-testid="clear_indicator" icon="times" />
            </ClearIndicator>
          ) : null}

          <Icon icon={icon} color={colors.grey20} />
        </Status>
      </Box>

      {!!materialPlaceholderValue && (
        <MaterialPlaceholder isVisible={materialAlwaysVisible || !!text}>
          {materialPlaceholderValue}
        </MaterialPlaceholder>
      )}

      {isOpen && !isLoading && (
        <div
          ref={setPopperElement}
          style={{
            ...styles.popper,
            minWidth: Math.max(referenceElement?.scrollWidth, 300),
            maxWidth: '100%',
            zIndex: 110,
          }}
          onClick={(e) => e.stopPropagation()}
          {...attributes.popper}>
          <Select value={selectedValue} options={options} onApply={handleApply} {...props} />
        </div>
      )}
    </Control>
  );
}

SingleSelectFilter.Option = Option;
SingleSelectFilter.Group = SelectGroup;

export default SingleSelectFilter;
