import { useWorkspace } from '~/contexts';
import React from 'react';
import RouteLink from './RouteLink';
import { OpportunityPopover } from '~/components';

function OpportunityLink({ opportunity, excludeProject = false, children, ...props }) {
  const { workspace } = useWorkspace();
  const url = `/app/${workspace.key}/pipeline/opportunities/${opportunity.id}/overview`;

  return (
    <RouteLink to={url} {...props}>
      <OpportunityPopover opportunityId={opportunity.id} excludeProject={excludeProject} placement="right">
        {children || opportunity.name}
      </OpportunityPopover>
    </RouteLink>
  );
}

export default OpportunityLink;
