import React from 'react';
import {
  ApprovalStatusFilter,
  ExpenseCategoryFilter,
  ExpenseReimbursableTypeFilter,
  ExportDropdown,
  FiltersBar,
  MemberFilter,
  Page,
  PeriodFilter,
  PracticeFilter,
  ProjectFilter,
} from '~/components';
import ReimbursedFilter from '~/components/filters/ReimbursedFilter';
import { useFeatures } from '~/hooks';
import { intervalOptions, intervalsByScope, mimeTypes } from '~/utils';

function ExpenseFilters({ filters, onChange, onExport }) {
  const features = useFeatures();

  const handleFilterChange = ({ target: { name, value } }) => {
    onChange({ [name]: value });
  };

  return (
    <>
      <Page.Header>
        <Page.Info>
          <Page.Eyebrow>Expenses</Page.Eyebrow>
          <Page.Title>Expense Auditing</Page.Title>
        </Page.Info>

        <Page.Actions>
          <ExportDropdown>
            {({ setIsOpen }) => (
              <>
                <ExportDropdown.Item
                  onClick={async () => {
                    await onExport(`expense_auditing.csv`, mimeTypes.csv);
                    setIsOpen(false);
                  }}>
                  Export to CSV
                </ExportDropdown.Item>

                <ExportDropdown.Item
                  onClick={async () => {
                    await onExport(`expense_auditing.xlsx`, mimeTypes.xlsx);
                    setIsOpen(false);
                  }}>
                  Export to Excel
                </ExportDropdown.Item>
              </>
            )}
          </ExportDropdown>
        </Page.Actions>
      </Page.Header>

      <Page.Filters>
        <FiltersBar>
          <PeriodFilter
            name="period"
            placeholder="Date Range"
            intervals={[intervalOptions.all_dates, ...intervalsByScope.day]}
            value={filters.period}
            onChange={handleFilterChange}
          />

          <ProjectFilter name="projects" value={filters.projects} onChange={handleFilterChange} />

          <ApprovalStatusFilter name="statuses" value={filters.statuses} onChange={handleFilterChange} />

          <MemberFilter name="members" value={filters.members} onChange={handleFilterChange} />

          <ExpenseReimbursableTypeFilter
            name="reimbursableType"
            value={filters.reimbursableType}
            onChange={handleFilterChange}
          />

          <ReimbursedFilter name="isReimbursed" value={filters.isReimbursed} onChange={handleFilterChange} />

          {features.practices && (
            <PracticeFilter
              name="memberPractices"
              placeholder="Member Practice"
              value={filters.memberPractices}
              onChange={handleFilterChange}
            />
          )}

          <ExpenseCategoryFilter
            name="expenseCategories"
            value={filters.expenseCategories}
            onChange={handleFilterChange}
          />
        </FiltersBar>
      </Page.Filters>
    </>
  );
}

export default ExpenseFilters;
