import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ActionButton, Buttons, CancelButton, Drawer, FormMessage, SingleSelect, Table } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useDirtyCheck, useForm } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { colors } from '~/styles';

const ErrorMessage = styled(FormMessage.Error)`
  margin-bottom: 1.5rem;
`;

const MissingId = styled.span`
  color: ${colors.error};
`;

export default function TimeOffTypeMapping({ onClose, onSave }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ isSubmitting, message, saved, status }, form] = useForm();
  const [timeOffTypeMappings, setTimeOffTypeMappings] = useState([]);
  const [internalTimeOffTypes, setInternalTimeOffTypes] = useState([]);
  const [externalTimeOffTypes, setExternalTimeOffTypes] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [dirty, setDirty] = useState(false);
  const dirtyCheck = useDirtyCheck(() => dirty);
  const drawerRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: timeOffTypeMappings } = await api.www.workspaces(workspace.id).bamboohr.getTimeOffTypeMappings();

        const { data: internalTimeOffTypes } = await api.www
          .workspaces(workspace.id)
          .bamboohr.getInternalTimeOffTypes();

        const { data: externalTimeOffTypes } = await api.www
          .workspaces(workspace.id)
          .bamboohr.getExternalTimeOffTypes();

        setTimeOffTypeMappings(timeOffTypeMappings);
        setInternalTimeOffTypes(internalTimeOffTypes);
        setExternalTimeOffTypes(externalTimeOffTypes);
      } finally {
        setIsReady(true);
      }
    };
    fetchData();
    return fetchData.cancel;
  }, [api, workspace]);

  const changeMapping = (externalTimeOffType, selected) => {
    const { value } = selected.target;

    setTimeOffTypeMappings((mappings) => {
      // Flag the form as dirty
      setDirty(true);
      // Check if the current mapping exists
      const mapping = _.find(mappings, { bamboohrId: externalTimeOffType.id });
      // If no value, then delete the mapping
      if (mapping && !value) {
        return mappings.filter((m) => m.id !== mapping.id);
      }
      // If there is already a mapping, update the value
      else if (mapping) {
        mapping.timeOffTypeId = value;
        return mappings.map((m) => (m.id === mapping.id ? mapping : m));
      }
      // If there is no mapping, but a value, then add it
      else if (value) {
        return [
          ...mappings,
          {
            id: _.uniqueId('temp_'),
            timeOffTypeId: value,
            bamboohrId: externalTimeOffType.id,
          },
        ];
      }
      // There was no mapping and the value is empty
      // This shouldn't happen, but it just returns the orignial list if it does
      return mappings;
    });
  };

  return (
    <Drawer
      isOpen
      title="Time Off Type Mappings"
      byline="BambooHR Integration"
      ref={drawerRef}
      onBeforeClose={({ setIsOpen }) => dirtyCheck(() => setIsOpen(false))}
      onClose={onClose}>
      {(closeDrawer) => {
        const handleCloseClick = () => dirtyCheck(() => closeDrawer());

        const handleSave = async () => {
          form.submit();

          const mappings = timeOffTypeMappings.map((mapping) => ({
            id: mapping.id.startsWith('temp_') ? undefined : mapping.id,
            ..._.pick(mapping, ['timeOffTypeId', 'bamboohrId']),
          }));

          try {
            await await api.www.workspaces(workspace.id).bamboohr.updateTimeOffTypeMappings(mappings);
            form.save();
            onSave();
            closeDrawer();
          } catch (error) {
            form.error({ message: error.message });
            drawerRef.current.scrollTo({ top: 0 });
          }
        };

        if (!isReady) return <PageLoader />;

        return (
          <>
            {status && <ErrorMessage>{message || 'An error has occurred.'}</ErrorMessage>}
            <Table style={{ marginBottom: '2.5rem' }}>
              <Table.BoxHeader>
                <Table.Column>BambooHR Time Off Type</Table.Column>
                <Table.Column>Ruddr Time Off Type</Table.Column>
              </Table.BoxHeader>
              <Table.Body>
                {externalTimeOffTypes.map((externalTimeOffType) => {
                  const mapping = _.find(timeOffTypeMappings, { bamboohrId: externalTimeOffType.id });
                  const selectedType = mapping ? _.find(internalTimeOffTypes, { id: mapping.timeOffTypeId }) : null;
                  return (
                    <Table.BoxRow key={externalTimeOffType.id}>
                      <Table.Cell>{externalTimeOffType.name}</Table.Cell>
                      <Table.Cell>
                        <SingleSelect
                          placeholder="Ruddr Time Off Type"
                          showEmptyOption={true}
                          noOptionsMessage="No available time off types"
                          value={mapping ? mapping.id : undefined}
                          valueRenderer={
                            selectedType ? (
                              selectedType.name
                            ) : mapping ? (
                              <MissingId>Unknown ID: {mapping.timeOffTypeId}</MissingId>
                            ) : null
                          }
                          onChange={changeMapping.bind(this, externalTimeOffType)}>
                          {internalTimeOffTypes.map((type) => (
                            <option key={type.id} value={type.id}>
                              {type.name}
                            </option>
                          ))}
                        </SingleSelect>
                      </Table.Cell>
                    </Table.BoxRow>
                  );
                })}
              </Table.Body>
            </Table>
            <Drawer.Actions>
              <Buttons align="right">
                <CancelButton onClick={handleCloseClick}>Close</CancelButton>
                <ActionButton isLoading={isSubmitting} ok={saved} onClick={handleSave}>
                  Save &amp; Close
                </ActionButton>
              </Buttons>
            </Drawer.Actions>
          </>
        );
      }}
    </Drawer>
  );
}
