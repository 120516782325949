import React from 'react';
import { Page } from '~/components';
import ProjectDetails from './ProjectDetails';
import ProjectKPI from './ProjectKPI';

export default function ProjectOverviewTab({ project }) {
  return (
    <Page.Content>
      <Page.Main>
        <ProjectKPI project={project} />
      </Page.Main>

      <ProjectDetails project={project} />
    </Page.Content>
  );
}
