import { useApi, useToast, useWorkspace } from '~/contexts';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PageLoader } from '~/routes/public/pages';

export default function RipplingRedirect() {
  const api = useApi();
  const location = useLocation();
  const { navigateWorkspace } = useWorkspace();
  const toast = useToast();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const { data: workspace } = await api.www.workspaces().lastAccessed();

      try {
        const { data } = await api.rippling.postRedirectUri({
          uri: window.location.href,
          workspaceId: workspace.id,
        });
        toast.success('Successfully connected with Rippling');
        window.open(data.redirectUri, '_self', 'noreferrer');
      } catch (error) {
        toast.error(error.message);
        await navigateWorkspace(workspace.key, '/settings/integrations');
      }
    })();
  }, [location.search, api, navigateWorkspace, toast, history]);

  return <PageLoader />;
}
