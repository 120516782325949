import { Checkbox, Currency, Level, MemberSelect, SingleSelect, Table, Tooltip } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { useActions, useDateTimeFormat } from '~/hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { colors, weights } from '~/styles';

const StyledCheckbox = styled.div`
  > label > div {
    background: ${colors.white};
    width: 1.125rem;
    height: 1.125rem;
    font-size: 0.5rem;
    margin: 0;
  }
`;

const StyledTable = styled(Table)`
  font-size: 0.875rem;
  margin-top: 2rem;

  ${Table.Cell} {
    align-items: flex-start;
    height: 100%;
    min-height: unset;
    padding: 1rem;
  }
`;

const Amount = styled.div`
  position: relative;
  font-weight: ${weights.bold};

  &,
  &.icon {
    ${({ status }) =>
      ({
        not_submitted: css`
          color: ${colors.grey25};
        `,
        pending_approval: css`
          color: ${colors.warning};
        `,
        rejected: css`
          color: ${colors.danger};
        `,
        approved: css`
          color: ${colors.success};
        `,
      })[status]}
  }
`;

const DetailsContent = styled.div`
  font-size: 0.875rem;
`;

const initialState = {
  isReady: false,
  data: null,
  query: {
    q: '',
    member: null,
  },
};
const handlers = {
  ready: ({ data }) => ({ isReady: true, dialog: null, data, editIndex: null }),
  filter: (query, state) => ({ query: { ...state.query, ...query } }),
};

function TransactionsDrawerExpensesTab({ invoice, expenses, projectId, start, end, currency, onChange }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [{ data, query }, actions] = useActions(handlers, initialState);

  const fetchData = useCallback(async () => {
    try {
      const q = {
        ..._.omit(query, 'member'),
        start: start || undefined,
        end: end || undefined,
        projectId: projectId || undefined,
        memberId: query.member?.id,
      };
      const { data } = await api.www.workspaces(workspace.id).invoices(invoice.id).getExpenseTransactions(q);

      actions.ready({ data });
    } catch (error) {
      actions.ready({ data: null });
    }
  }, [actions, workspace.id, query, api, invoice.id, start, end, projectId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const selected = useMemo(() => {
    if (!data) return;

    return {
      expenses: _.filter(data, (expense) => expenses.some((e) => e.id === expense.id)),

      get amount() {
        return _.sumBy(this.expenses, (expense) => expense.total);
      },

      get some() {
        return this.expenses.length > 0;
      },

      get all() {
        return this.expenses.length === data.length;
      },
    };
  }, [data, expenses]);

  const dateTimeFormat = useDateTimeFormat();

  if (!data) return null;

  const handleBulkSelectionChange = () => {
    onChange(
      selected.some
        ? expenses.filter(({ id }) => !data.some((expense) => id === expense.id))
        : [...expenses, ...data.map(({ id, expenseType }) => ({ id, expenseType }))],
    );
  };

  return (
    <div>
      <Level>
        <Level.Item>
          <MemberSelect
            placeholder="All"
            materialPlaceholder="Member"
            materialAlwaysVisible
            value={query.member}
            onChange={({ target: { value } }) => actions.filter({ member: value })}
          />
        </Level.Item>
        <Level.Item>
          <SingleSelect
            name="statusId"
            placeholder="All"
            materialPlaceholder="Approval Status"
            materialAlwaysVisible
            showEmptyOption
            value={query.statusId}
            onChange={({ target: { value } }) => actions.filter({ statusId: value })}>
            <option value="not_submitted">Not Submitted</option>
            <option value="pending_approval">Pending Approval</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
          </SingleSelect>
        </Level.Item>
      </Level>

      <StyledTable>
        <Table.Header>
          <Table.Column align="center" width="3rem">
            <StyledCheckbox>
              <Checkbox checked={selected.some} partial={!selected.all} onChange={handleBulkSelectionChange} />
            </StyledCheckbox>
          </Table.Column>
          <Table.Column>Details</Table.Column>
          <Table.Column width="6rem" align="right">
            Amount
          </Table.Column>
        </Table.Header>
        <Table.Body>
          {data.map((expense) => {
            const checked = _(expenses).some(({ id }) => id === expense.id);

            const handleSelectionChange = () => {
              onChange(
                checked
                  ? expenses.filter(({ id }) => id !== expense.id)
                  : [...expenses, { id: expense.id, expenseType: expense.expenseType }],
              );
            };

            return (
              <Table.Row key={expense.id}>
                <Table.Cell>
                  <StyledCheckbox>
                    <Checkbox checked={checked} onChange={handleSelectionChange} />
                  </StyledCheckbox>
                </Table.Cell>
                <Table.Cell>
                  <DetailsContent>
                    {_.compact([
                      dateTimeFormat.format(expense.date),
                      expense.expenseCategory.name,
                      expense.report?.member?.name,
                      expense.notes,
                    ]).join(' - ')}
                  </DetailsContent>
                </Table.Cell>
                <Table.Cell>
                  <Tooltip message={expense.status?.name}>
                    <Amount status={expense.statusId}>
                      <Currency value={expense.total} currency={currency} />
                    </Amount>
                  </Tooltip>
                </Table.Cell>
              </Table.Row>
            );
          })}
          <Table.Row style={{ fontWeight: weights.bold, border: 'none' }}>
            <Table.Cell />
            <Table.Cell>
              <p style={{ flex: 1, textAlign: 'right' }}>Total</p>
            </Table.Cell>
            <Table.Cell>
              <Currency value={selected.amount} currency={currency} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </StyledTable>
    </div>
  );
}

export default TransactionsDrawerExpensesTab;
