import _ from 'lodash';
import calculateExpenseMarkup from './calculateExpenseMarkup';
import Big from 'big.js';

const calculateExpenseRevenue = ({ enableMarkup, markupMethod, markupRatio, markupAmount, amount, markup }) => {
  if (_.isUndefined(markup)) {
    markup = calculateExpenseMarkup({ enableMarkup, markupMethod, markupRatio, markupAmount, amount });
  }

  if (_.isNull(markup)) return amount;

  return Big(markup || 0)
    .add(amount || 0)
    .round(2)
    .toNumber();
};

export default calculateExpenseRevenue;
