import React from 'react';
import { SingleSelectFilter } from '~/components';

export default function ActiveStatusSelect(props) {
  return (
    <SingleSelectFilter
      icon="filter"
      name="isActive"
      placeholder="Status"
      options={[
        { id: 'true', name: 'Active' },
        { id: 'false', name: 'Inactive' },
      ]}
      {...props}
    />
  );
}
