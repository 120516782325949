import { Button, Buttons, CancelButton, Field, Form, FormMessage, Icon, Level, ModalCard, Tooltip } from '~/components';
import { useApi, useToast, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import { useDirtyCheck, useFeatures, useForm } from '~/hooks';
import _ from 'lodash';
import { darken } from 'polished';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors, weights } from '~/styles';
import { emptyStringToNull } from '~/utils';
import * as Yup from 'yup';

const ProjectCreateFormContext = React.createContext({ forms: [] });

const NewClientButton = styled(Button)`
  background: ${colors.grey5};
  color: ${colors.primary};
  border-radius: 5px;
  font-weight: ${weights.bold};

  &:last-child {
    padding-right: 0.75rem;
  }

  .icon {
    font-size: 0.75rem;
  }
`;

function ProjectCreateForm({ project, onClose, onSaved, client }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const features = useFeatures();
  const [{ status, message, isSubmitting }, form] = useForm();
  const [clientFormVisible, setClientFormVisible] = useState(false);
  const formRef = useRef();
  const dirtyCheck = useDirtyCheck(() => formRef.current.dirty);
  const [forms, setForms] = useState([]);

  async function handleSubmit(values) {
    try {
      form.submit();

      const body = emptyStringToNull({
        ..._.omit(values, ['businessUnit', 'client']),
        businessUnitId: values.businessUnit?.id ?? null,
        clientId: values.client.id,
        practiceId: values.client.practiceId,
      });

      if (values.billingTypeId === 'fixed_recurring') {
        body.useBudget = false;
      } else {
        body.useMonthlyBudget = false;
      }

      const { data } = await api.www.workspaces(workspace.id).projects(project?.id).upsert(body);

      await onSaved(data);

      form.done();
    } catch ({ message }) {
      form.error({ message });
    }
  }

  const initialValues = {
    billingTypeId: client && client.isInternal ? 'non_billable' : 'tm',
    businessUnit: client && client.businessUnit ? client.businessUnit : null,
    clientId: '',
    client,
    currency: workspace.currency,
    isBillable: client && client.isInternal ? false : true,
    name: '',
    statusId: 'not_started',
    useRoles: workspace.projectDefaultUseRoles ?? true,
    useBudget: workspace.projectDefaultTrackBudget ?? true,
    useMonthlyBudget: workspace.projectDefaultTrackBudget ?? true,
  };

  const handleClose = () => dirtyCheck(onClose);

  return (
    <ProjectCreateFormContext.Provider value={{ forms, setForms }}>
      <ModalCard title="New Project" onClose={handleClose}>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            billingTypeId: Yup.string().label('Billing Type').required(),
            client: Yup.mixed().label('Client').required(),
            currency: Yup.string().label('Currency').required(),
            name: Yup.string().label('Project Name').max(255).required(),
          })}>
          {(formik) => {
            const handleClientChange = async ({ target: { value } }) => {
              const values = { ...formik.values, client: value };
              if (value) {
                if (value.isInternal) {
                  values.isBillable = false;
                  values.billingTypeId = 'non_billable';
                }

                if (features.businessUnits) values.businessUnit = value.businessUnit;

                values.currency = features.multicurrency ? value.currency : workspace.currency;
              }

              await formik.setValues(values);

              if (formik.errors.client) formik.validateField('client');
            };

            const handleBillingTypeChange = ({ target: { value } }) => {
              const values = {
                ...formik.values,
                billingTypeId: value,
                isBillable: value !== 'non_billable',
              };

              formik.setValues(values);
            };

            const handleClientSaved = async (client) => {
              await formik.setFieldValue('client', client);
              setClientFormVisible(false);
              if (formik.errors['client']) formik.validateField('client');
            };

            const submit = async () => {
              // Submit all open inline forms
              for await (const form of forms) {
                await form.ref.current.submitForm();
              }

              // If at least one form is invalid, don't submit the drawer
              for await (const form of forms) {
                if (form.ref.current && !form.ref.current.isValid) return;
              }

              formik.submitForm();
            };

            return (
              <Form>
                <ModalCard.Body>
                  <Form.Control>
                    <Field.Text autoFocus name="name" placeholder="Project Name" maxLength={255} />
                  </Form.Control>

                  {clientFormVisible ? (
                    <NewClientForm onCancel={() => setClientFormVisible(false)} onSaved={handleClientSaved} />
                  ) : (
                    <Form.Control>
                      <div style={{ flex: 2.5 }}>
                        <Field.ClientSelect
                          name="client"
                          placeholder="Client"
                          disabled={!!client}
                          clearable={!client}
                          onChange={handleClientChange}
                        />
                      </div>
                      {!client && (
                        <NewClientButton onClick={() => setClientFormVisible(true)}>
                          <Icon icon="plus" spaceRight /> Add a New Client
                        </NewClientButton>
                      )}
                    </Form.Control>
                  )}

                  {features.businessUnits && (
                    <Form.Control>
                      <Field.BusinessUnitSelect name="businessUnit" placeholder="Business Unit" />
                    </Form.Control>
                  )}

                  <Form.Control>
                    <Field.ProjectBillingTypeSelect
                      name="billingTypeId"
                      placeholder="Billing Type"
                      disabled={formik.values.client?.isInternal}
                      onChange={handleBillingTypeChange}
                    />

                    <Field.WorkspaceCurrencySelect
                      name="currency"
                      placeholder="Currency"
                      clearable={false}
                      disabled={!features.multicurrency}
                    />
                  </Form.Control>

                  <Form.Control>
                    <Field.Checkbox name="useRoles" label="This project will use roles for project team members" />
                  </Form.Control>

                  {formik.values.billingTypeId === 'fixed_recurring' ? (
                    <Form.Control>
                      <Field.Checkbox name="useMonthlyBudget" label="This project will have a monthly budget" />
                    </Form.Control>
                  ) : (
                    <Form.Control>
                      <Field.Checkbox name="useBudget" label="This project will have a budget" />
                    </Form.Control>
                  )}

                  {status && <FormMessage.Error>{message || 'An error has occurred.'}</FormMessage.Error>}
                </ModalCard.Body>

                <ModalCard.Footer>
                  <Buttons align="right">
                    <CancelButton onClick={handleClose}>Close</CancelButton>
                    <Button onClick={submit} isLoading={isSubmitting} disabled={clientFormVisible}>
                      Save
                    </Button>
                  </Buttons>
                </ModalCard.Footer>
              </Form>
            );
          }}
        </Formik>
      </ModalCard>
    </ProjectCreateFormContext.Provider>
  );
}

const ClientForm = styled.div`
  background: ${colors.grey5};
  padding: 0.75rem 1.5rem;
  margin: 0 -1.5rem;
`;

const TimesButton = styled(Button)`
  width: 1.375rem;
  height: 1.375rem;
  padding: 0;
  background: ${colors.danger};
  color: ${colors.white};
  font-size: 0.9rem;

  .icon {
    margin: 0;
  }

  &:hover {
    background: ${darken(0.1, colors.danger)};
  }
`;

const CheckButton = styled(Button)`
  width: 1.375rem;
  height: 1.375rem;
  padding: 0;
  font-size: 0.9rem;
`;

function NewClientForm({ onCancel, onSaved }) {
  const { workspace } = useWorkspace();
  const api = useApi();
  const toast = useToast();

  const form = useRef();
  const { setForms } = useContext(ProjectCreateFormContext);

  useEffect(() => {
    // Register the inline form
    setForms((forms) => [...forms, { id: 'client', ref: form }]);

    return () => {
      // Unregister the inline form
      setForms((forms) => forms.filter((f) => f.id !== 'client'));
    };
  }, [setForms]);

  async function handleSubmit(values, formik) {
    try {
      formik.setSubmitting(true);
      const { data } = await api.www.workspaces(workspace.id).clients().upsert(values);
      await onSaved(data);
    } catch ({ message }) {
      formik.setSubmitting(false);
      toast.error(message);
    }
  }
  const [blockSubmit, setBlockSubmit] = useState(true);
  const [duplicateClient, setDuplicateClient] = useState(null);

  function DuplicateClientWarning({ client }) {
    return (
      <Tooltip
        placement="left"
        style={{ display: 'flex', alignItems: 'center', padding: '0.4rem' }}
        message={
          <>
            There is already a client named <b>{client.name}</b>.
          </>
        }>
        <Icon icon="exclamation-triangle" style={{ fontSize: '1.5rem' }} color={colors.warning} />
      </Tooltip>
    );
  }

  const findDuplicate = async (name) => {
    if (name) {
      const { data: client } = await api.www.workspaces(workspace.id).clients().findDuplicate({ name });
      return client ? client : null;
    } else {
      return null;
    }
  };

  const handleNameChange = useRef(
    _.debounce(async ({ target: { value } }) => {
      setDuplicateClient(await findDuplicate(value));
      if (value.trim()) {
        setBlockSubmit(false);
      }
    }, 300),
  ).current;

  return (
    <ClientForm>
      <Formik
        innerRef={form}
        initialValues={{ name: '' }}
        validationSchema={Yup.object().shape({
          name: Yup.string().label('Client Name').max(255).trim().required(),
        })}
        onSubmit={handleSubmit}>
        {(formik) => {
          return (
            <Level gap=".4rem">
              <Level.Item>
                <Field.Text
                  onChange={(event) => {
                    setBlockSubmit(true);
                    handleNameChange(event);
                    formik.handleChange(event);
                  }}
                  autoFocus
                  name="name"
                  placeholder="New Client Name"
                />
              </Level.Item>
              {duplicateClient && formik.values.name && <DuplicateClientWarning client={duplicateClient} />}

              <Level.Item narrow right>
                <TimesButton onClick={onCancel}>
                  <Icon icon="times" />
                </TimesButton>
              </Level.Item>

              <Level.Item narrow right>
                <CheckButton
                  disabled={blockSubmit}
                  isLoading={formik.isSubmitting}
                  type="button"
                  onClick={formik.submitForm}>
                  <Icon icon="check" />
                </CheckButton>
              </Level.Item>
            </Level>
          );
        }}
      </Formik>
    </ClientForm>
  );
}

export default ProjectCreateForm;
