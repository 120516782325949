import React from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import OpportunityCreateForm from './OpportunityCreateForm';
import OpportunitiesListPage from './OpportunitiesListPage';
import OpportunityArea from './OpportunityArea';

export default function OpportunitiesArea() {
  const { path, url } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const handleCreate = (opportunity) => {
    history.push(`${url}/${opportunity.id}/overview`);
  };

  return (
    <>
      <Switch>
        <Route path={[`${path}/`, `${path}/new`, `${path}/edit/:opportunityId`]} exact>
          <OpportunitiesListPage sessionKey="opportunities_list" />
        </Route>
        <Route path={`${path}/:opportunityId`}>
          <OpportunityArea />
        </Route>
      </Switch>
      <Route path={`${path}/new`}>
        <OpportunityCreateForm
          onSaved={handleCreate}
          onClose={() => {
            history.push({ pathname: url, search: location.search });
          }}
        />
      </Route>
    </>
  );
}
