import React from 'react';
import styled from 'styled-components';
import TableBody from './TableBody';
import TableCell from './TableCell';
import TableColumn from './TableColumn';
import { TableProvider } from './TableContext';
import TableGroupRow from './TableGroupRow';
import TableHeader from './TableHeader';
import TableLoader from './TableLoader';
import TableRow from './TableRow';
import TableStatus from './TableStatus';
import TableTotal from './TableTotal';

const TableContents = styled.div`
  min-width: fit-content;
  /* margin-right: -1rem;
  padding-right: 1rem; */
`;

function Table({ ...props }) {
  return (
    <TableProvider>
      <TableContents {...props} />
    </TableProvider>
  );
}

Table.Header = TableHeader;
Table.Column = TableColumn;
Table.Body = TableBody;
Table.Row = TableRow;
Table.GroupRow = TableGroupRow;
Table.Cell = TableCell;
Table.Total = TableTotal;
Table.Loader = TableLoader;
Table.Status = TableStatus;

export default Table;
