import { useAuth } from '~/hooks';
import React, { useMemo } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import InvoiceDetails from './invoice-form/InvoiceDetails';
import InvoicesListPage from './invoices-list/InvoicesListPage';
import invoiceStatuses from '~/lookups/invoice-statuses.js';

function InvoicesArea() {
  const { path, url } = useRouteMatch();
  const auth = useAuth();

  const defaultInvoiceStatuses = useMemo(() => {
    if (auth.invoices.manage.project) return [invoiceStatuses.open, invoiceStatuses.draft];
    else return [invoiceStatuses.open];
  }, [auth]);

  return (
    <Switch>
      <Route path={`${path}/:invoiceId`}>
        <InvoiceDetails />
      </Route>
      <Route path={`${path}`} exact>
        <InvoicesListPage defaultInvoiceStatuses={defaultInvoiceStatuses} sessionKey="invoices_list" />
      </Route>
      <Redirect path="/" to={url} />
    </Switch>
  );
}

export default InvoicesArea;
