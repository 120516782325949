import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Buttons, DateTime } from '~/components';
import { colors, weights } from '~/styles'; // Reuse existing styles
import ImportedResults from './ImportedResults';
import ManualSyncModal from './ManualSyncModal';
import SyncResults from './SyncResults';
import SyncSettingsDrawer from './SyncSettingsDrawer';
import ConnectionInformation from './ConnectionInformation';

const Blocks = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 64rem;
  margin: -1rem;
  margin-top: 1rem;
`;

const Block = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: calc(50% - 2rem);
  margin: 1rem;
  padding: 1.5rem;
  background-color: ${colors.white};
  box-shadow: 0 0.1875rem 1rem ${colors.grey10};
  border-radius: 0.3125rem;
`;

const FullBlock = styled(Block)`
  width: calc(100% - 2rem);
`;

const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: ${weights.light};
`;

const Actions = styled.div`
  margin-top: auto;
  padding-top: 1rem;
`;

const IntegrationTable = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.grey10};
`;

const Attribute = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
`;

const Value = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0.75rem;
  font-weight: ${weights.bold};
`;

// Main HubSpotSettings component
export default function HubSpotSettings({ integration, reloadIntegration }) {
  const [showManualSync, setShowManualSync] = useState(false);
  const [showSyncSettings, setShowSyncSettings] = useState(false);
  // const [showEditConnection, setShowEditConnection] = useState(false);
  const [forceRefreshSyncsDate, setForceRefreshSyncsDate] = useState(moment().format());

  return (
    integration && (
      <>
        <Blocks>
          <Block>
            <ConnectionInformation integration={integration} refreshDate={forceRefreshSyncsDate} />
          </Block>
          <Block>
            <Title>Sync Settings</Title>
            <IntegrationTable>
              <Row>
                <Attribute>Automatically Sync</Attribute>
                <Value>{integration.settings.autoSync ? 'On' : 'Off'}</Value>
              </Row>
              <Row>
                <Attribute>Sync Start Date</Attribute>
                <Value>
                  <DateTime value={integration.settings.syncStartDate} format="l" titleFormat="LL" />
                </Value>
              </Row>
              <Row>
                <Attribute>Last Sync Date</Attribute>
                <Value>
                  {integration.settings.mostRecentSync ? (
                    <DateTime value={integration.settings.mostRecentSync} format="l" titleFormat="LL" />
                  ) : (
                    <span>Never been run</span>
                  )}
                </Value>
              </Row>
            </IntegrationTable>
            <Actions>
              <Buttons>
                <Button onClick={() => setShowSyncSettings(true)}>Change Settings</Button>
                <Button onClick={() => setShowManualSync(true)}>New Manual Sync</Button>
              </Buttons>
            </Actions>
          </Block>
        </Blocks>
        <Blocks>
          <FullBlock>
            <ImportedResults refreshDate={forceRefreshSyncsDate} />
          </FullBlock>
          <FullBlock>
            <SyncResults refreshDate={forceRefreshSyncsDate} />
          </FullBlock>
        </Blocks>

        {showManualSync && (
          <ManualSyncModal
            integration={integration}
            onClose={() => setShowManualSync(false)}
            onFinish={() => setForceRefreshSyncsDate(moment().format())}
          />
        )}
        {showSyncSettings && (
          <SyncSettingsDrawer
            integration={integration}
            onClose={() => setShowSyncSettings(false)}
            onSave={() => reloadIntegration()}
          />
        )}
      </>
    )
  );
}
