import React from 'react';
import SingleSelectFilter from './SingleSelectFilter';

function ExpenseBillableTypeFilter(props) {
  return (
    <SingleSelectFilter
      icon="filter"
      placeholder="Billable Type"
      renderValue={(value) => value.name}
      options={[
        { id: 'billable', name: 'Billable' },
        { id: 'non_billable', name: 'Non-Billable' },
      ]}
      {...props}
    />
  );
}

export default ExpenseBillableTypeFilter;
