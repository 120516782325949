import _ from 'lodash';
import { QueryString } from '~/utils';
import { del, get, patch, post, put } from './agent';

const clientApprovals = {
  project:
    (clientApprovalsUrl) =>
    (id = '') => {
      const url = _([clientApprovalsUrl, id]).compact().join('/');

      return {
        get: (query = {}) => get(url.concat(new QueryString(query, { multi: true }).toString(true))),
        create: (body) => post(url, body),
        update: (body) => patch(url, body),
        delete: () => del(url),
        sendTestEmail: (body) => post(url.concat('/send-email?test=true'), body),
        sendEmail: (body) => post(url.concat('/send-email'), body),
        updateTimeStatus: (body) => post(url.concat('/time/update-status'), body),
        updateExpenseStatus: (body) => post(url.concat('/expenses/update-status'), body),
        getTimeTransactions: (query = {}) =>
          get(url.concat('/transactions/time').concat(new QueryString(query).toString(true))),
        getExpenseTransactions: (query = {}) =>
          get(url.concat('/transactions/expenses').concat(new QueryString(query).toString(true))),
        updateTransactions: (body) => put(url.concat('/transactions'), body),
        history: () => get(url.concat('/history')),
      };
    },

  public: ({ workspaceKey, clientApprovalId, clientKey }) => {
    const url = `/${workspaceKey}/client-approvals`;

    return {
      getOutstanding: () => get(url.concat(`/client/${clientKey}`)),
      get: () => get(url.concat(`/${clientApprovalId}`)),
      updateTimeStatus: (body) => post(url.concat(`/${clientApprovalId}/time/update-status`), body),
      updateExpenseStatus: (body) => post(url.concat(`/${clientApprovalId}/expenses/update-status`), body),
      submit: (body) => post(url.concat(`/${clientApprovalId}/submit`), body),
      receipts: (expenseId) => get(url.concat(`/${clientApprovalId}/expense-items/${expenseId}/receipts`)),
    };
  },
};

export default clientApprovals;
