import React, { useCallback, useEffect, useState } from 'react';
import { Currency, Widget } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import BudgetTooltip from '../components/BudgetTooltip';
import ForecastedBudgetTooltip from '../components/ForecastedBudgetTooltip';
import {
  Box,
  Boxes,
  ChartContainer,
  Label,
  LowerBoxes,
  Metric,
  SmallBox,
  UpperBox,
  Value,
} from '../components/StyledComponents';
import ViewNav from '../components/ViewNav';
import ExpensesCostByMonthChart from './ExpensesCostByMonthChart';
import ExpensesCostProgressChart from './ExpensesCostProgressChart';

export default function ExpensesCostWidget({ project, view, actualsThrough, onViewChange }) {
  const { workspace } = useWorkspace();

  const api = useApi();
  const [{ data, isReady }, setQuery] = useState({ data: null, isReady: false });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www
        .workspaces(workspace.id)
        .projects(project.id)
        .dashboard()
        .progress.expensesCost({ actualsThrough });
      setQuery({ data, isReady: true });
    } catch (error) {
      setQuery({ data: null, isReady: true });
    }
  }, [workspace.id, project.id, api, actualsThrough]);

  useEffect(() => {
    fetchData();
  }, [fetchData, project]);

  const boxes = {
    budget: !!data?.budget,
    forecast: data?.forecast?.dates.length > 0,
    get any() {
      return this.budget || this.forecast;
    },
  };

  const currency = project.currency;

  return (
    <Widget loading={!isReady}>
      <Widget.Preview style={{ minHeight: '32.4rem' }} />
      <Widget.Content>
        <ViewNav view={view} onViewChange={onViewChange} />

        {data && (
          <>
            <ChartContainer>
              {data &&
                {
                  progress: () => <ExpensesCostProgressChart project={project} data={data} />,
                  month: () => <ExpensesCostByMonthChart project={project} data={data} />,
                }[view]()}
            </ChartContainer>

            <Boxes>
              {project.isBillable && (
                <Box>
                  <UpperBox>
                    <Value>
                      <Currency
                        value={data.actual.billable}
                        currency={currency}
                        minimumFractionDigits={0}
                        maximumFractionDigits={0}
                      />
                    </Value>
                    <Label>Billable Expenses Cost</Label>
                  </UpperBox>

                  {boxes.forecast && (
                    <LowerBoxes>
                      <SmallBox>
                        <Metric>
                          <Value>
                            <Currency
                              value={data.forecast.billable.forecasted}
                              currency={currency}
                              minimumFractionDigits={0}
                              maximumFractionDigits={0}
                            />
                          </Value>
                          <Label>Forecast</Label>
                        </Metric>
                        <Metric>
                          <Value>
                            <Currency
                              value={data.forecast.billable.left}
                              currency={currency}
                              minimumFractionDigits={0}
                              maximumFractionDigits={0}
                            />
                          </Value>
                          <Label>Forecast Left</Label>
                        </Metric>
                      </SmallBox>
                    </LowerBoxes>
                  )}
                </Box>
              )}

              <Box>
                <UpperBox>
                  <Value>
                    <Currency
                      value={data.actual.nonBillable}
                      currency={currency}
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  </Value>
                  <Label>Non-Billable Expenses Cost</Label>
                </UpperBox>
                {boxes.forecast && (
                  <LowerBoxes>
                    <SmallBox>
                      <Metric>
                        <Value>
                          <Currency
                            value={data.forecast.nonBillable.forecasted}
                            currency={currency}
                            minimumFractionDigits={0}
                            maximumFractionDigits={0}
                          />
                        </Value>
                        <Label>Forecast</Label>
                      </Metric>
                      <Metric>
                        <Value>
                          <Currency
                            value={data.forecast.nonBillable.left}
                            currency={currency}
                            minimumFractionDigits={0}
                            maximumFractionDigits={0}
                          />
                        </Value>
                        <Label>Forecast Left</Label>
                      </Metric>
                    </SmallBox>
                  </LowerBoxes>
                )}
              </Box>

              <Box>
                <UpperBox>
                  <Value>
                    <Currency
                      value={data.actual.total}
                      currency={currency}
                      minimumFractionDigits={0}
                      maximumFractionDigits={0}
                    />
                  </Value>
                  <Label>Total Expenses Cost</Label>
                </UpperBox>

                {boxes.any && (
                  <LowerBoxes>
                    {boxes.budget && (
                      <SmallBox>
                        <Metric>
                          <Value>
                            <Currency
                              value={data.budget.estimated ?? 0}
                              currency={currency}
                              minimumFractionDigits={0}
                              maximumFractionDigits={0}
                            />
                          </Value>
                          <Label>Expenses Budget</Label>
                        </Metric>
                        <Metric status={data.budget.status}>
                          <BudgetTooltip value={data.budget.percent}>
                            <Value>
                              <Currency
                                value={data.budget.remaining}
                                currency={currency}
                                minimumFractionDigits={0}
                                maximumFractionDigits={0}
                              />
                            </Value>
                            <Label>Expenses Budget Left</Label>
                          </BudgetTooltip>
                        </Metric>
                      </SmallBox>
                    )}
                    {boxes.forecast && (
                      <SmallBox>
                        <Metric>
                          <Value>
                            <Currency
                              value={data.forecast.total.forecasted}
                              currency={currency}
                              minimumFractionDigits={0}
                              maximumFractionDigits={0}
                            />
                          </Value>
                          <Label>Forecast</Label>
                        </Metric>
                        <Metric status={data.forecast.total.status} data-testid="forecasted-left">
                          <ForecastedBudgetTooltip value={data.forecast.total} currency={currency}>
                            <Value>
                              <Currency
                                value={data.forecast.total.left}
                                currency={currency}
                                minimumFractionDigits={0}
                                maximumFractionDigits={0}
                              />
                            </Value>
                            <Label>Forecast Left</Label>
                          </ForecastedBudgetTooltip>
                        </Metric>
                      </SmallBox>
                    )}
                  </LowerBoxes>
                )}
              </Box>
            </Boxes>
          </>
        )}
      </Widget.Content>
    </Widget>
  );
}
