import { rgba } from 'polished';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Dropdown, Icon, Page, Pill, Tooltip } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { colors, weights } from '~/styles';
import QBO from './assets/qbo.svg?react';

const Component = styled(Dropdown)`
  font-size: 0.875rem;
  font-weight: ${weights.normal};
  white-space: nowrap;
  width: 15rem;
  text-align: left;
  justify-self: center;
  margin-top: 0.25rem;

  ${Dropdown.Trigger} {
    button {
      color: ${colors.black};

      &:hover {
        color: ${colors.grey55};
      }
    }
  }
`;

const Menu = styled(Dropdown.Menu)`
  box-shadow: 0 6px 8px ${rgba(colors.black, 0.16)};
  min-width: 20rem;
  max-height: 20rem;
  overflow-y: auto;
`;

const Caret = styled(Icon)`
  font-size: 0.875rem;
`;

const Trigger = styled.div`
  position: relative;
  display: flex;
`;

const ClientName = styled.span`
  color: ${colors.grey55};
  font-style: italic;
  margin-left: 0.2rem;
`;

export default function ProjectDropdown({ project }) {
  const { workspace } = useWorkspace();
  const api = useApi();

  const [projects, setProjects] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.www.workspaces(workspace.id).projects().get({
        size: 1000,
        recordStatusId: 'active',
      });

      setProjects(data);
    } catch (error) {
      setProjects([]);
    }
  }, [api, workspace.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Component position="bottom" align="left">
      {({ setIsOpen, ...props }) => {
        return (
          <>
            <Dropdown.Trigger>
              <Button isAnchor onClick={() => setIsOpen(true)}>
                <Trigger>
                  <Page.Title>{project.name}</Page.Title>
                  {project.recordStatus.id === 'archived' && (
                    <div>
                      <Pill
                        style={{
                          marginLeft: '.5rem',
                          backgroundColor: colors.grey25,
                        }}>
                        Archived
                      </Pill>
                    </div>
                  )}
                  {project.lockTimeAndExpenses && (
                    <Tooltip message="Time and expenses have been locked for this project.">
                      <Pill
                        style={{
                          marginLeft: '.5rem',
                          backgroundColor: colors.red,
                        }}>
                        <Icon icon="lock" spaceRight /> Locked
                      </Pill>
                    </Tooltip>
                  )}
                  {project.qboProjectId && (
                    <Tooltip message="This project is connected with QuickBooks." placement="right">
                      <QBO style={{ width: '1.25rem', height: '1.25rem', marginLeft: '.5rem' }} />
                    </Tooltip>
                  )}
                </Trigger>
                <Caret spaceLeft icon="angle-down" />
              </Button>
            </Dropdown.Trigger>

            <Menu {...props}>
              {projects?.length > 0 ? (
                projects
                  .filter((p) => p.id !== project.id)
                  .map((project) => (
                    <Dropdown.Link
                      key={project.id}
                      onClick={() => setIsOpen(false)}
                      to={`/app/${workspace.key}/projects/${project.client.key}/${project.key}`}
                      value={project.id}>
                      {project.name} <ClientName>({project.client.name})</ClientName>
                    </Dropdown.Link>
                  ))
              ) : (
                <Dropdown.Item>No projects available</Dropdown.Item>
              )}
            </Menu>
          </>
        );
      }}
    </Component>
  );
}
