import React from 'react';
import styled from 'styled-components';
import { Button, Buttons, CancelButton, Drawer } from '~/components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  font-size: 0.875rem;
  padding-bottom: 2rem;
`;

function FiltersDrawer({ children, isOpen, onClose, onApply }) {
  return (
    <Drawer isOpen={isOpen} width="40rem" title="Filters" onClose={onClose}>
      <Container>{children}</Container>
      <Drawer.Actions>
        <Buttons align="right">
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <Button onClick={onApply}>Apply</Button>
        </Buttons>
      </Drawer.Actions>
    </Drawer>
  );
}

export default FiltersDrawer;
