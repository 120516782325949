import { Button } from '~/components';
import styled from 'styled-components';
import { colors, weights } from '~/styles';

export const ActionBarStyles = styled.div`
  display: flex;
  z-index: 5;
  position: relative;
  padding: 1rem 1rem 1rem;
`;

export const DateNav = styled.div`
  display: flex;
  justify-self: center;
  align-self: center;
  align-items: center;
`;

export const DateNavButton = styled(Button)`
  width: 1.625rem;
  height: 1.625rem;
  color: ${colors.grey40};
  font-size: 1.125rem;
  background-color: ${colors.grey5};
  border-radius: 50%;

  &:hover {
    color: ${colors.grey40};
    background-color: ${colors.grey10};
  }
`;

export const DatePickerToggleButton = styled(Button)`
  width: 9rem;
  margin: 0 1rem;
  color: ${colors.primary};
  font-weight: ${weights.medium};
  text-align: center;

  &:hover {
    color: ${colors.accent};
  }
`;

export const CircleButton = styled(Button)`
  position: relative;
  width: 2rem;
  height: 2rem;
  font-size: 0.75rem;
  padding: 0;
  background: ${colors.grey5};
  transition: all 100ms ease-in-out;
  border: none;

  &:hover {
    background-color: ${colors.grey10};
  }
`;

export const ViewNav = styled.div`
  align-self: center;
  display: flex;
  align-items: center;
  border-radius: 0.3125rem;
  border-color: ${colors.white};
  overflow: hidden;
  background: ${colors.white};

  button,
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 5rem;
    height: 1.625rem;
    margin-left: 1px;
    padding: 0 0.75rem;
    color: ${colors.white};
    background-color: ${colors.primary};
    font-size: 0.75rem;
    font-weight: ${weights.black};
    letter-spacing: 0.0625rem;
    text-transform: uppercase;
    border-radius: 0;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      background-color: ${colors.accent};
    }

    &:not(.is-active) {
      color: ${colors.grey40};
      background-color: ${colors.grey5};

      &:hover {
        background-color: ${colors.grey10};
      }
    }
  }
`;
