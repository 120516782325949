import { Currency, Field, Percent, Table } from '~/components';
import _, { sumBy } from 'lodash';
import React, { useMemo } from 'react';
import { colors, weights } from '~/styles';
import calculateServicesBudget from './calculateServicesBudget';
import styled from 'styled-components';
import Big from 'big.js';

const TableTitle = styled.span`
  flex: 0;
  color: ${colors.grey75};
  padding-left: 0rem;
  font-size: 0.875rem;
  font-weight: ${weights.bold};
  padding-top: 1rem;
`;

const summary = (project) => {
  return {
    get servicesRevenue() {
      return calculateServicesBudget(project)?.revenue || 0;
    },

    get expensesRevenue() {
      switch (project.budgetMode) {
        case 'detailed':
        case 'aggregated':
          return (
            sumBy(
              project.budgetExpenses.map((expense) => {
                let amount = expense.markupMethod
                  ? Big(
                      {
                        amount: expense.markupAmount,
                        percentage: Big(expense.billableAmount || 0)
                          .mul(expense.markupRatio || 0)
                          .toNumber(),
                      }[expense.markupMethod] || 0,
                    )
                      .add(expense.billableAmount || 0)
                      .toNumber()
                  : expense.billableAmount || 0;

                if (('fixed', 'fixed_recurring').includes(project.billingTypeId)) {
                  amount = Big(amount)
                    .add(expense.nonBillableAmount || 0)
                    .round(2)
                    .toNumber();
                }

                return amount;
              }),
            ) || 0
          );
        default: {
          let amount =
            project.budgetMarkupMethod && project.budgetExpensesBillableAmount
              ? Big(
                  {
                    amount: project.budgetMarkupAmount,
                    percentage: Big(project.budgetExpensesBillableAmount || 0)
                      .mul(project.budgetMarkupRatio || 0)
                      .div(100)
                      .toNumber(),
                  }[project.budgetMarkupMethod] || 0,
                )
                  .add(project.budgetExpensesBillableAmount || 0)
                  .round(2)
                  .toNumber()
              : project.budgetExpensesBillableAmount || 0;

          if (('fixed', 'fixed_recurring').includes(project.billingTypeId)) {
            amount = Big(amount)
              .add(project.budgetExpensesNonBillableAmount || 0)
              .toNumber();
          }

          return amount;
        }
      }
    },

    get otherItemsRevenue() {
      switch (project.budgetMode) {
        case 'detailed':
        case 'aggregated':
          return sumBy(project.budgetOtherItems.map((otherItem) => otherItem.fee)) || 0;
        default:
          return project.budgetOtherItemsFee || 0;
      }
    },

    get expensesCost() {
      switch (project.budgetMode) {
        case 'detailed':
        case 'aggregated':
          return sumBy(
            project.budgetExpenses.map((expense) =>
              Big(expense.billableAmount || 0)
                .add(expense.nonBillableAmount || 0)
                .toNumber(),
            ),
          );
        default:
          return Big(project.budgetExpensesBillableAmount || 0)
            .add(project.budgetExpensesNonBillableAmount || 0)
            .toNumber();
      }
    },

    get servicesCost() {
      if (!_.isNumber(project.budgetServicesGrossMargin)) return null;
      return this.servicesRevenue - this.servicesRevenue * (project.budgetServicesGrossMargin / 100);
    },

    get servicesGrossProfit() {
      if (!_.isNumber(project.budgetServicesGrossMargin)) return null;
      return (this.servicesRevenue || 0) * (project.budgetServicesGrossMargin / 100);
    },

    get totalRevenue() {
      return this.servicesRevenue + this.expensesRevenue + this.otherItemsRevenue;
    },

    get totalCost() {
      if (!_.isNumber(project.budgetServicesGrossMargin)) return null;
      return this.servicesCost + this.expensesCost;
    },

    get totalGrossProfit() {
      if (!_.isNumber(project.budgetServicesGrossMargin)) return null;
      return this.totalRevenue - this.totalCost;
    },

    get totalGrossMargin() {
      if (!_.isNumber(project.budgetServicesGrossMargin)) return null;
      if (!this.totalRevenue) return 0;
      return this.totalGrossProfit / this.totalRevenue;
    },
  };
};

function FinancialSummary({ project, projectModel }) {
  const {
    servicesRevenue,
    servicesCost,
    servicesGrossProfit,
    expensesRevenue,
    expensesCost,
    otherItemsRevenue,
    totalRevenue,
    totalGrossMargin,
    totalCost,
    totalGrossProfit,
  } = useMemo(() => summary(projectModel), [projectModel]);

  const currency = projectModel.currency;

  const viewMargin = project.permissions.viewMargin;

  return (
    <>
      <TableTitle style={{ marginBottom: '0.8rem' }}>Financial Budget Summary</TableTitle>

      <Table small data-testid="budget_summary">
        <Table.Header>
          <Table.Column>Type</Table.Column>
          <Table.Column align="right" width="10rem">
            Total
          </Table.Column>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Services Revenue</Table.Cell>
            <Table.Cell>
              <Currency value={servicesRevenue} currency={currency} />
            </Table.Cell>
          </Table.Row>
          {viewMargin && (
            <Table.Row>
              <Table.Cell>Target Services Gross Margin</Table.Cell>
              <Table.Cell>
                <Field.Number prefix="%" name="budgetServicesGrossMargin" min={0} max={100} precision={2} />
              </Table.Cell>
            </Table.Row>
          )}
          {viewMargin && (
            <Table.Row>
              <Table.Cell>Services Cost</Table.Cell>
              <Table.Cell>
                <Currency value={servicesCost} currency={currency} />
              </Table.Cell>
            </Table.Row>
          )}
          {viewMargin && (
            <Table.Row>
              <Table.Cell>Services Gross Profit</Table.Cell>
              <Table.Cell>
                <Currency value={servicesGrossProfit} currency={currency} />
              </Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell>Expenses Revenue</Table.Cell>
            <Table.Cell>
              <Currency value={expensesRevenue} currency={currency} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Expenses Cost</Table.Cell>
            <Table.Cell>
              <Currency value={expensesCost} currency={currency} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Other Items to Bill</Table.Cell>
            <Table.Cell>
              <Currency value={otherItemsRevenue} currency={currency} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Total Revenue</Table.Cell>
            <Table.Cell>
              <Currency value={totalRevenue} currency={currency} />
            </Table.Cell>
          </Table.Row>
          {viewMargin && (
            <Table.Row>
              <Table.Cell>Total Cost</Table.Cell>
              <Table.Cell>
                <Currency value={totalCost} currency={currency} />
              </Table.Cell>
            </Table.Row>
          )}
          {viewMargin && (
            <Table.Row>
              <Table.Cell>Total Gross Profit</Table.Cell>
              <Table.Cell>
                <Currency value={totalGrossProfit} currency={currency} />
              </Table.Cell>
            </Table.Row>
          )}
          {viewMargin && (
            <Table.Row>
              <Table.Cell>Total Gross Margin</Table.Cell>
              <Table.Cell>
                <Percent value={totalGrossMargin} />
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </>
  );
}

export default FinancialSummary;
