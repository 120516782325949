import { FieldAvatarFileInput } from './AvatarFileInput';
import { FieldBusinessUnitSelect } from './BusinessUnitSelect';
import { FieldCheckbox } from './Checkbox';
import { FieldClientProjectSelect } from './ClientProjectSelect';
import { FieldClientSelect } from './ClientSelect';
import { FieldCompanySelect } from './CompanySelect';
import { FieldCompanyServiceTypeSelect } from './CompanyServiceTypeSelect';
import { FieldCompanyTypeSelect } from './CompanyTypeSelect';
import { FieldSicCodeSelect } from './SicCodeSelect';
import { FieldCurrency } from './CurrencyInput';
import { FieldDisciplineSelect } from './DisciplineSelect';
import { FieldEmploymentTypeSelect } from './EmploymentTypeSelect';
import { FieldExpenseCategorySelect } from './ExpenseCategorySelect';
import { FieldExpenseItemStatusSelect } from './ExpenseItemStatusSelect';
import FieldControl from './FieldControl';
import FieldDayPicker from './FieldDayPicker';
import FieldRadioGroup from './FieldRadioGroup';
import FieldCheckboxGroup from './FieldCheckboxGroup';
import FieldSingleSelect from './FieldSingleSelect';
import FieldText from './FieldText';
import FieldTextArea from './FieldTextArea';
import { FieldHolidayScheduleSelect } from './HolidayScheduleSelect';
import { FieldImageFileInput } from './ImageFileInput';
import { FieldResumeFileInput } from './ResumeFileInput';
import { FieldInvoiceItemSelect } from './InvoiceItemSelect';
import { FieldJobTitleSelect } from './JobTitleSelect';
import { FieldMemberSelect } from './MemberSelect';
import { FieldPaymentTermsSelect } from './PaymentTermsSelect';
import { FieldPaymentMethodSelect } from './PaymentMethodSelect';
import { FieldPlaceholderSelect } from './PlaceholderSelect';
import { FieldPracticeSelect } from './PracticeSelect';
import { FieldProjectBillingTypeSelect } from './ProjectBillingTypeSelect';
import { FieldProjectMemberSelect } from './ProjectMemberSelect';
import { FieldProjectRoleSelect } from './ProjectRoleSelect';
import { FieldProjectStatusSelect } from './ProjectStatusSelect';
import { FieldProjectTaskSelect } from './ProjectTaskSelect';
import { FieldProjectTaskStatusSelect } from './ProjectTaskStatusSelect';
import { FieldProjectTypeSelect } from './ProjectTypeSelect';
import { FieldPipelineActivityTypeSelect } from './PipelineActivityTypeSelect';
import { FieldQBOProjectSelect } from './QBOProjectSelect';
import { FieldSecurityRoleSelect } from './SecurityRoleSelect';
import { FieldSelect } from './Select';
import { FieldStripeCountrySelect } from './StripeCountrySelect';
import { FieldTaxRateSelect } from './TaxRateSelect';
import { FieldTimeOffTypeSelect } from './TimeOffTypeSelect';
import { FieldTimeZoneSelect } from './TimeZoneSelect';
import { FieldCurrencySelect } from './CurrencySelect';
import { FieldRegionalFormatSelect } from './RegionalFormatSelect';
import FieldNumber from './FieldNumber';
import { FieldLocationSelect } from './LocationSelect';
import { FieldIndustrySelect } from './IndustrySelect';
import { FieldWorkspaceCurrencySelect } from './WorkspaceCurrencySelect';
import { FieldOpportunityStageSelect } from './OpportunityStageSelect';
import { FieldOpportunityTypeSelect } from './OpportunityTypeSelect';
import { FieldOpportunityLeadSourceSelect } from './OpportunityLeadSourceSelect';
import { FieldCompanySpendTierSelect } from './CompanySpendTierSelect';
import { FieldCountrySelect } from './CountrySelect';
import { FieldAuthProviderSelect } from './AuthProviderSelect';

const Field = {};
Field.Control = FieldControl;
Field.AvatarFileInput = FieldAvatarFileInput;
Field.BusinessUnitSelect = FieldBusinessUnitSelect;
Field.ImageFileInput = FieldImageFileInput;
Field.ResumeFileInput = FieldResumeFileInput;
Field.Checkbox = FieldCheckbox;
Field.ClientProjectSelect = FieldClientProjectSelect;
Field.ClientSelect = FieldClientSelect;
Field.CompanyServiceTypeSelect = FieldCompanyServiceTypeSelect;
Field.CompanySelect = FieldCompanySelect;
Field.CompanySpendTierSelect = FieldCompanySpendTierSelect;
Field.CompanyTypeSelect = FieldCompanyTypeSelect;
Field.SicCodeSelect = FieldSicCodeSelect;
Field.Currency = FieldCurrency;
Field.DayPicker = FieldDayPicker;
Field.EmploymentTypeSelect = FieldEmploymentTypeSelect;
Field.IndustrySelect = FieldIndustrySelect;
Field.InvoiceItemSelect = FieldInvoiceItemSelect;
Field.JobTitleSelect = FieldJobTitleSelect;
Field.LocationSelect = FieldLocationSelect;
Field.MemberSelect = FieldMemberSelect;
Field.PaymentMethodSelect = FieldPaymentMethodSelect;
Field.PaymentTermsSelect = FieldPaymentTermsSelect;
Field.PlaceholderSelect = FieldPlaceholderSelect;
Field.OpportunityLeadSourceSelect = FieldOpportunityLeadSourceSelect;
Field.OpportunityTypeSelect = FieldOpportunityTypeSelect;
Field.PipelineActivityTypeSelect = FieldPipelineActivityTypeSelect;
Field.OpportunityStageSelect = FieldOpportunityStageSelect;
Field.ProjectBillingTypeSelect = FieldProjectBillingTypeSelect;
Field.ProjectMemberSelect = FieldProjectMemberSelect;
Field.ProjectRoleSelect = FieldProjectRoleSelect;
Field.ProjectStatusSelect = FieldProjectStatusSelect;
Field.ProjectTaskSelect = FieldProjectTaskSelect;
Field.ProjectTaskStatusSelect = FieldProjectTaskStatusSelect;
Field.ProjectTypeSelect = FieldProjectTypeSelect;
Field.QBOProjectSelect = FieldQBOProjectSelect;
Field.RadioGroup = FieldRadioGroup;
Field.CheckboxGroup = FieldCheckboxGroup;
Field.DisciplineSelect = FieldDisciplineSelect;
Field.PracticeSelect = FieldPracticeSelect;
Field.SecurityRoleSelect = FieldSecurityRoleSelect;
Field.Select = FieldSelect;
Field.SingleSelect = FieldSingleSelect;
Field.TaxRateSelect = FieldTaxRateSelect;
Field.Text = FieldText;
Field.TextArea = FieldTextArea;
Field.TimeOffTypeSelect = FieldTimeOffTypeSelect;
Field.TimeZoneSelect = FieldTimeZoneSelect;
Field.ExpenseCategorySelect = FieldExpenseCategorySelect;
Field.ExpenseItemStatusSelect = FieldExpenseItemStatusSelect;
Field.StripeCountrySelect = FieldStripeCountrySelect;
Field.HolidayScheduleSelect = FieldHolidayScheduleSelect;
Field.CurrencySelect = FieldCurrencySelect;
Field.RegionalFormatSelect = FieldRegionalFormatSelect;
Field.Number = FieldNumber;
Field.WorkspaceCurrencySelect = FieldWorkspaceCurrencySelect;
Field.CountrySelect = FieldCountrySelect;
Field.AuthProviderSelect = FieldAuthProviderSelect;

export default Field;
