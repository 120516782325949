import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import {
  ActionButton,
  ActionsBanner,
  ButtonBadge,
  FiltersBar,
  Level,
  MemberFilter,
  Page,
  PeriodFilter,
  PracticeFilter,
  ProjectFilter,
  SplitButton,
  YesNoFilter,
} from '~/components';
import { ApproverFilter } from '~/components/filters';
import { useApi, useConfirmation, useSubscription, useToast, useWorkspace } from '~/contexts';
import { useActions, useDocumentTitle, useFeatures, useForm, useSearchParams, useSearchParamsConfig } from '~/hooks';
import { PageLoader } from '~/routes/public/pages';
import { dateFormats, intervalOptions } from '~/utils';
import intervalsByScope from '~/utils/intervalsByScope';
import ExpenseItemDrawer from '../item/ExpenseItemDrawer';
import ExpenseApprovalResults from './ExpenseApprovalResults';
import RejectExpenseDialog from './RejectExpenseDialog';

const handlers = {
  load: () => ({ action: 'load' }),
  refetch: () => ({ action: 'refetch' }),
  ready: ({ data }) => ({
    isReady: true,
    action: null,
    data,
  }),
  setParams: (params, state) => ({
    ...state,
    action: 'filter',
    query: { ...state.query, ...params },
    searchParamsStatus: 'ready',
  }),
  updateItems: (items, { data, query }) => ({
    data: data
      .map((result) => {
        let item = items.find((i) => i.id === result.id);
        if (!item) return result;

        return item ? { ...result, ...item } : result;
      })

      // Remove items from the queue if the status doesn't match the filter. This is mostly to
      // clear the queue when an approval action is taken.
      // This may eventually require a refetch, to exclude items based on other properties
      // (which may have changed when using the expense item drawer).
      .filter((result) => !query.status || result.statusId === query.status),
  }),
  removeItem: (id, { data }) => ({
    data: data.filter((i) => i.id !== id),
  }),
};

function ExpenseApprovalsPage({ parentUrl }) {
  const documentTitle = useDocumentTitle('Expense Approvals');

  const { workspace } = useWorkspace();
  const features = useFeatures();

  const initialState = useMemo(
    () => ({
      isReady: false,
      searchParamsStatus: 'pending',
      data: null,
      query: {
        period: null,
        projects: [],
        members: [],
        memberPractices: [],
        approver: workspace.member,
        includeLockedItems: 'no',
      },
      action: 'load',
    }),
    [workspace.member],
  );

  const [{ isReady, data, query, searchParamsStatus, action }, actions] = useActions(handlers, initialState);
  const [selection, setSelection] = useState([]);
  const [showNotes, setShowNotes] = useState(false);
  const [{ isSubmitting, saved }, form] = useForm();
  const { expenseItemId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const route = useRouteMatch();
  const api = useApi();
  const toast = useToast();
  const confirmation = useConfirmation();
  const { notify } = useSubscription();

  const searchParamsConfig = useSearchParamsConfig();

  const searchParams = useSearchParams({
    config: useMemo(
      () => ({
        period: {
          ...searchParamsConfig.period,
          default: {
            ...intervalOptions.custom,
            start: moment().subtract(2, 'months').startOf('month').format(dateFormats.isoDate),
            end: moment().add(1, 'months').endOf('month').format(dateFormats.isoDate),
          },
        },
        sort: { default: initialState.query.sort, ...searchParamsConfig.sort },
        projects: searchParamsConfig.projects,
        members: searchParamsConfig.members,
        memberPractices: searchParamsConfig.practices,
        approver: {
          default: initialState.query.approver,
          serialize: (value) => value?.id ?? 'all',
          deserialize: searchParamsConfig.member.deserialize,
        },
        includeLockedItems: { default: initialState.query.includeLockedItems, valid: ['yes', 'no'] },
      }),
      [initialState.query, searchParamsConfig],
    ),
    sessionKey: 'expense_approvals',
    onChange: useCallback((params) => actions.setParams(params), [actions]),
  });

  useEffect(() => {
    if (searchParamsStatus !== 'pending') return;
    searchParams.get().then((params) => {
      if (params) actions.setParams(params);
    });
  }, [searchParams, searchParamsStatus, actions]);

  const fetchData = useCallback(async () => {
    try {
      const { start, end } = query.period || {};

      const params = {
        ..._.omit(query, ['projects', 'members', 'memberPractices', 'approver', 'period', 'includeLockedItems']),
        projectId: query.projects?.map((v) => v.id),
        memberId: query.members?.map((v) => v.id),
        memberPracticeId: query.memberPractices?.map((v) => v.id),
        approverId: query.approver?.id,
        start: start ?? undefined,
        end: end ?? undefined,
        includeLockedItems: query.includeLockedItems ?? undefined,
      };

      const { data } = await api.www.workspaces(workspace.id).expenseAdmin().approvals(params);

      actions.ready({ data });
      return data;
    } catch (error) {
      actions.ready({ data: [], members: [] });
    }
  }, [actions, workspace.id, query, api]);

  const refetchData = async () => {
    actions.refetch();
    const data = await fetchData();
    const ids = data.map(({ id }) => id);
    setSelection(selection.filter((s) => ids.includes(s)));
  };

  useEffect(() => {
    if (searchParamsStatus !== 'ready') return;
    fetchData();
  }, [fetchData, searchParamsStatus]);

  const handleFilterChange = ({ target }) => {
    actions.setParams({ [target.name]: target.value });
    setSelection([]);
    searchParams.set({ [target.name]: target.value });
  };

  const handleSelectionChange = (selection) => {
    setSelection(selection);
  };

  const handleItemStatusChange = async (item, statusId) => {
    try {
      let notes;
      if (statusId === 'rejected') {
        notes = await confirmation.prompt((resolve) => (
          <RejectExpenseDialog count={1} onResolve={(notes) => resolve(notes)} />
        ));
        if (!notes) return;
      }

      form.submit(item.id);

      const { data } = await api.www
        .workspaces(workspace.id)
        .expenseAdmin()
        .batchUpdateStatus([{ ids: [item.id], statusId, notes }]);

      actions.updateItems(data);
      refetchData();

      notify(useSubscription.keys.refresh_expense_approval_count);
    } catch (error) {
      toast.error(error.message);
    }

    form.done();
  };

  const handleBatchStatusChange = async (statusId) => {
    try {
      let notes;
      if (statusId === 'rejected') {
        notes = await confirmation.prompt((resolve) => (
          <RejectExpenseDialog count={selection.length} onResolve={(notes) => resolve(notes)} />
        ));
        if (!notes) return;
      }

      form.submit('batch');

      const { data } = await api.www
        .workspaces(workspace.id)
        .expenseAdmin()
        .batchUpdateStatus([{ ids: selection, statusId, notes }]);

      actions.updateItems(data);
      refetchData();

      toast.success(`${{ approved: 'Approved', rejected: 'Rejected' }[statusId]} ${data.length} expense items.`);
      setSelection([]);
      form.save();
      notify(useSubscription.keys.refresh_expense_approval_count);
    } catch (error) {
      toast.error(error.message);
      form.done();
    }
  };

  const handleGroupAction = async (group, statusId) => {
    const ids = group.items.map((item) => item.id);

    try {
      let groupActions;

      switch (statusId) {
        case 'rejected': {
          let notes;
          if (statusId === 'rejected') {
            notes = await confirmation.prompt((resolve) => (
              <RejectExpenseDialog count={ids.length} onResolve={(notes) => resolve(notes)} />
            ));
            if (!notes) return;
          }

          groupActions = [{ ids: group.items.map((item) => item.id), statusId, notes }];
          break;
        }

        default:
          groupActions = [{ statusId, ids: group.items.map((item) => item.id) }];
      }

      form.submit({ action: 'group', group });

      const { data } = await api.www.workspaces(workspace.id).expenseAdmin().batchUpdateStatus(groupActions);

      actions.updateItems(data);
      refetchData();

      toast.success(`${{ approved: 'Approved', rejected: 'Rejected' }[statusId]} ${data.length} expense items.`);
      notify(useSubscription.keys.refresh_expense_approval_count);
    } catch (error) {
      toast.error(error.message);
    }

    form.done();
  };

  const handleResultClick = (item) => {
    history.push({ pathname: `${route.url}/item/${item.id}`, search: location.search, state: { scrollToTop: false } });
  };

  const handleCloseDrawer = () => {
    history.push({ pathname: parentUrl, search: location.search, state: { scrollToTop: false } });
    documentTitle.set('Expense Approvals');
  };

  const handleItemSaved = (item) => {
    actions.updateItems([item]);
    refetchData();
  };

  const handleItemDeleted = (item) => {
    actions.removeItem(item.id);
    refetchData();
    handleSelectionChange(selection.filter((s) => s !== item.id));
    handleCloseDrawer();
  };

  // Actions are only enabled if it's an approver's queue.
  const isApproverQueue = !!query.approver;

  if (!isReady) return <PageLoader />;

  return (
    <>
      <Page scrollable>
        <Page.Header>
          <Page.Info>
            <Page.Eyebrow>Expenses</Page.Eyebrow>
            <Page.Title>Expense Approvals</Page.Title>
          </Page.Info>
        </Page.Header>

        <Page.Filters>
          <FiltersBar>
            <PeriodFilter
              name="period"
              placeholder="Date Range"
              intervals={intervalsByScope.day}
              value={query.period}
              onChange={handleFilterChange}
            />

            <ApproverFilter name="approver" value={query.approver} onChange={handleFilterChange} />

            <ProjectFilter name="projects" value={query.projects} onChange={handleFilterChange} />

            <MemberFilter name="members" value={query.members} onChange={handleFilterChange} />

            {features.practices && (
              <PracticeFilter
                name="memberPractices"
                placeholder="Member Practice"
                value={query.memberPractices}
                onChange={handleFilterChange}
              />
            )}

            <YesNoFilter
              icon="filter"
              name="includeLockedItems"
              placeholder="Include Locked Items"
              value={query.includeLockedItems}
              onChange={handleFilterChange}
            />

            <YesNoFilter
              icon="gear"
              placeholder="Show Notes"
              value={showNotes ? 'yes' : 'no'}
              onChange={({ target: { value } }) => setShowNotes(value === 'yes')}
            />
          </FiltersBar>
        </Page.Filters>

        <ExpenseApprovalResults
          results={data}
          selection={selection}
          showNotes={showNotes}
          isSubmitting={isSubmitting}
          isApproverQueue={isApproverQueue}
          onResultClick={handleResultClick}
          onSelectionChange={handleSelectionChange}
          onStatusChange={handleItemStatusChange}
          onGroupAction={handleGroupAction}
          onChange={refetchData}
          action={action}
        />

        {selection?.length > 0 && (
          <ActionsBanner>
            <Level right>
              <Level.Item>
                <SplitButton>
                  <ActionButton
                    isLoading={isSubmitting === 'batch'}
                    ok={saved}
                    onClick={() => handleBatchStatusChange('approved')}>
                    Approve <ButtonBadge visible={!saved && isSubmitting !== 'batch'}>{selection.length}</ButtonBadge>
                  </ActionButton>

                  <SplitButton.Menu position="top" disabled={selection.length === 0}>
                    {({ setIsOpen }) => (
                      <SplitButton.Item onClick={() => setIsOpen(false) || handleBatchStatusChange('rejected')}>
                        Reject
                      </SplitButton.Item>
                    )}
                  </SplitButton.Menu>
                </SplitButton>
              </Level.Item>
            </Level>
          </ActionsBanner>
        )}
      </Page>

      {expenseItemId && (
        <ExpenseItemDrawer onSaved={handleItemSaved} onDeleted={handleItemDeleted} onClose={handleCloseDrawer} />
      )}
    </>
  );
}

export default ExpenseApprovalsPage;
