import _ from 'lodash';
import React from 'react';
import {
  BusinessUnitFilter,
  ClientFilter,
  ClientRecordStatusFilter,
  ClientTagFilter,
  IndustryFilter,
  LocationFilter,
  MemberFilter,
  PracticeFilter,
} from '~/components';
import { useFeatures } from '~/hooks';
import FiltersGroup from './FiltersGroup';

function ClientFiltersGroup({ filters, onChange }) {
  const features = useFeatures();

  return (
    <FiltersGroup
      label="Client Filters"
      filters={[
        filters.clients,
        filters.clientRecordStatusId,
        filters.clientPractices,
        filters.clientBusinessUnits,
        filters.clientSalesRepresentatives,
        filters.clientOwners,
        filters.clientTags,
        filters.clientLocations,
        filters.clientIndustries,
      ]}>
      {_.has(filters, 'clients') && (
        <ClientFilter
          value={filters.clients}
          onChange={({ target: { value } }) => {
            const result = { clients: value };

            if (_.has(filters, 'projects')) result.projects = [];
            if (_.has(filters, 'projectRoles')) result.projectRoles = [];
            if (_.has(filters, 'projectTasks')) result.projectTasks = [];

            onChange(result);
          }}
        />
      )}

      {_.has(filters, 'clientRecordStatusId') && (
        <ClientRecordStatusFilter
          value={filters.clientRecordStatusId}
          onChange={({ target: { value } }) => onChange({ clientRecordStatusId: value })}
        />
      )}

      {features.practices && _.has(filters, 'clientPractices') && (
        <PracticeFilter
          value={filters.clientPractices}
          placeholder="Client Practice"
          onChange={({ target: { value } }) => onChange({ clientPractices: value })}
        />
      )}

      {features.businessUnits && _.has(filters, 'clientBusinessUnits') && (
        <BusinessUnitFilter
          value={filters.clientBusinessUnits}
          placeholder="Client Business Unit"
          onChange={({ target: { value } }) => onChange({ clientBusinessUnits: value })}
        />
      )}

      {_.has(filters, 'clientSalesRepresentatives') && (
        <MemberFilter
          value={filters.clientSalesRepresentatives}
          placeholder="Client Sales Representative"
          onChange={({ target: { value } }) => onChange({ clientSalesRepresentatives: value })}
        />
      )}

      {_.has(filters, 'clientOwners') && (
        <MemberFilter
          value={filters.clientOwners}
          placeholder="Client Relationship Owner"
          onChange={({ target: { value } }) => onChange({ clientOwners: value })}
        />
      )}

      {_.has(filters, 'clientTags') && (
        <ClientTagFilter
          value={filters.clientTags}
          onChange={({ target: { value } }) => onChange({ clientTags: value })}
        />
      )}

      {_.has(filters, 'clientLocations') && (
        <LocationFilter
          value={filters.clientLocations}
          placeholder="Client Location"
          onChange={({ target: { value } }) => onChange({ clientLocations: value })}
        />
      )}

      {_.has(filters, 'clientIndustries') && (
        <IndustryFilter
          value={filters.clientIndustries}
          placeholder="Client Industry"
          onChange={({ target: { value } }) => onChange({ clientIndustries: value })}
        />
      )}
    </FiltersGroup>
  );
}

export default ClientFiltersGroup;
