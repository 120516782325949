import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Drawer } from '~/components';
import { useApi, useWorkspace } from '~/contexts';
import { PageLoader } from '~/routes/public/pages';

const Log = styled.code`
  font-size: 0.875rem;
  white-space: pre-wrap;
`;

export default function SyncLogDrawer({ id, onClose }) {
  const api = useApi();
  const { workspace } = useWorkspace();
  const [isReady, setIsReady] = useState(false);
  const [log, setLog] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await api.www.workspaces(workspace.id).rippling.getSyncLog(id);
        setLog(data);
      } finally {
        setIsReady(true);
      }
    };
    fetchData();
    return fetchData.cancel;
  }, [api, workspace, id]);

  return (
    <Drawer isOpen title="Sync Log" byline="Rippling Integration" onClose={onClose}>
      {() => {
        if (!isReady) {
          return <PageLoader />;
        }
        return <Log>{log}</Log>;
      }}
    </Drawer>
  );
}
