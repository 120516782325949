import Big from 'big.js';
import _ from 'lodash';

const calculateEntries = (entries) => ({
  billableHours: _.round(
    entries.reduce((a, v) => a + (v.billableHours || 0), 0),
    2,
  ),
  nonBillableHours: _.round(
    entries.reduce((a, v) => a + (v.nonBillableHours || 0), 0),
    2,
  ),
  revenue: _.round(
    entries.reduce((a, v) => a + (v.billableHours * v.rate || 0), 0),
    2,
  ),
});

const calculateServicesBudget = (project, unassignedTasksBudget = null) => {
  let data;

  switch (project.budgetMode) {
    case 'detailed':
      if (project.useRoles) {
        data = calculateEntries(
          project.roles.map((pr) => ({
            billableHours: pr.isBillable ? pr.estimatedBillableHours : 0,
            nonBillableHours: pr.estimatedNonBillableHours,
            rate: pr.rate,
          })),
        );
      } else {
        data = calculateEntries(
          project.members.map((pm) => ({
            billableHours: pm.isBillable ? pm.estimatedBillableHours : 0,
            nonBillableHours: pm.estimatedNonBillableHours,
            rate: project.isBillable && pm.isBillable ? pm.rate : null,
          })),
        );
      }
      break;

    case 'aggregated':
      if (project.useRoles) {
        data = calculateEntries(
          project.roles.map((pr) => ({
            billableHours: pr.taskBillableHours,
            nonBillableHours: pr.taskNonBillableHours,
            rate: project.isBillable && pr.isBillable ? pr.rate : null,
          })),
        );
      } else {
        data = calculateEntries(
          project.members.map((pm) => ({
            billableHours: pm.taskBillableHours,
            nonBillableHours: pm.taskNonBillableHours,
            rate: project.isBillable && pm.isBillable ? pm.rate : null,
          })),
        );
      }

      if (unassignedTasksBudget) {
        const { unassignedBillableHours, unassignedNonBillableHours, unassignedRevenue } = unassignedTasksBudget;

        data.billableHours = Big(data.billableHours).plus(unassignedBillableHours).toNumber();
        data.nonBillableHours = Big(data.nonBillableHours).plus(unassignedNonBillableHours).toNumber();
        data.revenue = Big(data.revenue).plus(unassignedRevenue).toNumber();
      }

      break;

    case 'summary':
    default:
      data = {
        billableHours: project.estimatedBillableHours,
        nonBillableHours: project.estimatedNonBillableHours,
        revenue: project.estimatedServiceRevenue,
      };
      break;
  }

  if (['fixed', 'fixed_recurring'].includes(project.billingTypeId)) data.revenue = project.estimatedServiceRevenue;

  return {
    ...data,
    get hours() {
      return this.billableHours + this.nonBillableHours;
    },
    get effectiveRate() {
      return this.hours > 0 ? this.revenue / this.hours : null;
    },
  };
};

export default calculateServicesBudget;
