import React from 'react';
import styled from 'styled-components';
import { BackLink, Page } from '~/components';
import { useWorkspace } from '~/contexts';
import { colors } from '~/styles';

const Description = styled.p`
  color: ${colors.grey55};
  font-size: 0.875rem;
`;

function CustomDataHeader({ children }) {
  const { workspace } = useWorkspace();

  return (
    <Page.Header>
      <BackLink defaultPath={`/app/${workspace.key}/settings/custom-data`} />
      {children}
    </Page.Header>
  );
}

CustomDataHeader.Details = Page.Info;
CustomDataHeader.Title = Page.Title;
CustomDataHeader.Description = Description;
CustomDataHeader.Buttons = Page.Actions;

export default CustomDataHeader;
